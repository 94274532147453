import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Divider, Button, Form, Input, Tooltip, message } from "antd";
import { post } from "../../../../helper/request";

import PaymentCard from "../PaymentCard";
import { CheckCerrency } from "../../../../components/checkcurrency";
import { ClipLoader } from "react-spinners";
const locale = require("react-redux-i18n").I18n;
function WithDrawSecondStep(props) {
  const {
    paymentId,
    paymentKey,
    // account: { account_number },
    // payment_config: { minimum_deposit, maximum_deposit },
    payment_name,
  } = props;
  const [account, setAccount] = useState("");
  const accountlist = useSelector(({ account }) => account.accountTypeList);
  const accountDetail = accountlist?.find(
    (e, i) => e.name === account && e.type === "real"
  );
  const [form] = Form.useForm();
  const [minimum, setMinimum] = useState("");
  const [amount, setAmount] = useState(0);
  const [suffix, setSuffix] = useState("");
  const [prefix, setPrefix] = useState("");
  const [loading, setLoading] = useState(false);
  const [countryId, setCountryId] = useState(null);
  const [rate, setRate] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  // const [message, setMessage] = useState("");
  const [laos, setLaos] = useState(null);

  useEffect(() => {
    setLaos(props.user.profile.country_id);
    setAccount(props.account.account_type);
  }, []);
  useEffect(() => {
    setLoading(true);
    if (account) {
      post(`${process.env.REACT_APP_API_URL}v2/payment/rate`, {
        payment_id: paymentId,
        type: "withdraw",
      })
        .then(({ data }) => {
          setRate(data.data.rate);
          props.onSetRate(data.data.rate);
          let miniAcc = accountDetail.minimum_withdraw;
          let miniPayment =
            props.paymentSelected?.payment_config[0]?.minimum_withdraw /
            data.data.rate;
          let isCondition = miniAcc > miniPayment ? miniAcc : miniPayment;
          let checkCent =
            props.account.account_type === "Cent"
              ? isCondition * 100
              : isCondition;
          let urlParams = new URLSearchParams(window.location.search);
          let account_number = urlParams.get("account");
          let condition = urlParams.get("condition");
          let isamount = urlParams.get("amount");
          if (isamount) {
            form.setFieldsValue({
              amount: Math.ceil(isamount),
            });
            setAmount(Math.ceil(isamount));
            props.onChangeAmount(Math.ceil(isamount));
          } else {
            form.setFieldsValue({
              amount: Math.ceil(checkCent),
            });
            setAmount(Math.ceil(checkCent));
            props.onChangeAmount(Math.ceil(checkCent));
          }

          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  }, [account]);

  function checkWithDraw(rule, value) {
    const mm = props.paymentSelected?.payment_config[0]?.minimum_withdraw;
    const maxi = props.paymentSelected.payment_config[0].maximum_withdraw;

    const current_balance = props.account.current_balance;

    if (
      props.account.account_type !== "Cent" &&
      current_balance > 10000 &&
      value > 10000
    ) {
      return Promise.reject(
        `${locale.t("withdraw_error.t_max")}  
         ${parseInt(10000)
           .toFixed(2)
           .replace(/\d(?=(\d{3})+\.)/g, "$&,")} USD`
      );
    } else {
      if (props.account.account_type === "Cent") {
        if (value > accountDetail?.maximum_withdraw) {
          return Promise.reject(
            `${locale.t(
              "withdraw_error.t_max"
            )} ${accountDetail?.maximum_withdraw.toLocaleString()} USC`
          );
        } else if (value > current_balance) {
          return Promise.reject(locale.t("withdraw_error.t_error"));
        } else {
          if (value >= Math.ceil(mm / rate) * 100) {
            return Promise.resolve();
          }
          return Promise.reject(` ${locale.t("withdraw_error.t_condition")}`);
        }
      } else if (value > accountDetail?.maximum_withdraw) {
        return Promise.reject(
          `${locale.t(
            "withdraw_error.t_max"
          )} $${(accountDetail?.maximum_withdraw).toLocaleString()}`
        );
      } else if (value > current_balance) {
        return Promise.reject(locale.t("withdraw_error.t_error"));
      } else {
        if (value >= Math.ceil(mm / rate)) {
          return Promise.resolve();
        }
        return Promise.reject(` ${locale.t("withdraw_error.t_condition")}`);
      }
    }
  }
  function checkWithDrawAl(rule, value) {
    if (value > props.account.current_balance) {
      return Promise.reject(locale.t("withdraw_error.t_error"));
    } else {
      if (+value > accountDetail?.maximum_withdraw) {
        return Promise.reject(
          `${locale.t("withdraw_error.amount_crypto")} ${
            accountDetail?.maximum_withdraw
          } ${prefix}`
        );
      } else if (+value >= accountDetail?.minimum_withdraw) {
        if (+value / rate >= minimum) {
          return Promise.resolve();
        } else {
          return Promise.reject(
            `${locale.t("withdraw_error.amount_crypto")} ${minimum} ${prefix}`
          );
        }
      } else {
        return Promise.reject(
          `${locale.t("withdraw_error.amount_crypto")} 3 USD`
        );
      }
    }
  }

  const renderRateText = (i) => {
    if (i) {
      if (paymentKey === "alphapo") {
        return `${locale.t("withdraw_info.amount")} (${prefix})`;
      } else {
        if (laos === 8) {
          return "ຈຳ ນວນເງິນ (LAK)";
        } else {
          return <div>{` (${prefix})`}</div>;
        }
      }
    } else {
      if (paymentKey === "alphapo") {
        return `${locale.t(
          "withdraw_info.currency_rate"
        )}(${prefix}/${suffix})`;
      } else if (props.account.account_type === "Cent") {
        if (laos === 8) {
          return "ອັດຕາເງິນຕາ (LAK-> USD)";
        } else {
          return (
            <div>{`${locale.t(
              "withdraw_info.currency_rate"
            )}(USD/${prefix})`}</div>
          );
        }
      } else {
        if (laos === 8) {
          return "ອັດຕາເງິນຕາ (LAK-> USD)";
        } else {
          return (
            <div>{`${locale.t("withdraw_info.currency_rate")}(USD/${
              CheckCerrency(props.user.profile.country_id)?.iso
            })`}</div>
          );
        }
      }
    }
  };
  const renderRateWithdraw = () => {
    if (paymentKey === "alphapo") {
      if (isNaN((props.amount / rate).toFixed(6))) return 0.0;
      else return (props.amount / rate).toFixed(6);
    } else if (props.account.account_type === "Cent") {
      return ` ${((props.amount / 100) * parseFloat(rate))
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    } else {
      return ` ${(props.amount * parseFloat(rate))
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    }
  };

  function onFinish(values) {
    try {
      setButtonLoading(true);
      const payloadTH = {
        payment_id: paymentId,
        account_trade_id: props.account.id,
        method: props.bookBankVerify.bank_name,
        amount:
          props.account.account_type === "Cent"
            ? values.amount / 100
            : values.amount,
        amount_thb:
          props.account.account_type === "Cent"
            ? (values.amount / 100) * rate
            : values.amount * rate,
        transfer_account_number: props.bookBankVerify.bank_account_number,
        name: `${props.bookBankVerify.bank_account_name}`,
      };
      const payloadCrypto = {
        payment_id: paymentId,
        account_trade_id: props.account.id,
        amount: values.amount,
        address: values.address,
        currency: prefix,
      };
      const payloadBonus = {
        payment_id: paymentId,
        account_trade_id: props.account.id,
        amount: values.amount,
        currency: prefix,
        bonus_withdraw_condition_id: props?.accountWelcome?.condition || null,
      };

      post(
        process.env.REACT_APP_API_URL + "v2/user/account/initial-withdraw",
        props?.accountWelcome !== null
          ? payloadBonus
          : paymentKey === "alphapo"
          ? payloadCrypto
          : payloadTH
      )
        .then((res) => {
          setButtonLoading(false);
          props.onNextStep({
            account_withdraw_id: res.data.data.id,
            amount: values.amount,
            payment_id: paymentId,
            payment_key: paymentKey,
            address: values.address,
          });
        })
        .catch((e) => {
          // props.onBackStep();
          console.log(e);
          // message.error(locale.t("dashboardWallet.error.title"));
          if (e?.response?.data?.code === 3012) {
            message.error(
              e?.response?.data?.error + ". Please choose a new payment."
            );
          } else if (e?.response?.data?.code === 3003) {
            message.error(
              e?.response?.data?.error +
                CheckCerrency(props.user.profile.country_id)?.iso
            );
          } else {
            message.error(e?.response?.data?.error);
          }

          setButtonLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  }
  const handleKeyPress = (event) => {
    const input = event.target.value + event.key;

    // Regular expression to check if the input is a two-digit number with optional decimal point
    const regex = /^\d*\.?\d{0,2}$/;

    if (!regex.test(input)) {
      event.preventDefault(); // Prevents entering the invalid key
    } else {
    }
  };
  const removeLeadingZeros = (value) => {
    return value.replace(/^0+/, "");
  };
  const checkAmount = () => {
    if (props.account.account_type === "Cent") {
      if (
        (props.amount / 100) * rate <
        props.paymentSelected?.payment_config[0]?.minimum_withdraw
      ) {
        return "text-danger";
      } else {
        return "text-success";
      }
    } else {
      if (
        props.amount * rate <
        props.paymentSelected?.payment_config[0]?.minimum_withdraw
      ) {
        return "text-danger";
      } else {
        return "text-success";
      }
    }
  };
  const checkAmountBroker = () => {
    if (props.account.account_type === "Cent") {
      if (props.amount < accountDetail?.minimum_withdraw * 100) {
        return "text-danger";
      } else {
        return "text-success";
      }
    } else {
      if (props.amount < accountDetail?.minimum_withdraw) {
        return "text-danger";
      } else {
        return "text-success";
      }
    }
  };
  return (
    <div className="user">
      {loading ? (
        <div
          className="h-100 d-flex justify-content-center align-items-center flex-column my-5"
          style={{ height: 500 }}
        >
          <ClipLoader
            color="#36d7b7"
            size={72}
            cssOverride={{ borderWidth: 8 }}
          />
        </div>
      ) : (
        <>
          <span className="fs-14 font-weight-bold ">
            2.{locale.t("deposit_step.second_step.title")}
          </span>
          <Form
            name="deposit_amount"
            initialValues={{
              amount: props.amount,
            }}
            onFinish={onFinish}
            className="user mx-auto"
            form={form}
          >
            <div>
              <div className="text-left text-gray mb-1 mt-4">
                <div className="d-flex align-items-center">
                  {locale.t("withdraw_info.amount")} &nbsp;
                  <Tooltip
                    placement="topLeft"
                    title={
                      <div className="fs-10">
                        {locale.t("withdraw_step.first_step.withdraw_info")}
                      </div>
                    }
                  >
                    <img
                      src="/assets/images/icons/icon-information.svg"
                      alt="infomation"
                      height={"100%"}
                      className="cursor-pointer"
                    />
                  </Tooltip>
                </div>
              </div>
              <Form.Item
                style={{ borderTop: "0" }}
                name="amount"
                rules={[
                  {
                    validator:
                      paymentKey === "alphapo"
                        ? checkWithDrawAl
                        : checkWithDraw,
                  },
                ]}
              >
                <Input
                  defaultValue={props.amount}
                  value={props.amount}
                  disabled={props.accountWelcome}
                  prefix={props.account.account_type === "Cent" ? "¢" : "$"}
                  suffix={props.account.account_type === "Cent" ? "USC" : "USD"}
                  type="text"
                  onKeyPress={handleKeyPress}
                  onChange={(event) => {
                    form.setFieldsValue({
                      amount: removeLeadingZeros(event.target.value),
                    });
                    props.onChangeAmount(
                      removeLeadingZeros(event.target.value)
                    );
                    setAmount(removeLeadingZeros(event.target.value));
                  }}
                />
              </Form.Item>
            </div>
            <div className={`${checkAmountBroker()}`}>
              {locale.t("deposit_step.second_step.amount_more_than")}{" "}
              {props.account.account_type === "Cent"
                ? accountDetail?.minimum_withdraw * 100
                : accountDetail?.minimum_withdraw}
              &nbsp;{props.account.account_type === "Cent" ? `USC` : `USD`}
            </div>
            <div className={`${checkAmount()} `}>
              {locale.t("deposit_step.second_step.amount_more_than")}{" "}
              {props.paymentSelected?.payment_config[0]?.minimum_withdraw}{" "}
              {CheckCerrency(props.user.profile.country_id)?.iso}
            </div>
            <span className="w-12 text-gray my-3">
              {" "}
              {locale.t("deposit_step.second_step.you_receive")}
            </span>

            <div className="my-3 font-weight-bold d-flex">
              {renderRateWithdraw()}
              &nbsp;
              {CheckCerrency(props.user.profile.country_id)?.iso}
            </div>
            <div className="mb-3 text-gray">
              {locale.t("deposit_step.second_step.fee")}:{" "}
              <span style={{ color: "#000" }}>
                <b>0.00 USD</b>
              </span>
            </div>
            <div className="mb-3 text-gray">
              {locale.t("withdraw_step.second_step.transection_method")}:
              <span style={{ color: "#000" }}>
                <b>&nbsp;{props.paymentSelected.name}</b>
              </span>
            </div>

            <div className="mb-3 text-gray d-flex align-items-center">
              {renderRateText()} :&nbsp;
              <b style={{ color: "#000" }}>{rate.toFixed(2)}</b>
              &nbsp;
              <Tooltip
                placement="topLeft"
                title={
                  <div className="fs-10">
                    Please note that your withdrawal will depend on the exchange
                    rate of the intermediary you use. Withdrawal Please make
                    sure which exchange rate is the most suitable for each
                    withdrawal.
                  </div>
                }
              >
                <img
                  src="/assets/images/icons/icon-information.svg"
                  alt="infomation"
                  height={"100%"}
                  className="cursor-pointer"
                />
              </Tooltip>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <Button
                className="btn-outline-iux w-100 mr-1"
                onClick={() =>
                  props.onBackStep(props?.accountWelcome && "welcome")
                }
              >
                {locale.t("prev")}
              </Button>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldsValue, getFieldsError }) => {
                  return (
                    <Button
                      className="btn-primary-new-iux w-100"
                      htmlType="submit"
                      loading={buttonLoading}
                      disabled={
                        amount === 0 ||
                        getFieldsError().filter(({ errors }) => errors.length)
                          .length > 0
                      }
                    >
                      {locale.t("next")}
                    </Button>
                  );
                }}
              </Form.Item>
            </div>
          </Form>
        </>
      )}
    </div>
  );
}

const mapStateToProps = ({ user, i18n }) => {
  const { bookBankVerify } = user;
  return { bookBankVerify, user };
};

export default connect(mapStateToProps, null)(WithDrawSecondStep);
