import { INITIALIZE_CHANGED, LOGOUT } from '../types'

const INITIALIZE_STATE = {
  isInitialize: false
}

export default (state = INITIALIZE_STATE, action) => {
  switch (action.type) {
    case INITIALIZE_CHANGED:
      return { ...state, isInitialize: action.payload }
    case LOGOUT:
      return { ...INITIALIZE_STATE }
    default:
      return state
  }
}
