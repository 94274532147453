export default {
  th: {
    download_for: "ดาวน์โหลดสำหรับ",
    open_web_terminal: "เปิดเว็บเทอร์มินัล MT5",
    web_terminal: "เว็บเทอร์มินัล MetaTrader5",
    meta_download_app: "แอปมือถือ MetaTrader",
    iux_download_app: "แอป IUX Markets Trade",
  },
  la: {
    download_for: "ດາວໂຫຼດສໍາລັບ",
    open_web_terminal: "ເປີດ MT5 WebTermina ",
    web_terminal: "MetaTrader5 WebTerminal",
    meta_download_app: "MetaTrader5 ໃນ​ໂທລະ​ສັບ​​",
    iux_download_app: "ແອັບການຄ້າ IUX Markets ",
  },
  en: {
    download_for: "Download for",
    open_web_terminal: "Open MT5 WebTerminal ",
    web_terminal: "MetaTrader5 WebTerminal",
    meta_download_app: "MetaTrader5 Mobile",
    iux_download_app: "IUX Markets Trade App",
  },
  in: {
    download_for: "डाउनलोड करना",
    open_web_terminal: "खुला MT5 WebTerminal",
    web_terminal: "MetaTrader5 WebTerminal",
    meta_download_app: "MetaTrader5 गतिमान",
    iux_download_app: "IUX Markets ऐप ट्रेड",
  },
  my: {
    download_for: "Muat turun untuk",
    open_web_terminal: "Buka MT5 WebTerminal ",
    web_terminal: "MetaTrader5 WebTerminal",
    meta_download_app: "MetaTrader5 Mobile",
    iux_download_app: "IUX Markets Trade App",
  },
  id: {
    download_for: "Unduh untuk",
    open_web_terminal: "Buka Terminal Web MT5",
    web_terminal: "MetaTrader5 WebTerminal",
    meta_download_app: "Aplikasi Handphone MetaTrader",
    iux_download_app: "Aplikasi IUX Maekets Trade",
  },
  cn: {
    download_for: "Download for",
    open_web_terminal: "Open MT5 WebTerminal ",
    web_terminal: "MetaTrader5 WebTerminal",
    meta_download_app: "MetaTrader5 Mobile",
    iux_download_app: "IUX Markets Trade App",
  },
  vn: {
    download_for: "Tải xuống cho",
    open_web_terminal: "Mở MT5 WebTerminal",
    web_terminal: "MetaTrader5 WebTerminal",
    meta_download_app: "MetaTrader5 di động",
    iux_download_app: "IIUX Markets Giao dịch ứng dụng",
  },
  kr: {
    download_for: "Download for",
    open_web_terminal: "Open MT5 WebTerminal ",
    web_terminal: "MetaTrader5 WebTerminal",
    meta_download_app: "MetaTrader5 Mobile",
    iux_download_app: "IUX Markets Trade App",
  },
  ph: {
    download_for: "I-download",
    open_web_terminal: "Bukas MT5 WebTerminal ",
    web_terminal: "MetaTrader5 WebTerminal",
    meta_download_app: "MetaTrader5 Mobile",
    iux_download_app: "IUX Markets Trade App",
  },
  jp: {
    download_for: "ダウンロードしてください",
    open_web_terminal: "MT5 Web末端を開きます",
    web_terminal: "Metatrader5 Web末端",
    meta_download_app: "Metatrader5モバイル",
    iux_download_app: "IUX Markets Trade App",
  },
};
