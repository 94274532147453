import { get } from "./request";
import { CheckLanguage } from "./countryid";
import { browserName, CustomView } from "react-device-detect";

export const onOpenPolicy = async (keyname) => {
  try {
    const { data } = await get(
      `${
        process.env.REACT_APP_API_URL
      }v2/policy?key_name=${keyname}&country_id=${CheckLanguage(
        localStorage.getItem("locale")
      )}`
    );
    if (data.data.file_path.startsWith("https")) {
      if (browserName === "Safari" || browserName === "Mobile Safari") {
        window.location.replace(decodeURIComponent(data.data.file_path));
      } else {
        window.open(decodeURIComponent(data.data.file_path));
      }
      //
    } else {
      if (browserName === "Safari" || browserName === "Mobile Safari") {
        window.location.replace(
          `${process.env.REACT_APP_POLICY}policy/?keyname=${keyname}&country_id=1`
        );
      } else {
        window.open(
          `${process.env.REACT_APP_POLICY}policy/?keyname=${keyname}&country_id=1`
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
};
