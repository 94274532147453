import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { updateVerifierUser } from "../../../../actions";
import { useDispatch } from "react-redux";
const locale = require("react-redux-i18n").I18n;
function InprogressVerify(props) {
  const { type, des } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <div
      className="d-flex justify-content-center my-5 flex-column align-items-center mx-auto"
      style={{ maxWidth: 534 }}
    >
      <div className="fs-16">{locale.t("inprogress")}</div>
      <div className="iux-scan d-flex justify-content-center mt-4">
        <img src="/assets/images/verify/progress.svg" alt="success" />
      </div>
      <div className="fs-16 mt-4 text-lightgray text-center">{des}</div>

      <Button
        className="mt-4 btn-primary-new-iux"
        type="primary"
        onClick={() => {
          if (type === "success") {
            dispatch(
              updateVerifierUser({
                status: "pending",
                userVerifyInfo: null,
              })
            );
          }

          history.push(
            `/${localStorage.getItem("locale")}/wallet/account/openaccount`
          );
        }}
      >
        <p> {locale.t("trydemo")}</p>
      </Button>
    </div>
  );
}

export default InprogressVerify;
