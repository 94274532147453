import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { StyledAccountMain } from "../content/AccountMain";
import { Link } from "react-router-dom";
import { RightOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Skeleton, Tooltip } from "antd";
import { CheckCerrency } from "../checkcurrency";
import { fCurrency, fCurrencySymbol, fNumber } from "../../helper/formatNumber";
const locale = require("react-redux-i18n").I18n;
export const ProfileDashboard = (props) => {
  const { user } = props;
  const [rate, setRate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const profileCountry = useSelector((state) => state.user.profileCountry);
  const renderMaintenance = () => {
    let a = user.accounts.filter((account) => account.type === "real");

    var sumUsingReduce = 0;
    var total = a.reduce(function (sum, value) {
      if (value.account_type === "Cent") {
        return (sumUsingReduce += Number(value.current_balance / 100));
      } else {
        return (sumUsingReduce += Number(value.current_balance));
      }
    }, 0);
    return total;
  };
  const fetchCurrencyChange = () => {
    setIsLoading(true);
    const options = { method: "GET", headers: { accept: "application/json" } };
    let currency = CheckCerrency(user?.profile?.country_id)?.iso;
    // let currency = "THB";
    // if (user.profile.country_id === 1) {
    //   currency = "THB";
    // } else if (user.profile.country_id === 6) {
    //   currency = "MYR";
    // } else if (user.profile.country_id === 8) {
    //   currency = "LAK";
    // } else if (user.profile.country_id === 9) {
    //   currency = "IDR";
    // } else if (user.profile.country_id === 10) {
    //   currency = "VND";
    // }
    fetch(
      `https://api.fastforex.io/fetch-one?to=${currency}&api_key=11a16d086e-81be869391-rg2y45`,
      options
    )
      .then((response) => response.json())
      .then((response) => {
        setRate(response.result[currency]);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchCurrencyChange();
  }, [user.profile.country_id]);
  return (
    <StyledAccountMain>
      <div className="p-3 p-sm-4">
        <div className="d-flex justify-content-center justify-content-sm-between flex-column flex-sm-row align-items-start">
          <div className="d-flex">
            <div className="mr-3">
              <img src="/assets/images/dashboard/profile.png" alt="img-user" />
            </div>
            <div className=" ">
              <div
                className="fs-20 font-weight-bold align-items-center"
                style={{ wordBreak: "break-word" }}
              >
                {user.profile.first_name}&nbsp;{user.profile.last_name}
              </div>
              <div className="d-flex flex-column ">
                <div className="d-flex ">
                  <div className="text-lightgray fs-12">
                    {" "}
                    {locale.t("main_profile.user_id")} :{" "}
                  </div>
                  <div className="fs-12 ml-2">{user.profile.user_id}</div>
                </div>
                <div className="ml-0 d-flex">
                  <div
                    className="text-lightgray fs-12"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {locale.t("main_profile.emailAddress")} :{" "}
                  </div>
                  <div className="fs-12 ml-2">{user.profile.email}</div>
                </div>
                <div className="ml-0 d-flex d-sm-none">
                  <div
                    className="text-lightgray fs-12"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {locale.t("dashboardWallet.accountBalance")} :
                  </div>
                  <div className="fs-12 ml-2">
                    <span className="d- d-sm-none text-black">
                      <b>$</b>
                    </span>
                    <span className="text-black ">
                      <b>
                        {fCurrencySymbol(renderMaintenance().toFixed(2))}
                        &nbsp;
                      </b>
                    </span>
                    <span className="d-none d-sm-block">USD</span>
                    {fCurrencySymbol(renderMaintenance().toFixed(2)).length >
                    5 ? (
                      <br />
                    ) : (
                      ""
                    )}
                    &nbsp;≈&nbsp;
                    <span className="d- d-sm-none">
                      {profileCountry?.currency_symbol}
                    </span>
                    <span>
                      {fCurrencySymbol((renderMaintenance() * rate).toFixed(2))}
                      &nbsp;
                    </span>
                    <span className="d-none d-sm-block">
                      {profileCountry?.symbol}
                    </span>
                  </div>
                </div>
                {/* <div className="ml-0">
                  <Link
                    to={`/${localStorage.getItem("locale")}/wallet/lotback`}
                    className="text-dark fs-14 d-flex "
                  >
                    <div className="text-lightgray d-flex align-items-center fs-12 mr-2">
                      Account status :
                    </div>
                    {user.royal_class && (
                      <div>
                        <div className="d-flex">
                          <img
                            src={`/assets/images/icons/lotback/ic_lot_back_lv_${user.royal_class}.svg`}
                            width={16}
                            alt="lotback"
                            className="mr-1"
                          />
                          {user.royal_class === 1
                            ? "RISING STAR"
                            : user.royal_class === 2
                            ? "CHAMPION"
                            : "ELITE"}{" "}
                        </div>
                      </div>
                    )}
                  </Link>
                </div> */}
              </div>
            </div>
          </div>{" "}
          <div className="d-none d-sm-block">
            <div className="text-lightgray fs-12 text-left text-sm-right mt-2 mt-sm-0">
              <span className="ml-2 cursor-pointer h-100 ">
                <Tooltip
                  placement="bottom"
                  title={locale.t("tooltip_currency")}
                >
                  <InfoCircleOutlined className="text-primary-new-iux fs-16" />
                </Tooltip>
              </span>{" "}
              &nbsp;{locale.t("dashboardWallet.accountBalance")}
            </div>

            {!user.profile.country_id ? (
              <Skeleton.Input style={{ width: "100%" }} />
            ) : (
              <div className="d-none d-flex align-items-center align-items-sm-end flex-row flex-sm-column">
                <div className="fs-28 fs-xs-16 font-weight-bold d-flex">
                  <div className="d-block d-sm-none">$</div>
                  {renderMaintenance()
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
                  <div className="d-none d-sm-block ml-2"> USD</div>
                </div>
                <div className=" fs-16  text-gray d-flex">
                  &nbsp;≈&nbsp;
                  <div className="d-block d-sm-none">
                    {profileCountry?.currency_symbol}
                  </div>
                  {(renderMaintenance() * rate)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
                  <div className="d-none d-sm-block ml-2">
                    {profileCountry?.symbol}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </StyledAccountMain>
  );
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDashboard);
