import React from "react";
import { Row, Card, Popover, Menu, Tooltip } from "antd";
import {
  UserOutlined,
  TeamOutlined,
  DeleteOutlined,
  LineChartOutlined,
  SettingOutlined,
} from "@ant-design/icons";
const locale = require("react-redux-i18n").I18n;

const menu = (props) => (
  <Menu style={{ border: "none" }}>
    <Menu.Item
      onClick={() => {
        props.onEditPasswordShow(props.account);
      }}
    >
      <UserOutlined />{" "}
      {`${locale.t("dashboardWallet.menu.account_edit")} ${
        props.account.platform
      }`}
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        props.onEditInvestorPasswordShow(props.account);
      }}
    >
      <TeamOutlined /> {locale.t("dashboardWallet.menu.investor_edit")}
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        props.onEditLeverageShow(props.account);
      }}
    >
      <LineChartOutlined /> {locale.t("dashboardWallet.menu.leverage_edit")}
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        props.onDelete(props.account);
      }}
    >
      <DeleteOutlined />
      {locale.t("dashboardWallet.menu.account_close")}
    </Menu.Item>
  </Menu>
);

const DropdownSetting = (props) => (
  <>
    <Popover
      placement="bottom"
      content={menu(props)}
      trigger="click"
      className="cursor-pointer"
    >
      <img src="/assets/images/icons/icon-edit-account.svg" alt="edit" />
    </Popover>
  </>
);

const DemoAccountCard = (props) => (
  <Card
    className="account-card"
    headStyle={{ background: "#fff" }}
    title={
      <>
        <div className="align-content-center d-flex justify-content-between w-100">
          <div className="d-flex align-items-center flex-column">
            <div className="">
              <div className="fs-14 fw-700 d-flex align-items-center">
                {props?.account?.account_number}
                <div
                  className={`bage-free-swap-${
                    props?.account?.swap_charge ? "nonfree" : "isfree"
                  } ml-2`}
                >
                  {props?.account?.swap_charge ? "Non-Free Swap" : "Free Swap"}
                </div>
              </div>

              <div className="d-flex fs-10">
                {/* {replace} */}DEMO
                {" • "}
                {props?.isDetail?.display
                  ? props?.isDetail?.display
                  : props?.account?.account_type}
                {" • "}
                <div className="ml-1">&nbsp; {props?.account?.platform}</div>
              </div>
            </div>
          </div>
          <div
            className="ml-2"
            style={{
              cursor: "pointer",
              outline: "none",
              boxShadow: "none",
              border: "none",
              fontSize: "14px",
              paddingRight: "8px",
              textAlign: "right",
              paddingLeft: "0",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => props.onSync(props.account)}
            {...props}
          >
            {/* <Tooltip placement="bottom" title={text}>
              <SyncOutlined />
            </Tooltip> */}
          </div>
        </div>
      </>
    }
    extra={<DropdownSetting {...props} />}
  >
    <Row gutter={[8, 8]}>
      <div span={12} className="ant-respon w-100" style={{ padding: "6px" }}>
        <div className="d-flex justify-content-between w-100 mb-3">
          <div className="fs-12 ">{locale.t("dashboardWallet.server")}</div>
          <div className="fs-14 fw-700">
            {props.account.api_server === "api"
              ? "IUXMarkets-Live"
              : props.account.api_server === "live2"
              ? "IUXMarkets-Live2"
              : props.account.api_server === "demo"
              ? "IUXMarkets-Demo"
              : "IUXMarkets"}
          </div>
        </div>
        <div className="d-flex justify-content-between w-100 mb-3">
          <div className="fs-12 ">
            {locale.t("dashboardWallet.accountBalance")} (USD)
          </div>
          <div className="fs-14 fw-700">
            {props.account.current_balance.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
        <div className="d-flex justify-content-between w-100 mb-3">
          <div className="fs-12 ">
            {locale.t("dashboardWallet.accountCredit")} (USD)
          </div>
          <div className="fs-14 fw-700">
            {props.account.current_credit.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>

        <div className="d-flex justify-content-between w-100 mb-3">
          <div className="fs-12 ">
            {locale.t("dashboardWallet.accountLeverage")}
          </div>
          <div className="fs-14 fw-700">
            1 : {props.account.current_leverage}
          </div>
        </div>
        {/* <div className="d-flex justify-content-between w-100 ">
          <div className="fs-12 ">Swap </div>
          <div className="fs-14 fw-600 ">
            <span
              className={`${
                props?.account?.swap_charge
                  ? "text-lightgray-iux"
                  : "text-primary-sub-iux"
              }`}
            >
              {props?.account?.swap_charge ? "Non-Free" : "Free"}
            </span>
          </div>
        </div> */}
      </div>
    </Row>
  </Card>
);

export default DemoAccountCard;
