export const CheckLanguage = (id) => {
  switch (id) {
    case "th":
      return 1;
    case "en":
      return 2;
    case "cn":
      return 4;
    case "my":
      return 6;
    case "in":
      return 7;
    case "la":
      return 8;
    case "id":
      return 9;
    case "vn":
      return 10;
    case "ph":
      return 12;
    default:
      return 2;
  }
};
//
