export const CheckLanguageMoment = (id) => {
  switch (id) {
    case "th":
      return "th";
    case "en":
      return "en";
    case "cn":
      return "zh-cn";
    case "my":
      return "ms-my";
    case "in":
      return "hi";
    case "la":
      return "lo";
    case "id":
      return "id";
    case "vn":
      return "vi";
    case "ph":
      return "tl-ph";
    case "jp":
      return "ja";
    case "kr":
      return "kr";
    default:
      return "en";
  }
};
//
