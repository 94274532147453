import { Button, Checkbox, Modal, message } from "antd";
import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import ReactApexCharts from "react-apexcharts";

import { useHistory } from "react-router-dom";
const CHART_SIZE = { width: 82, height: 82 };
const locale = require("react-redux-i18n").I18n;
function Swap() {
  const isFree = false;
  const profileCountry = useSelector((state) => state.user.profileCountry);
  const chartOptionsCheckIn = {
    chart: { sparkline: { enabled: true } },
    colors: ["#1CB894"],

    fill: {
      type: "",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#D9D9D9"],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 90, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    legend: { show: false },
    plotOptions: {
      radialBar: {
        hollow: { size: "64%" },
        track: { margin: 0 },
        dataLabels: {
          name: { show: false },
          value: {
            offsetY: 6,
            fontSize: 12,
            color: "#1E1E1E",
          },
        },
      },
    },
  };
  return (
    <div className="bg-swap-status p-4 mb-5 mw-swap ">
      <div className="px-3 ">
        <div className="d-flex w-100 fs-14 fw-700 align-items-center ">
          {locale.t("modal_swap_title")} : &nbsp;
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="11"
            viewBox="0 0 10 11"
            fill="none"
          >
            <circle
              cx="5"
              cy="5.5"
              r="5"
              fill={isFree ? "#E3673F" : "#1CB894"}
            />
            <circle cx="5" cy="5.5" r="2.5" fill="white" />
          </svg>
          &nbsp;{isFree ? "Non-Free" : "Free"}
        </div>
        <div className="d-flex justify-content-center text-lightgray-iux  align-items-start  align-items-md-center w-100 fs-14 mt-3">
          <div>
            <ReactApexCharts
              type="radialBar"
              series={[isFree ? 0 : 100]}
              options={chartOptionsCheckIn}
              {...CHART_SIZE}
            />
          </div>
          <div className="ml-3">
            {isFree
              ? locale.t("modal_swap_des_non")
              : locale.t("modal_swap_des_free")}
          </div>
        </div>
        <div className="mt-3 fs-12 text-lightgray mb-5">
          {profileCountry?.isalamic === 0 ? (
            <>
              <div>{locale.t("modal_note")} :</div>
              <ul style={{ paddingInlineStart: "20px" }}>
                <li>{locale.t("modal_note_des_1")}</li>
                <li>{locale.t("modal_note_des_2")}</li>
              </ul>
            </>
          ) : (
            <div>{locale.t("des_swap_islamic")}</div>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <button
          className="btn-primary-new-iux"
          onClick={() =>
            window.location.assign(
              `/${localStorage.getItem("locale")}/swap/${
                profileCountry?.isalamic === 0 ? "non-islamic" : "islamic"
              }/Standard`
            )
          }
        >
          {`Swap rates >`}
        </button>
      </div>
    </div>
  );
}

export default Swap;
