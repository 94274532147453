import { UPDATE_LOADING, AUTH_SUCCESS, LOGOUT } from "../types";
import { message } from "antd";
import { CLIENT_SECRET, CLIENT_ID } from "../config";
import { post } from "../helper/request";
import qs from "qs";
import { reject } from "q";
import axios from "axios";

export const authentication = ({ email, password }) => {
  const locale = require("react-redux-i18n").I18n;
  return (dispatch) => {
    dispatch({
      type: UPDATE_LOADING,
      payload: true,
    });
    const data = qs.stringify({
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      grant_type: "password",
      username: email,
      password: password,
      scope: "*",
    });
    return post(`${process.env.REACT_APP_HOST}/api/v2/oauth/token`, data)
      .then(({ data }) => {
        localStorage.setItem("access_token", data.data.access_token);
        localStorage.setItem(
          "expires_in",
          data.data.access_token_expires_at + 7
        );
        localStorage.setItem("refresh_token", data.data.refresh_token);
        axios
          .get(
            process.env.REACT_APP_API_URL +
              "get-currency?from=THB&to=USD&amount=0&precision=2",
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            }
          )
          .then((res) => {
            localStorage.setItem("USDCurrency", res.data.rate.data);
            localStorage.setItem("USDdeposit", res.data.rate.data);
            localStorage.setItem("LAKeposit", res.data.rate.data);
          });

        dispatch({
          type: AUTH_SUCCESS,
          payload: data,
        });
        return { success: true, data: data };
      })
      .catch(({ response }) => {
        if (response.status === 403) {
          if (response.data.message === "please verify your email") {
            message.error(locale.t("validate_email"));
          } else {
            message.error(response.data.error);
          }
        } else if (response.status === 400) {
          if (response?.data?.code === 1001) {
            message.error("The account is disabled.");
          } else {
            message.error("Wrong password or email");
          }
        }
        dispatch({
          type: UPDATE_LOADING,
          payload: false,
        });
        return reject({ success: false, data: response });
      });
  };
};

export const LogoutAuth = () => {
  localStorage.setItem("access_token", "");
  localStorage.setItem("expires_in", 0);
  localStorage.setItem("refresh_token", "");
  localStorage.setItem("isAuth", "false");
  localStorage.setItem("onClose", "");
  localStorage.removeItem("tfninid", "");
  return (dispatch) => {
    dispatch({
      type: LOGOUT,
    });
  };
};
