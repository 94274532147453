import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import LandingPage from "./pages/LandingPage";
import { connect } from "react-redux";
import { getUser, LogoutAuth, setLocation, getIpAddress } from "./actions";
import EmailVerifier from "./pages/EmailVerifier";
import ChangePasswordMT4Page from "./pages/change-password-mt4";
import { Guest, Private } from "./route";
import ResetPassword from "./pages/forgotpassword/ResetPassword";
import { setLocale } from "react-redux-i18n";
import { withRouter } from "react-router";
import ContactMobile from "./pages/dashboard-content/ContactMobile";
import RedirectPage from "./redirect";
import Axios from "axios";

const locale = require("react-redux-i18n").I18n;

class Router extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationApi: localStorage.getItem("locale"),
      loading: true,
      timer: 5,
      ip: "",
    };
  }
  isSetLanguage = false;
  handleChangLanguage = async (lang) => {
    // await Axios.post(`https://ipv4.icanhazip.com`).then(({ data }) => {
    //   this.props.getIpAddress(data);
    // });
    var newLang = "fr";
    let langs = [
      "en",
      "th",
      "la",
      "my",
      "id",
      "cn",
      "vn",
      "kr",
      "ph",
      "in",
      "jp",
    ];
    if (langs.includes(lang)) {
      this.props.setLocale(lang);
      this.props.setLocation(lang);
      this.setState({ loading: false, locationApi: lang });
    } else {
      try {
        let langfix = ["th", "lo", "vn", "us"];
        let aip = "";
        // const { data } = await Axios.get(`https://geo.ipify.org/api/v2/country?apiKey=at_WbaZkiyHdZ0pnQYmJbd2BPtc9XeOV&ipAddress=${aip}`);
        // let str = data.location.country;
        let res = document.getElementsByTagName("html")[0].getAttribute("lang");
        // console.log(res, "res");
        if (langfix.includes(res)) {
          if (res === "us") res = "en";
          // this.props.history.push(res);
          this.props.setLocale(res);
          this.props.setLocation(res);
          this.setState({ loading: false });
        } else {
          // this.props.history.push("en");
          this.props.setLocale("en");
          this.props.setLocation("en");
          this.setState({ loading: false });
        }
      } catch (e) {
        // this.props.setLocale("th");
        // this.props.setLocation("th");
        // this.setState({ loading: false });
        console.log(e);
      }
    }
    this.isSetLanguage = true;
  };

  onRedirect = (newlang) => {
    this.props.history.push(newlang);
    this.props.setLocale(newlang);
    this.props.setLocation(newlang);
    // window.location.reload();
  };

  // eslint-disable-next-line no-dupe-class-members
  componentDidMount() {
    const location = localStorage.getItem("locale");
    this.handleChangLanguage(location);
  }

  render() {
    const location = localStorage.getItem("locale");
    // if (==== '')
    let now = new Date().valueOf();
    let urlParams = new URLSearchParams(window.location.search);
    let code = urlParams.get("code");
    if (code) {
      sessionStorage.setItem("code", code);
      // localStorage.setItem("code", code);
      // localStorage.setItem("_exp_code", now + 12 * 60 * 60 * 1000);
      // if (window.location.pathname === `/${location}`) {
      //   localStorage.setItem("code", code);
      //   localStorage.setItem("_exp_code", now + 12 * 60 * 60 * 1000);
      // }
    }
    let check = localStorage.getItem("_exp_code");
    if (+check - now < 0) {
      localStorage.setItem("code", "");
    }
    let verify = window.location.pathname.startsWith("/verify/");
    if (verify) {
      return (
        <Switch>
          <Route path="/verify/:token" component={EmailVerifier} />
        </Switch>
      );
    } else {
      if (this.state.loading) {
        return (
          <div>
            <RedirectPage onRedirect={this.onRedirect} />
          </div>
        );
      } else {
        return (
          <Switch>
            <Route
              path={"/:lang/app/contact"}
              exact
              render={(props) => <ContactMobile locale={locale} {...props} />}
            />
            <Route
              path="/reset-password/:id/:token"
              component={ResetPassword}
            />
            <Route
              path="/:lang"
              render={(props) => {
                if (!this.isSetLanguage) {
                  const paths = props.location.pathname.split("/");
                  this.handleChangLanguage(paths[1]);
                }
                return (
                  <Switch>
                    <Private path={`/:lang/wallet`} component={Dashboard} />
                    <Route path="/:lang" component={LandingPage} />
                    <Guest
                      path="/:lang/change-password-mt4"
                      component={ChangePasswordMT4Page}
                    />
                  </Switch>
                );
              }}
            ></Route>
            <Route
              path={`/`}
              render={() => {
                if (code !== null) {
                  sessionStorage.setItem("code", code);
                }
                let check = localStorage.getItem("_exp_code");
                if (+check - now < 0) {
                  localStorage.setItem("code", "");
                }
                return (
                  <Redirect
                    to={
                      code !== null
                        ? `/${location}?code=${code}`
                        : `/${location}`
                    }
                  />
                );
              }}
            />
            <Route>
              <Redirect to={`/${location}`} />
            </Route>
          </Switch>
        );
      }
    }
  }
}

const mapStateToProps = ({ user, i18n }) => {
  return { user, locale: i18n.locale };
};

export default connect(mapStateToProps, {
  getUser,
  LogoutAuth,
  setLocale,
  setLocation,
  getIpAddress,
})(withRouter(Router));
