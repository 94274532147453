import React, { useEffect, useState } from "react";
import { Row, Col, Button, Input, Select, Form } from "antd";
import styled from "styled-components";
import { post } from "../../helper/request";
import { Link } from "react-router-dom";
import { calcMargin, calcMarginMt5 } from "./Cacl";
import Recommendation from "./components/Recommendation";
const { Option } = Select;
const locale = require("react-redux-i18n").I18n;

const BoxContentCal = styled.div`
  padding: 12px;
  border-radius: 20px;
  border: 1px solid #8080803d;
  background: #242424;
`;

const BoxErrors = styled.div`
  color: red;
`;

const styleInput = {
  height: 40,
  borderRadius: 4,
};

const fontWeight = {
  fontWeight: 700,
  fontSize: 14,
};

const styleDisabled = {
  backgroundColor: "transparent",
  color: "#fff",
  height: 40,
  borderRadius: 4,
};

export default function Margin(props) {
  const symbol = props.symbols;
  const metaTrader = props.mtType;
  const { handleChangeSymbol } = props;

  const [form] = Form.useForm();
  const [lot, setLot] = useState();
  const [accountType, setAccountType] = useState();
  const [currency, setCurrency] = useState();
  const [leverage, setLeverage] = useState();
  const [accountCurrency, setAccountCurrency] = useState();
  const [contractSize, setContractSize] = useState();
  const [ask, setAsk] = useState();
  const [bid, setBid] = useState();
  const [converse, setConverse] = useState();
  const [currencyPair, setCurrencyPair] = useState();
  const [currencyProfit, setCurrencyProfit] = useState();
  const [marginRate, setMarginRate] = useState();
  const [calcMode, setCalcMode] = useState();
  const [base, setBase] = useState();
  // show
  const [MarginAccount1, setMarginAccount1] = useState();
  const [MarginAccount2, setMarginAccount2] = useState();
  const [loading, setLoading] = useState(false);

  const [errors, setError] = useState([]);

  function onChangeBaseCurrency(value) {
    setAccountCurrency(value);
  }
  function handleChangeLot(value) {
    setLot(value);
  }
  function onChangeCurrency(value) {
    if (metaTrader.mt4) {
      setCurrency(value);
      post(process.env.REACT_APP_API_URL + `calc/rate`, {
        account: accountCurrency,
        pair: value,
      }).then((response) => {
        setCurrencyPair(response.data.symbol.symbol);
        setConverse(response.data.converse);
        setAsk(response.data.symbol.ask);
        setContractSize(response.data.symbol.contract_size);
      });
    } else {
      setCurrency(value);
      post(process.env.REACT_APP_API_URL + `calc/mt5/rate`, {
        account: accountCurrency,
        pair: value,
      }).then((response) => {
        setCurrencyProfit(response.data.symbol.currencyProfit);
        setBase(response.data.symbol.currencyBase);
        setCalcMode(response.data.symbol.calcMode);
        setCurrencyPair(response.data.symbol.symbol);
        setConverse(response.data.converse);
        setAsk(response.data.symbol.tickShort.ask);
        setBid(response.data.symbol.tickShort.bid);
        setContractSize(response.data.symbol.contractSize);
        setMarginRate(response.data.symbol.marginRateCurrency);
      });
    }
  }

  function onChangeLeverage(value) {
    setLeverage(value);
  }
  function onChangeAccountType(value) {
    setAccountType(value);
  }

  const onSubmit = async () => {
    if (metaTrader.mt4) {
      setLoading(true);
      const { marginBase, marginQuote } = calcMargin(
        lot,
        contractSize,
        leverage,
        ask,
        converse
      );
      setMarginAccount1(marginBase);
      setMarginAccount2(marginQuote);
      setLoading(false);
    } else {
      const { marginCalc, marginCalcQoute } = await calcMarginMt5(
        lot,
        contractSize,
        leverage,
        bid,
        base,
        converse,
        marginRate,
        accountCurrency,
        calcMode
      );
      setMarginAccount1(marginCalc);
      setMarginAccount2(marginCalcQoute);
    }
  };
  const handleReset = () => {
    setMarginAccount1(0);
    setMarginAccount2(0);
  };

  const pair =
    currencyPair !== undefined && currencyPair.substr(3, 5).replace(".i", "");
  return (
    <>
      <Form form={form} className="col-md-8 mb-4">
        <BoxContentCal>
          <Col span={12}>
            <Form.Item name="1">
              <div className="mt-3 text-gray">
                {locale.t("fxcal_list_1_box.Platform")}
              </div>
              <Select
                size="large"
                className="w-100"
                onChange={(value) => {
                  handleChangeSymbol(value);
                  form.resetFields();
                  handleReset();
                }}
                defaultValue="Metatrader5"
              >
                <Option value={"mt5"}>Metatrader5</Option>
              </Select>
            </Form.Item>
          </Col>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="1">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_4_box.title_1")}
                </div>
                <Select
                  onChange={(value) => onChangeBaseCurrency(value)}
                  defaultValue={locale.t("calculator_select")}
                  size="large"
                  className="w-100"
                >
                  <Option value={"USD"}>USD</Option>
                </Select>
              </Form.Item>
              <Form.Item name="1">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_4_box.title_3")}
                </div>
                <Select
                  onChange={(value) => onChangeCurrency(value)}
                  defaultValue={locale.t("calculator_select")}
                  size="large"
                  className="w-100"
                >
                  {symbol !== null &&
                    symbol.map((e, i) => {
                      return (
                        <Option key={i} value={e.name}>
                          {e.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item name="1">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_4_box.title_5")}
                </div>
                <Select
                  onChange={(value) => onChangeAccountType(value)}
                  defaultValue={locale.t("calculator_select")}
                  size="large"
                  className="w-100"
                >
                  <Option value={"Standard"}>Standard</Option>
                </Select>
              </Form.Item>
            </Col>
            {/*  */}
            <Col
              span={12}
              className="d-flex flex-column justify-content-between"
            >
              <Form.Item name="1">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_4_box.title_2")}
                </div>
                <Input
                  style={styleInput}
                  placeholder={"Please enter value"}
                  onChange={(e) => handleChangeLot(e.target.value)}
                />
              </Form.Item>
              <Form.Item name="1">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_4_box.title_4")}
                </div>
                <Select
                  onChange={(value) => onChangeLeverage(value)}
                  defaultValue={locale.t("calculator_select")}
                  size="large"
                  className="w-100"
                >
                  <Option value={50}>1:50</Option>
                  <Option value={100}>1:100</Option>
                  <Option value={200}>1:200</Option>
                  <Option value={500}>1:500</Option>
                  <Option value={1000}>1:1000</Option>
                  <Option value={2000}>1:2000</Option>
                </Select>
              </Form.Item>
              <Form.Item name="1">
                <div className="mt-3 text-gray">
                  {locale.t("fxcal_list_4_box.title_6")}
                </div>
                <Input value={ask} style={styleDisabled} disabled />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ margin: "1rem 0" }}>
            <BoxErrors>{errors}</BoxErrors>
            <button
              loading={loading}
              onClick={() => onSubmit()}
              className="w-sm-100 btn-primary-new-iux"
            >
              {locale.t("btn_calculator")}
            </button>
          </div>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <h6 style={fontWeight} className=" text-gray">
                {locale.t("fxcal_list_4_box.title_7")}{" "}
                {accountCurrency ? `(${accountCurrency})` : ""}
              </h6>
              <div className=" text-gray">
                {locale.t("fxcal_list_4_box.title_8")}
              </div>
              <Input
                value={MarginAccount1 ? MarginAccount1.toFixed(5) : ""}
                style={styleDisabled}
                disabled
              />
            </Col>
            <Col span={12}>
              <h6 style={fontWeight} className=" text-gray">
                {locale.t("fxcal_list_4_box.title_7")}{" "}
                {metaTrader.mt4
                  ? pair
                    ? `(${pair})`
                    : ""
                  : currencyProfit
                  ? `(${currencyProfit})`
                  : ""}
              </h6>
              <div className=" text-gray">
                {locale.t("fxcal_list_4_box.title_9")}
              </div>
              <Input
                value={MarginAccount2 ? MarginAccount2.toFixed(5) : ""}
                style={styleDisabled}
                disabled
              />
            </Col>
          </Row>
        </BoxContentCal>
      </Form>
      <Recommendation />
    </>
  );
}
