import { message, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { post } from "../../../../helper/request";

const locale = require("react-redux-i18n").I18n;

export const postTazapay = ({ amount_thb, account_trade_id, payment_id }) => {
  return post(`${process.env.REACT_APP_API_URL + "deposit/tazapay"}`, {
    amount_thb: amount_thb,
    account_trade_id: account_trade_id,
    payment_id: payment_id,
  });
};

const Tazapay = (props) => {
  const { paymentId } = props;
  const lang = localStorage.getItem("locale");
  const refPerfect = useRef(null);
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({
    customer_name: "",
    message: "",
    payment_amount: "",
    payment_date: "",
    payment_link: "",
    phone_number: "",
    status: "",
    transaction_id: 0,
    type: "",
    type_fee: "",
  });
  useEffect(() => {
    const postApiPayment = async () => {
      try {
        setLoading(true);
        const payload = {
          account_trade_id: props.account.id,
          amount_thb: props.amount,
          payment_id: paymentId,
        };
        const { data } = await postTazapay(payload);
        setPaymentData({
          customer_name: data.customer_name,
          message: data.message,
          payment_amount: parseFloat(data.payment_amount).toFixed(2),
          payment_date: data.payment_date,
          payment_link: data.redirect_url,
          phone_number: data.phone_number,
          status: data.status,
          transaction_id: data.transaction_id,
          type: data.type,
          type_fee: data.type_fee,
        });
        setLoading(true);
        refPerfect.current.click();
      } catch ({ response }) {
        message.error(response.data.message);
      }
    };
    postApiPayment();
  }, []);

  return (
    <Spin spinning={loading}>
      <form action={paymentData.payment_link} method="POST">
        <p>
          <input
            ref={refPerfect}
            type="submit"
            value="tazapay account"
            hidden
          />
        </p>
      </form>
    </Spin>
  );
};

export default Tazapay;
