import React, { useState } from "react";
import RcQueueAnim from "rc-queue-anim";

import FooterLandingPage from "../../../components/footer-landing-page/FooterDark";
import Screener from "../tradingview/screener";
import { CheckLanguage } from "../../../helper/countryLang";
import Heatmap from "../tradingview/heatmap";
import Crossrate from "../tradingview/crossrate";
import Technicalanalysis from "../tradingview/technicalanalysis";
import styled from "styled-components";
const locale = require("react-redux-i18n").I18n;
const MenuItem = styled.div`
  padding: 0.8rem 1rem;
  color: ${(props) => props.color};
  border-bottom: ${(props) => props.borderBottom};
  white-space: pre;
  font-weight: ${(props) => props.fontWeight};
  cursor: pointer;
`;
function MarketResearch({ islayout }) {
  const [isTabs, setIsTabs] = useState(0);
  const [valuesScreener, setValuesScreener] = useState("america");
  const [defaultIsScreen, setDefaultIsScreen] = useState("most_capitalized");
  const menu = [
    {
      id: 0,
      link: `/${localStorage.getItem("locale")}/swap/non-islamic/Standard`,
      text: locale.t("stock_screener"),
      values: "america",
      defaultScreen: "most_capitalized",
    },
    {
      id: 1,
      link: `/${localStorage.getItem("locale")}/swap/islamic/Standard`,
      text: locale.t("forex_screener"),
      values: "forex",
      defaultScreen: "general",
    },
    {
      id: 1,
      link: `/${localStorage.getItem("locale")}/swap/islamic/Standard`,
      text: locale.t("crypto_screener"),
      values: "crypto",
      defaultScreen: "general",
    },
  ];
  return (
    <RcQueueAnim className="bg-black">
      <div
        key={2}
        className="container text-white"
        style={{
          height: "auto",
          paddingTop: window.innerWidth <= 425 ? "20%" : "10%",
        }}
      >
        <h4 className="text-white font-bold-iux text-left fs-56 text-uppercase mt-5 mt-md-0">
          Market Research
        </h4>
        <span>
          {locale.t("title_trading_view_1")}
          &nbsp;
          <a
            href={`https://www.tradingview.com/economic-calendar/`}
            rel="noopener nofollow"
            target="_blank"
          >
            <span className="blue-text">{locale.t("link_trading_view")}</span>
          </a>
          &nbsp; {locale.t("title_trading_view_2")}
        </span>
        <div className="mt-4">
          <div className="">
            <div className="container">
              <div className="d-flex overflow-auto scroll-menu-hide">
                {menu?.map((e, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      setDefaultIsScreen(e.defaultScreen);
                      setValuesScreener(e.values);
                      setIsTabs(i);
                    }}
                    color={i === isTabs ? "#FFF" : "#8D8D8D"}
                    borderBottom={
                      i === isTabs ? "4px solid #1CB894" : "2px solid #ff000000"
                    }
                    fontWeight={i === isTabs ? 400 : "unset"}
                  >
                    {e.text}
                  </MenuItem>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-4">
            <Screener
              widgetProps={{
                lang: CheckLanguage(localStorage.getItem("locale") || "en"),
                market: valuesScreener,
                defaultScreen: defaultIsScreen,
              }}
            />
          </div>
        </div>
        <div className="mt-4">
          <div className="text-center fs-24 font-weight-bold">Heat Map</div>
          <div className="mt-4 row">
            <div className="col-12 col-sm-6">
              <Heatmap
                widgetProps={{
                  lang: CheckLanguage(localStorage.getItem("locale") || "en"),
                }}
              />
            </div>
            <div className="col-12 col-sm-6">
              <Crossrate
                widgetProps={{
                  lang: CheckLanguage(localStorage.getItem("locale") || "en"),
                }}
              />
            </div>
          </div>
        </div>
        <div className="my-5">
          <span className="fs-24 font-weight-bold">
            {" "}
            Trending technical Analysis{" "}
          </span>
          <div className="mt-2 row">
            <div className="col-12 col-sm-6 mt-4">
              <Technicalanalysis
                widgetProps={{
                  theme: "dark",
                  width: "100%",
                  symbol: "TVC:GOLD",
                  lang: CheckLanguage(localStorage.getItem("locale") || "en"),
                }}
              />
            </div>
            <div className="col-12 col-sm-6 mt-4">
              {" "}
              <Technicalanalysis
                widgetProps={{
                  theme: "dark",
                  width: "100%",
                  symbol: "CRYPTO:BTCUSD",
                  lang: CheckLanguage(localStorage.getItem("locale") || "en"),
                }}
              />
            </div>
            <div className="col-12 col-sm-6 mt-5">
              <Technicalanalysis
                widgetProps={{
                  theme: "dark",
                  width: "100%",
                  symbol: "FX:USDJPY",
                  lang: CheckLanguage(localStorage.getItem("locale") || "en"),
                }}
              />
            </div>
            <div className="col-12 col-sm-6 mt-5">
              <Technicalanalysis
                widgetProps={{
                  theme: "dark",
                  width: "100%",
                  symbol: "FX:EURUSD",
                  lang: CheckLanguage(localStorage.getItem("locale") || "en"),
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {islayout !== "dashboard" && (
        <section key={10}>
          <FooterLandingPage locale={locale} />
        </section>
      )}
    </RcQueueAnim>
  );
}

export default MarketResearch;
