export default {
  th: {
    pdf: {
      termRisk: "/assets/term-risk-en.pdf",
      termPolicy: "/assets/term-policy-en.pdf",
      termPromotion: "/assets/term-promotion-en.pdf",
      condition: "/assets/condition-IUX-market-en.pdf",
      // condition_update: "/assets/condition-update-110802564-IUX-market-en.pdf",
      bonusDeposit25: "/assets/Bonus-Desposit-25-en.pdf",
      // bonusDeposit15new: "/assets/Bonus-Desposit-20-en.pdf",
      bonusDeposit35: "/assets/Bonus-Desposit-35-en.pdf",
      // bonusDeposit30new: "/assets/Bonus-Desposit-30-en.pdf",
      bonus30: "/assets/Bonus-terms-conditions-30-en.pdf",
      bonus30new: "/assets/Bonus-terms-conditions-30-new-en.pdf",
      openClose: "/assets/open-close-en.pdf",
      lotBack: "/assets/term_iux_market_lotback_en.pdf",
      otp: "/assets/sms-iux-en.pdf",
      clientAgreement: "/assets/iux-client-agreement-en.pdf",
      generalBusiness: "/assets/general-business-terms-en.pdf",
      amlPolicy: "/assets/aml-policy-en.pdf",
    },
  },
  en: {
    pdf: {
      termRisk: "/assets/term-risk-en.pdf",
      termPolicy: "/assets/term-policy-en.pdf",
      termPromotion: "/assets/term-promotion-en.pdf",
      condition: "/assets/condition-IUX-market-en.pdf",
      // condition_update: "/assets/condition-update-110802564-IUX-market-en.pdf",
      bonusDeposit25: "/assets/Bonus-Desposit-25-en.pdf",
      // bonusDeposit15new: "/assets/Bonus-Desposit-20-en.pdf",
      bonusDeposit35: "/assets/Bonus-Desposit-35-en.pdf",
      // bonusDeposit30new: "/assets/Bonus-Desposit-30-en.pdf",
      bonus30: "/assets/Bonus-terms-conditions-30-en.pdf",
      bonus30new: "/assets/Bonus-terms-conditions-30-new-en.pdf",
      openClose: "/assets/open-close-en.pdf",
      lotBack: "/assets/term_iux_market_lotback_en.pdf",
      otp: "/assets/sms-iux-en.pdf",
      clientAgreement: "/assets/iux-client-agreement-en.pdf",
      generalBusiness: "/assets/general-business-terms-en.pdf",
      amlPolicy: "/assets/aml-policy-en.pdf",
    },
  },
  in: {
    pdf: {
      termRisk: "/assets/term-risk-en.pdf",
      termPolicy: "/assets/term-policy-en.pdf",
      termPromotion: "/assets/term-promotion-en.pdf",
      condition: "/assets/condition-IUX-market-en.pdf",
      // condition_update: "/assets/condition-update-110802564-IUX-market-en.pdf",
      bonusDeposit25: "/assets/Bonus-Desposit-25-en.pdf",
      // bonusDeposit15new: "/assets/Bonus-Desposit-20-en.pdf",
      bonusDeposit35: "/assets/Bonus-Desposit-35-en.pdf",
      // bonusDeposit30new: "/assets/Bonus-Desposit-30-en.pdf",
      bonus30: "/assets/Bonus-terms-conditions-30-en.pdf",
      bonus30new: "/assets/Bonus-terms-conditions-30-new-en.pdf",
      openClose: "/assets/open-close-en.pdf",
      lotBack: "/assets/term_iux_market_lotback_en.pdf",
      otp: "/assets/sms-iux-en.pdf",
      clientAgreement: "/assets/iux-client-agreement-en.pdf",
      generalBusiness: "/assets/general-business-terms-en.pdf",
      amlPolicy: "/assets/aml-policy-en.pdf",
    },
  },
  kr: {
    pdf: {
      termRisk: "/assets/term-risk-en.pdf",
      termPolicy: "/assets/term-policy-en.pdf",
      termPromotion: "/assets/term-promotion-en.pdf",
      condition: "/assets/condition-IUX-market-en.pdf",
      // condition_update: "/assets/condition-update-110802564-IUX-market-en.pdf",
      bonusDeposit25: "/assets/Bonus-Desposit-25-en.pdf",
      // bonusDeposit15new: "/assets/Bonus-Desposit-20-en.pdf",
      bonusDeposit35: "/assets/Bonus-Desposit-35-en.pdf",
      // bonusDeposit30new: "/assets/Bonus-Desposit-30-en.pdf",
      bonus30: "/assets/Bonus-terms-conditions-30-en.pdf",
      bonus30new: "/assets/Bonus-terms-conditions-30-new-en.pdf",
      openClose: "/assets/open-close-en.pdf",
      lotBack: "/assets/term_iux_market_lotback_en.pdf",
      otp: "/assets/sms-iux-en.pdf",
      clientAgreement: "/assets/iux-client-agreement-en.pdf",
      generalBusiness: "/assets/general-business-terms-en.pdf",
      amlPolicy: "/assets/aml-policy.pdf",
    },
  },
  ph: {
    pdf: {
      termRisk: "/assets/term-risk-en.pdf",
      termPolicy: "/assets/term-policy-en.pdf",
      termPromotion: "/assets/term-promotion-en.pdf",
      condition: "/assets/condition-IUX-market-en.pdf",
      // condition_update: "/assets/condition-update-110802564-IUX-market-en.pdf",
      bonusDeposit25: "/assets/Bonus-Desposit-25-en.pdf",
      // bonusDeposit15new: "/assets/Bonus-Desposit-20-en.pdf",
      bonusDeposit35: "/assets/Bonus-Desposit-35-en.pdf",
      // bonusDeposit30new: "/assets/Bonus-Desposit-30-en.pdf",
      bonus30: "/assets/Bonus-terms-conditions-30-en.pdf",
      bonus30new: "/assets/Bonus-terms-conditions-30-new-en.pdf",
      openClose: "/assets/open-close-en.pdf",
      lotBack: "/assets/term_iux_market_lotback_en.pdf",
      otp: "/assets/sms-iux-en.pdf",
      clientAgreement: "/assets/iux-client-agreement-en.pdf",
      generalBusiness: "/assets/general-business-terms-en.pdf",
      amlPolicy: "/assets/aml-policy.pdf",
    },
  },
  my: {
    pdf: {
      termRisk: "/assets/term-risk-my.pdf",
      termPolicy: "/assets/term-policy-my.pdf",
      termPromotion: "/assets/term-promotion-my.pdf",
      condition: "/assets/condition-IUX-market-my.pdf",
      condition_update: "/assets/condition-update-110802564-IUX-market-en.pdf",
      bonusDeposit25: "/assets/Bonus-Desposit-25-my.pdf",
      // bonusDeposit15new: "/assets/Bonus-Desposit-20-en.pdf",
      bonusDeposit35: "/assets/Bonus-Desposit-35-my.pdf",
      // bonusDeposit30new: "/assets/Bonus-Desposit-30-en.pdf",
      bonus30: "/assets/Bonus-terms-conditions-30-my.pdf",
      bonus30new: "/assets/Bonus-terms-conditions-30-new-en.pdf",
      openClose: "/assets/open-close-en.pdf",
      lotBack: "/assets/term_iux_market_lotback_en.pdf",
      otp: "/assets/sms-iux-my.pdf",
      clientAgreement: "/assets/iux-client-agreement-my.pdf",
      generalBusiness: "/assets/general-business-terms-my.pdf",
      amlPolicy: "/assets/aml-policy-my.pdf",
    },
  },
  id: {
    pdf: {
      termRisk: "/assets/term-risk-en.pdf",
      termPolicy: "/assets/term-policy-en.pdf",
      termPromotion: "/assets/term-promotion-en.pdf",
      condition: "/assets/condition-IUX-market-en.pdf",
      // condition_update: "/assets/condition-update-110802564-IUX-market-en.pdf",
      bonusDeposit25: "/assets/Bonus-Desposit-25-en.pdf",
      // bonusDeposit15new: "/assets/Bonus-Desposit-20-en.pdf",
      bonusDeposit35: "/assets/Bonus-Desposit-35-en.pdf",
      // bonusDeposit30new: "/assets/Bonus-Desposit-30-en.pdf",
      bonus30: "/assets/Bonus-terms-conditions-30-en.pdf",
      bonus30new: "/assets/Bonus-terms-conditions-30-new-en.pdf",
      openClose: "/assets/open-close-en.pdf",
      lotBack: "/assets/term_iux_market_lotback_en.pdf",
      otp: "/assets/sms-iux-en.pdf",
      clientAgreement: "/assets/iux-client-agreement-en.pdf",
      generalBusiness: "/assets/general-business-terms-en.pdf",
      amlPolicy: "/assets/aml-policy.pdf",
    },
  },
  la: {
    pdf: {
      termRisk: "/assets/term-risk-lo.pdf",
      termPolicy: "/assets/term-policy-lo.pdf",
      termPromotion: "/assets/term-promotion-lo.pdf",
      condition: "/assets/condition-IUX-market-lo.pdf",
      // condition_update: "/assets/condition-update-110802564-IUX-market-en.pdf",
      bonusDeposit25: "/assets/Bonus-Desposit-25-lo.pdf",
      bonusDeposit15new: "/assets/Bonus-Desposit-20-en.pdf",
      bonusDeposit35: "/assets/Bonus-Desposit-35-lo.pdf",
      bonusDeposit30new: "/assets/Bonus-Desposit-30-en.pdf",
      bonus30: "/assets/Bonus-terms-conditions-30-lo.pdf",
      bonus30new: "/assets/Bonus-terms-conditions-30-new-en.pdf",
      openClose: "/assets/open-close-en.pdf",
      lotBack: "/assets/term_iux_market_lotback_en.pdf",
      otp: "/assets/sms-iux-lo.pdf",
      clientAgreement: "/assets/iux-client-agreement-lo.pdf",
      generalBusiness: "/assets/general-business-terms-lo.pdf",
      amlPolicy: "/assets/aml-policy-lo.pdf",
    },
  },
  vn: {
    pdf: {
      termRisk: "/assets/term-risk-en.pdf",
      termPolicy: "/assets/term-policy-en.pdf",
      termPromotion: "/assets/term-promotion-en.pdf",
      condition: "/assets/condition-IUX-market-en.pdf",
      // condition_update: "/assets/condition-update-110802564-IUX-market-en.pdf",
      bonusDeposit25: "/assets/Bonus-Desposit-25-en.pdf",
      // bonusDeposit15new: "/assets/Bonus-Desposit-20-en.pdf",
      bonusDeposit35: "/assets/Bonus-Desposit-35-en.pdf",
      // bonusDeposit30new: "/assets/Bonus-Desposit-30-en.pdf",
      bonus30: "/assets/Bonus-terms-conditions-30-en.pdf",
      bonus30new: "/assets/Bonus-terms-conditions-30-new-en.pdf",
      openClose: "/assets/open-close-en.pdf",
      lotBack: "/assets/term_iux_market_lotback_en.pdf",
      otp: "/assets/sms-iux-en.pdf",
      clientAgreement: "/assets/iux-client-agreement-en.pdf",
      generalBusiness: "/assets/general-business-terms-en.pdf",
      amlPolicy: "/assets/aml-policy.pdf",
    },
  },
  cn: {
    pdf: {
      termRisk: "/assets/term-risk-en.pdf",
      termPolicy: "/assets/term-policy-en.pdf",
      termPromotion: "/assets/term-promotion-en.pdf",
      condition: "/assets/condition-IUX-market-en.pdf",
      // condition_update: "/assets/condition-update-110802564-IUX-market-en.pdf",
      bonusDeposit25: "/assets/Bonus-Desposit-25-en.pdf",
      // bonusDeposit15new: "/assets/Bonus-Desposit-20-en.pdf",
      bonusDeposit35: "/assets/Bonus-Desposit-35-en.pdf",
      // bonusDeposit30new: "/assets/Bonus-Desposit-30-en.pdf",
      bonus30: "/assets/Bonus-terms-conditions-30-en.pdf",
      bonus30new: "/assets/Bonus-terms-conditions-30-new-en.pdf",
      openClose: "/assets/open-close-en.pdf",
      lotBack: "/assets/term_iux_market_lotback_en.pdf",
      otp: "/assets/sms-iux-en.pdf",
      clientAgreement: "/assets/iux-client-agreement-en.pdf",
      generalBusiness: "/assets/general-business-terms-en.pdf",
      amlPolicy: "/assets/aml-policy.pdf",
    },
  },
};
