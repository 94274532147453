export default {
  en: {
    des_swap_islamic:
      "Please be aware that Swap-Free status is exclusively available for clients from Islamic countries and is applicable to specific instruments.",
    modal_swap_title: "	Swap Status	",
    modal_swap_des_non:
      "	Overnight charges apply. The Non-Free status is determined by your overnight instrument holding trading activity. To be upgraded to Swap-Free status, you need to trade mostly within the day.	",
    modal_swap_des_free:
      "	No more overnight charges. Your Swap-Free status is determined by your trading activity. To maintain an active Swap-Free status, you need to trade mostly within the day.	",
    modal_note: "	Notes	",
    modal_note_des_1:
      "	Using a liquidity provider’s algorithm to analyze clients’ trading  behavior, ensuring proper utilization of the swap-free program.	",
    modal_note_des_2:
      "	Please be aware that Swap-Free status is available only for specific instruments.	",
    descriptHead:
      "Explore our transparent and competitive Swap & Spread conditions to enhance your trading experience. IUX Markets, we strive to provide you with the utmost clarity and flexibility in managing your trading positions.",
    tabPrimaryTitle: "Non-Islamic Countries",
    tabSecondaryTitle: "Islamic Countries",
    tableDescriptionIsalamic:
      "IUX Markets have provided Swap-Free accounts for residents of Islamic countries who observe Sharia law. Our automated system identifies which clients are residents of countries where Islam is the predominant religion and designates their accounts as Swap-Free.",
    tableDescription:
      "For our valued clients in non-Islamic countries, all new accounts created in these regions are automatically set to Swap-Free status. To maintain an active Swap-Free status, you need to trade mostly within the day.",
    swapConditionsTitle: "Swap Conditions:	",
    swapConditionsDescription:
      "Our Swap conditions are designed to accommodate your trading preferences. Whether you opt for our Swap-Free status, enabling you to hold positions without incurring overnight charges, or choose to leverage the benefits of our standard Swap conditions, we aim to empower your trading strategy.",
    spreadConditionsTitle: "Spread Conditions:	",
    spreadConditionsDescription:
      "Our competitive Spread conditions are tailored to offer you the best pricing in the industry. Starting from as low as 0.0 pips, our spreads ensure you have the advantage of cost-efficient trading.",
    newAccountTitle: "Swap-Free for New Accounts!	",
    newAccountDescription:
      "Unlock your trading potential with our exclusive offer – no swap fees, ever. Join us now for a trading experience like no other.",
  },
  th: {
    des_swap_islamic:
      "โปรดทราบว่าสถานะการแลกเปลี่ยนฟรีนั้นมีให้สำหรับลูกค้าจากประเทศอิสลามเท่านั้นและใช้กับเครื่องมือเฉพาะ",
    modal_swap_title: "	สถานะสว็อป	",
    modal_swap_des_non:
      "	มีค่าใช้จ่ายค้างคืน สถานะที่ไม่ฟรีจะถูกกำหนดโดยเครื่องมือข้ามคืนของคุณที่ถือกิจกรรมการซื้อขาย ในการอัพเกรดเป็นสถานะการแลกเปลี่ยนคุณต้องแลกเปลี่ยนส่วนใหญ่ภายในวัน	",
    modal_swap_des_free:
      "	ไม่มีค่าใช้จ่ายค้างคืนอีกต่อไป สถานะสว็อปของคุณถูกกำหนดโดยกิจกรรมการซื้อขายของคุณ เพื่อรักษาสถานะการแลกเปลี่ยนที่ใช้งานอยู่คุณต้องซื้อขายส่วนใหญ่ภายในวัน	",
    modal_note: "	หมายเหตุ	",
    modal_note_des_1:
      "	การใช้อัลกอริทึมของผู้ให้บริการสภาพคล่องในการวิเคราะห์พฤติกรรมการซื้อขายของลูกค้าเพื่อให้มั่นใจว่าการใช้ประโยชน์จากสว็อปที่เหมาะสม	",
    modal_note_des_2: "	โปรดทราบว่าสถานะสว็อปฟรีมีให้เฉพาะสำหรับเครื่องมือเฉพาะ	",
    descriptHead:
      "สำรวจเงื่อนไขสว็อปที่โปร่งใสและมีการแข่งขันและสเปรดของเราเพื่อเพิ่มประสบการณ์การซื้อขายของคุณ IUX Markets เรามุ่งมั่นที่จะให้ความชัดเจนและความยืดหยุ่นสูงสุดในการจัดการตำแหน่งการซื้อขายของคุณ",
    tabPrimaryTitle: "ประเทศที่ไม่ใช่อิสลาม",
    tabSecondaryTitle: "ประเทศอิสลาม",
    tableDescriptionIsalamic:
      "IUX Markets ได้จัดทำบัญชีปลอดสว็อปสำหรับผู้อยู่อาศัยในประเทศอิสลามที่ปฏิบัติตามกฎหมายของชาเรีย ระบบอัตโนมัติของเราระบุว่าลูกค้ารายใดเป็นผู้อยู่อาศัยในประเทศที่อิสลามเป็นศาสนาที่โดดเด่นและกำหนดบัญชีของพวกเขาว่าปราศจากสว็อป",
    tableDescription:
      "สำหรับลูกค้าที่มีค่าของเราในประเทศที่ไม่ใช่อิสลามบัญชีใหม่ทั้งหมดที่สร้างขึ้นในภูมิภาคเหล่านี้จะถูกตั้งค่าเป็นสถานะการแลกเปลี่ยนโดยอัตโนมัติ เพื่อรักษาสถานะการแลกเปลี่ยนที่ใช้งานอยู่คุณต้องซื้อขายส่วนใหญ่ภายในวัน",
    swapConditionsTitle: "เงื่อนไขสว็อป:",
    swapConditionsDescription:
      "เงื่อนไขสว็อปของเราได้รับการออกแบบมาเพื่อรองรับการตั้งค่าการซื้อขายของคุณ ไม่ว่าคุณจะเลือกใช้สถานะการแลกเปลี่ยนของเราทำให้คุณสามารถดำรงตำแหน่งได้โดยไม่ต้องเสียค่าใช้จ่ายข้ามคืนหรือเลือกที่จะใช้ประโยชน์จากประโยชน์ของเงื่อนไขการแลกเปลี่ยนมาตรฐานของเราเรามุ่งมั่นที่จะเพิ่มขีดความสามารถในการซื้อขาย",
    spreadConditionsTitle: "เงื่อนไขสเปรด:",
    spreadConditionsDescription:
      "เงื่อนไขสเปรดการแข่งขันของเราได้รับการปรับแต่งเพื่อเสนอราคาที่ดีที่สุดในอุตสาหกรรม เริ่มต้นจากต่ำถึง 0.0 pips สเปรดของเราช่วยให้คุณมั่นใจได้ว่าคุณได้รับประโยชน์จากการซื้อขายที่มีประสิทธิภาพ",
    newAccountTitle: "สว็อปฟรีสำหรับบัญชีใหม่!",
    newAccountDescription:
      "ปลดล็อกศักยภาพการซื้อขายของคุณด้วยข้อเสนอพิเศษของเรา - ไม่มีค่าธรรมเนียมการแลกเปลี่ยนเลย เข้าร่วมกับเราตอนนี้เพื่อรับประสบการณ์การซื้อขายที่ไม่เคยมีมาก่อน",
  },
  la: {
    des_swap_islamic:
      "ກະລຸນາຮັບຊາບວ່າສະຖານະພາບທີ່ບໍ່ເສຍຄ່າແມ່ນມີສະເພາະສໍາລັບລູກຄ້າສະເພາະແຕ່ລະປະເທດຈາກປະເທດອິດສະລາມແລະສາມາດນໍາໃຊ້ໄດ້ກັບເຄື່ອງມືສະເພາະ.",
    modal_swap_title: "	ສະຖານະພາບ Swap	",
    modal_swap_des_non:
      "	ຄ່າບໍລິການໃນເວລາກາງຄືນ. ສະຖານະພາບທີ່ບໍ່ແມ່ນບໍ່ເສຍຄ່າແມ່ນຖືກກໍານົດໂດຍກິດຈະກໍາການຄ້າຂາຍເຄື່ອງມືໃນເວລາກາງຄືນຂອງທ່ານ. ເພື່ອໃຫ້ໄດ້ຮັບການຍົກລະດັບໃຫ້ເປັນສະຖານະພາບທີ່ບໍ່ເສຍຄ່າ, ທ່ານຈໍາເປັນຕ້ອງຄ້າຂາຍສ່ວນໃຫຍ່ພາຍໃນມື້.	",
    modal_swap_des_free:
      "	ບໍ່ມີຄ່າບໍລິການໃນເວລາກາງຄືນອີກຕໍ່ໄປ. ສະຖານະພາບທີ່ບໍ່ເສຍຄ່າຂອງທ່ານແມ່ນຖືກກໍານົດໂດຍກິດຈະກໍາການຄ້າຂອງທ່ານ. ເພື່ອຮັກສາສະຖານະພາບທີ່ບໍ່ເສຍຄ່າ, ທ່ານຈໍາເປັນຕ້ອງຄ້າຂາຍສ່ວນໃຫຍ່ພາຍໃນມື້.	",
    modal_note: "	ບັນທຶກ	",
    modal_note_des_1:
      "	ການນໍາໃຊ້ສູດການຄິດໄລ່ຂອງສະພາບຄ່ອງເພື່ອວິເຄາະພຶດຕິກໍາການຄ້າຂອງລູກຄ້າ, ຮັບປະກັນການນໍາໃຊ້ໂຄງການທີ່ຖືກຕ້ອງຂອງໂຄງການທີ່ບໍ່ເສຍຄ່າ.	",
    modal_note_des_2:
      "	ກະລຸນາຮັບຊາບວ່າສະຖານະພາບທີ່ບໍ່ເສຍຄ່າໂດຍສະເພາະແມ່ນມີພຽງແຕ່ເຄື່ອງມືສະເພາະເທົ່ານັ້ນ.	",
    descriptHead:
      "ສໍາຫຼວດສະພາບການປ່ຽນແປງທີ່ໂປ່ງໃສແລະການແຜ່ຂະຫຍາຍແລະແຜ່ຂະຫຍາຍເພື່ອເສີມຂະຫຍາຍປະສົບການການຄ້າຂອງທ່ານ. ຕະຫຼາດ IIUX, ພວກເຮົາພະຍາຍາມສະຫນອງຄວາມກະຈ່າງແຈ້ງແລະຄວາມຍືດຫຍຸ່ນສູງສຸດໃນການຄຸ້ມຄອງຕໍາແຫນ່ງຂອງທ່ານ.",
    tabPrimaryTitle: "ປະເທດທີ່ບໍ່ແມ່ນອິດສະລາມ",
    tabSecondaryTitle: "ປະເທດອິດສະລາມ",
    tableDescriptionIsalamic:
      "IUX Markets ໄດ້ສະຫນອງບັນຊີໂດຍບໍ່ເສຍຄ່າສໍາລັບຜູ້ທີ່ອາໄສຢູ່ໃນປະເທດອິສລາມທີ່ປະຕິບັດກົດຫມາຍ Sharia. ລະບົບອັດຕະໂນມັດຂອງພວກເຮົາກໍານົດວ່າລູກຄ້າແມ່ນຜູ້ທີ່ອາໃສຢູ່ໃນປະເທດທີ່ສາສະຫນາອິດສະລາມແມ່ນສາສະຫນາເດັ່ນແລະແຕ່ງຕັ້ງບັນຊີຂອງພວກເຂົາໃຫ້ເປັນແບບບໍ່ເສຍຄ່າ.",
    tableDescription:
      "ສໍາລັບລູກຄ້າທີ່ມີຄຸນຄ່າຂອງພວກເຮົາໃນປະເທດທີ່ບໍ່ແມ່ນອິດສະລາມ, ບັນຊີໃຫມ່ທັງຫມົດທີ່ສ້າງຂື້ນໃນຂົງເຂດເຫຼົ່ານີ້ແມ່ນຖືກກໍານົດໂດຍອັດຕະໂນມັດ. ເພື່ອຮັກສາສະຖານະພາບທີ່ບໍ່ເສຍຄ່າ, ທ່ານຈໍາເປັນຕ້ອງຄ້າຂາຍສ່ວນໃຫຍ່ພາຍໃນມື້.",
    swapConditionsTitle: "ສະພາບການແລກປ່ຽນ:",
    swapConditionsDescription:
      "ເງື່ອນໄຂການແລກປ່ຽນຂອງພວກເຮົາຖືກອອກແບບມາເພື່ອຮອງຮັບຄວາມມັກຂອງການຄ້າຂອງທ່ານ. ບໍ່ວ່າທ່ານຈະເລືອກເອົາສະຖານະພາບທີ່ບໍ່ເສຍຄ່າຂອງພວກເຮົາ, ເຮັດໃຫ້ທ່ານຖືຕໍາແຫນ່ງໂດຍບໍ່ມີການຊ່ວຍເຫຼືອໃນການຊ່ວຍເຫຼືອຂອງສະພາບການແລກປ່ຽນແບບມາດຕະຖານຂອງພວກເຮົາ, ພວກເຮົາຕັ້ງໃຈສ້າງຍຸດທະສາດການຄ້າຂອງທ່ານ.",
    spreadConditionsTitle: "ເງື່ອນໄຂການແຜ່ກະຈາຍ:",
    spreadConditionsDescription:
      "ສະພາບການແຜ່ກະຈາຍຂອງພວກເຮົາແມ່ນເຫມາະສົມທີ່ຈະສະເຫນີລາຄາທີ່ດີທີ່ສຸດໃນອຸດສະຫະກໍາ. ເລີ່ມຕົ້ນຈາກຕ່ໍາກ່ວາ 0.0 pips, ການແຜ່ກະຈາຍຂອງພວກເຮົາຮັບປະກັນວ່າທ່ານມີປະໂຫຍດຈາກການຊື້ຂາຍທີ່ມີຄ່າໃຊ້ຈ່າຍ.",
    newAccountTitle: "ແລກປ່ຽນໂດຍບໍ່ເສຍຄ່າສໍາລັບບັນຊີໃຫມ່!",
    newAccountDescription:
      "ປົດລັອກທ່າແຮງການຄ້າຂອງທ່ານກັບຂໍ້ສະເຫນີພິເສດຂອງພວກເຮົາ - ບໍ່ມີຄ່າທໍານຽມແລກປ່ຽນສິນຄ້າ, ເຄີຍ. ເຂົ້າຮ່ວມກັບພວກເຮົາໃນຕອນນີ້ສໍາລັບປະສົບການການຄ້າຄືບໍ່ມີໃຜອີກ.",
  },
  in: {
    des_swap_islamic:
      "कृपया ध्यान रखें कि स्वैप-फ्री स्थिति विशेष रूप से इस्लामी देशों के ग्राहकों के लिए उपलब्ध है और विशिष्ट उपकरणों पर लागू होती है।",
    modal_swap_title: "	स्वैप स्थिति	",
    modal_swap_des_non:
      "	रात भर के आरोप लागू होते हैं। गैर-मुक्त स्थिति आपके रातोंरात उपकरण होल्डिंग ट्रेडिंग गतिविधि द्वारा निर्धारित की जाती है। स्वैप-मुक्त स्थिति में अपग्रेड किए जाने के लिए, आपको ज्यादातर दिन के भीतर व्यापार करने की आवश्यकता है।	",
    modal_swap_des_free:
      "	रातोंरात कोई और शुल्क नहीं। आपकी स्वैप-मुक्त स्थिति आपकी ट्रेडिंग गतिविधि द्वारा निर्धारित की जाती है। एक सक्रिय स्वैप-मुक्त स्थिति बनाए रखने के लिए, आपको ज्यादातर दिन के भीतर व्यापार करने की आवश्यकता है।	",
    modal_note: "	टिप्पणियाँ	",
    modal_note_des_1:
      "	ग्राहकों के व्यापारिक व्यवहार का विश्लेषण करने के लिए एक तरलता प्रदाता के एल्गोरिथ्म का उपयोग करना, स्वैप-मुक्त कार्यक्रम का उचित उपयोग सुनिश्चित करना।	",
    modal_note_des_2:
      "	कृपया ध्यान रखें कि स्वैप-मुक्त स्थिति केवल विशिष्ट उपकरणों के लिए उपलब्ध है।	",
    descriptHead:
      "अपने व्यापारिक अनुभव को बढ़ाने के लिए हमारे पारदर्शी और प्रतिस्पर्धी स्वैप और प्रसार की स्थिति का अन्वेषण करें। IUX बाजार, हम आपको अपने ट्रेडिंग पदों के प्रबंधन में अत्यंत स्पष्टता और लचीलापन प्रदान करने का प्रयास करते हैं।",
    tabPrimaryTitle: "गैर-इस्लामिक देश",
    tabSecondaryTitle: "इस्लामिक देश",
    tableDescriptionIsalamic:
      "IUX Markets बाजारों ने इस्लामी देशों के निवासियों के लिए स्वैप-मुक्त खाते प्रदान किए हैं जो शरिया कानून का निरीक्षण करते हैं। हमारी स्वचालित प्रणाली पहचानती है कि कौन से ग्राहक ऐसे देशों के निवासी हैं जहां इस्लाम प्रमुख धर्म है और अपने खातों को स्वैप-फ्री के रूप में नामित करता है।",
    tableDescription:
      "गैर-इस्लामिक देशों में हमारे मूल्यवान ग्राहकों के लिए, इन क्षेत्रों में बनाए गए सभी नए खाते स्वचालित रूप से स्वैप-मुक्त स्थिति के लिए निर्धारित हैं। एक सक्रिय स्वैप-मुक्त स्थिति बनाए रखने के लिए, आपको ज्यादातर दिन के भीतर व्यापार करने की आवश्यकता है।",
    swapConditionsTitle: "स्वैप की स्थिति:",
    swapConditionsDescription:
      "हमारी स्वैप की स्थिति आपकी ट्रेडिंग वरीयताओं को समायोजित करने के लिए डिज़ाइन की गई है। चाहे आप हमारी स्वैप-फ्री स्थिति का विकल्प चुनते हैं, जिससे आप रातोंरात शुल्क के बिना पदों को पकड़ सकें, या हमारी मानक स्वैप शर्तों के लाभों का लाभ उठाने का चयन करें, हम आपकी ट्रेडिंग रणनीति को सशक्त बनाने का लक्ष्य रखते हैं।",
    spreadConditionsTitle: "प्रसार की स्थिति:",
    spreadConditionsDescription:
      "हमारी प्रतिस्पर्धी प्रसार की स्थिति आपको उद्योग में सर्वोत्तम मूल्य निर्धारण की पेशकश करने के लिए तैयार है। 0.0 पिप्स के रूप में कम से शुरू, हमारे प्रसार सुनिश्चित करते हैं कि आपके पास लागत-कुशल व्यापार का लाभ है।",
    newAccountTitle: "नए खातों के लिए स्वैप-फ्री!",
    newAccountDescription:
      "हमारे विशेष प्रस्ताव के साथ अपनी ट्रेडिंग क्षमता को अनलॉक करें - कोई स्वैप फीस नहीं, कभी भी। कोई अन्य की तरह एक व्यापारिक अनुभव के लिए अब हमसे जुड़ें।",
  },
  my: {
    des_swap_islamic:
      "Harap maklum bahawa status bebas swap tersedia secara eksklusif untuk pelanggan dari negara-negara Islam dan boleh digunakan untuk instrumen tertentu.",
    modal_swap_title: "	Status swap	",
    modal_swap_des_non:
      "	Caj semalaman dikenakan. Status tidak bebas ditentukan oleh aktiviti perdagangan yang memegang instrumen semalaman anda. Untuk dinaik taraf kepada status bebas swap, anda perlu berdagang kebanyakannya dalam sehari.	",
    modal_swap_des_free:
      "	Tiada lagi caj semalaman. Status bebas swap anda ditentukan oleh aktiviti dagangan anda. Untuk mengekalkan status bebas swap aktif, anda perlu berdagang kebanyakannya dalam masa sehari.	",
    modal_note: "	Nota	",
    modal_note_des_1:
      "	Menggunakan algoritma pembekal kecairan untuk menganalisis tingkah laku perdagangan pelanggan, memastikan penggunaan yang betul dalam program bebas swap.	",
    modal_note_des_2:
      "	Harap maklum bahawa status bebas swap hanya tersedia untuk instrumen tertentu.	",
    descriptHead:
      "Terokai syarat -syarat swap dan penyebaran kami yang telus dan kompetitif untuk meningkatkan pengalaman perdagangan anda. Pasaran IUX, kami berusaha untuk memberikan anda kejelasan dan fleksibiliti sepenuhnya dalam menguruskan kedudukan perdagangan anda.",
    tabPrimaryTitle: "Negara bukan Islam",
    tabSecondaryTitle: "Negara -negara Islam",
    tableDescriptionIsalamic:
      " IUX Markets telah menyediakan akaun bebas swap bagi penduduk negara-negara Islam yang memerhatikan undang-undang Syariah. Sistem automatik kami mengenal pasti pelanggan mana penduduk di negara-negara di mana Islam adalah agama yang utama dan menetapkan akaun mereka sebagai bebas swap.",
    tableDescription:
      "Bagi pelanggan kami yang bernilai di negara bukan Islam, semua akaun baru yang dibuat di kawasan ini secara automatik ditetapkan untuk status bebas swap. Untuk mengekalkan status bebas swap aktif, anda perlu berdagang kebanyakannya dalam masa sehari.",
    swapConditionsTitle: "Syarat Tukar:",
    swapConditionsDescription:
      "Keadaan pertukaran kami direka untuk menampung pilihan perdagangan anda. Sama ada anda memilih status bebas swap kami, membolehkan anda memegang jawatan tanpa menanggung caj semalaman, atau memilih untuk memanfaatkan manfaat syarat swap standard kami, kami berhasrat untuk memberi kuasa kepada strategi perdagangan anda.",
    spreadConditionsTitle: "Menyebarkan Syarat:",
    spreadConditionsDescription:
      "Keadaan penyebaran kompetitif kami disesuaikan untuk menawarkan harga terbaik dalam industri. Bermula dari serendah 0.0 pip, spread kami memastikan anda mempunyai kelebihan perdagangan yang cekap kos.",
    newAccountTitle: "Swap-bebas untuk akaun baru!",
    newAccountDescription:
      "Buka kunci potensi dagangan anda dengan tawaran eksklusif kami - tiada yuran swap, pernah. Sertai kami sekarang untuk pengalaman dagangan seperti yang lain.",
  },
  id: {
    des_swap_islamic:
      "Perlu diketahui bahwa status bebas swap secara eksklusif tersedia untuk klien dari negara-negara Islam dan berlaku untuk instrumen tertentu.",
    modal_swap_title: "	Status pertukaran	",
    modal_swap_des_non:
      "	Biaya semalam berlaku. Status non-bebas ditentukan oleh aktivitas perdagangan instrumen semalam Anda. Untuk ditingkatkan ke status bebas bertukar, Anda perlu berdagang sebagian besar dalam sehari.	",
    modal_swap_des_free:
      "	Tidak ada lagi biaya semalam. Status bebas swap Anda ditentukan oleh aktivitas perdagangan Anda. Untuk mempertahankan status bebas swap aktif, Anda perlu berdagang sebagian besar dalam sehari.	",
    modal_note: "	Catatan	",
    modal_note_des_1:
      "	Menggunakan algoritma penyedia likuiditas untuk menganalisis perilaku perdagangan klien, memastikan pemanfaatan program bebas swap yang tepat.	",
    modal_note_des_2:
      "	Perlu diketahui bahwa status bebas swap hanya tersedia untuk instrumen tertentu.	",
    descriptHead:
      "Jelajahi kondisi swap & spread kami yang transparan dan kompetitif untuk meningkatkan pengalaman perdagangan Anda. IUX Markets, kami berusaha untuk memberi Anda kejelasan dan fleksibilitas terbaik dalam mengelola posisi perdagangan Anda.",
    tabPrimaryTitle: "Negara-negara non-Islam",
    tabSecondaryTitle: "Negara -negara Islam",
    tableDescriptionIsalamic:
      "IUX Markets telah memberikan akun bebas swap untuk penduduk negara-negara Islam yang mengamati hukum Syariah. Sistem otomatis kami mengidentifikasi klien mana yang merupakan penghuni negara-negara di mana Islam adalah agama yang dominan dan menetapkan akun mereka sebagai bebas swap.",
    tableDescription:
      "Untuk klien kami yang berharga di negara-negara non-Islam, semua akun baru yang dibuat di wilayah ini secara otomatis diatur ke status bebas bertukar. Untuk mempertahankan status bebas swap aktif, Anda perlu berdagang sebagian besar dalam sehari.",
    swapConditionsTitle: "Kondisi Pertukaran:",
    swapConditionsDescription:
      "Kondisi pertukaran kami dirancang untuk mengakomodasi preferensi perdagangan Anda. Apakah Anda memilih status bebas swap kami, memungkinkan Anda untuk memegang posisi tanpa mengeluarkan biaya semalam, atau memilih untuk memanfaatkan manfaat dari kondisi pertukaran standar kami, kami bertujuan untuk memberdayakan strategi perdagangan Anda.",
    spreadConditionsTitle: "Kondisi penyebaran:",
    spreadConditionsDescription:
      "Kondisi penyebaran kompetitif kami dirancang untuk menawarkan harga terbaik di industri ini. Mulai dari serendah 0,0 PIPS, spread kami memastikan Anda memiliki keuntungan dari perdagangan yang hemat biaya.",
    newAccountTitle: "Tukar bebas untuk akun baru!",
    newAccountDescription:
      "Buka kunci potensi perdagangan Anda dengan penawaran eksklusif kami - tidak pernah ada biaya swap. Bergabunglah dengan kami sekarang untuk pengalaman perdagangan tidak seperti yang lain.",
  },
  cn: {
    des_swap_islamic:
      "請注意，伊斯蘭國家的客戶僅適用於無交換狀態，適用於特定工具。",
    modal_swap_title: "	交換狀態	",
    modal_swap_des_non:
      "	適用過夜費用。非免費狀態取決於您的隔夜工具持有交易活動。要升級到無交換狀態，您需要大部分時間在一天之內進行交易。	",
    modal_swap_des_free:
      "	不再有一夜之間的費用。您的無交換狀態取決於您的交易活動。為了維持無主交換狀態，您需要大部分時間在一天之內進行交易。	",
    modal_note: "	筆記	",
    modal_note_des_1:
      "	使用流動性提供商的算法來分析客戶的交易行為，以確保適當利用無交換計劃。	",
    modal_note_des_2: "	請注意，無交換狀態僅適用於特定工具。	",
    descriptHead:
      "探索我們透明且競爭性的交換和傳播條件，以增強您的交易經驗。 IUX市場，我們努力為您提供管理交易頭寸的最大清晰度和靈活性。",
    tabPrimaryTitle: "非伊斯蘭國家",
    tabSecondaryTitle: "伊斯蘭國家",
    tableDescriptionIsalamic:
      "IUX Markets市場為觀察伊斯蘭教法的伊斯蘭國家的居民提供了無交換帳戶。我們的自動化系統確定了哪些客戶是伊斯蘭教是主要宗教的國家的居民，並將其賬目指定為無交換。",
    tableDescription:
      "對於我們在非伊斯蘭國家的重要客戶，在這些地區創建的所有新帳戶都會自動設置為無交換狀態。為了維持無主交換狀態，您需要大部分時間在一天之內進行交易。",
    swapConditionsTitle: "交換條件：",
    swapConditionsDescription:
      "我們的交換條件旨在適應您的交易偏好。無論您是選擇我們的無交換狀態，使您能夠在不產生過夜費用的情況下擔任職位，還是選擇利用我們的標準交換條件的好處，我們旨在增強您的交易策略。",
    spreadConditionsTitle: "擴散條件：",
    spreadConditionsDescription:
      "我們的競爭性差異條件是為您提供業內最佳定價的量身定制的。從低至0.0 PIPS開始，我們的點差可確保您具有成本效益的交易優勢。",
    newAccountTitle: "	免費交換新帳戶！",
    newAccountDescription:
      "通過我們的獨家報價釋放您的交易潛力 - 永遠不會交換費用。立即加入我們，獲得與眾不同的交易經驗。",
  },
  vn: {
    des_swap_islamic:
      "Xin lưu ý rằng trạng thái không có trao đổi chỉ dành riêng cho khách hàng từ các quốc gia Hồi giáo và được áp dụng cho các công cụ cụ thể.",
    modal_swap_title: "	Trạng thái hoán đổi	",
    modal_swap_des_non:
      "	Phí qua đêm áp dụng. Trạng thái không miễn phí được xác định bởi hoạt động giao dịch của công cụ qua đêm của bạn. Để được nâng cấp lên trạng thái miễn phí hoán đổi, bạn cần giao dịch chủ yếu trong ngày.	",
    modal_swap_des_free:
      "	Không còn phí qua đêm. Trạng thái không hoán đổi của bạn được xác định bởi hoạt động giao dịch của bạn. Để duy trì trạng thái không hoán đổi hoạt động, bạn cần giao dịch chủ yếu trong ngày.	",
    modal_note: "	Ghi chú	",
    modal_note_des_1:
      "	Sử dụng thuật toán của nhà cung cấp thanh khoản để phân tích hành vi giao dịch của khách hàng, đảm bảo việc sử dụng đúng chương trình hoán đổi.	",
    modal_note_des_2:
      "	Xin lưu ý rằng trạng thái không hoán đổi chỉ có sẵn cho các công cụ cụ thể.	",
    descriptHead:
      "Khám phá các điều kiện hoán đổi & lan truyền trong suốt và cạnh tranh của chúng tôi để nâng cao trải nghiệm giao dịch của bạn. Thị trường IUX, chúng tôi cố gắng cung cấp cho bạn sự rõ ràng và linh hoạt tối đa trong việc quản lý các vị trí giao dịch của bạn.",
    tabPrimaryTitle: "Các quốc gia phi Hồi giáo",
    tabSecondaryTitle: "Các quốc gia Hồi giáo",
    tableDescriptionIsalamic:
      "IUX Markets đã cung cấp các tài khoản miễn phí hoán đổi cho cư dân của các quốc gia Hồi giáo tuân thủ luật Sharia. Hệ thống tự động của chúng tôi xác định khách hàng nào là cư dân của các quốc gia nơi Hồi giáo là tôn giáo chiếm ưu thế và chỉ định tài khoản của họ là hoán đổi.",
    tableDescription:
      "Đối với các khách hàng có giá trị của chúng tôi ở các quốc gia phi Hồi giáo, tất cả các tài khoản mới được tạo ở các khu vực này được tự động đặt thành trạng thái miễn phí hoán đổi. Để duy trì trạng thái không hoán đổi hoạt động, bạn cần giao dịch chủ yếu trong ngày.",
    swapConditionsTitle: "Điều kiện hoán đổi:",
    swapConditionsDescription:
      "Điều kiện hoán đổi của chúng tôi được thiết kế để phù hợp với sở thích giao dịch của bạn. Cho dù bạn chọn trạng thái không hoán đổi của chúng tôi, cho phép bạn giữ các vị trí mà không phải chịu phí qua đêm, hoặc chọn tận dụng lợi ích của các điều kiện hoán đổi tiêu chuẩn của chúng tôi, chúng tôi nhằm mục đích trao quyền cho chiến lược giao dịch của bạn.",
    spreadConditionsTitle: "Điều kiện lan rộng:",
    spreadConditionsDescription:
      "Điều kiện lan truyền cạnh tranh của chúng tôi được thiết kế để cung cấp cho bạn giá tốt nhất trong ngành. Bắt đầu từ mức thấp nhất là 0,0 pips, chênh lệch của chúng tôi đảm bảo bạn có lợi thế về giao dịch hiệu quả về chi phí.",
    newAccountTitle: "Trao đổi miễn phí cho các tài khoản mới!",
    newAccountDescription:
      "Mở khóa tiềm năng giao dịch của bạn với ưu đãi độc quyền của chúng tôi - không có phí hoán đổi. Tham gia với chúng tôi ngay bây giờ để có trải nghiệm giao dịch không giống ai.",
  },
  kr: {
    des_swap_islamic:
      "이슬람 국가의 고객에게 스왑 프리 상태가 독점적으로 제공되며 특정 기기에 적용 가능합니다.",
    modal_swap_title: "	스왑 상태	",
    modal_swap_des_non:
      "	야간 요금이 적용됩니다. 비없는 상태는 거래 활동을 보유하는 야간 기기에 의해 결정됩니다. 스왑이없는 상태로 업그레이드하려면 주로 하루 안에 거래해야합니다.	",
    modal_swap_des_free:
      "	더 이상 밤새 청구되지 않습니다. 스왑이없는 상태는 거래 활동에 의해 결정됩니다. 적극적인 스왑이없는 상태를 유지하려면 대부분 매일 거래해야합니다.	",
    modal_note: "	노트	",
    modal_note_des_1:
      "	유동성 제공 업체의 알고리즘을 사용하여 고객의 거래 행동을 분석하여 스왑 프리 프로그램의 적절한 활용을 보장합니다.	",
    modal_note_des_2:
      "	스왑 프리 상태는 특정 기기에 대해서만 사용할 수 있습니다.	",
    descriptHead:
      "거래 경험을 향상시키기 위해 투명하고 경쟁력있는 스왑 및 스프레드 조건을 탐색하십시오. IUX Markets, 우리는 거래 위치를 관리 할 때 최대한의 명확성과 유연성을 제공하기 위해 노력합니다.",
    tabPrimaryTitle: "비 이슬람 국가",
    tabSecondaryTitle: "이슬람 국가",
    tableDescriptionIsalamic:
      "IUX MArkets시장은 샤리아 법을 준수하는 이슬람 국가 거주자에게 스왑이없는 계정을 제공했습니다. 우리의 자동화 시스템은 어떤 고객이 이슬람이 주요 종교인 국가의 거주자인지 식별하고 자신의 계정을 스왑 프리로 지정합니다.",
    tableDescription:
      "비 이슬람 국가의 소중한 고객의 경우,이 지역에서 생성 된 모든 새로운 계정은 자동으로 교환 상태로 설정됩니다. 적극적인 스왑이없는 상태를 유지하려면 대부분 매일 거래해야합니다.",
    swapConditionsTitle: "	스왑 조건 :",
    swapConditionsDescription:
      "우리의 스왑 조건은 거래 선호도를 수용하도록 설계되었습니다. 스왑이없는 상태를 선택하든, 하룻밤의 요금을 내지 않고 위치를 유지할 수 있거나 표준 스왑 조건의 이점을 활용하기로 선택하든 거래 전략을 강화하는 것을 목표로합니다.",
    spreadConditionsTitle: "	스프레드 조건 :",
    spreadConditionsDescription:
      "우리의 경쟁력있는 스프레드 조건은 업계에서 최고의 가격을 제공 할 수 있도록 조정되었습니다. 0.0 핍까지 시작하여 스프레드는 비용 효율적인 거래의 이점을 보장합니다.",
    newAccountTitle: "새 계정을 위해 무료로 교환하십시오!",
    newAccountDescription:
      "스왑 수수료가없는 독점 제안으로 거래 잠재력을 잠금 해제하십시오. 지금 우리와 함께 거래 경험을 위해 우리와 함께하십시오.",
  },
  ph: {
    des_swap_islamic:
      "Mangyaring magkaroon ng kamalayan na ang swap-free status ay eksklusibo na magagamit para sa mga kliyente mula sa mga bansang Islam at naaangkop sa mga tiyak na instrumento.",
    modal_swap_title: "	Swap Status	",
    modal_swap_des_non:
      "	Mag -apply ang mga singil sa magdamag. Ang katayuan na hindi walang bayad ay natutukoy ng iyong magdamag na instrumento na may hawak na aktibidad sa pangangalakal. Upang ma-upgrade sa katayuan na walang swap, kailangan mong ipagpalit ang karamihan sa loob ng araw.	",
    modal_swap_des_free:
      "	Wala nang magdamag na singil. Ang iyong katayuan na walang swap ay natutukoy ng iyong aktibidad sa pangangalakal. Upang mapanatili ang isang aktibong katayuan na walang swap, kailangan mong ipagpalit ang karamihan sa loob ng araw.	",
    modal_note: "	Mga Tala	",
    modal_note_des_1:
      "	Gamit ang algorithm ng isang tagabigay ng pagkatuto upang pag-aralan ang pag-uugali ng kalakalan ng mga kliyente, tinitiyak ang wastong paggamit ng programa na walang swap.	",
    modal_note_des_2:
      "	Mangyaring magkaroon ng kamalayan na ang katayuan na walang swap ay magagamit lamang para sa mga tukoy na instrumento.	",
    descriptHead:
      "Galugarin ang aming transparent at mapagkumpitensya na pagpapalit at pagkalat ng mga kondisyon upang mapahusay ang iyong karanasan sa pangangalakal. Iux Markets, nagsusumikap kaming magbigay sa iyo ng lubos na kalinawan at kakayahang umangkop sa pamamahala ng iyong mga posisyon sa pangangalakal.",
    tabPrimaryTitle: "Mga bansang hindi Islam",
    tabSecondaryTitle: "Mga bansang Islam",
    tableDescriptionIsalamic:
      "Ang mga IUX Markets Markets ay nagbigay ng mga swap-free account para sa mga residente ng mga bansang Islam na nagmamasid sa batas ng Sharia. Kinikilala ng aming awtomatikong sistema kung aling mga kliyente ang mga residente ng mga bansa kung saan ang Islam ang pangunahing relihiyon at itinatalaga ang kanilang mga account bilang walang swap.",
    tableDescription:
      "Para sa aming mga pinahahalagahan na kliyente sa mga bansang hindi Islamiko, ang lahat ng mga bagong account na nilikha sa mga rehiyon na ito ay awtomatikong nakatakda sa katayuan na walang swap. Upang mapanatili ang isang aktibong katayuan na walang swap, kailangan mong ipagpalit ang karamihan sa loob ng araw.",
    swapConditionsTitle: "Mga Kondisyon ng Pagpalit:",
    swapConditionsDescription:
      "Ang aming mga kondisyon ng pagpapalit ay idinisenyo upang mapaunlakan ang iyong mga kagustuhan sa pangangalakal. Kung pipiliin mo ang aming katayuan ng swap-free, na nagbibigay-daan sa iyo upang hawakan ang mga posisyon nang hindi nagkakaroon ng mga magdamag na singil, o piliin na magamit ang mga benepisyo ng aming karaniwang mga kondisyon ng pagpapalit, nilalayon naming bigyan ng kapangyarihan ang iyog diskarte sa pangangalakal.	",
    spreadConditionsTitle: "Kumalat na mga kondisyon:",
    spreadConditionsDescription:
      "Ang aming mapagkumpitensyang mga kondisyon ng pagkalat ay naaayon upang mag -alok sa iyo ng pinakamahusay na pagpepresyo sa industriya. Simula mula sa mas mababang bilang 0.0 PIPS, tinitiyak ng aming mga pagkalat na mayroon kang kalamangan sa pangangalakal na mahusay sa gastos.",
    newAccountTitle: "Swap-free para sa mga bagong account!",
    newAccountDescription:
      "I -unlock ang iyong potensyal sa pangangalakal sa aming eksklusibong alok - walang mga bayarin sa pagpapalit, kailanman. Sumali sa amin ngayon para sa isang karanasan sa pangangalakal tulad ng walang iba.",
  },
  mm: {
    des_swap_islamic:
      "အစ္စလာမ်နိုင်ငံများမှဖောက်သည်များအတွက် SWAP အခမဲ့ status ကိုသီးသန့်ရရှိထားကြောင်းသတိပြုပါ။",
    modal_swap_title: "	swap status ကို	",
    modal_swap_des_non:
      "	နေ့ချင်းညချင်းစွဲချက်များလျှောက်ထားသည်။ အခမဲ့မဟုတ်သော status ကိုသင်၏ညအိပ်ညဥ့်တူရိယာကိုရောင်းဝယ်ဖောက်ကားခြင်းဖြင့်ဆုံးဖြတ်သည်။ Swap-free status ကိုအဆင့်မြှင့်တင်ရန်, သင်သည်တစ်နေ့တွင်အများအားဖြင့်ကုန်သွယ်ရန်လိုအပ်သည်။	",
    modal_swap_des_free:
      "	နောက်နေ့အဖိုးအခမပေး။ သင်၏ swap-free status ကိုသင်၏ကုန်သွယ်ရေးလှုပ်ရှားမှုကဆုံးဖြတ်သည်။ တက်ကြွသော swap-free status ကိုထိန်းသိမ်းရန်, သင်သည်တစ်နေ့တွင်အများအားဖြင့်ကုန်သွယ်ရန်လိုအပ်သည်။	",
    modal_note: "	မှတ်စုများ	",
    modal_note_des_1:
      "	ဖောက်သည်များ၏ကုန်သွယ်ရေးအပြုအမူကိုခွဲခြမ်းစိတ်ဖြာရန်ငွေဖြစ်လွယ်သော algorithm ကိုအသုံးပြုခြင်းသည် SWAP အခမဲ့ပရိုဂရမ်ကိုသင့်လျော်စွာအသုံးချနိုင်အောင်ပြုလုပ်ရန်။	",
    modal_note_des_2:
      "	swap-free status ကိုတိကျသောတူရိယာများအတွက်သာရရှိနိုင်ကြောင်းသတိပြုပါ။	",
    descriptHead:
      "သင်၏ကုန်သွယ်မှုအတွေ့အကြုံကိုမြှင့်တင်ရန်ကျွန်ုပ်တို့၏ပွင့်လင်းမြင်သာမှုနှင့်ယှဉ်ပြိုင်နိုင်စွမ်းရှိသောအစီအစဉ်များကိုစူးစမ်းလေ့လာပါ။ IUX စျေးကွက်များ, သင့်ကုန်သွယ်ရေးရာထူးများကိုစီမံခန့်ခွဲရာတွင်သင့်အားရှင်းလင်းပြတ်သားစွာနှင့်ပြောင်းလွယ်ပြင်လွယ်များကိုသင့်အားထောက်ပံ့ရန်ကြိုးစားသည်။",
    tabPrimaryTitle: "အစ္စလာမ့်မဟုတ်သောနိုင်ငံများ",
    tabSecondaryTitle: "အစ္စလာမ့်နိုင်ငံများ",
    tableDescriptionIsalamic:
      "ရှာရီးယားဥပဒေများကိုလိုက်နာသောအစ္စလာမ်နိုင်ငံများနေထိုင်ရာဒေသများရှိလူများအတွက် IUX စျေးကွက်များသည် SWAP အခမဲ့အကောင့်များကိုထောက်ပံ့ပေးခဲ့သည်။ ကျွန်ုပ်တို့၏အလိုအလျောက်စနစ်သည်ဖောက်သည်များအနေဖြင့်အစ္စလာမ်သည်အစ္စလာမ်သည်အဓိကဘာသာတရားများမှာရှိသည့်နိုင်ငံများ၏နေထိုင်သူများဖြစ်သည်ဟုခွဲခြားသတ်မှတ်သည်။",
    tableDescription:
      "ကျွန်ုပ်တို့၏တန်ဖိုးထားသော 0 န်ဆောင်မှုများအတွက်အစ္စလာမ်မဟုတ်သောနိုင်ငံများရှိကျွန်ုပ်တို့၏တန်ဖိုးရှိသည့် 0 န်ဆောင်မှုများအတွက်ဤဒေသများရှိဖန်တီးထားသောအကောင့်အသစ်များသည်အခမဲ့စီစဉ်ထားသည်။ တက်ကြွသော swap-free status ကိုထိန်းသိမ်းရန်, သင်သည်တစ်နေ့တွင်အများအားဖြင့်ကုန်သွယ်ရန်လိုအပ်သည်။",
    swapConditionsTitle: "လဲလှယ်ရေးအစီအစဉ်များ",
    swapConditionsDescription:
      "ကျွန်ုပ်တို့၏လဲလှယ်ရေးအစီအစဉ်များသည်သင်၏ကုန်သွယ်ရေးကြိုက်နှစ်သက်ရာများကိုလိုက်လျောညီထွေဖြစ်အောင်ဒီဇိုင်းပြုလုပ်ထားသည်။ ကျွန်ုပ်တို့၏ SWAP အခမဲ့အခြေအနေကိုသင်ရွေးချယ်ခြင်းရှိမရှိ, ညတွင်းချင်းစွဲချက်တင်ခြင်းမပြုဘဲရာထူးများထားရှိရန်သို့မဟုတ်ကျွန်ုပ်တို့၏စံပြောင်းရွှေ့အခြေအနေများ၏အကျိုးကျေးဇူးများကိုမြှင့်တင်ရန်ရွေးချယ်ရန်သင့်လျော်သောကုန်သွယ်ရေးမဟာဗျူဟာကိုမြှင့်တင်ရန်ကျွန်ုပ်တို့ရည်ရွယ်သည်။",
    spreadConditionsTitle: "ပြန့်နှံ့သောအခြေအနေများ",
    spreadConditionsDescription:
      "ကျွန်ုပ်တို့၏ယှဉ်ပြိုင်မှုပြန့်ပွားမှုအခြေအနေများသည်သင့်အားစက်မှုလုပ်ငန်းတွင်အကောင်းဆုံးစျေးနှုန်းကိုကမ်းလှမ်းရန်ဖြစ်သည်။ 0.0 pips များအနိမ့်အမြင့်မှစတင်ခြင်း, ကျွန်ုပ်တို့၏ပြန့်ပွားမှုသည်ကုန်ကျစရိတ်သက်သာသည့်ကုန်သွယ်မှု၏အားသာချက်ကိုသင့်တွင်သေချာစေသည်။",
    newAccountTitle: "အကောင့်အသစ်များအတွက် swap အခမဲ့!",
    newAccountDescription:
      "သင်၏ကုန်သွယ်မှုအလားအလာကိုကျွန်ုပ်တို့၏သီးသန့်ကမ်းလှမ်းမှုဖြင့်သော့ဖွင့်ပါ။ အခြားအဘယ်သူမျှမကဲ့သို့ကုန်သွယ်အတွေ့အကြုံအတွက်ယခုကျွန်တော်တို့ကိုပူးပေါင်းပါ။",
  },
  jp: {
    des_swap_islamic:
      "イスラム諸国からのクライアントのみがSWAPのないステータスが利用可能であり、特定の機器に適用できることに注意してください。",
    modal_swap_title: "ステータスを交換します",
    modal_swap_des_non:
      "一晩の料金が適用されます。 非フリーステータスは、トレーディングアクティビティを保持するための一晩の機器によって決定されます。 Swapのないステータスにアップグレードするには、主に1日以内に取引する必要があります。",
    modal_swap_des_free:
      "もう一晩の料金はありません。 スワップフリーステータスは、取引活動によって決定されます。 アクティブなスワップフリーステータスを維持するには、主に1日以内に取引する必要があります。",
    modal_note: "ノート",
    modal_note_des_1:
      "流動性プロバイダーのアルゴリズムを使用して、クライアントの取引行動を分析し、スワップフリープログラムの適切な利用を確保します。",
    modal_note_des_2:
      "スワップフリーステータスは特定の機器にのみ利用できることに注意してください。",
    descriptHead:
      "透明で競争力のあるスワップ＆スプレッド条件を調べて、取引体験を向上させてください。 IUX市場では、取引ポジションの管理に最大限の明確さと柔軟性を提供するよう努めています。",
    tabPrimaryTitle: "非イスラム諸国",
    tabSecondaryTitle: "イスラム諸国",
    tableDescriptionIsalamic:
      "IUX市場は、シャリアの法律を遵守するイスラム諸国の住民にSWAPのないアカウントを提供しています。 私たちの自動化されたシステムは、イスラム教が主要な宗教である国の居住者であり、アカウントをスワップフリーとして指定するクライアントがどのクライアントであるかを特定します。",
    tableDescription:
      "非イスラム国の大切なクライアントの場合、これらの地域で作成されたすべての新しいアカウントは、自動的にスワップフリーステータスに設定されます。 アクティブなスワップフリーステータスを維持するには、主に1日以内に取引する必要があります。",
    swapConditionsTitle: "条件を交換：",
    swapConditionsDescription:
      "スワップ条件は、取引の好みに対応するように設計されています。 スワップのないステータスを選択して、一晩の料金を払わずにポジションを保持できるか、標準スワップ条件の利点を活用することを選択するかどうかにかかわらず、取引戦略を強化することを目指しています。",
    spreadConditionsTitle: "条件の広がり：",
    spreadConditionsDescription:
      "当社の競争力のある普及条件は、業界で最高の価格設定を提供するように調整されています。 0.0ピップという低いピップから始めて、当社のスプレッドは、費用効率の高い取引の利点を確保します。",
    newAccountTitle: "新しいアカウントのためのスワップフリー！",
    newAccountDescription:
      "私たちの排他的なオファーであなたの取引の可能性を解き放つ - スワップ料金はありません。 今すぐご参加ください。",
  },
};
