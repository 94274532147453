import React, { useState, useEffect } from "react";
import { Alert, Divider, Skeleton } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const locale = require("react-redux-i18n").I18n;
const Odpx = (props) => {
  const [prompt, setPrompt] = useState(null);

  useEffect(() => {
    if (!prompt && props.amount > 0) {
      const formData = new FormData();
      formData.append("amount", (props.amount / props.rate).toFixed(2));
      formData.append("amount_thb", props.amount);
      formData.append("account_trade_id", props.account.id);
      const request = {
        amount: (props.amount / props.rate).toFixed(2),
        amount_thb: props.amount,
        account_trade_id: props.account.id,
      };
      const uri = `${process.env.REACT_APP_API_URL}v2/deposit/odpx`;
      var xhr = new XMLHttpRequest();
      xhr.open("POST", uri, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${localStorage.getItem("access_token")}`
      );
      xhr.responseType = "arraybuffer";
      xhr.onload = function (res) {
        if (this.status === 200) {
          var blob = this.response;
          var str = btoa(String.fromCharCode.apply(null, new Uint8Array(blob)));
          // console.log(str)
          setPrompt(`data:image/octet-stream;base64,${str}`);
        }
      };
      xhr.send(JSON.stringify(request));
    }
  }, [props.amount]);
  return (
    <>
      {prompt ? (
        <>
          {/* <Alert
            message={locale.t("deposit_info.prompt.title")}
            description={locale.t("deposit_info.prompt.description")}
            type="info"
          /> */}
          {/* <Divider /> */}
          <div className="text-center">
            <div className="col">
              <img
                src={prompt}
                alt="qr code"
                width="100%"
                style={{ maxWidth: "400px" }}
              />
            </div>
          </div>
          {/* <Divider /> */}
          {/* <Button
              className="btn-primary-outline"
              onClick={() => {
                props.onBackStep();
              }}
            >
              Prev
            </Button> */}
        </>
      ) : (
        <Skeleton.Button
          active={true}
          size={300}
          shape={"square"}
          block={false}
        />
      )}
    </>
  );
};

export default Odpx;
