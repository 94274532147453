import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
const locale = require("react-redux-i18n").I18n;
function RejectVerify(props) {
  const { isReason } = props;

  const history = useHistory();

  return (
    <div className="d-flex justify-content-center my-5 flex-column align-items-center">
      <div className="fs-16"> {locale.t("verificationFailed")}</div>
      <div className="iux-scan  d-flex justify-content-center mt-4">
        <img src="/assets/images/verify/reject.svg" alt="reject" />
      </div>
      <div className="fs-16 mt-4 text-lightgray">{locale.t("sorryverify")}</div>
      <div className="box-reason text-center mt-4 mx-5 flex-column">
        <p>{isReason?.reason_topic?.trim() || "Something went wrong"}</p>
        <p>{isReason?.reason || "Please try agian"}</p>
      </div>
      <div className="mt-2 fs-12 fw-300 text-lightgray text-center">
        {locale.t("needsupport")}
      </div>

      <Button
        className="mt-4 btn-primary-new-iux"
        type="primary"
        onClick={() =>
          history.push(`/${localStorage.getItem("locale")}/wallet/account`)
        }
      >
        <p className=""> {locale.t("uploadAgian")}</p>
      </Button>
    </div>
  );
}

export default RejectVerify;
