import { Col, Row, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { get, post } from "../../../../helper/request";

const ItemSelect = styled.div`
  box-shadow: 0 0 7px #80808033;
  padding: 12px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
  height: 100%;
  &:hover {
    background: #1f73b7;
    color: #fff;
  }
`;
export const postPaycly = ({ amount_thb, account_trade_id, ipaddress }) => {
  return post(`${process.env.REACT_APP_API_URL + "v2/deposit/paycly"}`, {
    amount_thb: amount_thb,
    account_trade_id: account_trade_id,
  });
};

const Paycly = (props) => {
  const selectIp = useSelector(({ user: { ipAddress } }) => ipAddress);
  const { paymentId } = props;
  const lang = localStorage.getItem("locale");
  const refPerfect = useRef(null);
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({
    host: "",
    api_token: "",
    website_id: "",
    action: "",
    price: "",
    curr: "",
    product_name: "",
    fullname: "",
    email: "",
    bill_street_1: "",
    bill_city: "",
    bill_country: "",
    bill_zip: "",
    bill_phone: "",
    id_order: "",
    notify_url: "",
    success_url: "",
    error_url: "",
    cardsend: "",
    source: "",
  });

  useEffect(() => {
    onFinish();
  }, [selectIp]);
  const onFinish = async (el) => {
    try {
      setLoading(true);
      const payload = {
        account_trade_id: props.account.id,
        amount_thb: +props.amount,
      };
      const { data } = await postPaycly(payload);

      if (data) {
        setLoading(true);

        await Promise.all([
          setPaymentData({
            host: data.data.host,
            api_token: data.data.api_token,
            website_id: data.data.website_id,
            action: data.data.action,
            price: data.data.price,
            curr: data.data.curr,
            product_name: data.data.product_name,
            fullname: data.data.fullname,
            email: data.data.email,
            bill_street_1: data.data.bill_street_1,
            bill_city: data.data.bill_city,
            bill_country: data.data.bill_country,
            bill_zip: data.data.bill_zip,
            bill_phone: data.data.bill_phone,
            id_order: data.data.id_order,
            notify_url: data.data.notify_url,
            success_url: data.data.success_url,
            error_url: data.data.error_url,
            cardsend: data.data.cardsend,
            source: data.data.source,
          }),
        ]).then(() => {
          refPerfect.current.click();
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Spin spinning={loading}>
      <form
        id="paymentForm"
        method="post"
        action="https://portal.online-epayment.com/checkout.do"
      >
        ​
        <input type="hidden" name="api_token" value={paymentData.api_token} />
        <input type="hidden" name="website_id" value={paymentData.website_id} />
        <input type="hidden" name="action" value={paymentData.action} />
        <input type="hidden" name="price" value={paymentData.price} />
        <input type="hidden" name="curr" value={paymentData.curr} />
        <input
          type="hidden"
          name="product_name"
          value={paymentData.product_name}
        />
        <input type="hidden" name="fullname" value={paymentData.fullname} />
        <input type="hidden" name="email" value={paymentData.email} />
        <input
          type="hidden"
          name="bill_street_1"
          value={paymentData.bill_street_1}
        />
        <input type="hidden" name="bill_city" value={paymentData.bill_city} />
        <input
          type="hidden"
          name="bill_country"
          value={paymentData.bill_country}
        />
        <input type="hidden" name="bill_zip" value={paymentData.bill_zip} />
        <input type="hidden" name="bill_phone" value={paymentData.bill_phone} />
        <input type="hidden" name="id_order" value={paymentData.id_order} />
        <input type="hidden" name="notify_url" value={paymentData.notify_url} />
        <input
          type="hidden"
          name="success_url"
          value={paymentData.success_url}
        />
        <input type="hidden" name="error_url" value={paymentData.error_url} />
        <input type="hidden" name="cardsend" value={paymentData.cardsend} />
        <input type="hidden" name="source" value={paymentData.source} />
        <input
          ref={refPerfect}
          type="submit"
          name="Submit"
          value="Submit"
          hidden
        />
      </form>
    </Spin>
  );
};

export default Paycly;
