import { Col, Row, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { get, post } from "../../../../helper/request";

const ItemSelect = styled.div`
  box-shadow: 0 0 7px #80808033;
  padding: 12px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
  height: 100%;
  text-align: center;
  &:hover {
    background: #169376;
    color: #fff;
    box-shadow: 0 0 12px #169376;
  }
`;
export const postDirecta24 = ({
  amount_thb,
  account_trade_id,
  payment_id,
  bank,
  ip,
}) => {
  return post(`${process.env.REACT_APP_API_URL + "v2/deposit/directa24"}`, {
    amount_thb: amount_thb,
    account_trade_id: account_trade_id,
    payment_id: payment_id,
    bank: bank,
    // ip: ip.toString().replace("\n", ""),
  });
};

export const getDirecta24 = () => {
  return get(`${process.env.REACT_APP_API_URL + "v2/methods/directa24"}`);
};

const Directa24 = (props) => {
  const selectIp = useSelector(({ user: { ipAddress } }) => ipAddress);
  const { paymentId } = props;

  const [linkPay, setLinkPay] = useState(null);
  const refPerfect = useRef(null);
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({
    banks: [],
    amount: 0,
    backURI: "",
    bank: "",
    currency: "",
    customer: "",
    datetime: "",
    frontURI: "",
    key: "",
    language: "",
    note: "",
    reference: "",
    host: "",
    clientIP: selectIp,
    host: "",
  });
  useEffect(() => {
    fectchBank();
  }, []);
  const fectchBank = async () => {
    try {
      const { data } = await getDirecta24();
      setLoading(true);

      setPaymentData((prev) => ({
        ...prev,
        banks: data.data,
      }));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const onFinish = async (el) => {
    setLoading(true);
    const payload = {
      account_trade_id: props.account.id,
      amount_thb: +props.amount,
      payment_id: paymentId,
      bank: el,
      // ip: selectIp,
    };
    const { data } = await postDirecta24(payload);
    if (data) {
      // setPaymentData((prev) => ({
      //   ...prev,
      //   host: data.host,
      //   merchant: data.merchant,
      //   amount: data.amount,
      //   backURI: data.backURI,
      //   bank: data.bank,
      //   currency: data.currency,
      //   customer: data.customer,
      //   datetime: data.datetime,
      //   frontURI: data.frontURI,
      //   key: data.key,
      //   language: data.language,
      //   note: data.note,
      //   reference: data.reference,
      //   clientIP: data.clientIP,
      //   host: data.host,
      // }));
      await Promise.all([setLinkPay(data?.data?.redirect_url)]).then(() => {
        refPerfect.current.click();
      });
      setLoading(false);
      // refPerfect.current.click();
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="row">
        {paymentData?.banks.map((e, i) => (
          <div className="col-12 col-lg-4 px-1">
            <ItemSelect onClick={() => onFinish(e.code)}>
              <div>
                <img src={e.logo} alt={e.name} width={40} />
              </div>
              <div>{e.name}</div>
            </ItemSelect>
          </div>
        ))}
      </div>
      {/* <form method="POST" action={paymentData.host}>
        <p>
          <input type="hidden" name="Merchant" value={paymentData.merchant} />
          <input type="hidden" name="Currency" value={paymentData.currency} />
          <input type="hidden" name="Customer" value={paymentData.customer} />
          <input type="hidden" name="Reference" value={paymentData.reference} />
          <input type="hidden" name="Key" value={paymentData.key} />
          <input type="hidden" name="Amount" value={paymentData.amount} />
          <input type="hidden" name="Note" value={paymentData.note} />
          <input type="hidden" name="Datetime" value={paymentData.datetime} />
          <input type="hidden" name="FrontURI" value={paymentData.frontURI} />
          <input type="hidden" name="BackURI" value={paymentData.backURI} />
          <input type="hidden" name="Language" value={paymentData.language} />
          <input type="hidden" name="Bank" value={paymentData.bank} />
          <input type="hidden" name="ClientIP" value={paymentData.clientIP} />
          <input
            ref={refPerfect}
            type="submit"
            value="help2pay account"
            hidden
          />
        </p>
      </form> */}
      <a ref={refPerfect} href={linkPay} hidden>
        Fasapay
      </a>
    </Spin>
  );
};

export default Directa24;
