import React, { useState, useEffect } from "react";
import { Divider, Button, Form, Input, Row, Spin, Result, Select } from "antd";
import styled from "styled-components";
import { connect } from "react-redux";
import { post } from "../../../../helper/request";
import PromptPayForm from "./PrompayForm";
import PaypalButton from "./Paypal";
import PerfetcMoney from "./PerfetctMoney";
import Payxspace from "./Payxspace";
import PayTrust from "./Paytrust";
import PaymentAsia from "./PaymentAsia";
import PayAlphapo from "./PayAlphapo";
import EasyFastPayForm from "./EasyFastForm";
import B2binpay from "./B2binpay";
import Odpx from "./Odpx";
import Tazapay from "./Tazapay";
import Help2pay from "./Help2pay";
import Transact365 from "./Transact365";
import { Link } from "react-router-dom";
import Alogateway from "./Alogateway";
import Fasapay from "./Fasapay";
import Monetix from "./Monetix";
import Echelon from "./Echelon";
import Paycly from "./Paycly";
import Moneyspace from "./Moneyspace";
import MyanPay from "./MyanPay";
import Directa24 from "./Directa24";
import Hoventon from "./Hoventon";
import Sticpay from "./Sticpay";
import Wealthpay from "./Wealthpay";
import { CheckCerrency } from "../../../../components/checkcurrency";
import Paysolution from "./Paysolution";
import NewEchelon from "./NewEchelon";
import NowPayment from "./NowPayment";

const locale = require("react-redux-i18n").I18n;
const { Option } = Select;
const ResultAmountStyled = styled.div`
  border: unser;
  border-radius: 12px;
  display: inline-block;
  width: 100%;
  background: #f2f2f2;
  width: 100%;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

// const Select = styled.select`
//   width: 100%;
//   height: 48px;
//   border: 1px solid #80808061;
//   outline: none;
//   border-radius: 8px;
//   padding-left: 16px;
//   padding-right: 16px;
// `;

function DepositSecondStep(props) {
  const { paymentId, paymentKey } = props;
  const [amount, setAmount] = useState(0);
  const [accountSelected, setAccountSelected] = useState(null);
  const [account, setAccount] = useState("");
  const [countryId, setCountryId] = useState(null);
  const [rate, setRate] = useState(0);
  const [alphaSymbol, setAlphaSymbol] = useState([]);
  const [step, setStep] = useState(0);
  const [suffix, setSuffix] = useState("");
  const [prefix, setPrefix] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setAccount(props.account.account_type);
    setAccountSelected(props.account.id);
    setCountryId(props.user.profile.country_id);
    setAmount(props.amount);
    setLoading(false);
  }, []);

  const Paypal = (props) => <PaypalButton {...props} />;

  const Skrill = (props) => {
    return (
      <>
        <Result title={locale.t("error_deposit.text_1")} />
      </>
    );
  };
  const Neteller = (props) => {
    return (
      <>
        <Result title={locale.t("error_deposit.text_1")} />
      </>
    );
  };

  const renderPayform = () => {
    switch (paymentKey) {
      case "laos_bank":
        return (
          <EasyFastPayForm
            paymentId={paymentId}
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
            rate={props.rate}
          />
        );
      case "thai_qr_code":
        return (
          <PromptPayForm
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
            rate={props.rate}
          />
        );
      case "hoventon":
        return (
          <Hoventon
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
            rate={props.rate}
          />
        );
      case "odpx":
        return (
          <Odpx
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
            rate={props.rate}
          />
        );
      case "paysolution":
        return (
          <Paysolution
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
            rate={props.rate}
          />
        );
      case "paypal":
        return (
          <Paypal
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
          />
        );
      case "payment_asia":
        return (
          <PaymentAsia
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            paymentId={paymentId}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
          />
        );
      case "perfect_money":
        return (
          <PerfetcMoney
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
          />
        );
      case "payxspace":
        return (
          <Payxspace
            paymentId={paymentId}
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
          />
        );
      case "tazapay":
        return (
          <Tazapay
            paymentId={paymentId}
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
          />
        );
      case "help2pay":
        return (
          <Help2pay
            paymentId={paymentId}
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
          />
        );
      case "directa24":
        return (
          <Directa24
            paymentId={paymentId}
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
          />
        );
      case "wealthpay":
        return (
          <Wealthpay
            paymentId={paymentId}
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
          />
        );
      case "alogateway":
        return (
          <Alogateway
            paymentId={paymentId}
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
          />
        );
      case "paycly":
        return (
          <Paycly
            paymentId={paymentId}
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
          />
        );
      case "moneyspace":
        return (
          <Moneyspace
            paymentId={paymentId}
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
          />
        );
      case "myanpay":
        return (
          <MyanPay
            paymentId={paymentId}
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
          />
        );
      case "fasapay":
        return (
          <Fasapay
            paymentId={paymentId}
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
          />
        );
      case "nowpayment":
        return (
          <NowPayment
            paymentId={paymentId}
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
          />
        );
      case "echelon":
        return (
          <div>
            {props.user.profile.country_id === 6 ? (
              <NewEchelon
                paymentId={paymentId}
                account={props.account}
                amount={props.amount}
                paymentKey={paymentKey}
                onBackStep={props.onBackStep}
                onSuccess={props.onSuccess}
              />
            ) : (
              <div>
                <Echelon
                  paymentId={paymentId}
                  account={props.account}
                  amount={props.amount}
                  paymentKey={paymentKey}
                  onBackStep={props.onBackStep}
                  onSuccess={props.onSuccess}
                />
              </div>
            )}
          </div>
        );

      case "monetix":
        return (
          <Monetix
            paymentId={paymentId}
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
          />
        );
      case "sticpay":
        return (
          <Sticpay
            paymentId={paymentId}
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
          />
        );

      // case "directa24":
      //   return (
      //     <Directa24
      //       paymentId={paymentId}
      //       account={props.account}
      //       amount={props.amount}
      //       paymentKey={paymentKey}
      //       onBackStep={props.onBackStep}
      //       onSuccess={props.onSuccess}
      //     />
      //   );
      // case "alphapo":
      //   return (
      //     <PayAlphapo
      //       account={props.account}
      //       amount={props.amount}
      //       amountUsd={returnResultAmount()}
      //       paymentKey={paymentKey}
      //       currency={prefix}
      //       onBackStep={props.onBackStep}
      //       onSuccess={props.onSuccess}
      //     />
      //   );
      case "paytrust":
        return (
          <PayTrust
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            paymentId={paymentId}
            amountUsd={(amount / parseFloat(props.rate)).toFixed(2)}
            prefix={prefix}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
          />
        );
      case "skrill":
        return (
          <Skrill
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            paymentId={paymentId}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
          />
        );
      case "netteller":
        return (
          <Neteller
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            paymentId={paymentId}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
          />
        );
      case "transact365":
        return (
          <Transact365
            account={props.account}
            amount={props.amount}
            paymentKey={paymentKey}
            paymentId={paymentId}
            onBackStep={props.onBackStep}
            onSuccess={props.onSuccess}
          />
        );
      default:
        return;
    }
  };

  return (
    <Spin spinning={loading} style={{ padding: 40 }}>
      <div>
        {renderPayform()}

        {paymentKey !== "laos_bank" && (
          <>
            <div className="my-2">
              {locale.t("deposit_info.payment.amount")}:
              <b>
                &nbsp;{amount}&nbsp;
                {CheckCerrency(props?.user?.profile?.country_id)?.iso}
              </b>
            </div>
            <Link
              to={{
                pathname: `/${localStorage.getItem("locale")}/wallet/`,
              }}
              className="w-100"
            >
              <Button
                className="btn-primary-new-iux w-100 ml-1"
                // onClick={props.onNextStepPayment}
              >
                {locale.t("deposit_step.third_step.go_account")}
              </Button>
            </Link>
          </>
        )}
      </div>
    </Spin>
  );
}

const mapStateToProps = ({ user, i18n }) => {
  return { user, locale: i18n.locale };
};

export default connect(mapStateToProps, null)(DepositSecondStep);
