import { get, put, puts } from "../helper/request";

export const getProvinceList = async () => {
  return get(`${process.env.REACT_APP_API_URL}v2/master/province`);
};
export const getBankList = async () => {
  return get(`${process.env.REACT_APP_API_URL}v2/master/bank`);
};
export const getCareerList = async () => {
  return get(`${process.env.REACT_APP_API_URL}v2/master/career`);
};
export const getUserValidation = async () => {
  return get(`${process.env.REACT_APP_API_URL}v2/user/validation`);
};
export const getUserAttempt = async () => {
  return get(`${process.env.REACT_APP_API_URL}v2/ocr/attempt`);
};
export const putUserAttempt = async (data) => {
  return puts(`${process.env.REACT_APP_API_URL}v2/ocr/attempt`, data);
};
