import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Upload,
} from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { get, post } from "../../../../helper/request";
import { InboxOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { CheckCerrency } from "../../../../components/checkcurrency";
import { useSelector } from "react-redux";

const locale = require("react-redux-i18n").I18n;
const { Dragger } = Upload;

const EasyFastPayForm = (props) => {
  const { paymentId } = props;
  const user = useSelector((state) => state.user);
  const profileCountry = useSelector((state) => state.user.profileCountry);
  const [form] = Form.useForm();
  const [bank, setBank] = useState(null);
  const [visible, setVisible] = useState(false);
  const [slip, setSlip] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [step, setStep] = useState(0);
  useEffect(() => {
    // if (!bank) {
    get(process.env.REACT_APP_API_URL + "v2/deposit/bank").then((res) => {
      setBank(res.data[0]);
    });
    // }
  }, []);

  const draggerOption = {
    name: "file_image",
    onRemove: (file) => {
      setSlip(null);
    },
    beforeUpload: (file) => {
      setSlip(file);
      return false;
    },
    slip,
  };

  function disabledDateTime() {
    // return {
    //   disabledHours: () => range(0, 24).splice(4, 20),
    //   disabledMinutes: () => range(30, 60),
    //   disabledSeconds: () => [55, 56],
    // };
  }

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      const formDataPayment = new FormData();
      formDataPayment.append("account_trade_id", props.account.id);
      formDataPayment.append("method", bank.bank);
      formDataPayment.append("amount_thb", props.amount);
      formDataPayment.append("amount", (props.amount / props.rate).toFixed(2));
      formDataPayment.append(
        "user_amount",
        (props.amount / props.rate).toFixed(2)
      );
      formDataPayment.append("date_payment", values.upload_date);
      formDataPayment.append("bank_account_number", values.account_number);
      formDataPayment.append("transfer_slip", values.dragger.file);
      formDataPayment.append("payment_id", paymentId);

      setConfirmLoading(true);
      post(
        `${process.env.REACT_APP_API_URL}v2/user/account/deposit`,
        formDataPayment
      )
        .then((res) => {
          props.onSuccess({
            message: {
              title: locale.t("deposit_info.success.title"),
              description: locale.t("deposit_info.success.description"),
            },
          });
          setConfirmLoading(false);
          setVisible(false);
        })
        .catch((err) => {
          setConfirmLoading(false);
          console.log(err);
          // message.error(err.response.data.message);
        });
    } catch (errorInfo) {
      setConfirmLoading(false);
      message.error(errorInfo.message);
    }
  };
  const laos = localStorage.getItem("setLocale");
  const windowIner = window.innerWidth;
  return (
    <>
      {bank ? (
        <>
          {step === 0 ? (
            <>
              {" "}
              <div className="fs-14 font-weight-bold mb-3">3.Pay</div>
              <div className="fs-16 mb-3">BECL BANK</div>
              <div className="mb-3 text-gray">
                Bank Name:{" "}
                <span style={{ color: "#000" }}>
                  <b> {bank.bank || `undefined`}</b>
                </span>
              </div>
              <div className="mb-3 text-gray">
                Account Name:{" "}
                <span style={{ color: "#000" }}>
                  <b> {bank.owner || `undefined`}</b>
                </span>
              </div>
              <div className="mb-3 text-gray">
                Account Number:{" "}
                <span style={{ color: "#000" }}>
                  <b> {bank.account_number || `undefined`}</b>{" "}
                  <CopyToClipboard text={bank.account_number}>
                    <img
                      src="/assets/images/icons/content-copy.svg"
                      alt="copy"
                      className="cursor-pointer"
                    />
                  </CopyToClipboard>
                </span>
              </div>
              <div className="text-gray">QR Code</div>
              <div>
                <img
                  src={`https://admin.iuxmarket.com/${bank.qr_code}`}
                  alt={"bank"}
                  width={200}
                  height={"auto"}
                />
              </div>
              <div className="mb-3 mt-3 text-gray">
                Amount:
                <span style={{ color: "#000" }}>
                  <b>
                    {" "}
                    {props.amount} {profileCountry?.symbol}
                  </b>
                </span>
              </div>
              <div className="mb-3 text-gray">
                You receive:
                <span style={{ color: "#000" }}>
                  <b>
                    {`${(props.amount / props.rate).toFixed(2)}
                (USD)`}
                  </b>
                </span>
              </div>
              <div style={{ display: step === 1 ? "none" : "block" }}>
                <>
                  <Divider />
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      // padding: "0rem 1rem",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      className="btn-outline-iux w-100 mr-1"
                      style={{ float: "left" }}
                      onClick={() => props.onBackStep()}
                    >
                      {locale.t("prev")}
                    </Button>
                    <Button
                      className="btn-primary-new-iux w-100"
                      onClick={() => {
                        setVisible(true);
                        setStep(1);
                      }}
                    >
                      {locale.t("next")}
                    </Button>
                  </div>
                </>
              </div>
            </>
          ) : (
            <div>
              <div className="fs-14 font-weight-bold mb-3">4.Confirm</div>
              <Form
                form={form}
                name="modal_deposit"
                className="user"
                onFinish={onFinish}
              >
                <div>
                  <div className="fs-16 mb-3">BECL BANK</div>
                  <div className="mb-3 text-gray">
                    Currency:{" "}
                    <span style={{ color: "#000" }}>
                      <b> {profileCountry?.symbol}</b>
                    </span>
                  </div>
                  <div className="mb-3 text-gray">
                    {locale.t("deposit_info.mt_account")}:{" "}
                    <span style={{ color: "#000" }}>
                      <b> {props.account.account_number}</b>
                    </span>
                  </div>
                  <div className="mb-3 text-gray">
                    {locale.t("deposit_info.bank.number")}:{" "}
                  </div>
                  <Form.Item
                    name="account_number"
                    rules={[
                      {
                        required: true,
                        message: locale.t("deposit_error.input"),
                      },
                    ]}
                  >
                    <Input placeholder="1234567890" />
                  </Form.Item>
                  <div className="mb-3 text-gray">
                    {locale.t("deposit_info.payment.input_date")}:{" "}
                  </div>
                  <Form.Item
                    name="upload_date"
                    rules={[
                      {
                        type: "object",
                        required: true,
                        message: locale.t("deposit_error.input"),
                      },
                    ]}
                  >
                    <DatePicker
                      format="YYYY-MM-DD HH:mm:ss"
                      disabledTime={disabledDateTime}
                      showTime={{ value: moment("00:00:00", "HH:mm:ss") }}
                    />
                  </Form.Item>
                  <div className="mb-3 text-gray">
                    {locale.t("deposit_info.payment.amount")}:{" "}
                    <span style={{ color: "#000" }}>
                      <b>
                        {props.amount} {profileCountry?.symbol}
                      </b>
                    </span>
                  </div>
                  <div className="text-gray mb-2">
                    {locale.t("deposit_info.payment.input_file_description")}
                  </div>
                  <Form.Item
                    name="dragger"
                    rules={[
                      {
                        type: "object",
                        required: true,
                        message: locale.t("deposit_error.input"),
                      },
                    ]}
                  >
                    <Dragger {...draggerOption} className="user">
                      <div
                        className="text-gray d-flex align-items-center justify-content-center flex-column"
                        style={{ height: 86, borderRadius: 8 }}
                      >
                        {/* <InboxOutlined /> */}
                        <img
                          src="/assets/images/icons/feather-upload.svg"
                          alt="upload"
                        />
                        <div className="text-gray">Upload Slip Here.</div>
                      </div>
                    </Dragger>
                  </Form.Item>{" "}
                  {/* <Divider />
                  <Alert
                    message={locale.t("deposit_info.attention.title")}
                    description={locale.t("deposit_info.attention.description")}
                    type="error"
                  /> */}
                  <div className="d-flex">
                    <Button
                      onClick={() => setStep(0)}
                      className="btn-outline-iux w-100 mr-1"
                    >
                      {locale.t("prev")}
                    </Button>

                    <Button
                      loading={confirmLoading}
                      className="btn-primary-new-iux w-100"
                      // onClick={onFinish}
                      htmlType="submit"
                    >
                      {locale.t("register_button_text")}
                    </Button>
                  </div>
                  {/* <div className="user p-2 p-md-4">
                    <Descriptions
                      title={locale.t("deposit_info.confirm")}
                      bordered
                    >
                      <Descriptions.Item
                        span={3}
                        label={locale.t("deposit_info.symbol")}
                      >
                        <p>{laos === "lo" ? "LAK" : "THB"}</p>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={3}
                        label={locale.t("deposit_info.mt_account")}
                      >
                        {props.account.account_number}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={3}
                        label={locale.t("deposit_info.bank.number")}
                      ></Descriptions.Item>
                      <Descriptions.Item
                        span={3}
                        label={locale.t("deposit_info.payment.input_date")}
                      >
                        <Form.Item
                          name="upload_date"
                          rules={[
                            {
                              type: "object",
                              required: true,
                              message: locale.t("deposit_error.input"),
                            },
                          ]}
                        >
                          <DatePicker
                            format="YYYY-MM-DD HH:mm:ss"
                            disabledTime={disabledDateTime}
                            showTime={{ value: moment("00:00:00", "HH:mm:ss") }}
                          />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={3}
                        label={locale.t("deposit_info.payment.input_file")}
                      >
                        <p>
                          {locale.t(
                            "deposit_info.payment.input_file_description"
                          )}
                        </p>
                        <Form.Item
                          name="dragger"
                          rules={[
                            {
                              type: "object",
                              required: true,
                              message: locale.t("deposit_error.input"),
                            },
                          ]}
                        >
                          <Dragger {...draggerOption}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                          </Dragger>
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={3}
                        label={locale.t("deposit_info.payment.amount")}
                      >
                        <p>
                          {props.amount} {laos === "lo" ? "LAK" : "THB"}
                        </p>
                      </Descriptions.Item>
                    </Descriptions>
                  </div> */}
                </div>
              </Form>
            </div>
          )}
        </>
      ) : (
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      )}
    </>
  );
};

export default EasyFastPayForm;
