import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

var firebaseConfig = {
  apiKey: "AIzaSyB95BsQbwpMZQA3P4uUqCHWlnR8Js6PuuE",
  authDomain: "test-noti-c2432.firebaseapp.com",
  projectId: "test-noti-c2432",
  storageBucket: "test-noti-c2432.appspot.com",
  messagingSenderId: "740561725932",
  appId: "1:740561725932:web:dbb0055a4fddc4f519b8c9",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      "BML3G50z3pP68v4rnzeZ_da1MUxy7jIpQPfuNzA7UyBFWmUtLihRs1b0NC6d6GcO2kM2jxRU7Ey-uLwkzupD8tA",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log(currentToken);
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log(payload, "payload");
      resolve(payload);
    });
  });
