import React from "react";
import { Link } from "react-router-dom";
import { Divider } from "antd";
import {
  styleFontTop,
  styleFontCurrent,
  styleHeader,
  styleBottom,
  styleSpread,
  styleBuy,
  styleSell,
  colorBit,
  colorAsk,
  styleBackground,
  TextGray,
  styleBitAsk,
  styleFontCurrentInt,
  TextSpead,
  styleClose,
  styleSpreadDashboard,
  styleBuyDashboard,
  styleSellDashboard,
  styleCloseDashboard,
} from "./style";
import { GlobalOutlined } from "@ant-design/icons";
const AllSymbol = ({ data, islayout }) => {
  const bidPrice = (data) => {
    let bidarr = data.b.toFixed(data.d).toString().substr(-3);
    if (data.d <= 2) {
      bidarr = data.b.toFixed(data.d).toString().substr(-2);
    }
    let bidarrr = data.b.toFixed(data.d).replace(bidarr, "");
    return (
      <div
        className="d-flex align-items-baseline justify-center"
        style={colorBit}
      >
        <div style={styleFontTop}>{bidarrr}</div>
        <div className="d-flex">
          <span style={styleFontCurrent}>{bidarr.toString().substr(0, 2)}</span>
          <div style={styleFontCurrentInt}>
            {data.d > 2 && bidarr.toString().substr(2, 3)}
          </div>
        </div>
      </div>
    );
  };

  const askPrice = (data) => {
    let askarr = data.a.toFixed(data.d).toString().substr(-3);
    if (data.d <= 2) {
      askarr = data.a.toFixed(data.d).toString().substr(-2);
    }
    let askarrr = data.a.toFixed(data.d).replace(askarr, "");
    return (
      <div
        className="d-flex align-items-baseline justify-center"
        style={colorAsk}
      >
        <div style={styleFontTop}>{askarrr}</div>
        <div className="d-flex">
          <span style={styleFontCurrent}>{askarr.toString().substr(0, 2)}</span>
          <div style={styleFontCurrentInt}>
            {data.d > 2 && askarr.toString().substr(2, 3)}
          </div>
        </div>
      </div>
    );
  };

  const spreadPrice = (data) => {
    let spread = data.a - data.b;
    let a = Math.pow(10, data.d - 1);
    let final = spread * a;
    return (
      <div className="ml-1 text-right" style={{ width: 32 }}>
        {final.toFixed(1)}
      </div>
    );
  };
  const addDefaultSrc = (ev) => {
    ev.target.src = `/assets/images/icons/${
      islayout === "dashboard" ? "globe-black" : "globe"
    }.svg`;
  };
  return (
    <>
      {data?.map((item, index) => (
        <div
          className="col-12 col-sm-6 col-md-6 col-lg-3"
          key={item.display + index}
        >
          <Link
            className={islayout === "dashboard" ? "text-black" : "text-white"}
            to={`/${localStorage.getItem("locale")}${
              islayout === "dashboard" ? "/wallet" : ""
            }/spread/symbol/analyze/${item.display}`}
          >
            <div
              className={`w-100 ${
                islayout === "dashboard"
                  ? "bg-spreads-box-dashboard"
                  : "bg-spreads-box"
              } border-round-16 mb-2 p-0`}
            >
              <div className="p-3 p-lg-2 p-md-2 box-market-landingpage">
                <div
                  className="d-flex justify-content-between p-2"
                  // style={styleHeader}
                >
                  <div className="d-flex align-items-center">
                    <img
                      onError={addDefaultSrc}
                      src={`${
                        process.env.REACT_APP_SYMBOL
                      }symbol/${item.display?.toLowerCase()}`}
                      width={30}
                      alt={item.s}
                      style={{ marginRight: 8 }}
                      className="rounded-circle"
                    />
                    &nbsp;
                    {item?.s}
                  </div>
                </div>

                <div
                  className="d-flex justify-content-between pl-3 pr-3"
                  // style={styleBackground}
                >
                  <div className="w-100">
                    <div style={styleBitAsk}>Bid</div>
                    {bidPrice(item)}
                  </div>

                  <div className="w-100">
                    <div style={styleBitAsk} className="ml-2">
                      Ask
                    </div>
                    {askPrice(item)}
                  </div>
                </div>

                <div
                  className="d-flex justify-content-between"
                  // style={styleBottom}
                >
                  <div className="w-100">
                    {islayout === "dashboard" ? (
                      <div
                        className=" d-flex justify-content-between mr-1 btn-outline-iux"
                        style={styleSpreadDashboard}
                      >
                        Spread
                        {spreadPrice(item)}
                      </div>
                    ) : (
                      <div
                        className="text-white d-flex justify-content-between mr-1"
                        style={styleSpread}
                      >
                        <div className="text-gray" style={TextGray}>
                          Spread
                        </div>
                        <div className="text-right" style={TextSpead}>
                          {spreadPrice(item)}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="w-100">
                    <div className="d-flex justify-content-between ml-1 ">
                      {item.dt !== 0 ? (
                        <>
                          <div className="" />
                          <Link to={`/${localStorage.getItem("locale")}/login`}>
                            {islayout === "dashboard" ? (
                              <button
                                style={styleBuyDashboard}
                                className="btn-primary-new-iux"
                              >
                                Buy
                              </button>
                            ) : (
                              <div style={styleBuy}>Buy</div>
                            )}
                          </Link>
                          <Link to={`/${localStorage.getItem("locale")}/login`}>
                            {islayout === "dashboard" ? (
                              <div
                                className="btn-outline-iux"
                                style={styleSellDashboard}
                              >
                                Sell
                              </div>
                            ) : (
                              <div style={styleSell}>Sell</div>
                            )}
                          </Link>
                        </>
                      ) : (
                        <>
                          {islayout === "dashboard" ? (
                            <div
                              className="btn-outline-iux"
                              style={styleCloseDashboard}
                            >
                              Market Close
                            </div>
                          ) : (
                            <div style={styleClose}>Market Close</div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </>
  );
};

export default AllSymbol;
