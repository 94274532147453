import Lotback from "./Lotback";
import Bonus from "./Bonus";
import Navbar from "./Navbar";
import LadningPage from "./LandingPage";
import Footer from "./Footer";
import SocialTrade from "./SocialTrade";
import Partner from "./Partner";
import Partner2 from "./Partner2";
import Futures from "./Futures";
import Contact from "./Contact";
import Login from "./Login";
import Help from "./Help";
import Register from "./Register";
import PlatformIphone from "./PlatformIphone";
import PlatformAndroid from "./PlatformAndroid";
import PlatformMac from "./PlatformMac";
import PlatformWindows from "./PlatformWindows";
import PlatformMt4 from "./PlatformMt4";
import Deposit from "./Deposit";
import AboutMe from "./AboutMe";
import Condition from "./Condition";
import Security from "./Security";
import Policy from "./Policy";
import TermCondition from "./TermCondition";
import MenuWallet from "./MenuWallet";
import Dashboard from "./Dashboard";
import Pdf from "./Pdf";
import WithDraw from "./WithDraw";
import Commission from "./Commission";
import Account from "./Account";
import Setting from "./Settig";
import Promote from "./Promote";
import PaymentOption from "./PaymentOption";
import GuidelinePartner from "./GuidelinePartner";
import ContactUs from "./ContactUs";
import Verify from "./Verify";
import ChangePassword from "./ChangePassword";
import ChangePhone from "./ChangePhone";
import Swap from "./Swap";
import Platform from "./Platform";
import Calendar from "./Calendar";
import Analytics from "./Analytics";
import Instrument from "./Instrument";
// LIST FILE TRANSLATION
export const translationsObject = {
  th: {
    ...Instrument.th,
    ...Analytics.th,
    ...Calendar.th,
    ...Platform.th,
    ...ChangePhone.th,
    ...ChangePassword.th,
    ...Verify.th,
    ...ContactUs.th,
    ...GuidelinePartner.th,
    ...Account.th,
    ...Navbar.th,
    ...LadningPage.th,
    ...Footer.th,
    ...SocialTrade.th,
    ...Partner.th,
    ...Partner2.th,
    ...Futures.th,
    ...Contact.th,
    ...Login.th,
    ...Register.th,
    ...PlatformIphone.th,
    ...PlatformAndroid.th,
    ...PlatformMac.th,
    ...PlatformWindows.th,
    ...Deposit.th,
    ...PlatformMt4.th,
    ...AboutMe.th,
    ...Condition.th,
    ...Security.th,
    ...Policy.th,
    ...TermCondition.th,
    ...MenuWallet.th,
    ...Dashboard.th,
    ...Pdf.th,
    ...WithDraw.th,
    ...Commission.th,
    ...Bonus.th,
    ...Lotback.th,
    ...Help.th,
    ...Setting.th,
    ...Promote.th,
    ...PaymentOption.th,
    ...Swap.th,
    next: "ถัดไป",
    prev: "ย้อนกลับ",
    goconsole: "กลับไปหน้าหลัก",
    comming_soon: "ฟีเจอร์ยังไม่พร้อมใช้งาน",
    click_send: "ส่ง",
    validate_email: "กรุณายืนยันอีเมลของคุณ",
    validate_email_resend: "ส่งอีเมลเพื่อยืนยันอีกครั้ง",
    validate_email_seccuss: "กรุณาตรวจสอบ Email ของคุณอีกครั้ง",
  },
  la: {
    ...Instrument.la,
    ...Analytics.la,
    ...Calendar.la,
    ...Platform.la,
    ...ChangePhone.la,
    ...ChangePassword.la,
    ...Verify.la,
    ...ContactUs.la,
    ...GuidelinePartner.la,
    ...Account.la,
    ...Navbar.la,
    ...LadningPage.la,
    ...Footer.la,
    ...SocialTrade.la,
    ...Partner2.la,
    ...Partner.la,
    ...Futures.la,
    ...Contact.la,
    ...Login.la,
    ...Register.la,
    ...PlatformIphone.la,
    ...PlatformAndroid.la,
    ...PlatformMac.la,
    ...PlatformWindows.la,
    ...Deposit.la,
    ...PlatformMt4.la,
    ...AboutMe.la,
    ...Condition.la,
    ...Security.la,
    ...Policy.la,
    ...TermCondition.la,
    ...MenuWallet.la,
    ...Dashboard.la,
    ...Pdf.la,
    ...WithDraw.la,
    ...Commission.la,
    ...Bonus.la,
    ...Lotback.la,
    ...Help.la,
    ...Setting.la,
    ...Promote.la,
    ...PaymentOption.la,
    ...Swap.la,
    next: "ຕໍ່ໄປ",
    prev: "ກັບໄປ",
    goconsole: "ໄປໜ້າທຳອິດ",
    comming_soon: "ฟีเจอร์ยังไม่พร้อมใช้งาน",
    click_send: "Send",
    validate_email: "Please verify your email",
    validate_email_resend: "Send email to verify again.",
    validate_email_seccuss: "Please check your email again.",
  },
  en: {
    ...Instrument.en,
    ...Analytics.en,
    ...Calendar.en,
    ...Platform.en,
    ...ChangePhone.en,
    ...ChangePassword.en,
    ...Verify.en,
    ...ContactUs.en,
    ...GuidelinePartner.en,
    ...Account.en,
    ...Navbar.en,
    ...LadningPage.en,
    ...Footer.en,
    ...SocialTrade.en,
    ...Partner2.en,
    ...Partner.en,
    ...Futures.en,
    ...Contact.en,
    ...Login.en,
    ...Register.en,
    ...PlatformIphone.en,
    ...PlatformAndroid.en,
    ...PlatformMac.en,
    ...PlatformWindows.en,
    ...Deposit.en,
    ...PlatformMt4.en,
    ...AboutMe.en,
    ...Condition.en,
    ...Security.en,
    ...Policy.en,
    ...TermCondition.en,
    ...MenuWallet.en,
    ...Dashboard.en,
    ...Pdf.en,
    ...WithDraw.en,
    ...Commission.en,
    ...Bonus.en,
    ...Lotback.en,
    ...Help.en,
    ...Setting.en,
    ...Promote.en,
    ...PaymentOption.en,
    ...Swap.en,
    next: "Next",
    prev: "Previous",
    goconsole: "Back to main page",
    comming_soon: "This page is not available.",
    click_send: "Send",
    validate_email: "Please verify your email",
    validate_email_resend: "Send email to verify again.",
    validate_email_seccuss: "Please check your email again.",
  },
  in: {
    ...Instrument.in,
    ...Analytics.in,
    ...Calendar.in,
    ...Platform.in,
    ...ChangePhone.in,
    ...ChangePassword.in,
    ...Verify.in,
    ...ContactUs.in,
    ...GuidelinePartner.in,
    ...Account.in,
    ...Navbar.in,
    ...LadningPage.en,
    ...Footer.in,
    ...SocialTrade.en,
    ...Partner.en,
    ...Partner2.en,
    ...Futures.en,
    ...Contact.in,
    ...Login.en,
    ...Register.en,
    ...PlatformIphone.en,
    ...PlatformAndroid.en,
    ...PlatformMac.en,
    ...PlatformWindows.en,
    ...Deposit.in,
    ...PlatformMt4.en,
    ...AboutMe.in,
    ...Condition.en,
    ...Security.en,
    ...Policy.en,
    ...TermCondition.en,
    ...MenuWallet.in,
    ...Dashboard.in,
    ...Pdf.en,
    ...WithDraw.en,
    ...Commission.in,
    ...Bonus.in,
    ...Lotback.in,
    ...Help.en,
    ...Setting.in,
    ...Promote.en,
    ...PaymentOption.en,
    ...Swap.in,
    next: "Next",
    prev: "Previous",
    goconsole: "Back to main page",
    comming_soon: "This page is not available.",
    click_send: "Send",
    validate_email: "Please verify your email",
    validate_email_resend: "Send email to verify again.",
    validate_email_seccuss: "Please check your email again.",
  },
  my: {
    ...Instrument.my,
    ...Analytics.my,
    ...Calendar.my,
    ...Platform.my,
    ...ChangePhone.my,
    ...ChangePassword.my,
    ...Verify.my,
    ...ContactUs.my,
    ...GuidelinePartner.my,
    ...Account.my,
    ...Navbar.my,
    ...LadningPage.my,
    ...Footer.my,
    ...SocialTrade.my,
    ...Partner.my,
    ...Partner2.my,
    ...Futures.my,
    ...Contact.my,
    ...Login.my,
    ...Register.my,
    ...PlatformIphone.my,
    ...PlatformAndroid.my,
    ...PlatformMac.my,
    ...PlatformWindows.my,
    ...Deposit.my,
    ...PlatformMt4.my,
    ...AboutMe.my,
    ...Condition.my,
    ...Security.my,
    ...Policy.my,
    ...TermCondition.my,
    ...MenuWallet.my,
    ...Dashboard.my,
    ...Pdf.my,
    ...WithDraw.my,
    ...Commission.my,
    ...Bonus.my,
    ...Lotback.my,
    ...Help.my,
    ...Setting.my,
    ...Promote.my,
    ...PaymentOption.my,
    ...Swap.my,
    next: "Seterusnya",
    prev: "Sebelumnya",
    goconsole: "Kembali ke halaman utama",
    comming_soon: "Halaman ini tidak tersedia.",
    click_send: "Klik",
    validate_email: "Sila sahkan e-mel anda",
    validate_email_resend: "Hantar e-mel untuk mengesahkan semula.",
    validate_email_seccuss: "Sila semak semula e-mel anda.",
  },
  id: {
    ...Instrument.id,
    ...Analytics.id,
    ...Calendar.id,
    ...Platform.id,
    ...ChangePhone.id,
    ...ChangePassword.id,
    ...Verify.id,
    ...ContactUs.id,
    ...GuidelinePartner.id,
    ...Account.id,
    ...Navbar.id,
    ...LadningPage.id,
    ...Footer.id,
    ...SocialTrade.id,
    ...Partner.id,
    ...Partner2.id,
    ...Futures.id,
    ...Contact.id,
    ...Login.id,
    ...Register.id,
    ...PlatformIphone.id,
    ...PlatformAndroid.id,
    ...PlatformMac.id,
    ...PlatformWindows.id,
    ...Deposit.id,
    ...PlatformMt4.id,
    ...AboutMe.id,
    ...Condition.id,
    ...Security.id,
    ...Policy.id,
    ...TermCondition.id,
    ...MenuWallet.id,
    ...Dashboard.id,
    ...Pdf.id,
    ...WithDraw.id,
    ...Commission.id,
    ...Bonus.id,
    ...Lotback.id,
    ...Help.id,
    ...Setting.id,
    ...Promote.id,
    ...PaymentOption.id,
    ...Swap.id,
    next: "Terus",
    prev: "Previous",
    goconsole: "Back to main page",
    comming_soon: "This page is not available.",
    click_send: "Send",
    validate_email: "Please verify your email",
    validate_email_resend: "Send email to verify again.",
    validate_email_seccuss: "Please check your email again.",
  },
  cn: {
    ...Instrument.cn,
    ...Analytics.cn,
    ...Calendar.cn,
    ...Platform.cn,
    ...ChangePhone.cn,
    ...ChangePassword.cn,
    ...Verify.cn,
    ...ContactUs.cn,
    ...GuidelinePartner.cn,
    ...Account.cn,
    ...Navbar.cn,
    ...LadningPage.cn,
    ...Footer.cn,
    ...SocialTrade.cn,
    ...Partner.cn,
    ...Partner2.cn,
    ...Futures.cn,
    ...Contact.cn,
    ...Login.cn,
    ...Register.cn,
    ...PlatformIphone.cn,
    ...PlatformAndroid.cn,
    ...PlatformMac.cn,
    ...PlatformWindows.cn,
    ...Deposit.cn,
    ...PlatformMt4.cn,
    ...AboutMe.cn,
    ...Condition.cn,
    ...Security.cn,
    ...Policy.cn,
    ...TermCondition.cn,
    ...MenuWallet.cn,
    ...Dashboard.cn,
    ...Pdf.cn,
    ...WithDraw.cn,
    ...Commission.cn,
    ...Bonus.cn,
    ...Lotback.cn,
    ...Help.cn,
    ...Setting.cn,
    ...Promote.cn,
    ...PaymentOption.cn,
    ...Swap.cn,
    next: "Next",
    prev: "Previous",
    goconsole: "Back to main page",
    comming_soon: "This page is not available.",
    click_send: "Send",
    validate_email: "Please verify your email",
    validate_email_resend: "Send email to verify again.",
    validate_email_seccuss: "Please check your email again.",
  },
  vn: {
    ...Instrument.vn,
    ...Analytics.vn,
    ...Calendar.vn,
    ...Platform.vn,
    ...ChangePhone.vn,
    ...ChangePassword.vn,
    ...Verify.vn,
    ...ContactUs.vn,
    ...GuidelinePartner.vn,
    ...Account.vn,
    ...Navbar.vn,
    ...LadningPage.vn,
    ...Footer.vn,
    ...SocialTrade.vn,
    ...Partner.vn,
    ...Partner2.vn,
    ...Futures.vn,
    ...Contact.vn,
    ...Login.vn,
    ...Register.vn,
    ...PlatformIphone.vn,
    ...PlatformAndroid.vn,
    ...PlatformMac.vn,
    ...PlatformWindows.vn,
    ...Deposit.vn,
    ...PlatformMt4.vn,
    ...AboutMe.vn,
    ...Condition.vn,
    ...Security.vn,
    ...Policy.vn,
    ...TermCondition.vn,
    ...MenuWallet.vn,
    ...Dashboard.vn,
    ...Pdf.vn,
    ...WithDraw.vn,
    ...Commission.vn,
    ...Bonus.vn,
    ...Lotback.vn,
    ...Help.vn,
    ...Setting.vn,
    ...Promote.vn,
    ...PaymentOption.vn,
    ...Swap.vn,
    next: "Tiếp theo",
    prev: "Quay lại",
    goconsole: "trở lại trang chủ",
    comming_soon: "Tính năng này vẫn chưa có sẵn",
    click_send: "Gửi",
    validate_email: "Vui lòng xác nhận e-mail của bạn",
    validate_email_resend: "Gửi email để xác minh lại",
    validate_email_seccuss: "Vui lòng kiểm tra E-mail của bạn lại",
  },
  kr: {
    ...Instrument.kr,
    ...Analytics.kr,
    ...Calendar.kr,
    ...Platform.kr,
    ...ChangePhone.kr,
    ...ChangePassword.kr,
    ...Verify.kr,
    ...ContactUs.kr,
    ...GuidelinePartner.kr,
    ...Account.kr,
    ...Navbar.kr,
    ...LadningPage.kr,
    ...Footer.kr,
    ...SocialTrade.kr,
    ...Partner.kr,
    ...Partner2.kr,
    ...Futures.kr,
    ...Contact.kr,
    ...Login.kr,
    ...Register.kr,
    ...PlatformIphone.kr,
    ...PlatformAndroid.kr,
    ...PlatformMac.kr,
    ...PlatformWindows.kr,
    ...Deposit.kr,
    ...PlatformMt4.kr,
    ...AboutMe.kr,
    ...Condition.kr,
    ...Security.kr,
    ...Policy.kr,
    ...TermCondition.kr,
    ...MenuWallet.kr,
    ...Dashboard.kr,
    ...Pdf.kr,
    ...WithDraw.kr,
    ...Commission.kr,
    ...Bonus.kr,
    ...Lotback.kr,
    ...Help.kr,
    ...Setting.kr,
    ...Promote.kr,
    ...PaymentOption.kr,
    ...Swap.kr,
    next: "Next",
    prev: "Previous",
    goconsole: "Back to main page",
    comming_soon: "This page is not available.",
    click_send: "Send",
    validate_email: "Please verify your email",
    validate_email_resend: "Send email to verify again.",
    validate_email_seccuss: "Please check your email again.",
  },
  ph: {
    ...Instrument.ph,
    ...Analytics.ph,
    ...Calendar.ph,
    ...Platform.ph,
    ...ChangePhone.ph,
    ...ChangePassword.ph,
    ...Verify.ph,
    ...GuidelinePartner.ph,
    ...Account.ph,
    ...Navbar.ph,
    ...LadningPage.ph,
    ...Footer.ph,
    ...SocialTrade.ph,
    ...Partner.ph,
    ...Partner2.ph,
    ...Futures.ph,
    ...Contact.ph,
    ...Login.ph,
    ...Register.ph,
    ...PlatformIphone.ph,
    ...PlatformAndroid.ph,
    ...PlatformMac.ph,
    ...PlatformWindows.ph,
    ...Deposit.ph,
    ...PlatformMt4.ph,
    ...AboutMe.ph,
    ...Condition.ph,
    ...Security.ph,
    ...Policy.ph,
    ...TermCondition.ph,
    ...MenuWallet.ph,
    ...Dashboard.ph,
    ...Pdf.ph,
    ...WithDraw.ph,
    ...Commission.ph,
    ...Bonus.ph,
    ...Lotback.ph,
    ...Help.ph,
    ...Setting.ph,
    ...Promote.ph,
    ...PaymentOption.ph,
    ...Swap.ph,
    next: "Next",
    prev: "Previous",
    goconsole: "Back to main page",
    comming_soon: "This page is not available.",
    click_send: "Send",
    validate_email: "Please verify your email",
    validate_email_resend: "Send email to verify again.",
    validate_email_seccuss: "Please check your email again.",
  },
  jp: {
    ...Instrument.jp,
    ...Analytics.jp,
    ...Calendar.jp,
    ...Platform.jp,
    ...ChangePhone.jp,
    ...ChangePassword.jp,
    ...Verify.jp,
    ...ContactUs.jp,
    ...GuidelinePartner.jp,
    ...Account.jp,
    ...Navbar.jp,
    ...LadningPage.jp,
    ...Footer.jp,
    ...SocialTrade.jp,
    ...Partner.jp,
    ...Partner2.jp,
    ...Futures.jp,
    ...Contact.jp,
    ...Login.jp,
    ...Register.jp,
    ...PlatformIphone.jp,
    ...PlatformAndroid.jp,
    ...PlatformMac.jp,
    ...PlatformWindows.jp,
    ...Deposit.jp,
    ...PlatformMt4.jp,
    ...AboutMe.jp,
    ...Condition.jp,
    ...Security.jp,
    ...Policy.jp,
    ...TermCondition.jp,
    ...MenuWallet.jp,
    ...Dashboard.jp,
    ...Pdf.jp,
    ...WithDraw.jp,
    ...Commission.jp,
    ...Bonus.jp,
    ...Lotback.jp,
    ...Help.jp,
    ...Setting.jp,
    ...Promote.jp,
    ...PaymentOption.jp,
    ...Swap.jp,
    next: "ถัดไป",
    prev: "ย้อนกลับ",
    goconsole: "กลับไปหน้าหลัก",
    comming_soon: "ฟีเจอร์ยังไม่พร้อมใช้งาน",
    click_send: "ส่ง",
    validate_email: "กรุณายืนยันอีเมลของคุณ",
    validate_email_resend: "ส่งอีเมลเพื่อยืนยันอีกครั้ง",
    validate_email_seccuss: "กรุณาตรวจสอบ Email ของคุณอีกครั้ง",
  },
};
