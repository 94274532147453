export default {
  th: {
    menuWallet: {
      deposit: "ฝากเงินด่วน",
      signOut: "ออกจากระบบ",
      withProcess: "รอดำเนินการ",
      getBonus: "รับโบนัส $30",
      verifynumberphone: "ยืนยันหมายเลขโทรศัพท์",
      withBonus: "รอรับโนนัส",
      verifyGetBonus: "* ยืนยันตัวตนรับโบนัส $50",
      errorGetBonusNotChooseAccount: "กรุณาเลือกบัญชีก่อนรับโบบัส",
      notmathPassword: "รหัสผ่านสองรหัสที่คุณป้อนไม่ตรงกัน!",
      notmathPassword_investor: "รหัสผ่านอินเวสเตอร์ ต้องไม่ตรงกับรหัสเทรด!",
    },
    account_detail: {
      detail: `สเปรดเริ่มต้น `,
      detail1: `สเปรดเริ่มต้น `,
      detail2: `ฝากขั้นต่ำ `,
      detail3: `คอมมิชชั่น  `,
      detail4: `เลเวอเรจสูงสุด `,
      detail5: `Swap  `,
    },
    standrd: {
      detail: `สเปรดเริ่มต้น :0.5 PIPs`,
      detail2: `โบนัสเงินฝาก :`,
      detail3: `คอมมิชชั่น: 0`,
      detail4: `เลเวอเรจสูงสุด : 1:1000`,
      detail5: `สวอป : ไม่มี`,
      detail6: `ฝากขั้นต่ำ :  10 USD`,
    },
    standrdX: {
      detail: `สเปรดเริ่มต้น :0.2 PIPs`,
      detail2: `เลเวอเรจสูงสุด : 1:3000`,
      detail3: `คอมมิชชั่น : 0 `,
      detail4: `ฝากขั้นต่ำ :  10 USD`,
      detail5: `Swap : ไม่มี`,
    },
    ecn: {
      detail: `สเปรด : 0.0 PIPs`,
      detail2: `โบนัสเงินฝาก : 0`,
      detail3: `คอมมิชชั่น: $3.5`,
      detail4: `เลเวอเรจสูงสุด : 1:3000`,
      detail5: `สวอป : 0`,
      detail6: `ฝากขั้นต่ำ : $200`,
    },
    raw: {
      detail: `สเปรด : 0.0 PIPs`,
      detail2: `โบนัสเงินฝาก : 0`,
      detail3: `คอมมิชชั่น:$7`,
      detail4: `เลเวอเรจสูงสุด : 1:3000`,
      detail5: `สวอป : 0`,
      detail6: `ฝากขั้นต่ำ : $200`,
    },
    pro: {
      detail: `สเปรด 0.1 PIPs`,
      detail2: `ฝากขั้นต่ำ 1000 USD`,
      detail3: `ไม่มีค่าคอมมิชชั่น`,
      detail4: `เลเวอเรจสูงสุด : 1:1000`,
      detail5: `\n`,
      detail6: `\n`,
    },
    open_account: {
      error_er: "กรุณายืนยันตัวตนก่อนเปิดบัญชีจริง",
      error_bank: "โปรดยืนยันธนาคารของคุณก่อนที่จะเปิดบัญชีจริง",
    },
  },
  la: {
    menuWallet: {
      deposit: "ການຝາກເງິນ",
      signOut: "ອອກ​ຈາກ​ລະ​ບົບ",
      withProcess: "ລໍຖ້າດໍາເນີນການ",
      getBonus: "ຮັບໂບນັດ $30",
      verifynumberphone: "ຢືນຢັນເບີໂທລະສັບ",
      withBonus: "ລໍຖ້າທີ່ຈະໄດ້ຮັບ Bonus",
      verifyGetBonus: "* ຢັ້ງຢືນຕົວຕົນ, ຮັບໂບນັດ 30 ໂດລາ",
      errorGetBonusNotChooseAccount: "ກະລຸນາ. ເລືອກບັນຊີກ່ອນທີ່ຈະຮັບໂບນັດ 30 $",
      notmathPassword: "ະຫັດຜ່ານສອງລະຫັດທີ່ທ່ານປ້ອນບໍ່ກົງກັນ!",
      notmathPassword_investor:
        "ລະຫັດຜ່ານຂອງນັກລົງທືນ ຕ້ອງບໍ່ກົງກັບລະຫັດການຊື້ຂາຍ!",
    },
    account_detail: {
      detail: `Spread  `,
      detail1: `Spread  `,
      detail2: `ເງິນຝາກຂັ້ນຕ່ໍາ `,
      detail3: `Commission  `,
      detail4: `Leverage ສູງສຸດ `,
      detail5: `Swap `,
      detail6: `ເງິນຝາກຕ່ ຳສຸດ `,
    },
    standrd: {
      detail: `Spread : 0.5 PIPs`,
      detail2: `ໂບນັດເງິນຝາກ :`,
      detail3: `Commission : 0`,
      detail4: `Leverage ສູງສຸດ : 1:1000`,
      detail5: `Swap : ບໍ່ມີ`,
      detail6: `ເງິນຝາກຕ່ ຳສຸດ :  10 USD`,
    },
    standrdX: {
      detail: `Spread ເລີ່ມຕົ້ນ :0.2 PIPs`,
      detail2: `Leverage ສູງສຸດ : 1:3000`,
      detail3: `Commission : 0 `,
      detail4: `ເງິນຝາກຕ່ ຳສຸດ :  10 USD`,
      detail5: `Swap : ບໍ່ມີ`,
    },
    ecn: {
      detail: `Spread : 0.0 PIPs`,
      detail2: `ໂບນັດເງິນຝາກ : 0`,
      detail3: `Commission : $3.5`,
      detail4: `Leverage ສູງສຸດ : 1:3000`,
      detail5: `Swap : 0`,
      detail6: `ເງິນຝາກຕ່ ຳ ສຸດ : $200`,
    },
    raw: {
      detail: `Spread : 0.0 PIPs`,
      detail2: `ໂບນັດເງິນຝາກ : 0`,
      detail3: `Commission : $7`,
      detail4: `Leverage ສູງສຸດ : 1:3000`,
      detail5: `Swap : 0`,
      detail6: `ເງິນຝາກຕ່ ຳ ສຸດ : $200`,
    },
    pro: {
      detail: `ແຜ່ຈາກ 0.1 PIPs`,
      detail2: `ບໍ່ມີຄະນະກໍາມະການ`,
      detail3: `ເງິນimumາກຂັ້ນຕ່ຳ 1000 USD`,
      detail4: `0.1 ຂະ ໜາດ Lot ຕ່ຳສຸດ`,
      detail5: `\n`,
      detail6: `\n`,
    },
    open_account: {
      error_er: "ກະລຸນາຢືນຢັນຕົວຕົນຂອງທ່ານເພື່ອເປີດບັນຊີ",
      error_bank: "ກະລຸນາກວດສອບທະນາຄານຂອງທ່ານກ່ອນທີ່ຈະເປີດບັນຊີຕົວຈິງ",
    },
  },
  en: {
    menuWallet: {
      deposit: "Deposit",
      signOut: "Sign Out",
      withProcess: "Pending",
      getBonus: "Get $30 Bonus",
      verifynumberphone: "Verify Number Phone",
      withBonus: "Waiting to receive Bonus",
      verifyGetBonus: "* Verify identity, receice a $50 bonus",
      errorGetBonusNotChooseAccount:
        "please. select a account before get bonus $30",
      notmathPassword: "The two passwords that you entered do not match!",
      notmathPassword_investor:
        "Investor password Must not match the trading Password!",
    },
    account_detail: {
      detail: `Spread from `,
      detail1: `Spread `,
      detail2: `Minimum deposit From`,
      detail3: `Commission `,
      detail4: `Maximum Leverage  `,
      detail5: `Swap  `,
      detail6: `Minimum deposit From  `,
    },
    standrd: {
      detail: `Spread from: 0.5 PIPs`,
      detail2: `Deposit Bonus:`,
      detail3: `Commission: 0`,
      detail4: `Maximum Leverage : 1:1000`,
      detail5: `Swap : 0`,
      detail6: `Minimum deposit From 10 USD`,
    },
    standrdX: {
      detail: `Spread from : 0.2 PIPs`,
      detail2: `Max Leverage: 1:3000`,
      detail3: `Commission: 0`,
      detail4: `Minimum deposit From : 10 USD`,
      detail5: `Swap : No`,
    },
    ecn: {
      detail: `Spread : 0.0 PIPs`,
      detail2: `Deposit Bonus : 0`,
      detail3: `Commission : $3.5`,
      detail4: `Maximum Leverage : 1:3000`,
      detail5: `Swap : 0`,
      detail6: `Min deposit : $200`,
    },
    raw: {
      detail: `Spread : 0.0 PIPs`,
      detail2: `Deposit Bonus : 0`,
      detail3: `Commission : $7`,
      detail4: `Maximum Leverage : 1:3000`,
      detail5: `Swap : 0`,
      detail6: `Min deposit : $200`,
    },
    pro: {
      detail: `Spread 0.1 PIPs`,
      detail2: `No Commission`,
      detail3: `Minimum Deposit 1000 USD`,
      detail4: `0.1 Minimum  Lot size`,
      detail5: `\n`,
      detail6: `\n`,
    },
    open_account: {
      error_er: "Please verify your identity before opening a real account.",
      error_bank: "Please verify your bank before opening a real account",
    },
  },
  in: {
    menuWallet: {
      deposit: `जमा`,
      signOut: `साइन आउट`,
      withProcess: `लंबित`,
      getBonus: `$30 बोनस प्राप्त करें`,
      verifynumberphone: `नंबर फ़ोन सत्यापित करें`,
      withBonus: `बोनस मिलने का इंतजार है`,
      verifyGetBonus: `* पहचान सत्यापित करें, $50 का बोनस प्राप्त करें`,
      errorGetBonusNotChooseAccount: `कृपया। बोनस $30 पाने से पहले एक खाता चुनें`,
      notmathPassword: `आपके द्वारा दर्ज किए गए दो पासवर्ड मेल नहीं खाते!`,
      notmathPassword_investor: `निवेशक का पासवर्ड ट्रेडिंग पासवर्ड से मेल नहीं खाना चाहिए!`,
    },
    account_detail: {
      detail: `से फैला`,
      detail1: `से फैला`,
      detail2: `न्यूनतम जमा`,
      detail3: `आयोग`,
      detail4: `अधिकतम उत्तोलन`,
      detail5: `बदलना`,
      detail6: `न्यूनतम जमा से`,
    },
    standrd: {
      detail: `फैलाव: 0.5 पीआईपी`,
      detail2: `जमा बोनस:`,
      detail3: `आयोग: 0`,
      detail4: `अधिकतम उत्तोलन: 1:1000`,
      detail5: `स्वैप : 0`,
      detail6: `न्यूनतम जमा 10 USD से`,
    },
    standrdX: {
      detail: `फैलाव: 0.2 पीआईपी`,
      detail2: `अधिकतम उत्तोलन: 1:3000`,
      detail3: `आयोग: 0`,
      detail4: `न्यूनतम जमा राशि: 10 USD`,
      detail5: `स्वैप: नहीं`,
    },
    ecn: {
      detail: `फैलाव: 0.0 पीआईपी`,
      detail2: `जमा बोनस : 0`,
      detail3: `कमीशन: $3.5`,
      detail4: `अधिकतम उत्तोलन: 1:3000`,
      detail5: `स्वैप : 0`,
      detail6: `न्यूनतम जमा राशि: $200`,
    },
    raw: {
      detail: `फैलाव: 0.0 पीआईपी`,
      detail2: `जमा बोनस : 0`,
      detail3: `कमीशन: $7`,
      detail4: `अधिकतम उत्तोलन: 1:3000`,
      detail5: `स्वैप : 0`,
      detail6: `न्यूनतम जमा राशि: $200`,
    },
    pro: {
      detail: `0.1 पीआईपी फैलाएं`,
      detail2: `कोई कमीशन नहीं`,
      detail3: `न्यूनतम जमा 1000 USD`,
      detail4: `0.1 न्यूनतम लॉट आकार`,
      detail5: `\n`,
      detail6: `\n`,
    },
    open_account: {
      error_er: `वास्तविक खाता खोलने से पहले कृपया अपनी पहचान सत्यापित करें।`,
      error_bank: `वास्तविक खाता खोलने से पहले कृपया अपने बैंक को सत्यापित करें`,
    },
  },
  my: {
    menuWallet: {
      deposit: `Deposit segera`,
      signOut: `Sign Out`,
      withProcess: `Sedang diproses`,
      getBonus: `Terima Bonus`,
      verifynumberphone: `Sahkan no. Telefon`,
      withBonus: `Menunggu untuk menerima Bonus`,
      verifyGetBonus: `* Sahkan identiti anda dan terima bonus $50`,
      errorGetBonusNotChooseAccount: `sila memilih akaun sebelum terima bonus 30 $`,
      notmathPassword: "Dua kata laluan yang anda masukkan tidak sepadan!",
      notmathPassword_investor:
        "Kata laluan pelabur Mesti tidak sepadan dengan Kata Laluan dagangan!",
    },
    account_detail: {
      detail: `Spread  `,
      detail1: `Spread  `,
      detail2: `Deposit minimum daripada`,
      detail3: `Komisen `,
      detail4: ` Leverage Maksimum `,
      detail5: `Swap  `,
      detail6: `Minimum deposit From `,
    },
    standrd: {
      detail: `Spread : 0.5 PIPs`,
      detail2: `Deposit minimum daripada:`,
      detail3: `Komisen: 0`,
      detail4: ` Leverage Maksimum: 1:1000`,
      detail5: `Swap : tiada`,
      detail6: `Minimum deposit From 10 USD`,
    },
    standrdX: {
      detail: `Spread dari : 0.2 PIPs`,
      detail2: ` Leverage Maksimum: 1:3000`,
      detail3: `Komisen: 0`,
      detail4: `Minimum deposit From : 10 USD`,
      detail5: `Swap : tiada`,
    },
    ecn: {
      detail: `Spread : 0.0 PIPs`,
      detail2: `Deposit minimum daripada : 0`,
      detail3: `Komisen: $3.5`,
      detail4: ` Leverage Maksimum: 1:3000`,
      detail5: `Swap : tiada`,
      detail6: `Min deposit : $200`,
    },
    raw: {
      detail: `Spread : 0.0 PIPs`,
      detail2: `Deposit minimum daripada : 0`,
      detail3: `Komisen: $7`,
      detail4: ` Leverage Maksimum: 1:3000`,
      detail5: `Swap : tiada`,
      detail6: `Min deposit : $200`,
    },
    pro: {
      detail: `Spread 0.1 PIPs`,
      detail2: `Tiada Komisen`,
      detail3: `Minimum Deposit 1000 USD`,
      detail4: `0.1 Minimum  Lot size`,
      detail5: `\n`,
      detail6: `\n`,
    },
    open_account: {
      error_er: "Sila sahkan identiti anda sebelum membuka akaun sebenar.",
      error_bank: "Sila sahkan bank anda sebelum membuka akaun sebenar",
    },
  },
  id: {
    menuWallet: {
      deposit: `Menyetorkan`,
      signOut: `Keluar`,
      withProcess: `Tertunda`,
      getBonus: `menerima Bonus`,
      verifynumberphone: `Verifikasi Nomor Telepon`,
      withBonus: `Menunggu untuk menerima Bonus`,
      verifyGetBonus: `* Verifikasi identitas, receice $ 30 bonus`,
      errorGetBonusNotChooseAccount: `silahkan. pilih account sebelum mendapatkan bonus 30 $`,
      notmathPassword: "The two passwords that you entered do not match!",
      notmathPassword_investor:
        "Investor password Must not match the trading Password!",
    },
    account_detail: {
      detail: `Spread from `,
      detail1: `Spread`,
      detail2: `Setoran minimum Mulai`,
      detail3: `Komisi `,
      detail4: ` Leverage Maksimum`,
      detail5: `Swap  `,
      detail6: `Minimum deposit From  `,
    },
    standrd: {
      detail: `Spread : 0.5 PIPs`,
      detail2: `Deposit Bonus :`,
      detail3: `Commission: 0`,
      detail4: `Maximum Leverage : 1:1000`,
      detail5: `Swap : 0`,
      detail6: `Minimum deposit From 10 USD`,
    },
    standrdX: {
      detail: `Spread from : 0.2 PIPs`,
      detail2: `Max Leverage: 1:3000`,
      detail3: `Commission: 0`,
      detail4: `Minimum deposit From : 10 USD`,
      detail5: `Swap : No`,
    },
    ecn: {
      detail: `Spread : 0.0 PIPs`,
      detail2: `Deposit Bonus : 0`,
      detail3: `Commission : $3.5`,
      detail4: `Maximum Leverage : 1:3000`,
      detail5: `Swap : 0`,
      detail6: `Min deposit : $200`,
    },
    raw: {
      detail: `Spread : 0.0 PIPs`,
      detail2: `Deposit Bonus : 0`,
      detail3: `Commission : $7`,
      detail4: `Maximum Leverage : 1:3000`,
      detail5: `Swap : 0`,
      detail6: `Min deposit : $200`,
    },
    pro: {
      detail: `Spread 0.1 PIPs`,
      detail2: `No Commission`,
      detail3: `Minimum Deposit 1000 USD`,
      detail4: `0.1 Minimum  Lot size`,
      detail5: `\n`,
      detail6: `\n`,
    },
    open_account: {
      error_er: "Harap verifikasi identitas Anda sebelum membuka akun nyata",
      error_bank: "Harap verifikasi bank Anda sebelum membuka rekening nyata",
    },
  },
  cn: {
    menuWallet: {
      deposit: `存款`,
      signOut: `登出`,
      withProcess: `待定`,
      getBonus: `获得奖励`,
      verifynumberphone: `验证码电话`,
      withBonus: `等待接收奖金`,
      verifyGetBonus: `*验证身份，receice $ 30奖金`,
      errorGetBonusNotChooseAccount: `请。选择获得奖励$ 30前的帐户`,
      notmathPassword: "The two passwords that you entered do not match!",
      notmathPassword_investor:
        "Investor password Must not match the trading Password!",
    },

    account_detail: {
      detail: `Spread from `,
      detail1: `Spread`,
      detail2: `Deposit Bonus`,
      detail3: `Commission `,
      detail4: `Maximum Leverage  `,
      detail5: `Swap  `,
      detail6: `Minimum deposit From  `,
    },
    standrd: {
      detail: `Spread : 0.5 PIPs`,
      detail2: `Deposit Bonus :`,
      detail3: `Commission: 0`,
      detail4: `Maximum Leverage : 1:1000`,
      detail5: `Swap : 0`,
      detail6: `Minimum deposit From 10 USD`,
    },
    standrdX: {
      detail: `Spread from : 0.2 PIPs`,
      detail2: `Max Leverage: 1:3000`,
      detail3: `Commission: 0`,
      detail4: `Minimum deposit From : 10 USD`,
      detail5: `Swap : No`,
    },
    ecn: {
      detail: `Spread : 0.0 PIPs`,
      detail2: `Deposit Bonus : 0`,
      detail3: `Commission : $3.5`,
      detail4: `Maximum Leverage : 1:3000`,
      detail5: `Swap : 0`,
      detail6: `Min deposit : $200`,
    },
    raw: {
      detail: `Spread : 0.0 PIPs`,
      detail2: `Deposit Bonus : 0`,
      detail3: `Commission : $7`,
      detail4: `Maximum Leverage : 1:3000`,
      detail5: `Swap : 0`,
      detail6: `Min deposit : $200`,
    },
    pro: {
      detail: `點差從 0.1 點`,
      detail2: `無佣金`,
      detail3: `最低存款 1000 美元`,
      detail4: `0.1 最小手數`,
      detail5: `\n`,
      detail6: `\n`,
    },
    open_account: {
      error_er: "在打開真實帳戶之前，請驗證您的身份",
      error_bank: "在開設真實帳戶之前，請驗證您的銀行",
    },
  },
  vn: {
    menuWallet: {
      deposit: "Tiền nạp khẩn",
      signOut: "Đăng xuất",
      withProcess: "Chờ thực hiện",
      getBonus: "Nhận $30 miễn phí",
      verifynumberphone: "Xác nhận số điện thoại",
      withBonus: "chờ tiền thưởng ",
      verifyGetBonus: "* Xác minh danh tính để nhận tiền thưởng. $50",
      errorGetBonusNotChooseAccount:
        "Vui lòng chọn tài khoản để nhận tiền thưởng",
      notmathPassword: "Mật khẩu cả 2 không khớp!",
      notmathPassword_investor:
        "Mật khẩu nhà đầu tư  không được khớp với mã giao dịch!",
    },
    account_detail: {
      detail: `Spread bắt đầu `,
      detail1: `Spread`,
      detail2: `Tiền gửi tối thiểu `,
      detail3: `Hoa hồng `,
      detail4: `Leverage tối đa  `,
      detail5: `Swap  `,
      detail6: `Nạp tiền tối thiểu   `,
    },
    standrd: {
      detail: `Spread bắt đầu :0.5 PIPs`,
      detail2: `Tiền gửi tối thiểu :`,
      detail3: `Hoa hồng: 0`,
      detail4: `Leverage tối đa : 1:1000`,
      detail5: `Swap : không có`,
      detail6: `Nạp tiền tối thiểu :  10 USD`,
    },
    standrdX: {
      detail: `Spread bắt đầu :0.2 PIPs`,
      detail2: `Leverage tối đa : 1:3000`,
      detail3: `Hoa hồng : 0 `,
      detail4: `Nạp tiền tối thiểu :  10 USD`,
      detail5: `Swap : không có`,
    },
    ecn: {
      detail: `Spread: 0.0 PIPs`,
      detail2: `Tiền gửi tối thiểu : 0`,
      detail3: `:Hoa hồng $3.5$`,
      detail4: `Leverage tối đa : 1:3000`,
      detail5: `Swap : 0`,
      detail6: `Nạp tiền tối thiểu : $200`,
    },
    raw: {
      detail: `Spread: 0.0 PIPs`,
      detail2: `Tiền gửi tối thiểu : 0`,
      detail3: `Hoa hồng: $7`,
      detail4: `Leverage tối đa : 1:3000`,
      detail5: `Swap : 0`,
      detail6: `Nạp tiền tối thiểu : $200`,
    },
    pro: {
      detail: `Spread 0.1 PIPs`,
      detail2: `Nạp tiền tối thiểu 1000 USD`,
      detail3: `Không có hoa hồng`,
      detail4: `Leverage tối đa : 1:1000`,
      detail5: `\n`,
      detail6: `\n`,
    },
    open_account: {
      error_er: "Vui lòng xác minh danh tính trước mở tài khoản thực",
      error_bank:
        "Vui lòng xác minh danh tính của bạn trước khi mở tài khoản thực",
    },
  },
  kr: {
    menuWallet: {
      deposit: `예금`,
      signOut: `어`,
      withProcess: `보류`,
      getBonus: `보너스를 받기`,
      verifynumberphone: `번호 전화 확인`,
      withBonus: `보너스를 받기 위해 대기`,
      verifyGetBonus: `* 신원을 확인 달러 (A $) (30)의 보너스를 receice`,
      errorGetBonusNotChooseAccount: `부디. GET 보너스 30 $ 전에 계정을 선택`,
      notmathPassword: "The two passwords that you entered do not match!",
      notmathPassword_investor:
        "Investor password Must not match the trading Password!",
    },
    account_detail: {
      detail: `Spread from `,
      detail1: `Spread`,
      detail2: `Deposit Bonus`,
      detail3: `Commission `,
      detail4: `Maximum Leverage  `,
      detail5: `Swap  `,
      detail6: `Minimum deposit From  `,
    },
    standrd: {
      detail: `Spread : 0.5 PIPs`,
      detail2: `Deposit Bonus :`,
      detail3: `Commission: 0`,
      detail4: `Maximum Leverage : 1:1000`,
      detail5: `Swap : 0`,
      detail6: `Minimum deposit From 10 USD`,
    },
    standrdX: {
      detail: `Spread from : 0.2 PIPs`,
      detail2: `Max Leverage: 1:3000`,
      detail3: `Commission: 0`,
      detail4: `Minimum deposit From : 10 USD`,
      detail5: `Swap : No`,
    },
    ecn: {
      detail: `Spread : 0.0 PIPs`,
      detail2: `Deposit Bonus : 0`,
      detail3: `Commission : $3.5`,
      detail4: `Maximum Leverage : 1:3000`,
      detail5: `Swap : 0`,
      detail6: `Min deposit : $200`,
    },
    raw: {
      detail: `Spread : 0.0 PIPs`,
      detail2: `Deposit Bonus : 0`,
      detail3: `Commission : $7`,
      detail4: `Maximum Leverage : 1:3000`,
      detail5: `Swap : 0`,
      detail6: `Min deposit : $200`,
    },
    pro: {
      detail: `Spread 0.1 PIPs`,
      detail2: `No Commission`,
      detail3: `Minimum Deposit 1000 USD`,
      detail4: `0.1 Minimum  Lot size`,
      detail5: `\n`,
      detail6: `\n`,
    },

    open_account: {
      error_er: "실제 계정을 열기 전에 신원을 확인하십시오",
      error_bank: " 실제 계정을 개설하기 전에 은행을 확인하십시오",
    },
  },
  ph: {
    menuWallet: {
      deposit: `deposito`,
      signOut: `Mag-sign Out`,
      withProcess: `Pending`,
      getBonus: `Tumanggap ng Bonus`,
      verifynumberphone: `I-verify ang Numero ng Telepono`,
      withBonus: `Naghihintay na matanggap Bonus`,
      verifyGetBonus: `* I-verify pagkakakilanlan, receice isang $ 30 na bonus`,
      errorGetBonusNotChooseAccount: `mangyaring. pumili ng isang account bago get bonus 30 $`,
      notmathPassword: "The two passwords that you entered do not match!",
      notmathPassword_investor:
        "Investor password Must not match the trading Password!",
    },
    account_detail: {
      standard_account_description:
        "	Pinaka sikat! Ang isang mahusay na account para sa lahat ng mga uri ng mangangalakal.	",
      standard_bonus_account_description:
        "	Ang aming instant na account sa pagpapatupad. Mahusay na punan na walang komisyon sa pangangalakal.	",
      raw_account_description:
        "	Ang pinakamababang hilaw na kumakalat at isang mababang nakapirming komisyon.	",
      pro_account_description:
        "	Propesyonal na account na may komisyon na libreng 0%.	",
      detail: `Spread from `,
      detail1: `Spread`,
      detail2: `Pinakamababang Deposito`,
      detail3: `Commission `,
      detail4: ` Leverage Maksimum `,
      detail5: `Swap  `,
      detail6: `Minimum deposit From  `,
    },
    standrd: {
      detail: `Spread : 0.5 PIPs`,
      detail2: `Deposit Bonus :`,
      detail3: `Commission: 0`,
      detail4: `Maximum Leverage : 1:1000`,
      detail5: `Swap : 0`,
      detail6: `Minimum deposit From 10 USD`,
    },
    standrdX: {
      detail: `Spread from : 0.2 PIPs`,
      detail2: `Max Leverage: 1:3000`,
      detail3: `Commission: 0`,
      detail4: `Minimum deposit From : 10 USD`,
      detail5: `Swap : No`,
    },
    ecn: {
      detail: `Spread : 0.0 PIPs`,
      detail2: `Deposit Bonus : 0`,
      detail3: `Commission : $3.5`,
      detail4: `Maximum Leverage : 1:3000`,
      detail5: `Swap : 0`,
      detail6: `Min deposit : $200`,
    },
    raw: {
      detail: `Spread : 0.0 PIPs`,
      detail2: `Deposit Bonus : 0`,
      detail3: `Commission : $7`,
      detail4: `Maximum Leverage : 1:3000`,
      detail5: `Swap : 0`,
      detail6: `Min deposit : $200`,
    },
    pro: {
      detail: `Spread 0.1 PIPs`,
      detail2: `No Commission`,
      detail3: `Minimum Deposit 1000 USD`,
      detail4: `0.1 Minimum  Lot size`,
      detail5: `\n`,
      detail6: `\n`,
    },
    open_account: {
      error_er:
        "Mangyaring i -verify ang iyong pagkakakilanlan bago buksan ang isang tunay na account",
      error_bank:
        "Mangyaring i -verify ang iyong bangko bago buksan ang isang tunay na account",
    },
  },
  jp: {
    menuWallet: {
      deposit: "デポジット",
      signOut: "サインアウト",
      withProcess: "保留中",
      getBonus: "30ドルのボーナスを取得します",
      verifynumberphone: "番号電話を確認します",
      withBonus: "ボーナスを受けるのを待っています",
      verifyGetBonus: "*身元を確認し、50ドルのボーナスを受け取ります",
      errorGetBonusNotChooseAccount:
        "お願いします。 ボーナス$ 30を取得する前に、アカウントを選択してください",
      notmathPassword: "入力した2つのパスワードは一致しません！",
      notmathPassword_investor:
        "投資家のパスワードは、取引パスワードと一致してはなりません！",
    },
    account_detail: {
      detail: "から広がります",
      detail1: "広める",
      detail2: "からの最小堆積物",
      detail3: "手数料",
      detail4: "最大レバレッジ",
      detail5: "スワップ",
      detail6: "からの最小堆積物",
    },
    standrd: {
      detail: "広がり：0.5ピップ",
      detail2: "デポジットボーナス：",
      detail3: "手数料：0",
      detail4: "最大レバレッジ：1：1000",
      detail5: "スワップ：0",
      detail6: "10米ドルからの最低預金",
    },
    standrdX: {
      detail: "広がり：0.2ピップ",
      detail2: "最大レバレッジ：1：3000",
      detail3: "手数料：0",
      detail4: "最低預金：10 USD",
      detail5: "スワップ：いいえ",
    },
    ecn: {
      detail: "スプレッド：0.0ピップ",
      detail2: "デポジットボーナス：0",
      detail3: "手数料：3.5ドル",
      detail4: "最大レバレッジ：1：3000",
      detail5: "スワップ：0",
      detail6: "最小預金：200ドル",
    },
    raw: {
      detail: "スプレッド：0.0ピップ",
      detail2: "デポジットボーナス：0",
      detail3: "手数料：7ドル",
      detail4: "最大レバレッジ：1：3000",
      detail5: "スワップ：0",
      detail6: "最小預金：200ドル",
    },
    pro: {
      detail: "0.1ピップを広げます",
      detail2: "手数料なし",
      detail3: "最低堆積物1000米ドル",
      detail4: "0.1最小ロットサイズ",
      detail5: "\n",
      detail6: "\n",
    },
    open_account: {
      error_er: "実際のアカウントを開設する前に、身元を確認してください。",
      error_bank: "実際のアカウントを開設する前に、銀行を確認してください",
    },
  },
};
