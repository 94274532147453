import React, { useState } from "react";
import { Form, Input, Button, Alert, message } from "antd";
import Axios from "axios";
import FooterAuthPage from "../../components/footer-landing-page/FooterAuth";
import { Times } from "./ForgotPasswordForm";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
const locale = require("react-redux-i18n").I18n;
const inputlabel = {
  height: "42px",
  background: "#242424",
  border: "1px solid #3c3c3c",
};
function ResetPassword(props) {
  const [visible, setVisible] = useState(false);
  // const [type, setType] = useState("")
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState(null);

  const onSubmit = (values) => {
    setLoading(true);
    Axios.post(process.env.REACT_APP_API_URL + "v2/password/reset", {
      newPassword: values.newPassword,
      reNewPassword: values.confirmNewPassword,
      token: props.match.params.token,
      user_id: props.match.params.id,
    })
      .then((res) => {
        setLoading(false);
        message.success(res.data.data);
        props.history.replace(`${localStorage.getItem("locale")}/login`);
        window.location.href =
          "https://www.iuxmarket.com/" +
          (localStorage.getItem("locale") || "en") +
          "/login";
      })
      .catch((e) => {
        setLoading(false);
        setText(e.response.data.error);
        setVisible(true);
      });
  };
  const validatorPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = new RegExp(
        /^(?=.*[0-9])(?=.*[A-Za-z])[a-zA-Z0-9~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]{8,15}$/
      );
      // /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}$/
      if (getFieldValue("newPassword") || getFieldValue("confirmNewPassword")) {
        if (value.match(regex)) {
          if (getFieldValue("newPassword") === value) {
            return Promise.resolve();
          } else {
            return Promise.reject(
              new Error("The two passwords that you entered do not match!")
            );
          }
        } else {
          return Promise.reject(
            "Set password must have uppercase and lowercase letters and numbers , minimum 8 characters , maximum 15 characters"
          );
        }
      } else {
        return Promise.reject(
          "Set password must have uppercase and lowercase letters and numbers , minimum 8 characters , maximum 15 characters"
        );
      }
    },
  });
  return (
    <div className="bg-black">
      <div className="bg-black">
        <div className="container">
          <div className="pt-4 pb-4 text-center text-white">
            {locale.t("login_top_1")}&nbsp;
            <span className="font-bold-iux">
              {locale.t("login_top_2")}&nbsp;
            </span>
            {locale.t("login_top_3")}
          </div>
        </div>
      </div>
      <section className="align-items-center d-flex flex-column h-100 justify-content-center w-100 bg-black">
        <div className="text-center rectangle-card rectangle-card-login ml-auto mr-auto mt-4 mb-4 position-relative">
          <Times
            className="font-bold-iux position-absolute"
            onClick={() =>
              props.history.push(`/${localStorage.getItem("locale")}`)
            }
          >
            <CloseOutlined style={{ color: "#A0A0A0" }} />
          </Times>
          {visible ? (
            <Alert className="m-3" message={text} type="error" />
          ) : null}
          <div className="logo-register">
            <Link
              to={`/${localStorage.getItem("locale")}`}
              style={{ padding: 0, overflowY: "auto" }}
            >
              <img
                alt="IUX"
                className="registerlogo"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/icons/iuxmarket_logo.svg"
                }
              />
            </Link>
          </div>
          <h5
            className="text-center text-white fw-700 fs-24"
            style={{
              padding: "10px 0px 40px 0px",
            }}
          >
            Enter Your New Password
          </h5>
          <Form onFinish={onSubmit}>
            <Form.Item
              name="newPassword"
              label="New password"
              rules={[
                {
                  required: true,
                  message: "Please enter new password",
                },
                validatorPassword,
              ]}
              style={{ color: "#8D8D8D" }}
            >
              <Input.Password
                style={inputlabel}
                placeholder="New password"
                className="login"
              />
            </Form.Item>
            <Form.Item
              name="confirmNewPassword"
              label="Confirm new password"
              dependencies={["newPassword"]}
              rules={[
                {
                  required: true,
                  message: "Please enter Confirm new password",
                },
                validatorPassword,
                // ({ getFieldValue }) => ({
                //   validator(rule, value) {
                //     if (!value || getFieldValue("newPassword") === value) {
                //       return Promise.resolve();
                //     }
                //     return Promise.reject(
                //       locale.t("menuWallet.notmathPassword")
                //     );
                //   },
                // }),
              ]}
            >
              <Input.Password
                style={inputlabel}
                className="login"
                placeholder="Confirm new password"
              />
            </Form.Item>
            <Button
              htmlType="submit"
              shape="round"
              loading={loading}
              className="mb-4 w-100 btn-primary-new-iux"
              type="primary"
              block
            >
              Confirm
            </Button>
          </Form>
        </div>
      </section>
      <FooterAuthPage locale={locale} />
    </div>
  );
}

export default ResetPassword;
