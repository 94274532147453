import { Button, Col, Divider, Row, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { get, post } from "../../../../helper/request";
const locale = require("react-redux-i18n").I18n;
export const onDepositTransact365 = ({
  amount_thb,
  account_trade_id,
  payment_id,
  bank,
  merchant,
}) => {
  return post(`${process.env.REACT_APP_API_URL + "deposit/transact365"}`, {
    amount_thb: amount_thb,
    account_trade_id: account_trade_id,
    payment_id: payment_id,
    bank: bank,
    merchant: merchant,
  });
};

export const postTransact365 = ({ paytype, merchant }) => {
  return post(`${process.env.REACT_APP_API_URL + "deposit/transact365/bank"}`, {
    paytype: paytype,
    merchant: merchant,
  });
};

export const getTransact365 = () => {
  return get(`${process.env.REACT_APP_API_URL + "deposit/transact365"}`);
};

const Transact365 = (props) => {
  const { paymentId } = props;
  const [merchant, setMerchant] = useState([]);
  const [mcKey, setMcKey] = useState("");
  const [bankPay, setBankPay] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const payment = async () => {
      setLoading(true);
      const { data } = await getTransact365();
      setMerchant(data);
      setLoading(false);
    };
    payment();
  }, []);

  const clickMerchant = async (merchant, bank) => {
    try {
      setLoading(true);
      const { data } = await postTransact365({
        paytype: "BankPay",
        merchant,
      });
      setBankPay(data);
      if (data.length <= 0 || bank) {
        const { data } = await onDepositTransact365({
          amount_thb: +props.amount,
          account_trade_id: props.account.id,
          payment_id: paymentId,
          bank: bank,
          merchant: merchant,
        });
        window.location.assign(data.url);
        // window.open(data.url, "_blank");
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e.response.data.message);
    }
  };

  const onFinish = async (b, m) => {
    try {
      setLoading(true);
      const { data } = await onDepositTransact365({
        amount_thb: +props.amount,
        account_trade_id: props.account.id,
        payment_id: paymentId,
        bank: b,
        merchant: b === "" ? m : mcKey,
      });
      setLoading(false);
      window.location.assign(data.url);
      // window.open(data.url, "_blank");
    } catch (e) {
      setLoading(false);
      console.log(e.response.data.message);
    }
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={[8, 8]}>
        {merchant.map((e, i) => (
          <Col span={12}>
            <div>
              <Button
                className="btn btn-outline-iux w-100"
                onClick={() => {
                  setMcKey(e);
                  clickMerchant(e);
                }}
              >
                {e}
              </Button>
            </div>
          </Col>
        ))}
        <Col span={24}>
          <div>
            <Divider />
          </div>
        </Col>
        {bankPay.map((e) => (
          <Col span={12}>
            <div>
              <Button
                className="btn btn-outline-iux w-100"
                onClick={() => clickMerchant(mcKey, e.code_name)}
              >
                {e.local_name}
              </Button>
            </div>
          </Col>
        ))}
      </Row>
      <div>
        <Button
          onClick={() => props.onBackStep()}
          className="btn-primary-new-iux"
        >
          {locale.t("prev")}
        </Button>
      </div>
      {/* <form method="POST" action={paymentData.host}>
        <p>
          <input type="hidden" name="Merchant" value={paymentData.merchant} />
          <input type="hidden" name="Currency" value={paymentData.currency} />
          <input type="hidden" name="Customer" value={paymentData.customer} />
          <input type="hidden" name="Reference" value={paymentData.reference} />
          <input type="hidden" name="Key" value={paymentData.key} />
          <input type="hidden" name="Amount" value={paymentData.amount} />
          <input type="hidden" name="Note" value={paymentData.note} />
          <input type="hidden" name="Datetime" value={paymentData.datetime} />
          <input type="hidden" name="FrontURI" value={paymentData.frontURI} />
          <input type="hidden" name="BackURI" value={paymentData.backURI} />
          <input type="hidden" name="Language" value={paymentData.language} />
          <input type="hidden" name="Bank" value={paymentData.bank} />
          <input type="hidden" name="ClientIP" value={paymentData.clientIP} />
          <input
            ref={refPerfect}
            type="submit"
            value="help2pay account"
            hidden
          />
        </p>
      </form> */}
    </Spin>
  );
};

export default Transact365;
