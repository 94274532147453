export default {
  th: {
    register_title: "เปิดบัญชี IUX Markets",
    register_choose_region: "เลือกประเทศ",
    register_choose_region_placeholder: "โปรดเลือกประเทศ *",
    register_fullname: "ชื่อ-สกุล (อังกฤษ)",
    register_firstname: "ชื่อ (อังกฤษ)",
    register_firstname_placeholder: "โปรดกรอกชื่อ (อังกฤษ) *",
    register_lastname_placeholder: "โปรดกรอกนามสกุล (อังกฤษ) *",
    register_lastname: "นามสกุล (อังกฤษ)",
    register_email: "อีเมลของคุณ",
    register_password: "กำหนดรหัสผ่าน",
    register_confirm_password: "ยืนยันรหัสผ่าน",
    register_policy: "ท่านได้ยอมรับเงื่อนไขและนโยบายของทาง IUX Markets ",
    register_policy_link: "เงื่อนไขและนโยบาย",
    register_button_text: "ดำเนินการ",
    register_error_input: "กรุณากรอกข้อมูลให้ครบถ้วน",
    register_error_choose_policy: "กรุณาตอบรับเงื่อนไขข้อตกลง",
    register_error_password_match: "รหัสผ่านไม่ตรงกัน",
    register_error_english_name: "กรุณากรอกชื่อหรือนามสกุลเป็นภาษาอังกฤษ",
    register_phone: "หมายเลขโทรศัพท์",
    register_url: "URL ของเว็บไซต์ หรือ Fanpage Facebook",
    register_detail: "อธิบายกลยุทธ์การตลาดของคุณ",
    register_aeardy: "อีเมล์นี้ได้ถูกใช้งานแล้ว",
    register_verify_title: "ยืนยันอีเมล์สำเร็จ",
    register_verify_description: "คุณสามารถ เข้าใช้ระบบได้ด้วย อีเมล์ของคุณ",
    register_verify_go: "ไปที่ login",
    register_verify_email: "กรุณายืนยันอีเมล",
    register_password_validate:
      "ตั้งรหัสผ่าน ต้องมี ตัวอักษรพิมพ์เล็ก-พิมพ์ใหญ่ และตัวเลข รวมกันไม่ต่ำกว่า 8 ตัวอักษร และไม่เกิน 15 ตัวอักษร",
    register_name_validate: "กรุณากรอกข้อมูลภาษาอังกฤษเท่านั้น",
  },
  la: {
    register_title: "ລົງທະບຽນ IUX Markets",
    register_choose_region: "ເລືອກປະເທດ *",
    register_choose_region_placeholder:
      "Please select your country of residence *",
    register_fullname: "ຊື່-ນາມສະກຸນ  *",
    register_firstname: "ຊື່​ແທ້ *",
    register_firstname_placeholder: "As seen on your ID documents *",
    register_lastname_placeholder: "As seen on your ID documents *",
    register_lastname: "ນາມ​ສະ​ກຸນ *",
    register_email: "ອີເມວ *",
    register_password: "ລະຫັດຜ່ານ *",
    register_confirm_password: "ຢືນ​ຢັນ​ລະ​ຫັດ *",
    register_policy: "ທ່ານຕົກລົງເຫັນດີກັບ IUXMarkets",
    register_policy_link: "ຂໍ້ ກຳ ນົດແລະນະໂຍບາຍ",
    register_button_text: "ລົງທະບຽນ",
    register_error_input: "ກະລຸນາຕື່ມຂໍ້ມູນທັງ ໝົດ",
    register_error_choose_policy: "ກະລຸນາຮັບກຳ ນົດແລະເງື່ອນໄຂ",
    register_error_password_match: "ລະຫັດຜ່ານບໍ່ກົງກັນ",
    register_error_english_name:
      "ກະລຸນາໃສ່ຊື່ ທຳ ອິດຫຼືນາມສະກຸນຂອງທ່ານເປັນພາສາອັງກິດ",
    register_phone: "ເບີໂທລະສັບ",
    register_url: "ເວບໄຊທ໌ URL ຫລື Fanpage Facebook",
    register_detail: "ອະທິບາຍຍຸດທະສາດການຕະຫຼາດຂອງທ່ານ",
    register_aeardy: "ໄດ້ຖືກ ນຳ ໃຊ້ແລ້ວ",
    register_verify_title: "ຢັ້ງຢືນ email ສຳ ເລັດ",
    register_verify_description:
      "ເຈົ້າ​ສາ​ມາດ Login ໂດຍໃຊ້ email ຂອງ​ເຈົ້າ​ເອງ",
    register_verify_go: "ໄປທີ່ login",
    register_verify_email: "ຢືນຢັນ Email",
    register_password_validate:
      "Password must be 8-15 characters. Include lowercase or uppercase, and number.",
    register_name_validate: "ກະລຸນາໃສ່ຂໍ້ມູນເປັນພາສາອັງກິດເທົ່ານັ້ນ",
  },
  en: {
    register_title: "REGISTER ACCOUNT",
    register_choose_region: "Country of residence *",
    register_choose_region_placeholder:
      "Please select your country of residence *",
    register_fullname: "Name  *",
    register_firstname: "First name *",
    register_lastname: "Last name *",
    register_firstname_placeholder: "As seen on your ID documents *",
    register_lastname_placeholder: "As seen on your ID documents *",
    register_email: "Email *",
    register_password: "Password *",
    register_confirm_password: "Confirm Password *",
    register_policy: "You agree to the IUX Markets ",
    register_policy_link: "terms and policies.\n",
    register_button_text: "Submit",
    register_error_input: "Please fill out all the information",
    register_error_choose_policy: "Please accept the terms and conditions",
    register_error_password_match: "Passwords do not match",
    register_error_english_name:
      "Please enter your first or last name in English.",
    register_phone: "Phone number",
    register_url: "Social link",
    register_detail: "Explain your marketing strategy",
    register_aeardy: "Aready use email",
    register_verify_title: "Verify success",
    register_verify_description: "You can login with your email",
    register_verify_go: "Go to login",
    register_verify_email: "Please verify your email.",
    register_password_validate:
      "Password must be 8-15 characters. Include lowercase or uppercase, and number.",
  },
  in: {
    register_title: "REGISTER ACCOUNT",
    register_choose_region: "Country of residence *",
    register_choose_region_placeholder:
      "Please select your country of residence *",
    register_fullname: "Name  *",
    register_firstname: "First name *",
    register_lastname: "Last name *",
    register_firstname_placeholder: "As seen on your ID documents *",
    register_lastname_placeholder: "As seen on your ID documents *",
    register_email: "Email *",
    register_password: "Password *",
    register_confirm_password: "Confirm Password *",
    register_policy: "You agree to the IUX Markets ",
    register_policy_link: "terms and policies.\n",
    register_button_text: "Submit",
    register_error_input: "Please fill out all the information",
    register_error_choose_policy: "Please accept the terms and conditions",
    register_error_password_match: "Passwords do not match",
    register_error_english_name:
      "Please enter your first or last name in English.",
    register_phone: "Phone number",
    register_url: "Social link",
    register_detail: "Explain your marketing strategy",
    register_aeardy: "Aready use email",
    register_verify_title: "Verify success",
    register_verify_description: "You can login with your email",
    register_verify_go: "Go to login",
    register_verify_email: "Please verify your email.",
    register_password_validate:
      "Password must be 8-15 characters. Include lowercase or uppercase, and number.",
  },
  my: {
    register_title: `Buka akaun IUX Markets`,
    register_choose_region: "Negara tempat tinggal *",
    register_choose_region_placeholder:
      "Sila pilih negara tempat tinggal anda *",
    register_fullname: "Name  *",
    register_firstname: `Nama pertama`,
    register_lastname: `Nama terakhir`,
    register_firstname_placeholder:
      "Seperti yang dilihat pada dokumen ID anda *",
    register_lastname_placeholder:
      "Seperti yang dilihat pada dokumen ID anda *",
    register_email: `e-mel`,
    register_password: `Kata laluan`,
    register_confirm_password: `Sahkan Kata laluan`,
    register_policy: `Anda menerima Syarat dan Ketentuan IUX Markets`,
    register_policy_link: `Syarat dan Ketentuan`,
    register_button_text: `Submit`,
    register_error_input: `Sila isi maklumat dengan lengkap`,
    register_error_choose_policy: `Sila terima Syarat dan Ketentuan`,
    register_error_password_match: `Kata laluan tidak sepadan`,
    register_error_english_name: `Sila masukkan nama pertama atau terakhir anda dalam bahasa Inggeris.`,
    register_phone: `Nombor telefon`,
    register_url: `Laman web URL atau Fanpage Facebook`,
    register_detail: `Terangkan strategi pemasaran anda`,
    register_aeardy: "E-mel ini telah digunakan",
    register_verify_title: "Pengesahan e-mail berjaya",
    register_verify_description: "Anda boleh log masuk dengan e-mel anda",
    register_verify_go: "Pergi ke log masuk",
    register_verify_email: "Sila sahkan e-mel anda.",
    register_password_validate:
      "Password must be 8-15 characters. Include lowercase or uppercase, and number.",
    register_name_validate: "Please enter information in English only.",
  },
  id: {
    register_title: `REGISTER ACCOUNT`,
    register_choose_region: "Negara tempat tinggal *",
    register_choose_region_placeholder:
      "Silakan pilih negara tempat tinggal Anda *",
    register_fullname: "Name  *",
    register_firstname: `Nama depan`,
    register_lastname: `Nama keluarga`,
    register_firstname_placeholder:
      "Seperti yang terlihat pada dokumen ID Anda *",
    register_lastname_placeholder:
      "Seperti yang terlihat pada dokumen ID Anda *",
    register_email: `Surel`,
    register_password: `Kata sandi`,
    register_confirm_password: `konfirmasi sandi`,
    register_policy: `Anda setuju untuk IUX Markets yang`,
    register_policy_link: `persyaratan dan kebijakan`,
    register_button_text: `Submit`,
    register_error_input: `Silahkan mengisi semua informasi yang`,
    register_error_choose_policy: `Harap menerima syarat dan ketentuan`,
    register_error_password_match: `Kata sandi tidak cocok`,
    register_error_english_name: `Masukkan nama depan atau belakang Anda dalam bahasa Inggris.`,
    register_phone: `nomor telepon`,
    register_url: `situs URL atau Fanpage Facebook`,
    register_detail: `Jelaskan strategi pemasaran Anda`,
    register_aeardy: "Aready use email",
    register_verify_title: "Verify success",
    register_verify_description: "You can login with your email",
    register_verify_go: "Go to login",
    register_verify_email: "Please verify your email.",
    register_password_validate:
      "Password must be 8-15 characters. Include lowercase or uppercase, and number.",
    register_name_validate: "Please enter information in English only.",
  },
  cn: {
    register_title: `注册账户`,
    register_choose_region: `选择地区`,
    register_choose_region_placeholder:
      "Please select your country of residence *",
    register_fullname: "Name  *",
    register_firstname: `名字`,
    register_lastname: `姓`,
    register_firstname_placeholder: "As seen on your ID documents *",
    register_lastname_placeholder: "As seen on your ID documents *",
    register_email: `电子邮件`,
    register_password: `密码`,
    register_confirm_password: `确认密码`,
    register_policy: `您同意IUX Markets`,
    register_policy_link: `条款和政策`,
    register_button_text: `处理`,
    register_error_input: `请填写所有信息`,
    register_error_choose_policy: `请接受条款和条件`,
    register_error_password_match: `密码不匹配`,
    register_error_english_name: `请用英文输入您的名字或姓氏。`,
    register_phone: `手机号`,
    register_url: `URL网站或Facebook上的Fanpage`,
    register_detail: `关于你自己的信息与我们共享。`,
    register_aeardy: "Aready use email",
    register_verify_title: "Verify success",
    register_verify_description: "You can login with your email",
    register_verify_go: "Go to login",
    register_verify_email: "Please verify your email.",
    register_password_validate:
      "Password must be 8-15 characters. Include lowercase or uppercase, and number.",
    register_name_validate: "Please enter information in English only.",
  },
  vn: {
    register_title: `ĐĂNG KÝ TÀI KHOẢN`,
    register_choose_region: "Quốc gia cư trú *",
    register_choose_region_placeholder:
      "Vui lòng chọn quốc gia cư trú của bạn *",
    register_fullname: "Name  *",
    register_firstname: `Tên`,
    register_lastname: `Họ`,
    register_firstname_placeholder: "Như trên giấy tờ tùy thân của bạn *",
    register_lastname_placeholder: "Như trên giấy tờ tùy thân của bạn *",
    register_email: `E-mail`,
    register_password: `Mật khẩu`,
    register_confirm_password: `Xác nhận mật khẩu`,
    register_policy: `Bạn đồng ý với IUX Markets`,
    register_policy_link: `điều khoản và chính sách`,
    register_button_text: `Thực hiện`,
    register_error_input: `Hãy điền đầy đủ thông tin`,
    register_error_choose_policy: `Vui lòng chấp nhận các điều khoản và điều kiện`,
    register_error_password_match: `Mật khẩu không khớp`,
    register_error_english_name: `Vui lòng nhập tên hoặc họ của bạn bằng tiếng Anh.`,
    register_phone: `Số điện thoại`,
    register_url: `Website URL hoặc Fanpage Facebook`,
    register_detail: `Giải thích chiến lược tiếp thị của bạn`,
    register_aeardy: "Aready use email",
    register_verify_title: "Verify success",
    register_verify_description: "You can login with your email",
    register_verify_go: "Go to login",
    register_verify_email: "Vui lòng xác nhận Email",
    register_password_validate:
      "Password must be 8-15 characters. Include lowercase or uppercase, and number.",
    register_name_validate: "Vui lòng chỉ nhập thông tin bằng tiếng Anh.",
  },
  kr: {
    register_title: `REGISTER 계좌`,
    register_choose_region: `지역 선택`,
    register_firstname_placeholder: "As seen on your ID documents *",
    register_lastname_placeholder: "As seen on your ID documents *",
    register_fullname: "Name  *",
    register_firstname: `이름`,
    register_lastname: `성`,
    register_email: `이메일`,
    register_password: `암호`,
    register_confirm_password: `비밀번호 확인`,
    register_policy: `당신은 IUX Markets에 동의`,
    register_policy_link: `약관 및 정책`,
    register_button_text: `방법`,
    register_error_input: `모든 정보를 기입하시기 바랍니다`,
    register_error_choose_policy: `이용 약관에 동의하시기 바랍니다`,
    register_error_password_match: `비밀번호가 일치하지 않습니다`,
    register_error_english_name: `영어 첫 번째 또는 마지막 이름을 입력하십시오.`,
    register_phone: `번호 전화`,
    register_url: `URL 웹 사이트 나 팬 페이지 페이스 북`,
    register_detail: `우리와 자신에 대한 정보를 공유.`,
    register_aeardy: "Aready use email",
    register_verify_title: "Verify success",
    register_verify_description: "You can login with your email",
    register_verify_go: "Go to login",
    register_verify_email: "Please verify your email.",
    register_password_validate:
      "Password must be 8-15 characters. Include lowercase or uppercase, and number.",
    register_name_validate: "Please enter information in English only.",
  },
  ph: {
    register_title: `REGISTER NG ACCOUNT`,
    register_choose_region: `Bansa ng paninirahan`,
    register_choose_region_placeholder:
      "Mangyaring piliin ang iyong bansang tinitirhan *",
    register_firstname_placeholder:
      "Gaya ng nakikita sa iyong ID na dokumento *",
    register_lastname_placeholder:
      "Gaya ng nakikita sa iyong ID na dokumento *",
    register_fullname: "Name  *",
    register_firstname: `Pangalan`,
    register_lastname: `Huling pangalan`,
    register_email: `Email`,
    register_password: `Password`,
    register_confirm_password: `Kumpirmahin ang Password`,
    register_policy: `Sumasang-ayon ka na ang IUX Markets`,
    register_policy_link: `mga tuntunin at patakaran`,
    register_button_text: `paraan`,
    register_error_input: `Mangyaring punan ang lahat ng mga impormasyon`,
    register_error_choose_policy: `Mangyaring tanggapin ang mga tuntunin at kundisyon`,
    register_error_password_match: `Password ay hindi tumutugma`,
    register_error_english_name: `Pakilagay ang iyong unang pangalan o apelyido sa Ingles.`,
    register_phone: `numero ng telepono`,
    register_url: `URL website o Fanpage Facebook`,
    register_detail: `Ibahagi ang impormasyon tungkol sa iyong sarili sa amin.`,
    register_aeardy: "Aready use email",
    register_verify_title: "Verify success",
    register_verify_description: "You can login with your email",
    register_verify_go: "Go to login",
    register_verify_email: "Please verify your email.",
    register_password_validate:
      "Password must be 8-15 characters. Include lowercase or uppercase, and number.",
    register_name_validate: "Please enter information in English only.",
  },
  jp: {
    register_title: "アカウント登録",
    register_choose_region: "居住国 *",
    register_choose_region_placeholder: "あなたの居住国を選択してください *",
    register_fullname: "名前  *",
    register_firstname: "ファーストネーム *",
    register_lastname: "苗字 *",
    register_firstname_placeholder: "IDドキュメントに見られる *",
    register_lastname_placeholder: "IDドキュメントに見られる *",
    register_email: "Eメール *",
    register_password: "パスワード *",
    register_confirm_password: "パスワードを認証する *",
    register_policy: "IUX市場に同意します",
    register_policy_link: "用語とポリシー。 \n",
    register_button_text: "提出する",
    register_error_input: "すべての情報に記入してください",
    register_error_choose_policy: "利用規約を受け入れてください",
    register_error_password_match: "パスワードが一致していません",
    register_error_english_name: "英語で最初の名前または姓を入力してください。",
    register_phone: "電話番号",
    register_url: "ソーシャルリンク",
    register_detail: "マーケティング戦略を説明してください",
    register_aeardy: "すでにメールを使用しています",
    register_verify_title: "成功を確認します",
    register_verify_description: "メールでログインできます",
    register_verify_go: "ログインに移動します",
    register_verify_email: "メールを確認してください。",
    register_password_validate:
      "パスワードは8〜15文字でなければなりません。 小文字または大文字、および番号を含めます。",
  },
};
