import React, { Component } from 'react'

export default class Calender extends Component {
    render() {
        return (
            <div>
                {/* <div id="webterminal" style="width:100%;height:600px;"></div>
                <script type="text/javascript" src="https://trade.mql5.com/trade/widget.js"></script>
                <script type="text/javascript">
                    new MetaTraderWebTerminal( "webterminal", {
                        version: 4,
                    servers: ["IUXMarket-Demo", "IUXMarket-Live"],
                    server: "IUXMarket-Demo",
                    demoAllServers: true,
                    utmSource: "www.iuxmarket.com",
                    startMode: "create_demo",
                    language: "en",
                    colorScheme: "black_on_white"
                } );
                </script> */}
            </div>
        )
    }
}
