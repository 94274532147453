import { Collapse, Icon } from "antd";
import React, { useEffect, useState } from "react";
import { getSymbolsSwap } from "../../api/account";
const { Panel } = Collapse;
const locale = require("react-redux-i18n").I18n;
function TableSwap({ islamic }) {
  const pathName = window.location.pathname;
  const [dataSwap, setDataSwap] = useState(null);
  const groupSymbols = [
    { title: "Major Currencies ", value: "Forex Major" },
    { title: "Minor Currencies ", value: "Forex Minor" },
    { title: "Exotic Currencies", value: "forex" },
    { title: "Metals&Energies", value: "Metals&Energies" },
    { title: "Crypto", value: "crypto" },
    { title: "Index", value: "index" },
    { title: "Stock", value: "stock" },
  ];
  const fetchAllSwap = async () => {
    try {
      const { data } = await getSymbolsSwap(
        pathName.split("/")[4] || "Standard"
      );
      setDataSwap(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllSwap();
  }, [pathName]);
  //   console.log(dataSwap, "dataSwap");
  const addDefaultSrc = (ev) => {
    ev.target.src = `/assets/images/icons/globe.svg`;
  };
  return (
    <section key={5}>
      <div className="container">
        <div className="row text-white swap-page-responsive">
          <div className="col-2">
            Markets <Icon type="down" />
          </div>
          <div className="col-2 text-right">
            <div className="fs-16">Minimum spread</div>
            <div className="text-lightgray ">pips</div>
          </div>
          <div className="col-2 text-right">
            <div>Avg. spread</div>
            <div className="text-lightgray ">pips</div>
          </div>
          <div className="col-2 text-right">
            <div>Long swap</div>
            <div className="text-lightgray ">pips</div>
          </div>
          <div className="col-2 text-right">
            <div>Short swap</div>
            <div className="text-lightgray ">pips</div>
          </div>
          <div className="col-2 text-right">
            <div>Commission</div>
            <div className="text-lightgray ">per lot/side</div>
          </div>
        </div>
      </div>
      <div className="mt-2 swap-pages swap-page-responsive">
        {groupSymbols.map((item, index) => {
          return (
            <Collapse
              key={index}
              bordered={false}
              expandIcon={({ isActive }) => (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  {isActive ? (
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.13802 11.1381L2.19521 10.1953L7.99995 4.39051L13.8047 10.1953L12.8619 11.1381L7.99995 6.27613L3.13802 11.1381Z"
                      fill="white"
                    />
                  ) : (
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.862 4.86194L13.8048 5.80475L8.00005 11.6095L2.19531 5.80475L3.13812 4.86194L8.00005 9.72387L12.862 4.86194Z"
                      fill="white"
                    />
                  )}
                </svg>
              )}
              expandIconPosition={"right"}
            >
              <Panel
                header={item.title}
                key={index}
                className="site-collapse-custom-panel"
              >
                {dataSwap
                  ?.filter(
                    (e) => e.group.toLowerCase() === item.value.toLowerCase()
                  )
                  ?.map((e, i) => (
                    <div
                      className="row text-white p-3 "
                      style={{ borderBottom: "1px solid #393939" }}
                      key={i}
                    >
                      <div className="col-3 pl-0 d-flex">
                        <img
                          onError={addDefaultSrc}
                          src={`${process.env.REACT_APP_SYMBOL}symbol/${
                            e.name.split("-")[1] === "iux"
                              ? e.name.split("-")[0].toLowerCase()
                              : e.name.split(".")[0].toLowerCase()
                          }`}
                          width={20}
                          height={20}
                          alt={e.name.split(".")[0]}
                          style={{ marginRight: 8, borderRadius: "50%" }}
                        />
                        <div>
                          {e.name}
                          <div className="fs-12 text-lightgray">
                            {e.description}
                          </div>
                          {!islamic && (
                            <div
                              className="text-primary-new-iux d-flex align-items-center  fs-10"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="6"
                                viewBox="0 0 6 6"
                                fill="none"
                              >
                                <circle cx="3" cy="3" r="3" fill="#1CB894" />
                              </svg>
                              &nbsp;Free-swap status available
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-1 text-right">
                        {e.minimum_spread.toFixed(1)}
                      </div>
                      <div className="col-2 text-right">
                        {e.avg_spread.toFixed(1)}
                      </div>
                      <div className="col-2 text-right">
                        {islamic ? "0.0" : e.swap_long.toFixed(1)}
                      </div>
                      <div className="col-2 text-right">
                        {islamic ? "0.0" : e.swap_short.toFixed(1)}
                      </div>
                      <div className="col-2 text-right">
                        ${e.commission.toFixed(1)}
                      </div>
                    </div>
                  ))}
              </Panel>
            </Collapse>
          );
        })}
      </div>
    </section>
  );
}

export default TableSwap;
