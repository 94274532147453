import React from "react";
import PropTypes from "prop-types";
import FooterLandingPage from "../../components/footer-landing-page/FooterDark";
import { Helmet } from "react-helmet";
import RcQueueAnim from "rc-queue-anim";
import TextWithCover from "../../components/text-with-cover/TextWithCover";
import { Link, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import ForexPage from "./forex";
import FuturesPage from "./futures";
import IndiesPage from "./indices";
import SharesPage from "./shares";
import MetalsPage from "./metals";
import EnergiesPage from "./energies";

const TITLE = "Metatrader | IUX Markets";
const locale = require("react-redux-i18n").I18n;

const MenuItem = styled.div`
  padding: 0.8rem 1rem;
  color: ${(props) => props.color};
  border-bottom: ${(props) => props.borderBottom};
  white-space: pre;
  font-weight: ${(props) => props.fontWeight};
`;

const MarketsIndex = () => {
  const pathName = window.location.pathname;
  const [isIdex, setIndex] = React.useState(0);
  const menu = [
    {
      id: 0,
      link: `/${localStorage.getItem("locale")}/market/cfds`,
      text: locale.t("iux_forex_page.title_1"),
    },
    {
      id: 1,
      link: `/${localStorage.getItem("locale")}/market/futures`,
      text: locale.t("iux_futures_page.title_1"),
    },
    {
      id: 2,
      link: `/${localStorage.getItem("locale")}/market/indices`,
      text: locale.t("iux_indices_page.title_1"),
    },
    {
      id: 3,
      link: `/${localStorage.getItem("locale")}/market/shares`,
      text: locale.t("iux_shares_page.title_1"),
    },
    {
      id: 4,
      link: `/${localStorage.getItem("locale")}/market/metals`,
      text: locale.t("iux_metals_page.title_1"),
    },
    {
      id: 5,
      link: `/${localStorage.getItem("locale")}/market/energies`,
      text: locale.t("iux_energies_page.title_1"),
    },
  ];

  const imgcover = [
    {
      title: locale.t("iux_forex_page.title_1"),
      description: locale.t("iux_forex_page.title_2"),
      image: "/assets/images/covers/cover-forex.jpg",
    },
    {
      title: locale.t("iux_futures_page.title_1"),
      description: locale.t("iux_futures_page.title_2"),
      image: "/assets/images/covers/cover-futures.jpg",
    },
    {
      title: locale.t("iux_indices_page.title_1"),
      description: locale.t("iux_indices_page.title_2"),
      image: "/assets/images/covers/cover-indices.jpg",
    },
    {
      title: locale.t("iux_shares_page.title_1"),
      description: locale.t("iux_shares_page.title_2"),
      image: "/assets/images/covers/cover-stock.jpg",
    },
    {
      title: locale.t("iux_metals_page.title_1"),
      description: locale.t("iux_metals_page.title_2"),
      image: "/assets/images/covers/cover-metal.jpg",
    },
    {
      title: locale.t("iux_energies_page.title_1"),
      description: locale.t("iux_energies_page.title_2"),
      image: "/assets/images/covers/cover-cfds.jpg",
    },
  ];

  React.useEffect(() => {
    const activeCover = () => {
      if (pathName) {
        menu.filter((e, i) => e.link === pathName && setIndex(e.id));
      }
    };
    activeCover();
  }, [pathName]);

  return (
    <div>
      <RcQueueAnim delay={300}>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="bg-black">
          <TextWithCover
            key={1}
            imagePath={process.env.PUBLIC_URL + imgcover[isIdex].image}
            title={imgcover[isIdex].title}
            content={
              <>
                <div>
                  <p>{imgcover[isIdex].description}</p>
                  {/* {renderButtonCover()} */}
                </div>
              </>
            }
          />
          <div className="sub-menu-page">
            <div className="container">
              <div className="d-flex overflow-auto scroll-menu-hide">
                {menu.map((e, i) => (
                  <Link to={e.link}>
                    <MenuItem
                      onClick={() => setIndex(i)}
                      color={e.link === pathName ? "#FFF" : "#8D8D8D"}
                      borderBottom={
                        e.link === pathName
                          ? "4px solid #1CB894"
                          : "2px solid #ff000000"
                      }
                      fontWeight={e.link === pathName ? 400 : "unset"}
                    >
                      {e.text}
                    </MenuItem>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <Switch>
            <Route
              path="/:lang/market/energies"
              render={() => {
                return <EnergiesPage />;
              }}
            />
            <Route
              path="/:lang/market/metals"
              render={() => {
                return <MetalsPage />;
              }}
            />
            <Route
              path="/:lang/market/shares"
              render={() => {
                return <SharesPage />;
              }}
            />
            <Route
              path="/:lang/market/indices"
              render={() => {
                return <IndiesPage />;
              }}
            />
            <Route
              path="/:lang/market/futures"
              render={() => {
                return <FuturesPage />;
              }}
            />
            <Route
              path="/:lang/market/cfds"
              render={() => {
                return <ForexPage />;
              }}
            />
            <Route
              path="/:lang/market"
              render={() => {
                return <ForexPage />;
              }}
            />
          </Switch>
          <div>
            <FooterLandingPage locale={locale} />
          </div>
        </div>
      </RcQueueAnim>
    </div>
  );
};

export default MarketsIndex;
