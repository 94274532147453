// import React, { Component, Fragment } from "react";
// import { Row, Divider, Input, Form, Button, Col, message } from "antd";
// import { connect } from "react-redux";
// import { post } from "../../../../helper/request";
// import { LoadingOutlined } from "@ant-design/icons";
// const locale = require("react-redux-i18n").I18n;

// class WithDrawThirdStep extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       loading: false,
//       buttonLoading: false,
//       withdraw_id: props.account_withdraw_id,
//       payment_id: props.paymentId,
//     };
//   }

//   onFinish = (values) => {
//     this.setState({
//       buttonLoading: true,
//     });
//     post(process.env.REACT_APP_API_URL + "v2/user/account/withdraw", {
//       payment_id: this.state.payment_id,
//       account_withdraw_id: this.state.withdraw_id,
//       verify_code: values.code,
//     })
//       .then((res) => {
//         this.setState({
//           buttonLoading: false,
//         });
//         this.props.onSuccess({
//           message: {
//             title: locale.t("withdraw_info.success.title"),
//             description: locale.t("description.success.description"),
//           },
//         });
//       })
//       .catch((e) => {
//         this.setState({
//           buttonLoading: false,
//         });
//         message.error(e.response.data.error);
//       });
//   };
//   render() {
//     const WithdrawFormCrypto = () => {
//       return (
//         <div style={{ padding: "0 40px" }}>
//           <Row>
//             <h4 className="mt-2 mb-2">{locale.t("withdraw_info.result")}</h4>
//           </Row>
//           <Row>
//             <Col span={12}>{locale.t("withdraw_info.account.name")}:</Col>
//             <Col span={12}>{this.props.bookBankVerify.bank_account_name}</Col>
//           </Row>
//           <Divider />
//           <Row>
//             <Col span={12}>{locale.t("withdraw_info.account.number")}:</Col>
//             <Col span={12}>
//               {this.props.paymentKey === "alphapo"
//                 ? this.props.address
//                 : this.props.bookBankVerify.bank_account_number}
//             </Col>
//           </Row>
//           <Divider />
//           <Row>
//             <Col span={12}>{locale.t("withdraw_info.account.bank")}:</Col>
//             <Col span={12}>{this.props.bookBankVerify.bank_name}</Col>
//           </Row>
//           <Divider />
//           <Row>
//             <Col span={12}>{locale.t("withdraw_info.account.amount")}:</Col>
//             <Col span={12}>
//               <span>
//                 {this.props.amount}{" "}
//                 {this.props.account.account_type === "Cent" ? "USC" : "USD"}
//               </span>
//             </Col>
//           </Row>
//           <Divider />
//           <p>{`${locale.t("withdraw_info.account.Code")} ${
//             this.props.otp_to === "phone" ? "SMS" : "Email"
//           }`}</p>
//           <Form onFinish={this.onFinish}>
//             <Form.Item name="code" rules={[{ required: true }]}>
//               <Input
//                 style={{ width: "20%", margin: "16px 0" }}
//                 className="w-sm-100"
//               />
//             </Form.Item>
//             <Divider />
//             <div style={{ display: "inline-block", width: "100%" }}>
//               <Form.Item style={{ float: "center" }}>
//                 <Button
//                   type="primary"
//                   htmlType="submit"
//                   loading={this.state.buttonLoading}
//                   className="btn-primary-new-iux"
//                 >
//                   submit
//                 </Button>
//               </Form.Item>
//             </div>
//           </Form>
//         </div>
//       );
//     };
//     const renderCase = () => {
//       switch (this.props.paymentKey) {
//         case "thai_bank":
//           return <WithdrawFormCrypto />;
//         case "alphapo":
//           return <WithdrawFormCrypto />;
//         case "laos_bank":
//           return <WithdrawFormCrypto />;
//         default:
//           return <WithdrawFormCrypto />;
//       }
//     };
//     return !this.state.loading ? (
//       <Fragment>{renderCase()}</Fragment>
//     ) : (
//       <LoadingOutlined />
//     );
//   }
// }

// const mapStateToProps = ({ user, i18n }) => {
//   const { bookBankVerify, otp_to } = user;
//   return { bookBankVerify, locale: i18n.locale, otp_to };
// };

// export default connect(mapStateToProps, null)(WithDrawThirdStep);
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Row, Divider, Input, Form, Button, Col, message } from "antd";
import { post } from "../../../../helper/request";
import { LoadingOutlined } from "@ant-design/icons";
import OtpInput from "../../../../components/otpinput/OtpInput";
import { CheckCerrency } from "../../../../components/checkcurrency";
const locale = require("react-redux-i18n").I18n;
export const WithDrawThirdStep = (props) => {
  const { account_withdraw_id, paymentKey, paymentSelected, paymentId, rate } =
    props;
  const profileCountry = useSelector((state) => state.user.profileCountry);
  const [loading, setLoading] = useState(false);
  const [laos, setLaos] = useState(null);
  // const [rate, setRate] = useState(0);
  const [suffix, setSuffix] = useState("");
  const [prefix, setPrefix] = useState("");
  const [otp, setOtp] = useState("");
  const onChange = (value) => setOtp(value);
  const [buttonLoading, setButtonLoading] = useState(false);
  const onFinish = (values) => {
    setButtonLoading(true);
    post(process.env.REACT_APP_API_URL + "v2/user/account/withdraw", {
      payment_id: paymentSelected.id,
      account_withdraw_id: account_withdraw_id.account_withdraw_id,
      verify_code: otp,
    })
      .then((res) => {
        setButtonLoading(false);

        props.onSuccess({
          message: {
            title: locale.t("withdraw_info.success.title"),
            description: locale.t("description.success.description"),
          },
        });
      })
      .catch((e) => {
        setButtonLoading(false);
        message.error(e?.response?.data?.error);
      });
  };

  const WithdrawFormCrypto = () => {
    return (
      <div style={{ padding: "0 40px" }} className="user">
        <Row>
          <h4 className="mt-2 mb-2">{locale.t("withdraw_info.result")}</h4>
        </Row>
        <Row>
          <Col span={12}>{locale.t("withdraw_info.account.name")}:</Col>
          <Col span={12}>{props.bookBankVerify.bank_account_name}</Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>{locale.t("withdraw_info.account.number")}:</Col>
          <Col span={12}>
            {props.paymentKey === "alphapo"
              ? props.address
              : props.bookBankVerify.bank_account_number}
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>{locale.t("withdraw_info.account.bank")}:</Col>
          <Col span={12}>{props.bookBankVerify.bank_name}</Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>{locale.t("withdraw_info.account.amount")}:</Col>
          <Col span={12}>
            <span>
              {props.amount}{" "}
              {props.account.account_type === "Cent" ? "USC" : "USD"}
            </span>
          </Col>
        </Row>
        <Divider />
        <p>{`${locale.t("withdraw_info.account.Code")} ${
          props.otp_to === "phone" ? "SMS" : "Email"
        }`}</p>
        <Form onFinish={onFinish}>
          <Form.Item name=" " rules={[{ required: true }]} className="user">
            {/* <Input
              style={{ width: "20%", margin: "16px 0" }}
              className="w-sm-100 "
            /> */}
            <OtpInput value={otp} valueLength={6} onChange={onChange} />
          </Form.Item>
          <Divider />
          <div style={{ display: "inline-block", width: "100%" }}>
            <Form.Item style={{ float: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={buttonLoading}
                className="btn-primary-new-iux"
              >
                submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  };
  const renderCase = () => {
    switch (props.paymentKey) {
      case "thai_bank":
        return <WithdrawFormCrypto />;
      case "alphapo":
        return <WithdrawFormCrypto />;
      case "laos_bank":
        return <WithdrawFormCrypto />;
      default:
        return <WithdrawFormCrypto />;
    }
  };
  useEffect(() => {
    setLoading(true);
    setPrefix(profileCountry?.symbol);
    if (props.user.profile.country_id === 10) {
      setSuffix("USD");
    }
    if (props.user.profile.country_id === 6) {
      setSuffix("USD");
    }
    if (props.user.profile.country_id === 1) {
      setSuffix("USD");
    }
    if (props.user.profile.country_id === 8) {
      setSuffix("USD");
    }
    if (props.user.profile.country_id === 9) {
      setSuffix("USD");
    }
    setLoading(false);
  }, [props.user.profile]);
  const renderRateText = (i) => {
    if (i) {
      if (paymentKey === "alphapo") {
        return `${locale.t("withdraw_info.amount")} (${prefix})`;
      } else {
        if (laos === 8) {
          return "ຈຳ ນວນເງິນ (LAK)";
        } else {
          return <div>{` (${prefix})`}</div>;
        }
      }
    } else {
      if (paymentKey === "alphapo") {
        return `${locale.t(
          "withdraw_info.currency_rate"
        )}(${prefix}/${suffix})`;
      } else if (props.account.account_type === "Cent") {
        if (laos === 8) {
          return "ອັດຕາເງິນຕາ (LAK-> USD)";
        } else {
          return (
            <div>{`${locale.t(
              "withdraw_info.currency_rate"
            )}(USD/${prefix})`}</div>
          );
        }
      } else {
        if (laos === 8) {
          return "ອັດຕາເງິນຕາ (LAK-> USD)";
        } else {
          return (
            <div>{`${locale.t(
              "withdraw_info.currency_rate"
            )}(${suffix}/${prefix})`}</div>
          );
        }
      }
    }
  };
  const renderRateWithdraw = () => {
    if (paymentKey === "alphapo") {
      if (isNaN((props.amount / props.rate).toFixed(6))) return 0.0;
      else return (props.amount / props.rate).toFixed(6);
    } else if (props.account.account_type === "Cent") {
      return ` ${((props.amount / 100) * parseFloat(props.rate)).toFixed(2)}`;
    } else {
      return ` ${(props.amount * parseFloat(props.rate)).toFixed(2)}`;
    }
  };

  return (
    <div className="user">
      <div className="user">
        <span className="fs-14 font-weight-bold ">
          3.{locale.t("withdraw_step.third_step.title")}
        </span>
        <div style={{ marginTop: 32 }}>
          <span className="w-12 text-gray my-3">
            {locale.t("deposit_step.second_step.you_receive")}
          </span>
          <div className="my-3 font-weight-bold d-flex fs-165">
            {renderRateWithdraw()}
            {renderRateText(true)}
          </div>
        </div>

        <div className="d-flex mt-3">
          <span className="text-gray">
            {locale.t("withdraw_info.account.name")}: &nbsp;
          </span>
          {props.bookBankVerify.bank_account_name}
        </div>

        <div className="d-flex mt-3">
          <span className="text-gray">
            {locale.t("withdraw_info.account.number")}:&nbsp;
          </span>
          <span span={12}>
            {props.paymentKey === "alphapo"
              ? props.address
              : props.bookBankVerify.bank_account_number}
          </span>
        </div>

        <div className="d-flex mt-3">
          <span className="text-gray">
            {locale.t("withdraw_info.account.bank")}:&nbsp;
          </span>
          <span>{props.bookBankVerify.bank_name}</span>
        </div>
        <div className="d-flex mt-3">
          <span className="text-gray">
            {" "}
            {locale.t("deposit_step.second_step.fee")}:&nbsp;
          </span>
          <b>0</b>
        </div>

        <div className="d-flex mt-3">
          <span className="text-gray">
            {locale.t("withdraw_info.account.amount")}:&nbsp;
          </span>
          <b>
            {props.account.account_type !== "Cent" && "$"}
            {props.amount && parseFloat(props.amount)}
            {props.account.account_type === "Cent" && "USC"}
          </b>
        </div>
        <Divider />
        <p className="text-gray">{`${locale.t("withdraw_info.account.Code")} ${
          props.otp_to === "phone" ? "SMS" : "Email"
        }`}</p>
        <Form onFinish={onFinish}>
          <div className="mt-3">
            <OtpInput value={otp} valueLength={6} onChange={onChange} />
          </div>
          <Divider />
          <div style={{ display: "inline-block", width: "100%" }}>
            <Form.Item style={{ float: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={buttonLoading}
                disabled={otp.trim().length !== 6}
                className="btn-primary-new-iux w-100"
              >
                {locale.t("register_button_text")}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

WithDrawThirdStep.propTypes = {};

const mapStateToProps = ({ user, i18n }) => {
  const { bookBankVerify, otp_to, profile } = user;
  return { bookBankVerify, locale: i18n.locale, otp_to, user };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WithDrawThirdStep);
