export default {
  th: {
    platform_windows_description:
      "เรามีแอพพลิเคชั่น MetaTrader 5 บน Windows แอพพลิเคชั่นเหล่านี้จะให้ เทรดเดอร์สามารถเข้าถึงบัญชีได้ทุกที่ที่ต้องการ แอพพลิเคชั่น MetaTrader โมบายของเราใช้ฟีเจอร์ขั้นสูงมากมายของ Windows เช่น การเปลี่ยน แนวนอนเป็นแนวตั้ง (การหมุนตัวเครื่อง) นอกจากนี้แอพพลิเคชั่นโมบาย MetaTrader ยังมีฟังก์ชั่นการเทรด ด้วยการคลิกเพียงครั้งเดียว ความสามารถในการปรับรูปแบบของคุณ รวมถึงกราฟและการวิเคราะห์ขั้นสูง ใช้แอพพลิเคชั่นโมบาย MetaTrader ของเราเพื่อสัมผัสกับความยืดหยุ่นที่ไม่มีจำกัด โดยที่ไม่ต้องลดความเร็ว หรือคุณภาพลง",
    platform_windows_5_description: `เรามีแอพพลิเคชั่น MetaTrader 5 บน Windows และ Mac OS การซื้อขายที่ไร้ขีดจำกัดรวมไปถึงค่าคอมมิชชั่นที่ต่ำกว่าแพลตฟอร์มอื่น ด้วยข้อกำหนดการซื้อขายที่ไม่มีข้อจำกัดที่ ด้วยคุณสมบัติของ Metatrader 5 นั้นรวบรวมสภาพคล่องที่หลากหลายแก้ปัญหาที่เกิดขึ้นบน Metatrader 5 เหมาะสำหรับเทรดเดอร์ทุกรูปแบบ และยังมีฟังก์ชันการเทรด ด้วยการคลิกเพียงครั้งเดียว ความสามารถในการปรับรูปแบบของคุณ รวมถึงกราฟและการวิเคราะห์ขั้นสูง ใช้แอพพลิเคชั่นโมบาย MetaTrader 5 ของเราเพื่อสัมผัสกับความยืดหยุ่นที่ไม่มีจำกัด โดยที่ไม่ต้องลดความเร็ว หรือคุณภาพลง`,
    platform_windows_header: {
      title: "MetaTrader สำหรับ Windows\n",
      sub_title:
        "ห้ามพลาดโอกาส! ติดตามตลาดตลอดเวลาด้วยแอพ MetaTrader สำหรับ Windows\n",
      button_text_5: "ดาวน์โหลด Metatrader 5",
      button_text: "ดาวน์โหลด Metatrader 5",
    },
  },
  la: {
    platform_windows_description:
      "ພວກເຮົາມີໂປແກຼມ Metatrader 5 ແລະ MetaTrader 5 ໃນ Windows. ຄຳ ຮ້ອງສະ ໝັກ ເຫຼົ່ານີ້ຈະຊ່ວຍໃຫ້ພໍ່ຄ້າສາມາດເຂົ້າເຖິງບັນຊີໄດ້ທຸກທີ່ທີ່ຕ້ອງການ. ໂປແກມມືຖື MetaTrader ຂອງພວກເຮົາໃຊ້ຄຸນລັກສະນະ Windows ທີ່ກ້າວ ໜ້າ ຫຼາຍຢ່າງເຊັ່ນ: ການປ່ຽນພູມສັນຖານໄປເປັນຮູບຄົນ. (ການ ໝູນ ວຽນແບບມືຖື) ນອກຈາກນັ້ນ, ໂປແກຼມມືຖື MetaTrader ຍັງສະ ໜອງ ການເຮັດວຽກການຄ້າດ້ວຍການກົດພຽງເທື່ອດຽວຄວາມສາມາດໃນການປັບແຕ່ງຮູບແບບຂອງທ່ານລວມທັງກາຟິກທີ່ກ້າວ ໜ້າ ແລະການວິເຄາະການ ນຳ ໃຊ້ໂປແກມ MetaTrader ມືຖືຂອງພວກເຮົາເພື່ອປະສົບກັບຄວາມຍືດຫຍຸ່ນບໍ່ ຈຳ ກັດ. ໂດຍບໍ່ ຈຳ ເປັນຕ້ອງຫຼຸດຜ່ອນຄວາມໄວຫລືຄຸນນະພາບ",
    platform_windows_5_description: `We offer MetaTrader 5 applications for Windows and Mac OS, with unlimited trading, lower latency and commissions than other platforms. MetaTrader 5 features a wide range of liquidity, and also fixes the problems that arose on MetaTrader 4. MetaTrader 5 is suitable for traders. You can simply trade with just the ‘one click’ function. MetaTrader 5 also comes with the ability to adjust your trading styles including advanced charting and analysis. To enjoy limitless versatility, use MetaTrader 5.`,
    platform_windows_header: {
      title: "MetaTrader ສຳ ລັບ Windows\n",
      sub_title:
        "ຢ່າພາດໂອກາດ! ຕິດຕາມຕະຫລາດຕະຫຼອດເວລາດ້ວຍແອັບ MetaTrader ສຳ ລັບ Windows\n",
      button_text_5: "ດາວໂຫລດ Metatrader 5",
      button_text: "ດາວໂຫລດ Metatrader 5 ",
    },
  },
  en: {
    platform_windows_description:
      "We have the  MetaTrader 5 applications on the Windows. These applications will provide Traders can access the account wherever they want. Our MetaTrader mobile application uses many advanced Windows features such as changing landscape to portrait. (Handset rotation) In addition, the MetaTrader mobile application also provides trading functions With just one click The ability to adjust your style Including advanced graphs and analysis Use our MetaTrader mobile application to experience unlimited flexibility. Without having to reduce the speed or quality",
    platform_windows_5_description: `We offer MetaTrader 5 applications for Windows and Mac OS, with unlimited trading, lower latency and commissions than other platforms. MetaTrader 5 features a wide range of liquidity, and also fixes the problems that arose on MetaTrader 4. MetaTrader 5 is suitable for traders. You can simply trade with just the ‘one click’ function. MetaTrader 5 also comes with the ability to adjust your trading styles including advanced charting and analysis. To enjoy limitless versatility, use MetaTrader 5.`,
    platform_windows_header: {
      title: "MetaTrader for Windows\n",
      sub_title: `Donn't miss the opportunity! Follow the market all the time with the MetaTrader app for Windows.`,
      button_text_5: "Download Metatrader 5",
      button_text: "Download Metatrader 5",
    },
  },
  in: {
    platform_windows_description:
      "We have the  MetaTrader 5 applications on the Windows. These applications will provide Traders can access the account wherever they want. Our MetaTrader mobile application uses many advanced Windows features such as changing landscape to portrait. (Handset rotation) In addition, the MetaTrader mobile application also provides trading functions With just one click The ability to adjust your style Including advanced graphs and analysis Use our MetaTrader mobile application to experience unlimited flexibility. Without having to reduce the speed or quality",
    platform_windows_5_description: `We offer MetaTrader 5 applications for Windows and Mac OS, with unlimited trading, lower latency and commissions than other platforms. MetaTrader 5 features a wide range of liquidity, and also fixes the problems that arose on MetaTrader 4. MetaTrader 5 is suitable for traders. You can simply trade with just the ‘one click’ function. MetaTrader 5 also comes with the ability to adjust your trading styles including advanced charting and analysis. To enjoy limitless versatility, use MetaTrader 5.`,
    platform_windows_header: {
      title: "MetaTrader for Windows\n",
      sub_title: `Donn't miss the opportunity! Follow the market all the time with the MetaTrader app for Windows.`,
      button_text_5: "Download Metatrader 5",
      button_text: "Download Metatrader 5",
    },
  },
  my: {
    platform_windows_description: `Kami mempunyai Aplikasi Metatrader 5 dan MetaTrader 5  pada Windows. Aplikasi ini akan memberikan Trader boleh mengakses akaun di mana sahaja yang Anda mahu. Aplikasi mudah alih MetaTrader kami menggunakan banyak ciri Windows berkelas seperti peralihan landskap kepada potret. Selain itu, aplikasi mudah alih MetaTrader mempunyai fungsi dagangan. dengan satu klik Keupayaan untuk menyesuaikan gaya anda termasuk graf dan analisis level tinggi Gunakan aplikasi mudah alih MetaTrader kami untuk mengalami fleksibiliti tanpa had. tanpa mengorbankan kelajuan atau kualiti`,
    platform_windows_5_description: `Kami menawarkan aplikasi MetaTrader 5 untuk Windows dan Mac OS, dagangan tanpa had, kependaman rendah dan komisen yang lebih rendah daripada platform lain. Metatrader 5 menampilkan pelbagai kecairan, menyelesaikan masalah yang timbul pada Metatrader 5. Sesuai untuk semua jenis pedagang. dan juga mempunyai fungsi dagangan dengan satu klik. Keupayaan untuk melaraskan gaya anda termasuk graf dan analisis terbaik. Gunakan aplikasi mudah alih MetaTrader 5 kami untuk mengalami fleksibiliti tanpa had, tanpa mengorbankan kelajuan atau kualiti`,
    platform_windows_header: {
      title: `MetaTrader untuk Windows`,
      sub_title: `Jangan lepaskan peluang! Ikut pasaran sepanjang masa dengan aplikasi MetaTrader untuk Windows`,
      button_text_5: `Muat turun Metatrader 5`,
      button_text: `Muat turun Metatrader 5`,
    },
  },
  id: {
    platform_windows_description: `Kami memiliki Metatrader 5 dan MetaTrader 5 aplikasi pada Windows. Aplikasi ini akan memberikan Pedagang dapat mengakses akun mana pun mereka inginkan. aplikasi mobile MetaTrader kami menggunakan banyak Windows fitur canggih seperti mengubah landscape ke portrait. (Handset rotasi) Selain itu, MetaTrader aplikasi mobile juga menyediakan perdagangan fungsi Dengan hanya satu klik Kemampuan untuk menyesuaikan gaya Anda Termasuk grafik canggih dan analisis Gunakan kami MetaTrader aplikasi mobile untuk mengalami fleksibilitas tak terbatas. Tanpa harus mengurangi kecepatan atau kualitas`,
    platform_windows_5_description: `We offer MetaTrader 5 applications for Windows and Mac OS, with unlimited trading, lower latency and commissions than other platforms. MetaTrader 5 features a wide range of liquidity, and also fixes the problems that arose on MetaTrader 4. MetaTrader 5 is suitable for traders. You can simply trade with just the ‘one click’ function. MetaTrader 5 also comes with the ability to adjust your trading styles including advanced charting and analysis. To enjoy limitless versatility, use MetaTrader 5.`,
    platform_windows_header: {
      title: `MetaTrader untuk Windows`,
      sub_title: `Jangan lewatkan kesempatan! Ikuti pasar sepanjang waktu dengan aplikasi MetaTrader untuk Windows`,
      button_text_5: `Download Metatrader 5`,
      button_text: `Download Metatrader 5`,
    },
  },
  cn: {
    platform_windows_description: `我们对WINDOWS MetaTrader的4和MetaTrader 5的应用程序。这些应用程序将提供交易员可以随时随地访问他们需要的帐户。我们的MetaTrader的移动应用程序使用了许多先进的功能WINDOWNS如改变景观肖像。 （手机旋转）此外，MetaTrader的移动应用程序还提供交易功能，只需点击一下，调整自己的风格，包括先进的图表和分析使用我们的MetaTrader的移动应用体验无限的灵活性的能力。而不必降低速度或质量`,
    platform_windows_5_description: `We offer MetaTrader 5 applications for Windows and Mac OS, with unlimited trading, lower latency and commissions than other platforms. MetaTrader 5 features a wide range of liquidity, and also fixes the problems that arose on MetaTrader 4. MetaTrader 5 is suitable for traders. You can simply trade with just the ‘one click’ function. MetaTrader 5 also comes with the ability to adjust your trading styles including advanced charting and analysis. To enjoy limitless versatility, use MetaTrader 5.`,
    platform_windows_header: {
      title: `MetaTrader的用于WINDOWS`,
      sub_title: `千万不要错过机会！按照市场上所有的时间与WINDOWS MetaTrader的应用`,
      button_text_5: `下载MetaTrader 5 `,
      button_text: `下载Metatrader 5`,
    },
  },
  vn: {
    platform_windows_description:
      "Chúng tôi có ứng dụng Metatrader 5 và MetaTrader 5  trên Windows. Các ứng dụng này sẽ cung cấp cho nhà giao  có thể truy cập vào tài khoản bất cứ nơi nào họ muốn. Ứng dụng MetaTrader di động của chúng tôi sử dụng nhiều Windows tiên tiến tính năng như thay đổi, phong cảnh đến chân dung. (Thiết bị cầm tay xoay). Bên cạnh đó, MetaTrader ứng dụng di động cũng cung cấp giao dịch chức năng, chỉ với một cú nhấp hả năng điều chỉnh phong cách của bạn bao gồm các biểu đồ tiên tiến và phân tích sử dụng của chúng tôi MetaTrader ứng dụng di động để trải nghiệm tính linh hoạt không giới hạn mà không cần phải giảm tốc độ hoặc chất lượng.",
    platform_windows_5_description: `Chúng tôi có ứng dụng MetaTrader 5 trên Windows và Mac OS. Giao dịch không giới hạn, độ trễ thấp và hoa hồng thấp hơn các nền tảng khác. Với các yêu cầu giao dịch không hạn chế khác nhau từ Metatrader 5 với các tính năng của Metatrader 5 có tính thanh khoản đa dạng, giải quyết các vấn đề phát sinh trên Metatrader 5 thích hợp cho mọi nhà giao dịch. và có chức năng giao dịch chỉ với một cú nhấp,  Khả năng điều chỉnh phong cách của bạn bao gồm đồ thị và phân tích nâng cao sử dụng ứng dụng di động MetaTrader 5 của chúng tôi để trải nghiệm tính linh hoạt không giới hạn mà không giảm tốc độ hoặc chất lượng.`,
    platform_windows_header: {
      title: "MetaTrader đối với Windows\n",
      sub_title:
        "Đừng bỏ lỡ cơ hội! Thực hiện theo thị trường tất cả các thời gian với  ứng dụng MetaTrader đối với Windows.\n",
      button_text_5: "Tải xuống Metatrader 5",
      button_text: "Tải xuống Metatrader 5",
    },
  },
  kr: {
    platform_windows_description: `우리는 Windows에 메타 트레이더 4 메타 트레이더 5 응용 프로그램을. 이러한 응용 프로그램은 상인들이 원하는 목적지 계정에 액세스 할 수 있습니다 제공 할 것입니다. 우리의 메타 트레이더 모바일 응용 프로그램은 많은 고급 Windows 세로로 가로을 변경하는 등의 기능을 사용합니다. (핸드셋 회전)에서 추가의 메타 트레이더 모바일 응용 프로그램은 하나의 무제한 유연성을 경험할 수있는 고급 그래프와 분석을 사용하여 우리의 메타 트레이더 모바일 응용 프로그램을 포함하여 스타일을 조정하는 기능을 클릭 한 번으로 기능 거래를 제공합니다. 속도 나 품질이 저하하지 않고`,
    platform_windows_5_description: `We offer MetaTrader 5 applications for Windows and Mac OS, with unlimited trading, lower latency and commissions than other platforms. MetaTrader 5 features a wide range of liquidity, and also fixes the problems that arose on MetaTrader 4. MetaTrader 5 is suitable for traders. You can simply trade with just the ‘one click’ function. MetaTrader 5 also comes with the ability to adjust your trading styles including advanced charting and analysis. To enjoy limitless versatility, use MetaTrader 5.`,
    platform_windows_header: {
      title: `Windows에 대한 메타 트레이더`,
      sub_title: `기회를 놓치지 마세요! Windows에 대한 메타 트레이더의 응용 프로그램과 함께 시장을 항상 따라`,
      button_text_5: `Windows에 대한 메타 트레이더 5 다운로드`,
      button_text: `Windows에 대한 메타 트레이더 4 다운로드`,
    },
  },
  ph: {
    platform_windows_description: `Mayroon kaming ang Metatrader 5 at MetaTrader 5 application sa Windows. Ang mga aplikasyon ay magbibigay Traders maaaring ma-access ang account kung saan man nila gusto. Ang aming MetaTrader mobile application ay gumagamit ng maraming mga advanced na Windows Nagtatampok tulad ng pagpapalit ng landscape sa portrait. (Handset pag-ikot) Bilang karagdagan, ang MetaTrader mobile application ay nagbibigay din ng kalakalan function Sa isang pag-click lamang Ang kakayahan upang ayusin ang iyong estilo Kabilang ang mga advanced na mga graph at pagtatasa Gamitin ang aming MetaTrader mobile application upang maranasan walang limitasyong flexibility. Nang hindi na kinakailangang upang mabawasan ang bilis o kalidad`,
    platform_windows_5_description: `We offer MetaTrader 5 applications for Windows and Mac OS, with unlimited trading, lower latency and commissions than other platforms. MetaTrader 5 features a wide range of liquidity, and also fixes the problems that arose on MetaTrader 4. MetaTrader 5 is suitable for traders. You can simply trade with just the ‘one click’ function. MetaTrader 5 also comes with the ability to adjust your trading styles including advanced charting and analysis. To enjoy limitless versatility, use MetaTrader 5.`,
    platform_windows_header: {
      title: `MetaTrader para Windows`,
      sub_title: `Huwag palampasin ang pagkakataon! Sundin ang market sa lahat ng oras na may MetaTrader app para Windows`,
      button_text_5: `I-download ang Metatrader 5`,
      button_text: `I-download ang Metatrader 5 `,
    },
  },
  jp: {
    platform_windows_description:
      "WindowsにMetatrader 5アプリケーションがあります。 これらのアプリケーションは、トレーダーが必要な場所にアカウントにアクセスできるようにします。 Metatraderモバイルアプリケーションでは、風景の変更など、多くの高度なWindows機能をポートレートに使用しています。 （携帯電話の回転）さらに、Metatraderモバイルアプリケーションは、ワンクリックしてトレーディング機能を提供し、高度なグラフや分析などのスタイルを調整する機能をクリックして、メタトレーダーモバイルアプリケーションを使用して無制限の柔軟性を体験します。 速度や品質を減らす必要なく",
    platform_windows_5_description:
      "WindowsとMac OS向けのMetatrader 5アプリケーションを提供しており、無制限の取引、レイテンシ、委員会が他のプラットフォームよりも低い。 Metatrader 5は、幅広い流動性を備えており、Metatrader 4で発生した問題も修正します。Metatrader5はトレーダーに適しています。 「ワンクリック」機能だけで取引できます。 Metatrader 5には、高度なチャートや分析などの取引スタイルを調整する機能も備わっています。 無限の汎用性を楽しむには、Metatrader 5を使用します。",
    platform_windows_header: {
      title: "Windows のメタトレーダー \n",
      sub_title:
        "機会をお見逃しなく！ Windows用のMetatraderアプリで常に市場をフォローしてください。",
      button_text_5: "Metatrader 5をダウンロードします",
      button_text: "Metatrader 5をダウンロードします",
    },
  },
};
