export default {
  th: {
    bookbankverifyTitle: "ตรวจสอบธนาคาร",
    kycTitle: "	ตรวจสอบตัวตน	",
    step1: "	ตรวจสอบเอกสาร	",
    step2: "	ข้อมูลพื้นฐาน	",
    step3: "	ตรวจสอบความสำเร็จ	",
    continue: "	ดำเนินการต่อ	",
    verifyidentity: "	ยืนยันตัวตนของคุณ	",
    acceptedfile:
      "	ประเภทไฟล์ที่ยอมรับได้คือ JPG, JPEG, PNG และขนาดไฟล์สูงสุดคือ 8 MB	",
    cameradevice: "	อุปกรณ์กล้องถ่ายรูป	",
    uploadfile: "	อัปโหลดไฟล์	",
    or: "	หรือ	",
    anotherdevice: "	ใช้อุปกรณ์อื่น	",
    anotherdeviceDetail:
      "	ข้อมูลส่วนบุคคลของคุณปลอดภัย ข้อมูลที่สกัดจากเอกสาร ID ของคุณอยู่ในIUX Markets และไม่เคยถูกแชร์กับ บริษัท อื่น ๆ	",
    scancode: "	สแกนรหัสด้านล่างเพื่อใช้อุปกรณ์อื่น	",
    back: "	กลับ	",
    verificationFailed: "	การตรวจสอบล้มเหลว	",
    sorryverify: "	ขออภัย! เราไม่สามารถยืนยันตัวตนของคุณได้	",
    needsupport:
      "ต้องการความช่วยเหลือ? เราอยู่ที่นี่เพื่อช่วยคุณ 24/7 \n โปรดติดต่อเราผ่านไลฟ์แชท",
    uploadAgian: "อัปโหลดอีกครั้ง",
    tempblock: "	ความพยายามตรวจสอบถูกบล็อกชั่วคราว	",
    tempblockdetail:
      "	ความพยายามในการตรวจสอบตัวตนได้รับการบล็อกชั่วคราวเป็นระยะเวลา 5 นาที กรุณาลองอัปโหลดเอกสารอื่นในภายหลัง	",
    permanently: "	ความพยายามตรวจสอบถูกบล็อกอย่างถาวร	",
    permanentlydetail:
      "	ความพยายามในการตรวจสอบตัวตนของคุณได้รับการบล็อกอย่างถาวร กรุณาติดต่อ IUX Market Supports ผ่านไลฟ์แชทเพื่อขอความช่วยเหลือเพิ่มเติม	",
    countdown: "	การนับถอยหลัง	",
    loadingtitle: "	ในขณะที่การประมวลผลนี้โปรดเปิดหน้านี้	",
    manualverify: "	การตรวจสอบตัวตนด้วยตนเอง	",
    trydemo: "บัญชีทดลอง",
    verifySuccess: "	ความสำเร็จในการตรวจสอบ	",
    verifySuccessdetial: "	ตัวตนของคุณได้รับการตรวจสอบแล้ว	",
    manualtitle: "	เอกสารประจำตัว	",
    manualsubtitle: "โปรดอัปโหลดรูปภาพ	",
    manualsubtitle2:
      "ภาพถ่ายควรสดใสและชัดเจนและเอกสารทั้งหมดของคุณจะต้องมองเห็นทุกมุมของเอกสาร",
    fontcard: "	ด้านหน้า	",
    backcard: "	ด้านหลัง	",
    manualselfie: "	อัปโหลดเซลฟี่ด้วยหลักฐาน	",
    manualselfiedetail:
      "	โปรดอัปโหลดเซลฟี่ด้วยเอกสาร Aadhaar ของคุณและเว็บไซต์ IUX Markets ที่เปิดในอินเทอร์เน็ตเบราว์เซอร์ในรูปภาพเดียวกัน ตรวจสอบให้แน่ใจว่าทั้งคู่มองเห็นได้ชัดเจนและใบหน้าของคุณไม่ครอบคลุม	",
    otpverifytitle: "	การตรวจสอบ OTP	",
    invalidphone: "	โทรศัพท์ไม่ถูกต้องโปรดแก้ไขหมายเลขโทรศัพท์ของคุณ	",
    requestotp: "	ขอ OTP	",
    enterotp: "	ป้อนรหัสจาก SMS ที่เราส่งไป	",
    codeexpiresin: "	รหัสหมดอายุใน	",
    seconds: "	ไม่กี่วินาที	",
    codenotmatch: "	รหัสไม่ตรงกัน	",
    bookbankuploadtitle:
      "	โปรดถ่ายภาพเอกสารประจำตัวของคุณและตรวจสอบให้แน่ใจว่าเอกสารของคุณเป็นไปตามข้อกำหนดดังต่อไปนี้	",
    bookbankuploaddetail1: "	คุณภาพดีสดใสและชัดเจน	",
    bookbankuploaddetail2: "	ประเภทไฟล์ JPG, JPEG, PNG, PDF	",
    bookbankuploaddetail3: "	ทุกมุมของเอกสารของคุณจะต้องปรากฏให้เห็น	",
    bookbankfilesize: "	ขนาดไฟล์ต้องไม่เกิน 8MB	",
    didotp: "ไม่ได้รับ OTP?",
    error_verify_type_file: "ขออภัยประเภทไฟล์ไม่รองรับ",
    error_verify_kyc: "ไม่ประสบความสำเร็จเราไม่สามารถแยกข้อมูลออกจากภาพได้",
    inprogress: "กำลังดำเนินการ",
    sendverifysuccess:
      "ส่งสำเร็จ! โดยทั่วไปใช้เวลาเพียง 3-15 นาทีเพื่อให้เราทำการตรวจสอบที่จำเป็นและยืนยันเอกสารของคุณให้เสร็จสมบูรณ์",
    kyc_scanning: "	กำลังสแกน ...	",
    kyc_detected: "	การตรวจจับประสบความสำเร็จ	",
    kyc_angle: "	เปลี่ยนมุม	",
    kyc_fail: "การตรวจจับล้มเหลว",
    kyc_move_far: "ขยับเอกสารให้ไกลออกไป",
    kyc_move: "	ขยับเอกสารให้ใกล้ชิดยิ่งขึ้น	",
  },
  la: {
    bookbankverifyTitle: "ຫຼັກຖານສະແດງການຢັ້ງຢືນທະນາຄານ",
    kycTitle: "	ຫຼັກຖານສະແດງການຢັ້ງຢືນຕົວຕົນ	",
    step1: "	ຢືນຢັນເອກະສານ	",
    step2: "	ຂໍ້ມູນພື້ນຖານ	",
    step3: "	ກວດສອບຄວາມສໍາເລັດ	",
    continue: "	ດໍາເນີນຕໍ່ໄປ	",
    verifyidentity: "	ຢືນຢັນຕົວຕົນຂອງທ່ານ	",
    acceptedfile:
      "	ປະເພດໄຟລ໌ທີ່ຍອມຮັບແມ່ນ JPG, JPEG, PNG, PNG ແລະຂະຫນາດໄຟລ໌ສູງສຸດແມ່ນ 8 MB.	",
    cameradevice: "	ອຸປະກອນກ້ອງ	",
    uploadfile: "	ອັບໂຫລດເອກະສານ	",
    or: "	ຫຼື	",
    anotherdevice: "	ໃຊ້ອຸປະກອນອື່ນ	",
    anotherdeviceDetail:
      "	ຂໍ້ມູນສ່ວນຕົວຂອງທ່ານແມ່ນປອດໄພ. ຂໍ້ມູນທີ່ສະກັດຈາກເອກະສານປະຈໍາຕົວຂອງທ່ານຢູ່ພາຍໃນຕະຫຼາດ iux ແລະບໍ່ເຄີຍແບ່ງປັນກັບບໍລິສັດອື່ນ.	",
    scancode: "	ສະແກນລະຫັດຂ້າງລຸ່ມນີ້ເພື່ອໃຊ້ອຸປະກອນອື່ນ.	",
    back: "	ກັບຄືນໄປບ່ອນ	",
    verificationFailed: "	ການຢັ້ງຢືນລົ້ມເຫລວ	",
    sorryverify: "	ຂໍ​ໂທດ! ພວກເຮົາບໍ່ສາມາດກວດສອບຕົວຕົນຂອງທ່ານໄດ້	",
    needsupport:
      "	ຕ້ອງການຄວາມຊ່ວຍເຫຼືອບໍ? ພວກເຮົາຢູ່ທີ່ນີ້ເພື່ອຊ່ວຍທ່ານ 24/7.  npletase ຕິດຕໍ່ພວກເຮົາໂດຍຜ່ານການສົນທະນາສົດໆ.	",
    uploadAgian: "ອັບໂຫລດອີກເທື່ອຫນຶ່ງ",
    tempblock: "	ຄວາມພະຍາຍາມກວດສອບໄດ້ຖືກປິດກັ້ນຊົ່ວຄາວ	",
    tempblockdetail:
      "	ຄວາມພະຍາຍາມກວດສອບຕົວຕົນໄດ້ຖືກປິດກັ້ນຊົ່ວຄາວໃນໄລຍະເວລາ 5 ນາທີ. ກະລຸນາລອງອັບໂຫລດເອກະສານອື່ນໃນເວລາຕໍ່ມາ.	",
    permanently: "	ຄວາມພະຍາຍາມກວດສອບໄດ້ຖືກສະກັດຢ່າງຖາວອນ	",
    permanentlydetail:
      "	ຄວາມພະຍາຍາມຢັ້ງຢືນຕົວຕົນຕົວຕົນຂອງທ່ານໄດ້ຖືກສະກັດກັ້ນຢ່າງຖາວອນ. ກະລຸນາຕິດຕໍ່ຕະຫຼາດ IIUX ສະຫນັບສະຫນູນໂດຍຜ່ານການສົນທະນາສົດໆສໍາລັບການຊ່ວຍເຫຼືອເພີ່ມເຕີມ.	",
    countdown: "	ນັບຖອຍຫລັງ	",
    loadingtitle: "	ໃນຂະນະທີ່ການປະມວນຜົນນີ້, ກະລຸນາຮັກສາຫນ້ານີ້ໃຫ້ເປີດ	",
    manualverify: "	ການກວດສອບຕົວຕົນດ້ວຍຕົນເອງ	",
    trydemo: "	ບັນຊີຕົວຢ່າງ	",
    verifySuccess: "	ຄວາມສໍາເລັດການຢັ້ງຢືນ	",
    verifySuccessdetial: "	ຕົວຕົນຂອງທ່ານໄດ້ຮັບການຢັ້ງຢືນແລ້ວ.	",
    manualtitle: "	ເອກະສານປະຈໍາຕົວ	",
    manualsubtitle: "	ກະລຸນາອັບໂຫລດຮູບ",
    manualsubtitle2:
      " ຮູບພາບດັ່ງກ່າວຄວນຈະສົດໃສແລະຊັດເຈນ, ແລະທັງຫມົດເຊື່ອມຕໍ່ຂອງເອກະສານຂອງທ່ານຕ້ອງເບິ່ງເຫັນໄດ້.	",
    fontcard: "	ແນວຫນ້າ	",
    backcard: "	ກັບຄືນໄປບ່ອນ	",
    manualselfie: "	ອັບໂຫລດ selfie ກັບຫຼັກຖານ	",
    manualselfiedetail:
      "	ກະລຸນາອັບໂຫລດເອກະພາບຂອງ Selfie ກັບເອກະສານ Aadhaar ຂອງທ່ານແລະເວັບໄຊທ໌ Aux Markets ໄດ້ເປີດຢູ່ໃນອິນເຕີເນັດ browser ໃນຮູບດຽວກັນ. ຮັບປະກັນວ່າທັງສອງແມ່ນເຫັນໄດ້ຊັດເຈນແລະໃບຫນ້າຂອງທ່ານບໍ່ໄດ້ຖືກປົກຄຸມ.	",
    otpverifytitle: "	ການຢັ້ງຢືນ OTP	",
    invalidphone: "	ໂທລະສັບບໍ່ຖືກຕ້ອງ, ດັດແກ້ເບີໂທລະສັບຂອງທ່ານ.	",
    requestotp: "	ຮ້ອງຂໍ OTP	",
    enterotp: "	ໃສ່ລະຫັດຈາກ SMS ທີ່ພວກເຮົາສົ່ງໄປ	",
    codeexpiresin: "	ລະຫັດຫມົດອາຍຸໃນ	",
    seconds: "	ວິນາທີ	",
    codenotmatch: "	ລະຫັດບໍ່ກົງກັນ.	",
    bookbankuploadtitle:
      "	ກະລຸນາຖ່າຍຮູບເອກະສານປະຈໍາຕົວຂອງທ່ານແລະໃຫ້ແນ່ໃຈວ່າເອກະສານຂອງທ່ານຕອບສະຫນອງກັບຂໍ້ກໍານົດດັ່ງຕໍ່ໄປນີ້.	",
    bookbankuploaddetail1: "	ຄຸນນະພາບດີ, ສົດໃສແລະຈະແຈ້ງ.	",
    bookbankuploaddetail2: "	ປະເພດເອກະສານ JPG, JPEG, PNG, PDF.	",
    bookbankuploaddetail3: "	ທຸກມຸມຂອງເອກະສານຂອງທ່ານຕ້ອງເບິ່ງເຫັນ.	",
    bookbankfilesize: "	ຂະຫນາດຂອງເອກະສານບໍ່ສາມາດເກີນ 8Mb.	",
    didotp: "ບໍ່ໄດ້ຮັບ OTP ບໍ?",
    error_verify_type_file: "ຂໍໂທດ, ປະເພດເອກະສານບໍ່ສາມາດຍອມຮັບໄດ້.",
    error_verify_kyc: "ບໍ່ສໍາເລັດຜົນ, ພວກເຮົາບໍ່ສາມາດສະກັດເອົາຂໍ້ມູນຈາກຮູບພາບ.",
    inprogress: "ມີຄວາມຄືບຫນ້າ",
    sendverifysuccess:
      "ຕ້ອງການຄວາມຊ່ວຍເຫຼືອບໍ? ພວກເຮົາຢູ່ທີ່ນີ້ເພື່ອຊ່ວຍທ່ານ 24/7 \n ກະລຸນາຕິດຕໍ່ຫາພວກເຮົາໂດຍຜ່ານການສົນທະນາທີ່ມີຊີວິດ.",
    kyc_scanning: "	ສະແກນ ...	",
    kyc_detected: "	ການຊອກຄົ້ນຫາຜົນປະສົບຜົນສໍາເລັດ	",
    kyc_angle: "	ປັບມຸມ	",
    kyc_fail: "ການຊອກຄົ້ນຫາລົ້ມເຫລວ",
    kyc_move_far: "ເອກະສານຍ້າຍອອກໄປໄກກວ່າ",
    kyc_move: "	C ເອກະສານຍ້າຍ C ເອກະສານ	",
  },
  en: {
    bookbankverifyTitle: "Proof of bank verification",
    kycTitle: "Proof of identity verification",
    step1: "Verify document",
    step2: "Base \ninformation",
    step3: "Verify success",
    continue: "Continue",
    verifyidentity: "Verify your identity",
    acceptedfile:
      "Accepted file types are jpg, jpeg, png and max file size is 8 MB.",
    cameradevice: "Camera device",
    uploadfile: "Upload file",
    or: "or",
    anotherdevice: "Use another device",
    anotherdeviceDetail:
      "Your personal information is secure. The data extracted from your ID document stays within IUX Markets and never gets shared with other companies.",
    scancode: "Scan the code below to use another device.",
    back: "Back",
    verificationFailed: `Verification Failed`,
    sorryverify: "Sorry! We couldn’t verify your identity",
    needsupport: `Need assistance? We are here to help you 24/7.\n Please contact us via Live Chat.`,
    uploadAgian: `Upload Again`,
    tempblock: `Verification attempt was temporarily blocked`,
    tempblockdetail: `Identity verification attempt has been temporarily blocked for a duration of 5 minutes. Kindly try uploading another document at a later time.`,
    permanently: `Verification attempt was permanently blocked`,
    permanentlydetail: `Your identity verification attempt has been permanently blocked. Please contact IUX Markets Support through Live Chat for further assistance.`,
    countdown: `Countdown`,
    loadingtitle: `While this processing, please keep this page open`,
    manualverify: `Manual identity verification`,
    trydemo: "Try Demo",
    verifySuccess: `Verification Success`,
    verifySuccessdetial: ` Your identity has been verified.`,
    manualtitle: `Identity document`,
    manualsubtitle: `Please upload a photo of your  `,
    manualsubtitle2:
      "The photo should be bright and clear, and all conners of your document must be visible.",
    fontcard: "Front",
    backcard: "Back",
    manualselfie: `Upload the selfie with the proof`,
    manualselfiedetail: `Please upload a selfie with your Aadhaar document and the IUX Markets website opened in the internet browser in the same photo. Ensure that both are clearly visible and that your face is not covered.`,
    otpverifytitle: `OTP Verification`,
    invalidphone: `Invalid phone, kindly edit your phone number.`,
    requestotp: `Request OTP`,
    enterotp: `Enter the code from the sms we sent to`,
    codeexpiresin: `Code expires in `,
    seconds: "seconds",
    codenotmatch: `The code doesn’t match.`,
    bookbankuploadtitle: `Please take a picture of your identity document and make sure your documents meet the following requirements.`,
    bookbankuploaddetail1: `Good quality, bright and clear.`,
    bookbankuploaddetail2: `File type jpg, jpeg, png, pdf.`,
    bookbankuploaddetail3: `All corners of your document must be visible.`,
    bookbankfilesize: `File size cannot exceed 8mb.`,
    didotp: "Didn’t receive OTP?",
    error_verify_type_file: "Sorry, file type cannot be accepted.",
    error_verify_kyc:
      "Unsuccessful, we were unable to extract information from image.",
    inprogress: "In progress",
    sendverifysuccess:
      "Successfully submitted! It typically takes just 3-15 minutes for us to complete the necessary checks and confirm your document.",
    kyc_scanning: "	Scanning...	",
    kyc_detected: "	Detection successful	",
    kyc_angle: "	Adjust the angle	",
    kyc_fail: "Detection failed",
    kyc_move_far: "Move document farther",
    kyc_move: "	Move document closer	",
  },
  in: {
    bookbankverifyTitle: "बैंक सत्यापन का प्रमाण",
    kycTitle: "	पहचान सत्यापन का प्रमाण	",
    step1: "	दस्तावेज़ सत्यापित करें	",
    step2: "	आधार सूचना	",
    step3: "	सफलता को सत्यापित करें	",
    continue: "	जारी रखना	",
    verifyidentity: "	अपनी पहचान सत्यापित करो	",
    acceptedfile:
      "	स्वीकृत फ़ाइल प्रकार JPG, JPEG, PNG और अधिकतम फ़ाइल का आकार 8 mb है।	",
    cameradevice: "	कैमरा युक्ति	",
    uploadfile: "	फ़ाइल अपलोड करें	",
    or: "	या	",
    anotherdevice: "	किसी अन्य डिवाइस का उपयोग करें	",
    anotherdeviceDetail:
      "	आपकी व्यक्तिगत जानकारी सुरक्षित है। आपके आईडी दस्तावेज़ से निकाला गया डेटा IUX Markets के भीतर रहता है और अन्य कंपनियों के साथ कभी भी साझा नहीं होता है।	",
    scancode:
      "	किसी अन्य डिवाइस का उपयोग करने के लिए नीचे दिए गए कोड को स्कैन करें।	",
    back: "	पीछे	",
    verificationFailed: "	सत्यापन में विफल रहा	",
    sorryverify: "	क्षमा मांगना! हम आपकी पहचान को सत्यापित नहीं कर सकते	",
    needsupport:
      "सहायता की जरूरत है? हम यहां आपकी मदद करने के लिए हैं 24/7 \n कृपया लाइव चैट के माध्यम से हमसे संपर्क करें।",
    uploadAgian: "	फिर से अपलोड करें	",
    tempblock: "	सत्यापन का प्रयास अस्थायी रूप से अवरुद्ध कर दिया गया था	",
    tempblockdetail:
      "	5 मिनट की अवधि के लिए पहचान सत्यापन प्रयास को अस्थायी रूप से अवरुद्ध कर दिया गया है। कृपया बाद में एक और दस्तावेज़ अपलोड करने का प्रयास करें।	",
    permanently: "	सत्यापन का प्रयास स्थायी रूप से अवरुद्ध किया गया था	",
    permanentlydetail:
      "	आपकी पहचान सत्यापन प्रयास को स्थायी रूप से अवरुद्ध कर दिया गया है। कृपया आगे की सहायता के लिए लाइव चैट के माध्यम से IUX मार्केट्स सपोर्ट से संपर्क करें।	",
    countdown: "	उलटी गिनती	",
    loadingtitle: "	इस प्रसंस्करण के दौरान, कृपया इस पृष्ठ को खुला रखें	",
    manualverify: "	मैनुअल पहचान सत्यापन	",
    trydemo: "	डेमो का प्रयास करें	",
    verifySuccess: "	सत्यापन सफलता	",
    verifySuccessdetial: "	आपकी पहचान सत्यापित की गई है।	",
    manualtitle: "	पहचान दस्तावेज़	",
    manualsubtitle: "	कृपया की एक तस्वीर अपलोड करें",
    manualsubtitle2:
      "फोटो उज्ज्वल और स्पष्ट होना चाहिए, और आपके दस्तावेज़ के सभी कॉनर दिखाई देना चाहिए।",
    fontcard: "	सामने	",
    backcard: "	पीछे	",
    manualselfie: "	सबूत के साथ सेल्फी अपलोड करें	",
    manualselfiedetail:
      "	कृपया अपने आधार दस्तावेज़ के साथ एक सेल्फी अपलोड करें और उसी फोटो में इंटरनेट ब्राउज़र में खोली गई IUX मार्केट्स वेबसाइट। सुनिश्चित करें कि दोनों स्पष्ट रूप से दिखाई दे रहे हैं और आपका चेहरा कवर नहीं है।	",
    otpverifytitle: "	ओटीपी सत्यापन	",
    invalidphone: "	अमान्य फोन, कृपया अपना फ़ोन नंबर संपादित करें।	",
    requestotp: "	अनुरोध ओटीपी	",
    enterotp: "	हमारे द्वारा भेजे गए एसएमएस से कोड दर्ज करें	",
    codeexpiresin: "	कोड समाप्त हो रहा है	",
    seconds: "	सेकंड	",
    codenotmatch: "	कोड मेल नहीं खाता है।	",
    bookbankuploadtitle:
      "	कृपया अपने पहचान दस्तावेज़ की एक तस्वीर लें और सुनिश्चित करें कि आपके दस्तावेज़ निम्नलिखित आवश्यकताओं को पूरा करते हैं।	",
    bookbankuploaddetail1: "	अच्छी गुणवत्ता, उज्ज्वल और स्पष्ट।	",
    bookbankuploaddetail2: "	फ़ाइल प्रकार JPG, JPEG, PNG, PDF।	",
    bookbankuploaddetail3: "	आपके दस्तावेज़ के सभी कोनों को दिखाई देना चाहिए।	",
    bookbankfilesize: "	फ़ाइल का आकार 8MB से अधिक नहीं हो सकता है।	",
    didotp: "क्या ओटीपी प्राप्त नहीं हुआ?",
    error_verify_type_file:
      "क्षमा करें, फ़ाइल प्रकार को स्वीकार नहीं किया जा सकता है।",
    error_verify_kyc: "असफल, हम छवि से जानकारी निकालने में असमर्थ थे।",
    inprogress: "प्रगति पर है",
    sendverifysuccess:
      "सफलतापूर्वक प्रस्तुत किया गया! आमतौर पर हमें आवश्यक चेक पूरा करने और अपने दस्तावेज़ की पुष्टि करने में सिर्फ 3-15 मिनट लगते हैं।",
    kyc_scanning: "	स्कैनिंग ...	",
    kyc_detected: "	पता लगाने में सफल	",
    kyc_angle: "	कोण को समायोजित करें	",
    kyc_fail: "पता लगाने में विफल रहा",
    kyc_move_far: "दस्तावेज़ आगे बढ़ें",
    kyc_move: "	दस्तावेज़ करीब ले जाएं	",
  },
  my: {
    bookbankverifyTitle: "Bukti pengesahan bank",
    kycTitle: "	Bukti Pengesahan Identiti	",
    step1: "	Sahkan dokumen	",
    step2: "	Maklumat asas	",
    step3: "	Mengesahkan kejayaan	",
    continue: "	Teruskan	",
    verifyidentity: "	Sahkan identiti anda	",
    acceptedfile:
      "	Jenis fail yang diterima ialah saiz fail JPG, JPEG, PNG dan Max ialah 8 MB.	",
    cameradevice: "	Peranti kamera	",
    uploadfile: "	Muat naik fail	",
    or: "	atau	",
    anotherdevice: "	Gunakan peranti lain	",
    anotherdeviceDetail:
      "	Maklumat peribadi anda selamat. Data yang diekstrak dari dokumen ID anda kekal di dalam pasaran IUX dan tidak pernah dikongsi dengan syarikat lain.	",
    scancode: "	Imbas kod di bawah untuk menggunakan peranti lain.	",
    back: "	Kembali	",
    verificationFailed: "	Pengesahan gagal	",
    sorryverify: "	Maaf! Kami tidak dapat mengesahkan identiti anda	",
    needsupport:
      "	Perlukan bantuan? Kami berada di sini untuk membantu anda 24/7 \n sila hubungi kami melalui sembang langsung.",
    uploadAgian: "	Muat naik lagi",
    tempblock: "	Percubaan pengesahan telah disekat buat sementara waktu	",
    tempblockdetail:
      "	Percubaan pengesahan identiti telah disekat buat sementara waktu selama 5 minit. Sila cuba memuat naik dokumen lain di kemudian hari.	",
    permanently: "	Percubaan pengesahan disekat secara kekal	",
    permanentlydetail:
      "	Percubaan pengesahan identiti anda telah disekat secara kekal. Sila hubungi sokongan pasaran IUX melalui sembang langsung untuk mendapatkan bantuan lanjut.	",
    countdown: "	Countdown	",
    loadingtitle: "	Semasa pemprosesan ini, sila pastikan halaman ini dibuka	",
    manualverify: "	Pengesahan identiti manual	",
    trydemo: "	Cuba demo	",
    verifySuccess: "	Kejayaan pengesahan	",
    verifySuccessdetial: "	Identiti anda telah disahkan.	",
    manualtitle: "	Dokumen identiti	",
    manualsubtitle: "	Sila muat naik foto",
    manualsubtitle2:
      "Foto itu harus cerah dan jelas, dan semua penyambung dokumen anda mesti kelihatan.",
    fontcard: "	Depan	",
    backcard: "	Kembali	",
    manualselfie: "	Muat naik selfie dengan bukti	",
    manualselfiedetail:
      "	Sila muat naik selfie dengan dokumen Aadhaar anda dan laman web IUX Markets dibuka di pelayar Internet dalam foto yang sama. Pastikan kedua -duanya kelihatan jelas dan wajah anda tidak dilindungi.	",
    otpverifytitle: "	Pengesahan OTP	",
    invalidphone: "	Telefon tidak sah, sila edit nombor telefon anda.	",
    requestotp: "	Minta OTP	",
    enterotp: "	Masukkan kod dari SMS yang kami hantar ke	",
    codeexpiresin: "	Kod tamat tempoh	",
    seconds: "	saat	",
    codenotmatch: "	Kod tidak sepadan.	",
    bookbankuploadtitle:
      "	Sila ambil gambar dokumen identiti anda dan pastikan dokumen anda memenuhi keperluan berikut.	",
    bookbankuploaddetail1: "	Kualiti yang baik, cerah dan jelas.	",
    bookbankuploaddetail2: "	Jenis fail JPG, JPEG, PNG, PDF.	",
    bookbankuploaddetail3: "	Semua sudut dokumen anda mesti kelihatan.	",
    bookbankfilesize: "	Saiz fail tidak boleh melebihi 8MB.	",
    didotp: "Tidak menerima OTP?",
    error_verify_type_file: "Maaf, jenis fail tidak boleh diterima.",
    error_verify_kyc:
      "Tidak berjaya, kami tidak dapat mengekstrak maklumat dari imej.",
    inprogress: "Sedang berjalan",
    sendverifysuccess:
      "Berjaya dikemukakan! Ia biasanya mengambil masa 3-15 minit untuk kami menyelesaikan cek yang diperlukan dan mengesahkan dokumen anda.",
    kyc_scanning: "	Mengimbas ...	",
    kyc_detected: "	Pengesanan berjaya	",
    kyc_angle: "	Laraskan sudut	",
    kyc_fail: "Pengesanan gagal",
    kyc_move_far: "Gerakkan dokumen lebih jauh",
    kyc_move: "	Pindahkan dokumen lebih dekat	",
  },
  id: {
    bookbankverifyTitle: "Bukti Verifikasi Bank",
    kycTitle: "	Bukti Verifikasi Identitas	",
    step1: "	Verifikasi dokumen	",
    step2: "	Informasi dasar	",
    step3: "	Verifikasi kesuksesan	",
    continue: "	Melanjutkan	",
    verifyidentity: "	Verifikasi identitas Anda	",
    acceptedfile:
      "	Jenis file yang diterima adalah ukuran file JPG, JPEG, PNG dan Max adalah 8 MB.	",
    cameradevice: "	Perangkat kamera	",
    uploadfile: "	Unggah data	",
    or: "	atau	",
    anotherdevice: "	Gunakan perangkat lain	",
    anotherdeviceDetail:
      "	Informasi pribadi Anda aman. Data yang diekstraksi dari dokumen ID Anda tetap berada di pasar IUX dan tidak pernah dibagikan dengan perusahaan lain.	",
    scancode: "	Pindai kode di bawah ini untuk menggunakan perangkat lain.	",
    back: "	Kembali	",
    verificationFailed: "	Verifikasi gagal	",
    sorryverify: "	Maaf! Kami tidak dapat memverifikasi identitas Anda	",
    needsupport:
      "	Butuh Bantuan? Kami di sini untuk membantu Anda 24/7 \n silakan hubungi kami melalui obrolan langsung.",
    uploadAgian: "	Unggah lagi	",
    tempblock: "	Upaya verifikasi diblokir sementara	",
    tempblockdetail:
      "	Upaya verifikasi identitas telah diblokir sementara selama 5 menit. Mohon cobalah mengunggah dokumen lain di lain waktu.	",
    permanently: "	Upaya verifikasi diblokir secara permanen	",
    permanentlydetail:
      "	Upaya verifikasi identitas Anda telah diblokir secara permanen. Silakan hubungi dukungan pasar IUX melalui obrolan langsung untuk bantuan lebih lanjut.	",
    countdown: "	Countdown	",
    loadingtitle: "	Saat pemrosesan ini, harap buka halaman ini	",
    manualverify: "	Verifikasi Identitas Manual	",
    trydemo: "	Coba demo	",
    verifySuccess: "	Keberhasilan verifikasi	",
    verifySuccessdetial: "	Identitas Anda telah diverifikasi.	",
    manualtitle: "	Dokumen identitas	",
    manualsubtitle: "	Harap unggah foto",
    manualsubtitle2:
      "Foto harus cerah dan jelas, dan semua Conners dari dokumen Anda harus terlihat.",
    fontcard: "	Depan	",
    backcard: "	Kembali	",
    manualselfie: "	Unggah selfie dengan buktinya	",
    manualselfiedetail:
      "	Harap unggah selfie dengan dokumen Aadhaar Anda dan situs web IUX Markets dibuka di browser internet di foto yang sama. Pastikan keduanya terlihat jelas dan wajah Anda tidak tertutup.	",
    otpverifytitle: "	Verifikasi OTP	",
    invalidphone: "	Telepon tidak valid, edit nomor telepon Anda.	",
    requestotp: "	Meminta OTP	",
    enterotp: "	Masukkan kode dari SMS yang kami kirim ke	",
    codeexpiresin: "	Kode berakhir	",
    seconds: "	detik	",
    codenotmatch: "	Kode tidak cocok.	",
    bookbankuploadtitle:
      "	Silakan ambil gambar dokumen identitas Anda dan pastikan dokumen Anda memenuhi persyaratan berikut.	",
    bookbankuploaddetail1: "	Kualitas bagus, cerah dan jernih.	",
    bookbankuploaddetail2: "	Jenis file JPG, JPEG, PNG, PDF.	",
    bookbankuploaddetail3: "	Semua sudut dokumen Anda harus terlihat.	",
    bookbankfilesize: "	Ukuran file tidak dapat melebihi 8MB.	",
    didotp: "Tidak menerima OTP?",
    error_verify_type_file: "Maaf, jenis file tidak dapat diterima.",
    error_verify_kyc:
      "Tidak berhasil, kami tidak dapat mengekstrak informasi dari gambar.",
    inprogress: "Sedang berlangsung",
    sendverifysuccess:
      "Berhasil dikirim! Biasanya hanya membutuhkan 3-15 menit bagi kami untuk menyelesaikan cek yang diperlukan dan mengkonfirmasi dokumen Anda.",
    kyc_scanning: "	Memindai ...	",
    kyc_detected: "	Deteksi berhasil	",
    kyc_angle: "	Sesuaikan sudut	",
    kyc_fail: "Deteksi gagal",
    kyc_move_far: "Pindahkan dokumen lebih jauh",
    kyc_move: "	Pindahkan dokumen lebih dekat	",
  },
  cn: {
    bookbankverifyTitle: "銀行驗證證明",
    kycTitle: "	身份證明驗證	",
    step1: "	驗證文檔	",
    step2: "	基本信息	",
    step3: "	驗證成功	",
    continue: "	繼續	",
    verifyidentity: "	驗證您的身份	",
    acceptedfile: "	接受的文件類型是JPG，JPEG，PNG和最大文件大小為8 MB。	",
    cameradevice: "	相機設備	",
    uploadfile: "	上傳文件	",
    or: "	或者	",
    anotherdevice: "	使用另一個設備	",
    anotherdeviceDetail:
      "	您的個人信息是安全的。從您的ID文檔中提取的數據停留在IUX市場中，從未與其他公司共享。	",
    scancode: "	掃描下面的代碼以使用其他設備。	",
    back: "	後退	",
    verificationFailed: "	驗證失敗	",
    sorryverify: "	對不起！我們無法驗證您的身份	",
    needsupport:
      "	需要幫助？我們在這里為24/7 \n提供幫助，請通過實時聊天與我們聯繫。",
    uploadAgian: "	再次上傳	",
    tempblock: "	驗證嘗試被暫時阻止	",
    tempblockdetail:
      "	身份驗證嘗試已暫時阻止5分鐘。請嘗試在以後上傳另一個文檔。	",
    permanently: "	驗證嘗試被永久阻止	",
    permanentlydetail:
      "	您的身份驗證嘗試已被永久阻止。請通過實時聊天與IUX市場支持聯繫，以獲取進一步的幫助。	",
    countdown: "	倒數	",
    loadingtitle: "	在此處理時，請保持此頁面打開	",
    manualverify: "	手動身份驗證	",
    trydemo: "	嘗試演示	",
    verifySuccess: "	驗證成功	",
    verifySuccessdetial: "	您的身份已得到驗證。	",
    manualtitle: "	身份證件	",
    manualsubtitle: "請上傳一張照片",
    manualsubtitle2: "照片應該明亮且清晰，並且文檔的所有鏈接都必須可見。",
    fontcard: "	正面	",
    backcard: "	後退	",
    manualselfie: "	用證據上傳自拍照	",
    manualselfiedetail:
      "	請使用您的Aadhaar文檔和IUX市場網站在同一張照片中打開的自拍照。確保兩者都清晰可見，並且您的臉沒有覆蓋。	",
    otpverifytitle: "	OTP驗證	",
    invalidphone: "	無效的電話，請編輯您的電話號碼。	",
    requestotp: "	請求OTP	",
    enterotp: "	輸入我們發送到的SMS的代碼	",
    codeexpiresin: "	代碼到期	",
    seconds: "	秒	",
    codenotmatch: "	代碼不匹配。	",
    bookbankuploadtitle: "	請拍攝您的身份文件，並確保您的文檔符合以下要求。	",
    bookbankuploaddetail1: "	優質，明亮而清晰。	",
    bookbankuploaddetail2: "	文件類型JPG，JPEG，PNG，PDF。	",
    bookbankuploaddetail3: "	文檔的所有角落都必須可見。	",
    bookbankfilesize: "	文件大小不能超過8MB。	",
    didotp: "沒有收到OTP？",
    error_verify_type_file: "抱歉，文件類型無法接受。",
    error_verify_kyc: "失敗，我們無法從圖像中提取信息。",
    inprogress: "進行中",
    sendverifysuccess:
      "成功提交！我們通常只需要3-15分鐘即可完成必要的檢查並確認您的文檔。",
    kyc_scanning: "	掃描...	",
    kyc_detected: "	檢測成功	",
    kyc_angle: "	調整角度	",
    kyc_fail: "檢測失敗",
    kyc_move_far: "將文檔移至更遠的地方",
    kyc_move: "	將文檔靠近移動	",
  },
  vn: {
    bookbankverifyTitle: "Bằng chứng xác minh ngân hàng",
    kycTitle: "	Bằng chứng xác minh danh tính	",
    step1: "	Xác minh tài liệu	",
    step2: "	Thông tin cơ bản	",
    step3: "	Xác minh thành công	",
    continue: "	Tiếp tục	",
    verifyidentity: "	Xác minh danh tính của bạn	",
    acceptedfile:
      "	Các loại tệp được chấp nhận là kích thước tệp JPG, JPEG, PNG và Max là 8 MB.	",
    cameradevice: "	Thiết bị máy ảnh	",
    uploadfile: "	Cập nhật dử liệu	",
    or: "	hoặc	",
    anotherdevice: "	Sử dụng một thiết bị khác	",
    anotherdeviceDetail:
      "	thông tin cá nhân của bạn được an toàn. Dữ liệu được trích xuất từ ​​tài liệu ID của bạn ở trong thị trường IUX và không bao giờ được chia sẻ với các công ty khác.	",
    scancode: "	Quét mã bên dưới để sử dụng một thiết bị khác.	",
    back: "	Mặt sau	",
    verificationFailed: "	Xác minh không hoàn thành	",
    sorryverify: "	Lấy làm tiếc! Chúng tôi không thể xác minh danh tính của bạn	",
    needsupport:
      "Cần sự trợ giúp? Chúng tôi ở đây để giúp bạn 24/7  \n Vui lòng liên hệ với chúng tôi qua trò chuyện trực tiếp.",
    uploadAgian: "	Tải lên một lần nữa	",
    tempblock: "	Nỗ lực xác minh đã bị chặn tạm thời	",
    tempblockdetail:
      "	Nỗ lực xác minh nhận dạng đã bị chặn tạm thời trong thời gian 5 phút. Vui lòng thử tải lên một tài liệu khác sau đó.	",
    permanently: "	Nỗ lực xác minh đã bị chặn vĩnh viễn	",
    permanentlydetail:
      "	Nỗ lực xác minh danh tính của bạn đã bị chặn vĩnh viễn. Vui lòng liên hệ với hỗ trợ của thị trường IUX thông qua trò chuyện trực tiếp để được hỗ trợ thêm.	",
    countdown: "	Đếm ngược	",
    loadingtitle: "	Trong khi xử lý này, vui lòng mở trang này	",
    manualverify: "	Xác minh nhận dạng thủ công	",
    trydemo: "	Hãy thử bản demo	",
    verifySuccess: "	Xác minh thành công	",
    verifySuccessdetial: "	Danh tính của bạn đã được xác minh.	",
    manualtitle: "	Tài liệu nhận dạng	",
    manualsubtitle: "Vui lòng tải lên một bức ảnh của",
    manualsubtitle2:
      "Ảnh phải sáng và rõ ràng, và tất cả các kết nối tài liệu của bạn phải được nhìn thấy.",
    fontcard: "	Đằng trước	",
    backcard: "	Mặt sau	",
    manualselfie: "	Tải lên selfie với bằng chứng	",
    manualselfiedetail:
      "	Vui lòng tải lên một selfie với tài liệu Aadhaar của bạn và trang web IUX Market được mở trong trình duyệt Internet trong cùng một bức ảnh. Đảm bảo rằng cả hai đều có thể nhìn thấy rõ và khuôn mặt của bạn không được bảo hiểm.	",
    otpverifytitle: "	Xác minh OTP	",
    invalidphone:
      "	Điện thoại không hợp lệ, vui lòng chỉnh sửa số điện thoại của bạn.	",
    requestotp: "	Yêu cầu OTP	",
    enterotp: "	Nhập mã từ SMS mà chúng tôi đã gửi đến	",
    codeexpiresin: "	Mã hết hạn	",
    seconds: "	giây	",
    codenotmatch: "	Mã không phù hợp.	",
    bookbankuploadtitle:
      "	Vui lòng chụp ảnh tài liệu danh tính của bạn và đảm bảo tài liệu của bạn đáp ứng các yêu cầu sau.	",
    bookbankuploaddetail1: "	Chất lượng tốt, tươi sáng và rõ ràng.	",
    bookbankuploaddetail2: "	Loại tệp JPG, JPEG, PNG, PDF.	",
    bookbankuploaddetail3:
      "	Tất cả các góc của tài liệu của bạn phải được nhìn thấy.	",
    bookbankfilesize: "	Kích thước tệp không thể vượt quá 8MB.	",
    didotp: "Didn nhận được OTP?",
    error_verify_type_file: "Xin lỗi, loại tệp không thể được chấp nhận.",
    error_verify_kyc:
      "Không thành công, chúng tôi không thể trích xuất thông tin từ hình ảnh.",
    inprogress: "Trong tiến trình",
    sendverifysuccess:
      "Đã gửi thành công! Thông thường chỉ mất 3-15 phút để chúng tôi hoàn thành các séc cần thiết và xác nhận tài liệu của bạn.",
    kyc_scanning: "	Quét ...	",
    kyc_detected: "	Phát hiện thành công	",
    kyc_angle: "	Điều chỉnh góc	",
    kyc_fail: "Phát hiện không thành công",
    kyc_move_far: "Di chuyển tài liệu xa hơn",
    kyc_move: "	Di chuyển tài liệu gần hơn	",
  },
  kr: {
    bookbankverifyTitle: "은행 검증의 증거",
    kycTitle: "	신원 검증 증명	",
    step1: "	문서를 확인하십시오	",
    step2: "	기본 정보	",
    step3: "	성공을 확인하십시오	",
    continue: "	계속하다	",
    verifyidentity: "	귀하의 신원을 확인하십시오	",
    acceptedfile:
      "	허용되는 파일 유형은 JPG, JPEG, PNG 및 최대 파일 크기는 8MB입니다.	",
    cameradevice: "	카메라 장치	",
    uploadfile: "	파일 업로드	",
    or: "	또는	",
    anotherdevice: "	다른 장치를 사용하십시오	",
    anotherdeviceDetail:
      "	귀하의 개인 정보는 안전합니다. ID 문서에서 추출한 데이터는 IUX 시장 내에 유지되며 다른 회사와 공유되지 않습니다.	",
    scancode: "	다른 장치를 사용하려면 아래 코드를 스캔하십시오.	",
    back: "	뒤쪽에	",
    verificationFailed: "	확인이 실패했습니다	",
    sorryverify: "	죄송합니다! 우리는 당신의 신원을 확인할 수 없었습니다	",
    needsupport:
      "도움이 필요하다? 24/7 \n 라이브 채팅을 통해 저희에게 연락하십시오.",
    uploadAgian: "	다시 업로드하십시오	",
    tempblock: "	검증 시도가 일시적으로 차단되었습니다	",
    tempblockdetail:
      "	신원 확인 시도는 5 분 동안 일시적으로 차단되었습니다. 나중에 다른 문서를 업로드하십시오.	",
    permanently: "	검증 시도가 영구적으로 차단되었습니다	",
    permanentlydetail:
      "	귀하의 신원 확인 시도가 영구적으로 차단되었습니다. 추가 지원을 위해 라이브 채팅을 통해 IUX Markets 지원에 문의하십시오.	",
    countdown: "	카운트 다운	",
    loadingtitle: "	이 처리하는 동안이 페이지를 열어 두십시오	",
    manualverify: "	수동 신원 확인	",
    trydemo: "	데모를 시도하십시오	",
    verifySuccess: "	검증 성공	",
    verifySuccessdetial: "	귀하의 신원이 확인되었습니다.	",
    manualtitle: "	신원 문서	",
    manualsubtitle: "	사진을 업로드하십시오",
    manualsubtitle2:
      "사진은 밝고 명확해야하며 문서의 모든 연결이 보일 것입니다.",
    fontcard: "	앞쪽	",
    backcard: "	뒤쪽에	",
    manualselfie: "	증거로 셀카를 업로드하십시오	",
    manualselfiedetail:
      "	Aadhaar 문서와 IUX Markets 웹 사이트가 같은 사진으로 인터넷 브라우저에 열린 셀카를 업로드하십시오. 둘 다 명확하게 보이고 얼굴이 덮여 있지 않은지 확인하십시오.	",
    otpverifytitle: "	OTP 검증	",
    invalidphone: "	잘못된 전화, 전화 번호를 친절하게 편집하십시오.	",
    requestotp: "	OTP를 요청하십시오	",
    enterotp: "	보낸 SMS에서 코드를 입력하십시오	",
    codeexpiresin: "	코드가 만료됩니다	",
    seconds: "	초	",
    codenotmatch: "	코드는 일치하지 않습니다.	",
    bookbankuploadtitle:
      "	신원 문서의 사진을 찍고 문서가 다음 요구 사항을 충족하는지 확인하십시오.	",
    bookbankuploaddetail1: "	좋은 품질, 밝고 명확합니다.	",
    bookbankuploaddetail2: "	파일 유형 JPG, JPEG, PNG, PDF.	",
    bookbankuploaddetail3: "	문서의 모든 모서리가 표시되어야합니다.	",
    bookbankfilesize: "	파일 크기는 8MB를 초과 할 수 없습니다.	",
    didotp: "OTP를받지 못했습니까?",
    error_verify_type_file: "죄송합니다. 파일 유형을 수락 할 수 없습니다.",
    error_verify_kyc: "실패하면 이미지에서 정보를 추출 할 수 없었습니다.",
    inprogress: "진행 중",
    sendverifysuccess:
      "성공적으로 제출되었습니다! 일반적으로 필요한 수표를 작성하고 문서를 확인하는 데 3-15 분이 걸립니다.",
    kyc_scanning: "	스캐닝...	",
    kyc_detected: "	감지 성공	",
    kyc_angle: "	각도를 조정하십시오	",
    kyc_fail: "탐지가 실패했습니다",
    kyc_move_far: "문서를 더 멀리 이동하십시오",
    kyc_move: "	문서를 가까이으로 이동하십시오	",
  },
  ph: {
    bookbankverifyTitle: "Patunay ng pag -verify sa bangko",
    kycTitle: "	Patunay ng pagpapatunay ng pagkakakilanlan	",
    step1: "	I -verify ang dokumento	",
    step2: "	Impormasyon sa Base	",
    step3: "	I -verify ang tagumpay	",
    continue: "	Magpatuloy	",
    verifyidentity: "	Patunayan ang iyong pagkakakilanlan	",
    acceptedfile:
      "	Ang mga tinanggap na uri ng file ay ang JPG, JPEG, PNG at MAX na laki ng file ay 8 MB.	",
    cameradevice: "	Aparato ng camera	",
    uploadfile: "	Mag -upload ng file	",
    or: "	o	",
    anotherdevice: "	Gumamit ng isa pang aparato	",
    anotherdeviceDetail:
      "	Ang iyong personal na impormasyon ay ligtas. Ang data na nakuha mula sa iyong dokumento ng ID ay mananatili sa loob ng mga IUX Markets at hindi kailanman maibabahagi sa ibang mga kumpanya.	",
    scancode: "	I -scan ang code sa ibaba upang gumamit ng isa pang aparato.	",
    back: "	Balik	",
    verificationFailed: "	Nabigo ang pag-verify	",
    sorryverify: "	Paumanhin! Hindi namin ma -verify ang iyong pagkakakilanlan	",
    needsupport:
      "Kailangan ng tulong? Narito kami upang matulungan ka 24/7 \n mangyaring makipag -ugnay sa amin sa pamamagitan ng live chat.",
    uploadAgian: "	Mag -upload ulit	",
    tempblock: "	Ang pagtatangka sa pag -verify ay pansamantalang naharang	",
    tempblockdetail:
      "	Ang pagtatangka ng pag -verify ng pagkakakilanlan ay pansamantalang naharang sa loob ng 5 minuto. Mabuting subukang mag -upload ng isa pang dokumento sa ibang pagkakataon.	",
    permanently: "	Ang pagtatangka ng pag -verify ay permanenteng naharang	",
    permanentlydetail:
      "	Ang iyong pagtatangka sa pagpapatunay ng pagkakakilanlan ay permanenteng naharang. Mangyaring makipag -ugnay sa suporta sa Iux Markets sa pamamagitan ng live chat para sa karagdagang tulong.	",
    countdown: "	Countdown	",
    loadingtitle:
      "	Habang ang pagproseso na ito, mangyaring panatilihing bukas ang pahinang ito	",
    manualverify: "	Manu -manong Pag -verify ng Pagkakakilanlan	",
    trydemo: "	Subukan ang demo	",
    verifySuccess: "	Tagumpay sa pag -verify	",
    verifySuccessdetial: "	Ang iyong pagkakakilanlan ay na -verify.	",
    manualtitle: "	Dokumento ng pagkakakilanlan	",
    manualsubtitle: "Mangyaring mag -upload ng larawan ng	",
    manualsubtitle2:
      "Ang larawan ay dapat na maliwanag at malinaw, at ang lahat ng mga koneksyon ng iyong dokumento ay dapat makita.",
    fontcard: "	Harapan	",
    backcard: "	Balik	",
    manualselfie: "	I -upload ang selfie na may patunay	",
    manualselfiedetail:
      "	Mangyaring mag -upload ng isang selfie kasama ang iyong dokumento ng Aadhaar at ang website ng Iux Markets na binuksan sa browser ng Internet sa parehong larawan. Tiyakin na ang parehong ay malinaw na nakikita at na ang iyong mukha ay hindi sakop.	",
    otpverifytitle: "	Pag -verify ng OTP	",
    invalidphone:
      "	Di -wastong telepono, mabait na i -edit ang numero ng iyong telepono.	",
    requestotp: "	Humiling ng OTP	",
    enterotp: "	Ipasok ang code mula sa SMS na ipinadala namin	",
    codeexpiresin: "	Mag -expire ang code sa	",
    seconds: "	Segundo	",
    codenotmatch: "	Hindi tumutugma ang code.	",
    bookbankuploadtitle:
      "	Mangyaring kumuha ng larawan ng iyong dokumento ng pagkakakilanlan at tiyaking matugunan ng iyong mga dokumento ang mga sumusunod na kinakailangan.	",
    bookbankuploaddetail1: "	Magandang kalidad, maliwanag at malinaw.	",
    bookbankuploaddetail2: "	Uri ng File JPG, JPEG, PNG, PDF.	",
    bookbankuploaddetail3:
      "	Ang lahat ng mga sulok ng iyong dokumento ay dapat makita.	",
    bookbankfilesize: "	Ang laki ng file ay hindi maaaring lumampas sa 8MB.	",
    didotp: "Hindi ba natanggap ang OTP?",
    error_verify_type_file: "Paumanhin, hindi matatanggap ang uri ng file.",
    error_verify_kyc:
      "Hindi matagumpay, hindi namin nakuha ang impormasyon mula sa imahe.",
    inprogress: "Sa pag -unlad",
    sendverifysuccess:
      "Matagumpay na isinumite! Karaniwang tumatagal lamang ng 3-15 minuto para makumpleto namin ang mga kinakailangang tseke at kumpirmahin ang iyong dokumento.",
    kyc_scanning: "	Pag -scan ...	",
    kyc_detected: "	Matagumpay ang pagtuklas	",
    kyc_angle: "	Ayusin ang anggulo	",
    kyc_fail: "Nabigo ang pagtuklas",
    kyc_move_far: "Ilipat ang dokumento nang mas malayo",
    kyc_move: "	Ilipat ang dokumento nang mas malapit	",
  },
  jp: {
    bookbankverifyTitle: "銀行検証の証明",
    kycTitle: "アイデンティティ検証の証明",
    step1: "ドキュメントを確認します",
    step2: "ベース\n報",
    step3: "成功を確認します",
    continue: "続く",
    verifyidentity: "あなたの身元を確認します",
    acceptedfile:
      "受け入れられたファイルタイプは、JPG、JPEG、PNG、および最大ファイルサイズは8 MBです。",
    cameradevice: "カメラデバイス",
    uploadfile: "ファイルをアップロードする",
    or: "または",
    anotherdevice: "別のデバイスを使用します",
    anotherdeviceDetail:
      "あなたの個人情報は安全です。 IDドキュメントから抽出されたデータは、IUX市場内にとどまり、他社と共有されることはありません。",
    scancode: "以下のコードをスキャンして、別のデバイスを使用します。",
    back: "戻る",
    verificationFailed: "検証に失敗しました",
    sorryverify: "ごめん！ あなたの身元を確認できませんでした",
    needsupport:
      "手伝いが必要？ 24時間年中無休でお手伝いします。 nライブチャットでお問い合わせください。",
    uploadAgian: "もう一度アップロードします",
    tempblock: "検証の試みは一時的にブロックされました",
    tempblockdetail:
      "ID検証の試みは、5分間一時的にブロックされています。 後で別のドキュメントをアップロードしてみてください。",
    permanently: "検証の試みは永久にブロックされました",
    permanentlydetail:
      "あなたの身元確認の試みは永久にブロックされています。 ライブチャットを通じてIUX Markets Supportに連絡してください。",
    countdown: "秒読み",
    loadingtitle: "この処理中は、このページを開いたままにしてください",
    manualverify: "手動IDの検証",
    trydemo: "デモを試してみてください",
    verifySuccess: "検証の成功",
    verifySuccessdetial: "あなたの身元が検証されています。",
    manualtitle: "身分証明書",
    manualsubtitle: "あなたの写真をアップロードしてください",
    manualsubtitle2:
      "写真は明るく明確でなければならず、あなたのドキュメントのすべてのコナーは見える必要があります。",
    fontcard: "フロント",
    backcard: "戻る",
    manualselfie: "自撮りを証明でアップロードします",
    manualselfiedetail:
      "AadhaarドキュメントとIUX MarketsのWebサイトを同じ写真にインターネットブラウザにオープンしたセルフィーをアップロードしてください。 両方がはっきりと見え、顔が覆われていないことを確認してください。",
    otpverifytitle: "OTP検証",
    invalidphone: "無効な電話、電話番号を親切に編集してください。",
    requestotp: "OTPをリクエストします",
    enterotp: "送信したSMSからコードを入力します",
    codeexpiresin: "コードはで期限切れになります",
    seconds: "秒",
    codenotmatch: "コードは一致しません。",
    bookbankuploadtitle:
      "あなたの身元ドキュメントの写真を撮り、あなたのドキュメントが次の要件を満たしていることを確認してください。",
    bookbankuploaddetail1: "良質で、明るく明確です。",
    bookbankuploaddetail2: "ファイルタイプJPG、JPEG、PNG、PDF。",
    bookbankuploaddetail3:
      "ドキュメントのすべてのコーナーが表示されなければなりません。",
    bookbankfilesize: "ファイルサイズは8MBを超えることはできません。",
    didotp: "OTPを受け取りませんでしたか？",
    error_verify_type_file:
      "申し訳ありませんが、ファイルタイプは受け入れられません。",
    error_verify_kyc:
      "失敗して、画像から情報を抽出することができませんでした。",
    inprogress: "進行中",
    sendverifysuccess:
      "正常に提出されました！ 通常、必要なチェックを完了してドキュメントを確認するには、わずか3〜15分かかります。",
    kyc_scanning: "走査...",
    kyc_detected: "検出が成功しました",
    kyc_angle: "角度を調整します",
    kyc_fail: "検出に失敗しました",
    kyc_move_far: "ドキュメントをさらに移動します",
    kyc_move: "ドキュメントを近くに移動します",
  },
};
