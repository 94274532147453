export default {
  th: {
    withdraw_info: {
      result: "ยืนยันการถอนเงิน",
      warning_head:
        "ในการถอนเงิน หากมี Position ซื้อขายอยู่ท่านสามารถถอนเงินได้ 70% Equity โดยนับไม่รวมเครดิตหรือโบนัส",
      warning_bottom1:
        "โปรดทราบว่าการถอนเงินของท่านจะขึ้นอยู่กับอัตราแลกเปลี่ยนของตัวกลางที่ท่านใช้ในการถอนเงิน โปรดตรวจสอบให้แน่ใจว่าอัตราแลกเปลี่ยนใดเหมาะสมที่สุดในการถอนแต่ละครั้ง ",
      warning_bottom2:
        "และโปรดทราบว่าการฝากและการถอนของท่านจะเป็นต้องเป็นชื่อที่ตรงกันกับข้อมูลที่ท่านทำการยืนยันเข้ามาเท่านั้น เพื่อหลีกเลี่ยงปัญหาการถอนเงินที่อาจจะเกิดขึ้นหากข้อมูลไม่ตรงกันกับการลงทะเบียน ",
      account: {
        name: "ชื่อบัญชี",
        number: "เลขที่บัญชี",
        bank: "ธนาคาร",
        amount: "จำนวนเงิน (USD)",
        Code: "กรอกโค้ดที่ได้รับทาง",
      },
      success: {
        title: "ถอนเงินสำเร็จ",
        description:
          "เราได้รับคำร้องการถอนเงินของท่าน และจะถูกดำเนินการภายใน 24 ชั่วโมง โปรดทราบว่า จำนวนเงินที่ร้องขอได้ถูกหักออกจากบัญชีของท่านแล้ว",
        amount: "ถอนเงิน",
      },
      note: {
        title:
          "หมายเหตุ: โปรดทราบขณะที่ท่านติดออร์เดอร์การซื้อขาย การถอนเงินจะถอนได้ 70% ของ margin โดยมิได้นำเครดิตมาร่วมด้วย",
        description:
          "แนะนำ: หากต้องการถอนเงินควรจะปิดออเดอร์ที่ค้างอยู่ทั้งหมดก่อน",
      },
      amount: "จำนวนเงินที่ต้องการถอน",
      currency_rate: "ค่าเงินปัจจุบัน",
    },
    withdraw_step: {
      no_payment: "ขออภัย ตัวเลือกการชำระเงินไม่สามารถใช้งานได้ชั่วคราว",
      no_account: "คุณยังไม่มีบัญชี MT5 กรุณาเปิดบัญชีก่อนดำเนินงาน",
      first_step: {
        title: "ขั้นตอนที่ 1",
        description: "เลือกบัญชี",
        withdraw_with: "ช่องทางการถอนเงิน",
        show_all_method: "แสดงช่องทางการถอนเงินทั้งหมด",
        minimun_withdraw: "	ถอนขั้นต่ำ	",
        withdraw_info:
          "	การถอนกองทุนหากมีตำแหน่งที่ใช้งานอยู่คุณสามารถถอนส่วนของผู้ถือหุ้น 70% ไม่รวมเครดิตหรือโบนัส	",
      },
      second_step: {
        title: "ขั้นตอนที่ 2",
        description: "เลือกจำนวนเงิน",
        transection_method: "วิธีการทำธุรกรรม",
      },
      third_step: {
        title: "ยืนยันการถอนเงิน",
        description: "สรุปการถอนเงิน",
      },
    },
    withdraw_error: {
      t_account: "กรุณาเลือกบัญชีของคุณ",
      t_amount: "ขั้นต่ำการถอนจำนวนเงินต้องมากกว่า",
      t_condition: "เงื่อนไข",
      t_error: "จำนวนเงินไม่เพียงพอ",
      t_max: "ถอนสูงสุด",
    },
    withdraw_success: {
      title: "Withdraw success",
      detail: "The amount will transfer to your bank account in 3-5 minute",
      button: "Back to Account",
    },
  },
  la: {
    withdraw_info: {
      result: "ການຢັ້ງຢືນ",
      warning_head:
        "Withdrawing funds, if there are active positions, you can withdraw 70% Equity, not including credit or bonus.",
      warning_bottom1:
        " Please note that your withdrawal will depend on the exchange rate of the intermediary you use. Withdrawal Please make sure which exchange rate is the most suitable for each withdrawal.",
      warning_bottom2:
        "And please note that your deposit and withdrawal must be in the same name as the information you have confirmed. In order to avoid any withdrawal problems that may occur if the information is inconsistent with the registration",
      account: {
        name: "ຊື່​ບັນ​ຊີ",
        number: "ເລກບັນຊີ",
        bank: "ຊື່ທະນາຄານ",
        amount: "ຈໍາ​ນວນ",
        Code: "ໃສ່ລະຫັດຈາກອີເມວ ",
      },
      success: {
        title: "ສໍາ​ເລັດ",
        description:
          "ວກເຮົາໄດ້ຮັບ ຄຳ ຮ້ອງຂໍຖອນເງິນຂອງທ່ານແລ້ວ. ແລະຈະປະມວນຜົນພາຍໃນ 24 ຊົ່ວໂມງ. ກະລຸນາຮັບຊາບວ່າ ຈຳ ນວນເງິນທີ່ຂໍໄດ້ຖືກຫັກອອກຈາກບັນຊີຂອງທ່ານແລ້ວ.",
        amount: "ຖອນເງິນ",
      },
      note: {
        title:
          "ຫມາຍ​ເຫດ​ : ຄວນຮູ້ໃນຂະນະທີ່ທ່ານສັ່ງຊື້ຢູ່​ ການຖອນເງິນແມ່ນຮັບ70% ຂອງ margin ໂດຍບໍ່ມີການ ນຳເຄດິດ ມາລວມນຳ ຍອດເງິນຂອງທ່ານ",
        description: "ແນະ ນຳ: ຖ້າຈະຖອນເງິນ, ທຸກ ຄຳ ສັ່ງທີ່ຍັງຄ້າງຄວນຈະປິດກ່ອນ.",
      },
      amount: "ຈຳ ນວນເງິນ (LAK)",
      currency_rate: "ອັດຕາເງິນຕາ",
    },
    withdraw_step: {
      no_payment: "ຂໍອະໄພ, ທາງເລືອກການຈ່າຍເງິນບໍ່ສາມາດໃຊ້ໄດ້ຊົ່ວຄາວ.",
      no_account:
        "ທ່ານບໍ່ມີບັນຊີ MT5. ກະລຸນາລົງທະບຽນບັນຊີ MT5 ເພື່ອເລີ່ມກ່ອນຂະບວນການນີ້",
      first_step: {
        title: "ຂັ້ນຕອນທີ 1",
        description: "ເລືອກບັນຊີ",
        withdraw_with: "ວິທີການຖອນເງິນ",
        show_all_method: "ສະແດງທຸກວິທີການ",
        minimun_withdraw: "	ການຖອນເງິນຂັ້ນຕ່ໍາ	",
        withdraw_info:
          "	ການຖອນເງິນ, ຖ້າມີຕໍາແຫນ່ງທີ່ໃຊ້ງານ, ທ່ານສາມາດຖອນຮຸ້ນ 70%, ບໍ່ລວມເອົາສິນເຊື່ອຫລືໂບນັດ.	",
      },
      second_step: {
        title: "ຂັ້ນຕອນທີ 2",
        description: "ຈຳ ນວນເງິນເຂົ້າ",
        transection_method: "ວິທີການເຮັດທຸລະກໍາ",
      },
      third_step: {
        title: "ຖອນການຢັ້ງຢືນ",
        description: "ຜົນໄດ້ຮັບ",
      },
    },
    withdraw_error: {
      t_account: "ບັນຊີຂອງທ່ານຍັງບໍ່ໄດ້ເລືອກເທື່ອ.",
      t_amount: "ຈຳ ນວນເງິນຄວນຫຼາຍກ່ວາ",
      t_condition: "ເງື່ອນໄຂ",
      t_error: "Insufficient balance",
      t_max: "Maximum withdraw",
    },
    withdraw_success: {
      title: "Withdraw success",
      detail: "The amount will transfer to your bank account in 3-5 minute",
      button: "Back to Account",
    },
  },
  en: {
    withdraw_info: {
      result: "Confirmation",
      warning_head:
        "Withdrawing funds, if there are active positions, you can withdraw 70% Equity, not including credit or bonus.",
      warning_bottom1:
        " Please note that your withdrawal will depend on the exchange rate of the intermediary you use. Withdrawal Please make sure which exchange rate is the most suitable for each withdrawal.",
      warning_bottom2:
        "And please note that your deposit and withdrawal must be in the same name as the information you have confirmed. In order to avoid any withdrawal problems that may occur if the information is inconsistent with the registration",
      account: {
        name: "Account Name",
        number: "Account Number",
        bank: "Bank name",
        amount: "Amount",
        Code: "Enter the code received via",
      },
      success: {
        title: "Success",
        description:
          "We have received your withdrawal request. And will be processed within 24 hours. Please note that the requested amount has been deducted from your account.",
        amount: "Withdraw",
      },
      note: {
        title:
          "Note: Please be aware while placing a trading order. Withdrawals can be withdrawn 70% of the margin without bringing credit.",
        description:
          "Recommend: If withdrawing funds, all pending orders should be closed first.",
      },
      amount: "Amount",
      currency_rate: "Currency Rate",
    },
    withdraw_step: {
      no_payment: "Apologies, the payment option is temporarily unavailable.",
      no_account:
        "You don't have MT5 account. please register MT5 account before this process",
      first_step: {
        title: "Step 1",
        description: "Select Account",
        withdraw_with: "Withdraw with",
        show_all_method: "Show all method",
        minimun_withdraw: "	Minimum withdraw	",
        withdraw_info:
          "	Withdrawing funds, if there are active positions, you can withdraw 70% Equity, not including credit or bonus.	",
      },
      second_step: {
        title: "Step 2",
        description: "Input Amount",
        transection_method: "Transaction Method",
      },
      third_step: {
        title: "Withdraw Confirmation",
        description: "Result",
      },
    },
    withdraw_error: {
      t_account: "Your account not selected yet.",
      t_amount: "amount should more than",
      t_condition: "Not meet the minimum requirement",
      t_error: "Insufficient balance",
      t_max: "Maximum withdraw",
    },
    withdraw_success: {
      title: "Withdraw success",
      detail: "The amount will transfer to your bank account in 3-5 minute",
      button: "Back to Account",
    },
  },
  in: {
    withdraw_info: {
      result: "Confirmation",
      warning_head:
        "Withdrawing funds, if there are active positions, you can withdraw 70% Equity, not including credit or bonus.",
      warning_bottom1:
        " Please note that your withdrawal will depend on the exchange rate of the intermediary you use. Withdrawal Please make sure which exchange rate is the most suitable for each withdrawal.",
      warning_bottom2:
        "And please note that your deposit and withdrawal must be in the same name as the information you have confirmed. In order to avoid any withdrawal problems that may occur if the information is inconsistent with the registration",
      account: {
        name: "Account Name",
        number: "Account Number",
        bank: "Bank name",
        amount: "Amount",
        Code: "Enter the code received via",
      },
      success: {
        title: "Success",
        description:
          "We have received your withdrawal request. And will be processed within 24 hours. Please note that the requested amount has been deducted from your account.",
        amount: "Withdraw",
      },
      note: {
        title:
          "Note: Please be aware while placing a trading order. Withdrawals can be withdrawn 70% of the margin without bringing credit.",
        description:
          "Recommend: If withdrawing funds, all pending orders should be closed first.",
      },
      amount: "Amount",
      currency_rate: "Currency Rate",
    },
    withdraw_step: {
      no_payment: "क्षमा करें, भुगतान विकल्प अस्थायी रूप से अनुपलब्ध है।",
      no_account:
        "You don't have MT5 account. please register MT5 account before this process",
      first_step: {
        title: "Step 1",
        description: "Select Account",
        withdraw_with: "Withdraw with",
        show_all_method: "Show all method",
        minimun_withdraw: "	Minimum withdraw	",
        withdraw_info:
          "	Withdrawing funds, if there are active positions, you can withdraw 70% Equity, not including credit or bonus.	",
      },
      second_step: {
        title: "Step 2",
        description: "Input Amount",
        transection_method: "Transaction Method",
      },
      third_step: {
        title: "Withdraw Confirmation",
        description: "Result",
      },
    },
    withdraw_error: {
      t_account: "Your account not selected yet.",
      t_amount: "amount should more than",
      t_condition: "Not meet the minimum requirement",
      t_error: "Insufficient balance",
      t_max: "Maximum withdraw",
    },
    withdraw_success: {
      title: "Withdraw success",
      detail: "The amount will transfer to your bank account in 3-5 minute",
      button: "Back to Account",
    },
  },
  my: {
    withdraw_info: {
      result: `Pengesahan pengeluaran`,
      warning_head:
        "Pengeluaran dana, jika terdapat posisi aktif, anda boleh mengeluarkan 70% Ekuiti, tidak termasuk kredit atau bonus.",
      warning_bottom1:
        " Sila ambil perhatian bahawa pengeluaran anda akan bergantung pada kadar pertukaran pengantara yang anda gunakan.Pengeluaran Sila pastikan kadar pertukaran mana yang paling sesuai untuk setiap pengeluaran.",
      warning_bottom2:
        "Dan sila ambil perhatian bahawa deposit dan pengeluaran anda mestilah atas nama yang sama dengan maklumat yang telah anda sahkan. Bagi mengelakkan sebarang masalah pengeluaran yang mungkin berlaku sekiranya maklumat tidak konsisten dengan pendaftaran",
      account: {
        name: `Nama akaun`,
        number: `Nombor akaun`,
        bank: `Nama bank`,
        amount: `jumlah`,
        Code: `Masukkan kod yang diterima melalui`,
      },
      success: {
        title: `Pengeluaran berjaya`,
        description: `Kami telah menerima permintaan pengeluaran anda dan ia akan diproses dalam masa 24 jam. Sila ambil perhatian bahawa jumlah yang diminta telah ditolak daripada akaun anda`,
        amount: `Mengeluarkan`,
      },
      note: {
        title: `Nota: Jika ketika mengeluarkan wang, anda membuka pesanan yang belum selesai, anda akan dapat mengeluarkan hanya 70% daripada Baki sedia ada anda. (Perintah yang tidak termasuk, termasuk, stop, jual, stop, had, menjual)`,
        description: `Disyorkan: Untuk mengeluarkan dana, semua pesanan yang belum selesai hendaklah ditutup terlebih dahulu`,
      },
      amount: `Jumlah`,
      currency_rate: `Kadar mata wang`,
    },
    withdraw_step: {
      no_payment:
        "Maaf, pilihan pembayaran tidak tersedia buat sementara waktu.",
      no_account: `Anda tidak mempunyai akaun MT5, sila buka akaun sebelum meneruskan`,
      first_step: {
        title: `Langkah 1`,
        description: `Pilih Akaun`,
        withdraw_with: "Menarik diri dengan",
        show_all_method: "Tunjukkan semua kaedah",
        minimun_withdraw: "	Minimum menarik balik	",
        withdraw_info:
          "	Mengeluarkan dana, jika terdapat kedudukan aktif, anda boleh mengeluarkan 70% ekuiti, tidak termasuk kredit atau bonus.	",
      },
      second_step: {
        title: `langkah 2`,
        description: `Pilih Jumlah`,
        transection_method: "Transaction Method",
      },
      third_step: {
        title: `Withdraw Confirmation`,
        description: `Ringkasan Pengeluaran`,
      },
    },
    withdraw_error: {
      t_account: `Sila pilih akaun anda.`,
      t_amount: `Jumlah pengeluaran minimum mestilah lebih besar daripada`,
      t_condition: "syarat",
      t_error: "jumlah dana tidak mencukupi",
      t_max: "Pengeluaran Maksimum",
    },
    withdraw_success: {
      title: "Withdraw success",
      detail: "The amount will transfer to your bank account in 3-5 minute",
      button: "Back to Account",
    },
  },
  id: {
    withdraw_info: {
      result: `Konfirmasi`,
      warning_head:
        "Withdrawing funds, if there are active positions, you can withdraw 70% Equity, not including credit or bonus.",
      warning_bottom1:
        " Please note that your withdrawal will depend on the exchange rate of the intermediary you use. Withdrawal Please make sure which exchange rate is the most suitable for each withdrawal.",
      warning_bottom2:
        "And please note that your deposit and withdrawal must be in the same name as the information you have confirmed. In order to avoid any withdrawal problems that may occur if the information is inconsistent with the registration",
      account: {
        name: `Nama akun`,
        number: `Nomor akun`,
        bank: `nama Bank`,
        amount: `Jumlah`,
        Code: `Enter the code received via`,
      },
      success: {
        title: `Keberhasilan`,
        description: `Kami telah menerima permintaan penarikan Anda. Dan akan diproses dalam waktu 24 jam. Harap dicatat bahwa jumlah yang diminta telah dipotong dari akun Anda`,
        amount: `Menarik`,
      },
      note: {
        title: `Catatan: Jika penarikan uang dalam hal membuka pending order Anda akan dapat menarik hanya 70% dari Equity yang ada. (Pesanan yang tidak termasuk, termasuk, berhenti, sell, stop, limit, sell)`,
        description: `Merekomendasikan: Jika penarikan dana, semua pesanan yang tertunda harus ditutup pertama`,
      },
      amount: `Jumlah`,
      currency_rate: `Currency rate`,
    },
    withdraw_step: {
      no_payment: "Mohon maaf, opsi pembayaran untuk sementara tidak tersedia.",
      no_account: `Anda tidak memiliki akun MT5. silakan mendaftar akun MT5 sebelum proses ini`,
      first_step: {
        title: `Langkah 1`,
        description: `Pilih account`,
        withdraw_with: "Menarik diri dengan",
        show_all_method: "Tunjukkan semua kaedah",
        minimun_withdraw: "	Penarikan minimum	",
        withdraw_info:
          "	Menarik dana, jika ada posisi aktif, Anda dapat menarik 70% ekuitas, tidak termasuk kredit atau bonus.	",
      },
      second_step: {
        title: `Langkah 2`,
        description: `Jumlah masukan`,
        transection_method: "Transaction Method",
      },
      third_step: {
        title: `Withdraw Confirmation`,
        description: `Hasil`,
      },
    },
    withdraw_error: {
      t_account: `Akun Anda tidak dipilih belum.`,
      t_amount: `Jumlah harus lebih dari`,
      t_condition: "kondisi",
      t_error: "Insufficient balance",
      t_max: "Maximum withdraw",
    },
    withdraw_success: {
      title: "Withdraw success",
      detail: "The amount will transfer to your bank account in 3-5 minute",
      button: "Back to Account",
    },
  },
  cn: {
    withdraw_info: {
      result: `确认`,
      warning_head:
        "Withdrawing funds, if there are active positions, you can withdraw 70% Equity, not including credit or bonus.",
      warning_bottom1:
        " Please note that your withdrawal will depend on the exchange rate of the intermediary you use. Withdrawal Please make sure which exchange rate is the most suitable for each withdrawal.",
      warning_bottom2:
        "And please note that your deposit and withdrawal must be in the same name as the information you have confirmed. In order to avoid any withdrawal problems that may occur if the information is inconsistent with the registration",
      account: {
        name: `用户名`,
        number: `账号`,
        bank: `银行名称`,
        amount: `量`,
        Code: `Enter the code received via`,
      },
      success: {
        title: `成功`,
        description: `，我们收到您的取款请求。而将在24小时内处理。请注意，请求量已经从您的账户中扣除`,
        amount: `退出`,
      },
      note: {
        title: `注：如果在打开挂单的情况下取钱，您将能够收回现有的余额只有70％。 （未包括订单，包括止损，卖出，止损，限制，卖出）`,
        description: `推荐：如果抽逃资金，所有挂单，应首先关闭`,
      },
      amount: `金额`,
      currency_rate: `货币汇率`,
    },
    withdraw_step: {
      no_payment: "Apologies, but the payment is temporarily unavailable.",
      no_account: `你不必MT5账户。请在此过程之前注册MT5账户`,
      first_step: {
        title: `第1步`,
        description: `选择帐户`,
        withdraw_with: "Withdraw with",
        show_all_method: "Show all method",
        minimun_withdraw: "	最低撤回	",
        withdraw_info:
          "	撤回資金，如果有積極的職位，則可以撤回70％的股權，而不包括信貸或獎金。	",
      },
      second_step: {
        title: `第2步`,
        description: `投入量`,
        transection_method: "Transaction Method",
      },
      third_step: {
        title: `Withdraw Confirmation`,
        description: `结果`,
      },
    },
    withdraw_error: {
      t_account: `您的帐户尚未选定。`,
      t_amount: `量应超过3美元。`,
      t_condition: "状况",
      t_error: "Insufficient balance",
      t_max: "Maximum withdraw",
    },
    withdraw_success: {
      title: "Withdraw success",
      detail: "The amount will transfer to your bank account in 3-5 minute",
      button: "Back to Account",
    },
  },
  vn: {
    withdraw_info: {
      result: "Xác nhận rút tiền",
      warning_head:
        "Withdrawing funds, if there are active positions, you can withdraw 70% Equity, not including credit or bonus.",
      warning_bottom1:
        " Please note that your withdrawal will depend on the exchange rate of the intermediary you use. Withdrawal Please make sure which exchange rate is the most suitable for each withdrawal.",
      warning_bottom2:
        "And please note that your deposit and withdrawal must be in the same name as the information you have confirmed. In order to avoid any withdrawal problems that may occur if the information is inconsistent with the registration",
      account: {
        name: "Tên tài khoản",
        number: "Số tài khoản",
        bank: "Tài khoản",
        amount: "Số tiền (USD)",
        Code: "Điền mã nhận được qua",
      },
      success: {
        title: "Rút tiền thành công",
        description:
          "Chúng tôi đã nhận được yêu cầu rút tiền của bạn. Và nó sẽ được xử lý trong vòng 24 giờ. Xin lưu ý rằng số tiền được yêu cầu đã bị trừ khỏi tài khoản của bạn.",
        amount: "Rút tiền",
      },
      note: {
        title:
          "“LƯU Ý: Hãy lưu ý rằng khi bạn có đặt lệnh giao dịch. Rút tiền có thể được rút 70% margin mà không cần tín dụng.",
        description: `Khuyến nghị: Nếu bạn muốn rút tiền, bạn nên đóng tất cả các lệnh đang chờ xử lý trước.`,
      },
      amount: "Số tiền muốn rút",
      currency_rate: "Giá tiền hiện tại",
    },
    withdraw_step: {
      no_payment: "Xin lỗi, tùy chọn thanh toán tạm thời không khả dụng.",
      no_account:
        "Bạn chưa có tài khoản MT5 vui lòng mở tài khoản trước thực hiện.",
      first_step: {
        title: `Bước 1`,
        description: `Chọn tài khoản`,
        withdraw_with: "rút tiền với",
        show_all_method: "Hiển thị tất cả phương pháp",
        minimun_withdraw: "	Rút tối thiểu	",
        withdraw_info:
          "	Rút tiền, nếu có các vị trí hoạt động, bạn có thể rút 70% vốn chủ sở hữu, không bao gồm tín dụng hoặc tiền thưởng.	",
      },
      second_step: {
        title: `Bước 2`,
        description: "Chọn số tiền",
        transection_method: "Phương thức giao dịch",
      },
      third_step: {
        title: `Xác nhận rút tiền`,
        description: "Tóm tắt rút tiền",
      },
    },
    withdraw_error: {
      t_account: "Vui lòng chọn tài khoản của bạn",
      t_amount: "Số tiền rút tối thiểu phải nhiều hơn",
      t_condition: "tình trạng",
      t_error: "Insufficient balance",
      t_max: "Maximum withdraw",
    },
    withdraw_success: {
      title: "Rút tiền thành công",
      detail: "Số tiền sẽ chuyển vào tài khoản ngân hàng của bạn sau 3-5 phút",
      button: "Quay lại tài khoản",
    },
  },
  kr: {
    withdraw_info: {
      result: `확인`,
      warning_head:
        "Withdrawing funds, if there are active positions, you can withdraw 70% Equity, not including credit or bonus.",
      warning_bottom1:
        " Please note that your withdrawal will depend on the exchange rate of the intermediary you use. Withdrawal Please make sure which exchange rate is the most suitable for each withdrawal.",
      warning_bottom2:
        "And please note that your deposit and withdrawal must be in the same name as the information you have confirmed. In order to avoid any withdrawal problems that may occur if the information is inconsistent with the registration",
      account: {
        name: `계정 이름`,
        number: `계좌 번호`,
        bank: `은행 이름`,
        amount: `양`,
        Code: `Enter the code received via`,
      },
      success: {
        title: `성공`,
        description: `우리는 당신의 탈퇴 요청을 받았습니다. 24 시간 내에 처리 될 것이다. 요청 된 금액이 계정에서 공제되어 있습니다`,
        amount: `빼다`,
      },
      note: {
        title: `참고 : 보류중인 주문을 여는 이벤트에 돈을 철회하는 경우 당신은 당신의 기존 잔액의 70 %를 철회 할 수 있습니다. (포함되지 않은 주문은 포함, 정지, 판매, 정지, 제한, 판매)`,
        description: `추천 : 자금을 철수하면 모든 보류중인 주문을 먼저 닫아야합니다`,
      },
      amount: `금액`,
      currency_rate: `환율 비율`,
    },
    withdraw_step: {
      no_payment: "Apologies, but the payment is temporarily unavailable.",
      no_account: `당신은 MT5 계정을 가지고 있지 않습니다. 이 과정 전에 MT5 계정을 등록하십시오`,
      first_step: {
        title: `1 단계`,
        description: `계정 선택`,
        withdraw_with: "Withdraw with",
        show_all_method: "Show all method",
        minimun_withdraw: "	최소 철회	",
        withdraw_info:
          "	자금 인출, 활발한 직책이있는 경우 신용이나 보너스를 포함하지 않고 70% 지분을 인출 할 수 있습니다.	",
      },
      second_step: {
        title: `2 단계`,
        description: `입력 금액`,
        transection_method: "Transaction Method",
      },
      third_step: {
        title: `Withdraw Confirmation`,
        description: `결과`,
      },
    },
    withdraw_error: {
      t_account: `계정이 아직 선택하지.`,
      t_amount: `양은 3 달러를해야한다.`,
      t_condition: "",
      t_error: "Insufficient balance",
      t_max: "Maximum withdraw",
    },
    withdraw_success: {
      title: "Withdraw success",
      detail: "The amount will transfer to your bank account in 3-5 minute",
      button: "Back to Account",
    },
  },
  ph: {
    withdraw_info: {
      result: `pagpapatibay`,
      warning_head:
        "Withdrawing funds, if there are active positions, you can withdraw 70% Equity, not including credit or bonus.",
      warning_bottom1:
        " Please note that your withdrawal will depend on the exchange rate of the intermediary you use. Withdrawal Please make sure which exchange rate is the most suitable for each withdrawal.",
      warning_bottom2:
        "And please note that your deposit and withdrawal must be in the same name as the information you have confirmed. In order to avoid any withdrawal problems that may occur if the information is inconsistent with the registration",
      account: {
        name: `Pangalan ng account`,
        number: `account Number`,
        bank: `Pangalan ng bangko`,
        amount: `dami`,
        Code: `Enter the code received via`,
      },
      success: {
        title: `tagumpay`,
        description: `Natanggap namin ang iyong kahilingan withdrawal. At ay ipoproseso sa loob ng 24 na oras. Mangyaring tandaan na ang hiniling na halaga ay ibabawas mula sa iyong account`,
        amount: `bawiin`,
      },
      note: {
        title: `Tandaan: Kung ma-withdraw ang pera sa kaganapan ng pagbubukas nakabinbing mga order Makaka upang bawiin lamang ang 70% ng iyong mga umiiral na Balanse. (Mga order na hindi kasama, isama, stop, sell, stop, limit, sell)`,
        description: `Magrekomenda: Kung ma-withdraw ang mga pondo, ang lahat ng mga nakabinbing mga order ay dapat na unang sarado`,
      },
      amount: `Halaga`,
      currency_rate: `Rate ng pera`,
    },
    withdraw_step: {
      no_payment:
        "Paumanhin, pansamantalang hindi available ang opsyon sa pagbabayad.",
      no_account: `Hindi mo na kailangang MT5 account. mangyaring magparehistro MT5 account bago ang proseso na ito`,
      first_step: {
        title: `hakbang 1`,
        description: `Piliin ang Account`,
        withdraw_with: "Umatras kasama",
        show_all_method: "Ipakita ang lahat ng paraan",
        minimun_withdraw: "	Minimum na pag -atras	",
        withdraw_info:
          "	Ang pag -alis ng mga pondo, kung may mga aktibong posisyon, maaari kang mag -alis ng 70% equity, hindi kasama ang kredito o bonus.	",
      },
      second_step: {
        title: `hakbang 2`,
        description: `input Halaga`,
        transection_method: "Transaction Method",
      },
      third_step: {
        title: `Withdraw Confirmation`,
        description: `resulta`,
      },
    },
    withdraw_error: {
      t_account: `Ang iyong account ay hindi pa nakapili.`,
      t_amount: `halagang dapat higit sa`,
      t_condition: "kundisyon",
      t_error: "Insufficient balance",
      t_max: "Maximum withdraw",
    },
    withdraw_success: {
      title: "Withdraw success",
      detail: "The amount will transfer to your bank account in 3-5 minute",
      button: "Back to Account",
    },
  },
  jp: {
    withdraw_info: {
      result: "確認",
      warning_head:
        "資金を撤回して、アクティブなポジションがある場合、クレジットやボーナスを含めずに、70％の株式を引き出すことができます。",
      warning_bottom1:
        "撤退は、使用する仲介者の為替レートに依存することに注意してください。 撤退すると、どの為替レートが撤退ごとに最も適しているかを確認してください。",
      warning_bottom2:
        "また、預金と引き出しは、確認した情報と同じ名前でなければならないことに注意してください。 情報が登録と矛盾している場合に発生する可能性のある撤退の問題を回避するために",
      account: {
        name: "アカウント名",
        number: "口座番号",
        bank: "銀行名",
        amount: "額",
        Code: "byで受信したコードを入力します",
      },
      success: {
        title: "成功",
        description:
          "引き出しリクエストを受け取りました。 24時間以内に処理されます。 要求された金額はアカウントから控除されていることに注意してください。",
        amount: "撤回する",
      },
      note: {
        title:
          "注：取引注文の際は注意してください。 引き出しは、信用を得ることなく、マージンの70％を引き出すことができます。",
        description:
          "推奨：資金を撤回する場合、保留中のすべての注文は最初に閉鎖する必要があります。",
      },
      amount: "額",
      currency_rate: "通貨レート",
    },
    withdraw_step: {
      no_payment:
        "申し訳ございませんが、お支払いオプションは一時的にご利用いただけません。",
      no_account:
        "MT5アカウントはありません。 このプロセスの前にMT5アカウントを登録してください",
      first_step: {
        title: "ステップ1",
        description: "アカウントを選択します",
        withdraw_with: "で撤退します",
        show_all_method: "すべての方法を表示します",
        minimun_withdraw: "最小撤回",
        withdraw_info:
          "資金を撤回して、アクティブなポジションがある場合、クレジットやボーナスを含めずに、70％の株式を引き出すことができます。",
      },
      second_step: {
        title: "ステップ2",
        description: "入力量",
        transection_method: "トランザクション方法",
      },
      third_step: {
        title: "確認を撤回します",
        description: "結果",
      },
    },
    withdraw_error: {
      t_account: "アカウントはまだ選択されていません。",
      t_amount: "金額はそれ以上のものです",
      t_condition: "最小要件を満たしていません",
      t_error: "残高不足",
      t_max: "最大撤回",
    },
    withdraw_success: {
      title: "成功を撤回します",
      detail: "金額は3〜5分で銀行口座に転送されます",
      button: "アカウントに戻ります",
    },
  },
};
