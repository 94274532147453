import { Button, Modal } from "antd";
import React from "react";
const locale = require("react-redux-i18n").I18n;
function DeleteAccount(props) {
  const { isOpen, onClose, account, onDeleteAccount } = props;
  return (
    <Modal
      className="w-modal width-modal-openAccountBonus user user-change"
      closable={true}
      visible={isOpen}
      title={false}
      onCancel={() => {
        onClose();
      }}
      footer={false}
    >
      <div className="p-4">
        <div className="text-center fw-700 fs-16">Delete Account Trade</div>
        <div className="w-100  d-flex justify-content-center mt-4">
          <img src="/assets/images/verify/reject.svg" alt="reject" />
        </div>
        <div className="text-center fs-14 mt-3">
          Are you sure you want to delete{" "}
        </div>
        <div className="text-center fs-14">
          account number {account?.account_number}?
        </div>
        <div className="d-flex mt-4">
          <Button
            className="btn-primary-new-iux w-100"
            onClick={() => onDeleteAccount(account)}
          >
            {locale.t("dashbordCommission.irpoint_confirm")}
          </Button>
          <Button
            className="btn-primary-new-outline ml-2 w-100"
            onClick={() => onClose()}
          >
            {locale.t("dashbordCommission.irpoint_cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteAccount;
