import React, { Component } from "react";
import RightMenu from "./RightMenu";
import { Link, withRouter } from "react-router-dom";
import { Menu, Button, Drawer } from "antd";
import { connect } from "react-redux";
import "../../assets/css/navbar.css";
import { setLocale } from "react-redux-i18n";
import { setLocation } from "../../actions";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";

const SubMenu = Menu.SubMenu;
const locale = require("react-redux-i18n").I18n;
const flagStyle = {
  width: 20,
  height: 20,
};

class Navbar extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     current: "mail",
  //     visible: false,
  //     backgroundColor: "#00000000",
  //     visibleLang: false,
  //     childrenDrawer: false,
  //     childrenDrawerMarket: false,
  //     openKeys: ["sub1"],
  //   };
  // }
  rootSubmenuKeys = ["sub1", "sub2", "sub3", "sub4"];
  state = {
    current: "mail",
    visible: false,
    backgroundColor: "#00000000",
    visibleLang: false,
    childrenDrawer: false,
    childrenDrawerMarket: false,
    openKeys: ["sub1"],
  };

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  // lang
  showLang = () => {
    this.setState({
      visibleLang: true,
    });
  };

  okLang = (e) => {
    console.log(e);
    this.setState({
      visibleLang: false,
    });
  };

  cancelLang = (e) => {
    console.log(e);
    this.setState({
      visibleLang: false,
    });
  };

  showChildrenDrawer = () => {
    this.setState({
      childrenDrawer: true,
    });
  };

  onChildrenDrawerClose = () => {
    this.setState({
      childrenDrawer: false,
    });
  };

  onMarket = () => {
    this.setState({
      childrenDrawerMarket: true,
    });
  };

  onCloseMarket = () => {
    this.setState({
      childrenDrawerMarket: false,
    });
  };

  onHover = (isDropdownVisible) => {
    if (isDropdownVisible) {
      this.setState({
        backgroundImage:
          "radial-gradient(circle farthest-side at center bottom, rgba(0, 156, 222, 0), rgba(0, 48, 135, 0) 125%)",
      });
    } else {
      if (window.scrollY > 0) {
        this.setState({
          backgroundImage:
            "radial-gradient(circle farthest-side at center bottom, rgb(54 113 147), rgb(57 115 150) 125%)",
        });
      } else {
        this.setState({
          backgroundImage:
            "radial-gradient(circle farthest-side at center bottom, rgba(0, 156, 222, 0), rgba(0, 48, 135, 0) 125%)",
        });
      }
    }
  };

  handleChangLanguage = (newLang) => {
    const oldLang = localStorage.getItem("locale");
    localStorage.setItem("locale", newLang);
    const newPath = this.props.history.location.pathname.replace(
      oldLang,
      newLang
    );
    this.props.history.push(newPath);
    window.location.reload();
  };

  langs = [
    {
      lang: "en",
      img: "gb",
      name: "English",
    },
    {
      lang: "th",
      img: "th",
      name: "ภาษาไทย",
    },
    {
      lang: "my",
      img: "my",
      name: "Bahasa melayu",
    },
    {
      lang: "id",
      img: "id",
      name: "Bahasa Indonesia",
    },
    {
      lang: "cn",
      img: "cn",
      name: "简体中文",
    },
    {
      lang: "vn",
      img: "vn",
      name: "Tiếng Việt",
    },
    {
      lang: "kr",
      img: "kr",
      name: "한국어",
    },
    {
      lang: "ph",
      img: "ph",
      name: "Filipino",
    },
    {
      lang: "la",
      img: "la",
      name: "ພາສາລາວ",
    },
    {
      lang: "in",
      img: "in",
      name: "India",
    },
    {
      lang: "jp",
      img: "jp",
      name: "Japan",
    },
  ];

  itemNavbar = [
    // {
    //   text: locale.t("account"),
    //   link: `/${localStorage.getItem("locale")}/accounts`,
    // },
    // {
    //   text: locale.t("loyaltybonus"),
    //   link: `/${localStorage.getItem("locale")}/lotback`,
    // },
    // {
    //   text: locale.t("startbonus"),
    //   link: `/${localStorage.getItem("locale")}/promotion`,
    // },
    {
      text: locale.t("spread"),
      link: `/${localStorage.getItem("locale")}/spread`,
    },
    // {
    //   text: locale.t("partnership"),
    //   link: `/${localStorage.getItem("locale")}/partner`,
    // },
    // {
    //   text: locale.t("about"),
    //   link: `/${localStorage.getItem("locale")}/about-us`,
    // },
  ];

  menuDropdown = (
    <Menu
      style={{ width: "160px", background: "transparent" }}
      className="notransition"
    >
      {this.langs.map((i, x) => (
        <Menu.Item
          key="1"
          className="d-flex justify-content-between onHoverLang"
          style={{ padding: "8px 1rem" }}
          onClick={() => this.handleChangLanguage(i.lang)}
        >
          <div>
            <img
              className="mr-2 mb-1"
              style={flagStyle}
              src={process.env.PUBLIC_URL + i.img}
              alt=""
            />
          </div>
          <p style={{ color: "black" }}>{i.name}</p>
        </Menu.Item>
      ))}
    </Menu>
  );

  render() {
    const tools = [
      {
        title: locale.t("tools"),
        list: [
          {
            name: locale.t("analyze"),
            link: `/${localStorage.getItem("locale")}/spread/marketresearch`,
          },
          {
            name: locale.t("MetaTrader 5"),
            link: `/${localStorage.getItem("locale")}/platforms/mt5`,
          },
          {
            name: locale.t("WebTrader 5"),
            link: `/${localStorage.getItem(
              "locale"
            )}/webterminal/version5?sever=live`,
          },
          // {
          //   name: locale.t("fxcal_page_title"),
          //   link: `/${localStorage.getItem("locale")}/help/calculators`,
          // },
          // {
          //   name: locale.t("economic"),
          //   link: `/${localStorage.getItem("locale")}/help/economic-calendar`,
          // },
          {
            name: locale.t("payment_option"),
            link: `/${localStorage.getItem("locale")}/payment-option`,
          },
        ],
      },
    ];
    const accountlist = [
      {
        title: locale.t("account"),
        list: [
          {
            name: locale.t("cover.accover.title"),
            link: `/${localStorage.getItem("locale")}/accounts`,
          },
          {
            name: locale.t("cover.accstd.title"),
            link: `/${localStorage.getItem("locale")}/accounts/standard`,
          },
          {
            name: locale.t("cover.accbonus.title"),
            link: `/${localStorage.getItem("locale")}/accounts/bonus`,
          },
          // {
          //   name: locale.t("cover.accecn.title"),
          //   link: `/${localStorage.getItem("locale")}/accounts/ecn`,
          // },
          {
            name: locale.t("cover.accraw.title"),
            link: `/${localStorage.getItem("locale")}/accounts/raw`,
          },
          {
            name: locale.t("cover.accpro.title"),
            link: `/${localStorage.getItem("locale")}/accounts/pro`,
          },
          // {
          //   name: locale.t("cover.acccnt.title"),
          //   link: `/${localStorage.getItem("locale")}/accounts/cent`,
          // },
          {
            name: locale.t("cover.deposit.title"),
            link: `/${localStorage.getItem("locale")}/accounts/funding`,
          },
          {
            name: locale.t("cover.withdraw.title"),
            link: `/${localStorage.getItem("locale")}/accounts/withdrawal`,
          },
        ],
      },
    ];
    const companylist = [
      {
        title: locale.t("company"),
        list: [
          {
            name: locale.t("partnership"),
            link: `https://partner.iuxmarkets.com/en/`,
          },
          {
            name: locale.t("startbonus"),
            link: `/${localStorage.getItem("locale")}/promotion`,
          },
          {
            name: locale.t("loyaltybonus"),
            link: `/${localStorage.getItem("locale")}/lotback#`,
          },
          {
            name: locale.t("help_cover_title"),
            link: `https://help.iuxmarkets.com/${localStorage.getItem(
              "locale"
            )}/`,
          },
          {
            name: locale.t("about"),
            link: `/${localStorage.getItem("locale")}/about-us`,
          },
          {
            name: `Privacy & Terms`,
            link: `https://policy.iuxmarkets.com/${localStorage.getItem(
              "locale"
            )}`,
          },
        ],
      },
    ];
    const marketlist = [
      {
        title: "Markets",
        list: [
          {
            name: "CFDS",
            link: `/${localStorage.getItem("locale")}/market/cfds`,
          },
          {
            name: "Futures",
            link: `/${localStorage.getItem("locale")}/market/futures`,
          },
          {
            name: locale.t("Indices"),
            link: `/${localStorage.getItem("locale")}/market/indices`,
          },
          {
            name: locale.t("Shares"),
            link: `/${localStorage.getItem("locale")}/market/shares`,
          },
          {
            name: locale.t("Metals"),
            link: `/${localStorage.getItem("locale")}/market/metals`,
          },
          {
            name: locale.t("Energies"),
            link: `/${localStorage.getItem("locale")}/market/energies`,
          },
          {
            name: "Instrument",
            link: `/${localStorage.getItem("locale")}/instrument`,
          },
        ],
      },
    ];

    return (
      <nav style={{ backgroundColor: "#000" }}>
        <div
          className="menuBar col-md"
          style={{
            boxShadow: this.state.boxShadow,
          }}
        >
          <div className="container nav-auto-dflex">
            <div className="menuCon">
              <div className="rightMenu">
                <RightMenu onHover={this.onHover} />
              </div>
              <Drawer
                width="100%"
                closable={false}
                onClose={this.onClose}
                visible={this.state.visible}
                placement="left"
              >
                <div className="pt-1 bg-draweriux">
                  <div className="d-flex">
                    <div style={{ order: "2" }}>
                      <Button
                        className="d-flex MenuOutline"
                        onClick={this.onClose}
                      >
                        <CloseOutlined />
                      </Button>
                    </div>
                    <div className="col-md logo-autoNav ml-5">
                      <Link
                        to={`/${localStorage.getItem("locale")}/wallet/`}
                        style={{ padding: 0 }}
                      >
                        <img
                          alt="IUX"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/iuxmarket_logo.svg"
                          }
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex mt-1 border-tbWallet-landing">
                    <div
                      style={{ width: "80%" }}
                      className="col-md d-flex"
                    ></div>
                    <div onClick={this.showChildrenDrawer}>
                      <div className="d-flex">
                        <div className="col-md btn text-white d-block">
                          <div
                            style={{ textTransform: "uppercase" }}
                            className="d-flex ml-4 d-flex justify-content-between"
                          >
                            <div>
                              <span
                                className={`fi fi-${
                                  localStorage.getItem("locale") === "en"
                                    ? "gb"
                                    : localStorage.getItem("locale")
                                }`}
                              />
                            </div>
                            <div
                              className="ml-2 mt-1"
                              style={{ fontSize: "12px" }}
                            >
                              {localStorage.getItem("locale")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Drawer
                      className="bg-draweriux"
                      width="100%"
                      closable={false}
                      placement="left"
                      onClose={this.onChildrenDrawerClose}
                      visible={this.state.childrenDrawer}
                    >
                      <div
                        className="d-flex bg-draweriux"
                        style={{
                          borderBottom: "1px solid #ffffff1a",
                          padding: "12px",
                        }}
                      >
                        <div className="bg-draweriux" style={{ order: "2" }}>
                          <Button
                            className="d-flex MenuOutline"
                            onClick={this.onChildrenDrawerClose}
                          >
                            <CloseOutlined />
                          </Button>
                        </div>
                        <div className="col-md logo-autoNav ml-5">
                          <h4 className="text-white">Country</h4>
                        </div>
                      </div>
                      <Menu id="menu-iuxdrawer">
                        {this.langs.map((i, x) => (
                          <Menu.Item key={x}>
                            <Link
                              className="text-white"
                              to="#"
                              onClick={() => this.handleChangLanguage(i.lang)}
                            >
                              <div className="d-flex justify-content-between text-white">
                                <div>
                                  {/* <img
                                    className="mr-2 mb-1 rounded shadow"
                                    style={flagStyle}
                                    src={process.env.PUBLIC_URL + i.img}
                                    alt=""
                                  /> */}
                                  <span className={`fi fi-${i?.img}`} />
                                </div>
                                <p className="text-left">&nbsp;{i.name}</p>
                              </div>
                            </Link>
                          </Menu.Item>
                        ))}
                      </Menu>
                    </Drawer>
                  </div>
                </div>
                <div style={{ height: "100vh", background: "#000" }}></div>
                {/* <Menu
                  key="1"
                  theme="dark"
                  defaultSelectedKeys={["1"]}
                  mode="inline"
                  id="menu-iuxdrawer"
                  openKeys={this.state.openKeys}
                  onOpenChange={this.onOpenChange}
                >
                  <SubMenu
                    key="sub1"
                    title={
                      <span className="text-white-hover">
                        {locale.t("market")}
                      </span>
                    }
                  >
                    {marketlist.map((market, index) => {
                      return market.list.map((subMenu) => {
                        return (
                          <Menu.Item>
                            <Link
                              key={index}
                              className="text-white"
                              onClick={this.onClose}
                              to={subMenu.link}
                            >
                              {subMenu.name}
                            </Link>
                          </Menu.Item>
                        );
                      });
                    })}
                  </SubMenu>
                  <SubMenu
                    key="sub2"
                    title={
                      <span className="text-white-hover">
                        {locale.t("account")}
                      </span>
                    }
                  >
                    <Menu.Item>
                      <Link
                        key={"account"}
                        className="text-white"
                        onClick={this.onClose}
                        to={`/${localStorage.getItem("locale")}/accounts`}
                      >
                        {locale.t("cover.accover.title")}
                      </Link>
                    </Menu.Item>
                    {this.props?.account?.accountTypeList
                      ?.filter((e) => e.active === 1 && e.type === "real")
                      ?.map((e, index) => (
                        <Menu.Item>
                          <Link
                            key={index}
                            className="text-white"
                            onClick={this.onClose}
                            to={`/${localStorage.getItem("locale")}/accounts/${
                              e.display
                            }`}
                          >
                            {e.display}
                          </Link>
                        </Menu.Item>
                      ))}
                    <Menu.Item>
                      <Link
                        key={"funding"}
                        className="text-white"
                        onClick={this.onClose}
                        to={`/${localStorage.getItem(
                          "locale"
                        )}/accounts/funding`}
                      >
                        {locale.t("cover.deposit.title")}
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link
                        key={"account"}
                        className="text-white"
                        onClick={this.onClose}
                        to={`/${localStorage.getItem(
                          "locale"
                        )}/accounts/withdrawal`}
                      >
                        {locale.t("cover.withdraw.title")}
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link
                        key={"Spread"}
                        className="text-white"
                        onClick={this.onClose}
                        to={`/${localStorage.getItem(
                          "locale"
                        )}/swap/non-islamic/Standard`}
                      >
                        Spread & Swap
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="sub3"
                    title={
                      <span className="text-white-hover">
                        {locale.t("tools")}
                      </span>
                    }
                    // inlineCollapsed
                    // mode="inline"
                    // theme="dark"
                  >
                    {tools.map((item, index) => {
                      return item.list.map((subMenu) => {
                        return (
                          <Menu.Item>
                            <Link
                              key={index}
                              className="text-white"
                              onClick={this.onClose}
                              to={subMenu.link}
                            >
                              {subMenu.name}
                            </Link>
                          </Menu.Item>
                        );
                      });
                    })}
                  </SubMenu>

                  <SubMenu
                    key="sub4"
                    title={
                      <span className="text-white-hover">
                        {locale.t("company")}
                      </span>
                    }
                  >
                    {companylist.map((item, index) => {
                      return item.list.map((subMenu, i) => {
                        return (
                          <Menu.Item>
                            {i === 0 || i === 3 || i === 5 ? (
                              <a
                                target={"_blank"}
                                key={i}
                                href={subMenu.link}
                                className="text-white"
                                onClick={this.onClose}
                                rel="noreferrer"
                              >
                                {subMenu.name}
                              </a>
                            ) : (
                              <Link
                                key={i}
                                className="text-white"
                                onClick={this.onClose}
                                to={subMenu.link}
                              >
                                {subMenu.name}
                              </Link>
                            )}
                          </Menu.Item>
                        );
                      });
                    })}
                  </SubMenu>
                </Menu> */}
              </Drawer>
            </div>
            <div>
              <Button
                className="barsMenu"
                type="primary"
                onClick={this.showDrawer}
              >
                <MenuOutlined />
              </Button>
            </div>
            <div className="logo mt-3">
              <Link
                to={`/${localStorage.getItem("locale")}`}
                style={{ padding: 0, overflowY: "auto" }}
              >
                <img
                  alt="IUX"
                  className="logo-s-landing"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/iuxmarket_logo.svg"
                  }
                />
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = ({ user, account }) => {
  return { user, account };
};

export default connect(mapStateToProps, { setLocale, setLocation })(
  withRouter(Navbar)
);
