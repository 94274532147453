import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import RcQueueAnim from "rc-queue-anim";
import { Breadcrumb } from "antd";

import FooterLandingPage from "../../../components/footer-landing-page/FooterDark";
import Axios from "axios";
import { useParams } from "react-router-dom";
import Allsymbol from "../../../mock/symbols/allsymbols.json";
import Sectionoverview from "./sectionoverview";
import Sectionspread from "./sectionspread";
import TradingViewWidget from "./sectionoverview2";
import { CheckLanguage } from "../../../helper/countryLang";
const locale = require("react-redux-i18n").I18n;
const mockdata = [
  { title: "Digits", values: "Digits" },
  { title: "Contract size", values: "ContractSize" },
  { title: "Spread", values: "Spread" },
  { title: "Stop level", values: "StopsLevel" },
  { title: "Margin currency", values: "CurrencyMargin" },
  { title: "Profit currency", values: "CurrencyProfit" },
  { title: "Calculation", values: "Path" },
];
export const Analyzesymbol = ({ islayout }) => {
  let { id } = useParams();
  const [dataSymbol, setDataSymbol] = useState({});
  const [dataInicators, setDataInicators] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };
  const overviewMemo = useMemo(
    () => (
      // <TradingViewWidget />
      <Sectionoverview
        widgetProps={{
          colorTheme: islayout === "dashboard" ? "light" : "dark",
          symbols: id,
          isTransparent: islayout !== "dashboard" ? true : false,
        }}
      />
    ),
    [id, islayout]
  );
  const fetchIndicator = async () => {
    try {
      setIsLoading(true);
      let fsymbol = Allsymbol.find((e) => e.Symbol === id.toUpperCase());
      setDataSymbol(fsymbol);
      if (fsymbol.Path === "Forex" || fsymbol.Path === "Crypto") {
        const options = {
          method: "POST",
          url: `https://fcs1.p.rapidapi.com/${fsymbol.Path.toLocaleLowerCase()}/indicators`,

          headers: {
            "content-type": "application/x-www-form-urlencoded",
            "X-RapidAPI-Key":
              "f5af97c325msh7b5767cfc63ec19p1ed64fjsne7025f76539b",
            "X-RapidAPI-Host": "fcs1.p.rapidapi.com",
          },
          data: { period: "1d", symbol: id },
        };
        Axios.request(options)
          .then(function (response) {
            setDataInicators(response.data.response);
          })
          .catch(function (error) {
            console.error(error);
          });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchIndicator();
  }, []);

  return (
    <RcQueueAnim className={islayout !== "dashboard" && "bg-black"}>
      <div
        key={2}
        className="container"
        style={{
          height: "auto",
          paddingTop:
            islayout !== "dashboard"
              ? window.innerWidth <= 425
                ? "20%"
                : "10%"
              : "",
        }}
      >
        {islayout !== "dashboard" && (
          <Breadcrumb className={islayout !== "dashboard" && "text-white"}>
            <Breadcrumb.Item
              className={islayout !== "dashboard" && "text-white"}
            >
              <a
                href={`/${localStorage.getItem("locale")}/`}
                className={islayout !== "dashboard" && "text-white"}
              >
                Home
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className={islayout !== "dashboard" && "text-white"}
            >
              <a
                href={`/${localStorage.getItem("locale")}/instrument`}
                className={islayout !== "dashboard" && "text-white"}
              >
                Instrument
              </a>
            </Breadcrumb.Item>

            <Breadcrumb.Item
              className={islayout !== "dashboard" && "text-white"}
            >
              {id.toUpperCase()}
            </Breadcrumb.Item>
          </Breadcrumb>
        )}
        <div className="my-4">
          {!isLoading && (
            <TradingViewWidget
              widgetProps={{
                colorTheme: islayout === "dashboard" ? "light" : "dark",
                symbols: id,
                isTransparent: islayout !== "dashboard" ? true : false,
                lang: CheckLanguage(localStorage.getItem("locale")),
              }}
            />
          )}
          <div className="tradingview-widget-copyright">
            Real-time Market Analysis Widget Powered by TradingView <br />
            &nbsp;
            <a
              href={`https://www.tradingview.com`}
              rel="noopener nofollow"
              target="_blank"
            >
              <span className="blue-text">
                Track all markets on TradingView
              </span>
            </a>
            &nbsp;for up-to-the-minute financial insights.
          </div>
          {/* {!isLoading && overviewMemo} */}

          {/* <div className="tradingview-widget-copyright">
            <a
              href="https://www.tradingview.com/"
              rel="noopener nofollow"
              target="_blank"
            >
              <span className="blue-text">
                Track all markets on TradingView
              </span>
            </a>
          </div> */}
        </div>
        <div className="mt-5">
          <div className="row">
            <div className="col-12 col-sm-6">
              <Sectionspread id={id} islayout={islayout} />

              <div
                className={`${
                  islayout === "dashboard" ? "bg-white" : "bg-dark-gray"
                } p-4 mt-4`}
                style={{ borderRadius: 10 }}
              >
                <h4 className={islayout !== "dashboard" && "text-white"}>
                  Details
                </h4>
                <div>
                  {dataSymbol &&
                    mockdata.map((e, i) => (
                      <div
                        className="d-flex justify-content-between py-3 "
                        style={{ borderBottom: "1px solid #3C3C3C" }}
                      >
                        <span
                          className={islayout !== "dashboard" && "text-white"}
                        >
                          {e.title}
                        </span>
                        <span className="text-primary-new-iux">
                          {isNumeric(dataSymbol[e.values])
                            ? parseInt(dataSymbol[e.values]).toFixed(0)
                            : dataSymbol[e.values]}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              {Object.keys(dataInicators).length > 0 ? (
                <div
                  className={`${
                    islayout === "dashboard" ? "bg-white" : "bg-dark-gray"
                  } p-4 `}
                  style={{ borderRadius: 10 }}
                >
                  <h4 className={islayout !== "dashboard" && "text-white"}>
                    Technical Analytic
                  </h4>
                  <div
                    className={`text-center py-4 fs-24 ${
                      islayout !== "dashboard" && "text-white"
                    }`}
                  >
                    {dataInicators.indicators &&
                      dataInicators.indicators.summary}
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <span className="text-gray">Indicator</span>
                      {dataInicators.indicators &&
                        Object.keys(dataInicators.indicators).map((e, i) => (
                          <div
                            className={islayout !== "dashboard" && "text-white"}
                            key={i}
                          >
                            <b>{e !== "summary" && e}</b>
                          </div>
                        ))}
                    </div>
                    <div className="col-6">
                      <div className="d-flex justify-content-between">
                        <div className="text-gray text-right">Signal</div>
                        <div className="text-gray text-right">Value</div>
                      </div>
                      {dataInicators.indicators &&
                        Object.values(dataInicators.indicators).map((e, i) => (
                          <>
                            {i !==
                              Object.values(dataInicators.indicators).length -
                                1 && (
                              <div
                                className={`${
                                  islayout !== "dashboard" && "text-white"
                                } d-flex justify-content-between `}
                                key={i}
                              >
                                <span className="text-center">{e.v}</span>
                                <span
                                  className={
                                    e.s === "Buy"
                                      ? "text-primary-new-iux "
                                      : e.s === "Sell"
                                      ? "text-danger"
                                      : ""
                                  }
                                >
                                  ({e.s})
                                </span>
                              </div>
                            )}
                          </>
                        ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={`${
                    islayout === "dashboard" ? "bg-white" : "bg-dark-gray"
                  } p-4 d-flex justify-content-center align-items-center`}
                  style={{ borderRadius: 10, height: 500 }}
                >
                  <h4
                    className={`${
                      islayout !== "dashboard" && "text-white"
                    } text-center`}
                  >
                    Coming soon....
                  </h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {islayout !== "dashboard" && (
        <section key={10}>
          <FooterLandingPage locale={locale} />
        </section>
      )}
    </RcQueueAnim>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Analyzesymbol);
