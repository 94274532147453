import {
  Col,
  Row,
  Layout,
  Button,
  Modal,
  Input,
  Select,
  Form,
  message,
  Alert,
  Popover,
  Divider,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { post } from "../../helper/request";
import { addAccount } from "../../actions";
import CreateAccount from "./Account/CreateAccount";
const locale = require("react-redux-i18n").I18n;
const { Option } = Select;
const MenuItem = styled.div`
  cursor: pointer;
  color: ${(props) => props.color};
  white-space: pre;
  padding: 0.8rem 1rem;
  font-weight: ${(props) => props.fontWeight};
  position: relative;
  &:after {
    display: block;
    content: "";
    margin-top: 0.8rem;
    border-bottom: 4px solid #1cb894;
    transform: scaleX(${(props) => (props.borderBottom ? 1 : 0)});
    transition: transform 250ms ease-in-out;
  }
  &:hover:after {
    transform: scaleX(1);
  }
`;
const BadgeAccount = styled.div`
  border-radius: 40px;
  padding: 8px 16px;
  background: ${(props) => props.bg};
  display: block;
  color: ${(props) => props.fc};
  font-weight: 700;
  font-size: 14px;
  width: fit-content;
`;

function OpenAccount() {
  const refAdd = React.createRef();
  let urlParams = new URLSearchParams(window.location.search);
  let typeAccount = urlParams.get("typeAccount");
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [modalCreateVisible, setModalCreateVisible] = useState(false);
  const user = useSelector((state) => state.user);
  const accountTypeList = useSelector((state) => state.account.accountTypeList);
  const accountTypeListDemo = useSelector(
    (state) => state.account.accountTypeListDemo
  );
  const accounts = useSelector((state) => state.user.accounts);
  const [isTab, setIsTab] = useState(1);
  const [mobile, setMobile] = useState(false);
  const leverages = useSelector((state) => state.user.leverages);
  const [isType, setIsType] = useState();
  const [isDetail, setisDetail] = useState();
  const [accountRecommend, setAccountRecommend] = useState(
    accountTypeList?.filter(
      (e) =>
        e.type === "real" &&
        (e.name === "StandardBonus" || e.name === "Standard")
    )
  );
  const bgaccrec = [
    { bg: "#D2F1EA", fc: "#1CB894" },
    { bg: "rgba(0, 158, 255, 0.2)", fc: "#009EFF" },
  ];
  const bgaccpro = [
    { bg: "rgba(227, 103, 63, 0.2);", fc: "#B44844" },
    { bg: "rgba(126, 11, 217, 0.2)", fc: "#7E0BD9" },
  ];

  const onCloseCreateAccount = () => {
    setModalCreateVisible(false);
  };
  const validatorPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = new RegExp(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
      );

      if (value?.match(regex)) {
        return Promise.resolve();
      } else {
        if (value) {
          return Promise.reject(
            locale.t("dashboardWallet.modal.accountPassword_validate")
          );
        } else {
          return Promise.reject(locale.t("dashboardWallet.input_error"));
        }
      }
    },
  });
  useEffect(() => {
    if (typeAccount === "Professional") {
      setAccountRecommend(
        accountTypeList?.filter(
          (e) => e.type === "real" && (e.name === "Pro" || e.name === "Raw")
        )
      );
      setIsTab(2);
    } else {
      setAccountRecommend(
        accountTypeList?.filter(
          (e) =>
            e.type === "real" &&
            (e.name === "StandardBonus" || e.name === "Standard")
        )
      );
      setIsTab(1);
    }
  }, [accountTypeList, typeAccount]);
  // useEffect(() => {
  //   setAccountRecommend(
  //     accountTypeList?.filter(
  //       (e) =>
  //         e.type === "real" &&
  //         (e.name === "StandardBonus" || e.name === "Standard")
  //     )
  //   );
  // }, [accountTypeList]);
  return (
    <div className="p-4">
      <div className="d-flex pb-4 justify-content-between" key={1}>
        <p className="text-header-account-wallet">
          {locale.t("dashboardWallet.modal.title")}
        </p>
      </div>
      <div className="sub-menu-page-user" key={2}>
        <div className=" user">
          <div className="d-flex overflow-auto scroll-menu-hide-user ">
            <MenuItem
              onClick={() => {
                setAccountRecommend(
                  accountTypeList?.filter(
                    (e) =>
                      e.type === "real" &&
                      (e.name === "StandardBonus" || e.name === "Standard")
                  )
                );
                setIsTab(1);
              }}
              color={isTab === 1 ? "#000" : "#8D8D8D"}
              borderBottom={isTab === 1}
              fontWeight={isTab === 1 ? "unset" : "unset"}
            >
              <div className="d-flex align-items-center">
                {locale.t("dashboardWallet.recommended")}
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAccountRecommend(
                  accountTypeList?.filter(
                    (e) =>
                      e.type === "real" &&
                      (e.name === "Raw" || e.name === "Pro")
                  )
                );
                setIsTab(2);
              }}
              color={isTab === 2 ? "#000" : "#8D8D8D"}
              borderBottom={isTab === 2}
              fontWeight={isTab === 2 ? "unset" : "unset"}
            >
              <div className="d-flex align-items-center">
                {locale.t("dashboardWallet.Professional")}
              </div>
            </MenuItem>
          </div>
        </div>
      </div>
      <div>
        <div className=" ">
          {accountRecommend?.map((e, i) => (
            <div className="card-irp-style d-flex justify-content-start  justify-content-sm-between flex-column flex-md-row mb-3">
              <div>
                <div className="d-flex justify-content-center justify-content-lg-start">
                  <BadgeAccount
                    bg={isTab === 1 ? bgaccrec[i]?.bg : bgaccpro[i]?.bg}
                    fc={isTab === 1 ? bgaccrec[i]?.fc : bgaccpro[i]?.fc}
                  >
                    {e.display}
                  </BadgeAccount>
                  {[...Array(e?.popularity)].map((e, i) => (
                    <img
                      src="/assets/images/icons/dashboard/starAcc.svg"
                      alt="starAcc"
                      className="ml-1"
                    />
                  ))}
                </div>
                <div className="fs-20 fw-700 mt-2 text-center text-md-left">
                  Spread From {e.minimum_spread} pips
                </div>
                <div className="mt-2 text-lightgray text-center text-sm-left">
                  <p className="">
                    {`${locale.t("account_detail.detail2")} $${
                      e?.minimum_deposit
                    }`}
                  </p>
                  <p className="mt-2">
                    {`${locale.t("account_detail.detail3")} $${e?.commission}`}{" "}
                    per lot per side
                  </p>
                  <p className="mt-2">{`${locale.t(
                    "account_detail.detail4"
                  )} 1:${e?.maximum_leverage}`}</p>
                </div>
              </div>
              <div className="my-sm-auto my-2">
                <button
                  // onClick={setDeposit}
                  className="btn-primary-new-iux w-100 "
                  type="primary"
                  style={{ borderRadius: 8 }}
                  onClick={() => {
                    if (user.userVerify.status === "accepted") {
                      setIsType("trade-real");
                      setisDetail(e);
                      setModalCreateVisible(true);
                    } else {
                      history.push(
                        `/${localStorage.getItem(
                          "locale"
                        )}/wallet/account/Standard`
                      );
                    }
                  }}
                >
                  {locale.t("deposit_info.mt_account")}
                </button>
                <button
                  // onClick={setDeposit}
                  className="btn-orange-new-outline w-100 mt-2"
                  type="primary"
                  style={{ borderRadius: 8 }}
                  onClick={() => {
                    setIsType("trade-demo");
                    setisDetail(e);
                    setModalCreateVisible(true);
                  }}
                >
                  {locale.t("trydemo")}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/***
       * Create Account Modal
       */}
      <CreateAccount
        isOpen={modalCreateVisible}
        onClose={onCloseCreateAccount}
        isType={isType}
        isAccountType={isDetail?.display}
      />
      {/*  */}
    </div>
  );
}

export default OpenAccount;
