export default {
  th: {
    verify_that_title: "	ตรวจสอบว่าเป็นคุณ	",
    verify_sub_title: "	เพื่อความปลอดภัยของคุณก่อนอื่นให้ตรวจสอบว่าเป็นคุณ	",
    current_password: "	รหัสผ่านปัจจุบัน	",
    change_phone_title: "	เปลี่ยนหมายเลขโทรศัพท์	",
    accept_to_condition: "	ฉันเห็นด้วยกับ	",
    sub_change_condition:
      "	โดยการคลิก“ คำขอ OTP” คุณยินยอมให้รับโทรศัพท์และข้อความ SMS จากIUX Markets เกี่ยวกับการอัปเดตตามคำสั่งซื้อของคุณ	",
    new_phone_number: "	หมายเลขโทรศัพท์ใหม่	",
    verify_new_phone: "	ยืนยันหมายเลขโทรศัพท์ใหม่ของคุณ	",
    verify_phone_success: "	หมายเลขโทรศัพท์ของคุณได้รับการยืนยันแล้ว	",
  },
  la: {
    verify_that_title: "	ຢືນຢັນວ່າມັນແມ່ນທ່ານ	",
    verify_sub_title: "	ເພື່ອຄວາມປອດໄພຂອງທ່ານ, ທໍາອິດຢັ້ງຢືນວ່າມັນແມ່ນທ່ານ.	",
    current_password: "	ລະ​ຫັດ​ປັດ​ຈຸ​ບັນ	",
    change_phone_title: "	ປ່ຽນເບີໂທລະສັບ	",
    accept_to_condition: "	ຂ້າພະເຈົ້າຕົກລົງເຫັນດີກັບ	",
    sub_change_condition:
      "	ໂດຍການກົດປຸ່ມ ຮ້ອງຂໍ OTP ທ່ານຍິນຍອມທີ່ຈະຮັບໂທລະສັບແລະຂໍ້ຄວາມ SMS ຈາກ Iux message ກ່ຽວກັບການອັບເດດຂອງທ່ານ.	",
    new_phone_number: "	ເບີໂທລະສັບໃຫມ່	",
    verify_new_phone: "	ຢືນຢັນເບີໂທລະສັບໃຫມ່ຂອງທ່ານ	",
    verify_phone_success: "	ເບີໂທລະສັບຂອງທ່ານໄດ້ຮັບການຢັ້ງຢືນແລ້ວ.	",
  },
  en: {
    verify_that_title: "	Verify that it’s you	",
    verify_sub_title: "	For your security, first verify that it’s you.	",
    current_password: "	Current password	",
    change_phone_title: "	Change phone number	",
    accept_to_condition: "	I agree to the	",
    sub_change_condition:
      "	By clicking “Request OTP” you consent to receive phone calls and SMS messages from IUX Markets regarding updates on your order.	",
    new_phone_number: "	New Phone number	",
    verify_new_phone: "	Verify your new phone number	",
    verify_phone_success: "	Your phone number has been verified.	",
  },
  in: {
    verify_that_title: "	सत्यापित करें कि यह आप है	",
    verify_sub_title: "	आपकी सुरक्षा के लिए, पहले सत्यापित करें कि यह आप है।	",
    current_password: "	वर्तमान पासवर्ड	",
    change_phone_title: "	फोन नंबर बदलें	",
    accept_to_condition: "	मैं करने के लिए सहमत हूं	",
    sub_change_condition:
      "	अनुरोध ओटीपी पर क्लिक करके आप अपने आदेश पर अपडेट के बारे में IUX Marketsसे फोन कॉल और एसएमएस संदेश प्राप्त करने के लिए सहमति देते हैं।	",
    new_phone_number: "	नया फोन नंबर	",
    verify_new_phone: "	अपना नया फ़ोन नंबर सत्यापित करें	",
    verify_phone_success: "	आपका फ़ोन नंबर सत्यापित किया गया है।	",
  },
  my: {
    verify_that_title: "	Sahkan bahawa itu anda	",
    verify_sub_title:
      "	Untuk keselamatan anda, sahkan terlebih dahulu bahawa anda adalah anda.	",
    current_password: "	Kata laluan semasa	",
    change_phone_title: "	Tukar nombor telefon	",
    accept_to_condition: "	Saya bersetuju dengan	",
    sub_change_condition:
      "	Dengan mengklik Permintaan OTP, anda bersetuju untuk menerima panggilan telefon dan mesej SMS dari pasaran IUX mengenai kemas kini pesanan anda.	",
    new_phone_number: "	Nombor telefon baru	",
    verify_new_phone: "	Sahkan nombor telefon baru anda	",
    verify_phone_success: "	Nombor telefon anda telah disahkan.	",
  },
  id: {
    verify_that_title: "	Pastikan itu Anda	",
    verify_sub_title:
      "	Untuk keamanan Anda, pertama verifikasi bahwa itu adalah Anda.	",
    current_password: "	Kata sandi saat ini	",
    change_phone_title: "	Ubah nomor telepon	",
    accept_to_condition: "	Saya setuju dengan	",
    sub_change_condition:
      "	Dengan mengklik meminta OTP Anda menyetujui untuk menerima panggilan telepon dan pesan SMS dari pasar IUX mengenai pembaruan pada pesanan Anda.	",
    new_phone_number: "	Nomor telepon baru	",
    verify_new_phone: "	Verifikasi nomor telepon baru Anda	",
    verify_phone_success: "	Nomor telepon Anda telah diverifikasi.	",
  },
  cn: {
    verify_that_title: "	驗證是你	",
    verify_sub_title: "	為了確保您的安全，首先驗證您是您。	",
    current_password: "	當前密碼	",
    change_phone_title: "	更改電話號碼	",
    accept_to_condition: "	我同意	",
    sub_change_condition:
      "	通過單擊“請求OTP”，您同意接收有關您訂單更新的IUX市場的電話和SMS消息。	",
    new_phone_number: "	新電話號碼	",
    verify_new_phone: "	驗證您的新電話號碼	",
    verify_phone_success: "	您的電話號碼已驗證。	",
  },
  vn: {
    verify_that_title: "	Xác minh rằng nó	",
    verify_sub_title:
      "	Đối với bảo mật của bạn, trước tiên hãy xác minh rằng nó là bạn.	",
    current_password: "	Mật khẩu hiện tại	",
    change_phone_title: "	Thay đổi số điện thoại	",
    accept_to_condition: "	tôi đồng ý với	",
    sub_change_condition:
      "	Bằng cách nhấp vào yêu cầu của OTP, bạn đồng ý nhận các cuộc gọi điện thoại và tin nhắn SMS từ các thị trường IUX liên quan đến các bản cập nhật trên đơn đặt hàng của bạn.	",
    new_phone_number: "	Số điện thoại mới	",
    verify_new_phone: "	Xác minh số điện thoại mới của bạn	",
    verify_phone_success: "	Số điện thoại của bạn đã được xác minh.	",
  },
  kr: {
    verify_that_title: "	그것이 당신인지 확인하십시오	",
    verify_sub_title: "	보안을 위해 먼저 그것이 당신인지 확인하십시오.	",
    current_password: "	현재 비밀번호	",
    change_phone_title: "	전화 번호를 변경하십시오	",
    accept_to_condition: "	나는 동의 함	",
    sub_change_condition:
      "	요청을 클릭하면 주문 업데이트와 관련하여 IUX 시장으로부터 전화 및 SMS 메시지를받는 데 동의합니다.	",
    new_phone_number: "	새로운 전화 번호	",
    verify_new_phone: "	새 전화 번호를 확인하십시오	",
    verify_phone_success: "	전화 번호가 확인되었습니다.	",
  },
  ph: {
    verify_that_title: "	Patunayan na ikaw ito	",
    verify_sub_title: "	Para sa iyong seguridad, mapatunayan muna na ikaw ito.	",
    current_password: "	Kasalukuyang password	",
    change_phone_title: "	Baguhin ang numero ng telepono	",
    accept_to_condition: "	Sumasang -ayon ako sa	",
    sub_change_condition:
      "	Sa pamamagitan ng pag -click sa Humiling ng OTP na pahintulot mong makatanggap ng mga tawag sa telepono at mga mensahe ng SMS mula sa mga IUX Markets patungkol sa mga pag -update sa iyong order.	",
    new_phone_number: "	Bagong numero ng telepono	",
    verify_new_phone: "	Patunayan ang iyong bagong numero ng telepono	",
    verify_phone_success: "	Na -verify ang numero ng iyong telepono.	",
  },
  jp: {
    verify_that_title: "それがあなたであることを確認してください",
    verify_sub_title:
      "あなたのセキュリティのために、まずそれがあなたであることを確認してください。",
    current_password: "現在のパスワード",
    change_phone_title: "電話番号を変更します",
    accept_to_condition: "私はに同意します",
    sub_change_condition:
      "「Request OTP」をクリックすると、注文の更新に関してIUX市場から電話とSMSメッセージを受信することに同意します。",
    new_phone_number: "新しい電話番号",
    verify_new_phone: "新しい電話番号を確認します",
    verify_phone_success: "お使いの電話番号が確認されました。",
  },
};
