import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import {
  Col,
  Row,
  Layout,
  Button,
  Modal,
  Input,
  Select,
  Form,
  message,
  Alert,
  Popover,
} from "antd";
const { Option } = Select;
function Webterminal({ version }) {
  const history = useHistory();
  const loc = localStorage.getItem("locale");
  let ref = useRef();
  let urlParams = new URLSearchParams(window.location.search);
  let mobile = urlParams.get("mobile");
  let isserver = urlParams.get("sever");
  const [selectserver, setSelectserver] = useState("demo");
  useEffect(() => {
    setSelectserver(isserver);
  }, [urlParams]);
  const onChangeSever = (value) => {
    history.push(`/${loc}/webterminal/version5?sever=${value}`);
  };
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </Helmet>
      {/* <div style={{ height: '66px', backgroundColor: '#2073b7' }}></div> */}
      {!mobile && (
        <div className="bg-black d-flex align-items-center justify-content-between px-4">
          <div className=" nav-auto-dflex py-2">
            <Link to={`/${loc}`} className="text-white">
              <img
                alt="IUX"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/icons/iuxmarket_logo.svg"
                }
              />
              &nbsp; IUX Markets
            </Link>
          </div>
          <Select
            size="large"
            value={selectserver}
            onChange={(e) => onChangeSever(e)}
            placeholder="Leverage"
          >
            <Option value={"live"}>Live 1</Option>
            <Option value={"live2"}>Live 2</Option>
            <Option value={"live3"}>Live 3</Option>
            <Option value={"demo"}>Demo</Option>
          </Select>
        </div>
      )}
      <div id="webterminal" ref={(e) => (ref = e)} className="height-100vh">
        <iframe
          src={`https://${selectserver}.iuxmarkets.com/terminal`}
          style={{ width: "100%", height: "100vh" }}
        ></iframe>
      </div>
    </>
  );
}

export default Webterminal;
