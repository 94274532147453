import React, { Component } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import { Menu, Icon, Button, Dropdown } from "antd";
import { connect } from "react-redux";
import { setLocale } from "react-redux-i18n";
import { setLocation } from "../../actions";
import styled from "styled-components";
import "../../assets/css/navbar.css";
import { DownOutlined } from "@ant-design/icons";
const locale = require("react-redux-i18n").I18n;

const MenuDropdownCustom = styled.div`
  position: fixed;
  z-index: 1;
  background: black;
  width: auto;
  // margin-left: -10px;
  text-align: center;
  min-width: 180px;
`;

const ButtonSignUp = styled(Button)`
  background-color: #1cb894;
  color: #fff;
  border: 1px solid #1cb894;
  border-radius: 6px;
  height: 42px;
  width: auto;
  transition: unset;
  &:hover {
    transition: unset;
    color: #1cb894;
    background-color: #000;
    border: 1px solid #1cb894;
  }
`;
const ButtonSignIn = styled(Button)`
  color: #1cb894;
  background-color: transparent;
  border: 1px solid #1cb894;
  border-radius: 6px;
  height: 42px;
  transition: unset;
  &:hover {
    color: #fff;
    transition: unset;
    background-color: #1cb894;
    border: 1px solid #1cb894;
  }
`;

const marketlist = [
  {
    name: "CFDs",
    link: `/${localStorage.getItem("locale")}/market/cfds`,
  },
  {
    name: "Futures",
    link: `/${localStorage.getItem("locale")}/market/futures`,
  },
  {
    name: locale.t("Indices"),
    link: `/${localStorage.getItem("locale")}/market/indices`,
  },
  {
    name: locale.t("Shares"),
    link: `/${localStorage.getItem("locale")}/market/shares`,
  },
  {
    name: locale.t("Metals"),
    link: `/${localStorage.getItem("locale")}/market/metals`,
  },
  {
    name: locale.t("Energies"),
    link: `/${localStorage.getItem("locale")}/market/energies`,
  },
];

const flagStyle = {
  width: 20,
  height: 20,
};

const flagImg = [
  {
    name: "th",
    country: "ภาษาไทย",
    value: "thailand.svg",
  },
  {
    name: "en",
    country: "English",
    value: "united-kingdom.svg",
  },
  {
    name: "zh",
    country: "China",
    value: "china.svg",
  },
  {
    name: "ko",
    country: "Korea",
    value: "south-korea.svg",
  },
  {
    name: "vi",
    country: "Vietnam",
    value: "vietnam.svg",
  },
  {
    name: "ms",
    country: "Malaysia",
    value: "malaysia.svg",
  },
  {
    name: "id",
    country: "Indonesia",
    value: "indonesian.svg",
  },
  {
    name: "cn",
    country: "China",
    value: "france.svg",
  },
  {
    name: "vi",
    country: "Vitenam",
    value: "vitenam.svg",
  },
  {
    name: "ko",
    country: "Korea",
    value: "korea.svg",
  },
  {
    name: "ph",
    country: "Philippines",
    value: "philippines.svg",
  },
  {
    name: "laos",
    country: "Loas",
    value: "laos.svg",
  },
];

const langs = [
  {
    lang: "en",
    img: "gb",
    name: "English",
  },
  {
    lang: "my",
    img: "my",
    name: "Bahasa melayu",
  },
  {
    lang: "id",
    img: "id",
    name: "Bahasa Indonesia",
  },
  {
    lang: "th",
    img: "th",
    name: "ภาษาไทย",
  },
  {
    lang: "cn",
    img: "cn",
    name: "简体中文",
  },
  {
    lang: "vn",
    img: "vn",
    name: "Tiếng Việt",
  },
  {
    lang: "kr",
    img: "kr",
    name: "한국어",
  },
  {
    lang: "ph",
    img: "ph",
    name: "Filipino",
  },
  {
    lang: "la",
    img: "la",
    name: "ພາສາລາວ",
  },
  {
    lang: "in",
    img: "in",
    name: "India",
  },
  {
    lang: "jp",
    img: "jp",
    name: "Japan",
  },
];

class RightMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: this.props.locale,
      isHovered: false,
      open: false,
    };
    this.handleHover = this.handleHover.bind(this);
    this.onCloseMenuItem = this.onCloseMenuItem.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
  }

  onCloseMenuItem(el) {
    this.setState({
      open: el.key,
    });
  }

  onClickClose() {
    this.setState({
      open: false,
    });
  }

  componentDidMount() {
    if (this.state.open === true) {
      window.addEventListener("click", () => {
        this.setState({
          open: false,
        });
      });
    }
  }

  handleHover() {
    this.setState((prevState) => ({
      isHovered: !prevState.isHovered,
    }));
    this.props.onHover(this.state.isHovered);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.locale !== this.props.locale) {
      this.setState({ locale: nextProps.locale });
    }
  }

  handleChangLanguage = (oldLang, newLang) => {
    const newPath = this.props.history.location.pathname.replace(
      oldLang,
      newLang
    );
    this.props.history.push(newPath);
    window.location.reload();
  };

  handleChangLanguageDesk = (newLang) => {
    const oldLang = localStorage.getItem("locale");
    localStorage.setItem("locale", newLang);
    const newPath = this.props.history.location.pathname.replace(
      oldLang,
      newLang
    );
    this.props.history.push(newPath);
    window.location.reload();
  };

  renderFlag(name = false) {
    const imgFlag = name
      ? flagImg.filter((item) => item.name === this.state.locale)[0].country
      : flagImg.filter((item) => item.name === this.state.locale)[0].value;
    return imgFlag;
  }

  renderUserOrOther = (props) => {
    const menuLanguage = (
      <Menu
        style={{
          background: "rgba(24, 74, 130, 0)",
          textAlign: "right",
          borderRadius: "5px",
        }}
        className="shadow rounded mx-4 w-auto float-right"
      >
        <Menu.Item key="leng">
          <Link
            className="text-white"
            to="#"
            onClick={() =>
              this.handleChangLanguage(localStorage.getItem("locale"), "en")
            }
          >
            <div className="d-flex justify-content-between">
              <div>
                <img
                  className="mr-0 mb-1 rounded shadow"
                  style={flagStyle}
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/united-kingdom.svg"
                  }
                  alt=""
                />
              </div>
              <div>English</div>
            </div>
          </Link>
        </Menu.Item>
        <Menu.Item key="Malaysia">
          <Link
            className="text-white"
            to="#"
            onClick={() =>
              this.handleChangLanguage(localStorage.getItem("locale"), "ms")
            }
          >
            <div className="d-flex justify-content-between">
              <div>
                <img
                  className="mr-0 mb-1 rounded shadow"
                  style={flagStyle}
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icons/malaysia.svg"
                  }
                  alt=""
                />
              </div>
              <div>Malaysia</div>
            </div>
          </Link>
        </Menu.Item>
        <Menu.Item key="Indonesia">
          <Link
            className="text-white"
            to="#"
            onClick={() =>
              this.handleChangLanguage(localStorage.getItem("locale"), "id")
            }
          >
            <div className="d-flex justify-content-between">
              <div>
                <img
                  className="mr-0 mb-1 rounded shadow"
                  style={flagStyle}
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/indonesian.svg"
                  }
                  alt=""
                />
              </div>
              <div>Indonesia</div>
            </div>
          </Link>
        </Menu.Item>
        <Menu.Item key="China">
          <Link
            className="text-white"
            to="#"
            onClick={() =>
              this.handleChangLanguage(localStorage.getItem("locale"), "zh")
            }
          >
            <div className="d-flex justify-content-between">
              <div>
                <img
                  className="mr-0 mb-1 rounded shadow"
                  style={flagStyle}
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icons/china.svg"
                  }
                  alt=""
                />
              </div>
              <div>China</div>
            </div>
          </Link>
        </Menu.Item>
        <Menu.Item key="Vietnam">
          <Link
            className="text-white"
            to="#"
            onClick={() =>
              this.handleChangLanguage(localStorage.getItem("locale"), "vn")
            }
          >
            <div className="d-flex justify-content-between">
              <div>
                <img
                  className="mr-0 mb-1 rounded shadow"
                  style={flagStyle}
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icons/vietnam.svg"
                  }
                  alt=""
                />
              </div>
              <div>Vietnam</div>
            </div>
          </Link>
        </Menu.Item>
        <Menu.Item key="Korea">
          <Link
            className="text-white"
            to="#"
            onClick={() =>
              this.handleChangLanguage(localStorage.getItem("locale"), "ko")
            }
          >
            <div className="d-flex justify-content-between">
              <div>
                <img
                  className="mr-0 mb-1 rounded shadow"
                  style={flagStyle}
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icons/korea.svg"
                  }
                  alt=""
                />
              </div>
              <div>Korea</div>
            </div>
          </Link>
        </Menu.Item>
        <Menu.Item key="Philippines">
          <Link
            className="text-white"
            to="#"
            onClick={() =>
              this.handleChangLanguage(localStorage.getItem("locale"), "ph")
            }
          >
            <div className="d-flex justify-content-between">
              <div>
                <img
                  className="mr-0 mb-1 rounded shadow"
                  style={flagStyle}
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/philippines.svg"
                  }
                  alt=""
                />
              </div>
              <div>Philippines</div>
            </div>
          </Link>
        </Menu.Item>
        <Menu.Item key="Philippines">
          <Link
            className="text-white"
            to="#"
            onClick={() =>
              this.handleChangLanguage(localStorage.getItem("locale"), "lo")
            }
          >
            <div className="d-flex justify-content-between">
              <div>
                <img
                  className="mr-0 mb-1 rounded shadow"
                  style={flagStyle}
                  src={process.env.PUBLIC_URL + "/assets/images/icons/laos.svg"}
                  alt=""
                />
              </div>
              <div>Laos</div>
            </div>
          </Link>
        </Menu.Item>
        <Menu.Item key="th">
          <Link
            className="text-white"
            to="#"
            onClick={() =>
              this.handleChangLanguage(localStorage.getItem("locale"), "th")
            }
          >
            <div className="d-flex justify-content-between">
              <div>
                <img
                  className="mr-0 mb-1 rounded shadow"
                  style={flagStyle}
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icons/thailand.svg"
                  }
                  alt=""
                />
              </div>
              <div>ภาษาไทย</div>
            </div>
          </Link>
        </Menu.Item>
      </Menu>
    );
    return (
      <Menu mode="horizontal" className="d-flex align-items-center">
        {/* <Menu.Item
          key="market"
          onClick={this.onCloseMenuItem}
          onMouseLeave={() => this.setState({ open: false })}
        >
          <div>
            <div onMouseEnter={() => this.setState({ open: "market" })}>
              <Link to="#" className="text-white-hover ant-dropdown-link">
                {locale.t("market")} <DownOutlined />
              </Link>
            </div>
            <MenuDropdownCustom
              onMouseLeave={() => this.setState({ open: false })}
              style={{
                display: this.state.open === "market" ? "block" : "none",
              }}
            >
              <div>
                {marketlist.map((market, index) => {
                  return (
                    <div
                      className="menu-hover-item d-flex w-100 text-left"
                      key={index}
                    >
                      <Link
                        className="text-white-hover w-100"
                        style={{ padding: "0px 12px" }}
                        to={market.link}
                        onClick={this.onClickClose}
                      >
                        {market.name}
                      </Link>
                    </div>
                  );
                })}
                <div className="menu-hover-item d-flex" key="partnership">
                  <Link
                    to={`/${localStorage.getItem("locale")}/instrument`}
                    className="text-white-hover"
                    style={{ padding: "0px 12px" }}
                  >
                    Instrument
                  </Link>
                </div>{" "}
              </div>
            </MenuDropdownCustom>
          </div>
        </Menu.Item> */}
        {/* <Menu.Item
          key="account"
          onClick={this.onCloseMenuItem}
          onMouseLeave={() => this.setState({ open: false })}
        >
          <div>
            <div onMouseEnter={() => this.setState({ open: "account" })}>
              <Link to="#" className="text-white-hover ant-dropdown-link">
                {locale.t("account")} <DownOutlined />
              </Link>
            </div>
            <MenuDropdownCustom
              onMouseLeave={() => this.setState({ open: false })}
              style={{
                display: this.state.open === "account" ? "block" : "none",
              }}
            >
              <div>
                <Link
                  className="menu-hover-item d-flex  text-white-hover"
                  key="accounts"
                  to={`/${localStorage.getItem("locale")}/accounts`}
                  style={{ padding: "0px 12px" }}
                >
                  {locale.t("cover.accover.title")}
                </Link>
                {this.props.account.accountTypeList
                  ?.filter((e) => e.active === 1 && e.type === "real")
                  ?.map((e, i) => (
                    <Link
                      className="menu-hover-item d-flex   text-white-hover"
                      key={i}
                      to={`/${localStorage.getItem("locale")}/accounts/${
                        e.display
                      }`}
                      style={{ padding: "0px 12px" }}
                    >
                      {e.display}
                    </Link>
                  ))}

                <Link
                  className="menu-hover-item d-flex  text-white-hover"
                  key="funding"
                  to={`/${localStorage.getItem("locale")}/accounts/funding`}
                  style={{ padding: "0px 12px" }}
                >
                  {locale.t("cover.deposit.title")}
                </Link>
                <Link
                  className="menu-hover-item d-flex   text-white-hover"
                  key="withdrawal"
                  to={`/${localStorage.getItem("locale")}/accounts/withdrawal`}
                  style={{ padding: "0px 12px" }}
                >
                  {locale.t("cover.withdraw.title")}
                </Link>
                <div className="menu-hover-item d-flex" key="partnership">
                  <Link
                    to={`/${localStorage.getItem(
                      "locale"
                    )}/swap/non-islamic/Standard`}
                    className="text-white-hover"
                    style={{ padding: "0px 12px" }}
                  >
                    Spread & Swap
                  </Link>
                </div>
              </div>
            </MenuDropdownCustom>
          </div>
        </Menu.Item> */}
        {/* <Menu.Item
          key="tools"
          onClick={this.onCloseMenuItem}
          onMouseLeave={() => this.setState({ open: false })}
        >
          <div>
            <div onMouseEnter={() => this.setState({ open: "tools" })}>
              <Link to="#" className="text-white-hover ant-dropdown-link">
                {locale.t("tools")} <DownOutlined />
              </Link>
            </div>
            <MenuDropdownCustom
              onMouseLeave={() => this.setState({ open: false })}
              style={{
                display: this.state.open === "tools" ? "block" : "none",
              }}
            >
              <div className="">
                <div className="menu-hover-item d-flex" key="startbonus">
                  <Link
                    to={`/${localStorage.getItem(
                      "locale"
                    )}/spread/marketresearch`}
                    className="text-white-hover"
                    style={{ padding: "0px 12px" }}
                  >
                    {locale.t("analyze")}
                  </Link>
                </div>
               
                <div className="menu-hover-item d-flex" key="platforms-mt5">
                  <Link
                    to={`/${localStorage.getItem("locale")}/platforms/mt5`}
                    className="text-white-hover"
                    style={{ padding: "0px 12px" }}
                  >
                    {locale.t("MetaTrader 5")}
                  </Link>
                </div>
                <div className="menu-hover-item d-flex" key="platforms-web">
                  <Link
                    to={`/${localStorage.getItem(
                      "locale"
                    )}/webterminal/version5?sever=live`}
                    className="text-white-hover"
                    style={{ padding: "0px 12px" }}
                  >
                    {locale.t("WebTrader 5")}
                  </Link>
                </div>
              </div>
              <div className="">
                <div className="menu-hover-item d-flex" key="calculators">
                  <Link
                    to={`/${localStorage.getItem("locale")}/help/calculators`}
                    className="text-white-hover"
                    style={{ padding: "0px 12px" }}
                  >
                    {locale.t("fxcal_page_title")}
                  </Link>
                </div>
              </div>
             
              <div className="">
                <div className="menu-hover-item d-flex" key="payment-option">
                  <Link
                    to={`/${localStorage.getItem("locale")}/payment-option`}
                    className="text-white-hover"
                    style={{ padding: "0px 12px" }}
                  >
                    {locale.t("payment_option")}
                  </Link>
                </div>
              </div>
            </MenuDropdownCustom>
          </div>
        </Menu.Item> */}
        {/* <Menu.Item
          key="platform"
          onClick={this.onCloseMenuItem}
          onMouseLeave={() => this.setState({ open: false })}
        >
          <div>
            <div onMouseEnter={() => this.setState({ open: "platform" })}>
              <Link to="#" className="text-white ant-dropdown-link">
                {locale.t("platforms")} <DownOutlined />
              </Link>
            </div>
            <MenuDropdownCustom
              onMouseLeave={() => this.setState({ open: false })}
              style={{
                display: this.state.open === "platform" ? "block" : "none",
              }}
            >
              
            </MenuDropdownCustom>
          </div>
        </Menu.Item> */}
        {/* <Menu.Item
          key="spread"
          onClick={this.onCloseMenuItem}
          onMouseLeave={() => this.setState({ open: false })}
        >
          <div>
            <div onMouseEnter={() => this.setState({ open: "spread" })}>
              <Link
                to={`/${localStorage.getItem("locale")}/spread`}
                className="text-white-hover ant-dropdown-link"
              >
                {locale.t("spread")}&nbsp;
                <DownOutlined />
              </Link>
            </div>
            <MenuDropdownCustom
              onMouseLeave={() => this.setState({ open: false })}
              style={{
                display: this.state.open === "spread" ? "block" : "none",
              }}
            >
              <div>
                <div className="menu-hover-item d-flex" key="partnership">
                  <Link
                    to={`/${localStorage.getItem("locale")}/spread`}
                    className="text-white-hover"
                    style={{ padding: "0px 12px" }}
                  >
                    {locale.t("symbol")}
                  </Link>
                </div>{" "}
                <div className="menu-hover-item d-flex" key="partnership">
                  <Link
                    to={`/${localStorage.getItem(
                      "locale"
                    )}/swap/non-islamic/Standard`}
                    className="text-white-hover"
                    style={{ padding: "0px 12px" }}
                  >
                    Spread & Swap
                  </Link>
                </div>
                <div className="menu-hover-item d-flex" key="startbonus">
                  <Link
                    to={`/${localStorage.getItem(
                      "locale"
                    )}/spread/marketresearch`}
                    className="text-white-hover"
                    style={{ padding: "0px 12px" }}
                  >
                    {locale.t("analyze")}
                  </Link>
                </div>
              </div>
            </MenuDropdownCustom>
          </div>
        </Menu.Item> */}
        {/* <Menu.Item
          key="company"
          onClick={this.onCloseMenuItem}
          onMouseLeave={() => this.setState({ open: false })}
        >
          <div>
            <div onMouseEnter={() => this.setState({ open: "company" })}>
              <Link to="#" className="text-white-hover ant-dropdown-link">
                {locale.t("company")} <DownOutlined />
              </Link>
            </div>
            <MenuDropdownCustom
              onMouseLeave={() => this.setState({ open: false })}
              style={{
                display: this.state.open === "company" ? "block" : "none",
              }}
            >
              <div>
                <div className="menu-hover-item d-flex" key="partnership">
                  <a
                    href={`https://partner.iuxmarkets.com`}
                    className="text-white-hover"
                    style={{ padding: "0px 12px" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {locale.t("partnership")}
                  </a>
                </div>
                <div className="menu-hover-item d-flex" key="startbonus">
                  <Link
                    to={`/${localStorage.getItem("locale")}/promotion`}
                    className="text-white-hover"
                    style={{ padding: "0px 12px" }}
                  >
                    {locale.t("startbonus")}
                  </Link>
                </div>
                <div className="menu-hover-item d-flex" key="lotback">
                  <Link
                    to={`/${localStorage.getItem("locale")}/lotback#`}
                    className="text-white-hover"
                    style={{ padding: "0px 12px" }}
                  >
                    {locale.t("loyaltybonus")}
                  </Link>
                </div>
                <div className="menu-hover-item d-flex" key="about">
                  <a
                    href={`https://help.iuxmarkets.com/${localStorage.getItem(
                      "locale"
                    )}/`}
                    className="text-white-hover"
                    style={{ padding: "0px 12px" }}
                  >
                    {locale.t("help_cover_title")}
                  </a>
                </div>
                <div className="menu-hover-item d-flex" key="about">
                  <Link
                    to={`/${localStorage.getItem("locale")}/about-us`}
                    className="text-white-hover"
                    style={{ padding: "0px 12px" }}
                  >
                    {locale.t("about")}
                  </Link>
                </div>
                <div className="menu-hover-item d-flex" key="about">
                  <a
                    href={`https://policy.iuxmarkets.com/${localStorage.getItem(
                      "locale"
                    )}`}
                    className="text-white-hover"
                    target="_blank"
                    style={{ padding: "0px 12px" }}
                    rel="noreferrer"
                  >
                    Privacy & Terms
                  </a>
                </div>
              </div>
            </MenuDropdownCustom>
          </div>
        </Menu.Item> */}
        <Menu.Item
          key="language"
          onClick={this.onCloseMenuItem}
          onMouseLeave={() => this.setState({ open: false })}
        >
          <div>
            <div className="d-flex align-items-center text-white">
              <div className="mr-1 d-flex">
                {/* <img
                  src={"/assets/images/icons/Icon-globe_24px.svg"}
                  alt="globe_24px"
                /> */}
                <span
                  className={`fi fi-${
                    localStorage.getItem("locale") === "en"
                      ? "gb"
                      : localStorage.getItem("locale")
                  }`}
                />
              </div>
              <div className="mr-1" style={{ textTransform: "uppercase" }}>
                {localStorage.getItem("locale")}
              </div>
              <DownOutlined />
            </div>
            <MenuDropdownCustom
              onMouseLeave={() => this.setState({ open: false })}
              style={{
                display: this.state.open === "language" ? "block" : "none",
                width: 130,
              }}
            >
              {langs.map((i, x) => (
                <div
                  key={x}
                  className="d-flex menu-hover-item justify-content-between align-items-center pl-2 pr-2"
                  onClick={() => this.handleChangLanguageDesk(i.lang)}
                >
                  <div>
                    {/* <img
                      style={flagStyle}
                      src={process.env.PUBLIC_URL + i.img}
                      alt=""
                    /> */}
                    <span className={`fi fi-${i?.img}`} />
                  </div>
                  <div className="text-white">{i.name}</div>
                </div>
              ))}
            </MenuDropdownCustom>
          </div>
        </Menu.Item>
        {/* <Menu.Item onClick={this.onCloseMenuItem}>
          {" "}
          <Link
            className="p-0"
            to={`/${localStorage.getItem("locale")}/register`}
          >
            <ButtonSignUp>{locale.t("register")}</ButtonSignUp>{" "}
          </Link>
        </Menu.Item>
        <Menu.Item onClick={this.onCloseMenuItem}>
          {" "}
          <Link
            // className="text-color-iux "
            to={`/${localStorage.getItem("locale")}/login`}
          >
            <ButtonSignIn>{locale.t("signin")}</ButtonSignIn>{" "}
          </Link>
        </Menu.Item> */}
      </Menu>
    );
  };

  render() {
    return this.renderUserOrOther(this.props);
  }
}

const mapStateToProps = ({ i18n, user, account }) => ({
  locale: i18n.locale,
  user,
  account,
});

export default connect(mapStateToProps, { setLocale, setLocation })(
  withRouter(RightMenu)
);
