import {
  AppleOutlined,
  CloseOutlined,
  WindowsOutlined,
  AndroidOutlined,
} from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const locale = require("react-redux-i18n").I18n;

const CheckBoxButton = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  border: 2px solid ${(props) => (props.active ? "#1cb894" : "#fff")};
  cursor: pointer;
`;

const Inside = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? "#1cb894" : "#fff")};
  margin: auto;
`;

const CloseBtn = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  color: #fff;
`;

const DialogPromote = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [checkBox, setCheckBox] = useState(false);

  const dialog = localStorage.getItem("promo");
  let now = new Date().valueOf();

  useEffect(() => {
    if (dialog !== "1") {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [dialog]);

  const handleCheckBox = () => {
    setCheckBox(!checkBox);
  };

  const onClose = () => {
    localStorage.setItem("promo", 1);
    setIsModalVisible(false);
    localStorage.setItem("_exp_promo", now + 24 * 60 * 60 * 1000);
  };

  useEffect(() => {
    let check = localStorage.getItem("_exp_promo");
    if (+check - now < 0) {
      localStorage.setItem("promo", null);
    }
  }, []);

  const detectAgent = (text) => {
    let a = text;
    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
    var isIphone = ua.indexOf("ios") > -1; //&& ua.indexOf("mobile");
    if (isAndroid) {
      a =
        "https://download.mql5.com/cdn/mobile/mt5/android?server=IUXMarkets-Demo,IUXMarkets-Live,IUXMarkets-Live2";
    } else if (isIphone) {
      a =
        "https://download.mql5.com/cdn/mobile/mt5/ios?server=IUXMarkets-Demo,IUXMarkets-Live,IUXMarkets-Live2";
    } else {
      a =
        "https://download.mql5.com/cdn/web/iux.markets.limited/mt5/iuxmarkets5setup.exe";
    }
    return a;
  };

  return (
    <Modal
      title={false}
      footer={false}
      visible={isModalVisible}
      bodyStyle={{ padding: 0 }}
      width="80%"
      
      // onOk={handleOk}
      // onCancel={handleCancel}
    >
      <div
        className="h-100"
        style={{
          backgroundImage:
            "radial-gradient(circle, rgb(38 72 93), rgb(14 28 35 / 98%), rgb(25 43 51), rgb(23 36 41), rgb(9 14 15))",
        }}
      >
        <div
          style={{
            background:
              "url('" +
              "assets/images/covers/img-bg-promote.png" +
              "') center no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              backgroundImage:
                "radial-gradient(circle, rgb(29 57 74 / 2%), rgba(26, 50, 62, 0.85), rgb(19 35 42 / 89%), rgba(23, 36, 41, 0.93), rgba(22, 29, 31, 0.98))",
            }}
          >
            <div className="position-relative">
              <CloseBtn onClick={onClose}>
                <CloseOutlined />
              </CloseBtn>
            </div>
            <div className="p-70-sm-20">
              <div className="d-flex flex-wrap align-items-center">
                <div className="d-flex flex-column width-lg-50-sm-100">
                  <div className="d-flex align-items-center">
                    <img src="/assets/images/logo-new-iux.png" alt="..." />
                    <div className="ml-2 text-white f-15px">
                      {locale.t("promo.header")}
                    </div>
                  </div>
                  <div className="font-bold-iux text-white f-42 mt-3 text-center text-md-left">
                    {locale.t("promo.title_1")}
                  </div>
                  <div className="font-bold-iux text-white f-32 text-center text-md-left">
                    {locale.t("promo.title_2")}
                  </div>
                  <div className="text-white f-20 text-center text-md-left">
                    {locale.t("promo.title_3")}
                  </div>
                  <div className="text-white mt-md-1 mt-lg-1 mt-xl-3 text-center text-md-left">
                    {locale.t("promo.description_1")}
                  </div>
                  <div className="text-white text-center text-md-left">
                    {locale.t("promo.description_2")}
                  </div>
                  <div className="d-flex flex-wrap justify-content-center justify-content-lg-start mt-3">
                    <div className="mr-0 mr-lg-3 mt-3 mt-lg-0 w-sm-100">
                      <a
                        download
                        href="https://download.mql5.com/cdn/mobile/mt5/ios?server=IUXMarkets-Demo,IUXMarkets-Live,IUXMarkets-Live2"
                      >
                        <Button
                          className="btn-success-outline w-btn-success-custom"
                          key="back"
                        >
                          <div className="align-items-center d-flex justify-center justify-content-md-start w-100">
                            <WindowsOutlined />
                            &nbsp;&nbsp;Windows
                          </div>
                        </Button>
                      </a>
                    </div>
                    <div className="mr-0 mr-lg-3 mt-3 mt-lg-0 w-sm-100">
                      <a
                        download
                        href="https://download.mql5.com/cdn/mobile/mt5/ios?server=IUXMarkets-Demo,IUXMarkets-Live,IUXMarkets-Live2"
                        target={"_blank"}
                      >
                        <Button
                          className="btn-success-outline w-btn-success-custom d-flex align-items-center"
                          key="back"
                        >
                          <div className="align-items-center d-flex justify-center justify-content-md-start w-100">
                            <AppleOutlined />
                            &nbsp;&nbsp;IOS
                          </div>
                        </Button>
                      </a>
                    </div>
                    <div className="w-sm-100 mt-3 mt-lg-0">
                      <a
                        download
                        href="https://download.mql5.com/cdn/mobile/mt5/android?server=IUXMarkets-Demo,IUXMarkets-Live,IUXMarkets-Live2"
                        target={"_blank"}
                      >
                        <Button
                          className="btn-success-outline w-btn-success-custom"
                          key="back"
                        >
                          <div className="align-items-center d-flex justify-center justify-content-md-start w-100">
                            <AndroidOutlined />
                            &nbsp;&nbsp;Android
                          </div>
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <img
                      src="/assets/images/covers/mt5-landing-page.png"
                      className="w-responsive-img-mt-5-promo"
                      height="auto"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="w-100 d-flex justify-center mt-4">
                  <a download href={detectAgent()}>
                    <Button
                      className="btn-success-iux-custom w-btn-success-custom d-flex align-items-center"
                      key="back"
                      style={{ fontSize: 24 }}
                      onClick={onClose}
                    >
                      <span className="w-100 text-center">
                        {locale.t("promo.button")}
                      </span>
                    </Button>
                  </a>
                </div>
                <div className="mt-3 d-flex justify-center align-items-center">
                  <div>
                    <CheckBoxButton active={checkBox} onClick={handleCheckBox}>
                      {checkBox && <Inside active={checkBox} />}
                    </CheckBoxButton>
                  </div>
                  <div className="text-white ml-2 f-12">
                    {locale.t("promo.label")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DialogPromote;
