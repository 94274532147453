import React, { useState, Fragment, useEffect } from "react";
import {
  Divider,
  Button,
  Form,
  Input,
  Row,
  Spin,
  Result,
  Select,
  Space,
  Radio,
  Collapse,
  List,
} from "antd";
import { post } from "../../../../helper/request";
import { connect, useSelector } from "react-redux";
// import DepositFirstStep from "./deposit/DepositFirstStep";
// import DepositSecondStep from "./deposit/DepositSecondStep";
// import DepositThirdStep from "./deposit/DepositThirdStep";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { CheckCerrency } from "../../../../components/checkcurrency";
const locale = require("react-redux-i18n").I18n;
const { Option } = Select;

const ResultAmountStyled = styled.div`
  border: unser;
  border-radius: 12px;
  display: inline-block;
  width: 100%;
  background: #f2f2f2;
  width: 100%;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const DepositFirstStep = (props) => {
  // console.log(props, "props");
  // const {
  //   paymentId,
  //   paymentKey,
  //   account: { account_number },
  //   payment_config: { minimum_deposit, maximum_deposit },
  //   payment_name,
  // } = props;
  const profileCountry = useSelector((state) => state.user.profileCountry);
  const [spinnig, setSpinnig] = useState(true);
  const [bankAccount, setBankAccount] = useState("");
  const [showpaymentall, setShowpaymentall] = useState(false);
  const payment = useSelector(({ user }) => user.bank);
  // const [amount, setAmount] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [account, setAccount] = useState(props.account);
  const [paymentId, setPaymentId] = useState(2);
  const [paymentKey, setPaymentKey] = useState("thai_qr_code");
  const [payment_config, setPayment_config] = useState({
    minimum_deposit: 100,
    maximum_deposit: 1000000,
    minimum_withdraw: 500,
    maximum_withdraw: 1000000,
  });
  let { minimum_deposit, maximum_deposit } = payment_config;

  //       modalSuccess: false,
  //       spinning: true,
  //       step: 0,
  //       bank: undefined,
  //       bankAccountNumber: "",
  //       amount: 0,
  //       showSuccess: false,
  //       account: this.props.account || undefined,
  //       payment_id: 1,
  //       payment_key: "",
  //       payment_config: {},
  //       payment_name: "",

  // const [accountSelected, setAccountSelected] = useState(null);

  const [countryId, setCountryId] = useState(null);
  const [rate, setRate] = useState(0);
  const [step, setStep] = useState(0);
  const [defaultpayment, setDefaultpayment] = useState(2);
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    props.onChangePayment(e.target.value);
  };
  useEffect(() => {
    setLoading(true);
    setCountryId(props.user.profile.country_id);
  }, []);
  const addDefaultSrc = (ev) => {
    ev.target.src = "/assets/images/icons/globe-black.svg";
  };
  return (
    <Fragment>
      <span className="fs-18 fw-700 ">
        1.{locale.t("deposit_step.first_step.description")}
      </span>
      <div style={{ marginTop: 20 }} />

      <Form
        name="deposit_amount"
        onFinish={props.onNextStep}
        className="user mx-auto"
      >
        <div>
          <Form.Item
            style={{ borderTop: "0", fontSize: 12 }}
            name="account"
            label={locale.t("dashboardWallet.account")}
            disabled={
              props?.user?.accounts?.filter((e) => e.type === "real").length ===
              0
            }
          >
            <Select
              size="large"
              placeholder={locale.t("deposit_step.first_step.description")}
              // value={101335}
              defaultValue={
                props.accountSelected ? props.accountSelected.id : undefined
              }
              onChange={props.onAccountSelected}
            >
              {props?.user?.accounts?.filter((e) => e.type === "real").length >
                0 &&
                props.user?.accounts
                  ?.filter((e) => e.type === "real")
                  ?.map((e, i) => (
                    <Option value={e.id} key={e.id}>
                      <div className="d-flex justify-content-between">
                        <div>
                          {e.platform}&nbsp;
                          {e.account_number}
                          &nbsp; <b>({e?.account_group?.display})</b>
                        </div>
                        <div>
                          {e.account_type === "Cent" ? "¢" : "$"}
                          {e.current_balance
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </div>
                      </div>
                    </Option>
                  ))}
            </Select>
          </Form.Item>
        </div>

        {props.accountSelected && (
          <>
            <div className="mt-3" />
            <span className="w-12 text-gray">
              {locale.t("deposit_step.first_step.deposit_with")}
            </span>
            {payment.length > 0 ? (
              <>
                {" "}
                <div
                  style={{
                    padding: "10px 30px",
                    backgroundColor: "#F2F2F2",
                    borderRadius: 8,
                    width: "fit-content",
                    marginBottom: 20,
                    marginTop: 16,
                    fontWeight: 700,
                  }}
                  className="fw-700"
                >
                  {locale.t("dashboardWallet.recommended")}
                </div>
                <Radio.Group onChange={onChange} value={props.paymentKey}>
                  <Space direction="vertical">
                    {/* {renderDefault(props.user.profile.country_id, payment)} */}
                    {payment?.filter((e) => e.recommend === 1)?.length > 0 ? (
                      payment
                        ?.filter((e) => e.recommend === 1)
                        ?.map((e, i) => (
                          <Radio
                            key={i}
                            value={e.payment_key}
                            className="d-flex align-items-center"
                          >
                            <div className="d-flex w-100 align-items-center">
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/bank/${e.logo}`}
                                alt="...."
                                width={40}
                                height={40}
                                style={{ borderRadius: 4 }}
                              />
                              <div className="ml-2">
                                <List.Item className="p-0">
                                  <List.Item.Meta
                                    title={
                                      <div className="text-left">{e.name}</div>
                                    }
                                    description={`(${locale.t(
                                      "deposit_step.second_step.minimun_deposit"
                                    )} ${e?.payment_config[0]?.minimum_deposit
                                      ?.toFixed(2)
                                      ?.replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${
                                      profileCountry?.symbol
                                    })`}
                                  />
                                </List.Item>
                              </div>
                            </div>
                          </Radio>
                        ))
                    ) : (
                      <>
                        {payment?.length > 0 && (
                          <Radio
                            value={payment[0]?.payment_key}
                            className="d-flex align-items-center"
                          >
                            <div className="d-flex w-100 align-items-center">
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/bank/${payment[0]?.logo}`}
                                alt="...."
                                width={40}
                                style={{ borderRadius: 4 }}
                              />
                              <div className="ml-2">
                                <List.Item className="p-0">
                                  <List.Item.Meta
                                    className="text-left"
                                    title={
                                      <div className="text-left">
                                        {payment[0]?.name}
                                      </div>
                                    }
                                    description={`(${locale.t(
                                      "deposit_step.second_step.minimun_deposit"
                                    )} ${payment[0]?.payment_config[0]?.minimum_deposit
                                      ?.toFixed(2)
                                      ?.replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${
                                      profileCountry?.symbol
                                    })`}
                                  />
                                </List.Item>
                              </div>
                            </div>
                          </Radio>
                        )}
                      </>
                    )}
                    <div
                      className="cursor-pointer fs-12 text-gray my-3"
                      onClick={() => setShowpaymentall(!showpaymentall)}
                    >
                      {locale.t("deposit_step.first_step.show_all_method")}
                    </div>
                    {showpaymentall &&
                      payment &&
                      payment
                        ?.filter((e) => e.recommend === 0)
                        ?.map((e, i) => (
                          <Radio
                            value={e.payment_key}
                            className="d-flex align-items-center"
                          >
                            <div className="d-flex w-100 align-items-center">
                              <img
                                onError={addDefaultSrc}
                                src={`${process.env.PUBLIC_URL}/assets/images/bank/${e.logo}`}
                                alt="...."
                                width={40}
                                height={40}
                                style={{ borderRadius: 4 }}
                              />
                              <div className="ml-2">
                                <List.Item className="p-0">
                                  <List.Item.Meta
                                    title={
                                      <div className="text-left">{e.name}</div>
                                    }
                                    description={`(${locale.t(
                                      "deposit_step.second_step.minimun_deposit"
                                    )} ${e?.payment_config[0]?.minimum_deposit
                                      ?.toFixed(2)
                                      ?.replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${
                                      profileCountry?.symbol
                                    })`}
                                  />
                                </List.Item>
                              </div>
                            </div>
                          </Radio>
                        ))}
                  </Space>
                </Radio.Group>
              </>
            ) : (
              <div className="text-center py-5 fs-14 text-lightgray">
                {locale.t("withdraw_step.no_payment")}
                {/* Apologies, but the payment is temporarily unavailable. */}
              </div>
            )}
          </>
        )}
        <div
          className="mt-3"
          style={{
            display: "flex",
            width: "100%",

            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            className="btn-primary-new-iux w-100"
            onClick={props.onNextStep}
            disabled={!props.accountSelected || props.paymentKey === null}
          >
            {locale.t("next")}
          </Button>
        </div>
      </Form>
    </Fragment>
  );
};

const mapStateToProps = ({ user, i18n }) => {
  const { accounts, profile, account } = user;
  return { accounts, profile, account, locale: i18n.locale, user };
};

export default connect(mapStateToProps, null)(DepositFirstStep);
