import { Button, Form, message, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { partnerMove } from "../../api/partner";
import { Link } from "react-router-dom";
import moment from "moment";

const locale = require("react-redux-i18n").I18n;

const Tab = styled.div`
  background-color: #3e3e3e;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const { Option } = Select;

const HolidayNoti = ({ dataholiday = {} }) => {
  return (
    <div>
      <Tab>
        <div className="mr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <g
              id="Icon_feather-alert-circle"
              data-name="Icon feather-alert-circle"
              transform="translate(0.009)"
            >
              <path
                id="Path_24054"
                data-name="Path 24054"
                d="M0,0H16V16H0Z"
                transform="translate(-0.009)"
                fill="none"
              />
              <g id="Group_7293" data-name="Group 7293">
                <path
                  id="Path_24051"
                  data-name="Path 24051"
                  d="M14.667,8A6.667,6.667,0,1,1,8,1.333,6.667,6.667,0,0,1,14.667,8Z"
                  fill="none"
                  stroke="#f19e03"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.333"
                />
                <path
                  id="Path_24052"
                  data-name="Path 24052"
                  d="M8,5.333V8"
                  fill="none"
                  stroke="#f19e03"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.333"
                />
                <path
                  id="Path_24053"
                  data-name="Path 24053"
                  d="M8,10.667H8"
                  fill="none"
                  stroke="#f19e03"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.333"
                />
              </g>
            </g>
          </svg>
        </div>
        <div className="text-white">
          <span>Holiday Calendar</span>
          <span>
            &nbsp;- {moment(dataholiday.effect_date).format("DD MMMM YYYY")}
            -&nbsp;
          </span>
          <span>{dataholiday.topic}</span>
          {/* <button
            style={{
              color: "#F19E03",
              outline: "none",
              border: "none",
              background: "transparent",
            }}
            className="ml-2 mr-2"
          >
            information
          </button> */}
          <Link to={`/${localStorage.getItem("locale")}"/wallet/economic`}>
            <button
              className="ml-2"
              style={{
                color: "#f19e03",
                outline: "none",
                border: "none",
                background: "transparent",
                // color: "#fff",
                // outline: "none",
                // border: "none",
                // background: "rgb(17, 173, 117)",
                // borderRadius: "6px",
              }}
            >
              See more
            </button>
          </Link>
        </div>
      </Tab>
    </div>
  );
};

export default HolidayNoti;
