import React from "react";
import { StyledContent } from "../../../components";
import { Empty, Table } from "antd";
import moment from "moment";

const TransferHistory = (props) => {
  const columns = [
    {
      title: props.locale.t("dashboardInnerTransfer.accountNumberTransfer"),
      dataIndex: "from_account_trade",
      key: "1",
      width: "15%",
      render: (account) => {
        return account.account_number;
      },
    },
    {
      title: props.locale.t("dashboardInnerTransfer.accountNumberIncome"),
      dataIndex: "to_account_trade",
      key: "2",
      width: "15%",
      render: (account) => {
        return account.account_number;
      },
    },
    {
      title: props.locale.t("dashboardInnerTransfer.amount"),
      dataIndex: "balance_amount",
      key: "3",
      width: "15%",
      render: (amount, account) => {
        return `${
          account.from_account_trade.account_type === "Cent"
            ? amount * 100
            : amount
        } ${
          account.from_account_trade.account_type === "Cent" ? "USC" : "USD"
        }`;
      },
    },
    {
      title: props.locale.t("dashboardInnerTransfer.date"),
      dataIndex: "createdAt",
      key: "4",
      width: "15%",
      render: (date) => {
        return (
          <span>
            <p key={date}>{moment(date).format("YYYY-MM-DD")}</p>
          </span>
        );
      },
    },
  ];

  const data = props.history;

  return (
    <div>
      <h5 className="mb-4 fw-700">
        {props.locale.t("dashboardInnerTransfer.historyTitle")}
      </h5>
      <Table
        locale={{
          emptyText: (
            <Empty
              description={props.locale.t("nodata")}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ),
        }}
        key="history"
        columns={columns}
        dataSource={data}
        style={{ fontSize: "12px" }}
        scroll={{ y: 500 }}
        pagination={false}
      />
    </div>
  );
};

export default TransferHistory;
