import { Button, Checkbox, Modal, message } from "antd";
import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

const locale = require("react-redux-i18n").I18n;

function ModalSwap(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const profileCountry = useSelector((state) => state.user.profileCountry);
  const { isOpen, onClose, isFree } = props;
  const [isChecked, setIsChecked] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  //eieiei
  return (
    <Modal
      className="w-modal-swap width-modal-swap user user-change"
      closable={true}
      visible={isOpen}
      footer={false}
      onCancel={() => {
        onClose();
      }}
    >
      <div className="px-3">
        <div className="d-flex w-100 fs-14 fw-700 align-items-center mt-5">
          {locale.t("modal_swap_title")} : &nbsp;
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="11"
            viewBox="0 0 10 11"
            fill="none"
          >
            <circle
              cx="5"
              cy="5.5"
              r="5"
              fill={isFree ? "#E3673F" : "#1CB894"}
            />
            <circle cx="5" cy="5.5" r="2.5" fill="white" />
          </svg>
          &nbsp;{isFree ? "Non-Free" : "Free"}
        </div>
        <div className="d-flex justify-content-center text-lightgray-iux  align-items-center flex-column w-100 fs-14 mt-3">
          {isFree
            ? locale.t("modal_swap_des_non")
            : locale.t("modal_swap_des_free")}
        </div>
        <div className="mt-3 fs-12 text-lightgray mb-5">
          <div>{locale.t("modal_note")} :</div>
          <ul style={{ paddingInlineStart: "20px" }}>
            <li>{locale.t("modal_note_des_1")}</li>
            <li>{locale.t("modal_note_des_2")}</li>
          </ul>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn-primary-new-iux"
          onClick={() =>
            window.location.assign(
              `/${localStorage.getItem("locale")}/swap/${
                profileCountry?.isalamic === 0 ? "non-islamic" : "islamic"
              }/Standard`
            )
          }
        >
          {`Swap rates >`}
        </button>
      </div>
    </Modal>
  );
}

export default ModalSwap;
