import React, { useState, Fragment, useEffect } from "react";
import {
  Divider,
  Button,
  Form,
  Input,
  Row,
  Spin,
  Result,
  Select,
  Space,
  Radio,
  Collapse,
  List,
  message,
} from "antd";
import { post, get } from "../../../../helper/request";
import { connect, useSelector } from "react-redux";
// import OtpInput from "../../../../components/otpinput/OtpInput";
// import DepositFirstStep from "./deposit/DepositFirstStep";
// import DepositSecondStep from "./deposit/DepositSecondStep";
// import DepositThirdStep from "./deposit/DepositThirdStep";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { CheckCerrency } from "../../../../components/checkcurrency";
const locale = require("react-redux-i18n").I18n;
const { Option } = Select;

export const WithDrawFirstStep = (props) => {
  const profileCountry = useSelector((state) => state.user.profileCountry);
  const [showpaymentall, setShowpaymentall] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [rate, setRate] = useState(0);
  // const [otp, setOtp] = useState("");
  // const onChange = (value) => setOtp(value);

  return (
    <Fragment>
      <span className="fs-18 fw-700 ">
        1.{locale.t("deposit_step.first_step.description")}
      </span>
      <div style={{ marginTop: 20 }} />
      {/* <OtpInput value={otp} valueLength={6} onChange={onChange} /> */}
      <Form
        name="deposit_amount"
        onFinish={props.onNextStep}
        className="user mx-auto"
      >
        <div>
          <Form.Item
            style={{ borderTop: "0", fontSize: 12 }}
            name="account"
            label={locale.t("dashboardWallet.account")}
            disabled={
              props.user.accounts.filter((e) => e.type === "real").length === 0
            }
          >
            {props?.accountWelcome ? (
              <Select
                size="large"
                placeholder={locale.t("deposit_step.first_step.description")}
                // value={props.accountSelected}
                defaultValue={
                  props.accountSelected ? props.accountSelected.id : undefined
                }
                onChange={props.onAccountSelected}
                disabled
              >
                {props.user.accounts.filter((e) => e.type === "real").length >
                  0 &&
                  props.user.accounts
                    .filter((e) => e.type === "real")
                    .map((e, i) => (
                      <Option value={e.id}>
                        <div className="d-flex justify-content-between">
                          <div>
                            {e.platform}&nbsp;
                            {e.account_number}
                            &nbsp;
                            <b>
                              (
                              {e.type === "welcome"
                                ? "Welcome"
                                : e.account_type}
                              )
                            </b>
                          </div>
                          <div>
                            {e.account_type === "Cent" ? "¢" : "$"}
                            {e.current_balance
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </div>
                        </div>
                      </Option>
                    ))}
              </Select>
            ) : (
              <Select
                size="large"
                placeholder={locale.t("deposit_step.first_step.description")}
                // value={props.accountSelected}
                defaultValue={
                  props.accountSelected ? props.accountSelected.id : undefined
                }
                onChange={props.onAccountSelected}
              >
                {props.user.accounts.filter((e) => e.type === "real").length >
                  0 &&
                  props.user.accounts
                    .filter((e) => e.type === "real")
                    .map((e, i) => (
                      <Option value={e.id}>
                        <div className="d-flex justify-content-between">
                          <div>
                            {e.platform}&nbsp;
                            {e.account_number}
                            &nbsp;
                            <b>({e.account_name_show || e.account_type})</b>
                          </div>
                          <div>
                            {e.account_type === "Cent" ? "¢" : "$"}
                            {e.current_balance
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </div>
                        </div>
                      </Option>
                    ))}
              </Select>
            )}
          </Form.Item>
        </div>
        {props.accountSelected && (
          <>
            <div className="mt-3" />
            <span className="w-12 text-gray">
              {locale.t("withdraw_step.first_step.withdraw_with")}
            </span>
            {props?.paymentAll.length > 0 ? (
              <>
                <div
                  style={{
                    padding: "10px 30px",
                    backgroundColor: "#F2F2F2",
                    borderRadius: 8,
                    width: "fit-content",
                    marginBottom: 20,
                    marginTop: 16,
                    fontWeight: 700,
                  }}
                  className="fw-700"
                >
                  {locale.t("deposit_step.first_step.recommended")}
                </div>

                <Radio.Group
                  onChange={props.onChangePayment}
                  value={props.paymentKey}
                >
                  <Space direction="vertical">
                    {props?.paymentAll?.filter((e) => e.recommend === 1)
                      .length > 0 ? (
                      props?.paymentAll
                        ?.filter((e) => e.recommend === 1)
                        .map((e, i) => (
                          <Radio
                            value={e.payment_key}
                            className="d-flex align-items-center"
                          >
                            <div className="d-flex w-100">
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/bank/${e.logo}`}
                                alt="...."
                                width={"40px"}
                                height={"40px"}
                                style={{ borderRadius: 4 }}
                              />

                              <div className="ml-2">
                                <List.Item className="p-0">
                                  <List.Item.Meta
                                    title={<div>{e.name}</div>}
                                    description={`(Minimum withdraw ${e?.payment_config[0]?.minimum_withdraw
                                      ?.toFixed(2)
                                      ?.replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${
                                      profileCountry?.symbol
                                    })`}
                                  />
                                </List.Item>
                              </div>
                            </div>
                          </Radio>
                        ))
                    ) : (
                      <Radio
                        value={props?.paymentAll[0]?.payment_key}
                        className="d-flex align-items-center"
                      >
                        <div className="d-flex w-100">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/bank/${props?.paymentAll[0]?.logo}`}
                            alt="...."
                            width={"40px"}
                            height={"40px"}
                            style={{ borderRadius: 4 }}
                          />

                          <div className="ml-2">
                            <List.Item className="p-0">
                              <List.Item.Meta
                                title={<div>{props?.paymentAll[0]?.name}</div>}
                                description={`(Minimum withdraw ${props?.paymentAll[0]?.payment_config[0]?.minimum_withdraw
                                  ?.toFixed(2)
                                  ?.replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${
                                  profileCountry?.symbol
                                })`}
                              />
                            </List.Item>
                          </div>
                        </div>
                      </Radio>
                    )}

                    <div
                      className="cursor-pointer fs-12 text-gray my-3"
                      onClick={() => setShowpaymentall(!showpaymentall)}
                    >
                      {locale.t("withdraw_step.first_step.show_all_method")}
                    </div>
                    {showpaymentall &&
                      props.paymentAll &&
                      props.paymentAll
                        .filter((e) => e.recommend === 0)
                        .map((e, i) => (
                          <Radio
                            value={e.payment_key}
                            className="d-flex align-items-center"
                          >
                            <div className="d-flex w-100">
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/bank/${e.logo}`}
                                alt="...."
                                width={"40px"}
                                height={"40px"}
                                style={{ borderRadius: 4 }}
                              />

                              <div className="ml-2">
                                <List.Item className="p-0">
                                  <List.Item.Meta
                                    title={<div>{e.name}</div>}
                                    description={`(Minimum withdraw ${e?.payment_config[0]?.minimum_withdraw
                                      ?.toFixed(2)
                                      ?.replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${
                                      profileCountry?.symbol
                                    })`}
                                  />
                                </List.Item>
                              </div>
                            </div>
                          </Radio>
                        ))}
                  </Space>
                </Radio.Group>
              </>
            ) : (
              <div className="text-center py-5 fs-14 text-lightgray">
                {locale.t("withdraw_step.no_payment")}
                {/* Apologies, but the payment is temporarily unavailable. */}
              </div>
            )}
          </>
        )}
        <div
          className="mt-3"
          style={{
            display: "flex",
            width: "100%",

            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {
            <Button
              className="btn-primary-new-iux w-100"
              onClick={props.onNextStep}
              disabled={
                props.accountSelected === null || props.paymentSelected === null
              }
            >
              {locale.t("next")}
            </Button>
          }
        </div>
      </Form>
    </Fragment>
  );
};

const mapStateToProps = ({ user, i18n }) => {
  const { accounts, profile, account } = user;
  return { accounts, profile, account, locale: i18n.locale, user };
};

export default connect(mapStateToProps, null)(WithDrawFirstStep);
