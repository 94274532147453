import { Button, Form, Upload, message } from "antd";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
const locale = require("react-redux-i18n").I18n;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function UploadVerify(props) {
  const { form, onChangeStep, isValidationData } = props;
  const [isFile, setIsFile] = useState(null);
  const [isFilePDF, setIsFilePDF] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const uploadOption = {
    onRemove: async (file) => {
      setIsFile(null);
      setIsFilePDF(null);
      await form.setFieldsValue({ upload: null });
      await form.validateFields(["upload"]);
    },

    beforeUpload: async (file) => {
      const isPNG = file.type === "image/png";
      const isJPG = file.type === "image/jpg";
      const isJPEG = file.type === "image/jpeg";
      const isPDF = file.type === "application/pdf";
      const isLt2M = file.size / 1024 / 1024 < 8;
      if (!isPNG && !isJPG && !isJPEG && !isPDF) {
        message.error(`${file.name} ${locale.t("acceptedfile")}`);
        setIsFile(null);
        await form.setFieldsValue({ upload: null });
        // await form.validateFields(["upload"]);
      } else if (!isLt2M) {
        message.error(locale.t("acceptedfile"));
      } else {
        if (isPDF) {
          setIsFilePDF(file);
          setIsFile(file);
        } else if (!file.url && !file.preview) {
          file.preview = await fileToBase64(file);
          setIsFilePDF(null);
          setIsFile(`data:${file.type};base64,` + file.preview || file.url);
        } else {
          setIsFilePDF(null);
          setIsFile(`data:${file.type};base64,` + file.preview || file.url);
        }
      }

      return false;
    },
  };
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await fileToBase64(file);
    }
    setIsFile(file.url || file.preview);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <div>
      <div>
        {" "}
        <>
          <div className="d-flex justify-content-center my-5 flex-column align-items-center">
            <div className="fs-20 font-weight-300">
              {locale.t("manualtitle")}
            </div>
            <div
              className="fs-16 mt-4 text-lightgray text-center"
              style={{ maxWidth: 800 }}
            >
              {locale.t("manualsubtitle")}&nbsp;
              {isValidationData.label}
              &nbsp;{locale.t("manualsubtitle2")}
            </div>
            <div className="mt-5 row ">
              {[...Array(3)].map((e, i) => (
                <div key={i} className="col-4 col-md-4 px-1 px-md-4">
                  <img
                    src={`/assets/images/verify/id-doc-condition${i + 1}.png`}
                    alt={`id-doc-${i + 1}`}
                    width={"100%"}
                  />
                </div>
              ))}
            </div>
            <div className="text-center text-lightgray mt-4">
              {locale.t("acceptedfile")}
            </div>
          </div>
          <div className="px-5">
            <hr className="my-5" />
          </div>
          <div className="mb-5">
            <div className="d-flex justify-content-center mt-5">
              <Form.Item
                className="upload-verify d-flex justify-content-center"
                name="upload"
                rules={[
                  {
                    required: true,
                    // message: locale.t("dashboardFund.modalVerify.footerText3"),
                  },
                ]}
              >
                <Upload
                  {...uploadOption}
                  fileList={false}
                  onPreview={handlePreview}
                  className="cursor-pointer"
                >
                  {isFile ? (
                    <div
                      style={{
                        maxWidth: "270px",
                        border: "1px dashed #1CB894",
                        borderRadius: 8,
                      }}
                      className="p-4 position-reletive"
                    >
                      <div
                        className="fs-20 cursor-pointer"
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 8,
                          zIndex: 8,
                        }}
                        onClick={() => {
                          form.setFieldsValue({ upload: null });
                          setIsFile(null);
                        }}
                      >
                        x
                      </div>
                      {isFilePDF ? (
                        <Document
                          file={isFile}
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          <Page
                            width={200}
                            pageIndex={0}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                          />
                        </Document>
                      ) : (
                        <img
                          src={isFile}
                          alt="eieiei"
                          width={"100%"}
                          style={{ borderRadius: 8 }}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="iux-scan-id ">
                      <img
                        src="/assets/images/verify/uploadfile.svg"
                        alt="uploadfile"
                      />
                    </div>
                  )}
                </Upload>
              </Form.Item>
            </div>
            <div className="text-center mt-3"> {locale.t("uploadfile")}</div>

            <div
              className="text-center mt-3 text-lightdark mx-auto"
              style={{ maxWidth: 800 }}
            >
              {locale.t("anotherdeviceDetail")}
            </div>
          </div>
          <Form.Item shouldUpdate>
            {() => (
              <div className="text-center">
                <Button
                  disabled={
                    form.getFieldValue("upload") === undefined ||
                    form.getFieldValue("upload") === null ||
                    isFile === null
                  }
                  // type="submit"
                  // loading={loading}
                  onClick={() => onChangeStep("upload")}
                  className="btn-primary-new-iux"
                >
                  {locale.t("continue")}
                </Button>
              </div>
            )}
          </Form.Item>
        </>
      </div>
    </div>
  );
}

export default UploadVerify;
