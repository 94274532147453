export default {
  th: {
    lang: "อังกฤษ / ไทย",
    register: "สมัครสมาชิก",
    signin: "เข้าสู่ระบบ",
    contact: "ติดต่อ",
    partnership: "พาร์ทเนอร์",
    company: "บริษัท",
    copytrade: "Copy Trade",
    platforms: "แพลตฟอร์ม",
    account: "บัญชี",
    fund: "ธุรกรรม",
    invite: "โปรแกรมล็อตแบค",
    loyalty: "โปรแกรมโรยัลตี้",
    partner: "โปรแกรมพาร์ทเนอร์",
    accounttype: "ประเภทบัญชี",
    program: "โปรแกรมเทรด",
    verify: "การยืนยันตัวตน",
    deposit: "ฝาก - ถอน",
    Transfer: "โอนเงินภายใน",
    calendar: "ปฏิทิน",
    market: "ตลาด",
    forex: "CFDs",
    futures: "ฟิวเจอร์",
    Indices: "ดัชนี",
    Shares: "หุ้น",
    Metals: "โลหะ",
    Energies: "พลังงาน",
    startbonus: "โปรโมชัน",
    loyaltybonus: "โปรแกรมล็อตแบ็ค",
    tools: "เครื่องมือ",
    spread: "สเปรด",
    analyze: "วิเคราะห์โดย TradingView",
    symbol: "ตลาด",
    setting: "ตั้งค่า",
  },
  la: {
    lang: "ອັງກິດ / ໄທ",
    register: "ລົງ​ທະ​ບຽນ",
    signin: "ເຂົ້າ​ສູ່​ລະ​ບົບ",
    contact: "ຕິດຕໍ່",
    partnership: "ຜູ້ຮ່ວມມື",
    company: "ບໍລິສັດ",
    copytrade: "Copy Trade",
    platforms: "ແພລະຕະຟອມ",
    account: "ບັນຊີ",
    fund: "ເຮັດທຸລະກຳ",
    invite: "ໂປແກມLotback",
    loyalty: "ໂປແກຼມ Lotback",
    partner: "ໂປແກຼມຄູ່ຮ່ວມງານ",
    accounttype: "ປະເພດບັນຊີ",
    program: "ໂປແກມເທຮດ",
    verify: "ຢັ້ງຢືນ",
    deposit: "ການຝາກເງິນ - ຖອນເງິນ",
    Transfer: "ໂອນ",
    calendar: "ປະຕິທິນ",
    market: "ຕະຫຼາດ",
    forex: "CFDs",
    futures: "Futures",
    Indices: "ຕົວຊີ້ວັດ",
    Shares: "ຮຸ້ນ",
    Metals: "ໂລຫະ",
    Energies: "ພະລັງງານ",
    startbonus: "ໂປຼໂມຊັນ",
    loyaltybonus: "ໂປແກຼມ Lotback",
    tools: "Tools & Services",
    spread: "Spread",
    analyze: "ວິເຄາະໂດຍ TardingView",
    symbol: "Markets",
    setting: "Setting",
  },
  en: {
    lang: "EN / TH",
    register: "Open an account",
    signin: "Login",
    contact: "Contact",
    partnership: "Partnership",
    company: "Company",
    copytrade: "Copy Trade",
    platforms: "Platforms",
    account: "Account",
    fund: "Transaction",
    invite: "Lot Back",
    loyalty: "Loyalty",
    partner: "Partner",
    accounttype: "Account type",
    program: "Trade Program",
    verify: "Verify",
    deposit: "Deposit - Withdraw",
    Transfer: "Transfer",
    calendar: "Economic Calendar",
    market: "Markets",
    Forex: "CFDs",
    futures: "Futures",
    Indices: "Indices",
    Shares: "Shares",
    Metals: "Metals",
    Energies: "Energies",
    startbonus: "Promotion",
    loyaltybonus: "Lot Back",
    tools: "Tools & Services",
    spread: "Spread",
    analyze: "Analyze with TradingView",
    symbol: "Markets",
  },
  in: {
    lang: "एन/टीएच",
    register: "खाता खोलें",
    signin: "लॉग इन करें",
    contact: "संपर्क",
    partnership: "साझेदारी",
    company: "कंपनी",
    copytrade: "प्रतिलिपि व्यापार",
    platforms: "प्लेटफार्म",
    account: "खाता",
    fund: "लेन-देन",
    invite: "बहुत पीछे",
    loyalty: "निष्ठा",
    partner: "साथी",
    accounttype: "खाते का प्रकार",
    program: "व्यापार कार्यक्रम",
    verify: "सत्यापित करें",
    deposit: "जमा निकालना",
    Transfer: "स्थानांतरण",
    calendar: "पंचांग",
    market: "बाज़ार",
    Forex: "सीएफडी",
    futures: "फ्यूचर्स",
    Indices: "सूचकांकों",
    Shares: "शेयरों",
    Metals: "धातुओं",
    Energies: "ऊर्जा",
    startbonus: "प्रचार",
    loyaltybonus: "बहुत पीछे",
    tools: "उपकरण एवं सेवाएँ",
    spread: "फैलाना",
    analyze: "TardingView द्वारा विश्लेषण",
    symbol: "बाज़ार",
    setting: "सेटिंग",
  },
  my: {
    lang: `EN / TH`,
    register: `Daftar`,
    signin: `Log Masuk`,
    contact: `Hubungi`,
    partnership: `Rakan kongsi`,
    company: "Syarikat",
    copytrade: `Salinan Dagangan`,
    platforms: `Platform`,
    account: `Akaun`,
    fund: `Transaksi`,
    invite: `Program LotBack`,
    partner: "Rakan kongsi",
    accounttype: "Jenis Akaun",
    program: `Program perdagangan`,
    verify: `Mengesahkan identiti`,
    deposit: `Deposit - Pengeluaran`,
    Transfer: `Pemindahan`,
    calendar: "kalendar",
    market: `Pasaran`,
    Forex: `CFDs`,
    futures: `Niaga hadapan`,
    Indices: `Indeks `,
    Shares: `Saham`,
    Metals: `Metals`,
    Energies: `tenaga`,
    startbonus: "promosi",
    loyaltybonus: "Program LotBack",
    tools: "Tools & Services",
    spread: "Spread",
    analyze: "Menganalisis oleh TradingView",
    symbol: "Markets",
  },
  id: {
    lang: `EN / TH`,
    register: `Daftar`,
    signin: `Masuk`,
    contact: `Kontak`,
    partnership: `persekutuan`,
    company: "Company",
    copytrade: `Copy Dagang`,
    platforms: `platform`,
    account: `Akun`,
    fund: `Transaksi`,
    invite: `Loyalitas`,
    partner: "Pasangan",
    accounttype: "Jenis akun",
    program: `Program perdagangan`,
    verify: `Memeriksa`,
    deposit: `Deposit - Penarikan`,
    Transfer: `Transfer`,
    calendar: "Calendar",
    market: `Pasar`,
    Forex: `CFDs`,
    futures: `Futures`,
    Indices: `indeks`,
    Shares: `saham`,
    Metals: `logam`,
    Energies: `energi`,
    startbonus: "promosi",
    loyaltybonus: "Loyalitas",
    tools: "Tools & Services",
    spread: "Spread",
    analyze: "Analisis dengan TradingView",
    symbol: "Markets",
    setting: "Setting",
  },
  cn: {
    lang: `EN / TH`,
    register: `注册`,
    signin: `登入`,
    contact: `联系`,
    partnership: `合伙`,
    company: "Company",
    copytrade: `复制贸易`,
    platforms: `平台`,
    account: `帐户`,
    fund: `交易`,
    invite: `忠诚`,
    partner: "伙伴",
    accounttype: "Account type",
    program: `贸易项目`,
    verify: `校验`,
    deposit: `存款 - 提款`,
    Transfer: `转让`,
    calendar: "Calendar",
    market: `市场`,
    Forex: `外汇`,
    futures: `期货`,
    Indices: `指数`,
    Shares: `分享`,
    Metals: `金属`,
    Energies: `能源`,
    startbonus: "促销",
    loyaltybonus: "忠诚",
    tools: "Tools & Services",
    spread: "Spread",
    analyze: "通過TradingView進行分析",
    symbol: "Markets",
  },
  vn: {
    lang: "Tiếng Anh / Tiếng Thái",
    register: "Đăng ký",
    signin: "Đăng nhập",
    contact: "Liên hệ",
    partnership: "Đối tác",
    company: " Công ty",
    copytrade: "Copy Trade",
    platforms: "Nền tảng",
    account: "Tài khoản",
    fund: "Giao dịch",
    invite: "CHƯƠNG TRÌNH LOTBACK",
    loyalty: "Chương trình Loyalty",
    partner: "Chương trình đối tác",
    accounttype: "Loại tài khoản",
    program: "Chương trình giao dịch",
    verify: "Xác minh danh tính",
    deposit: "Nạp - Rút",
    Transfer: "Chuyển tiền nội bộ",
    calendar: "lịch",
    market: "Thị trường",
    forex: "CFDs",
    futures: "Future",
    Indices: "chỉ số ",
    Shares: "chứng khoán",
    Metals: "Kim loại",
    Energies: "Năng lượng",
    startbonus: "Khuyến mãi",
    loyaltybonus: "Chương trình lotback",
    tools: "Công cụ",
    spread: "Spread",
    analyze: "Phân tích bằng cách giao dịch",
    symbol: "Markets",
    setting: "Cài đặt",
  },
  kr: {
    lang: `EN / TH`,
    register: `가입하기`,
    signin: `에 서명`,
    contact: `접촉`,
    partnership: `협력 관계`,
    company: `Company`,
    copytrade: `복사 전시회`,
    platforms: `플랫폼`,
    account: `계정`,
    fund: `트랜잭션`,
    invite: `충의`,
    partner: "파트너",
    accounttype: "Account type",
    program: `전시회 프로그램`,
    verify: `확인`,
    deposit: `예금 - 철수`,
    Transfer: `이전`,
    calendar: "Calendar",
    market: `시장`,
    Forex: `외환`,
    futures: `선물`,
    Indices: `지수`,
    Shares: `주식`,
    Metals: `궤조`,
    Energies: `에너지`,
    startbonus: "프로모션",
    loyaltybonus: "충의",
    spread: "Spread",
    analyze: "TradingView로 분석하십시오",
    symbol: "Markets",
    setting: "Setting",
  },
  ph: {
    lang: `EN / TH`,
    register: `Mag-sign Up`,
    signin: `Mag-sign in`,
    contact: `Makipag-ugnay sa`,
    partnership: `sosyohan`,
    company: "Company",
    copytrade: `Kopyahin Trade`,
    platforms: `platform`,
    account: `account`,
    fund: `transaksiyon`,
    invite: `katapatan`,
    partner: "kasosyo",
    accounttype: "Account type",
    program: `Trade Programa`,
    verify: `mapatunayan`,
    deposit: `Deposit - Bawiin`,
    Transfer: `paglilipat`,
    calendar: "Kalendaryo",
    market: `MGA PAMILIHAN`,
    Forex: `CFDs`,
    futures: `futures`,
    Indices: `Mga Index`,
    Shares: `pagbabahagi`,
    Metals: `riles`,
    Energies: `energies`,
    startbonus: "Mga Pag-promote",
    loyaltybonus: "katapatan",
    tools: "Tools & Services",
    spread: "Spread",
    analyze: "Pag -aralan sa pamamagitan ng TradingView",
    symbol: "Markets",
    setting: "Setting",
  },
  jp: {
    lang: "en / th",
    register: "アカウントを開きます",
    signin: "ログイン",
    contact: "接触",
    partnership: "パートナーシップ",
    company: "会社",
    copytrade: "トレードをコピーします",
    platforms: "プラットフォーム",
    account: "アカウント",
    fund: "取引",
    invite: "たくさん戻って",
    loyalty: "忠誠心",
    partner: "相棒",
    accounttype: "口座の種類",
    program: "貿易プログラム",
    verify: "確認する",
    deposit: "デポジット - 撤回",
    Transfer: "移行",
    calendar: "経済カレンダー",
    market: "市場",
    Forex: "CFDS",
    futures: "先物",
    Indices: "インデックス",
    Shares: "株式",
    Metals: "金属",
    Energies: "エネルギー",
    startbonus: "プロモーション",
    loyaltybonus: "たくさん戻って",
    tools: "ツールとサービス",
    spread: "広める",
    analyze: "TradingViewで分析します",
    symbol: "市場",
  },
};
