import { Col, Row, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { get, post } from "../../../../helper/request";

const ItemSelect = styled.div`
  box-shadow: 0 0 7px #80808033;
  padding: 12px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
  height: 100%;
  text-align: center;
  &:hover {
    background: #169376;
    color: #fff;
    box-shadow: 0 0 12px #169376;
  }
`;
export const postWealthpay = ({
  amount_thb,
  account_trade_id,
  payment_id,
  bank,
  ip,
}) => {
  return post(`${process.env.REACT_APP_API_URL + "v2/deposit/wealthpay"}`, {
    amount_thb: amount_thb,
    account_trade_id: account_trade_id,
    payment_id: payment_id,
    bank: bank,
    // ip: ip.toString().replace("\n", ""),
  });
};

export const getWealthpay = () => {
  return get(`${process.env.REACT_APP_API_URL + "v2/deposit/wealthpay"}`);
};

const Wealthpay = (props) => {
  const selectIp = useSelector(({ user: { ipAddress } }) => ipAddress);
  const { paymentId } = props;

  const [linkPay, setLinkPay] = useState(null);
  const refWealthpay = useRef(null);
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({
    banks: [],
    amount: 0,
    backURI: "",
    bank: "",
    currency: "",
    customer: "",
    datetime: "",
    frontURI: "",
    key: "",
    language: "",
    note: "",
    reference: "",
    host: "",
    clientIP: selectIp,
    host: "",
  });
  useEffect(() => {
    fectchBank();
  }, []);
  const fectchBank = async () => {
    try {
      const { data } = await getWealthpay();
      setLoading(true);

      setPaymentData((prev) => ({
        ...prev,
        banks: data.data,
      }));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const onFinish = async (el) => {
    setLoading(true);
    const payload = {
      account_trade_id: props.account.id,
      amount_thb: +props.amount,
      payment_id: paymentId,
      bank: el,
      // ip: selectIp,
    };
    const { data } = await postWealthpay(payload);
    if (data) {
      await Promise.all([setLinkPay(data?.data?.link)]).then(() => {
        refWealthpay.current.click();
      });
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="row">
        {paymentData?.banks.map((e, i) => (
          <div className="col-12 col-lg-4 px-1">
            <ItemSelect onClick={() => onFinish(Object.keys(e)[0])}>
              {/* <div>{Object.keys(e)[0]}</div> */}
              <div>{paymentData.banks[i][Object.keys(e)[0]]}</div>
            </ItemSelect>
          </div>
        ))}
      </div>

      <a ref={refWealthpay} href={linkPay} hidden>
        Wealthpay
      </a>
    </Spin>
  );
};

export default Wealthpay;
