import React, { useState, useEffect } from "react";
import { Modal, Space, Table, Tag, Badge, Empty } from "antd";
import { get, post } from "../../../helper/request";
import moment from "moment";
import { connect } from "react-redux";

const locale = require("react-redux-i18n").I18n;

export const Transaction = (props) => {
  const [transactions, setTransactions] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [countryId, setCountryId] = useState(0);

  const renderprefix = () => {
    if (countryId === 8) {
      return "₭";
    } else if (countryId === 6) {
      return "RM";
    } else if (countryId === 10) {
      return "₫";
    } else if (countryId === 9) {
      return "Rp";
    } else {
      return "฿";
    }
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const columnsdata = [
    {
      title: <div className="fs-12 text-gray">Type</div>,
      dataIndex: "amount",
      key: "amount",

      render: (amount) => {
        let color = "#1CB894";
        if (amount < 0) {
          color = "#F41112";
        }
        return (
          <span>
            <p style={{ color: color }} key={amount}>
              {amount > 0 ? "Deposit" : "Withdraw"}
            </p>
          </span>
        );
      },

      filteredValue: filteredInfo.amount || null,
      onFilter: (value, record) => record.amount.includes(value),
      sorter: (a, b) => a.amount - b.amount,
      sortOrder: sortedInfo.columnKey === "amount" && sortedInfo.order,
      width: "5%",
    },
    {
      title: <div className="fs-14 text-gray">Time</div>,
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => {
        return (
          <span>
            <p key={date} className="text-black">
              {moment(date).format("YYYY/MM/DD HH:mm")}
            </p>
          </span>
        );
      },
      onFilter: (value, record) => record.date.includes(value),
      sorter: (a, b) => {
        return a.createdAt - b.createdAt;
      },
      sortOrder: sortedInfo.columnKey === "createdAt" && sortedInfo.order,
      width: "22%",
    },
    {
      title: (
        <div className="fs-12 text-gray">
          {locale.t("dashboardFund.tabHistory.account")}
        </div>
      ),
      dataIndex: "account_trade",
      key: "account_trade",
      render: (account_trade) => {
        return (
          <span className="text-black">
            {account_trade !== null ? account_trade.account_number : ""}
          </span>
        );
      },
      onFilter: (value, record) => record.account.includes(value),
      sorter: (a, b) => a.account_trade.length - b.account_trade.length,
      sortOrder: sortedInfo.columnKey === "account" && sortedInfo.order,
      width: "5%",
    },
    {
      title: <div className="fs-12 text-gray">Method</div>,
      dataIndex: "method",
      key: "method",
      render: (method, data) => {
        return (
          <div className="d-flex text-black">
            {data?.payment ? (
              <img
                onError={addDefaultSrc}
                alt="method"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/bank/" +
                  data?.payment?.logo
                }
                width={20}
                height={20}
              />
            ) : (
              <img
                onError={addDefaultSrc}
                alt="method"
                src={
                  process.env.PUBLIC_URL + "/assets/images/" + method + ".png"
                }
                width={20}
                height={20}
              />
            )}
            <span className="ml-2">
              {data?.payment ? data?.payment.name : method}
            </span>
          </div>
        );
      },
      filteredValue: filteredInfo.method || null,
      onFilter: (value, record) => record.method.includes(value),
      sorter: (a, b) => a.method - b.method,
      sortOrder: sortedInfo.columnKey === "method" && sortedInfo.order,
      width: "25%",
    },
    {
      title: <div className="fs-12 text-gray">Local Amount</div>,
      dataIndex: "amount_thb",
      key: "amount_thb",
      render: (amount) => {
        return (
          <span className="text-black">
            {renderprefix()}
            {amount?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </span>
        );
      },
      filteredValue: filteredInfo.amount || null,
      onFilter: (value, record) => record.amount.includes(value),
      sorter: (a, b) => a.amount - b.amount,
      sortOrder: sortedInfo.columnKey === "amount" && sortedInfo.order,
      width: "17%",
    },

    {
      title: <div className="fs-12 text-gray">Receive</div>,
      dataIndex: "amount",
      key: "amount",
      render: (amount) => {
        let color = "#1CB894";
        if (amount < 0) {
          color = "#F41112";
        }
        return (
          <span>
            <p style={{ color: color }} key={amount}>
              {amount < 0 && "-"} $
              {Math.abs(amount)
                ?.toFixed(2)
                ?.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </p>
          </span>
        );
      },
      filteredValue: filteredInfo.amount || null,
      onFilter: (value, record) => record.amount.includes(value),
      sorter: (a, b) => a.amount - b.amount,
      sortOrder: sortedInfo.columnKey === "amount" && sortedInfo.order,
      width: "15%",
    },

    {
      title: (
        <div className="fs-12 text-gray">
          {locale.t("dashboardFund.tabHistory.status")}
        </div>
      ),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <span className="fs-14">
            {status && capitalizeFirstLetter(status)}
          </span>
        );
      },
      onFilter: (value, record) => record.status.includes(value),
      sorter: (a, b) => a.status.length - b.status.length,
      sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
      width: "8%",
    },

    //   {
    //     title: "Action",
    //     key: "action",
    //     render: (_, record) => (
    //       <Space size="middle">
    //         {record.method === "GBPayQRCode" && record.status !== "accepted" ? (
    //           <button
    //             className="bnt btn-outline-iux"
    //             type="button"
    //             onClick={() => this.handleReCheck(record.id)}
    //           >
    //             Recheck
    //           </button>
    //         ) : (
    //           <div />
    //         )}
    //       </Space>
    //     ),
    //   },
  ];
  const addDefaultSrc = (ev) => {
    ev.target.src = "/assets/images/icons/globe-black.svg";
  };
  const fetchTranactions = () => {
    get(process.env.REACT_APP_API_URL + "v2/user/account/trade/transaction")
      .then((res) => {
        const datakey = res.data.data.map((e, i) => (e.key = i + 1));
        setTransactions(res.data.data.reverse());

        // setColumns(columnsdata);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    setCountryId(props.user.profile.country_id);
    fetchTranactions();
  }, [props.user.profile.country_id]);

  // const handleChange = (pagination, filters, sorter) => {
  //   this.setState({
  //     filteredInfo: filters,
  //     sortedInfo: sorter,
  //   });
  // };
  // const handleReCheck = (id) => {
  //   try {
  //     post(`${process.env.REACT_APP_API_URL}deposit/gbpay/recheck`, {
  //       account_deposit_id: id,
  //     })
  //       .then((res) => {
  //         Modal.info({
  //           title: "info",
  //           content: res.data.message,
  //         });
  //       })
  //       .catch((res) => {
  //         Modal.error({
  //           title: "something went wrong",
  //           content: "please contact to Support@iuxmarkets.com",
  //         });
  //       });
  //   } catch (error) {
  //     Modal.error({
  //       title: "something went wrong",
  //       content: "please contact to Support@iuxmarkets.com",
  //     });
  //     console.log(error);
  //   }
  // };
  const renderExpand = (data) => {
    return (
      <div className="overflow-hidden">
        <div className="fs-12 font-weight-bold">Other details</div>
        <div className="row mt-2">
          <div className="col-3">
            <div className="fs-12 text-gray">Account Type</div>
            <div className="fs-12">
              {data?.account_trade?.account_group?.display}
            </div>
          </div>
          <div className="col-3">
            <div className="fs-12 text-gray">Transaction ID</div>
            <div className="fs-12">{data.id}</div>
          </div>
          <div className="col-3">
            <div className="fs-12 text-gray">Rate </div>
            <div className="fs-12">
              {(data.amount_thb / data.amount)?.toFixed(2)}
            </div>
          </div>
          <div className="col-3">
            <div className="fs-12 text-gray">Your bank account </div>
            <div className="fs-12">{data.transfer_account_number}</div>
          </div>
        </div>
      </div>
    );
  };
  // filteredInfo: null,
  // sortedInfo: null,
  // search: "",
  return (
    <div className="user">
      <h5 className="mb-3">{locale.t("dashboardFund.tabHistory.title")}</h5>
      <Table
        locale={{
          emptyText: (
            <Empty
              description={locale.t("nodata")}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ),
        }}
        columns={columnsdata}
        expandIconColumnIndex={7}
        // expandIcon={() => }
        expandable={{
          expandRowByClick: true,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <img
                src="/assets/images/icons/dashboard/arrow-up.svg"
                type="up"
                alt="arrow-up"
                style={{ fontSize: "20px" }}
                className="cursor-pointer"
                onClick={(e) => onExpand(record, e)}
              />
            ) : (
              <img
                src="/assets/images/icons/dashboard/arrow-down.svg"
                type="down"
                alt="arrow-down"
                style={{ fontSize: "20px" }}
                className="cursor-pointer"
                onClick={(e) => onExpand(record, e)}
              />
            ),

          expandedRowRender: (record) => renderExpand(record),
          rowExpandable: (record) => record.account_trade !== "",
        }}
        pagination={{
          showSizeChanger: false,
        }}
        // dataSource={dataCalendar}.
        dataSource={transactions.filter(
          (transaction) =>
            transaction.status === "pending" &&
            moment(transaction.createdAt).isSame(moment(), "day")
        )}
      />

      <h5 className="mb-3">
        {locale.t("dashboardFund.tabHistory.title2")}
        {/* <RangePicker
                  placeholder={['จากวันที่', 'ถึงวันที่']} className="ml-2" /> */}
      </h5>
      <Table
        locale={{
          emptyText: (
            <Empty
              description={locale.t("nodata")}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ),
        }}
        columns={columnsdata}
        expandIconColumnIndex={7}
        // expandIcon={() => }
        expandable={{
          expandRowByClick: true,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <img
                src="/assets/images/icons/dashboard/arrow-up.svg"
                type="up"
                alt="arrow-up"
                style={{ fontSize: "20px" }}
                className="cursor-pointer"
                onClick={(e) => onExpand(record, e)}
              />
            ) : (
              <img
                src="/assets/images/icons/dashboard/arrow-down.svg"
                type="down"
                alt="arrow-down"
                style={{ fontSize: "20px" }}
                className="cursor-pointer"
                onClick={(e) => onExpand(record, e)}
              />
            ),

          expandedRowRender: (record) => renderExpand(record),
          rowExpandable: (record) => record.account_trade !== "",
        }}
        pagination={{
          showSizeChanger: false,
        }}
        // dataSource={dataCalendar}.
        dataSource={transactions.filter(
          (transaction) => transaction.status !== "pending"
        )}
      />
    </div>
  );
};

const mapStateToProps = ({ i18n, user }) => {
  return { locale: i18n.locale, user };
};

export default connect(mapStateToProps, null)(Transaction);
