import Axios from "axios";
export const get = (url, withAuth = true) => {
  const headers = {
    headers: {
      Authorization: withAuth
        ? `Bearer ${localStorage.getItem("access_token")}`
        : null,
    },
  };
  return Axios.get(url, headers);
};

export const post = (url, data = {}, withAuth = true) => {
  const headers = {
    headers: {
      Authorization: withAuth
        ? `Bearer ${localStorage.getItem("access_token")}`
        : null,
    },
  };
  return Axios.post(url, data, headers);
};

export const put = (url, data = {}, withAuth = true) => {
  const headers = withAuth
    ? {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    : null;
  return Axios.post(url, data, headers);
};
export const puts = (url, data = {}, withAuth = true) => {
  const headers = withAuth
    ? {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    : null;
  return Axios.put(url, data, headers);
};
export const del = (url, withAuth = true) => {
  const headers = withAuth
    ? {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    : null;
  return Axios.delete(url, headers);
};
