import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  Col,
  Row,
  Layout,
  Button,
  Modal,
  Input,
  Select,
  Form,
  message,
  Alert,
  Popover,
  Divider,
} from "antd";
import { post } from "../../helper/request";
import {
  addAccount,
  updateLaverage,
  deleteAccount,
  archiveAccount,
  getArchive,
} from "../../actions";
const { Option } = Select;
const locale = require("react-redux-i18n").I18n;
export const OpenAccount = (props) => {
  const { confirm } = Modal;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [accounts, setAccounts] = useState(props.user.accounts);
  const accountTypeList = useSelector((state) => state.account.accountTypeList);
  // const [currency] = useState("USD");
  const [currency, setCurrency] = useState("USD");
  const refAdd = React.createRef();
  const [accountId, setAccountId] = useState(null);
  const selectAccountType = ["Standard", "StandardBonus", "Raw", "Cent"];
  const selectLeverage = {
    Standard: ["1", "20", "50", "100", "200", "500", "1000", "2000"],
    StandardBonus: ["1", "20", "50", "100", "200", "500", "1000"],
    Raw: ["1", "20", "50", "100", "200", "500", "1000"],
    Cent: ["1", "20", "50", "100", "200", "500", "1000", "2000"],
  };
  const [leverType, setLeverType] = useState(null);

  const [form] = Form.useForm();
  const [accType, setAccType] = useState(selectLeverage[selectAccountType[0]]);
  useEffect(() => {
    setAccounts(props.user.accounts);
  }, [props.user]);
  const handleaccountTypeChange = (value, option) => {
    if (value === "Cent") {
      setCurrency("USC");
    } else {
      setCurrency("USD");
    }
    setAccountId(option.key);
    setAccType(selectLeverage[value]);
    setLeverType(selectLeverage[value][0]);
  };
  const onCreate = async () => {
    try {
      const values = await form.validateFields();
      const data = {
        currency: currency,
        platform: "MT5",
        account_type_id: accountId,
        // account_type: values.type,
        password: values.password,
        password_confirmation: values.password,
        leverage: leverType,
        // password_investor: values.password_investor,
        code: localStorage.getItem("code"),
      };

      setButtonLoading(true);
      post(
        process.env.REACT_APP_API_URL + "v2/user/account/trade-real/add",
        data
      )
        .then((res) => {
          let { data } = res;

          const account = {
            id: data.data.id,
            user_id: data.data.user_id,
            account_number: data.data.account_number.toString(),
            platform: data.data.platform,
            currency: data.data.currency,
            api_server: data.data.api_server,
            account_type: values.type,
            password: data.data.password,
            status: data.data.status,
            leverage: data.data.leverage,
            current_leverage: data.data.current_leverage,
            current_balance: data.data.current_balance || 0,
            current_credit: data.data.current_credit || 0,
            deletedAt: null,
            type: data.data.type,
            reset_code: null,
            password_investor: data.data.password_investor,
            reset_code_investor: null,
            createdAt: data.data.createdAt,
            updatedAt: data.data.updatedAt,
            isOrderPlaced: false,
            avilableWithdrawNonCalculated: 0,
            avilableWithdraw: 0,
          };

          props.addAccount([...accounts, account]);
          setButtonLoading(false);

          Modal.info({
            title: locale.t("dashboardWallet.your_mt5"),
            content: (
              <Alert message={data.data.account_number} type="success" />
            ),
          });
          refAdd.current.resetFields();
        })
        .catch((e) => {
          console.log(e);
          setButtonLoading(false);
          try {
            if (e.response.data.code === 10) {
              Modal.error({
                title: locale.t("dashboardWallet.your_account"),
                content: (
                  <Alert
                    message={locale.t("dashboardWallet.error_10")}
                    type="success"
                  />
                ),
              });
            } else {
              message.error(e.response.data.error);
            }
          } catch (error) {
            console.log(error);
          }
        });
    } catch (errorInfo) {
      setButtonLoading(false);
    }
  };
  const validatorPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = new RegExp(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
      );
      if (value.match(regex)) {
        return Promise.resolve();
      }
      return Promise.reject(
        locale.t("dashboardWallet.modal.accountPassword_validate")
      );
    },
  });

  const validatorInvestorPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = new RegExp(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
      );
      if (value.match(regex)) {
        return Promise.resolve();
      }
      return Promise.reject(
        locale.t("dashboardWallet.modal.accountPasswordInvestor")
      );
    },
  });

  const onSecondCityChange = (value) => {
    setLeverType(value);
  };
  return (
    <div className="user">
      {" "}
      <Form
        form={form}
        ref={refAdd}
        initialValues={{
          currency: currency,
        }}
        className="user-change"
      >
        <div
        // style={{
        //   display: isMobile && mobile ? "block" : "none",
        //   padding: isMobile && mobile && "24px",
        // }}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="mt_type"
                rules={[
                  {
                    message: locale.t("dashboardWallet.input_error"),
                  },
                ]}
              >
                <Input disabled defaultValue={"Metatrader 5"} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                // name="currency"
                rules={[
                  {
                    required: true,
                    message: locale.t("dashboardWallet.input_error"),
                  },
                ]}
              >
                <Input disabled value={currency} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="type"
                rules={[
                  {
                    required: true,
                    message: locale.t("dashboardWallet.input_error"),
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Please Select Account Type"
                  //   defaultValue={"Standard"}
                  onChange={handleaccountTypeChange}
                >
                  {accountTypeList
                    ?.filter(
                      (e) =>
                        e.active === 1 &&
                        e.type !== "welcome" &&
                        e.type !== "demo"
                    )
                    ?.map((acctype) => (
                      <Option key={acctype.id} value={acctype.name}>
                        {acctype.display}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                shouldUpdate
                // name="select"
                rules={[
                  {
                    required: true,
                    message: locale.t("dashboardWallet.input_error"),
                  },
                ]}
              >
                {({ getFieldsValue, getFieldsError }) => {
                  return (
                    <Select
                      name="select"
                      size="large"
                      // value={leverType}
                      onChange={onSecondCityChange}
                      placeholder="Leverage"
                    >
                      {getFieldsValue().type &&
                        props?.user?.leverages[getFieldsValue().type].map(
                          (e, index) => (
                            <Option key={index} value={e.values}>
                              <div>
                                <div className="row">
                                  <div className="col-8 pr-0">
                                    <div className="">1:{e.values} &nbsp;</div>
                                    <Divider className="m-0" />
                                  </div>
                                  {(e.values === 1 ||
                                    e.values === 100 ||
                                    e.values === 1000) && (
                                    <div
                                      className={`${
                                        e.values >= 1000
                                          ? "text-danger"
                                          : e.values < 100
                                          ? "text-primary-new-iux"
                                          : "text-yellow-new-iux"
                                      } col-4 text-right pl-0`}
                                    >
                                      <div className=""> {e.title}</div>
                                      <Divider
                                        className={`${
                                          e.values >= 1000
                                            ? "bg-danger"
                                            : e.values < 100
                                            ? "bg-primary-iux"
                                            : "bg-yellow-iux"
                                        } m-0`}
                                        style={{
                                          background:
                                            e.values >= 1000
                                              ? "#B44844"
                                              : e.values < 100
                                              ? "#1cb894"
                                              : "rgb(241, 158, 3)",
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Option>
                          )
                        )}
                    </Select>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: locale.t("dashboardWallet.input_error"),
                  },
                  validatorPassword,
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder={locale.t(
                    "dashboardWallet.modal.accountPassword"
                  )}
                  className="user-password"
                />
              </Form.Item>
            </Col>
            {/* <Col span={24}>
          <Form.Item
            name="password_confirmation"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: locale.t("dashboardWallet.input_error"),
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (
                    !value ||
                    getFieldValue("password") === value
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    locale.t("menuWallet.notmathPassword")
                  );
                },
              }),
            ]}
          >
            <Input
              type="password"
              placeholder={locale.t(
                "dashboardWallet.modal.accountConfirmPassword"
              )}
              style={styleInput}
            />
          </Form.Item>
        </Col> */}
          </Row>
          {/* <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="password_investor"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: locale.t("dashboardWallet.input_error"),
                  },
                  validatorInvestorPassword,
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") !== value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        locale.t("menuWallet.notmathPassword_investor")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder={locale.t(
                    "dashboardWallet.modal.accountPasswordInvestor"
                  )}
                  className="user-password"
                />
              </Form.Item>
            </Col>
          </Row> */}
        </div>
        <div className="d-flex justify-content-between text-center">
          <Button
            className="btn-primary-new-outline mr-2"
            key="back"
            onClick={() => {
              form.resetFields();
              //   setModalCreateVisible(false);
            }}
          >
            {locale.t("dashboardWallet.modal.cancel")}
          </Button>

          <Button
            className="btn-primary-new-iux w-100"
            key="submit"
            type="primary"
            loading={buttonLoading}
            onClick={onCreate}
          >
            {locale.t("dashboardWallet.modal.confirm")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

OpenAccount.propTypes = {};

const mapStateToProps = ({ user, i18n }) => {
  return { user, locale: i18n.locale };
};

const mapDispatchToProps = {
  addAccount,
  getArchive,
  updateLaverage,
  archiveAccount,
  deleteAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenAccount);
