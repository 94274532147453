export const CheckFormatDateChart = (id) => {
  switch (id) {
    case "th":
      return "DD MMMM YYYY";
    case "en":
      return "DD MMMM YYYY";
    case "cn":
      return "DD MMMM YYYY";
    case "my":
      return "DD MMMM YYYY";
    case "in":
      return "DD MMMM YYYY";
    case "la":
      return "DD MMMM YYYY";
    case "id":
      return "DD MMMM YYYY";
    case "vn":
      return "DD MMMM YYYY";
    case "ph":
      return "DD MMMM YYYY";
    case "jp":
      return "YYYY年 MMM D日";
    case "kr":
      return "DD MMMM YYYY";
    default:
      return "DD MMMM YYYY";
  }
};
//
export const CheckFormatMonthChart = (id) => {
  switch (id) {
    case "th":
      return "MMM YYYY";
    case "en":
      return "MMM YYYY";
    case "cn":
      return "MMM YYYY";
    case "my":
      return "MMM YYYY";
    case "in":
      return "MMM YYYY";
    case "la":
      return "MMM YYYY";
    case "id":
      return "MMM YYYY";
    case "vn":
      return "MMM YYYY";
    case "ph":
      return "MMM YYYY";
    case "jp":
      return "YYYY年 MMM";
    case "kr":
      return "MMM YYYY";
    default:
      return "MMM YYYY";
  }
};
