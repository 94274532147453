export default {
  th: {
    change_password_title: `เปลี่ยนรหัสผ่าน`,
    current_password: `รหัสผ่านปัจจุบัน`,
    new_password: `รหัสผ่านใหม่`,
    validation1: `ต้องเป็น 8-15 อักขระ`,
    validation2: `ควรรวมตัวอักษรตัวพิมพ์ใหญ่หรือตัวพิมพ์เล็ก`,
    validation3: ` ต้องมีตัวเลขตัวเลขอย่างน้อยหนึ่งตัว (0-9)`,
    success_password_title: `เปลี่ยนความสำเร็จของรหัสผ่าน`,
    success_password_detail: `รหัสผ่านของคุณเปลี่ยนไปแล้ว`,
  },
  la: {
    change_password_title: `ປ່ຽນ​ລະ​ຫັດ​ຜ່ານ`,
    current_password: `ລະ​ຫັດ​ປັດ​ຈຸ​ບັນ`,
    new_password: `ລະຫັດລັບໃຫມ່`,
    validation1: `ຕ້ອງເປັນ 8-15 ຕົວອັກສອນ`,
    validation2: `ຄວນປະກອບມີຕົວອັກສອນໃຫຍ່ຫຼືຕົວອັກສອນນ້ອຍ`,
    validation3: ` ຕ້ອງມີຢ່າງຫນ້ອຍຫນຶ່ງຕົວເລກຕົວເລກ (0-9)`,
    success_password_title: `ປ່ຽນຄວາມສໍາເລັດລະຫັດຜ່ານ`,
    success_password_detail: `ລະຫັດຜ່ານຂອງທ່ານໄດ້ຖືກປ່ຽນແລ້ວ.`,
  },
  en: {
    change_password_title: `Change Password`,
    current_password: `Current password`,
    new_password: `New password`,
    validation1: `Must be 8-15 characters`,
    validation2: `Should include either uppercase or lowercase letters`,
    validation3: ` Must contain at least one numeric digit (0-9)`,
    success_password_title: `Change Password Success`,
    success_password_detail: `Your password has been changed.`,
  },
  in: {
    change_password_title: `पासवर्ड बदलें`,
    current_password: `वर्तमान पासवर्ड`,
    new_password: `नया पासवर्ड`,
    validation1: `8-15 वर्ण होना चाहिए`,
    validation2: `या तो अपरकेस या लोअरकेस अक्षरों को शामिल करना चाहिए`,
    validation3: ` कम से कम एक संख्यात्मक अंक (0-9) होना चाहिए`,
    success_password_title: `पासवर्ड सफलता बदलें`,
    success_password_detail: `आपका पासवर्ड बदल दिया गया है।`,
  },
  my: {
    change_password_title: `Tukar kata laluan`,
    current_password: `Kata laluan semasa`,
    new_password: `Kata laluan baharu`,
    validation1: `Mesti 8-15 aksara`,
    validation2: `Harus termasuk huruf besar atau huruf kecil`,
    validation3: `Mesti mengandungi sekurang-kurangnya satu digit angka (0-9)`,
    success_password_title: `Tukar Kejayaan Kata Laluan`,
    success_password_detail: `Kata laluan anda telah ditukar.`,
  },
  id: {
    change_password_title: `Ganti kata sandi`,
    current_password: `Kata sandi saat ini`,
    new_password: `Kata sandi baru`,
    validation1: `Harus 8-15 karakter`,
    validation2: `Harus menyertakan huruf huruf besar atau kecil`,
    validation3: ` Harus berisi setidaknya satu digit numerik (0-9)`,
    success_password_title: `Ubah Sukses Kata Sandi`,
    success_password_detail: `Kata sandi Anda telah diubah.`,
  },
  cn: {
    change_password_title: `更改密码`,
    current_password: `当前密码`,
    new_password: `新密码`,
    validation1: `必须是8-15个字符`,
    validation2: `应该包括大写字母或小写字母`,
    validation3: ` 必须至少包含一个数字数字（0-9）`,
    success_password_title: `更改密码成功`,
    success_password_detail: `당신의 비밀번호가 변경되었습니다.`,
  },
  vn: {
    change_password_title: `Đổi mật khẩu`,
    current_password: `Mật khẩu hiện tại`,
    new_password: `Mật khẩu mới`,
    validation1: `Phải là 8-15 ký tự`,
    validation2: `Nên bao gồm các chữ cái chữ hoa hoặc chữ thường`,
    validation3: ` Phải chứa ít nhất một chữ số số (0-9)`,
    success_password_title: `Thay đổi thành công về mật khẩu`,
    success_password_detail: `Mật khẩu của bạn đã được thay đổi.`,
  },
  kr: {
    change_password_title: `비밀번호를 변경하십시오`,
    current_password: `현재 비밀번호`,
    new_password: `새 비밀번호`,
    validation1: `8-15 자 여야합니다`,
    validation2: `대문자 또는 소문자를 포함해야합니다`,
    validation3: `하나 이상의 숫자 숫자 (0-9)를 포함해야합니다.`,
    success_password_title: `비밀번호 성공을 변경하십시오`,
    success_password_detail: `당신의 비밀번호가 변경되었습니다.`,
  },
  ph: {
    change_password_title: `Palitan ANG password`,
    current_password: `Kasalukuyang password`,
    new_password: `Bagong password`,
    validation1: `Dapat 8-15 character`,
    validation2: `Dapat isama ang alinman sa mga titik o maliliit na titik`,
    validation3: `Dapat maglaman ng hindi bababa sa isang numero ng numero (0-9)`,
    success_password_title: `Baguhin ang tagumpay ng password`,
    success_password_detail: `Nabago ang iyong password.`,
  },
  jp: {
    change_password_title: `パスワードを変更する`,
    current_password: `現在のパスワード`,
    new_password: `新しいパスワード`,
    validation1: `8〜15文字でなければなりません`,
    validation2: `大文字または小文字のいずれかを含める必要があります`,
    validation3: `少なくとも1つの数値桁（0-9）を含める必要があります`,
    success_password_title: `パスワードの成功を変更します`,
    success_password_detail: `あなたのパスワードは変更されました。`,
  },
};
