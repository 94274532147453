import React, { useEffect, useState } from "react";
import { Button, Col, message, Modal, Row, Spin } from "antd";
import { get, post } from "../../../../helper/request";
import styled from "styled-components";
import IuxIcon from "../../../../components/svg/iux-icon";
import ArrowLeftIcon from "../../../../components/svg/arrow-left";
import TimesIcon from "../../../../components/svg/times";

export const Pointer = styled.div`
  cursor: pointer;
`;

export const LoadingPanel = styled.div`
  display: flex;
  min-height: 150px;
  margin-top: 16px;
`;

const BoxTop = styled.div`
  border: 1px solid #8080802e;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 0px;
`;

const BoxUnder = styled.div`
  border: 1px solid #8080802e;
  border-radius: 0px 0px 5px 5px;
  border-top: 0px;
  background: #f5f5f5;
`;

const font = {
  fontSize: 16,
};

const PayTrust = (props) => {
  const { amount, amountUsd } = props;
  const [bank, setBank] = useState([]);
  const [select, setSelect] = useState({
    bank: undefined,
    currency: undefined,
  });
  const [step, setStep] = useState(0);
  const [modal, setModal] = useState(false);
  const [payOption, setPayOption] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [loadingBank, setLoadingBank] = useState(false);
  const [loadImage, setLoadImage] = useState(false);

  useEffect(() => {
    setLoadingBank(true);
    get(`${process.env.REACT_APP_API_URL + "deposit/paytrust"}`)
      .then(({ data }) => {
        setBank(data);
        setSelect({
          bank: data[0].bank_code,
          currency: data[0].currency,
        });
        setLoadingBank(false);
      })
      .catch(({ response }) => {
        setLoadingBank(false);
      });
  }, []);

  const handleChange = (e) => {
    const a = e;
    setSelect({ bank: bank[a].bank_code, currency: bank[a].currency });
    setModal(true);
  };

  const handleSubmit = () => {
    setLoading(true);
    post(`${process.env.REACT_APP_API_URL + "deposit/paytrust"}`, {
      payment_key: props.paymentKey,
      amount_thb: props.amount,
      account_trade_id: props.account.id,
      payment_id: props.paymentId,
      bank_code: select.bank,
      currency: select.currency,
    })
      .then(({ data }) => {
        setPayOption({
          web: data.redirect_to,
          qrCode: data.redirect_to_qrcode,
        });
        setLoading(false);
      })
      .catch(({ response }) => {
        message.error(response.data.message);
        setModal(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (modal) {
      handleSubmit();
    } else {
      setPayOption(undefined);
      setLoadImage(false);
    }
  }, [modal]);

  return (
    <Spin spinning={loading || loadingBank}>
      <Row gutter={[8, 8]}>
        {bank.length > 0 &&
          bank.map((item, i) => (
            <Col
              key={i}
              span={6}
              onClick={() => {
                handleChange(i);
              }}
            >
              <div className="deposit-select">
                <div className="auto-display d-flex flex-column h-100 justify-content-center">
                  <div>
                    <img
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/images/payment/${item.name.replace(
                        /\s/g,
                        ""
                      )}.png`}
                      alt="...."
                      height={40}
                    />
                  </div>
                  <div> {item.name}</div>
                </div>
              </div>
            </Col>
          ))}
      </Row>
      <Modal visible={modal} footer={null}>
        <div className="d-flex justify-content-between">
          {step === 1 && (
            <Pointer onClick={() => setStep(0)}>
              <IuxIcon icon={ArrowLeftIcon} color="#A0A0A0" />
            </Pointer>
          )}
          {step === 0 && <div />}
          <Pointer onClick={() => setModal(false)}>
            <IuxIcon icon={TimesIcon} color="#A0A0A0" />
          </Pointer>
        </div>
        {step === 0 ? (
          <>
            <h4 className="font-bold-iux text-center mb-4">
              Select your deposit
            </h4>
            <p>
              Easy to choose your deposit method Both services are available
              through your bank. and more convenient by scanning QR code.
            </p>
            <Row gutter={[8, 8]} className="mt-4">
              <Col
                span={12}
                onClick={() => {
                  setStep(1);
                }}
              >
                <div className="deposit-select-2">
                  <div className="auto-display d-flex flex-column h-100 justify-content-center">
                    <div>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/payment/qrcode_pay_online.png`}
                        alt="...."
                        width="100px"
                        height="100px"
                      />
                      <div class="badge-overlay">
                        <span class="top-right badge-custom gold">
                          MOST POPULAR
                        </span>
                      </div>
                    </div>
                    <div className="font-bold-iux mt-1">Pay with QR code</div>
                  </div>
                </div>
              </Col>
              <Col
                span={12}
                onClick={() => {
                  payOption !== undefined && window.open(payOption.web);
                }}
              >
                <div className="deposit-select-2">
                  <div className="auto-display d-flex flex-column h-100 justify-content-center">
                    <div>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/payment/kindpng_1200044.png`}
                        alt="...."
                        width="100px"
                        height="100px"
                      />
                    </div>
                    <div className="font-bold-iux mt-1">Pay with bank</div>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <div
              className="position-relative d-flex align-items-center mt-3"
              style={{
                backgroundImage:
                  "linear-gradient(to right top, #007ad0, #0087c9, #0090b3, #009494, #009674)",
                height: 70,
              }}
            >
              <div className="d-inline-block position-absolute">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/iux-logo.svg`}
                  alt="..."
                />
              </div>
              <div
                className="font-bold-iux text-center text-white w-100"
                style={{ fontSize: 30 }}
              >
                QR code
              </div>
            </div>
            {payOption !== undefined && (
              <>
                {!loadImage && (
                  <LoadingPanel>
                    <div className="m-auto">Loading</div>
                  </LoadingPanel>
                )}
                <div
                  className={
                    loadImage
                      ? "d-block w-100 text-center"
                      : "d-none w-100 text-center"
                  }
                >
                  <img
                    src={payOption.qrCode}
                    alt=".."
                    onLoad={() => {
                      setLoadImage(true);
                    }}
                  />
                </div>
                <BoxTop className="ml-1 mr-1 row">
                  <div className="col-4 font-bold-iux d-flex align-items-center mt-2">
                    Amount USD
                  </div>
                  <div
                    className="col-8 text-right font-bold-iux mt-2"
                    style={font}
                  >
                    {`${amountUsd} USD`}
                  </div>
                  <div className="col-4 font-bold-iux d-flex align-items-center mt-2">
                    Currency
                  </div>
                  <div
                    className="col-8 text-right font-bold-iux mt-2"
                    style={font}
                  >
                    {"VND"}
                  </div>
                  <div className="col-4 font-bold-iux d-flex align-items-center mt-2">
                    Convert
                  </div>
                  <div
                    className="col-8 text-right font-bold-iux mt-2"
                    style={font}
                  >
                    USD
                  </div>
                  <div className="w-100 mt-2" />
                </BoxTop>
                <BoxUnder className="ml-1 mr-1 pt-2 pb-2 row">
                  <div className="col-4 font-bold-iux mt-2 d-flex align-items-center">
                    Amount
                  </div>
                  <div
                    className="col-8 text-right font-bold-iux mt-2"
                    style={{ color: "#1CB894", fontSize: 20 }}
                  >{`${amount} VND`}</div>
                </BoxUnder>
              </>
            )}
          </>
        )}
      </Modal>
    </Spin>
  );
};

export default PayTrust;
