import React, { useEffect, useState } from "react";
import { StyledContent } from "../../components/content/Content";
import { Layout, Tabs } from "antd";
import Deposit from "./fund/Deposit";
import WithDraw from "./fund/WithDraw";
import Transaction from "./fund/Transaction";
import { connect } from "react-redux";
import VerifyBookBank from "./fund/verifies/VerifyBookBank";
import VerifyUser from "./fund/verifies/VerifyUser";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Link, Route, Switch } from "react-router-dom";

const LayoutMain = styled.div`
  background: #085a8c;
  color: #fff;
  border-radius: 2px;
  padding: 0px 2px;
  font-size: 12px;
  display: inline-block;
`;

const TITLE = "Transaction | IUX Markets";
const locale = require("react-redux-i18n").I18n;
const { TabPane } = Tabs;

const urlParams = new URLSearchParams(window.location.search);
const myParam = urlParams.get("transaction");
const MenuItem = styled.div`
  padding: 0.8rem 1rem;
  color: ${(props) => props.color};
  border-bottom: ${(props) => props.borderBottom};
  white-space: pre;
  font-weight: ${(props) => props.fontWeight};
`;

export const Fund = (props) => {
  const pathName = window.location.pathname;
  const [isIdex, setIndex] = React.useState(0);
  const menu = [
    {
      id: 0,
      link: `/${localStorage.getItem("locale")}/wallet/fund/deposit`,
      text: locale.t("dashboardFund.deposit"),
    },
    {
      id: 1,
      link: `/${localStorage.getItem("locale")}/wallet/fund/withdraw`,
      text: locale.t("dashboardFund.withdraw"),
    },
    {
      id: 2,
      link: `/${localStorage.getItem("locale")}/wallet/fund/transaction`,
      text: locale.t("dashboardFund.history"),
    },
  ];

  const [tabName, setTabName] = useState(props.location.search);
  //     var tabName = locale.t("dashboardFund.deposit");
  //     if (myParam !== undefined) {
  //       tabName =
  //         urlParams !== undefined ? myParam : locale.t("dashboardFund.deposit");
  //     }
  //     this.state = {
  //       tabName: props.location.search,
  //     };
  //   }

  //   componentWillReceiveProps(nextProps) {
  //     if (nextProps.location.search !== nextProps.location.search) {
  //       this.setState({ tabName: nextProps.location.search });
  //     }
  //   }
  // render() {
  useEffect(() => {
    setTabName(props.location.search);
  }, [props.location.search]);
  let urlParams = new URLSearchParams(window.location.search);
  let transaction = urlParams.get("account");

  return (
    <div style={{ position: "relative" }}>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="bg-white p-4" style={{ borderRadius: 20 }}>
        <p className="text-header-account-wallet">{locale.t("fund")}</p>
        <div className="sub-menu-page-user mt-4">
          <div className="d-flex overflow-auto scroll-menu-hide">
            {menu.map((e, i) => (
              <Link to={e.link}>
                <MenuItem
                  onClick={() => setIndex(i)}
                  color={
                    e.link === pathName
                      ? "#000"
                      : pathName ===
                          `/${localStorage.getItem("locale")}/wallet/fund` &&
                        e.id === 0
                      ? "#000"
                      : "#8D8D8D"
                  }
                  borderBottom={
                    e.link === pathName
                      ? "4px solid #1CB894"
                      : pathName ===
                          `/${localStorage.getItem("locale")}/wallet/fund` &&
                        e.id === 0
                      ? "4px solid #1CB894"
                      : "2px solid #ff000000"
                  }
                  fontWeight={
                    e.link === pathName
                      ? "unset"
                      : pathName ===
                          `/${localStorage.getItem("locale")}/wallet/fund` &&
                        e.id === 0
                      ? "unset"
                      : "unset"
                  }
                >
                  {e.text}
                </MenuItem>
              </Link>
            ))}
          </div>
        </div>
        {/* <Tabs
            defaultActiveKey={this.state.tabName}
            onChange={(key) => {
              this.setState({ tabName: key });
            }}
          >
            <TabPane
              tab={locale.t("dashboardFund.deposit")}
              key={`?transaction=deposit&account=${transaction}`}
            >
              <StyledContent>
                <Deposit />
              </StyledContent>
            </TabPane>
            <TabPane
              tab={locale.t("dashboardFund.withdraw")}
              key={`?transaction=withdraw&account=${transaction}`}
            >
              <StyledContent>
                {this.props.user.bookBankVerify.status === "accepted" ? (
                  <WithDraw />
                ) : (
                  <VerifyBookBank />
                )}
              </StyledContent>
            </TabPane>
            <TabPane
              tab={locale.t("dashboardFund.history")}
              key={locale.t("dashboardFund.history")}
            >
              <StyledContent>
                <Transaction />
              </StyledContent>
            </TabPane>
          </Tabs> */}
        <Switch>
          <Route
            path={`/:lang/wallet/fund/deposit`}
            render={() => {
              return props.user.userVerify.status === "accepted" ? (
                <Deposit />
              ) : (
                <VerifyUser />
              );
            }}
          />
          <Route
            path={`/:lang/wallet/fund/withDraw`}
            render={() => {
              return props.user.bookBankVerify.status === "accepted" ? (
                <WithDraw />
              ) : (
                <VerifyBookBank />
              );
            }}
          />
          <Route
            path={`/:lang/wallet/fund/transaction`}
            render={() => {
              return <Transaction />;
            }}
          />
          <Route
            path={`/:lang/wallet/fund`}
            render={() => {
              return props.user.userVerify.status === "accepted" ? (
                <Deposit />
              ) : (
                <VerifyUser />
              );
            }}
          />
        </Switch>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, i18n }) => {
  return { user: user, locale: i18n.locale };
};

export default connect(mapStateToProps, null)(Fund);
