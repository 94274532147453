export default {
  th: {
    btn_calculator: "คำนวน",
    calculator_select: "โปรดเลือก",
    help_cover_title: `ศูนย์ช่วยเหลือ`,
    help_cover_sub_title:
      "ศูนย์ช่วยเหลือ - ค้นหาคำตอบที่คุณต้องการโดยใช้ฐานความรู้เชิงลึกของเราหรือติดต่อฝ่ายสนับสนุนลูกค้าที่เป็นมิตรของเราตลอด 24 ชั่วโมงทุกวัน",
    help_page_title: "Most frequently asked questions",
    help_page_sub_title: `นี่คือคำถามที่พบบ่อยที่สุดที่เทรดเดอร์มักจะถามเราหรือพิจารณาที่จะถามก่อนเปิดบัญชีกับเรา สำหรับข้อมูลเพิ่มเติมเกี่ยวกับแหล่งความช่วยเหลือที่เกี่ยวข้องกับ IUX Markets และผลิตภัณฑ์ที่เรานำเสนอเพียงพิมพ์คำถามของคุณด้านล่างหรือไปที่บริการแชทสดของเรา`,
    fxcal_cover_title: `CFDs Trading Calculators`,
    fxcal_cover_sub_title: `CFDs Trading calculators – calculate current PIPs values in your account in offered products on IUX Markets`,
    fxcal_page_title: `เครื่องคำนวณCFDs`,
    economic: `ปฏิทินเศรษฐกิจ`,
    economic_sub_title: `ดูเหตุการณ์ข่าวเศรษฐกิจโลกที่จะเกิดขึ้นทั้งหมดและความสำคัญในปฏิทิน CFDs นี้`,
    payment_option: `ช่องทางการชำระเงิน`,
    economic_des: `Bookmark this page and refer to it regularly to stay up-to-date with key market-moving economic events and releases including: interest rate decisions, GDP announcements, NFP numbers, and more. If you want to keep track of upcoming, high impact news and data releases at a glance, our Economic Calendar is absolutely indispensable.งถึงหน้านี้เป็นประจำเพื่อติดตามข่าวสารล่าสุดเกี่ยวกับเหตุการณ์ทางเศรษฐกิจที่ขับเคลื่อนตลาดและข่าวประชาสัมพันธ์ ซึ่งรวมถึงการตัดสินใจเรื่องอัตราดอกเบี้ย การประกาศ GDP หมายเลข NFP และอื่นๆ หากคุณต้องการติดตามข่าวสารและข้อมูลที่กำลังจะเกิดขึ้นอย่างรวดเร็ว ปฏิทินเศรษฐกิจของเราเป็นสิ่งที่ขาดไม่ได้อย่างยิ่ง`,
    //
    fxcal_list_1: `All In One`,
    fxcal_list_1_box: {
      Platform: `แพลตฟอร์ม`,
      title_1: `สกุลเงินหลักของบัญชี`,
      title_2: `ปริมาณเป็นจำนวน Lot`,
      title_3: `สกุลเงิน`,
      title_4: `Swap Long`,
      title_5: `เลเวอเรจสำหรับมาร์จิ้น`,
      title_6: `Swap Short`,
      title_7: `ประเภทของบัญชี`,
      title_8: `ความผันผวนของราคาขั้นต่ำ`,
      title_9: `มาร์จิ้นที่ต้องใช้`,
      title_10: `Swap Long`,
      title_11: `มูลค่า PIPs`,
      title_12: `Swap Short`,
    },
    fxcal_list_1_box_how: {
      title_1: `แนะนำนักลงทุน`,
      title_2: `การคำนวณต้นทุนซื้อขายก่อนเปิดโพซิชั่นจะช่วยให้เรารู้โอกาสของกำไรขาดทุนที่จะเกิดขึ้นได้ `,
      title_3: `การซื้อขายของด้วย Contract Size 0.01 Lot แต่ละออร์เดอร์มีต้นทุนซึ่งเราเรียกสิ่งนี้ว่า “Margin Required” ที่แตกต่างเนื่องจากการเลือกใช้ Leverage ที่ไม่เท่ากัน Contract Size ที่แตกต่างกันและอัตราการแลกเปลี่ยนที่ไม่เท่ากัน`,
    },
    fxcal_list_1_box_how_2: {
      title_1: `IUX Markets`,
      title_2: `โบรกเกอร์ขึ้นชื่อด้าน Spread ที่แคบที่สุด เริ่มต้นที่ 0.1 PIPs เนื่องจากเป็นโบรกเกอร์ส่งคำสั่งตรงเข้าสู่ตลาดและ Liquidity Provider ชั้นนำ`,
    },
    // 222222222
    fxcal_list_2: `Currency`,
    fxcal_list_2_box: {
      title_1: `จาก`,
      title_2: `ไปเป็น`,
      title_3: `จำนวนเงิน`,
      title_4: `อัตราแลกเปลี่ยนปัจจุบัน`,
      title_5: `ผลรวม`,
    },
    fxcal_list_2_box_how: {
      title_1: `มันทำงานอย่างไร`,
      title_2: `เครื่องแปลงค่าเงินของเราจะช่วยให้ท่านสามารถแปลงค่าเงินของสกุลเงินต่าง ๆ ได้ โดยใช้อัตราแลกเปลี่ยนปัจจุบัน`,
      title_3: `อันดับแรกให้กรอกสกุลเงินที่ท่านต้องการนำมาแปลงและสกุลเงินผลลัพธ์ที่ท่านต้องการ หลังจากนั้นให้กรอกจำนวนเงินที่ท่านต้องการแปลงและคลิกที่ปุ่มคำนวณ`,
    },
    fxcal_list_2_box_how_2: {
      title_1: `ท่านทราบหรือไม่?`,
      title_2: `ถึงแม้ว่าตลาดCFDsจะเปิดตลอด 24 ชั่วโมงในแต่ละวัน แต่ตลาดนี้ไม่ได้มีความเคลื่อนไหวอยู่ตลอดเวลา`,
      title_3: `แล้วชั่วโมงไหนที่เหมาะสำหรับทำการเทรด? ช่วงเวลาการเปิดและปิดตลาดCFDsจะขึ้นอยู่กับช่วงเวลาการเทรดของตลาดหลัก 4 แห่ง ซึ่งก็คือซิดนีย์ โตเกียว ลอนดอน นิวยอร์ก ตลาดเหล่านี้มีช่วงเวลาการเปิดและปิดตลาดที่ได้มีการกำหนดไว้ซึ่งจะช่วยให้ท่านสามารถตัดสินใจได้ว่าควรทำการเทรดที่เวลาใด`,
    },
    // 333333333
    fxcal_list_3: `PIPs value`,
    fxcal_list_3_box: {
      title_1: `คู่สกุลเงิน`,
      title_2: `ปริมาณเป็นจำนวน Lot`,
      title_3: `สกุลเงินหลักของบัญชี`,
      title_4: `อัตราแลกเปลี่ยนปัจจุบัน`,
      title_5: `ประเภทของบัญชี`,
      title_6: `สกุลเงินหลักของบัญชี`,
      title_6_1: {
        title: "มูลค่า PIPs",
      },
      title_7: `สกุลเงินที่ได้รับการแปลง`,
      title_7_1: {
        title: "มูลค่า PIPs",
      },
    },
    fxcal_list_3_box_how: {
      title_1: `มันทำงานอย่างไร`,
      title_2: `เครื่องคำนวณค่า PIPs ของเราจะช่วยให้ท่านสามารถหามูลค่าต่อหนึ่ง PIPs ของสกุลเงินหลักของท่านเพื่อที่ท่านจะสามารถติดตามความเสี่ยงที่เกิดขึ้นจากการเทรดได้แม่นยำยิ่งขึ้น`,
      title_3: `ท่านต้องใช้เพียงแค่สกุลเงินหลักของท่าน คู่สกุลเงินที่ท่านเทรด อัตราแลกเปลี่ยน และขนาดของโพซิชั่นเพื่อทำการคำนวณมูลค่าของหนึ่ง PIPs`,
    },
    fxcal_list_3_box_how_2: {
      title_1: `การคำนวณจะเป็นดังนี้:`,
      title_2: `ถึงแม้ว่าตลาดCFDsจะเปิดตลอด 24 ชั่วโมงในแต่ละวัน แต่ตลาดนี้ไม่ได้มีความเคลื่อนไหวอยู่ตลอดเวลา`,
      title_3: `มูลค่า PIPs = (1 PIPs / อัตราแลกเปลี่ยน) * ขนาดของ Lot\nตัวอย่าง:\n1 PIPs: 0.0001\nสกุลเงินหลักของบัญชี: EUR\nคู่สกุลเงิน: EUR/USD\nอัตราแลกเปลี่ยน: 1.08962 (EUR/USD)\nขนาดของ Lot: 1 Lot (100000 EUR)\nมูลค่า PIPs = 0.0001 / 1.08962 * 100000\nแต่ละ PIPs มีมูลค่า €9.18`,
    },
    // 44444444
    fxcal_list_4: `Margin`,
    fxcal_list_4_box: {
      title_1: `สกุลเงินหลักของบัญชี`,
      title_2: `ปริมาณเป็นจำนวน Lot`,
      title_3: `คู่สกุลเงิน`,
      title_4: `เลเวอเรจ`,
      title_5: `ประเภทของบัญชี`,
      title_6: `อัตราแลกเปลี่ยนปัจจุบัน`,
      title_7: `มาร์จิ้นที่ต้องใช้`,
      title_8: `สกุลเงินหลักของบัญชี`,
      title_9: `สกุลเงินที่ได้รับการแปลง`,
    },
    fxcal_list_4_box_how: {
      title_1: `มันทำงานอย่างไร`,
      title_2: `เครื่องคำนวณมาร์จิ้นของเราจะช่วยให้ท่านสามารถคำนวณค่ามาร์จิ้นที่จะต้องใช้เพื่อเปิดและรักษาโพซิชั่นได้`,
      title_3: `กรอกสกุลเงินหลักของท่าน เลือกคู่สกุลเงินและเลเวอเรจ และกรอกโพซิชั่นของท่านเป็นจำนวน lot`,
    },
    fxcal_list_4_box_how_2: {
      title_1: `การคำนวณจะเป็นดังนี้:`,
      title_2: `มาร์จิ้นที่ต้องใช้ = ปริมาณการเทรด / เลเวอเรจ * อัตราแลกเปลี่ยนสกุลเงินของบัญชี`,
      title_3: `ตัวอย่าง:\nปริมาณเป็นจำนวน Lot: 5 (หนึ่ง Standard Lot = 100,000 หน่วย)\nเลเวอเรจ: 100\nสกุลเงินหลักของบัญชี: USD\nคู่สกุลเงิน: EUR/USD\nอัตราแลกเปลี่ยน: 1.365 (EUR/USD)\nมาร์จิ้นที่ต้องใช้ = 500,000 /100 * 1.365\nมาร์จิ้นที่ต้องใช้ $6825.00 USD`,
    },
    // 55555555
    fxcal_list_5: `Swap`,
    fxcal_list_5_box: {
      title_1: `สกุลเงินหลักของบัญชี`,
      title_2: `Swap Long`,
      title_3: `คู่สกุลเงิน`,
      title_4: `Swap Short`,
      title_5: `ประเภทของบัญชี`,
      title_6: `อัตราแลกเปลี่ยนปัจจุบัน`,
      title_7: `ปริมาณเป็นจำนวน Lot`,

      title_8: `สกุลเงินหลักของบัญชี`,
      title_9: `สกุลเงินที่ได้รับการแปลง`,

      title_10: `Swap Long`,
      title_11: `Swap Short`,
    },
    // 666666
    fxcal_list_6: `Profit and loss`,
    fxcal_page_6_title: `มูลค่าของ Stop loss / Take Profit`,
    fxcal_list_6_box: {
      title_1: `สกุลเงินหลักของบัญชี`,
      title_2: `ราคาเปิด`,
      title_3: `คู่สกุลเงิน`,
      title_4: `ตั้งค่า Stop Loss`,
      title_5: `ประเภทของบัญชี`,
      title_6: `ตั้งค่า Take Profit`,
      title_7: `ปริมาณเป็นจำนวน Lot`,
      title_8: `ประเภทของการเทรด`,

      title_9: `Stop Loss ที่`,
      title_10: `Stop Loss, PIPs`,
      title_11: `Take Profit ที่`,
      title_12: `Take Profit, PIPs`,
      title_13: `มูลค่า PIPs`,
    },
    // 7777777
    fxcal_page_7_title: `ระดับ Stop loss / Take Profit`,
    fxcal_list_7_box: {
      title_1: `สกุลเงินหลักของบัญชี`,
      title_2: `ปริมาณเป็นจำนวน Lot`,
      title_3: `คู่สกุลเงิน`,
      title_4: `ตั้งค่า Stop Loss`,
      title_5: `ประเภทของบัญชี`,
      title_6: `ตั้งค่า Take Profit`,
      title_7: `ผลขาดทุน`,
      title_8: `ผลกำไร`,
    },
  },
  // lolololololololololololololololololololololololololololololololololololo
  la: {
    btn_calculator: "ຄິດໄລ່",
    calculator_select: "ກະ​ລຸ​ນາ​ເລືອກ",
    help_cover_title: "Help Center",
    help_cover_sub_title:
      "Help Center – Find the answers you’re looking for using our in-depth knowledgebase or contact our friendly customer support 24/7",
    help_page_title: "Most frequently asked questions",
    help_page_sub_title: `Here are the most common questions a trader would normally ask us or consider to ask before opening an account with us.
    For more information on help resources related to IUX Markets and the products we offer, simply type your question below or visit our live chat service.`,
    fxcal_cover_title: `CFDs Trading Calculators`,
    fxcal_cover_sub_title: `CFDs Trading calculators – calculate current PIPs values in your account in offered products on IUX Markets`,
    fxcal_page_title: `CFDs Trading Calculators`,
    economic: `Economic calendar`,
    economic_sub_title: `View all upcoming global economic news events and their importance in this CFDs calendar.`,
    payment_option: `ທາງເລືອກໃນການຈ່າຍເງິນ`,
    economic_des: `Bookmark this page and refer to it regularly to stay up-to-date with key market-moving economic events and releases including: interest rate decisions, GDP announcements, NFP numbers, and more. If you want to keep track of upcoming, high impact news and data releases at a glance, our Economic Calendar is absolutely indispensable.`,
    // 1111111
    fxcal_list_1: `All In One`,
    fxcal_list_1_box: {
      Platform: `Platform`,
      title_1: `ບັນຊີເງິນຕາພື້ນຖານ`,
      title_2: `ປະລິມານແມ່ນຈຳນວນ Lot`,
      title_3: `ເງິນຕາ`,
      title_4: `Swap Long`,
      title_5: `Leverage ສຳ ລັບ Margin`,
      title_6: `Swap Short`,
      title_7: `ປະເພດບັນຊີ`,
      title_8: `ການເຫນັງຕີງຂອງລາຄາຕໍ່າສຸດ`,
      title_9: `Margin ແມ່ນຕ້ອງໃຊ້`,
      title_10: `Swap Long`,
      title_11: `ມູນຄ່າ PIPs`,
      title_12: `Swap Short`,
    },
    fxcal_list_1_box_how: {
      title_1: `ແນະນຳນັກລົງທືນ`,
      title_2: `ການຄິດໄລ່ຕົ້ນທຶນຂອງການຊື້ຂາຍກ່ອນທີ່ຈະເປີດຕຳແໜ່ງ ຈະຊ່ວຍໃຫ້ພວກເຮົາກຳນົດໂອກາດກຳໄລແລະການສູນເສຍທີ່ອາດເກີດຂື້ນ`,
      title_3: `ການຊື້ຂາຍຂອງດ້ວຍ Contract Size 0.01 Lot ແຕ່ລະ ອອເດ້ ມີຄ່າໃຊ້ຈ່າຍທີ່ພວກເຮົາເອີ້ນວ່າ “Margin Required” ນັ້ນແມ່ນແຕກຕ່າງກັນຍ້ອນການເລືອກ Leverage ນັ້ນບໍ່ເທົ່າກັນ Contract Size ແຕກຕ່າງກັນແລະອັດຕາແລກປ່ຽນບໍ່ຄືກັນ`,
    },
    fxcal_list_1_box_how_2: {
      title_1: `IUX Markets`,
      title_2: `Broker ທີ່ມີຊື່ສຽງເລື່ອງ Spread ແຄບທີ່ສຸດ ເລີ່ມຕົ້ນທີ່ 0.1 PIPs ເນື່ອງຈາກວ່າມັນແມ່ນ Broker ທີ່ສົ່ງຄຳສັ່ງໄປຕະຫລາດແລະ Liquidity Provider ແຖວໜ້າ`,
    },
    // 22222222
    fxcal_list_2: `Currency`,
    fxcal_list_2_box: {
      title_1: `ຈາກ`,
      title_2: `ເພື່ອຈະ`,
      title_3: `ຈໍາ​ນວນ`,
      title_4: `ອັດຕາແລກປ່ຽນໃນປະຈຸບັນ`,
      title_5: `ລວມ`,
    },
    // 333333
    fxcal_list_3: `PIPs value`,
    fxcal_list_3_box: {
      title_1: `ຄູ່ສະກຸນເງິນ`,
      title_2: `ຈຳນວນແມ່ນຈຳນວນ Lot`,
      title_3: `ບັນຊີເງິນຕາພື້ນຖານ`,
      title_4: `ອັດຕາແລກປ່ຽນໃນປະຈຸບັນ`,
      title_5: `ປະເພດບັນຊີ`,
      title_6: `ບັນຊີເງິນຕາພື້ນຖານ`,
      title_6_1: {
        title: "ມູນຄ່າ PIPs",
      },
      title_7: `ສະກຸນເງິນແມ່ນໄດ້ຮັບການປ່ຽນ`,
      title_7_1: {
        title: "ມູນຄ່າ PIPs",
      },
    },
    // 4444444
    fxcal_list_4: `Margin`,
    fxcal_list_4_box: {
      title_1: `ບັນຊີເງິນຕາພື້ນຖານ`,
      title_2: `ປະລິມານແມ່ນ ຈຳ ນວນ Lot`,
      title_3: `ຄູ່ສະກຸນເງິນ`,
      title_4: `Leverage`,
      title_5: `ປະເພດບັນຊີ`,
      title_6: `ອັດຕາແລກປ່ຽນໃນປະຈຸບັນ`,
      title_7: `Marginຕ້ອງການ`,
      title_8: `base currency ບັນຊີ`,
      title_9: `ປ່ຽນສະກຸນເງິນ`,
    },
    // 555555
    fxcal_list_5: `Swap`,
    fxcal_list_5_box: {
      title_1: `ບັນຊີເງິນຕາພື້ນຖານ`,
      title_2: `Swap Long`,
      title_3: `ຄູ່ສະກຸນເງິນ`,
      title_4: `Swap Short`,
      title_5: `ປະເພດບັນຊີ`,
      title_6: `ອັດຕາແລກປ່ຽນໃນປະຈຸບັນ`,
      title_7: `ປະລິມານແມ່ນຈຳນວນ Lot`,

      title_8: `ບັນຊີເງິນຕາພື້ນຖານ`,
      title_9: `ປ່ຽນສະກຸນເງິນ`,

      title_10: `Swap Long`,
      title_11: `Swap Short`,
    },
    // 6666666
    fxcal_list_6: `Profit and loss`,
    fxcal_page_6_title: `ຄຸນຄ່າຂອງ Stop loss / Take Profit`,
    fxcal_list_6_box: {
      title_1: `base currency ບັນຊີ`,
      title_2: `ລາຄາເປີດ`,
      title_3: `ຄູ່ສະກຸນເງິນ`,
      title_4: `ຕັ້ງ​ຄ່າ Stop Loss`,
      title_5: `ປະເພດບັນຊີ`,
      title_6: `ຕັ້ງ​ຄ່າ Take Profit`,
      title_7: `ປະລິມານແມ່ນ ຈຳ ນວນ Lot`,
      title_8: `ປະເພດການຄ້າ`,

      title_9: `Stop Loss ທີ່`,
      title_10: `Stop Loss, PIPs`,
      title_11: `Take Profit ທີ່`,
      title_12: `Take Profit, PIPs`,
      title_13: `ມູນຄ່າ PIPs`,
    },
    // 77777777
    fxcal_page_7_title: `ລະດັບ Stop loss / Take Profit`,
    fxcal_list_7_box: {
      title_1: `base currency ບັນຊີ`,
      title_2: `ຈຳ ນວນແມ່ນ ຈຳ ນວນ Lot`,
      title_3: `ຄູ່ສະກຸນເງິນ`,
      title_4: `ຕັ້ງ​ຄ່າ Stop Loss`,
      title_5: `ປະເພດບັນຊີ`,
      title_6: `ຕັ້ງ​ຄ່າ Take Profit`,
      title_7: `ການສູນເສຍ`,
      title_8: "ກຳ ໄລ",
    },
  },
  // ==================
  en: {
    btn_calculator: "CALCULATE",
    calculator_select: "Please select",
    help_cover_title: "Help Center",
    help_cover_sub_title:
      "Help Center – Find the answers you’re looking for using our in-depth knowledgebase or contact our friendly customer support 24/7",
    help_page_title: "Most frequently asked questions",
    help_page_sub_title: `Here are the most common questions a trader would normally ask us or consider to ask before opening an account with us.
    For more information on help resources related to IUX Markets and the products we offer, simply type your question below or visit our live chat service.`,
    fxcal_cover_title: `CFDs Trading Calculators`,
    fxcal_cover_sub_title: `CFDs Trading calculators – calculate current PIPs values in your account in offered products on IUX Markets`,
    fxcal_page_title: `CFDs Trading Calculators`,
    economic: `Economic calendar`,
    economic_sub_title: `View all upcoming global economic news events and their importance in this CFDs calendar.`,
    payment_option: `Payment option`,
    economic_des: `Bookmark this page and refer to it regularly to stay up-to-date with key market-moving economic events and releases including: interest rate decisions, GDP announcements, NFP numbers, and more. If you want to keep track of upcoming, high impact news and data releases at a glance, our Economic Calendar is absolutely indispensable.`,
    // 11111111
    fxcal_list_1: `All In One`,
    fxcal_list_1_box: {
      Platform: `Platform`,
      title_1: `Account Base Currency`,
      title_2: `Volume in Lots`,
      title_3: `Currency Pair`,
      title_4: `Swap Long`,
      title_5: `Leverage for Margin`,
      title_6: `Swap Short`,
      title_7: `Account Type`,
      title_8: `Price Fluctuation`,
      title_9: `Required Margin`,
      title_10: `Swap Long`,
      title_11: `PIPs Value`,
      title_12: `Swap Short`,
    },
    fxcal_list_1_box_how: {
      title_1: `Recommendation for investors`,
      title_2: `Calculating the cost of trading before opening a position will help you determine the potential profit and loss opportunities.`,
      title_3: `Trading with Contract Size 0.01 Lot. Each order has a cost which we call this “Margin Required” which is different due to the different leverage selection, different contract size and different exchange rate.`,
    },
    fxcal_list_1_box_how_2: {
      title_1: `IUX Markets`,
      title_2: `Well known CFDs broker for its low spread and fast execution. `,
    },
    // 2222222
    fxcal_list_2: `Currency`,
    fxcal_list_2_box: {
      title_1: `From`,
      title_2: `To`,
      title_3: `Amount`,
      title_4: `Current Conversion Rate`,
      title_5: `Total`,
    },
    // 33333333
    fxcal_list_3: `PIPs value`,
    fxcal_list_3_box: {
      title_1: `Currency Pair`,
      title_2: `Volume in Lots`,
      title_3: `Account Base Currency`,
      title_4: `Current Conversion Price`,
      title_5: `Account Type`,
      title_6: `Account Base Currency`,
      title_6_1: {
        title: "PIPs Value ",
      },
      title_7: `Converted Currency`,
      title_7_1: {
        title: "PIPs Value ",
      },
    },
    // 444444444
    fxcal_list_4: `Margin`,
    fxcal_list_4_box: {
      title_1: `Account Base Currency`,
      title_2: `Volume in Lots`,
      title_3: `Currency Pair`,
      title_4: `Leverage`,
      title_5: `Account Type`,
      title_6: `Current Conversion Price`,
      title_7: `Required Margin`,
      title_8: `Account Base Currency`,
      title_9: `Converted Currency`,
    },
    // 555555555
    fxcal_list_5: `Swap`,
    fxcal_list_5_box: {
      title_1: `Account Base Currency`,
      title_2: `Swap Long`,
      title_3: `Currency Pair`,
      title_4: `Swap Short`,
      title_5: `Account Type`,
      title_6: `Current Conversion Price`,
      title_7: `Volume in Lots`,

      title_8: `Account Base Currency`,
      title_9: `Converted Currency`,

      title_10: `Swap Long`,
      title_11: `Swap Short`,
    },
    // 6666666666
    fxcal_list_6: `Profit and loss`,
    fxcal_page_6_title: `Stop loss / Take Profit value`,
    fxcal_list_6_box: {
      title_1: `Account Base Currency`,
      title_2: `Open Price`,
      title_3: `Currency Pair`,
      title_4: `Set Stop Loss Amount`,
      title_5: `Account Type`,
      title_6: `Set Take Profit Amount`,
      title_7: `Volume in Lots`,
      title_8: `Trade Type`,

      title_9: `Stop Loss at`,
      title_10: `Stop Loss, PIPs`,
      title_11: `Take Profit at`,
      title_12: `Take Profit, PIPs`,
      title_13: `PIPs Value `,
    },
    // 7777777777
    fxcal_page_7_title: `Stop loss / Take Profit levels`,
    fxcal_list_7_box: {
      title_1: `Account Base Currency`,
      title_2: `Volume in Lots`,
      title_3: `Currency Pair`,
      title_4: `Set Stop Loss at (points)`,
      title_5: `Account Type`,
      title_6: `Set Take Profit at (points)`,
      title_7: `Your Loss`,
      title_8: `Your Profit`,
    },
  },
  in: {
    btn_calculator: "CALCULATE",
    calculator_select: "Please select",
    help_cover_title: "Help Center",
    help_cover_sub_title:
      "Help Center – Find the answers you’re looking for using our in-depth knowledgebase or contact our friendly customer support 24/7",
    help_page_title: "Most frequently asked questions",
    help_page_sub_title: `Here are the most common questions a trader would normally ask us or consider to ask before opening an account with us.
    For more information on help resources related to IUX Markets and the products we offer, simply type your question below or visit our live chat service.`,
    fxcal_cover_title: `CFDs Trading Calculators`,
    fxcal_cover_sub_title: `CFDs Trading calculators – calculate current PIPs values in your account in offered products on IUX Markets`,
    fxcal_page_title: `CFDs Trading Calculators`,
    economic: `Economic calendar`,
    economic_sub_title: `View all upcoming global economic news events and their importance in this CFDs calendar.`,
    payment_option: `Payment option`,
    economic_des: `Bookmark this page and refer to it regularly to stay up-to-date with key market-moving economic events and releases including: interest rate decisions, GDP announcements, NFP numbers, and more. If you want to keep track of upcoming, high impact news and data releases at a glance, our Economic Calendar is absolutely indispensable.`,
    // 11111111
    fxcal_list_1: `All In One`,
    fxcal_list_1_box: {
      Platform: `Platform`,
      title_1: `Account Base Currency`,
      title_2: `Volume in Lots`,
      title_3: `Currency Pair`,
      title_4: `Swap Long`,
      title_5: `Leverage for Margin`,
      title_6: `Swap Short`,
      title_7: `Account Type`,
      title_8: `Price Fluctuation`,
      title_9: `Required Margin`,
      title_10: `Swap Long`,
      title_11: `PIPs Value`,
      title_12: `Swap Short`,
    },
    fxcal_list_1_box_how: {
      title_1: `Recommendation for investors`,
      title_2: `Calculating the cost of trading before opening a position will help you determine the potential profit and loss opportunities.`,
      title_3: `Trading with Contract Size 0.01 Lot. Each order has a cost which we call this “Margin Required” which is different due to the different leverage selection, different contract size and different exchange rate.`,
    },
    fxcal_list_1_box_how_2: {
      title_1: `IUX Markets`,
      title_2: `Well known CFDs broker for its low spread and fast execution. `,
    },
    // 2222222
    fxcal_list_2: `Currency`,
    fxcal_list_2_box: {
      title_1: `From`,
      title_2: `To`,
      title_3: `Amount`,
      title_4: `Current Conversion Rate`,
      title_5: `Total`,
    },
    // 33333333
    fxcal_list_3: `PIPs value`,
    fxcal_list_3_box: {
      title_1: `Currency Pair`,
      title_2: `Volume in Lots`,
      title_3: `Account Base Currency`,
      title_4: `Current Conversion Price`,
      title_5: `Account Type`,
      title_6: `Account Base Currency`,
      title_6_1: {
        title: "PIPs Value ",
      },
      title_7: `Converted Currency`,
      title_7_1: {
        title: "PIPs Value ",
      },
    },
    // 444444444
    fxcal_list_4: `Margin`,
    fxcal_list_4_box: {
      title_1: `Account Base Currency`,
      title_2: `Volume in Lots`,
      title_3: `Currency Pair`,
      title_4: `Leverage`,
      title_5: `Account Type`,
      title_6: `Current Conversion Price`,
      title_7: `Required Margin`,
      title_8: `Account Base Currency`,
      title_9: `Converted Currency`,
    },
    // 555555555
    fxcal_list_5: `Swap`,
    fxcal_list_5_box: {
      title_1: `Account Base Currency`,
      title_2: `Swap Long`,
      title_3: `Currency Pair`,
      title_4: `Swap Short`,
      title_5: `Account Type`,
      title_6: `Current Conversion Price`,
      title_7: `Volume in Lots`,

      title_8: `Account Base Currency`,
      title_9: `Converted Currency`,

      title_10: `Swap Long`,
      title_11: `Swap Short`,
    },
    // 6666666666
    fxcal_list_6: `Profit and loss`,
    fxcal_page_6_title: `Stop loss / Take Profit value`,
    fxcal_list_6_box: {
      title_1: `Account Base Currency`,
      title_2: `Open Price`,
      title_3: `Currency Pair`,
      title_4: `Set Stop Loss Amount`,
      title_5: `Account Type`,
      title_6: `Set Take Profit Amount`,
      title_7: `Volume in Lots`,
      title_8: `Trade Type`,

      title_9: `Stop Loss at`,
      title_10: `Stop Loss, PIPs`,
      title_11: `Take Profit at`,
      title_12: `Take Profit, PIPs`,
      title_13: `PIPs Value `,
    },
    // 7777777777
    fxcal_page_7_title: `Stop loss / Take Profit levels`,
    fxcal_list_7_box: {
      title_1: `Account Base Currency`,
      title_2: `Volume in Lots`,
      title_3: `Currency Pair`,
      title_4: `Set Stop Loss at (points)`,
      title_5: `Account Type`,
      title_6: `Set Take Profit at (points)`,
      title_7: `Your Loss`,
      title_8: `Your Profit`,
    },
  },
  my: {
    btn_calculator: "PENGIRAAN",
    calculator_select: "Sila pilih",
    help_cover_title: "Pusat Bantuan",
    help_cover_sub_title:
      "Pusat Bantuan – Cari jawapan yang anda mahu menggunakan pangkalan pengetahuan kami yang mendalam atau hubungi sokongan pelanggan mesra kami selama 24 jam setiap hari seminggu",
    help_page_title: "Pertanyaan Lazim",
    help_page_sub_title: `Berikut ialah soalan lazim yang biasanya ditanya oleh pedagang kepada kami atau pertimbangkan untuk ditanya sebelum membuka akaun dengan kami.
    Untuk mendapatkan maklumat lanjut tentang sumber bantuan yang berkaitan dengan IUX Markets dan produk yang kami tawarkan, cuma taip soalan anda di bawah atau lawati perkhidmatan live chat kami`,
    fxcal_cover_title: `Kalkulator Dagangan CFDs`,
    fxcal_cover_sub_title: `Kalkulator dagangan CFDs - Kira nilai PIPs semasa dalam mata wang akaun anda merentas rangkaian produk IUX Markets.`,
    fxcal_page_title: `Kalkulator CFDs`,
    economic: `Kalendar ekonomi`,
    economic_sub_title: `Lihat semua acara berita ekonomi global yang akan datang dan kepentingannya dalam kalendar CFDs ini.`,
    payment_option: `Pilihan pembayaran`,
    economic_des: `Tandai halaman ini dan rujuk padanya dengan kerap untuk mengikuti perkembangan peristiwa penting dan keluaran ekonomi yang menggerakkan pasaran termasuk: keputusan kadar faedah, pengumuman KDNK, nombor NFP dan banyak lagi. Jika anda ingin menjejaki berita dan keluaran data berimpak tinggi yang akan datang sekilas pandang, Kalendar Ekonomi kami amat diperlukan.`,
    // 11111111
    fxcal_list_1: `All In One`,
    fxcal_list_1_box: {
      Platform: `Platform`,
      title_1: `Mata Wang Asas Akaun`,
      title_2: `Nilai dalam Lot`,
      title_3: `Pasangan Mata Wang`,
      title_4: `Swap Long`,
      title_5: `Leverage untuk Margin`,
      title_6: `Swap Short`,
      title_7: `Jenis Akaun`,
      title_8: `Turun Naik Harga`,
      title_9: `Margin yang diperlukan`,
      title_10: `Swap Long`,
      title_11: `Nilai PIPs `,
      title_12: `Swap Short`,
    },
    fxcal_list_1_box_how: {
      title_1: `Cadangan untuk pelabur`,
      title_2: `Mengira kos dagangan sebelum membuka kedudukan akan membantu anda menentukan potensi peluang untung dan rugi.`,
      title_3: `Berdagang dengan Saiz Kontrak 0.01 Lot. Setiap pesanan mempunyai kos yang kami panggil "Margin Diperlukan" yang berbeza disebabkan oleh pemilihan leverage yang berbeza, saiz kontrak yang berbeza dan kadar pertukaran yang berbeza..`,
    },
    fxcal_list_1_box_how_2: {
      title_1: `IUX Markets`,
      title_2: `Broker dengan reputasi untuk spread rendah bermula dari 0.1 PIPs kerana ia merupakan broker pelaksanaan langsung terkemuka dan Pembekal Kecairan.`,
    },
    // 2222222
    fxcal_list_2: `Mata wang`,
    fxcal_list_2_box: {
      title_1: `Daripada`,
      title_2: `Kepada`,
      title_3: `Jumlah`,
      title_4: `Kadar Penukaran Semasa`,
      title_5: `Total`,
    },
    // 33333333
    fxcal_list_3: `Nilai PIPs `,
    fxcal_list_3_box: {
      title_1: `Pasangan mata wang`,
      title_2: `Nilai dalam Lots`,
      title_3: `Mata Wang Asas Akaun`,
      title_4: `Kadar Penukaran Semasa`,
      title_5: `Jenis Akaun`,
      title_6: `Mata Wang Asas Akaun`,
      title_6_1: {
        title: " Nilai PIPs ",
      },
      title_7: `Mata Wang yang Ditukar`,
      title_7_1: {
        title: "Nilai PIPs ",
      },
    },
    // 444444444
    fxcal_list_4: `Margin`,
    fxcal_list_4_box: {
      title_1: `Mata Wang Asas Akaun`,
      title_2: `Nilai dalam Lots`,
      title_3: `Pasangan mata wang`,
      title_4: `Leverage`,
      title_5: `Jenis Akaun`,
      title_6: `Kadar Penukaran Semasa`,
      title_7: `Margin yang diperlukan`,
      title_8: `Mata Wang Asas Akaun`,
      title_9: `Mata Wang yang Ditukar`,
    },
    // 555555555
    fxcal_list_5: `Swap`,
    fxcal_list_5_box: {
      title_1: `Mata Wang Asas Akaun`,
      title_2: `Swap Long`,
      title_3: `Pasangan mata wang`,
      title_4: `Swap Short`,
      title_5: `Jenis Akaun`,
      title_6: `Kadar Penukaran Semasa`,
      title_7: `Niali dalam Lots`,

      title_8: `Mata Wang Asas Akaun`,
      title_9: `Mata Wang yang Ditukar`,

      title_10: `Swap Long`,
      title_11: `Swap Short`,
    },
    // 6666666666
    fxcal_list_6: `Untung dan Rugi`,
    fxcal_page_6_title: `Henti kerugian / Ambil Nilai Untung`,
    fxcal_list_6_box: {
      title_1: `Mata Wang Asas Akaun`,
      title_2: `Harga Pembukaann`,
      title_3: `Pasangan mata wang`,
      title_4: `Tetapkan Jumlah Henti Kerugian`,
      title_5: `Jenis Akaun`,
      title_6: `Tetapkan Jumlah Ambil Untung`,
      title_7: `Nilai dalam Lots`,
      title_8: `Jenis dagangan`,

      title_9: `Hentikan Kerugian pada`,
      title_10: `Hentikan Kerugian, PIPs`,
      title_11: `Ambil Untung pada`,
      title_12: `Ambil Untung, PIPs`,
      title_13: `Nilai PIPs `,
    },
    // 7777777777
    fxcal_page_7_title: `Tahap Henti kerugian / Ambil Untung`,
    fxcal_list_7_box: {
      title_1: `Mata Wang Asas Akaun`,
      title_2: `Nilai dalam Lots`,
      title_3: `Pasangan mata wang`,
      title_4: `Tetapkan Jumlah Henti Kerugian pada (poin)`,
      title_5: `Jenis Akaun`,
      title_6: `Tetapkan Jumlah Ambil Untung pada (poin)`,
      title_7: `Jumlah Untung anda`,
      title_8: `Jumlah Rugi anda `,
    },
  },
  id: {
    btn_calculator: "CALCULATE",
    calculator_select: "Please select",
    help_cover_title: "Help Center",
    help_cover_sub_title:
      "Help Center – Find the answers you’re looking for using our in-depth knowledgebase or contact our friendly customer support 24/7",
    help_page_title: "Most frequently asked questions",
    help_page_sub_title: `Here are the most common questions a trader would normally ask us or consider to ask before opening an account with us.
    For more information on help resources related to IUX Markets and the products we offer, simply type your question below or visit our live chat service.`,
    fxcal_cover_title: `CFDs Trading Calculators`,
    fxcal_cover_sub_title: `CFDs Trading calculators – calculate current PIPs values in your account in offered products on IUX Markets`,
    fxcal_page_title: `CFDs Trading Calculators`,
    economic: `Economic calendar`,
    economic_sub_title: `View all upcoming global economic news events and their importance in this CFDs calendar.`,
    payment_option: `Payment option`,
    economic_des: `Bookmark this page and refer to it regularly to stay up-to-date with key market-moving economic events and releases including: interest rate decisions, GDP announcements, NFP numbers, and more. If you want to keep track of upcoming, high impact news and data releases at a glance, our Economic Calendar is absolutely indispensable.`,
    // 11111111
    fxcal_list_1: `All In One`,
    fxcal_list_1_box: {
      Platform: `Platform`,
      title_1: `Account Base Currency`,
      title_2: `Volume in Lots`,
      title_3: `Currency Pair`,
      title_4: `Swap Long`,
      title_5: `Leverage for Margin`,
      title_6: `Swap Short`,
      title_7: `Account Type`,
      title_8: `Price Fluctuation`,
      title_9: `Required Margin`,
      title_10: `Swap Long`,
      title_11: `PIPs Value`,
      title_12: `Swap Short`,
    },
    fxcal_list_1_box_how: {
      title_1: `Recommendation for investors`,
      title_2: `Calculating the cost of trading before opening a position will help you determine the potential profit and loss opportunities.`,
      title_3: `Trading with Contract Size 0.01 Lot. Each order has a cost which we call this “Margin Required” which is different due to the different leverage selection, different contract size and different exchange rate.`,
    },
    fxcal_list_1_box_how_2: {
      title_1: `IUX Markets`,
      title_2: `Well known CFDs broker for its low spread and fast execution. `,
    },
    // 2222222
    fxcal_list_2: `Currency`,
    fxcal_list_2_box: {
      title_1: `From`,
      title_2: `To`,
      title_3: `Amount`,
      title_4: `Current Conversion Rate`,
      title_5: `Total`,
    },
    // 33333333
    fxcal_list_3: `PIPs value`,
    fxcal_list_3_box: {
      title_1: `Currency Pair`,
      title_2: `Volume in Lots`,
      title_3: `Account Base Currency`,
      title_4: `Current Conversion Price`,
      title_5: `Account Type`,
      title_6: `Account Base Currency`,
      title_6_1: {
        title: "PIPs Value ",
      },
      title_7: `Converted Currency`,
      title_7_1: {
        title: "PIPs Value ",
      },
    },
    // 444444444
    fxcal_list_4: `Margin`,
    fxcal_list_4_box: {
      title_1: `Account Base Currency`,
      title_2: `Volume in Lots`,
      title_3: `Currency Pair`,
      title_4: `Leverage`,
      title_5: `Account Type`,
      title_6: `Current Conversion Price`,
      title_7: `Required Margin`,
      title_8: `Account Base Currency`,
      title_9: `Converted Currency`,
    },
    // 555555555
    fxcal_list_5: `Swap`,
    fxcal_list_5_box: {
      title_1: `Account Base Currency`,
      title_2: `Swap Long`,
      title_3: `Currency Pair`,
      title_4: `Swap Short`,
      title_5: `Account Type`,
      title_6: `Current Conversion Price`,
      title_7: `Volume in Lots`,

      title_8: `Account Base Currency`,
      title_9: `Converted Currency`,

      title_10: `Swap Long`,
      title_11: `Swap Short`,
    },
    // 6666666666
    fxcal_list_6: `Profit and loss`,
    fxcal_page_6_title: `Stop loss / Take Profit value`,
    fxcal_list_6_box: {
      title_1: `Account Base Currency`,
      title_2: `Open Price`,
      title_3: `Currency Pair`,
      title_4: `Set Stop Loss Amount`,
      title_5: `Account Type`,
      title_6: `Set Take Profit Amount`,
      title_7: `Volume in Lots`,
      title_8: `Trade Type`,

      title_9: `Stop Loss at`,
      title_10: `Stop Loss, PIPs`,
      title_11: `Take Profit at`,
      title_12: `Take Profit, PIPs`,
      title_13: `PIPs Value `,
    },
    // 7777777777
    fxcal_page_7_title: `Stop loss / Take Profit levels`,
    fxcal_list_7_box: {
      title_1: `Account Base Currency`,
      title_2: `Volume in Lots`,
      title_3: `Currency Pair`,
      title_4: `Set Stop Loss at (points)`,
      title_5: `Account Type`,
      title_6: `Set Take Profit at (points)`,
      title_7: `Your Loss`,
      title_8: `Your Profit`,
    },
  },
  cn: {
    btn_calculator: "CALCULATE",
    calculator_select: "Please select",
    help_cover_title: "Help Center",
    help_cover_sub_title:
      "Help Center – Find the answers you’re looking for using our in-depth knowledgebase or contact our friendly customer support 24/7",
    help_page_title: "Most frequently asked questions",
    help_page_sub_title: `Here are the most common questions a trader would normally ask us or consider to ask before opening an account with us.
    For more information on help resources related to IUX Markets and the products we offer, simply type your question below or visit our live chat service.`,
    fxcal_cover_title: `CFDs Trading Calculators`,
    fxcal_cover_sub_title: `CFDs Trading calculators – calculate current PIPs values in your account in offered products on IUX Markets`,
    fxcal_page_title: `CFDs Trading Calculators`,
    economic: `Economic calendar`,
    economic_sub_title: `View all upcoming global economic news events and their importance in this CFDs calendar.`,
    payment_option: `Payment option`,
    economic_des: `Bookmark this page and refer to it regularly to stay up-to-date with key market-moving economic events and releases including: interest rate decisions, GDP announcements, NFP numbers, and more. If you want to keep track of upcoming, high impact news and data releases at a glance, our Economic Calendar is absolutely indispensable.`,
    // 11111111
    fxcal_list_1: `All In One`,
    fxcal_list_1_box: {
      Platform: `Platform`,
      title_1: `Account Base Currency`,
      title_2: `Volume in Lots`,
      title_3: `Currency Pair`,
      title_4: `Swap Long`,
      title_5: `Leverage for Margin`,
      title_6: `Swap Short`,
      title_7: `Account Type`,
      title_8: `Price Fluctuation`,
      title_9: `Required Margin`,
      title_10: `Swap Long`,
      title_11: `PIPs Value`,
      title_12: `Swap Short`,
    },
    fxcal_list_1_box_how: {
      title_1: `Recommendation for investors`,
      title_2: `Calculating the cost of trading before opening a position will help you determine the potential profit and loss opportunities.`,
      title_3: `Trading with Contract Size 0.01 Lot. Each order has a cost which we call this “Margin Required” which is different due to the different leverage selection, different contract size and different exchange rate.`,
    },
    fxcal_list_1_box_how_2: {
      title_1: `IUX Markets`,
      title_2: `Well known CFDs broker for its low spread and fast execution. `,
    },
    // 2222222
    fxcal_list_2: `Currency`,
    fxcal_list_2_box: {
      title_1: `From`,
      title_2: `To`,
      title_3: `Amount`,
      title_4: `Current Conversion Rate`,
      title_5: `Total`,
    },
    // 33333333
    fxcal_list_3: `PIPs value`,
    fxcal_list_3_box: {
      title_1: `Currency Pair`,
      title_2: `Volume in Lots`,
      title_3: `Account Base Currency`,
      title_4: `Current Conversion Price`,
      title_5: `Account Type`,
      title_6: `Account Base Currency`,
      title_6_1: {
        title: "PIPs Value ",
      },
      title_7: `Converted Currency`,
      title_7_1: {
        title: "PIPs Value ",
      },
    },
    // 444444444
    fxcal_list_4: `Margin`,
    fxcal_list_4_box: {
      title_1: `Account Base Currency`,
      title_2: `Volume in Lots`,
      title_3: `Currency Pair`,
      title_4: `Leverage`,
      title_5: `Account Type`,
      title_6: `Current Conversion Price`,
      title_7: `Required Margin`,
      title_8: `Account Base Currency`,
      title_9: `Converted Currency`,
    },
    // 555555555
    fxcal_list_5: `Swap`,
    fxcal_list_5_box: {
      title_1: `Account Base Currency`,
      title_2: `Swap Long`,
      title_3: `Currency Pair`,
      title_4: `Swap Short`,
      title_5: `Account Type`,
      title_6: `Current Conversion Price`,
      title_7: `Volume in Lots`,

      title_8: `Account Base Currency`,
      title_9: `Converted Currency`,

      title_10: `Swap Long`,
      title_11: `Swap Short`,
    },
    // 6666666666
    fxcal_list_6: `Profit and loss`,
    fxcal_page_6_title: `Stop loss / Take Profit value`,
    fxcal_list_6_box: {
      title_1: `Account Base Currency`,
      title_2: `Open Price`,
      title_3: `Currency Pair`,
      title_4: `Set Stop Loss Amount`,
      title_5: `Account Type`,
      title_6: `Set Take Profit Amount`,
      title_7: `Volume in Lots`,
      title_8: `Trade Type`,

      title_9: `Stop Loss at`,
      title_10: `Stop Loss, PIPs`,
      title_11: `Take Profit at`,
      title_12: `Take Profit, PIPs`,
      title_13: `PIPs Value `,
    },
    // 7777777777
    fxcal_page_7_title: `Stop loss / Take Profit levels`,
    fxcal_list_7_box: {
      title_1: `Account Base Currency`,
      title_2: `Volume in Lots`,
      title_3: `Currency Pair`,
      title_4: `Set Stop Loss at (points)`,
      title_5: `Account Type`,
      title_6: `Set Take Profit at (points)`,
      title_7: `Your Loss`,
      title_8: `Your Profit`,
    },
  },
  vn: {
    btn_calculator: "Tính toán",
    calculator_select: "Hãy chọn",
    help_cover_title: `Trung tâm trợ giúp`,
    help_cover_sub_title:
      "Trung tâm trợ giúp - Tìm câu trả lời bạn cần bằng cách sử dụng cơ sở kiến thức chuyên sâu của chúng tôi hoặc liên hệ với bộ phận hỗ trợ khách hàng thân thiện của chúng tôi 24/7 ngày.",
    help_page_title: "Câu hỏi thường gặp",
    help_page_sub_title: `Đây là những câu hỏi phổ biến nhất mà các nhà giao dịch thường hỏi chúng tôi hoặc cân nhắc hỏi trước khi mở tài khoản với chúng tôi. Để biết thêm thông tin về các nguồn trợ giúp liên quan đến IUX Markets và các sản phẩm chúng tôi cung cấp, chỉ cần nhập câu hỏi của bạn bên dưới hoặc truy cập dịch vụ trò chuyện trực tiếp của chúng tôi.`,
    fxcal_cover_title: `Máy tính giao dịch ngoại hối`,
    fxcal_cover_sub_title: `Máy tính giao dịch ngoại hối - Tính giá trị PIPs hiện tại bằng đơn vị tiền tệ trong tài khoản của bạn trên nhiều sản phẩm của IUX Markets.`,
    fxcal_page_title: `Máy tính giao dịch ngoại hối`,
    economic: `Lịch kinh tế`,
    economic_sub_title: `Xem tất cả các sự kiện tin tức kinh tế toàn cầu sắp tới và tầm quan trọng của chúng trong lịch ngoại hối này.`,
    payment_option: `Phương thức thanh toán`,
    economic_des: `Bookmark trang này và tham khảo trang này thường xuyên để cập nhật các sự kiện và phát hành kinh tế vận động thị trường quan trọng bao gồm: quyết định lãi suất, thông báo GDP, số NFP, v.v`,
    //
    fxcal_list_1: `All In One`,
    fxcal_list_1_box: {
      Platform: `Nền tảng`,
      title_1: `Đơn vị tiền tệ chính của tài khoản`,
      title_2: `Số lượng là số Lot`,
      title_3: `Tiền tệ`,
      title_4: `Swap Long`,
      title_5: `Đòn bẩy cho ký quỹ`,
      title_6: `Swap Short`,
      title_7: `Loại tài khoản`,
      title_8: `Dao động giá tối thiểu`,
      title_9: `Ký quỹ phải sử dụng`,
      title_10: `Swap Long`,
      title_11: `Giá trị PIPs`,
      title_12: `Swap Short`,
    },
    fxcal_list_1_box_how: {
      title_1: `Giới thiệu nhà đầu tư`,
      title_2: `Tính toán chi phí giao dịch trước khi mở một vị thế sẽ giúp chúng tôi biết cơ hội của năng lãi và lỗ tiềm năng.`,
      title_3: `Giao dịch với Contract Size 0.01 Lot, Mỗi đơn đặt hàng có vốn khác nhau mà chúng tôi gọi là  “Margin Required” khác nhau vì sử dụng Leverage không bằng nhau Contract Size khác nhau và tỷ giá hối đoái không bình đẳng.`,
    },
    fxcal_list_1_box_how_2: {
      title_1: `IUX Markets`,
      title_2: `Nhà môi giới được biết đến với mức chênh lệch thấp nhất bắt đầu từ 0.1 PIPs, Bởi vì nó là nhà môi giới trực tiếp ra thị trường và Liquidity Provider hàng đầu.`,
    },
    // 222222222
    fxcal_list_2: `Currency`,
    fxcal_list_2_box: {
      title_1: `Từ`,
      title_2: `đến`,
      title_3: `Số tiền`,
      title_4: `Tỷ giá hối đoái hiện tại`,
      title_5: `Tổng`,
    },
    fxcal_list_2_box_how: {
      title_1: `Cách hoạt động`,
      title_2: `Công cụ chuyển đổi tiền tệ của chúng tôi sẽ cho phép bạn chuyển đổi bất kỳ loại tiền nào bằng cách sử dụng tỷ giá hối đoái hiện tại.`,
      title_3: `Đầu tiên hãy điều đơn vị tiền tệ bạn muốn chuyển đổi và đơn vị tiền tệ kết quả mong muốn của bạn. Sau đó, nhập số tiền bạn muốn chuyển đổi và nhấp vào nút tính toán.`,
    },
    fxcal_list_2_box_how_2: {
      title_1: `Bạn có biết không?`,
      title_2: `Mặc dù thị trường CFDs luôn mở 24giờ mỗi ngày. Nhưng thị trường này không chuyển động mọi lúc.`,
      title_3: `Thời gain nào phụ hợp giao dịch? Giờ mở cửa và đóng cửa của thị trường ngoại hối phụ thuộc vào giờ giao dịch của thị trường chính 4 Địa điểm là Sydney, Tokyo, London, London. Các thị trường này có đặt thời gian mở và đóng cửa cho phép bạn quyết định thời điểm giao dịch.`,
    },
    // 333333333
    fxcal_list_3: `PIPs value`,
    fxcal_list_3_box: {
      title_1: `Cặp tiền`,
      title_2: `số lượng là Lot`,
      title_3: `Đơn vị tiền tệ chính của tài khoản`,
      title_4: `Tỷ giá hối đoái hiện tại`,
      title_5: `Loại tài khoản`,
      title_6: `Đơn vị tiền tệ chính của tài khoản`,
      title_6_1: {
        title: "Giá trị  PIPs",
      },
      title_7: `Tiền tệ được thay đổi`,
      title_7_1: {
        title: "Giá trị PIPs",
      },
    },
    fxcal_list_3_box_how: {
      title_1: `Làm thế nào?`,
      title_2: `Máy tính giá PIPs sẽ giúp bạn tìm giá trị trên mỗi PIPs của đồng tiền chính. Để bạn có thể theo dõi rủi ro xảy ra từ giao dịch chính xác hơn.`,
      title_3: `Bạn chỉ cần dùng đơn vị tiền tệ chính của mình. Cặp tiền tệ bạn giao dịch, tỷ giá thay  và kích thước của vị trí để tính giá trị của một PIPs.`,
    },
    fxcal_list_3_box_how_2: {
      title_1: `Tính toán như sau::`,
      title_2: `Mặc dù thị trường CFDs luôn mở 24 giờ mỗi ngày. Nhưng thị trường này không chuyển động mọi lúc.`,
      title_3: `Giá trị PIPs = (1 PIPs / tỷ giá hối đoái) * Kích thước Lot\ ví dụ:\n1 PIPs: 0.0001\Đơn vị tiền tệ chính của tài khoả: EUR\cặp tiền: EUR/USD\Tỷ giá hối đoái: 1.08962 (EUR/USD)\kích thước Lot: 1 Lot (100000 EUR)\ giá trị PIPs = 0.0001 / 1.08962 * 100000\ mỗi PIPs có giá trị €9.18`,
    },
    // 44444444
    fxcal_list_4: `Margin`,
    fxcal_list_4_box: {
      title_1: `Đơn vị tiền tệ chính của tài khoản`,
      title_2: `Số lượng là Lot`,
      title_3: `Cặp tiền`,
      title_4: `Leverage`,
      title_5: `Loại tài khoản`,
      title_6: `Tỷ giá hối đoái hiện tại`,
      title_7: `Ký quỹ được sử dụng`,
      title_8: `Đơn vị tiền tệ chính của tài khoản`,
      title_9: `Tiền tệ được thay đổi`,
    },
    fxcal_list_4_box_how: {
      title_1: `Làm thế nào?`,
      title_2: `Máy tính ký quỹ của chúng tôi sẽ  bạn tính toán số tiền ký quỹ cần thiết để mở và duy trì một vị trí.`,
      title_3: `Điền tiền tệ chính của bạn chọn một cặp tiền tệ và đòn bẩy và điền vào vị trí của bạn với số tiền lot`,
    },
    fxcal_list_4_box_how_2: {
      title_1: `Tính toán như sau:`,
      title_2: `Ký quỹ được sử dụng = số lượng gaio dịch/Leverage * tỷ giá hối đoái tiền tệ của tài khoản.`,
      title_3: `ví dụ:\số lượng l Lot: 5 (1 Standard Lot = 100,000 หน่วย)\Leverage: 100\đơn vị tiền tệ chính của tài khoản: USD\cặp tiền: EUR/USD\Tỷ giá hối đoá: 1.365 (EUR/USD)\ký quỹ được sử dụng = 500,000 /100 * 1.365\ký quỹ được sử dụng $6825.00 USD`,
    },
    // 55555555
    fxcal_list_5: `Swap`,
    fxcal_list_5_box: {
      title_1: `Đơn vị tiền tệ chính của tài khoản`,
      title_2: `Swap Long`,
      title_3: `Cặp tiền`,
      title_4: `Swap Short`,
      title_5: `Loại tài khoản`,
      title_6: `Tỷ giá hối đoái hiện tại`,
      title_7: `Số lượng là Lot`,

      title_8: `Đơn vị tiền tệ chính của tài khoản`,
      title_9: `Tiền tệ được thay đổi`,

      title_10: `Swap Long`,
      title_11: `Swap Short`,
    },
    // 666666
    fxcal_list_6: `Profit and loss`,
    fxcal_page_6_title: `Giá trị của Stop loss / Take Profit`,
    fxcal_list_6_box: {
      title_1: `Đơn vị tiền tệ chính của tài khoản`,
      title_2: `Giá mở`,
      title_3: `Cặp tiền`,
      title_4: `Cài đặt Stop Loss`,
      title_5: `Loại tài khoản`,
      title_6: `Cài đặt Take Profit`,
      title_7: `Số lượng là Lot`,
      title_8: `Loại giao dịch`,

      title_9: `Stop Loss tại`,
      title_10: `Stop Loss, PIPs`,
      title_11: `Take Profit tại`,
      title_12: `Take Profit, PIPs`,
      title_13: `Giá trị PIPs`,
    },
    // 7777777
    fxcal_page_7_title: `mức Stop loss / Take Profit`,
    fxcal_list_7_box: {
      title_1: `Đơn vị tiền tệ chính của tài khoản`,
      title_2: `Số lượng là Lot`,
      title_3: `Cặp tiền`,
      title_4: `Cài đặt Stop Loss`,
      title_5: `Loại tài khoản`,
      title_6: `Cài đặtTake Profit`,
      title_7: `Mất tiền`,
      title_8: `lợi nhuận`,
    },
  },
  kr: {
    btn_calculator: "CALCULATE",
    calculator_select: "Please select",
    help_cover_title: "Help Center",
    help_cover_sub_title:
      "Help Center – Find the answers you’re looking for using our in-depth knowledgebase or contact our friendly customer support 24/7",
    help_page_title: "Most frequently asked questions",
    help_page_sub_title: `Here are the most common questions a trader would normally ask us or consider to ask before opening an account with us.
    For more information on help resources related to IUX Markets and the products we offer, simply type your question below or visit our live chat service.`,
    fxcal_cover_title: `CFDs Trading Calculators`,
    fxcal_cover_sub_title: `CFDs Trading calculators – calculate current PIPs values in your account in offered products on IUX Markets`,
    fxcal_page_title: `CFDs Trading Calculators`,
    economic: `Economic calendar`,
    economic_sub_title: `View all upcoming global economic news events and their importance in this CFDs calendar.`,
    payment_option: `Payment option`,

    economic_des: `Bookmark this page and refer to it regularly to stay up-to-date with key market-moving economic events and releases including: interest rate decisions, GDP announcements, NFP numbers, and more. If you want to keep track of upcoming, high impact news and data releases at a glance, our Economic Calendar is absolutely indispensable.`,
    // 11111111
    fxcal_list_1: `All In One`,
    fxcal_list_1_box: {
      Platform: `Platform`,
      title_1: `Account Base Currency`,
      title_2: `Volume in Lots`,
      title_3: `Currency Pair`,
      title_4: `Swap Long`,
      title_5: `Leverage for Margin`,
      title_6: `Swap Short`,
      title_7: `Account Type`,
      title_8: `Price Fluctuation`,
      title_9: `Required Margin`,
      title_10: `Swap Long`,
      title_11: `PIPs Value`,
      title_12: `Swap Short`,
    },
    fxcal_list_1_box_how: {
      title_1: `Recommendation for investors`,
      title_2: `Calculating the cost of trading before opening a position will help you determine the potential profit and loss opportunities.`,
      title_3: `Trading with Contract Size 0.01 Lot. Each order has a cost which we call this “Margin Required” which is different due to the different leverage selection, different contract size and different exchange rate.`,
    },
    fxcal_list_1_box_how_2: {
      title_1: `IUX Markets`,
      title_2: `Well known CFDs broker for its low spread and fast execution. `,
    },
    // 2222222
    fxcal_list_2: `Currency`,
    fxcal_list_2_box: {
      title_1: `From`,
      title_2: `To`,
      title_3: `Amount`,
      title_4: `Current Conversion Rate`,
      title_5: `Total`,
    },
    // 33333333
    fxcal_list_3: `PIPs value`,
    fxcal_list_3_box: {
      title_1: `Currency Pair`,
      title_2: `Volume in Lots`,
      title_3: `Account Base Currency`,
      title_4: `Current Conversion Price`,
      title_5: `Account Type`,
      title_6: `Account Base Currency`,
      title_6_1: {
        title: "PIPs Value ",
      },
      title_7: `Converted Currency`,
      title_7_1: {
        title: "PIPs Value ",
      },
    },
    // 444444444
    fxcal_list_4: `Margin`,
    fxcal_list_4_box: {
      title_1: `Account Base Currency`,
      title_2: `Volume in Lots`,
      title_3: `Currency Pair`,
      title_4: `Leverage`,
      title_5: `Account Type`,
      title_6: `Current Conversion Price`,
      title_7: `Required Margin`,
      title_8: `Account Base Currency`,
      title_9: `Converted Currency`,
    },
    // 555555555
    fxcal_list_5: `Swap`,
    fxcal_list_5_box: {
      title_1: `Account Base Currency`,
      title_2: `Swap Long`,
      title_3: `Currency Pair`,
      title_4: `Swap Short`,
      title_5: `Account Type`,
      title_6: `Current Conversion Price`,
      title_7: `Volume in Lots`,

      title_8: `Account Base Currency`,
      title_9: `Converted Currency`,

      title_10: `Swap Long`,
      title_11: `Swap Short`,
    },
    // 6666666666
    fxcal_list_6: `Profit and loss`,
    fxcal_page_6_title: `Stop loss / Take Profit value`,
    fxcal_list_6_box: {
      title_1: `Account Base Currency`,
      title_2: `Open Price`,
      title_3: `Currency Pair`,
      title_4: `Set Stop Loss Amount`,
      title_5: `Account Type`,
      title_6: `Set Take Profit Amount`,
      title_7: `Volume in Lots`,
      title_8: `Trade Type`,

      title_9: `Stop Loss at`,
      title_10: `Stop Loss, PIPs`,
      title_11: `Take Profit at`,
      title_12: `Take Profit, PIPs`,
      title_13: `PIPs Value `,
    },
    // 7777777777
    fxcal_page_7_title: `Stop loss / Take Profit levels`,
    fxcal_list_7_box: {
      title_1: `Account Base Currency`,
      title_2: `Volume in Lots`,
      title_3: `Currency Pair`,
      title_4: `Set Stop Loss at (points)`,
      title_5: `Account Type`,
      title_6: `Set Take Profit at (points)`,
      title_7: `Your Loss`,
      title_8: `Your Profit`,
    },
  },
  ph: {
    btn_calculator: "CALCULATE",
    calculator_select: "Please select",
    help_cover_title: "Help Center",
    help_cover_sub_title:
      "Help Center – Find the answers you’re looking for using our in-depth knowledgebase or contact our friendly customer support 24/7",
    help_page_title: "Most frequently asked questions",
    help_page_sub_title: `Here are the most common questions a trader would normally ask us or consider to ask before opening an account with us.
    For more information on help resources related to IUX Markets and the products we offer, simply type your question below or visit our live chat service.`,
    fxcal_cover_title: `CFDs Trading Calculators`,
    fxcal_cover_sub_title: `CFDs Trading calculators – calculate current PIPs values in your account in offered products on IUX Markets`,
    fxcal_page_title: `CFDs Trading Calculators`,
    economic: `Economic calendar`,
    economic_sub_title: `View all upcoming global economic news events and their importance in this CFDs calendar.`,
    payment_option: `Payment option`,
    economic_des: `Bookmark this page and refer to it regularly to stay up-to-date with key market-moving economic events and releases including: interest rate decisions, GDP announcements, NFP numbers, and more. If you want to keep track of upcoming, high impact news and data releases at a glance, our Economic Calendar is absolutely indispensable.`,
    // 11111111
    fxcal_list_1: `All In One`,
    fxcal_list_1_box: {
      Platform: `Platform`,
      title_1: `Account Base Currency`,
      title_2: `Volume in Lots`,
      title_3: `Currency Pair`,
      title_4: `Swap Long`,
      title_5: `Leverage for Margin`,
      title_6: `Swap Short`,
      title_7: `Account Type`,
      title_8: `Price Fluctuation`,
      title_9: `Required Margin`,
      title_10: `Swap Long`,
      title_11: `PIPs Value`,
      title_12: `Swap Short`,
    },
    fxcal_list_1_box_how: {
      title_1: `Recommendation for investors`,
      title_2: `Calculating the cost of trading before opening a position will help you determine the potential profit and loss opportunities.`,
      title_3: `Trading with Contract Size 0.01 Lot. Each order has a cost which we call this “Margin Required” which is different due to the different leverage selection, different contract size and different exchange rate.`,
    },
    fxcal_list_1_box_how_2: {
      title_1: `IUX Markets`,
      title_2: `Well known CFDs broker for its low spread and fast execution. `,
    },
    // 2222222
    fxcal_list_2: `Currency`,
    fxcal_list_2_box: {
      title_1: `From`,
      title_2: `To`,
      title_3: `Amount`,
      title_4: `Current Conversion Rate`,
      title_5: `Total`,
    },
    // 33333333
    fxcal_list_3: `PIPs value`,
    fxcal_list_3_box: {
      title_1: `Currency Pair`,
      title_2: `Volume in Lots`,
      title_3: `Account Base Currency`,
      title_4: `Current Conversion Price`,
      title_5: `Account Type`,
      title_6: `Account Base Currency`,
      title_6_1: {
        title: "PIPs Value ",
      },
      title_7: `Converted Currency`,
      title_7_1: {
        title: "PIPs Value ",
      },
    },
    // 444444444
    fxcal_list_4: `Margin`,
    fxcal_list_4_box: {
      title_1: `Account Base Currency`,
      title_2: `Volume in Lots`,
      title_3: `Currency Pair`,
      title_4: `Leverage`,
      title_5: `Account Type`,
      title_6: `Current Conversion Price`,
      title_7: `Required Margin`,
      title_8: `Account Base Currency`,
      title_9: `Converted Currency`,
    },
    // 555555555
    fxcal_list_5: `Swap`,
    fxcal_list_5_box: {
      title_1: `Account Base Currency`,
      title_2: `Swap Long`,
      title_3: `Currency Pair`,
      title_4: `Swap Short`,
      title_5: `Account Type`,
      title_6: `Current Conversion Price`,
      title_7: `Volume in Lots`,

      title_8: `Account Base Currency`,
      title_9: `Converted Currency`,

      title_10: `Swap Long`,
      title_11: `Swap Short`,
    },
    // 6666666666
    fxcal_list_6: `Profit and loss`,
    fxcal_page_6_title: `Stop loss / Take Profit value`,
    fxcal_list_6_box: {
      title_1: `Account Base Currency`,
      title_2: `Open Price`,
      title_3: `Currency Pair`,
      title_4: `Set Stop Loss Amount`,
      title_5: `Account Type`,
      title_6: `Set Take Profit Amount`,
      title_7: `Volume in Lots`,
      title_8: `Trade Type`,

      title_9: `Stop Loss at`,
      title_10: `Stop Loss, PIPs`,
      title_11: `Take Profit at`,
      title_12: `Take Profit, PIPs`,
      title_13: `PIPs Value `,
    },
    // 7777777777
    fxcal_page_7_title: `ระดัlevelsบ Stop loss / Take Profit`,
    fxcal_list_7_box: {
      title_1: `Account Base Currency`,
      title_2: `Volume in Lots`,
      title_3: `Currency Pair`,
      title_4: `Set Stop Loss at (points)`,
      title_5: `Account Type`,
      title_6: `Set Take Profit at (points)`,
      title_7: `Your Loss`,
      title_8: `Your Profit`,
    },
  },
  jp: {
    btn_calculator: "計算します",
    calculator_select: "選んでください",
    help_cover_title: "ヘルプセンター",
    help_cover_sub_title:
      "ヘルプセンター - 詳細な知識ベースを使用して探している回答を見つけるか、24時間年中無休でフレンドリーなカスタマーサポートに連絡してください",
    help_page_title: "最もよくある質問",
    help_page_sub_title:
      "以下は、トレーダーが通常私たちにアカウントを開設する前に私たちに尋ねたり、尋ねたりする最も一般的な質問です。IUX市場と私たちが提供する製品に関連するヘルプリソースの詳細については、以下に質問を入力するか、ライブチャットサービスにアクセスしてください 。",
    fxcal_cover_title: "CFDS取引計算機",
    fxcal_cover_sub_title:
      "CFDS取引計算機 -  IUX市場で提供される製品のアカウントの現在のPIPS値を計算する",
    fxcal_page_title: "CFDS取引計算機",
    economic: "経済カレンダー",
    economic_sub_title:
      "このCFDSカレンダーで、今後のすべてのグローバルな経済ニュースイベントとその重要性を表示します。",
    payment_option: "支払いオプション",
    economic_des:
      "このページをブックマークし、定期的に参照して、金利の決定、GDPアナウンス、NFP番号などを含む、主要な市場で動く経済イベントやリリースを最新の状態に保ちます。 今後の影響を受けたニュースとデータリリースを一目で追跡したい場合、私たちの経済カレンダーは絶対に不可欠です。",
    // 11111111
    fxcal_list_1: "すべて1つ",
    fxcal_list_1_box: {
      Platform: "プラットホーム",
      title_1: "アカウントベース通貨",
      title_2: "ロットのボリューム",
      title_3: "通貨ペア",
      title_4: "長く交換します",
      title_5: "マージンを活用します",
      title_6: "短い交換",
      title_7: "口座の種類",
      title_8: "価格の変動",
      title_9: "必要なマージン",
      title_10: "長く交換します",
      title_11: "PIPS値",
      title_12: "短い交換",
    },
    fxcal_list_1_box_how: {
      title_1: "投資家への推奨",
      title_2:
        "ポジションを開く前に取引コストを計算すると、潜在的な損益の機会を決定するのに役立ちます。",
      title_3:
        "契約サイズ0.01ロットで取引。 各注文には、この「マージンが必要」と呼ぶコストがあります。これは、レバレッジの選択が異なり、契約規模が異なり、為替レートが異なるために異なります。",
    },
    fxcal_list_1_box_how_2: {
      title_1: "IUX市場",
      title_2:
        "低いスプレッドと迅速な実行のためのよく知られているCFDSブローカー。",
    },
    // 2222222
    fxcal_list_2: "通貨",
    fxcal_list_2_box: {
      title_1: "から",
      title_2: "に",
      title_3: "額",
      title_4: "現在の変換率",
      title_5: "合計",
    },
    // 33333333
    fxcal_list_3: "PIPS値",
    fxcal_list_3_box: {
      title_1: "通貨ペア",
      title_2: "ロットのボリューム",
      title_3: "アカウントベース通貨",
      title_4: "現在の変換価格",
      title_5: "口座の種類",
      title_6: "アカウントベース通貨",
      title_6_1: {
        title: "PIPS値",
      },
      title_7: "変換された通貨",
      title_7_1: {
        title: "PIPS値",
      },
    },
    // 444444444
    fxcal_list_4: "マージン",
    fxcal_list_4_box: {
      title_1: "アカウントベース通貨",
      title_2: "ロットのボリューム",
      title_3: "通貨ペア",
      title_4: "てこの作用",
      title_5: "口座の種類",
      title_6: "現在の変換価格",
      title_7: "必要なマージン",
      title_8: "アカウントベース通貨",
      title_9: "変換された通貨",
    },
    // 555555555
    fxcal_list_5: "スワップ",
    fxcal_list_5_box: {
      title_1: "アカウントベース通貨",
      title_2: "長く交換します",
      title_3: "通貨ペア",
      title_4: "短い交換",
      title_5: "口座の種類",
      title_6: "現在の変換価格",
      title_7: "ロットのボリューム",

      title_8: "アカウントベース通貨",
      title_9: "変換された通貨",

      title_10: "長く交換します",
      title_11: "短い交換",
    },
    // 6666666666
    fxcal_list_6: "利益と損失",
    fxcal_page_6_title: "損失を停止 /利益価値を取得します",
    fxcal_list_6_box: {
      title_1: "アカウントベース通貨",
      title_2: "オープン価格",
      title_3: "通貨ペア",
      title_4: "停止損失額を設定します",
      title_5: "口座の種類",
      title_6: "セット利益額を取得します",
      title_7: "ロットのボリューム",
      title_8: "貿易タイプ",

      title_9: "で停止します",
      title_10: "停止損失、ピップ",
      title_11: "で利益を得る",
      title_12: "利益を得てください、ピップ",
      title_13: "PIPS値",
    },
    // 7777777777
    fxcal_page_7_title: "損失を停止 /利益レベルを取得します",
    fxcal_list_7_box: {
      title_1: "アカウントベース通貨",
      title_2: "ロットのボリューム",
      title_3: "通貨ペア",
      title_4: "（ポイント）で停止損失を設定します",
      title_5: "口座の種類",
      title_6: "（ポイント）で利益を上げる",
      title_7: "君の負け",
      title_8: "あなたの利益",
    },
  },
};
