import React, { useState } from "react";

function FooterContact() {
  const [isOpen, setIsOpen] = useState(false);
  const onOpenChange = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="chat24-container" style={{ zIndex: 9999 }}>
      <div className="cursor-pointer" onClick={() => onOpenChange()}>
        Logo
      </div>
      <div className={`footer-contact-container${isOpen ? "-show" : " "}  `}>
        FooterContact
      </div>
    </div>
  );
}

export default FooterContact;
