import { Spin } from "antd";
import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { get, post } from "../../../../helper/request";

export const postPerfectMoney = ({
  amount_thb,
  account_trade_id,
  ip,
  payment_id,
}) => {
  return post(`${process.env.REACT_APP_API_URL + "deposit/payasia"}`, {
    ip: ip,
    amount_thb: amount_thb,
    account_trade_id: account_trade_id,
    payment_id: payment_id,
  });
};

function PaymentAsia(props) {
  console.log(props);
  const { paymentId } = props;
  const refPerfect = useRef(null);
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({
    action: "",
    method: "",
    payload: {
      merchant_reference: "",
      currency: "",
      amount: "",
      customer_ip: "",
      customer_first_name: "",
      customer_last_name: "",
      customer_address: "",
      customer_phone: "",
      customer_email: "",
      customer_state: "",
      customer_country: "",
      return_url: "",
      network: "",
      sign: "",
    },
  });
  useEffect(() => {
    Axios.post(`https://ipv4.icanhazip.com`).then((res) => {
      if (res) {
        setLoading(true);
        const a = res.data.replace("\n", "");
        const postApiPayment = async () => {
          const payload = {
            account_trade_id: props.account.id,
            amount_thb: props.amount,
            ip: a,
            payment_id: paymentId,
          };
          const { data } = await postPerfectMoney(payload);
          setPaymentData(data);
          refPerfect.current.click();
          setLoading(false);
        };
        postApiPayment();
      }
    });
  }, []);

  return (
    <Spin spinning={loading}>
      <form action={paymentData.action} method={paymentData.method}>
        <p>
          <input
            type="hidden"
            name="merchant_reference"
            value={paymentData.payload.merchant_reference}
          />
          <input
            type="hidden"
            name="currency"
            value={paymentData.payload.currency}
          />
          <input
            type="hidden"
            name="amount"
            value={paymentData.payload.amount}
          />
          <input
            type="hidden"
            name="customer_ip"
            value={paymentData.payload.customer_ip}
          />
          <input
            type="hidden"
            name="customer_first_name"
            value={paymentData.payload.customer_first_name}
          />
          <input
            type="hidden"
            name="customer_last_name"
            value={paymentData.payload.customer_last_name}
          />
          <input
            type="hidden"
            name="customer_address"
            value={paymentData.payload.customer_address}
          />
          <input
            type="hidden"
            name="customer_phone"
            value={paymentData.payload.customer_phone}
          />
          <input
            type="hidden"
            name="customer_email"
            value={paymentData.payload.customer_email}
          />
          <input
            type="hidden"
            name="customer_state"
            value={paymentData.payload.customer_state}
          />
          <input
            type="hidden"
            name="customer_country"
            value={paymentData.payload.customer_country}
          />
          <input
            type="hidden"
            name="return_url"
            value={paymentData.payload.return_url}
          />
          <input
            type="hidden"
            name="network"
            value={paymentData.payload.network}
          />
          <input type="hidden" name="sign" value={paymentData.payload.sign} />
          <input
            ref={refPerfect}
            type="submit"
            value="Payment asia account"
            hidden
          />
        </p>
      </form>
    </Spin>
  );
}

export default PaymentAsia;
