import { Carousel, message } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { setModalBonus } from "../../actions";

const locale = require("react-redux-i18n").I18n;

const BoxBanner = styled.div`
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  height: ${(props) => props.mobile && "90px"};

  background: ${(props) => props.bg};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  background-color: ${(props) => props.bgcolor};
`;
const TextGradients = styled.div`
  background: linear-gradient(180deg, #1cb894 0%, rgba(0, 71, 255, 0.9) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const TextGradientsYellow = styled.div`
  background: linear-gradient(313deg, #d2b554 0%, rgba(227, 103, 63, 0.5) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Bagde = styled.div`
  border-radius: 40px;
  background: rgba(0, 71, 255, 0.2);
  color: #0047ff;
  display: flex;
  align-items: center;
  padding: 8px 16px;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;

const Banner = () => {
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const onClickBonus = () => {
    if (
      user?.bonus_config?.bonus_countries?.find(
        (e) => e === user?.profile?.country_id
      )
    ) {
      if (
        user.bookBankVerify.status === "accepted" &&
        user.userVerify.status === "accepted"
      ) {
        dispatch(setModalBonus(true));
      } else {
        if (user.userVerify.status !== "accepted") {
          message.error(locale.t("open_account.error_er"));
          history.push(
            `/${localStorage.getItem("locale")}/wallet/account/Standard`
          );
        } else {
          message.error(locale.t("open_account.error_bank"));
          history.push(
            `/${localStorage.getItem("locale")}/wallet/fund/withdraw`
          );
        }
      }
    } else {
      history.push(`/${localStorage.getItem("locale")}/wallet/receivebonus`);
    }
  };
  return (
    <div className="mb-3">
      <Carousel autoplay dots>
        {user?.bonus_config?.bonus_countries?.find(
          (e) => e === user?.profile?.country_id
        ) &&
          user.accountTradeBonusId.isRecived === null &&
          user?.accounts?.filter((e) => e.type === "welcome").length < 1 && (
            <BoxBanner
              bg="url('/assets/images/announcement/bg.png')"
              bgcolor={"#FFF"}
            >
              <div className="p-4 d-flex justify-content-cneter justify-content-md-between flex-column flex-sm-row  align-items-center">
                <div className="text-center">
                  <TextGradients className="d-flex fs-20 fw-700 justify-content-center justify-content-md-start">
                    Get&nbsp;<Bagde>Welcome</Bagde>&nbsp;Bonus $30
                  </TextGradients>
                  <div className="text-center text-sm-left">
                    {/* Open a live trading account with IUX Markets and get up to
                    $30 bonus free */}
                    {locale.t("banner_bonus_detail")}
                  </div>
                </div>{" "}
                <button
                  className="btn-primary-new-outline px-5 fw-700  mt-3 mt-md-0"
                  style={{ borderRadius: "10px", whiteSpace: "nowrap" }}
                  onClick={() => onClickBonus()}
                >
                  {" "}
                  {locale.t("bonus_button")}
                </button>
              </div>
            </BoxBanner>
          )}
        {!user?.affiliate_id && (
          <BoxBanner
            bgcolor={"#FFF"}
            bg="linear-gradient(333deg, rgba(210, 181, 84, 0.49) 0%, rgba(210, 181, 84, 0.00) 100%);"
          >
            <div className="p-4 d-flex justify-content-cneter justify-content-md-between flex-column flex-sm-row  align-items-center">
              <div>
                <TextGradientsYellow className="d-flex fs-20 fw-700 justify-content-center justify-content-md-start">
                  {locale.t("banner_partner_title")}
                </TextGradientsYellow>
                <div className="text-center text-sm-left">
                  {locale.t("banner_partner_detail")}
                </div>
              </div>{" "}
              <button
                className="btn-yellow-new-iux px-5 fw-700 mt-3 mt-md-0 "
                style={{ borderRadius: "10px", whiteSpace: "nowrap" }}
                onClick={() => window.open("https://partner.iuxmarkets.com")}
              >
                {locale.t("banner_join_partner")}
              </button>
            </div>
          </BoxBanner>
        )}
      </Carousel>
    </div>
  );
};

export default Banner;
