import React, { useState, useEffect, useRef } from "react";
import RcQueueAnim from "rc-queue-anim";
import styled from "styled-components";
import AllInOne from "./AllInOne";
import Currency from "./Currency";
import Pip from "./Pip";
import Margin from "./Margin";
import Swap from "./Swap";
import Tpsl from "./Tpsl";
import { get } from "../../helper/request";
const locale = require("react-redux-i18n").I18n;

const SubTabsCal = styled.div`
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  padding: 6px 14px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  white-space: pre;
  color: ${(props) => props.Color}
  background-color: ${(props) => props.BagroundColor}
`;

const Tab = styled.div`
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  padding: 6px 14px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  white-space: pre;
  color: ${(props) => props.Color}
  background-color: ${(props) => props.BagroundColor}
`;

const Calculator = () => {
  const [symbols, setSymbols] = useState(null);
  const [tab, setTab] = useState(0);
  const [selectMt, SetSelectMt] = useState({ mt4: false, mt5: true });
  const imgcover = [
    {
      title: locale.t("fxcal_cover_title"),
      description: locale.t("fxcal_cover_sub_title"),
    },
    {
      title: locale.t("fxcal_cover_title"),
      description: locale.t("fxcal_cover_sub_title"),
    },
    {
      title: locale.t("fxcal_cover_title"),
      description: locale.t("fxcal_cover_sub_title"),
    },
  ];
  useEffect(() => {
    get(process.env.REACT_APP_API_URL + `/calc/mt5/symbols`).then((res) => {
      setSymbols(res.data);
    });
  }, []);

  const handleChangeSymbol = (el) => {
    if (el === "mt4") {
      SetSelectMt({ mt4: true });
      get(process.env.REACT_APP_API_URL + `/calc/symbols`).then((res) => {
        setSymbols(res.data);
      });
    } else {
      SetSelectMt({ mt5: true });
      get(process.env.REACT_APP_API_URL + `/calc/mt5/symbols`).then((res) => {
        setSymbols(res.data);
      });
    }
  };

  const swithCal = (el) => {
    setTab(el);
  };

  const menu = [
    "fxcal_list_1",
    "fxcal_list_2",
    "fxcal_list_3",
    "fxcal_list_4",
    "fxcal_list_5",
    "fxcal_list_6",
  ];

  return (
    <RcQueueAnim>
      <div key={1} className="container">
        <div
          style={{ fontweight: 700, fontSize: 40 }}
          className="mb-4 ml-mr-sm-auto text-center text-lg-left text-white"
        >
          {locale.t("fxcal_cover_title")}
        </div>
        <div
          className="d-flex ml-mr-sm-auto"
          style={{
            borderBottom: "1px solid #80808038",
            padding: "1rem 0 0",
            overflowX: "auto",
          }}
        >
          {menu.map((e, i) => (
            <SubTabsCal
              className="mr-2"
              onClick={() => swithCal(i)}
              BagroundColor={tab === i ? "#1CB894" : ""}
              Color={tab === i ? "#fff" : "#FFF"}
            >
              {locale.t(e)}
            </SubTabsCal>
          ))}
        </div>
        <div className="row mt-4">
          {tab === 0 && (
            <AllInOne
              symbols={symbols}
              mtType={selectMt}
              handleChangeSymbol={handleChangeSymbol}
            />
          )}
          {tab === 1 && (
            <Currency
              symbols={symbols}
              mtType={selectMt}
              handleChangeSymbol={handleChangeSymbol}
            />
          )}
          {tab === 2 && (
            <Pip
              symbols={symbols}
              mtType={selectMt}
              handleChangeSymbol={handleChangeSymbol}
            />
          )}
          {tab === 3 && (
            <Margin
              symbols={symbols}
              mtType={selectMt}
              handleChangeSymbol={handleChangeSymbol}
            />
          )}
          {tab === 4 && (
            <Swap
              symbols={symbols}
              mtType={selectMt}
              handleChangeSymbol={handleChangeSymbol}
            />
          )}
          {tab === 5 && (
            <Tpsl
              symbols={symbols}
              mtType={selectMt}
              handleChangeSymbol={handleChangeSymbol}
            />
          )}
        </div>
      </div>
    </RcQueueAnim>
  );
};

export default Calculator;
