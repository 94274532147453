export const CheckLanguage = (id) => {
  switch (id) {
    case "th":
      return "th_TH";
    case "en":
      return "en";
    case "cn":
      return "zh_CN";
    case "my":
      return "ms_MY";
    case "in":
      return "en";
    case "la":
      return "en";
    case "id":
      return "id";
    case "vn":
      return "vi_VN";
    case "ph":
      return "en";
    case "jp":
      return "ja";
    case "kr":
      return "kr";
    default:
      return "en";
  }
};
//
