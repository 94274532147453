import React from "react";
import { Route, Redirect } from "react-router-dom";
import Connect from "./Connect";

const Guest = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Connect
        mapStateToProps={(state) => ({
          isAuth: state.user.isAuth,
        })}
        mapDispatchToProps={{}}
      >
        {(store) =>
          store.isAuth === false ? (
            <Component {...props} />
          ) : localStorage.getItem("join_us") ? (
            <Redirect
              to={{
                pathname: `/${localStorage.getItem("locale")}/formpartner`,
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: `/${localStorage.getItem("locale")}/wallet`,
              }}
            />
          )
        }
      </Connect>
    )}
  />
);

export { Guest };
