import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Col,
  Row,
  Divider,
  Button,
  Modal,
  Form,
  Input,
  Statistic,
  Select,
  message,
} from "antd";
import styled from "styled-components";
import { CaretRightOutlined, PieChartOutlined } from "@ant-design/icons";
import { get, post } from "../../../helper/request";
import { onOpenPolicy } from "../../../helper/policy";
import { ClipLoader } from "react-spinners";
const locale = require("react-redux-i18n").I18n;

const { Option } = Select;
const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const styleInput = {
  // height: 56,
};
const Popluar = styled.div`
  background: #1cb894;
  color: #fff;
  position: absolute;
  top: 0;
  left: 5%;
  text-transform: uppercase;
  padding: 4px 12px 4px 12px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
`;
function Commission(props) {
  const [form] = Form.useForm();
  const [credit, setCredit] = useState(0);
  const [balance, setBalance] = useState(0);
  const [currentpiont, setcurrentPiont] = useState(0);
  const [ModalBalanceVisible, setModalBalanceVisible] = useState(false);
  const [ModalCreditVisible, setModalCreditVisible] = useState(false);
  const [packages, setPackages] = useState([]);
  const [type, setType] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [visibleLotback, setVisibleLotback] = useState(false);
  const [totalPoint, setTotalPoint] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalBalance, setTotalBalance] = useState(0);
  function fncToFixed(num, dec) {
    if (dec != null) {
      var decimals = dec;
    } else {
      var decimals = 2;
    }

    num *= Math.pow(10, decimals);
    num =
      (Math.round(num, decimals) +
        (num - Math.round(num, decimals) >= 0.4 ? 1 : 0)) /
      Math.pow(10, decimals);
    return num.toFixed(decimals);
  }
  useEffect(() => {
    if (packages.length <= 0) {
      fetchRoyalty();
    }
    return () => {};
  }, [ModalCreditVisible]);

  const disableModalLotBack = () => {
    setVisibleLotback(false);
  };
  const fetchRoyalty = () => {
    setIsLoading(true);
    get(process.env.REACT_APP_API_URL + "v2/Royalty")
      .then((res) => {
        setcurrentPiont(res.data.data.royal_plus);
        setPackages(res.data.data.packages);
        let total = 0;
        res.data.data.packages.map((e, i) => {
          total = Math.abs(e.point) + total;
        });

        setTotalPoint(Math.abs(res.data.data.total_credit));
        setTotalBalance(Math.abs(res.data.data.total_balance));
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const onFinish = (values) => {
    setButtonLoading(true);
    post(process.env.REACT_APP_API_URL + "v2/royalty", {
      account_trade_id: values.accountTo,
      amount: values.exchangPoint,
      type: type,
    })
      .then((res) => {
        setButtonLoading(false);
        setModalBalanceVisible(false);
        setModalCreditVisible(false);
        message.success(res.data.data);
        fetchRoyalty();
      })
      .catch((e) => {
        setButtonLoading(false);
        setModalBalanceVisible(false);
        setModalCreditVisible(false);
        try {
          message.error(e.response.data.error);
        } catch (error) {
          message.error(error.message);
        }
      });
  };

  const checkCreaditinput = (rule, value) => {
    if (value > currentpiont || value > setCredit || value > setBalance) {
      return Promise.reject(locale.t("dashbordCommission.irpoint_reject"));
    }
    if (value < 0.01) {
      return Promise.reject(
        locale.t("dashbordCommission.irpoint_more") + "0.01"
      );
    }
    return Promise.resolve();
  };

  const checkCreaditinputBalance = (rule, value) => {
    if (value > currentpiont || value > setCredit || value > setBalance) {
      return Promise.reject(locale.t("dashbordCommission.irpoint_reject"));
    }
    if (value < 0.02) {
      return Promise.reject(
        locale.t("dashbordCommission.irpoint_more") + "0.02"
      );
    }
    return Promise.resolve();
  };

  const validatorValue = (event) => {
    setCredit(event.target.value);
    setBalance(event.target.value);
    if (setCredit < 0 || setBalance < 0) {
      return Promise.reject(locale.t("dashbordCommission.irpoint_reject"));
    }
    return Promise.resolve();
  };

  let sum = packages.filter((packages, index) => packages.active === true);
  var sumUsingReduce = 0;
  var total = sum.reduce(function (sum, value) {
    return (sumUsingReduce += Number(value.point));
  }, 0);

  var packagesIrp = packages.filter((item) => item.active === true);
  var packagesFind = packagesIrp[packagesIrp.length - 1];

  const PackagesItem = (props) => (
    <div className="col-12 col-lg-4 px-2">
      <div>
        <div
          className={`${
            props.current ? "card-irp-style-active" : "card-irp-style"
          } d-flex justify-content-between align-items-center`}
        >
          <div>
            <div className="fs-20 font-weight-bold">
              {" "}
              {props.name.toUpperCase()}
            </div>
            <div className="fs-12 text-lightgray">{props.rate} Point / Lot</div>
            <div className="fs-24 font-weight-bold mt-3">
              {" "}
              {packagesIrp.length === props.royal_class
                ? total.toFixed(2) <= 0
                  ? "00.00"
                  : Math.floor(currentpiont * 100) / 100
                : "00.00"}
              &nbsp; Point
            </div>
          </div>
          <div>
            {" "}
            <img
              src={`/assets/images/icons/lotback/ic_lot_back_lv_${props.royal_class}.svg`}
              alt={`lotbacklv${props.royal_class}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
  const colors = ["#fff", "#fff", "#fff"];
  var packActive = packages.filter((item) => item.active === true);
  var packSelect = packActive[packActive.length - 1];
  const windowIner = window.innerWidth;
  return (
    <div>
      {!isLoading ? (
        <>
          <div style={{ marginTop: "20px" }} className="mx-auto">
            <div className="row">
              {packages.map((element, index) => {
                return (
                  <PackagesItem
                    key={index}
                    {...element}
                    index={index}
                    current={
                      element.royal_class === (props?.user?.royal_class || 1)
                    }
                  />
                );
              })}
            </div>
          </div>
          <div className="row mt-4">
            <div
              style={{ display: "inline-blog", padding: "0px 15px 0px 15px" }}
              className="col-12 col-lg-4 px-2 mt-2 "
            >
              <div>
                <div className="d-flex justify-content-between text-black">
                  <div className="status-irp-1">
                    <span
                      style={{ padding: "0px 0px 0px 10px" }}
                      className="fs-14"
                    >{`${locale.t("dashbordCommission.irpoint_status")}`}</span>
                  </div>
                  <div className="fs-18 fw-700">
                    <span>
                      {packSelect
                        ? packSelect.name
                        : locale.t("dashbordCommission.irpoint_name")}
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-2 text-black">
                  <div className="status-irp-1">
                    {/* <img
                alt="123"
                className="lotback-logo-curren"
                src={process.env.PUBLIC_URL + "/assets/images/royalplus/ct.png"}
              /> */}
                    <span
                      style={{ padding: "0px 0px 0px 10px" }}
                      className="fs-14"
                    >
                      {`${locale.t("dashbordCommission.irpoint_point")}`}
                    </span>
                  </div>
                  <div className="fs-14">
                    <span>
                      {currentpiont < 0
                        ? "00.00"
                        : Math.floor(currentpiont * 100) / 100}{" "}
                      Point
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-2 text-black">
                  <div className="status-irp-1">
                    <span
                      style={{ padding: "0px 0px 0px 10px" }}
                      className="fs-14 "
                    >
                      {`${locale.t("dashbordCommission.irpoint_total_credit")}`}
                    </span>
                  </div>
                  <div className=" fs-14">
                    <span>
                      {totalPoint < 0
                        ? "00.00"
                        : Math.floor(totalPoint * 100) / 100}
                      &nbsp;USD
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-2 text-black">
                  <div className="status-irp-1">
                    <span
                      style={{ padding: "0px 0px 0px 10px" }}
                      className="fs-14 "
                    >
                      {`${locale.t(
                        "dashbordCommission.irpoint_total_balance"
                      )}`}
                    </span>
                  </div>
                  <div className=" fs-14">
                    <span>
                      {totalBalance < 0
                        ? "00.00"
                        : Math.floor((totalBalance / 2) * 100) / 100}{" "}
                      USD
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4 px-2 mt-2">
              <div className="border-lotback-exchange mx-auto mt-md-0 ">
                <div className="d-flex justify-content-between aling-item-center">
                  <div>
                    <div>
                      <b>
                        <p className="fs-16 text-left">
                          {`${locale.t("dashbordCommission.irpoint_bonus")}`}
                        </p>
                      </b>
                    </div>
                    <div className=" fs-12 text-lightgray">{`${locale.t(
                      "dashbordCommission.irpoint_credit"
                    )}`}</div>
                  </div>
                  <div>
                    <div className="fw-700 " style={{ fontSize: 24 }}>
                      <b
                        style={{ fontWeight: "700", fontFamily: "sans-serif" }}
                      >
                        {currentpiont < 0
                          ? "00.00"
                          : (
                              Math.floor(currentpiont * 100) / 100
                            ).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                      </b>
                    </div>
                  </div>
                </div>
                <div className="botton-bonus-irp mt-4">
                  <Button
                    className="btn-primary-new-iux text-white w-100"
                    style={{ borderRadius: 8 }}
                    onClick={() => {
                      setType("credit");
                      setModalCreditVisible(true);
                    }}
                    disabled={currentpiont < 0}
                  >
                    {`${locale.t("dashbordCommission.botton_credit")}`}
                  </Button>
                </div>
              </div>
            </div>
            {/* ==============================================================Balance======================================================================== */}
            <div className="col-12 col-lg-4 px-2 mt-2">
              <div className="border-lotback-exchange m-auto mt-md-0 ">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <div>
                      <b>
                        <p className="fs-16 text-left">
                          {locale.t("dashboardWallet.accountBalance")}
                        </p>
                      </b>
                    </div>
                    <div className="fs-12 text-lightgray">
                      {`${locale.t("dashbordCommission.irpoint_cash")}`}
                    </div>
                  </div>
                  <div className="fw-700 " style={{ fontSize: 24 }}>
                    <b style={{ fontWeight: "700", fontFamily: "sans-serif" }}>
                      {currentpiont < 0
                        ? "00.00"
                        : fncToFixed(currentpiont / 2, 2).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "USD",
                            }
                          )}
                    </b>
                  </div>
                </div>
                <div className="botton-bonus-irp mt-4">
                  <Button
                    className="btn-primary-new-iux text-white w-100"
                    style={{ borderRadius: 8 }}
                    onClick={() => {
                      setType("balance");
                      setModalBalanceVisible(true);
                    }}
                    disabled={currentpiont < 0}
                  >
                    {locale.t("dashbordCommission.botton_cash")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="text-primary-new-iux cursor-pointer w-100 text-center py-4"
            onClick={() => onOpenPolicy("term_iux_market_lotback")}
          >
            {locale.t("contact_sidebar.lable_4")} LotBack
          </div>
        </>
      ) : (
        <>
          <div
            className="h-100 d-flex justify-content-center align-items-center flex-column my-5"
            style={{ height: 500 }}
          >
            <ClipLoader
              color="#36d7b7"
              size={72}
              cssOverride={{ borderWidth: 8 }}
            />
            <p className="mt-5 text-lightgray-iux">
              {locale.t("loadingtitle")}
            </p>
          </div>
        </>
      )}
      <Modal
        className="user"
        // title="IUX LotBack"
        visible={ModalBalanceVisible}
        onCancel={() => {
          form.resetFields();
          setCredit(0);
          setBalance(0);
          setModalBalanceVisible(false);
        }}
        footer={false}
      >
        <div className="px-4">
          <Form form={form} {...layout} name="balanceExchange">
            <div className="my-4">
              <h5 className="text-header-irp text-center font-weight-bold">
                {locale.t("dashbordCommission.irpoint_change_cash")}
              </h5>
            </div>
            {/* <div >
              <b className="text-content-irp">
                {locale.t("dashbordCommission.irpoint_current")}{" "}
              </b>
              <b style={{ color: "red", fontWeight: "700" }}>
                {currentpiont < 0
                  ? "0.00"
                  : Math.floor(currentpiont * 100) / 100}
              </b>
            </div> */}
            <div className="text-right text-lightgray fs-16">
              {locale.t("dashbordCommission.irpoint_current")}{" "}
              {currentpiont < 0
                ? "00.00"
                : Math.floor(currentpiont * 100) / 100}{" "}
              Point
            </div>
            <Col>
              <Form.Item
                name="exchangPoint"
                label="LotBack"
                rules={[{ validator: checkCreaditinputBalance }]}
                style={{ textAlign: "left" }}
              >
                <Input
                  prefix=""
                  suffix={<div className="text-lightgray">Point</div>}
                  type="number"
                  onChange={validatorValue}
                  style={styleInput}
                />
              </Form.Item>
              <Form.Item
                name="accountTo"
                label={locale.t("dashbordCommission.irpoint_account_to")}
                style={{ textAlign: "left" }}
                rules={[
                  {
                    required: true,
                    message: locale.t("dashboardWallet.input_error"),
                  },
                ]}
              >
                <Select
                  placeholder={locale.t(
                    "dashboardInnerTransfer.input1Placeholder"
                  )}
                  size="large"
                >
                  {props.user.accounts
                    .filter(
                      (item) =>
                        item.type === "real" &&
                        item.account_type !== "Cent" &&
                        item.account_type !== "ECN" &&
                        item.account_type !== "Raw" &&
                        item.account_type !== "Pro"
                    )
                    .map((account, index) => {
                      return (
                        <Option key={index} value={account.id}>
                          <div className="d-flex justify-content-between ">
                            <div className="fs-16">
                              <span>{account?.account_group?.display}</span>
                              &nbsp;{account.account_number}
                            </div>
                            <div
                              style={{
                                // fontWeight: "bold",
                                color:
                                  account.current_balance > 0
                                    ? "green"
                                    : "gray",
                              }}
                              className="text-right"
                            >
                              <div>
                                {`${account.current_balance.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "USD",
                                  }
                                )}`}
                              </div>
                              <div className="text-gray">
                                Credit:&nbsp;
                                {`${account.current_credit.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "USD",
                                  }
                                )}`}
                              </div>
                            </div>
                          </div>
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <div className="d-flex justify-content-center">
                <img
                  src="/assets/images/icons/lotback/exchange.svg"
                  alt="exchange"
                />
              </div>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.accounts !== currentValues.accounts
                }
              >
                {({ getFieldValue }) => {
                  return getFieldValue("accounts") !== 0 ? (
                    <Statistic
                      suffix={
                        <div className="text-lightgray pr-2">USD (Balance)</div>
                      }
                      name="customizeExchange"
                      placeholder="Current Change"
                      title={locale.t("you_will_get")}
                      value={fncToFixed(balance / 2, 2)}
                      valueStyle={{
                        textAlign: "left",
                        fontSize: "16px",
                        border: "1px solid #D9D9D9",
                        marginTop: "10px",
                        padding: "2px",
                        borderRadius: "8px",
                        backgroundColor: "#a9adb01c",
                        paddingLeft: "12px",
                        height: "48px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    />
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <div
              className={
                windowIner < 427
                  ? `d-flex justify-content-center mt-4`
                  : `d-flex justify-content-between mt-4`
              }
            >
              <Button
                className="btn-outline-iux"
                key="back"
                style={{ borderRadius: 10, minWidth: 140 }}
                onClick={() => {
                  form.resetFields();
                  setBalance(0);
                  setCredit(0);
                  setModalBalanceVisible(false);
                }}
              >
                {locale.t("dashbordCommission.irpoint_cancel")}
              </Button>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldsValue, getFieldsError }) => {
                  return (
                    <Button
                      className="btn-primary-new-iux "
                      htmlType="submit"
                      style={{ borderRadius: 10, minWidth: 140 }}
                      loading={buttonLoading}
                      disabled={
                        getFieldsValue().exchangPoint === undefined ||
                        getFieldsValue().accountTo === undefined ||
                        getFieldsError().filter(({ errors }) => errors.length)
                          .length > 0
                      }
                      onClick={() => {
                        form
                          .validateFields()
                          .then((values) => {
                            onFinish(values);
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }}
                    >
                      {locale.t("dashbordCommission.irpoint_confirm")}
                    </Button>
                  );
                }}
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
      <Modal
        className="user"
        // title="LotBack to Bonus"
        visible={ModalCreditVisible}
        onCancel={() => {
          form.resetFields();
          setCredit(0);
          setBalance(0);
          setModalCreditVisible(false);
        }}
        footer={false}
      >
        <div className="px-4">
          <Form form={form} {...layout} name="creditExchange">
            <div className="my-4">
              <h5 className="text-header-irp text-center font-weight-bold">{`${locale.t(
                "dashbordCommission.irpoint_change"
              )}`}</h5>
            </div>
            <div className="text-right text-lightgray fs-16">
              {locale.t("dashbordCommission.irpoint_current")}{" "}
              {currentpiont < 0
                ? "00.00"
                : Math.floor(currentpiont * 100) / 100}{" "}
              Point
            </div>
            <Col>
              <Form.Item
                name="exchangPoint"
                label={locale.t("exchange")}
                rules={[{ validator: checkCreaditinput }]}
                style={{ textAlign: "left" }}
              >
                <Input
                  prefix=""
                  suffix={<div className="text-lightgray">Point</div>}
                  type="number"
                  onChange={validatorValue}
                  style={styleInput}
                />
              </Form.Item>
              <Form.Item
                name="accountTo"
                label={locale.t("dashbordCommission.irpoint_account_to")}
                style={{ textAlign: "left" }}
                rules={[
                  {
                    required: true,
                    message: locale.t("dashboardWallet.input_error"),
                  },
                ]}
              >
                <Select
                  placeholder={locale.t(
                    "dashboardInnerTransfer.input1Placeholder"
                  )}
                  size="large"
                >
                  {props.user.accounts
                    .filter(
                      (item) =>
                        item.type === "real" &&
                        item.account_type !== "Cent" &&
                        item.account_type !== "ECN" &&
                        item.account_type !== "Raw" &&
                        item.account_type !== "Pro"
                    )
                    .map((account, index) => {
                      return (
                        <Option key={index} value={account.id}>
                          <div className="d-flex justify-content-between ">
                            <div className="fs-16">
                              <span>{account?.account_group?.display}</span>
                              &nbsp;{account.account_number}
                            </div>
                            <div
                              style={{
                                // fontWeight: "bold",
                                color:
                                  account.current_balance > 0
                                    ? "green"
                                    : "gray",
                              }}
                              className="text-right"
                            >
                              <div className="fs-16">
                                {`${account.current_balance.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "USD",
                                  }
                                )}`}
                              </div>
                              <div className="text-gray fs-12">
                                Credit:&nbsp;
                                {`${account.current_credit.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "USD",
                                  }
                                )}`}
                              </div>
                            </div>
                          </div>
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <div className="d-flex justify-content-center">
                <img
                  src="/assets/images/icons/lotback/exchange.svg"
                  alt="exchange"
                />
              </div>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.accounts !== currentValues.accounts
                }
              >
                {({ getFieldValue }) => {
                  return getFieldValue("accounts") !== 0 ? (
                    <Statistic
                      suffix={
                        <div className="text-lightgray pr-2">USD (Bonus)</div>
                      }
                      name="customizeExchange"
                      placeholder="Current Change"
                      title={locale.t("you_will_get")}
                      value={credit}
                      disabled
                      valueStyle={{
                        textAlign: "left",
                        fontSize: "16px",
                        border: "1px solid #D9D9D9",
                        marginTop: "10px",
                        padding: "2px",
                        borderRadius: "8px",
                        backgroundColor: "#a9adb01c",
                        paddingLeft: "12px",
                        height: "48px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    />
                  ) : null;
                }}
              </Form.Item>
            </Col>
            <div
              className={
                windowIner < 427
                  ? `d-flex justify-content-center mt-4`
                  : `d-flex justify-content-between mt-4`
              }
            >
              <Button
                className="btn-outline-iux"
                key="back"
                style={{ borderRadius: 10, minWidth: 140 }}
                onClick={() => {
                  form.resetFields();
                  setCredit(0);
                  setBalance(0);
                  setModalCreditVisible(false);
                }}
              >
                {locale.t("dashbordCommission.irpoint_cancel")}
              </Button>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldsValue, getFieldsError }) => {
                  return (
                    <Button
                      className="btn-primary-new-iux "
                      htmlType="submit"
                      style={{ borderRadius: 10, minWidth: 140 }}
                      loading={buttonLoading}
                      disabled={
                        getFieldsValue().exchangPoint === undefined ||
                        getFieldsValue().accountTo === undefined ||
                        getFieldsError().filter(({ errors }) => errors.length)
                          .length > 0
                      }
                      onClick={() => {
                        form
                          .validateFields()
                          .then((values) => {
                            onFinish(values);
                          })
                          .catch((info) => {});
                      }}
                    >
                      {locale.t("dashbordCommission.irpoint_confirm")}
                    </Button>
                  );
                }}
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = ({ user, i18n }) => {
  return { user, locale: i18n.locale };
};
export default connect(mapStateToProps, null)(Commission);
