import React, { useState, useEffect } from "react";
import {
  Select,
  Button,
  Input,
  Icon,
  Radio,
  Row,
  Col,
  message,
  Form,
  Modal,
} from "antd";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { get, post } from "../../helper/request.js";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import FooterLandingPage from "../../components/footer-landing-page/FooterDark.js";
import { getBank, getUser } from "../../actions/user.js";
import { useHistory } from "react-router-dom";
const TITLE = "Contact Partner | IUX Markets";

const locale = require("react-redux-i18n").I18n;
const { Option } = Select;
const flagStyle = {
  width: 20,
  height: 20,
};

function FormPartner(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  let urlParams = new URLSearchParams(window.location.search);
  let access_token = urlParams?.get("access_token");
  const [form] = Form.useForm();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [countries, setCuntries] = useState([]);
  const [location, setLocation] = useState(0);
  const [invesEx, setInvesEx] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const setLocationChange = (value) => {
    setLocation(value);
  };
  const setEx = (value) => {
    setInvesEx(value);
  };
  const exdata = [
    { value: "น้อยกว่า 1 ปี", title: locale.t("radio_Partner.text_1") },
    { value: "มากกว่า 1 ปี", title: locale.t("radio_Partner.text_2") },
    { value: "มากกว่า 3 ปี", title: locale.t("radio_Partner.text_3") },
    { value: "มากกว่า 5 ปี", title: locale.t("radio_Partner.text_4") },
  ];
  useEffect(() => {
    localStorage.removeItem("join_us");
    if (countries.length <= 0) {
      get(process.env.REACT_APP_API_URL + "countries")
        .then((res) => {
          setCuntries(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }

    return () => {};
  }, []);
  useEffect(() => {
    if (access_token) {
      setIsLoading(true);
      onJoinus();
    } else if (!localStorage.getItem("access_token")) {
      history.push(
        `/${localStorage.getItem("locale")}/wallet/account/Standard`
      );
    }
    // localStorage.getItem("access_token");
    // console.log(
    //   access_token,
    //   "access_token",
    //   localStorage.getItem("access_token")
    // );
  }, [access_token]);
  const onJoinus = async () => {
    try {
      let urlParams = new URLSearchParams(window.location.search);
      let access_token = urlParams.get("access_token");
      let refresh_token = urlParams.get("refresh_token");
      let access_token_expires_at = urlParams.get("access_token_expires_at");
      if (access_token) {
        localStorage.setItem("expires_in", access_token_expires_at + 7);
        localStorage.setItem("refresh_token", refresh_token);
        localStorage.setItem("join_us", true);
        Promise.all([localStorage.setItem("access_token", access_token)]);

        dispatch(getUser());
        dispatch(getBank());
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const onFinish = (values) => {
    setButtonLoading(true);

    post(process.env.REACT_APP_API_URL + "v2/affiliate", {
      phone: values.phone,
      site: values.site || "",
      experience: invesEx,
      country_id: location,
      note: values.note || "",
      // firstname: values.firstname,
      // lastname: values.lastname,
      // email: values.email,
      // phone: values.phone,
      // site: values.site,
      // note: values.note,
      // experience: invesEx,
    })
      .then((res) => {
        setButtonLoading(false);
        Modal.success({
          title: `${locale.t("partner_request.success")}`,
          content: res.data.message,
          onOk() {
            window.location.href = "./login";
          },
        });
      })
      .catch((e) => {
        setButtonLoading(false);
        try {
          if (e.response.data.code === 8000) {
            message.error(
              "Please waiting for contact form IUXMarkets partner manager"
            );
          }
          if (e.response.data.code === 8001) {
            message.error("Email is already exists");
          }
          if (e.response.data.code === 8004) {
            message.error(
              "Sorry, Your account doesn't pass our criterion please contact support@partner.com"
            );
          }
        } catch (error) {
          message.error("This is an error message");
        }
      });
  };

  return (
    <>
      <Form
        form={form}
        name="basic"
        className=" bg-black"
        style={{
          backgroundImage: "url('/assets/images/covers/bg-partner-form.png')",
          backgroundSize: "cover",
        }}
      >
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className=" container">
          <Row className="resolution-display row">
            <div className="col-12 col-md-6">
              <h2 className="text-uppercase fs-56-lot text-white">
                Partnership programs
              </h2>
              <p className="text-white text-left">
                {locale.t("partner_header.description")}
              </p>
            </div>

            {isLoading || user?.loading ? (
              <div>loading......</div>
            ) : (
              <div className="w-100 col-12 col-md-6">
                <section className=" d-flex align-content-center flex-wrap justify-content-center">
                  <div className=" text-center rectangle-card-partner">
                    <h4 className="mt-4 text-white fs-24 text-uppercase px-5 mx-2">
                      {locale.t("form_partner.title")}
                    </h4>
                    <div className="text-left text-gray mb-1 mt-4">
                      {/* {locale.t("register_choose_region")} */}
                      Region
                    </div>
                    {/* <Form.Item label="" name="country_id">
                    <div
                      className="d-flex justify-content-center"
                      style={{ width: "100%" }}
                    >
                      <Select
                        style={{ textAlign: "left", width: "100%" }}
                        size="large"
                        onChange={setLocationChange}
                        placeholder={locale.t("register_choose_region")}
                      >
                        {countries.map((country, index) => {
                          return (
                            <Option key={index} value={country.id}>
                              {
                                <img
                                  className="mr-2 mb-1 rounded"
                                  style={flagStyle}
                                  src={`${process.env.PUBLIC_URL}/assets/images/icons/${country.image}`}
                                  alt=""
                                />
                              }
                              {country.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </Form.Item> */}
                    <Form.Item label="" name="firstname">
                      <div
                        className="d-flex justify-content-start  border-input align-items-center pl-2"
                        style={{ height: 50 }}
                      >
                        <div className="text-gray text-left">
                          {countries.find(
                            (e) => e.id === user?.profile?.country_id
                          ) &&
                            countries.find(
                              (e) => e.id === user?.profile?.country_id
                            ).name}
                        </div>
                      </div>
                    </Form.Item>
                    <div className="text-left text-gray mb-1 mt-4">
                      {locale.t("register_firstname")}
                    </div>
                    <Form.Item label="" name="firstname">
                      <div
                        className="d-flex justify-content-start  border-input align-items-center pl-2"
                        style={{ height: 50 }}
                      >
                        <div className="text-gray text-left">
                          {user.userVerify.first_name}
                        </div>
                      </div>
                    </Form.Item>
                    <div className="text-left text-gray mb-1 mt-4">
                      {locale.t("register_lastname")}
                    </div>
                    <Form.Item label="" name="lastname">
                      <div
                        className="d-flex justify-content-start  border-input align-items-center pl-2"
                        style={{ height: 50 }}
                      >
                        <div className="text-gray text-left">
                          {user.userVerify.last_name}
                        </div>
                      </div>
                    </Form.Item>
                    <div className="text-left text-gray mb-1 mt-4">
                      {locale.t("register_email")}
                    </div>
                    <Form.Item label="" name="email">
                      <div
                        className="d-flex justify-content-start  border-input align-items-center pl-2"
                        style={{ height: 50 }}
                      >
                        <div className="text-gray text-left">
                          {user?.profile?.email}
                        </div>
                      </div>
                    </Form.Item>
                    <div className="text-left text-gray mb-1 mt-4">
                      {locale.t("register_phone") + " *"}
                    </div>
                    <Form.Item
                      label=""
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: locale.t("form_partner.validatePhone"),
                        },
                      ]}
                    >
                      <div className="d-flex justify-content-center ">
                        <Input
                          style={{ height: "50px" }}
                          placeholder={locale.t("register_phone")}
                          type="text"
                          onKeyPress={(event) => {
                            if (!/[0-9,+]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </Form.Item>
                    <div className="text-left text-gray mb-1 mt-4">
                      {locale.t("register_url")}
                    </div>
                    <Form.Item
                      label=""
                      name="site"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: locale.t("form_partner.validateWebsite"),
                      //   },
                      // ]}
                    >
                      <div className="d-flex justify-content-center">
                        <Input
                          style={{ height: "50px" }}
                          placeholder={"URL link"}
                          type="text"
                        />
                      </div>
                    </Form.Item>
                    <div className="text-left text-gray mb-1 mt-4">
                      {locale.t("register_detail")}
                    </div>
                    <Form.Item
                      label=""
                      name="note"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: locale.t("form_partner.validateNote"),
                      //   },
                      // ]}
                    >
                      <div className="d-flex justify-content-center ">
                        <Input
                          style={{ height: "50px" }}
                          placeholder={locale.t("register_detail")}
                          type="text"
                        />
                      </div>
                    </Form.Item>
                    <div className="text-left text-gray mb-1 mt-4">
                      {locale.t("form_partner.text_2")}
                    </div>
                    <Form.Item label="" name="experience">
                      <div
                        className="d-flex justify-content-center"
                        style={{ width: "100%" }}
                      >
                        <Select
                          style={{ textAlign: "left", width: "100%" }}
                          size="large"
                          onChange={setEx}
                          placeholder={"Choose your experience"}
                        >
                          {exdata.map((ex, index) => {
                            return (
                              <Option key={index} value={ex.value}>
                                {ex.title}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    </Form.Item>
                    <div className="text-gray">
                      Note: Please note that should your application go through,
                      you will need to have an activated account with
                      IUXMARKETS. You can open an account from{" "}
                      <Link
                        to={`/${localStorage.getItem("locale")}/login`}
                        className="text-white"
                      >
                        here
                      </Link>
                    </div>
                    <Button
                      loading={buttonLoading}
                      shape="round"
                      className="mt-4 btn-yellow-new-iux"
                      type="primary"
                      htmlType="submit"
                      onClick={() => {
                        form
                          .validateFields()
                          .then((values) => {
                            onFinish(values);
                          })
                          .catch((info) => {
                            console.log("Validate Failed:", info);
                          });
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                </section>
              </div>
            )}
          </Row>

          <section key={10}>
            <FooterLandingPage locale={locale} dark />
          </section>
        </div>
      </Form>
    </>
  );
}
const mapStateToProps = ({ i18n }) => ({
  locale: i18n.locale,
});
export default connect(mapStateToProps, null)(FormPartner);
