export default {
  th: {
    transaction_title: "Transaction",
    depositPage: {
      CoverTitle: "การฝาก-ถอน ที่รวดเร็วและปลอดภัย\n",
      CoverSubTitle:
        "คุณสามารถชำระเงินในประเทศหรือระหว่างประเทศได้จากทุกที่ที่คุณอยู่ ไม่ว่าจะเป็นที่บ้านหรือในขณะเดินทาง ได้อย่างรวดเร็วเพียงไม่กี่นาทีและช่องทางฝากถอนที่ครบถ้วนที่สุดทุกธนาคาร\n",
      CoverButtonText: "เริ่มทำการ ฝาก-ถอน",
      SectionTitle1: "สามารถ ฝาก-ถอน ได้อย่างไร\n",
      SectionSubTitle1: "",
      SectionBox1: "สมัครสมาชิกหรือเข้าสู้ระบบ\n",
      SectionBox2: "เลือกธนาคารที่คุณใช้งานอยู่แล้วดำเนินกการ ฝาก-ถอน\n\n",
      SectionBox3: "ฟรีค่าธรรมเนียม ฝาก/ถอน\n",
      SectionBox4: "เงินเข้าเพียงไม่กี่นาที\n",
      SectionTitle2: "ตัวเลือกการ ฝาก-ถอน",
      SectionSubTitle2:
        "เรารักษาข้อมูลทางการเงินของคุณอย่างปลอดภัยด้วยการเข้ารหัส คุณจึงสามารถชำระเงินออนไลน์ได้อย่างมั่นใจ\n",
      dialog: {
        title: "คุณทำธุรกรรมเสร็จแล้วใช่หรือไม่?",
        description: "คุณสามารถกด ตกลง เมื่อการทำธุรกรรมเสร็จสมบูรณ์",
        okButton: "ตกลง",
        cancelButton: "ยกเลิก",
      },
      accept: {
        header: `ตัวเตือนความปลอดภัย`,
        subHeader: `เพื่อให้แน่ใจว่าคำขอ ฝาก-ถอนเงิน ของคุณได้รับการยอมรับ โปรดตอบคำถามก่อนที่จะเลือกเคลือข่าย ERC20`,
        title: `*คุณยืนยันหรือไม่ว่าแพลตฟอร์มหรือแอปพลิเคชันที่คุณกำลัง ฝาก-ถอน รองรับเคลือข่าย ERC20*`,
        acc_ok: `ใช่ ฉันได้ยืนยันกับพวกเขาโดยตรงว่าพวกเขาสนับสนุนเครือข่าย ERC20`,
        acc_cancel: `ไม่ ฉันไม่ได้ยืนยันกับพวกเขาโดยตรงว่าพวกเขาสนับสนุนเครือข่าย ERC20`,
        confirm: `ตกลง`,
        pl: `** หากคุณ ฝาก-ถอนทรัพย์สินของคุณผ่านเครือข่าย ERC20 ไปยังที่อยู่และแพลตฟอร์มปลายทาง
        หรือแอปพลิเคชันที่ไม่รับรองเคลือข่าย (ERC20) คุณจะสูญเสียทรัพย์สินของคุณโดยทางบริษัทจะไม่ได้รับผิดชอบ`,
      },
    },
    faq: {
      notice_title: "ข้อกำหนด",
      notice:
        "* โปรดทราบว่าการฝากและถอนเงินของท่านจะขึ้นอยู่กับอัตราแลกเปลี่ยนของตัวกลางที่ท่านใช้ในการถอนเงิน โปรดตรวจสอบให้แน่ใจว่าอัตราแลกเปลี่ยนใดเหมาะสมที่สุดในการถอนแต่ละครั้ง",
      title_faq: "คำถามที่พบบ่อย",
      viewmore: "แสดงเพิ่มเติม",
      tooltip:
        "An appeal can be submitted if you have already submitted your deposit order but have exceeded the estimated processing duration. ",
      faq_title_1: "ฝากเงินผ่านช่องทาง QR อย่างไร ?",
      faq_des_1: "สามารถเลือกฝากเงินผ่านช่องทาง QR Code",
      faq_title_2: "ฉันสามารถฝากเงินในช่วงวันหยุดได้หรือไม่?",
      faq_des_2: "คุณสามารถฝาก ในช่วงวันหยุดได้ตามปกติ",
      faq_title_3: "ใช้ระยะเวลาฝากนานเท่าใด?",
      faq_des_3: "10 - 15 วินาที เมื่อคุณทำธุรกรรมเสร็จสิ้น",
      faq_title_4:
        "ในกรณีที่ยอดคงเหลือติดลบ เงินฝากจะถูกรีเซ็ตยอดคงเหลือหรือไม่?",
      faq_des_4: "ยอดเงินติดลบของคุณจะถูกรีเซ็ทเป็น 0 เมื่อคุณมีการฝากเงินใหม่",
      faq_title_5: "วิธีรับโบนัส $30 ?",
      faq_des_5:
        "รับโบนัส $30 แบบไม่มีเงินฝากในบัญชีต้อนรับเมื่อยืนยันตัวตน (POI) และบัญชีธนาคาร (POB) สำเร็จ",
      faq_footer:
        "**โปรดทราบว่าการฝากและการถอนของท่านจะเป็นต้องเป็นชื่อที่ตรงกันกับข้อมูลที่ท่านทำการยืนยันเข้ามาเท่านั้น เพื่อหลีกเลี่ยงปัญหาการถอนเงินที่อาจจะเกิดขึ้นหากข้อมูลไม่ตรงกันกับการลงทะเบียน",
    },
    deposit_description: {
      qr_payment: ``,
      visamaster: `เข้าทันที `,
      paypal: ``,
      skrill: ``,
      neteller: ``,
    },
    deposit_info: {
      success: {
        title: "การฝากเงินเสร็จสิ้น",
        description: "ระยะเวลาในการฝาก : ประมาณ 1-3 นาที ",
      },
      warning1:
        "โปรดทราบว่าการฝากเงินของท่านจะขึ้นอยู่กับอัตราแลกเปลี่ยนของตัวกลางที่ท่านใช้ในการ ฝากเงิน โปรดตรวจสอบให้แน่ใจว่าอัตราแลกเปลี่ยนใดเหมาะสมที่สุดในการฝากแต่ละครั้ง ",
      warning2:
        "และโปรดทราบว่าการฝากและการถอนของท่านจำเป็นต้องเป็นชื่อที่ตรงกันกับข้อมูลที่ท่านทำการยืนยันเข้ามาเท่านั้น เพื่อหลีกเลี่ยงปัญหาการถอนเงินที่อาจจะเกิดขึ้นหากข้อมูลไม่ตรงกันกับการลงทะเบียน",
      mt_account: "บัญชี MT5",
      symbol: "ค่าเงิน",
      confirm: "ยืนยันการฝากเงิน",
      amount: "จำนวนเงินที่ฝาก (USD)",
      amount_cent: "จำนวนเงินที่ฝาก (USC)",
      currency_rate: "ค่าเงินปัจจุบัน",
      bank: {
        book_bank_name: "ชื่อบัญชี",
        number: "เลขบัญชีธนาคาร",
      },
      payment: {
        amount: "จำนวนเงินฝาก",
        confirm_amount: "ยืนยัน จำนวนเงินฝาก",
        input_date: "วันที่",
        input_file: "อัพโหลดไฟล์",
        input_file_description: "อัพโหลดหลักฐานการฝากเงิน",
      },
      attention: {
        title: "โปรดทราบ",
        description:
          "การโอนเงินที่โอนมาจากบัญชีธนาคารจะต้องเป็นบัญชีเดียวกันกับผู้ที่โอนระบุในข้อมูลของบัญชีเทรด IUX Markets.com จะไม่ดำเนินการโอนเงินให้กับบุคคลที่ 3 ลูกค้าที่ต้องการโอนเงินจะต้องระบุแบบฟอร์มต่อไปนี้ในขณะที่ดำเนินการโอนเงิน : invoice ของผู้ให้บริการการชำระเงิน (Service payment invoice) หรือหมาย เลขบัญชีเทรดของลูกค้า ยกตัวอย่างเช่น Service payment invoice 123456 ชื่อผู้ส่งที่เขียนเป็นภาษาอังกฤษ",
      },
      prompt: {
        title: "โปรดทราบ",
        description:
          "เมื่อโอนเงินผ่าน qr code prompt pay สำเร็จ. ระบบจะทำการฝากเงินเข้าบัญชี โดยอัตโนมัติ",
      },
      visa_master: {
        title: "cvv คืออะไร",
        description:
          "CVV คือหมายเลขสามหรือสี่หลักของบัตรเครดิตแต่ละใบ และปรากฏอยู่เฉพาะบนบัตรเครดิตที่จับต้องได้เท่านั้น",
      },
      warning_tp: {
        Title: "ปิดปรับปรุงชั่วคราว",
      },
    },
    deposit_step: {
      no_account: "คุณยังไม่มีบัญชี MT5 กรุณาเปิดบัญชีก่อนดำเนินงาน",
      account: "บัญชี",
      first_step: {
        deposit_with: "ช่องทางการฝากเงิน",
        recommended: "ที่แนะนำ",
        show_all_method: "แสดงช่องทางการฝากเงินทั้งหมด",
        title: "ขั้นตอนที่ 1",
        description: "เลือกบัญชี",
        choose_account: "เลือกบัญชี",
      },
      second_step: {
        title: "ใส่จำนวนเงิน",
        description: "จำนวน",
        you_receive: "ยอดเงินที่คุณได้รับ",
        fee: "ค่าธรรมเนียมการทำธุรกรรม",
        amount_more_than: "	จำนวนเงินควรมากกว่า	",
        minimun_requirement: "	ไม่เป็นไปตามข้อกำหนดขั้นต่ำ	",
        minimun_deposit: "	เงินฝากขั้นต่ำ	",
      },
      third_step: {
        title: "ขั้นตอนที่ 2",
        description: "วิธีการชำระเงิน",
        go_account: "ไปที่หน้าบัญชีของฉัน",
      },
    },
    deposit_error: {
      input: "กรุณากรอกข้อมูล",
      account: "กรุณาเลือกบัญชีของคุณ",
      amount: "ขั้นต่ำการฝากจำนวนเงินต้องมากกว่า 3 ดอลลาร์สหรัฐ",
      h2p: "ขั้นต่ำการฝากจำนวนเงินต้องมากกว่า",
      ecn: "ขั้นต่ำการฝากจำนวนเงินต้องมากกว่า 200 ดอลลาร์สหรัฐ",
      raw: "ขั้นต่ำการฝากจำนวนเงินต้องมากกว่า 1000 ดอลลาร์สหรัฐ",
      pro: "ขั้นต่ำการฝากจำนวนเงินต้องมากกว่า 1000 ดอลลาร์สหรัฐ",
      nomore: "การฝากเงินต้องไม่เกิน",
    },
    error_deposit: {
      text_1: "ยังไม่พร้อม ให้บริการในภูมิภาคของคุณ",
    },
  },
  la: {
    transaction_title: "ການເຮັດທຸລະກໍາ",
    depositPage: {
      CoverTitle: "ການຖອນເງິນຝາກທີ່ໄວແລະປອດໄພ.  n",
      CoverSubTitle:
        "ທ່ານສາມາດຈ່າຍຄ່າພາຍໃນຫຼືຕ່າງປະເທດຈາກບ່ອນໃດກໍ່ຕາມທີ່ທ່ານຢູ່. ບໍ່ວ່າຈະຢູ່ເຮືອນຫຼືໃນຂະນະທີ່ເດີນທາງໄປໄວ, ພຽງແຕ່ສອງສາມນາທີແລະວິທີການເງິນທີ່ຄົບຖ້ວນທີ່ສຸດແລະການຖອນເງິນສໍາລັບທຸກທະນາຄານ.  n",
      CoverButtonText: "ເລີ່ມຕົ້ນຝາກເງິນ",
      SectionTitle1: "ວິທີການເງິນຝາກ?  n",

      SectionBox1: "ລົງທະບຽນແລະເຂົ້າສູ່ລະບົບ  n",
      SectionBox2: "ເລືອກທະນາຄານທີ່ທ່ານກໍາລັງໃຊ້ແລະດໍາເນີນການເງິນຝາກເງິນທີ່  n",
      SectionBox3: "ສູນ - ຄ່າທໍານຽມສໍາລັບການຝາກແລະການຖອນເງິນ  n",
      SectionBox4: "ເງິນຝາກແລະການຖອນເງິນໃນເວລາສອງສາມນາທີ  n",
      SectionTitle2: "ຕົວເລືອກເງິນຝາກແລະການຖອນເງິນ  n",
      SectionSubTitle2:
        "ພວກເຮົາຮັກສາຂໍ້ມູນການເງິນຂອງທ່ານໃຫ້ປອດໄພດ້ວຍການເຂົ້າລະຫັດ. ສະນັ້ນທ່ານສາມາດຈ່າຍເງິນ online ດ້ວຍຄວາມຫມັ້ນໃຈ.  n",
      dialog: {
        title: "ທ່ານໄດ້ເຮັດສໍາເລັດການເຮັດທຸລະກໍາແລ້ວບໍ?",
        description: "ທ່ານສາມາດກົດ OK ເມື່ອການເຮັດທຸລະກໍາຄົບຖ້ວນ.",
        okButton: "ຕົກ​ລົງ",
        cancelButton: "ຍົກເລີກ",
      },
      accept: {
        header: "ການແຈ້ງເຕືອນຄວາມປອດໄພ",
        subHeader:
          "ເພື່ອໃຫ້ແນ່ໃຈວ່າການຮ້ອງຂໍເງິນຝາກແລະການຖອນເງິນຂອງທ່ານໄດ້ຮັບການອະນຸມັດ, ກະລຸນາຕອບຄໍາຖາມເຫຼົ່ານີ້ກ່ອນທີ່ຈະເລືອກເຄືອຂ່າຍ ERC20",
        title:
          "ທ່ານໃນທາງບວກບໍທີ່ເວທີຫຼືການນໍາໃຊ້ຂອງທ່ານທ່ານກໍາລັງໃຊ້ສໍາລັບເຄືອຂ່າຍ ERC20?",
        acc_ok:
          "ແມ່ນແລ້ວ, ຂ້າພະເຈົ້າໄດ້ຢືນຢັນໂດຍກົງກັບພວກເຂົາໂດຍກົງວ່າພວກເຂົາສະຫນັບສະຫນູນເຄືອຂ່າຍ ERC20.",
        acc_cancel:
          "ບໍ່, ຂ້ອຍບໍ່ໄດ້ຢືນຢັນໂດຍກົງກັບພວກເຂົາໂດຍກົງວ່າພວກເຂົາສະຫນັບສະຫນູນເຄືອຂ່າຍ ECR20.",
        confirm: "ຢືນຢັນ",
        pl: "** ຖ້າທ່ານຝາກຊັບສິນຂອງທ່ານຜ່ານເຄືອຂ່າຍ ERC20 ໄປທີ່ທີ່ຢູ່ແລະສະຫມັກປາຍທາງຫຼືສະຫມັກທີ່ບໍ່ແນ່ນອນ (ERC20). ທ່ານຈະສູນເສຍຊັບສິນຂອງທ່ານໂດຍບໍ່ມີບໍລິສັດຖືກຮັບຜິດຊອບ.",
      },
    },
    faq: {
      notice_title: "ແຈ້ງການ",
      notice:
        "ເງິນຝາກແລະການຖອນເງິນຂອງທ່ານຕ້ອງຢູ່ໃນນາມດຽວກັນກັບຂໍ້ມູນທີ່ທ່ານກວດສອບ. ເພື່ອຫລີກລ້ຽງບັນຫາການຖອນເງິນທີ່ອາດຈະເກີດຂື້ນຖ້າຂໍ້ມູນແມ່ນບໍ່ສອດຄ່ອງກັບການລົງທະບຽນ.",
      title_faq: "ສົງໄສ",
      viewmore: "ເບິ່ງເພີ່ມເຕີມ",
      tooltip:
        "ການອຸທອນສາມາດສົ່ງໄດ້ຖ້າທ່ານໄດ້ສົ່ງໃບສັ່ງຊື້ຂອງທ່ານແລ້ວແຕ່ໄດ້ລື່ນກາຍໄລຍະເວລາໃນການປຸງແຕ່ງທີ່ຄາດຄະເນແລ້ວ.",
      faq_title_1: "ວິທີການຝາກເງິນຜ່ານລະຫັດ QR?",
      faq_des_1: "ທ່ານສາມາດເລືອກລະຫັດເງິນຝາກ QR Method",
      faq_title_2: "ຂ້ອຍສາມາດຝາກເງິນໃນວັນພັກຜ່ອນໄດ້ບໍ່?",
      faq_des_2: "ທ່ານສາມາດຝາກເງິນໃນວັນພັກຜ່ອນ",
      faq_title_3: "ມັນໃຊ້ເວລາດົນປານໃດໃນການຝາກເງິນ?",
      faq_des_3: "10 - 15 ວິນາທີເມື່ອທ່ານສໍາເລັດການເຮັດສໍາເລັດແລ້ວ",
      faq_title_4: "ໃນກໍລະນີທີ່ຍອດເງິນແມ່ນລົບ, ເງິນຝາກຈະຕັ້ງຄ່າໃຫ້ສົມດຸນບໍ?",
      faq_des_4:
        "ຍອດເງິນໃນແງ່ລົບຂອງທ່ານຈະຖືກຕັ້ງຄ່າໃຫ້ 0 ເມື່ອທ່ານມີເງິນຝາກໃຫມ່.",
      faq_title_5: "ວິທີການຮັບໂບນັດ $30?",
      faq_des_5:
        "ພຽງສະຫມັກເປີດບັນຊີ Welcome Standard ໂດຍບໍ່ມີການຝາກເງິນ ຢືນຢັນຕົວຕົນຂອງທ່ານ(POI)ແລະຢືນຢັນທະນາຄານ(POB) ທ່ານຈະໄດ້ຮັບໂບນັດ $30 ພາຍໃນໜ້າບັນຊີຂອງທ່ານ",
      faq_footer:
        "** ແລະກະລຸນາຮັບຊາບວ່າການຝາກເງິນແລະການຖອນເງິນຂອງທ່ານຕ້ອງຢູ່ໃນຊື່ດຽວກັນກັບຂໍ້ມູນທີ່ທ່ານກວດສອບ. ເພື່ອຫລີກລ້ຽງບັນຫາການຖອນເງິນທີ່ອາດຈະເກີດຂື້ນຖ້າຂໍ້ມູນແມ່ນບໍ່ສອດຄ່ອງກັບການລົງທະບຽນ.",
    },
    deposit_info: {
      success: {
        title: "ຢ່າງຈະສໍາເລັດ",
        description: "ເວລາເງິນຝາກ: ປະມານ 1-3 ນາທີ",
      },
      warning1:
        "ກະລຸນາຮັບຊາບວ່າເງິນຝາກຂອງທ່ານຈະຂື້ນກັບອັດຕາແລກປ່ຽນຂອງຕົວກາງຂອງຕົວກາງທີ່ທ່ານໃຊ້.",
      warning2:
        "ແລະກະລຸນາຮັບຊາບວ່າການຝາກເງິນແລະການຖອນເງິນຂອງທ່ານຕ້ອງຢູ່ໃນຊື່ດຽວກັນກັບຂໍ້ມູນທີ່ທ່ານກວດສອບ. ເພື່ອຫລີກລ້ຽງບັນຫາການຖອນເງິນທີ່ອາດຈະເກີດຂື້ນຖ້າຂໍ້ມູນແມ່ນບໍ່ສອດຄ່ອງກັບການລົງທະບຽນ.",
      mt_account: "ບັນຊີ MT5",
      symbol: "ເງິນຕາ",
      confirm: "ຢືນຢັນ",
      amount: "ຈໍານວນເງິນ (ໂດລາສະຫະ)",
      amount_cent: "ຈໍານວນເງິນ (USC)",
      currency_rate: "ອັດຕາສະກຸນເງິນ",
      bank: {
        book_bank_name: "ຊື່ບັນຊີ",
        number: "ບັນ​ຊີ​ທະ​ນາ​ຄານ",
      },
      payment: {
        amount: "ຈໍາ​ນວນ",
        confirm_amount: "ຢືນຢັນຈໍານວນເງິນ",
        input_date: "ວັນທີເງິນຝາກ",
        input_file: "ອັບໂຫລດເອກະສານ",
        input_file_description: "ອັບໂຫລດບ່ອນນີ້.",
      },
      attention: {
        title: "ຄວາມສົນໃຈ",
        description:
          "ການໂອນເງິນທີ່ໂອນຈາກບັນຊີທະນາຄານຕ້ອງເປັນບັນຊີດຽວກັນກັບຜູ້ທີ່ລະບຸໄວ້ໃນຂໍ້ມູນບັນຊີການຄ້າ. ເງິນຕາ Iux.com. Markets.com ຈະບໍ່ຖືກດໍາເນີນການໃຫ້ເປັນຝ່າຍທີ 3. ລູກຄ້າທີ່ຕ້ອງການໂອນເງິນຕ້ອງລະບຸຮູບແບບຟອມຕໍ່ໄປນີ້ໃນຂະນະທີ່ປະມວນຜົນການໂອນຍ້າຍ: ໃບເກັບເງິນຈ່າຍຄ່າບໍລິການຫຼືເລກບັນຊີ. ຍົກຕົວຢ່າງຂອງລູກຄ້າ, ການຈ່າຍເງິນໃບເກັບເງິນໃນການບໍລິການ 123456 ຜູ້ສົ່ງຊື່ເປັນພາສາອັງກິດ.",
      },
      prompt: {
        title: "ຄວາມສົນໃຈ",
        description:
          "ເມື່ອໂອນເງິນຜ່ານລະຫັດ QR, ການຈ່າຍເງິນທີ່ປະສົບຜົນສໍາເລັດໃນລະບົບຈະຝາກເງິນໂດຍອັດຕະໂນມັດເຂົ້າບັນຊີ.",
      },
      visa_master: {
        title: "CVV ແມ່ນຫຍັງ, cvc",
        description:
          "CVV ແມ່ນສາມຫລືສີ່ຕົວເລກຂອງແຕ່ລະບັດເຄຼດິດ. ແລະພຽງແຕ່ປະກົດຕົວໃນບັດເຄດິດທີ່ມີຄວາມຫມາຍ",
      },
      warning_tp: {
        Title: "ການບໍາລຸງຮັກສາຊົ່ວຄາວ",
      },
    },
    deposit_step: {
      no_account: "ທ່ານບໍ່ມີບັນຊີ MT5. ກະລຸນາລົງທະບຽນບັນຊີ MT5 ກ່ອນຂະບວນການນີ້",
      account: "ບັນຊີ",
      first_step: {
        deposit_with: "ຝາກ",
        recommended: "ແນະນໍາ",
        show_all_method: "ສະແດງທຸກວິທີການ",
        title: "ຂັ້ນຕອນທີ 1",
        description: "ເລືອກບັນຊີ",
        choose_account: "ເລືອກບັນຊີ",
      },
      second_step: {
        title: "ໃສ່ຈໍານວນ",
        description: "ຈໍາ​ນວນ",
        you_receive: "ທ່ານໄດ້ຮັບ",
        fee: "ຄ່າທໍານຽມການເຮັດທຸລະກໍາ",
        amount_more_than: "	ຈໍານວນເງິນຄວນຫຼາຍກ່ວາ	",
        minimun_requirement: "	ບໍ່ຕອບສະຫນອງຄວາມຕ້ອງການຂັ້ນຕ່ໍາ	",
        minimun_deposit: "	ເງິນຝາກຂັ້ນຕ່ໍາ	",
      },
      third_step: {
        title: "ຂັ້ນຕອນທີ 2",
        description: "ການຈ່າຍເງິນ",
        go_account: "ໄປທີ່ການບັນຊີຂອງຂ້ອຍ",
      },
    },
    deposit_error: {
      input: "ກະລຸນາຕື່ມຄ່າທີ່ຢູ່ທີ່ນີ້.",
      account: "ບັນຊີຂອງທ່ານບໍ່ໄດ້ຖືກຄັດເລືອກເທື່ອ.",
      amount: "ຈໍານວນເງິນຄວນມີຫຼາຍກ່ວາ 3 ໂດລາ.",
      h2p: "ຈໍານວນເງິນຄວນຫຼາຍກ່ວາ",
      ecn: "ຈໍານວນເງິນຄວນມີຫຼາຍກ່ວາ 200 ໂດລາສະຫະລັດ",
      raw: "ຈໍານວນເງິນຄວນມີຫຼາຍກ່ວາ 1000 USD",
      pro: "ຈໍານວນເງິນຄວນມີຫຼາຍກ່ວາ 1000 USD",
      nomore: "ເງິນຝາກສູງສຸດ",
    },
    error_deposit: {
      text_1: "ຍັງບໍ່ທັນມີໃນຂົງເຂດຂອງທ່ານ",
    },
  },
  en: {
    transaction_title: "Transaction",
    depositPage: {
      CoverTitle: "Fast and secure deposits-withdrawals.\n",
      CoverSubTitle:
        "You can pay locally or internationally from wherever you are. Whether at home or while traveling Fast, only a few minutes and the most complete deposit and withdrawal methods for all banks.\n",
      CoverButtonText: "Start deposit",
      SectionTitle1: "How to deposit-withdrawal?\n",
      SectionSubTitle1: "",
      SectionBox1: "Register and sign-in\n",
      SectionBox2:
        "Select the bank you are using and proceed to deposit-withdrawal\n",
      SectionBox3: "Zero-fee for deposit and withdrawal\n",
      SectionBox4: "Fast deposit and withdrawal in a few minutes\n",
      SectionTitle2: "Deposit and withdrawal options\n",
      SectionSubTitle2:
        "We keep your financial information secure with encryption. So you can pay online with confidence.\n",
      dialog: {
        title: "Did you complete the transaction?",
        description: "You can press OK when the transaction is complete.",
        okButton: "OK",
        cancelButton: "Cancel",
      },
      accept: {
        header: `Security Alert`,
        subHeader: `To make sure that your deposit and withdrawal requests are approved, please answer these questions before selecting ERC20 network`,
        title: `Are you positive that your platform or application you are using is available for the ERC20 network?`,
        acc_ok: `Yes, I have directly confirmed to them that they support the ERC20 network.`,
        acc_cancel: `No, I have not directly confirmed to them that they support the ECR20 network.`,
        confirm: `Confirm`,
        pl: `** If you deposit-withdraw your assets via the ERC20 network to the destination address and platform or uncertified applications (ERC20). You will lose your assets without the Company being held liable.`,
      },
    },
    faq: {
      notice_title: "Notice",
      notice:
        "Your deposit and withdrawal must be in the same name as the information you verify. To avoid potential withdrawal problems if the information is inconsistent with the registration.",
      title_faq: "FAQ",
      viewmore: "View more",
      tooltip:
        "An appeal can be submitted if you have already submitted your deposit order but have exceeded the estimated processing duration. ",
      faq_title_1: "How to Deposit via QR code ?",
      faq_des_1: "You can choose a deposit method QR Code",
      faq_title_2: "Can I deposit money on holiday?",
      faq_des_2: "You can deposit in holiday",
      faq_title_3: "How long does it take to deposit?",
      faq_des_3: "10 - 15 Second when you finished process",
      faq_title_4:
        "In the event that the balance is negative, will the deposit be reset balance?",
      faq_des_4:
        "Your negative balance will reset to 0 when you have a new deposited.",
      faq_title_5: "How to get $30 bonus?",
      faq_des_5:
        "Get a $30 no deposit bonus on a Welcome Account once successfully verifying both your identity (POI) and bank account (POB).",
      faq_footer:
        "** And please note that your deposit and withdrawal must be in the same name as the information you verify. To avoid potential withdrawal problems if the information is inconsistent with the registration.",
    },
    deposit_info: {
      success: {
        title: "Successfully",
        description: "Deposit time: Approximately 1-3 minutes",
      },
      warning1:
        "Please note that your deposit will depend on the exchange rate of the intermediary you use.When making a deposit, please ensure that the exchange rate is optimal for each deposit.",
      warning2:
        "And please note that your deposit and withdrawal must be in the same name as the information you verify. To avoid potential withdrawal problems if the information is inconsistent with the registration.",
      mt_account: "MT5 Account",
      symbol: "Currency",
      confirm: "Confirm",
      amount: "Amount (USD)",
      amount_cent: "Amount (USC)",
      currency_rate: "Currency Rate",
      bank: {
        book_bank_name: "Account's name",
        number: "Bank Account",
      },
      payment: {
        amount: "Amount",
        confirm_amount: "Comfirm amount",
        input_date: "Deposit's date",
        input_file: "Upload File",
        input_file_description: "Upload Slip Here.",
      },
      attention: {
        title: "Attention",
        description:
          "The transfer of money transferred from a bank account must be the same account as the one specified in the trading account information. IUX Markets.com Money will not be processed to a 3rd party. Clients who wish to transfer money must specify the following forms while processing the transfer: Service payment invoice or trading account number. Customer's For example, Service payment invoice 123456 Sender names written in English.",
      },
      prompt: {
        title: "Attention",
        description:
          "When transferring money via qr code, successful payment The system will automatically deposit money into the account.",
      },
      visa_master: {
        title: "What is cvv, cvc",
        description:
          "The CVV is the three or four digits of each credit card. And only appear on tangible credit cards",
      },
      warning_tp: {
        Title: "Temporary maintenance",
      },
    },
    deposit_step: {
      no_account:
        "You don't have MT5 account. please register MT5 account before this process",
      account: "Account",
      first_step: {
        deposit_with: "Deposit with",
        recommended: "Recommended",
        show_all_method: "Show all method",
        title: "Step 1",
        description: "Select Account",
        choose_account: "Choose Account",
      },
      second_step: {
        title: "Enter Amount",
        description: " Amount",
        you_receive: "You receive",
        fee: "Transaction Fee",
        amount_more_than: "	Amount should more than	",
        minimun_requirement: "	Not meet the minimum requirement	",
        minimun_deposit: "	Minimum deposit	",
      },
      third_step: {
        title: "Step 2",
        description: "Medthod Payment",
        go_account: "Go to My Accounting",
      },
    },
    deposit_error: {
      input: "Please fill value here.",
      account: "Your account not selected yet.",
      amount: "amount should more than 3 USD.",
      h2p: "amount should more than ",
      ecn: "amount should more than 200 USD",
      raw: "amount should more than 1000 USD",
      pro: "amount should more than 1000 USD",
      nomore: "Maximum Deposit",
    },
    error_deposit: {
      text_1: "Not yet available in your region",
    },
  },
  in: {
    transaction_title: "लेन-देन",
    depositPage: {
      CoverTitle: "तेजी से और सुरक्षित जमा-ब्रीथड्रॉल। ",
      CoverSubTitle:
        "आप जहां भी हैं, वहां से स्थानीय या अंतरराष्ट्रीय स्तर पर भुगतान कर सकते हैं। चाहे घर पर हो या तेजी से यात्रा करते समय, केवल कुछ मिनट और सभी बैंकों के लिए सबसे पूर्ण जमा और निकासी के तरीके।",
      CoverButtonText: "जमा शुरू करना",
      SectionTitle1: "कैसे जमा करें",
      SectionSubTitle1: " ",
      SectionBox1: "रजिस्टर और साइन-इन ",
      SectionBox2:
        "उस बैंक का चयन करें जिसका आप उपयोग कर रहे हैं और जमा-ब्रीड्रावल  के लिए आगे बढ़ें",
      SectionBox3: "जमा और निकासी के लिए शून्य-शुल्क ",
      SectionBox4: "कुछ मिनटों में तेजी से जमा और निकासी",
      SectionTitle2: "जमा और निकासी विकल्प ",
      SectionSubTitle2:
        "हम आपकी वित्तीय जानकारी को एन्क्रिप्शन के साथ सुरक्षित रखते हैं। तो आप विश्वास के साथ ऑनलाइन भुगतान कर सकते हैं। ",
      dialog: {
        title: "क्या आपने लेनदेन पूरा किया?",
        description: "लेन -देन पूरा होने पर आप ओके दबा सकते हैं।",
        okButton: "ठीक है",
        cancelButton: "रद्द करना",
      },
      accept: {
        header: "सुरक्षा चेतावनी",
        subHeader:
          "यह सुनिश्चित करने के लिए कि आपके जमा और वापसी अनुरोधों को मंजूरी दी गई है, कृपया ERC20 नेटवर्क का चयन करने से पहले इन सवालों का जवाब दें",
        title:
          "क्या आप सकारात्मक हैं कि आपका प्लेटफ़ॉर्म या आपके द्वारा उपयोग किए जा रहे एप्लिकेशन ईआरसी 20 नेटवर्क के लिए उपलब्ध है?",
        acc_ok:
          "हां, मैंने उन्हें सीधे पुष्टि की है कि वे ईआरसी 20 नेटवर्क का समर्थन करते हैं।",
        acc_cancel:
          "नहीं, मैंने उन्हें सीधे पुष्टि नहीं की है कि वे ECR20 नेटवर्क का समर्थन करते हैं।",
        confirm: "पुष्टि करना",
        pl: "** यदि आप ERC20 नेटवर्क के माध्यम से अपनी संपत्ति को गंतव्य पते और प्लेटफ़ॉर्म या अनअर्टिफाइड एप्लिकेशन (ERC20) के लिए जमा करते हैं। आप कंपनी को उत्तरदायी होने के बिना अपनी संपत्ति खो देंगे।",
      },
    },
    faq: {
      notice_title: "सूचना",
      notice:
        "आपकी जमा और निकासी उसी नाम से होनी चाहिए जैसे कि आप सत्यापित करते हैं। यदि पंजीकरण के साथ जानकारी असंगत है, तो संभावित वापसी की समस्याओं से बचने के लिए।",
      title_faq: "सामान्य प्रश्न",
      viewmore: "और देखें",
      tooltip:
        "यदि आप पहले से ही अपना जमा आदेश जमा कर चुके हैं, लेकिन अनुमानित प्रसंस्करण अवधि को पार कर चुके हैं, तो अपील प्रस्तुत की जा सकती है।",
      faq_title_1: "QR कोड के माध्यम से कैसे जमा करें?",
      faq_des_1: "आप एक जमा विधि QR कोड चुन सकते हैं",
      faq_title_2: "क्या मैं छुट्टी पर पैसा जमा कर सकता हूं?",
      faq_des_2: "आप छुट्टी में जमा कर सकते हैं",
      faq_title_3: "जमा करने में कितना समय लगता है?",
      faq_des_3: "10 - 15 सेकंड जब आप प्रक्रिया समाप्त करते हैं",
      faq_title_4:
        "इस घटना में कि शेष राशि नकारात्मक है, क्या जमा राशि को रीसेट किया जाएगा?",
      faq_des_4:
        "जब आपके पास एक नया जमा किया जाएगा तो आपका नकारात्मक संतुलन 0 पर रीसेट हो जाएगा।",
      faq_title_5: "$30 बोनस कैसे प्राप्त करें ?",
      faq_des_5:
        "अपनी पहचान (POI) और बैंक खाता (POB) दोनों को सफलतापूर्वक सत्यापित करने के बाद वेलकम अकाउंट पर $30 का नो डिपॉजिट बोनस प्राप्त करें।",
      faq_footer:
        "** और कृपया ध्यान दें कि आपकी जमा और निकासी उसी नाम से होनी चाहिए जैसे कि आप सत्यापित करते हैं। यदि पंजीकरण के साथ जानकारी असंगत है, तो संभावित वापसी की समस्याओं से बचने के लिए।",
    },
    deposit_info: {
      success: {
        title: "सफलतापूर्वक",
        description: "जमा समय: लगभग 1-3 मिनट",
      },
      warning1:
        "कृपया ध्यान दें कि आपकी जमा राशि आपके द्वारा उपयोग की जाने वाली मध्यस्थ की विनिमय दर पर निर्भर करेगी। जब जमा कर रही है, तो कृपया सुनिश्चित करें कि प्रत्येक जमा के लिए विनिमय दर इष्टतम है।",
      warning2:
        "और कृपया ध्यान दें कि आपकी जमा और निकासी उसी नाम से होनी चाहिए जैसे कि आप सत्यापित करते हैं। यदि पंजीकरण के साथ जानकारी असंगत है, तो संभावित वापसी की समस्याओं से बचने के लिए।",
      mt_account: "Mt5 खाता",
      symbol: "मुद्रा",
      confirm: "पुष्टि करना",
      amount: "कुल राशि (डोलर)",
      amount_cent: "राशि (यूएससी)",
      currency_rate: "मुद्रा दर",
      bank: {
        book_bank_name: "खाता का नाम",
        number: "बैंक खाता",
      },
      payment: {
        amount: "मात्रा",
        confirm_amount: "पुष्टि राशि",
        input_date: "जमा की तारीख",
        input_file: "फ़ाइल अपलोड करें",
        input_file_description: "यहां पर्ची अपलोड करें।",
      },
      attention: {
        title: "ध्यान",
        description:
          "बैंक खाते से हस्तांतरित धन का हस्तांतरण एक ही खाता होना चाहिए जैसा कि ट्रेडिंग खाते की जानकारी में निर्दिष्ट किया गया है। Iux मार्केट्स.कॉम मनी को 3 पार्टी में संसाधित नहीं किया जाएगा। जो ग्राहक धन हस्तांतरित करना चाहते हैं, उन्हें स्थानांतरण को संसाधित करते समय निम्नलिखित फॉर्म निर्दिष्ट करना होगा: सेवा भुगतान चालान या ट्रेडिंग खाता संख्या। ग्राहक के उदाहरण के लिए, सेवा भुगतान चालान 123456 प्रेषक नाम अंग्रेजी में लिखे गए हैं।",
      },
      prompt: {
        title: "ध्यान",
        description:
          "क्यूआर कोड के माध्यम से धन हस्तांतरित करते समय, सफल भुगतान सिस्टम स्वचालित रूप से खाते में पैसा जमा करेगा।",
      },
      visa_master: {
        title: "CVV, CVC क्या है",
        description:
          "CVV प्रत्येक क्रेडिट कार्ड के तीन या चार अंक है। और केवल मूर्त क्रेडिट कार्ड पर दिखाई देते हैं",
      },
      warning_tp: {
        Title: "अस्थायी रखरखाव",
      },
    },
    deposit_step: {
      no_account:
        "आपके पास MT5 खाता नहीं है। कृपया इस प्रक्रिया से पहले MT5 खाता पंजीकृत करें",
      account: "खाता",
      first_step: {
        deposit_with: "के साथ जमा करना",
        recommended: "अनुशंसित",
        show_all_method: "सभी विधि दिखाओ",
        title: "स्टेप 1",
        description: "लेखा का चयन करें",
        choose_account: "खाता चुनें",
      },
      second_step: {
        title: "राशि डालें",
        description: "मात्रा",
        you_receive: "आपको प्राप्त हुया",
        fee: "लेनदेन शुल्क",
        amount_more_than: "	राशि से अधिक होना चाहिए	",
        minimun_requirement: "	न्यूनतम आवश्यकता को पूरा नहीं करना	",
        minimun_deposit: "	न्यूनतम जमा	",
      },
      third_step: {
        title: "चरण दो",
        description: "विधि भुगतान",
        go_account: "मेरे लेखांकन में जाओ",
      },
    },
    deposit_error: {
      input: "कृपया यहां मूल्य भरें।",
      account: "आपका खाता अभी तक नहीं चुना गया है।",
      amount: "राशि 3 USD से अधिक होनी चाहिए।",
      h2p: "राशि से अधिक होना चाहिए",
      ecn: "राशि 200 USD से अधिक होनी चाहिए",
      raw: "राशि 1000 अमरीकी डालर से अधिक होनी चाहिए",
      pro: "राशि 1000 अमरीकी डालर से अधिक होनी चाहिए",
      nomore: "अधिकतम जमा",
    },
    error_deposit: {
      text_1: "अभी तक आपके क्षेत्र में उपलब्ध नहीं है",
    },
  },
  my: {
    transaction_title: "Transaksi",
    depositPage: {
      CoverTitle: `Lakukan deposit dan pengeluaran dengan pantas dan selamat`,
      CoverSubTitle: `Anda boleh membayar dari tempatan atau antarabangsa di mana sahaja anda berada sama ada di rumah atau semasa dalam perjalanan dengan cepat, hanya beberapa minit beserta saluran deposit dan pengeluaran yang paling cekap dari semua bank.`,
      CoverButtonText: `Mulakan deposit dan pengeluaran`,
      SectionTitle1: `Bagaimanakah saya boleh lakukan deposit-pengeluaran?`,
      // SectionSubTitle1: '',
      SectionBox1: `Daftar atau log masuk.`,
      SectionBox2: `Pilih bank yang sedang anda gunakan untuk memproses deposit - pengeluaran.`,
      SectionBox3: `Bayaran deposit/pengeluaran percuma`,
      SectionBox4: `Wang diproses masuk dalam beberapa minit sahaja`,
      SectionTitle2: `Pililhan Deposit dan pengeluaran`,
      SectionSubTitle2: `Kami memastikan maklumat kewangan anda selamat dengan penyulitan. Jadi anda boleh membayar secara online dengan yakin.`,
      dialog: {
        title: "Adakah anda telah menyelesaikan transaksi?",
        description: "Anda boleh menekan OK apabila transaksi selesai.",
        okButton: "OK",
        cancelButton: "Cancel",
      },
      accept: {
        header: `Amaran keselamatan`,
        subHeader: `Untuk memastikan bahawa permintaan deposit dan pengeluaran anda diluluskan, sila jawab soalan ini sebelum memilih rangkaian ERC20.`,
        title: `Adakah anda dapat mengesahakan bahawa platform atau aplikasi yang anda deposit dan keluarkan menyokong rangkaian ERC20?`,
        acc_ok: `Ya, saya telah mengesahkan secara langsung kepada mereka bahawa mereka menyokong rangkaian ERC20.`,
        acc_cancel: `Tidak, saya tidak mengesahkan secara langsung kepada mereka bahawa mereka menyokong rangkaian ECR20.`,
        confirm: `Confirm`,
        pl: `** Jika anda mendeposit-keluarkan aset anda melalui rangkaian ERC20 ke alamat dan platform destinasi atau aplikasi yang tidak diperakui (ERC20), anda akan kehilangan aset anda. Syarikat tidak dipertanggungjawabkan.`,
      },
    },
    faq: {
      notice_title: "Tercatat",
      notice:
        "Deposit dan pengeluaran anda mestilah atas nama yang sama dengan maklumat identiti anda. Untuk mengelakkan kemungkinan masalah pengeluaran jika maklumat tidak konsisten dengan pendaftaran.",
      title_faq: "Soalan Lazim ",
      viewmore: "lihat lebih banyak",
      tooltip:
        "An appeal can be submitted if you have already submitted your deposit order but have exceeded the estimated processing duration. ",
      faq_title_1: "Bagaimana untuk membuat deposit melalui kod QR?",
      faq_des_1: "Anda boleh memilih kaedah deposit Kod QR.",
      faq_title_2: "Bolehkah saya mendepositkan wang semasa bercuti?",
      faq_des_2: "Anda boleh deposit pada hari raya.",
      faq_title_3: "Berapa lama masa yang diambil untuk deposit?",
      faq_des_3: "10-15 Saat, apabila anda selesai proses.",
      faq_title_4:
        "Sekiranya baki negatif, adakah deposit akan ditetapkan semula kepada baki?",
      faq_des_4:
        "Baki negatif anda akan ditetapkan semula kepada 0 apabila anda mempunyai deposit baharu.",
      faq_title_5: "Bagaimana untuk mendapatkan bonus $30 ?",
      faq_des_5:
        "Dapatkan bonus tanpa deposit $30 pada Akaun Selamat Datang setelah berjaya mengesahkan kedua-dua identiti anda (POI) dan akaun bank (POB).",
      faq_footer:
        "** Sila ambil perhatian bahawa deposit dan pengeluaran anda mestilah dalam nama yang sama dengan maklumat yang anda sahkan untuk mengelakkan masalah pengeluaran jika maklumat tidak konsisten dengan pendaftaran.",
    },
    deposit_info: {
      success: {
        title: `Deposit selesai`,
        description: `Masa Deposit: Kira-kira 1-3 minit `,
      },
      warning1:
        "Sila ambil perhatian bahawa deposit anda akan bergantung pada kadar pertukaran pengantara yang anda gunakan.Apabila membuat deposit, sila pastikan bahawa kadar pertukaran adalah optimum untuk setiap deposit.",
      warning2:
        "Dan sila ambil perhatian bahawa deposit dan pengeluaran anda mestilah dalam nama yang sama dengan maklumat yang anda sahkan. Untuk mengelakkan kemungkinan masalah pengeluaran jika maklumat tidak konsisten dengan pendaftaran.",
      mt_account: `Akaun MT5`,
      symbol: `Mata wang`,
      confirm: `Pengesahan deposit`,
      amount: `Jumlah (USD)`,
      amount_cent: "Jumlah (USC)",
      currency_rate: `Kadar mata wang`,
      bank: {
        book_bank_name: `Nama akaun ini`,
        number: `No. Akaun bank`,
      },
      payment: {
        amount: `Jumlah deposit`,
        confirm_amount: `Comfirm jumlah `,
        input_date: `Tarikh `,
        input_file: `Memuat naik fail`,
        input_file_description: `Muat naik bukti deposit.`,
      },
      attention: {
        title: `Perhatian`,
        description: `Pemindahan dana yang dipindahkan daripada akaun bank mestilah sama dengan pemindahan yang dinyatakan dalam maklumat akaun dagangan. IUX Markets.com tidak akan memproses sebarang pemindahan pihak ketiga. Pelanggan yang ingin memindahkan wang perlu isi borang berikut : invois penyedia pembayaran (Invois pembayaran perkhidmatan) atau nombor akaun dagangan pelanggan. Contohnya, Invois pembayaran perkhidmatan 123456, nama pengirim yang ditulis dalam bahasa Inggeris.`,
      },
      prompt: {
        title: `Perhatian`,
        description: `Apabila memindahkan wang melalui kod QR selesai, sistem akan mendepositkan wang secara automatik ke dalam akaun anda`,
      },
      visa_master: {
        title: `apa itu cvv?`,
        description: `CVV ialah nombor tiga atau empat digit bagi setiap kad kredit dan muncul hanya pada kad kredit fizikal`,
      },
      warning_tp: {
        Title: `Ditutup buat sementara waktu untuk pengubahsuaian`,
      },
    },
    deposit_step: {
      no_account: `Anda tidak mempunyai akaun MT5, sila buka akaun sebelum meneruskan`,
      account: "Akaun",
      first_step: {
        deposit_with: "Deposit dengan",
        recommended: "Disyorkan",
        show_all_method: "Tunjukkan semua kaedah",
        title: `Langkah 1`,
        description: `Pilih Akaun`,
        choose_account: "Pilih Akaun",
      },
      second_step: {
        title: `Masukkan jumlah`,
        description: `Pilih Jumlah `,
        you_receive: "Anda akan menerima",
        fee: "Bayaran transaksi",
        amount_more_than: "	Jumlah harus lebih daripada	",
        minimun_requirement: "	Tidak memenuhi syarat minimum	",
        minimun_deposit: "	Deposit minimum	",
      },
      third_step: {
        title: `langkah 3`,
        description: `Pilih Kaedah Deposit`,
        go_account: "Pergi ke perakaunan saya",
      },
    },
    deposit_error: {
      input: `Sila isi maklumat`,
      account: `Sila pilih akaun anda`,
      amount: `Jumlah deposit minimum mestilah lebih daripada 3 USD`,
      h2p: "Jumlah deposit minimum mestilah lebih daripada  ",
      ecn: "Jumlah deposit mestilah lebih daripada 200 USD",
      raw: "Jumlah deposit mestilah lebih daripada 1000 USD",
      pro: "Jumlah deposit mestilah lebih daripada 1000 USD",
      nomore: "Deposit mestilah tidak lebih daripada",
    },
    error_deposit: {
      text_1: "Belum tersedia di wilayah anda",
    },
  },
  id: {
    transaction_title: "Transaction",
    depositPage: {
      CoverTitle: `Cepat dan safe deposit-menarik`,
      CoverSubTitle: `Anda dapat membayar secara lokal atau internasional dari manapun Anda berada. Apakah di rumah atau saat bepergian Cepat, hanya beberapa menit dan paling lengkap deposit dan penarikan metode untuk semua bank.`,
      CoverButtonText: `Deposit awal`,
      SectionTitle1: `Bagaimana bisa menitipkan-menarik?`,
      // SectionSubTitle1: '',
      SectionBox1: `Mendaftar atau login ke sistem.`,
      SectionBox2: `Pilih bank yang Anda sudah menggunakan untuk memproses deposito - penarikan.`,
      SectionBox3: `Deposit biaya gratis / penarikan`,
      SectionBox4: `Uang hanya dalam hitungan menit`,
      SectionTitle2: `Deposit dan pilihan penarikan`,
      SectionSubTitle2: `Kami mengamankan informasi keuangan Anda dengan enkripsi. Jadi Anda dapat membayar secara online dengan percaya diri`,
      dialog: {
        title: "Did you complete the transaction?",
        description: "You can press OK when the transaction is complete.",
        okButton: "OK",
        cancelButton: "Cancel",
      },
      accept: {
        header: `Security Alert`,
        subHeader: `To make sure that your deposit and withdrawal requests are approved, please answer these questions before selecting ERC20 network`,
        title: `Are you positive that your platform or application you are using is available for the ERC20 network?`,
        acc_ok: `Yes, I have directly confirmed to them that they support the ERC20 network.`,
        acc_cancel: `No, I have not directly confirmed to them that they support the ECR20 network.`,
        confirm: `Confirm`,
        pl: `** If you deposit-withdraw your assets via the ERC20 network to the destination address and platform or uncertified applications (ERC20). You will lose your assets without the Company being held liable.`,
      },
    },
    faq: {
      notice_title: "Dicatat",
      notice:
        "Setoran dan penarikan Anda harus atas nama yang sama dengan informasi identitas Anda. Untuk menghindari potensi masalah penarikan jika informasi tidak sesuai dengan pendaftaran.",
      title_faq: "FAQ",
      viewmore: "melihat lebih banyak ",
      tooltip:
        "An appeal can be submitted if you have already submitted your deposit order but have exceeded the estimated processing duration. ",
      faq_title_1: "Bagaimana cara menyetor melalui kode QR?",
      faq_des_1: "Anda dapat memilih metode deposit QR Code.",
      faq_title_2: "Bisakah saya menyetor uang pada hari libur?",
      faq_des_2: "Anda dapat menyetor di hari libur.",
      faq_title_3: "Berapa lama waktu yang dibutuhkan untuk deposit?",
      faq_des_3: "10–15 Detik, saat Anda menyelesaikan proses.",
      faq_title_4: "Jika saldo negatif, apakah deposit akan direset ke saldo?",
      faq_des_4:
        "Saldo negatif Anda akan disetel ulang ke 0 saat Anda memiliki setoran baru.",
      faq_title_5: "Bagaimana cara mendapatkan bonus $30 ?",
      faq_des_5:
        "Dapatkan bonus tanpa deposit $30 pada Akun Selamat Datang setelah berhasil memverifikasi identitas (POI) dan rekening bank (POB) Anda.",
      faq_footer:
        "** Harap perhatikan bahwa setoran dan penarikan Anda harus atas nama yang sama dengan informasi yang Anda verifikasi untuk menghindari potensi masalah penarikan jika informasi tersebut tidak sesuai dengan pendaftaran.",
    },
    deposit_info: {
      success: {
        title: `berhasil`,
        description: `Waktu Deposit: Sekitar 1-3 menit \n biaya deposit 3,2%`,
      },
      warning1:
        "Please note that your deposit will depend on the exchange rate of the intermediary you use.When making a deposit, please ensure that the exchange rate is optimal for each deposit.",
      warning2:
        "And please note that your deposit and withdrawal must be in the same name as the information you verify. To avoid potential withdrawal problems if the information is inconsistent with the registration.",
      mt_account: `Akun MT5`,
      symbol: `Mata uang`,
      confirm: `Konfirmasi`,
      amount: `jumlah (USD)`,
      amount_cent: "Jumlah (USC)",
      currency_rate: `Mata uang`,
      bank: {
        book_bank_name: `Nama akun`,
        number: `Akun bank`,
      },
      payment: {
        amount: `jumlah`,
        confirm_amount: `jumlah comfirm`,
        input_date: `tanggal Deposit ini`,
        input_file: `Unggah data`,
        input_file_description: `Upload Selipkan sini.`,
      },
      attention: {
        title: `Perhatian`,
        description: `Transfer uang ditransfer dari rekening bank harus account yang sama dengan yang ditentukan dalam informasi rekening perdagangan. IUX Markets.com Uang tidak akan diproses ke pihak ke-3. Klien yang ingin mentransfer uang harus menentukan bentuk sebagai berikut saat memproses transfer: Jasa faktur pembayaran atau rekening trading nomor. Pelanggan yang Misalnya, layanan pembayaran invoice 123456 nama pengirim ditulis dalam bahasa Inggris.`,
      },
      prompt: {
        title: `Perhatian`,
        description: `Ketika mentransfer uang melalui kode qr, pembayaran sukses Sistem secara otomatis akan menyetorkan uang ke rekening`,
      },
      visa_master: {
        title: `Apa CVV, cvc`,
        description: `CVV adalah tiga atau empat digit masing-masing kartu kredit. Dan hanya muncul pada kartu kredit yang nyata`,
      },
      warning_tp: {
        Title: `perawatan sementara`,
      },
    },
    deposit_step: {
      no_account: `Anda tidak memiliki akun MT5. silakan mendaftar akun MT5 sebelum proses ini`,
      account: "Akun",
      first_step: {
        deposit_with: "Deposit dengan",
        recommended: "Disyorkan",
        show_all_method: "Tunjukkan semua kaedah",
        title: `Langkah 1`,
        description: `Pilih akun`,
        choose_account: "Pilih akun",
      },
      second_step: {
        title: `Masukan jumlah`,
        description: `Jumlah masukan`,
        you_receive: "Anda akan menerima",
        fee: "Biaya transaksi",
        amount_more_than: "	Jumlah harus lebih dari	",
        minimun_requirement: "	Tidak memenuhi persyaratan minimum	",
        minimun_deposit: "	Setoran minimum	",
      },
      third_step: {
        title: `Langkah 3`,
        description: `Medthod Pembayaran`,
        go_account: "Pergi ke akuntansi saya",
      },
    },
    deposit_error: {
      input: `plases nilai fill disini`,
      account: `Akun Anda tidak dipilih belum`,
      amount: `Jumlah harus lebih dari 3 USD`,
      h2p: "Jumlah harus lebih dari ",
      ecn: "amount should more than 200 USD",
      raw: "amount should more than 1000 USD",
      pro: "amount should more than 1000 USD",
      nomore: "Maximum Deposit",
    },
    error_deposit: {
      text_1: "Not yet available in your region",
    },
  },
  cn: {
    transaction_title: "Transaction",
    depositPage: {
      CoverTitle: `快速和安全的存款吸`,
      CoverSubTitle: `你可以从你的地方是本地或国际支付。无论是在家里或在旅行快，只有几分钟，所有银行的最完整的存款和取款的方法。`,
      CoverButtonText: `开始存款`,
      SectionTitle1: `如何存款取款呢？`,
      // SectionSubTitle1: '',
      SectionBox1: `注册或登录到系统。`,
      SectionBox2: `选择您已经使用来处理存款银行 - 提款。`,
      SectionBox3: `免费存款/取款手续费`,
      SectionBox4: `钱在短短几分钟内`,
      SectionTitle2: `存款和取款选项`,
      SectionSubTitle2: `我们保护与加密您的财务信息。所以，你可以放心地进行网上支付`,
      dialog: {
        title: "Did you complete the transaction?",
        description: "You can press OK when the transaction is complete.",
        okButton: "OK",
        cancelButton: "Cancel",
      },
      accept: {
        header: `Security Alert`,
        subHeader: `To make sure that your deposit and withdrawal requests are approved, please answer these questions before selecting ERC20 network`,
        title: `Are you positive that your platform or application you are using is available for the ERC20 network?`,
        acc_ok: `Yes, I have directly confirmed to them that they support the ERC20 network.`,
        acc_cancel: `No, I have not directly confirmed to them that they support the ECR20 network.`,
        confirm: `Confirm`,
        pl: `** If you deposit-withdraw your assets via the ERC20 network to the destination address and platform or uncertified applications (ERC20). You will lose your assets without the Company being held liable.`,
      },
    },
    faq: {
      notice_title: "注意",
      notice:
        "您的存款和提款必须与您验证的信息相同。 如果信息与注册不一致，请避免潜在的提款问题。",
      title_faq: "常问问题",
      viewmore: "查看更多",
      tooltip:
        "如果您已经提交了存款订单，但超出了估计的处理期限，则可以提交上诉。",
      faq_title_1: "如何通过QR码存入？",
      faq_des_1: "您可以选择存款方法QR码",
      faq_title_2: "我可以在假期里存钱吗？",
      faq_des_2: "你可以存入假期",
      faq_title_3: "存入需要多长时间？",
      faq_des_3: "完成过程后的10-15秒",
      faq_title_4: "如果余额为负，则存款会重置余额吗？",
      faq_des_4: "当您有新存入时，您的负余额将重置为0。",
      faq_title_5: "如何存款以获取30美元的奖金？",
      faq_des_5:
        "一旦成功验证您的身份（POI）和银行帐户（POB），请在欢迎帐户上获得30美元的押金奖金。",
      faq_footer:
        "**，请注意，您的存款和提款必须与您验证的信息相同。 如果信息与注册不一致，请避免潜在的提款问题。",
    },
    deposit_info: {
      success: {
        title: `顺利`,
        description: `存款时间：约1-3分钟 \n 存款手续费3.2％`,
      },
      mt_account: `MT5账户`,
      symbol: `货币`,
      confirm: `确认`,
      amount: `金额（美元）`,
      currency_rate: `货币汇率`,
      bank: {
        book_bank_name: `账户名`,
        number: `银行账户`,
      },
      warning1:
        "Please note that your deposit will depend on the exchange rate of the intermediary you use.When making a deposit, please ensure that the exchange rate is optimal for each deposit.",
      warning2:
        "And please note that your deposit and withdrawal must be in the same name as the information you verify. To avoid potential withdrawal problems if the information is inconsistent with the registration.",
      payment: {
        amount: `量`,
        confirm_amount: `Comfirm量`,
        input_date: `存款的日期`,
        input_file: `上传文件`,
        input_file_description: `上传单这里。`,
      },
      attention: {
        title: `注意`,
        description: `钱从银行账户转移转移必须是相同的帐户作为交易账户信息中指定的一个。 IUX Markets.com资金将不会被处理到第三方。谁愿意转让款客户端在处理转让必须指定下列形式：服务付款发票或交易账户号码。客户例如，服务支付发票123456发件人的名字用英文写的。`,
      },
      prompt: {
        title: `注意`,
        description: `当通过QR码转账，支付成功，系统会自动将钱存入该账户MT5。`,
      },
      visa_master: {
        title: `什么是CVV，CVC`,
        description: `该CVV是每个信用卡的三位或四位数字。而且只出现在有形信用卡`,
      },
      warning_tp: {
        Title: `临时维护`,
      },
    },
    deposit_step: {
      no_account: `你不必MT5账户。请在此过程之前注册MT5账户`,
      account: "Account",
      first_step: {
        deposit_with: "Deposit with",
        recommended: "Recommended",
        show_all_method: "Show all method",
        title: `第1步`,
        description: `选择帐户`,
        choose_account: "Choose Account",
      },
      second_step: {
        title: `第2步`,
        description: `投入量`,
        you_receive: "You receive",
        fee: "Transaction Fee",
        amount_more_than: "	金額應超過	",
        minimun_requirement: "	不符合最低要求	",
        minimun_deposit: "	最低存款	",
      },
      third_step: {
        title: `第3步`,
        description: `Medthod付款`,
        go_account: "去我的會計",
      },
    },
    deposit_error: {
      input: `这里plases填充值`,
      account: `您的帐户尚未选择`,
      amount: `量应超过 3 USD`,
      h2p: "amount should more than ",
      ecn: "amount should more than 200 USD",
      raw: "amount should more than 1000 USD",
      pro: "amount should more than 1000 USD",
      nomore: "Maximum Deposit",
    },
    error_deposit: {
      text_1: "Not yet available in your region",
    },
  },
  vn: {
    transaction_title: "giao dịch",
    depositPage: {
      CoverTitle: "Nạp-rút tiền nhanh chóng và an toàn\n",
      CoverSubTitle:
        "Bạn có thể thực hiện thanh toán trong nước hoặc quốc tế từ mọi nơi. cho dù ở nhà hay khi đi du lịch hanh chóng chỉ vài phút và các kênh gửi và rút tiền đầy đủ nhất trong tất cả các ngân hàng.\n",
      CoverButtonText: "Bắt đầu thực hiện nạp/rút tiền",
      SectionTitle1: "Cách nạp/rút tiền\n",
      SectionSubTitle1: "",
      SectionBox1: "Đăng ký hoặc đăng nhập hệ thống\n",
      SectionBox2: "Chọn ngân hàng bạn đang sử dụng và tiến hành nạp-rút\n\n",
      SectionBox3: "Phí Nạp/ Rút tiền miễn phí\n",
      SectionBox4: "Tiền vào chỉ trong vài phút.\n",
      SectionTitle2: "Tùy chọn nửi-rút tiền",
      SectionSubTitle2:
        "Chúng tôi bảo mật thông tin tài chính của bạn bằng mã hóa. Vì vậy, bạn có thể tự tin thanh toán trực tuyến.\n",
      dialog: {
        title: "Bạn đã hoàn thành giao dịch?",
        description: "Bạn có thể nhấn OK khi giao dịch hoàn tất.",
        okButton: "OK",
        cancelButton: "Hủy bỏ",
      },
      accept: {
        header: `Nhắc nhở sự bảo mật`,
        subHeader: `để đảm bảo rằng yêu cầu Nạp-Rút tiền của bạn được chấp nhận. Vui lòng trả lợi câu hỏi trước khi chọn kết  mạng ERC20.`,
        title: `** Bạn có xác nhận hay không rằng nền tảng hoặc ứng dụng mà bạn đang Nạp-Rút tiền đã hỗ trợ kết mạng ERC20.`,
        acc_ok: `Vâng,  tôi đã xác nhận trực tiếp với họ rằng họ đã hỗ trợ kết mạng ERC20, `,
        acc_cancel: `Không, tôi không xác nhận trực tiếp với họ rằng họ đã hỗ trợ kết mạng ERC20.`,
        confirm: `đồng ý`,
        pl: `**Nếu bạn Nạp-Rút tiền tài sản của bạn qua kết mạng ERC20 sang địa chỉ và nền tảng đích hoặc ứng dụng mà không chấp nhận kết mạng (ERC20), Bạn sẽ mất tài sản của bạn và bên công ty sẽ không chịu trách nghiệm.`,
      },
    },
    faq: {
      notice_title: "Nhận thấy",
      notice:
        "Tiền gửi và rút tiền của bạn phải cùng tên với thông tin bạn xác minh. Để tránh các vấn đề rút tiền tiềm ẩn nếu thông tin không phù hợp với đăng ký",
      title_faq: "FAQ",
      viewmore: "View more",
      tooltip:
        "An appeal can be submitted if you have already submitted your deposit order but have exceeded the estimated processing duration. ",
      faq_title_1: "Làm cách nào để gửi tiền qua mã QR?",
      faq_des_1: "Bạn có thể chọn phương thức gửi tiền VietQR và QR Pay.",
      faq_title_2: "Tôi có thể gửi tiền vào kỳ nghỉ không?",
      faq_des_2: "Bạn có thể gửi tiền trong kỳ nghỉ. ",
      faq_title_3: "Mất bao lâu để đặt cọc?",
      faq_des_3: "10 - 15 giây khi bạn hoàn thành quy trình.",
      faq_title_4:
        "Trong trường hợp số dư âm, khoản tiền gửi có được thiết lập lại số dư không?",
      faq_des_4:
        "Số dư âm của bạn sẽ được đặt lại về 0 khi bạn có một khoản tiền gửi mới. ",
      faq_title_5: "Cách nhận tiền thưởng $30 ?",
      faq_des_5:
        "Nhận $30 tiền thưởng không cần ký gửi trên Tài khoản chào mừng sau khi xác minh thành công cả danh tính (POI) và tài khoản ngân hàng (POB) của bạn.",
      faq_footer:
        "** Xin lưu ý rằng tiền gửi và rút tiền của bạn phải giống với thông tin bạn xác minh. Để tránh các vấn đề rút tiền tiềm ẩn nếu thông tin không phù hợp với đăng ký.",
    },
    deposit_description: {
      qr_payment: ``,
      visamaster: `Vào ngay lập tức , `,
      paypal: ``,
      skrill: ``,
      neteller: ``,
    },
    deposit_info: {
      success: {
        title: "Nạp tiền hoàn tất",
        description: "Thời gian nạp tiền: Vào ngay lập tức, ",
      },
      mt_account: "Tài khoản MT5",
      symbol: "Tiền tệ",
      confirm: "Xác nhận tiền nạp",
      amount: "Số tiền nạp (USD)",
      amount_cent: "Số tiền nạp (USC)",
      currency_rate: "Tiền tệ hiện tại",
      bank: {
        book_bank_name: "Tên tài khoản",
        number: "Số tài khoản ngân hàng",
      },
      warning1:
        "Please note that your deposit will depend on the exchange rate of the intermediary you use.When making a deposit, please ensure that the exchange rate is optimal for each deposit.",
      warning2:
        "And please note that your deposit and withdrawal must be in the same name as the information you verify. To avoid potential withdrawal problems if the information is inconsistent with the registration.",
      payment: {
        amount: "Số tiền nạp",
        confirm_amount: "xác nhận số tiền nạp",
        input_date: "Ngày",
        input_file: "Tải lên tệp",
        input_file_description: "Tải lên bằng chứng ký nạp",
      },
      attention: {
        title: "Xin lưu ý",
        description:
          "Việc chuyển tiền từ tài khoản ngân hàng phải giống với người chuyển tiền được chỉ định trong thông tin tài khoản giao dịch. IUX Markets.com không thực hiện chuyển tiền cho bên thứ 3, Khách hàng muốn chuyển tiền phải cung cấp mẫu sau này trong khi thực hiện chuyển tiền: invoice của nhà cung cấp dịch vụ thanh toán(Service payment invoice) hoặc số. Tài khoản giao dịch của khách hàng Ví dụ:  Service payment invoice 123456 tên người gửi được viết bằng tiếng Anh.",
      },
      prompt: {
        title: "Xin lưu ý",
        description:
          "Khi chuyển tiền qua mã qr code prompt pay thành công, hệ thống sẽ tự động nạp tiền vào tài khoản.",
      },
      visa_master: {
        title: "cvv là gì?",
        description:
          "CVV là số có ba hoặc bốn chữ số của mỗi thẻ tín dụng. và chỉ xuất hiện trên thẻ tín dụng thực.",
      },
      warning_tp: {
        Title: "Tạm thời đóng cửa để bảo trì",
      },
    },
    deposit_step: {
      no_account:
        "Bạn chưa có tài khoản MT5 Vui lòng mở tài khoản trước khi tiếp tục.",
      account: "Tài khoản",
      first_step: {
        deposit_with: "gửi tiền với",
        recommended: "Thanh toán được đề xuất",
        show_all_method: "Hiển thị tất cả phương pháp",
        title: "Chọn tài khoản",
        description: "Chọn tài khoản",
        choose_account: "Choose Account",
      },
      second_step: {
        title: "Chọn số tiền",
        description: "Chọn số tiền",
        you_receive: "Bạn sẽ nhận",
        fee: "Phí giao dịch",
        amount_more_than: "	Số tiền nên nhiều hơn	",
        minimun_requirement: "	Không đáp ứng yêu cầu tối thiểu	",
        minimun_deposit: "	Tiền gửi tối thiểu	",
      },
      third_step: {
        title: "Chọn cách nạp tiền",
        description: "Chọn cách nạp tiền",
        go_account: "Đi đến kế toán của tôi",
      },
    },
    deposit_error: {
      input: "Vui lòng điền thông tin",
      account: "Vui lòng điền tài khoản của bạn",
      amount: "Số tiền nạp tối thiểu phải nhiều hơn 3 USD",
      h2p: "Số tiền nạp tối thiểu phải nhiều hơn ",
      ecn: "Số tiền nạp tối thiểu phải nhiều hơn 200 USD",
      raw: "Số tiền nạp tối thiểu phải nhiều hơn 1000 USD",
      pro: "Số tiền nạp tối thiểu phải nhiều hơn 1000 USD",
      nomore: "Khoản tiền gửi không được nhiều hơn",
    },
    error_deposit: {
      text_1: "Chưa khả dụng trong khu vực của bạn",
    },
  },
  kr: {
    transaction_title: "Transaction",
    depositPage: {
      CoverTitle: `빠르고 안전한 예금 인출`,
      CoverSubTitle: `당신은 당신이 어디에서 로컬 또는 국제적으로 지불 할 수 있습니다. 집에서 여부 빠른 여행을하면서, 단 몇 분 모든 은행에 대한 가장 완벽한 입출금 방법.`,
      CoverButtonText: `시작 예금`,
      SectionTitle1: `어떻게 예금 인출 할 수 있습니까?`,
      // SectionSubTitle1: '',
      SectionBox1: `등록 또는 시스템에 로그인합니다.`,
      SectionBox2: `인출 - 당신은 이미 예금을 처리하기 위해 사용하는 은행을 선택합니다.`,
      SectionBox3: `무료 입금 / 출금 수수료`,
      SectionBox4: `몇 분에 돈`,
      SectionTitle2: `입금 및 출금 옵션`,
      SectionSubTitle2: `우리는 암호화를 통해 금융 정보를 보호. 안심하고 온라인으로 지불 할 수 있도록`,
      dialog: {
        title: "Did you complete the transaction?",
        description: "You can press OK when the transaction is complete.",
        okButton: "OK",
        cancelButton: "Cancel",
      },
      accept: {
        header: `Security Alert`,
        subHeader: `To make sure that your deposit and withdrawal requests are approved, please answer these questions before selecting ERC20 network`,
        title: `Are you positive that your platform or application you are using is available for the ERC20 network?`,
        acc_ok: `Yes, I have directly confirmed to them that they support the ERC20 network.`,
        acc_cancel: `No, I have not directly confirmed to them that they support the ECR20 network.`,
        confirm: `Confirm`,
        pl: `** If you deposit-withdraw your assets via the ERC20 network to the destination address and platform or uncertified applications (ERC20). You will lose your assets without the Company being held liable.`,
      },
    },
    faq: {
      notice_title: "알아채다",
      notice:
        "예금 및 철수는 귀하가 확인한 정보와 동일한 이름이어야합니다. 정보가 등록과 일치하지 않는 경우 잠재적 인 철수 문제를 피하기 위해.",
      title_faq: "자주하는 질문",
      viewmore: "더보기",
      tooltip:
        "이미 예금 명령을 제출했지만 예상 처리 기간을 초과 한 경우 항소를 제출할 수 있습니다.",
      faq_title_1: "QR 코드를 통해 입금하는 방법은 무엇입니까?",
      faq_des_1: "예금 방법 QR 코드를 선택할 수 있습니다",
      faq_title_2: "휴가에 돈을 입금 할 수 있습니까?",
      faq_des_2: "휴가에 입금 할 수 있습니다",
      faq_title_3: "입금하는 데 얼마나 걸립니까?",
      faq_des_3: "프로세스가 완료되면 10-15 초",
      faq_title_4: "잔액이 음수 인 경우 예금은 재설정 잔액입니까?",
      faq_des_4: "새 입금 된 경우 네거티브 밸런스가 0으로 재설정됩니다.",
      faq_title_5: "보너스 $ 30를 받기 위해 입금하는 방법?",
      faq_des_5:
        "신원 (POI)과 은행 계좌 (POB)를 모두 확인하면 환영 계정에서 30 달러의 예금 보너스를 받으십시오.",
      faq_footer:
        "** 보증금 및 철수는 귀하가 확인한 정보와 동일한 이름이어야합니다. 정보가 등록과 일치하지 않는 경우 잠재적 인 철수 문제를 피하기 위해.",
    },
    deposit_info: {
      success: {
        title: `성공적으로`,
        description: `예금 시간 : 약 1-3분 \n예금 수수료 3.2 %`,
      },
      mt_account: `MT5 계정`,
      symbol: `통화`,
      confirm: `확인`,
      amount: `금액 (USD)`,
      amount_cent: "금액 (USC)",
      currency_rate: `환율 비율`,
      bank: {
        book_bank_name: `계정의 이름`,
        number: `은행 계좌`,
      },
      warning1:
        "Please note that your deposit will depend on the exchange rate of the intermediary you use.When making a deposit, please ensure that the exchange rate is optimal for each deposit.",
      warning2:
        "And please note that your deposit and withdrawal must be in the same name as the information you verify. To avoid potential withdrawal problems if the information is inconsistent with the registration.",
      payment: {
        amount: `양`,
        confirm_amount: `Comfirm 양`,
        input_date: `입금 날짜`,
        input_file: `파일 업로드`,
        input_file_description: `여기에 슬립 업로드 할 수 있습니다.`,
      },
      attention: {
        title: `주의`,
        description: `은행 계좌에서 전송 된 돈의 전송은 거래 계좌 정보에 지정된 것과 동일한 계정이어야합니다. IUX Markets.com 돈은 제 3 자에게 처리되지 않습니다. 전송을 처리하는 동안 전송 돈을하고자하는 클라이언트는 다음과 같은 형태를 지정해야합니다 : 서비스 지급 청구서 또는 거래 계좌 번호를. 고객은 예를 들어있어, 서비스 지급 청구서 123456 보낸 사람의 이름은 영어로 작성.`,
      },
      prompt: {
        title: `주의`,
        description: `QR 코드를 통해 돈을 전송할 때, 성공적인 지불 시스템이 자동으로 MT5 계좌에 돈을 입금합니다.`,
      },
      visa_master: {
        title: `CVV, CVC는 무엇인가`,
        description: `CVV는 각 신용 카드의 세 가지 또는 네 자리 숫자입니다. 그리고 단지 유형 신용 카드에 표시`,
      },
      warning_tp: {
        Title: `임시 유지 보수`,
      },
    },
    deposit_step: {
      no_account: `당신은 MT5 계정을 가지고 있지 않습니다. 이 과정 전에 MT5 계정을 등록하십시오`,
      account: "Account",
      first_step: {
        deposit_with: "Deposit with",
        recommended: "Recommended",
        show_all_method: "Show all method",
        title: `1 단계`,
        description: `계정 선택`,
        choose_account: "Choose Account",
      },
      second_step: {
        title: `Enter Amount`,
        description: `입력 금액`,
        you_receive: "You receive",
        fee: "Transaction Fee",
        amount_more_than: "	금액은 더 이상이어야합니다	",
        minimun_requirement: "	최소 요구 사항을 충족하지 마십시오	",
        minimun_deposit: "	최소 예금	",
      },
      third_step: {
        title: `3 단계`,
        description: `Medthod 지불`,
        go_account: "내 회계로 이동하십시오",
      },
    },
    deposit_error: {
      input: `여기 Please 채우기 값`,
      account: `계정이 아직 선택하지`,
      amount: `양해야 이상 3 USD`,
      h2p: "amount should more than ",
      ecn: "amount should more than 200 USD",
      raw: "amount should more than 1000 USD",
      pro: "amount should more than 1000 USD",
      nomore: "Maximum Deposit",
    },
    error_deposit: {
      text_1: "Not yet available in your region",
    },
  },
  ph: {
    transaction_title: "Transaction",
    depositPage: {
      CoverTitle: `Mabilis at ligtas na deposit-withdraw`,
      CoverSubTitle: `Maaari kang magbayad sa lokal o internationally mula sa nasaan ka man. Kahit na sa bahay o habang naglalakbay Mabilis, lamang ng ilang minuto at ang pinaka-kumpletong deposito at withdrawal pamamaraan para sa lahat ng mga bangko.`,
      CoverButtonText: `Start deposit`,
      SectionTitle1: `Paano magdeposito-withdraw?`,
      // SectionSubTitle1: '',
      SectionBox1: `Magparehistro o login sa system.`,
      SectionBox2: `Piliin ang bangko na ginagamit mo na ang i-proseso ang mga deposito - withdrawals.`,
      SectionBox3: `Libre deposit / withdrawal fees`,
      SectionBox4: `Money sa loob lamang ng ilang minuto`,
      SectionTitle2: `Deposito at withdrawal mga opsyon`,
      SectionSubTitle2: `Kami ay secure ang iyong pampinansyal na impormasyon sa pag-encrypt. Kaya maaari kang magbayad online may pagtitiwala`,
      dialog: {
        title: "Did you complete the transaction?",
        description: "You can press OK when the transaction is complete.",
        okButton: "OK",
        cancelButton: "Cancel",
      },
      accept: {
        header: `Security Alert`,
        subHeader: `To make sure that your deposit and withdrawal requests are approved, please answer these questions before selecting ERC20 network`,
        title: `Are you positive that your platform or application you are using is available for the ERC20 network?`,
        acc_ok: `Yes, I have directly confirmed to them that they support the ERC20 network.`,
        acc_cancel: `No, I have not directly confirmed to them that they support the ECR20 network.`,
        confirm: `Confirm`,
        pl: `** If you deposit-withdraw your assets via the ERC20 network to the destination address and platform or uncertified applications (ERC20). You will lose your assets without the Company being held liable.`,
      },
    },
    faq: {
      notice_title: "PAUNAWA",
      notice:
        "Ang iyong deposito at pag -atras ay dapat na nasa parehong pangalan tulad ng impormasyon na iyong napatunayan. Upang maiwasan ang mga potensyal na problema sa pag -alis kung ang impormasyon ay hindi naaayon sa pagrehistro.",
      title_faq: "FAQ",
      viewmore: "Tingnan pa",
      tooltip:
        "Ang isang apela ay maaaring isumite kung na isinumite mo na ang iyong order ng deposito ngunit lumampas sa tinantyang tagal ng pagproseso.",
      faq_title_1: "Paano magdeposito sa pamamagitan ng QR code?",
      faq_des_1: "Maaari kang pumili ng isang paraan ng deposito ng QR code",
      faq_title_2: "Maaari ba akong magdeposito ng pera sa holiday?",
      faq_des_2: "Maaari kang magdeposito sa holiday",
      faq_title_3: "Gaano katagal bago magdeposito?",
      faq_des_3: "10 - 15 segundo kapag natapos mo ang proseso",
      faq_title_4:
        "Kung sakaling negatibo ang balanse, mai -reset ba ang deposito?",
      faq_des_4:
        "Ang iyong negatibong balanse ay i -reset sa 0 kapag mayroon kang isang bagong na -deposito.",
      faq_title_5: "Paano makakuha ng $30 na bonus?",
      faq_des_5:
        "Kumuha ng isang $ 30 na walang deposit bonus sa isang welcome account sa sandaling matagumpay na mapatunayan ang parehong iyong pagkakakilanlan (POI) at bank account (POB).",
      faq_footer:
        "** At mangyaring tandaan na ang iyong deposito at pag -alis ay dapat na nasa parehong pangalan tulad ng impormasyon na iyong napatunayan. Upang maiwasan ang mga potensyal na problema sa pag -alis kung ang impormasyon ay hindi naaayon sa pagrehistro.",
    },
    deposit_info: {
      success: {
        title: `matagumpay`,
        description: `Deposit oras: Humigit-kumulang 1-3 minuto`,
      },
      mt_account: `MT5 Account`,
      symbol: `pera`,
      confirm: `Kumpirmahin`,
      amount: `Halaga (USD)`,
      amount_cent: "Halaga (USC)",
      currency_rate: `Rate ng pera`,
      bank: {
        book_bank_name: `pangalan ng Account`,
        number: `Bank account`,
      },
      warning1:
        "Please note that your deposit will depend on the exchange rate of the intermediary you use.When making a deposit, please ensure that the exchange rate is optimal for each deposit.",
      warning2:
        "And please note that your deposit and withdrawal must be in the same name as the information you verify. To avoid potential withdrawal problems if the information is inconsistent with the registration.",
      payment: {
        amount: `dami`,
        confirm_amount: `comfirm halaga`,
        input_date: `date ni Deposit`,
        input_file: `Mag-upload ng File`,
        input_file_description: `Mag-upload Slip Dito.`,
      },
      attention: {
        title: `atensyon`,
        description: `Ang paglipat ng pera mailipat mula sa isang bank account ay dapat na ang parehong account bilang ang isa na tinukoy sa impormasyon kalakalan account. IUX Markets.com Money Hindi mapoproseso sa isang 3rd party. Ang kliente ay nagnanais na transfer ng pera ay dapat tukuyin ang sumusunod na form habang pinoproseso ang transfer: Serbisyo ng pagbabayad ng invoice o kalakalan account number. Customer ay Halimbawa, Serbisyo ng pagbabayad ng invoice 123456 mga pangalan ng nagpadala nakasulat sa Ingles.`,
      },
      prompt: {
        title: `atensyon`,
        description: `Kapag ang paglilipat ng pera sa pamamagitan ng QR code, matagumpay na pagbabayad sistema ay awtomatikong deposito ng pera sa mga account.`,
      },
      visa_master: {
        title: `Ano ang CVV, cvc`,
        description: `CVV ay ang tatlo o apat na digit ng bawat credit card. At lumitaw lamang sa nahahawakang credit card`,
      },
      warning_tp: {
        Title: `Temporary maintenance`,
      },
    },
    deposit_step: {
      no_account: `Hindi mo na kailangang MT5 account. mangyaring magparehistro MT5 account bago ang proseso na ito`,
      account: "Account",
      first_step: {
        deposit_with: "Magdeposito sa",
        recommended: "Inirerekomenda",
        show_all_method: "Ipakita ang lahat ng paraan",
        title: `hakbang 1`,
        description: `Piliin ang Account`,
        choose_account: "Piliin ang Account",
      },
      second_step: {
        title: `Ipasok ang halaga`,
        description: `input Halaga`,
        you_receive: "Matatanggap mo",
        fee: "Bayad sa Transaksyon",
        amount_more_than: "	Ang halaga ay dapat higit pa sa	",
        minimun_requirement: "	Hindi matugunan ang minimum na kinakailangan	",
        minimun_deposit: "	Minimum na deposito	",
      },
      third_step: {
        title: `hakbang 3`,
        description: `Medthod Pagbabayad`,
        go_account: "Pumunta sa aking accounting",
      },
    },
    deposit_error: {
      input: `plases fill halaga dito`,
      account: `Ang iyong account ay hindi pa nakapili`,
      amount: `halagang dapat higit sa 3 USD`,
      h2p: "amount should more than ",
      ecn: "amount should more than 200 USD",
      raw: "amount should more than 1000 USD",
      pro: "amount should more than 1000 USD",
      nomore: "Maximum Deposit",
    },
    error_deposit: {
      text_1: "Not yet available in your region",
    },
  },
  jp: {
    transaction_title: "取引",
    depositPage: {
      CoverTitle: "高速かつ安全な堆積物-withdrawals。 n",
      CoverSubTitle:
        "どこからでもローカルまたは国際的に支払うことができます。 自宅であろうと速く旅行中であろうと、ほんの数分で、すべての銀行の最も完全な預金と撤退方法。 n",
      CoverButtonText: "デポジットを開始します",
      SectionTitle1: "Withdrawalを堆積する方法 n",
      SectionSubTitle1: "",
      SectionBox1: "登録とサインイン n",
      SectionBox2: "使用している銀行を選択して、預金に進みますwithdrawal  n",
      SectionBox3: "デポジットと撤退のゼロフィー n",
      SectionBox4: "数分で迅速なデポジットと撤退 n",
      SectionTitle2: "デポジットアンドアウトオプション n",
      SectionSubTitle2:
        "暗号化により財務情報を安全に保ちます。 だからあなたは自信を持ってオンラインで支払うことができます。 n",
      dialog: {
        title: "トランザクションを完了しましたか？",
        description: "トランザクションが完了したらOKを押すことができます。",
        okButton: "わかりました",
        cancelButton: "キャンセル",
      },
      accept: {
        header: `セキュリティーアラート`,
        subHeader: `預金と撤回のリクエストが承認されていることを確認するには、ERC20ネットワークを選択する前にこれらの質問に答えてください`,
        title: `使用しているプラットフォームまたはアプリケーションがERC20ネットワークで利用できることは肯定的ですか？`,
        acc_ok: `はい、私は彼らがERC20ネットワークをサポートしていることを彼らに直接確認しました。`,
        acc_cancel: `いいえ、私は彼らがECR20ネットワークをサポートしていることを彼らに直接確認していません。`,
        confirm: `確認する`,
        pl: `** ERC20ネットワークを介して宛先アドレスとプラットフォームまたは承認されていないアプリケーション（ERC20）に預金する場合。 会社が責任を負うことなく、資産を失うことになります。`,
      },
    },
    faq: {
      notice_title: "知らせ",
      notice:
        "預金と撤回は、確認する情報と同じ名前でなければなりません。 情報が登録と矛盾している場合、潜在的な撤退の問題を回避するため。",
      title_faq: "よくある質問",
      viewmore: "もっと見る",
      tooltip:
        "既に預金注文を提出しているが、推定処理期間を超えている場合、控訴を提出できます。",
      faq_title_1: "QRコードを介してデポジットする方法は？",
      faq_des_1: "デポジットメソッドQRコードを選択できます",
      faq_title_2: "休日にお金を預けられますか？",
      faq_des_2: "休日に入金できます",
      faq_title_3: "デポジットにどれくらい時間がかかりますか？",
      faq_des_3: "10-15秒プロセスが終了したとき",
      faq_title_4:
        "バランスがマイナスである場合、デポジットはバランスをリセットしますか？",
      faq_des_4:
        "ネガティブバランスは、新しい預け入れがあるときに0にリセットされます。",
      faq_title_5: "30ドルのボーナスを取得する方法は？",
      faq_des_5:
        "ID（POI）と銀行口座（POB）の両方を正常に確認したら、ウェルカムアカウントに30ドルのデポジットボーナスを取得します。",
      faq_footer:
        "**預金と撤回は、確認する情報と同じ名前でなければならないことに注意してください。 情報が登録と矛盾している場合、潜在的な撤退の問題を回避するため。",
    },
    deposit_info: {
      success: {
        title: "正常に",
        description: "デポジット時間：約1〜3分",
      },
      warning1:
        "デポジットは、使用する仲介者の為替レートに依存することに注意してください。デポジットを作成する場合、為替レートが各デポジットに最適であることを確認してください。",
      warning2:
        "また、預金と撤回は、確認する情報と同じ名前でなければならないことに注意してください。 情報が登録と矛盾している場合、潜在的な撤退の問題を回避するため。",
      mt_account: "MT5アカウント",
      symbol: "通貨",
      confirm: "確認する",
      amount: "金額（USD）",
      amount_cent: "金額（USC）",
      currency_rate: "通貨レート",
      bank: {
        book_bank_name: "アカウントの名前",
        number: "銀行口座",
      },
      payment: {
        amount: "額",
        confirm_amount: "確認額",
        input_date: "デポジットの日付",
        input_file: "ファイルをアップロードする",
        input_file_description: "ここにスリップをアップロードします。",
      },
      attention: {
        title: "注意",
        description:
          "銀行口座から転送された送金の譲渡は、取引口座情報で指定された口座と同じアカウントでなければなりません。 IUX Markets.comのお金は、サードパーティに処理されません。 送金を希望するクライアントは、転送の処理中に次のフォームを指定する必要があります：サービス支払い請求書または取引口座番号。 たとえば、顧客は、英語で書かれたサービス支払い請求書123456送信者名。",
      },
      prompt: {
        title: "注意",
        description:
          "QRコードを介してお金を転送すると、支払いが成功すると、システムは自動的にアカウントにお金を預けます。",
      },
      visa_master: {
        title: "CVV、CVCとは何ですか",
        description:
          "CVVは、各クレジットカードの3桁または4桁です。 そして、有形のクレジットカードにのみ表示されます",
      },
      warning_tp: {
        Title: "一時的なメンテナンス",
      },
    },
    deposit_step: {
      no_account:
        "MT5アカウントはありません。 このプロセスの前にMT5アカウントを登録してください",
      account: "アカウント",
      first_step: {
        deposit_with: "で入金します",
        recommended: "推奨",
        show_all_method: "すべての方法を表示します",
        title: "ステップ1",
        description: "アカウントを選択します",
        choose_account: "アカウントを選択します",
      },
      second_step: {
        title: "金額を入力します",
        description: "額",
        you_receive: "あなたは受け取ります",
        fee: "取引手数料",
        amount_more_than: "金額はそれ以上のものです",
        minimun_requirement: "最小要件を満たしていません",
        minimun_deposit: "最小預金",
      },
      third_step: {
        title: "ステップ2",
        description: "メソッド支払い",
        go_account: "私の会計に行きます",
      },
    },
    deposit_error: {
      input: "ここで価値を入力してください。",
      account: "アカウントはまだ選択されていません。",
      amount: "金額は3米ドルを超える必要があります。",
      h2p: "金額はそれ以上のものです",
      ecn: "金額は200米ドルを超える必要があります",
      raw: "金額は1000米ドルを超える必要があります",
      pro: "金額は1000米ドルを超える必要があります",
      nomore: "最大堆積物",
    },
    error_deposit: {
      text_1: "あなたの地域ではまだ利用できません",
    },
  },
};
