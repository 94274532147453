import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Connect from "./Connect";
import { Spin } from "antd";

const Private = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Connect
        mapStateToProps={state => {
          return ({
            loading: state.user.loading,
            isAuth: state.user.isAuth,
            accessToken: state.user.accessToken
          })
        }}
        mapDispatchToProps={{}}
      >
        {({ isAuth, accessToken, loading }) => {
          return isAuth || accessToken ? (
            <Spin spinning={loading}>
              <Component {...props} />
            </Spin>
          ) : (
              <Redirect
                to={{
                  pathname: `/${localStorage.getItem('locale')}/login`,
                }}
              />
            )
        }}
      </Connect>
    )}
  />
)

export { Private }
