export default {
  th: {
    contactus_header: "	มีคำถามหรือต้องการความช่วยเหลือจากผู้เชี่ยวชาญ?	",
    contactus_sub_header1:
      "	ทีมบริการลูกค้าเฉพาะของเราอยู่ที่นี่ 24/7 เพื่อช่วยเหลือคุณ	",
    contactus_sub_header2:
      "	ไม่ว่าคุณจะยังใหม่กับ iuxmarkets หรือมีคำถามเกี่ยวกับบัญชีที่มีอยู่ของคุณเราสามารถช่วยได้	",
  },

  la: {
    contactus_header: "	ມີຄໍາຖາມຫຼືຕ້ອງການຄວາມຊ່ວຍເຫຼືອດ້ານຜູ້ຊ່ຽວຊານບໍ?	",
    contactus_sub_header1:
      "	ທີມງານບໍລິການລູກຄ້າທີ່ອຸທິດຕົນຂອງພວກເຮົາແມ່ນຢູ່ທີ່ນີ້ 24/7 ເພື່ອຊ່ວຍທ່ານ.	",
    contactus_sub_header2:
      "	ບໍ່ວ່າທ່ານຈະເປັນຄົນໃຫມ່ທີ່ເປັນ iuxchmarets ຫຼືມີຄໍາຖາມກ່ຽວກັບບັນຊີທີ່ມີຢູ່ຂອງທ່ານ, ພວກເຮົາສາມາດຊ່ວຍໄດ້.	",
  },

  en: {
    contactus_header: "	Have a question or require specialist assistance?	",
    contactus_sub_header1:
      "	Our dedicated customer service team is here 24/7 to assist you.	",
    contactus_sub_header2:
      "	Whether you’re new to IUXMarkets or have a question about your existing account, we can help.	",
  },
  in: {
    contactus_header: "	एक प्रश्न या विशेषज्ञ सहायता की आवश्यकता है?	",
    contactus_sub_header1:
      "	हमारी सहायता के लिए हमारी समर्पित ग्राहक सेवा टीम 24/7 है।	",
    contactus_sub_header2:
      "	चाहे आप Iuxmarkets के लिए नए हों या आपके मौजूदा खाते के बारे में कोई प्रश्न हो, हम मदद कर सकते हैं।	",
  },
  my: {
    contactus_header: "	Ada soalan atau memerlukan bantuan pakar?	",
    contactus_sub_header1:
      "	Pasukan perkhidmatan pelanggan kami yang berdedikasi di sini 24/7 untuk membantu anda.	",
    contactus_sub_header2:
      "	Sama ada anda baru untuk iuxmarket atau mempunyai soalan mengenai akaun anda yang sedia ada, kami boleh membantu.	",
  },
  id: {
    contactus_header: "	Punya pertanyaan atau membutuhkan bantuan spesialis?	",
    contactus_sub_header1:
      "	Tim layanan pelanggan kami yang berdedikasi ada di sini 24/7 untuk membantu Anda.	",
    contactus_sub_header2:
      "	Apakah Anda baru mengenal iuxmarket atau memiliki pertanyaan tentang akun Anda yang ada, kami dapat membantu.	",
  },
  cn: {
    contactus_header: "	有疑問還是需要專業幫助？	",
    contactus_sub_header1: "	我們專門的客戶服務團隊在這裡24/7可以為您提供幫助。	",
    contactus_sub_header2:
      "	無論您是iuxmarkets的新手還是對您現有帳戶有疑問，我們都可以提供幫助。	",
  },
  vn: {
    contactus_header: "	Có một câu hỏi hoặc yêu cầu hỗ trợ chuyên gia?	",
    contactus_sub_header1:
      "	Nhóm dịch vụ khách hàng chuyên dụng của chúng tôi ở đây 24/7 để hỗ trợ bạn.	",
    contactus_sub_header2:
      "	Cho dù bạn mới sử dụng iuxmarkets hoặc có câu hỏi về tài khoản hiện tại của bạn, chúng tôi có thể giúp đỡ.	",
  },
  kr: {
    contactus_header: "	질문이 있거나 전문적인 지원이 필요하십니까?	",
    contactus_sub_header1:
      "	우리의 전용 고객 서비스 팀이 당신을 도와주기 위해 24/7에 있습니다.	",
    contactus_sub_header2:
      "	IUXMARKETS를 처음 사용하든 기존 계정에 대한 질문이 있든 도움을 드릴 수 있습니다.	",
  },
  ph: {
    contactus_header:
      "	Mayroon bang isang katanungan o nangangailangan ng tulong sa espesyalista?	",
    contactus_sub_header1:
      "	Narito ang aming dedikadong koponan ng serbisyo sa customer 24/7 upang tulungan ka.	",
    contactus_sub_header2:
      "	Kung bago ka sa Iuxmarkets o may katanungan tungkol sa iyong umiiral na account, makakatulong kami.	",
  },
  jp: {
    contactus_header: "質問がありますか、それとも専門家の支援が必要ですか？",
    contactus_sub_header1:
      "私たちの専用のカスタマーサービスチームは、あなたを支援するために24時間年中無休です。",
    contactus_sub_header2:
      "iuxmarketsを初めて使用する場合でも、既存のアカウントについて質問がある場合でも、お手伝いできます。",
  },
};
