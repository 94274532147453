import React, { Component, useEffect, useState } from "react";
import "./assets/scss/app.scss";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { fetchToken, onMessageListener } from "./firebase";
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
  i18nReducer,
} from "react-redux-i18n";
import HttpsRedirect from "react-https-redirect";
import { translationsObject } from "./transations";
import "./App.css";
import "./Card.css";
import "./Loyalty.css";

import "antd/dist/antd.css";
import "react-alice-carousel/lib/alice-carousel.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import Router from "./Router";
import reducers from "./reducers";
import { YongAuth } from "./actions";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  combineReducers({
    ...reducers,
    i18n: i18nReducer,
  }),
  composeEnhancers(applyMiddleware(thunk))
);

syncTranslationWithStore(store);
store.dispatch(loadTranslations(translationsObject));
store.dispatch(setLocale(localStorage.getItem("locale") || "th"));

// class App extends Component {
//   componentWillMount() {
//     if (!store.getState().auth.isInitialize) {
//       store.dispatch(YongAuth(store.getState().user));
//       if (localStorage.getItem("locale") === "la") {
//         document.body.style.cssText = "font-family: Noto Sans Lao !important;";
//       }
//     }
//   }

//   render() {
//     return (
//       <>
//         <Provider store={store}>
//           <HttpsRedirect>
//             <Router />
//           </HttpsRedirect>
//         </Provider>
//       </>
//     );
//   }
// }

// export default App;

function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);
  const onShowNotificationClicked = () => {
    setNotification({
      title: "Notification",
      body: "This is a test notification",
    });
    setShow(true);
  };

  useEffect(() => {
    if (!store.getState().auth.isInitialize) {
      store.dispatch(YongAuth(store.getState().user));
      if (localStorage.getItem("locale") === "la") {
        document.body.style.cssText = "font-family: Noto Sans Lao !important;";
      }
    }
  }, []);
  // useEffect(() => {
  //   fetchToken(setTokenFound);

  //   onMessageListener()
  //     .then((payload) => {
  //       console.log(payload, "payload");
  //       setNotification({
  //         title: payload.notification.title,
  //         body: payload.notification.body,
  //       });
  //       alert(payload.notification.title);
  //       setShow(true);
  //     })
  //     .catch((err) => console.log("failed: ", err));
  //   if ("serviceWorker" in navigator) {
  //     window.addEventListener("load", () => {
  //       navigator.serviceWorker
  //         .register("/firebase-messaging-sw.js")
  //         .then((registration) => {
  //           console.log(
  //             "Service Worker registered with scope:",
  //             registration.scope
  //           );
  //         })
  //         .catch((error) => {
  //           console.error("Service Worker registration failed:", error);
  //         });
  //     });
  //   }
  // }, []);
  // console.log(isTokenFound, "isTokenFound");
  return (
    <>
      <Provider store={store}>
        <HttpsRedirect>
          <Router />
        </HttpsRedirect>
      </Provider>
    </>
  );
}

export default App;
