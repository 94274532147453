import React, { useEffect, useState } from "react";
import { Checkbox, Modal } from "antd";
import styled from "styled-components";
import { Button } from "antd";
import { getPolicyApi } from "../../api/policy";
import { useDispatch } from "react-redux";
import { getUser } from "../../actions";

const locale = require("react-redux-i18n").I18n;

const CheckBoxButton = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  border: 2px solid ${(props) => (props.active ? "#1cb894" : "#fff")};
  cursor: pointer;
`;

const Inside = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? "#1cb894" : "#fff")};
  margin: auto;
`;

const CloseBtn = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  color: #000;
`;

const DialogPolicy = ({ onClose }) => {
  const [checkBox, setCheckBox] = useState(false);

  const handleCheckBox = (e) => {
    setCheckBox(e.target.checked);
  };

  return (
    <div className="h-100">
      <div
        style={{
          background:
            "url('" + "assets/images/world-iux.svg" + "') center no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div>
          <div className="p-4">
            <div>
              <h1 className="font-bold-iux color-textbox">IUX MARKETS</h1>
              <div style={{ whiteSpace: "break-spaces" }} className="mt-3">
                {locale.t("policy_dashboard_1")}
              </div>
              <div className="d-flex flex-column justify-content-center mt-3">
                <div className="d-flex justify-content-center pt-3 pb-3">
                  <Checkbox onChange={handleCheckBox} />
                  &nbsp;<span>{locale.t("accept_policy")}</span>
                </div>
                <div className="d-flex justify-content-center">
                  <Button
                    className="btn-primary-new-iux"
                    disabled={!checkBox}
                    onClick={onClose}
                  >
                    CONFIRM
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogPolicy;
