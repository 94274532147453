import { Col, Row, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { get, post } from "../../../../helper/request";

const ItemSelect = styled.div`
  box-shadow: 0 0 7px #80808033;
  padding: 12px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
  height: 100%;
  &:hover {
    background: #1f73b7;
    color: #fff;
  }
`;
export const postMoneyspace = ({ amount_thb, account_trade_id, ipaddress }) => {
  return post(`${process.env.REACT_APP_API_URL + "v2/deposit/moneyspace"}`, {
    amount_thb: amount_thb,
    account_trade_id: account_trade_id,
  });
};

const Moneyspace = (props) => {
  const selectIp = useSelector(({ user: { ipAddress } }) => ipAddress);
  const { paymentId } = props;
  const lang = localStorage.getItem("locale");
  const refPerfect = useRef(null);
  const [loading, setLoading] = useState(false);
  const [linkPay, setLinkPay] = useState(null);

  useEffect(() => {
    onFinish();
  }, [selectIp]);
  const onFinish = async (el) => {
    try {
      setLoading(true);
      const payload = {
        account_trade_id: props.account.id,
        amount_thb: +props.amount,
      };
      const { data } = await postMoneyspace(payload);

      if (data?.data) {
        setLoading(true);

        await Promise.all([setLinkPay(data?.data[0]?.link_payment)]).then(
          () => {
            refPerfect.current.click();
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Spin spinning={loading}>
      <a ref={refPerfect} href={linkPay} hidden>
        Money space
      </a>
    </Spin>
  );
};

export default Moneyspace;
