export const CheckFormatDate = (id) => {
  switch (id) {
    case "th":
      return "DDMMMYY";
    case "en":
      return "DDMMMYY";
    case "cn":
      return "DDMMMYY";
    case "my":
      return "DDMMMYY";
    case "in":
      return "DDMMMYY";
    case "la":
      return "DDMMMYY";
    case "id":
      return "DDMMMYY";
    case "vn":
      return "DDMMMYY";
    case "ph":
      return "DDMMMYY";
    case "jp":
      return "YYYY年 MMM D日";
    case "kr":
      return "DDMMMYY";
    default:
      return "DDMMMYY";
  }
};
//
