export default {
  th: {
    social_trade_cover_title: "Eiloy Social Trade",
    social_trade_cover_description:
      "IUX Markets มีความยินดีที่จะได้ให้บริการ AutoTrade แก่ลูกค้าของเราซึ่งนี่คือบริการคัดลอกบัญชีรุ่นใหม่ (คัดลอกการเทรด) ซึ่งอยู่ภายใต้การดำเนินงานของ Eiloy Social Trade",
    social_trade_cover_buttonText: "ไปที่ Eiloy",

    social_trade_eiloy_social_title: "Eiloy Social คืออะไร ?",
    social_trade_eiloy_social_description:
      "IUX Markets มีความยินดีที่จะได้ให้บริการ AutoTrade แก่ลูกค้าของเรา ซึ่งนี่คือบริการคัดลอกบัญชีรุ่นใหม่ (คัดลอกการเทรด) ซึ่งอยู่ภายใต้การดำเนินงานของ IUX Markets บริการนี้จะให้คุณสามารถคัดลอกการเทรด ของระบบที่คุณเลือกไปยังบัญชี IUX Markets MetaTrader 4 ของคุณได้โดยตรง ด้วย AutoTrade คุณจะไม่ ต้องจ่ายค่าธรรมเนียมตามปริมาณการซื้อขายหรือติดตั้งซอฟต์แวร์ที่ซับซ้อนใดๆ มันง่ายมากและทำให้คุณ มีรายได้เพียงคุณเลือกมาสเตอร์ได้ถูกต้อง",
    social_trade_eiloy_social_side_box: {
      text_1: "ความเร็วสูงสุดลื่นไหลไม่มีดีเลย์",
      text_2: "เลเวอเรจสูงสุด 1:1000",
      text_3: "ขนาด lot ต่ำสุด 0.01",
      text_4: "มีตัวเลือกการฝากเงินและถอนเงินที่หลากหลาย",
      text_5: "ไม่มีข้อจำกัดในการซื้อขาย",
      text_6: "ฝากขั้นต่ำเพียง 30 เหรียญ",
      text_7: "Metatrader",
    },

    social_trade_why_mt4_title:
      "ทำไมแพลตฟอร์ม IUX Markets MetaTrader 4 จึงมีความแตกต่าง?",
    social_trade_why_mt5_title:
      "ทำไมแพลตฟอร์ม IUX Markets MetaTrader 5 จึงมีความแตกต่าง?",
    social_trade_why_mt4_sub_title: "การดำเนินคำสั่งที่รวดเร็วเป็นอย่างมาก\n",
    social_trade_why_mt4_description:
      "เซิฟเวอร์ของ IUX Markets MetaTrader 4 นี้ถือเป็นระบบนิเวศทางการเงินที่เป็นที่ตั้งของบริษัทที่เป็นผู้ทำการซื้อขาย ตลาด พื้นที่ซื้อขาย ผู้ให้บริการและข้อมูลเกี่ยว กับตลาดกว่า 600 ราย เซิฟเวอร์ MetaTrader 4 ยังได้มีการเชื่อมต่อข้ามมายัง Cloud ของเรา เพื่อให้แน่ใจว่าเกิดเวลาแฝงที่ต่ำและมีการดำเนินคำสั่งซื้อขายที่รวดเร็ว\n" +
      "\n" +
      "เซิฟเวอร์ IUX Markets MetaTrader 4 มีเวลาแฝงน้อยกว่า 1 มิลลิวินาที ไปยังผู้ให้บริการ VPS หลัก ไม่ว่าจะอยู่ในศูนย์ข้อมูลผ่านทางสายแบบเฉพาะไปยังศูนย์ข้อมูล \n" +
      "\n",
    social_trade_eiloy_different: {
      title: "อะไรที่ทำให้ Eiloy Social Trade มีความแตกต่าง\n",
      text_box_1: "มีการแสดงเฉพาะระบบที่ดีที่สุดเท่านั้น",
      text_box_2: "บัญชีจริงเท่านั้น\n",
      text_box_3: "ไม่มีสิ่งจูงใจ",
      text_box_4: "สถิติที่แม่นยำ\n",
      text_box_5: "ไม่มีค่าธรรมเนียมแอบแฝง",
      text_box_6: "ควบคุมได้อย่างสมบูรณ์",
    },
  },
  la: {
    social_trade_cover_title: "Eiloy Social Trade",
    social_trade_cover_description:
      "IUX Markets ມັນເປັນຄວາມຍິນດີທີ່ຈະສະ ເໜີ AutoTrade ໃຫ້ແກ່ລູກຄ້າຂອງພວກເຮົາ, ນີ້ແມ່ນການບໍລິການ ສຳ ເນົາບັນຊີລຸ້ນຕໍ່ໄປ. (ສຳ ເນົາການຄ້າ) ເຊິ່ງຢູ່ພາຍໃຕ້ການ ດຳ ເນີນງານຂອງ Eiloy Social Trade",
    social_trade_cover_buttonText: "ໄປທີ່ Eiloy",

    social_trade_eiloy_social_title: "Eiloy Social ແມ່ນ​ຫຍັງ?",
    social_trade_eiloy_social_description:
      "IUX Markets ມັນເປັນຄວາມຍິນດີທີ່ສາມາດໃຫ້ AutoTrade ແກ່ລູກຄ້າຂອງພວກເຮົາ ນີ້ແມ່ນການບໍລິການສຳເນົາບັນຊີລຸ້ນໃໝ່. (ສຳເນົາການຄ້າ) ມັນ ດຳ ເນີນການໂດຍ IUX Markets ບໍລິການນີ້ຊ່ວຍໃຫ້ທ່ານສາມາດຄັດລອກການຄ້າ. ໃນລະບົບທີ່ທ່ານເລືອກເຂົ້າໃນບັນຊີ IUX Markets MetaTrader 4 ຂອງທ່ານໂດຍກົງກັບ AutoTrade, ທ່ານຈະບໍ່ຕ້ອງເສຍຄ່າ ທຳ ນຽມທາງປະລິມານຫລືຕິດຕັ້ງຊອບແວທີ່ສັບສົນໃດໆ. ມັນງ່າຍຫຼາຍແລະເຮັດໃຫ້ທ່ານ ຫາເງິນໄດ້ເທົ່ານັ້ນ, ທ່ານເລືອກເອົານາຍທີ່ຖືກຕ້ອງ.",
    social_trade_eiloy_social_side_box: {
      text_1: "ความเร็วสูงสุดลื่นไหลไม่มีดีเลย์",
      text_2: "เลเวอเรจสูงสุด 1:1000",
      text_3: "ขนาด lot ต่ำสุด 0.01",
      text_4: "ມີຫລາຍທາງເລືອກໃນການຝາກແລະຖອນເງິນ.",
      text_5: "ບໍ່ມີຂໍ້ ຈຳ ກັດດ້ານການເທຮດ",
      text_6: "ຝາກເງີນຂັ້ນຕ່ ຳ ພຽງແຕ່ 30 ໂດລາເທົ່ານັ້ນ.",
      text_7: "Metatrader",
    },

    social_trade_why_mt4_title:
      "ເປັນຫຍັງ IUXMarkets MetaTrader 4 ຈຶ່ງແຕກຕ່າງກັນ?",
    social_trade_why_mt5_title:
      "Why is the IUX Markets MetaTrader 5 platform different?",
    social_trade_why_mt4_sub_title: "ປະຕິບັດຕາມ ລຳ ດັບທີ່ໄວທີ່ສຸດ\n",
    social_trade_why_mt4_description:
      "ເຊີຟເວີຂອງ IUXMarkets MetaTrader 4 ແມ່ນຖືວ່າເປັນລະບົບນິເວດທາງການເງິນທີ່ເປັນບໍລິສັດທີ່ຊື້ຂາຍໃນຕະຫລາດ. ຜູ້ໃຫ້ບໍລິການ 600 ກວ່າຄົນແລະຂໍ້ມູນທີ່ກ່ຽວຂ້ອງກັບຕະຫລາດ, ເຄື່ອງແມ່ຂ່າຍ MetaTrader 4 ຍັງສະ ໜອງ ການເຊື່ອມຕໍ່ຂ້າມກັບ Cloud ຂອງພວກເຮົາເພື່ອຮັບປະກັນຄວາມຊ້າແລະການປະຕິບັດການສັ່ງຊື້ໄດ້ໄວ.\n",
    social_trade_eiloy_different: {
      title: "ສິ່ງທີ່ເຮັດໃຫ້ການຄ້າທາງສັງຄົມ Eiloy ແຕກຕ່າງກັນ?\n",
      text_box_1: "ມີພຽງແຕ່ລະບົບທີ່ດີທີ່ສຸດເທົ່ານັ້ນທີ່ສະແດງໃຫ້ເຫັນ.",
      text_box_2: "ບັນຊີຕົວຈິງເທົ່ານັ້ນ\n",
      text_box_3: "ບໍ່ມີແຮງຈູງໃຈ",
      text_box_4: "ສະຖິຕິທີ່ຖືກຕ້ອງ\n",
      text_box_5: "ບໍ່ມີຄ່າ ທຳ ນຽມທີ່ເຊື່ອງໄວ້",
      text_box_6: "ຄວບຄຸມຢ່າງສົມບູນ",
    },
  },
  en: {
    social_trade_cover_title: "Eiloy Social Trade",
    social_trade_cover_description:
      "IUX Markets is pleased to provide AutoTrade services to our customers, which is the new version of account copy service. (Copy trades) which are operated by Eiloy Social Trade.",
    social_trade_cover_buttonText: "Go To Eiloy",

    social_trade_eiloy_social_title: "What is Eiloy ?",
    social_trade_eiloy_social_description:
      "IUX Markets is pleased to provide AutoTrade services to our customers, which is the new version of account copy service. (Copy trades) which are operated by Eiloy Social Trade.",
    social_trade_eiloy_social_side_box: {
      text_1: "Smooth top speed with no delays",
      text_2: "Leverage up to 1: 1000",
      text_3: "Minimum lot size 0.01",
      text_4: "There are many options for depositing and withdrawing funds.\n",
      text_5: "No trading restrictions\n",
      text_6: "Deposit a minimum of only $ 30.",
      text_7: "Metatrader",
    },

    social_trade_why_mt4_title:
      "Why is the IUX Markets MetaTrader 4 platform different?\n",
    social_trade_why_mt5_title:
      "Why is the IUX Markets MetaTrader 5 platform different?",
    social_trade_why_mt4_sub_title: "Extremely fast execution",
    social_trade_why_mt4_description:
      "The IUX Markets MetaTrader 4 server is considered a financial ecosystem that houses companies trading in the marketplace. Over 600 service providers and market-related data, the MetaTrader 4 server also provides cross-connection to our Cloud to ensure low latency and fast order execution.",
    social_trade_eiloy_different: {
      title: "What makes Eiloy Social Trade different?",
      text_box_1: "Only the best systems are shown.",
      text_box_2: "Real account only",
      text_box_3: "No incentive",
      text_box_4: "Accurate statistics",
      text_box_5: "No hidden fees",
      text_box_6: "Completely controlled",
    },
  },
  in: {
    social_trade_cover_title: "Eiloy Social Trade",
    social_trade_cover_description:
      "IUX Markets is pleased to provide AutoTrade services to our customers, which is the new version of account copy service. (Copy trades) which are operated by Eiloy Social Trade.",
    social_trade_cover_buttonText: "Go To Eiloy",

    social_trade_eiloy_social_title: "What is Eiloy ?",
    social_trade_eiloy_social_description:
      "IUX Markets is pleased to provide AutoTrade services to our customers, which is the new version of account copy service. (Copy trades) which are operated by Eiloy Social Trade.",
    social_trade_eiloy_social_side_box: {
      text_1: "Smooth top speed with no delays",
      text_2: "Leverage up to 1: 1000",
      text_3: "Minimum lot size 0.01",
      text_4: "There are many options for depositing and withdrawing funds.\n",
      text_5: "No trading restrictions\n",
      text_6: "Deposit a minimum of only $ 30.",
      text_7: "Metatrader",
    },

    social_trade_why_mt4_title:
      "Why is the IUX Markets MetaTrader 4 platform different?\n",
    social_trade_why_mt5_title:
      "Why is the IUX Markets MetaTrader 5 platform different?",
    social_trade_why_mt4_sub_title: "Extremely fast execution",
    social_trade_why_mt4_description:
      "The IUX Markets MetaTrader 4 server is considered a financial ecosystem that houses companies trading in the marketplace. Over 600 service providers and market-related data, the MetaTrader 4 server also provides cross-connection to our Cloud to ensure low latency and fast order execution.",
    social_trade_eiloy_different: {
      title: "What makes Eiloy Social Trade different?",
      text_box_1: "Only the best systems are shown.",
      text_box_2: "Real account only",
      text_box_3: "No incentive",
      text_box_4: "Accurate statistics",
      text_box_5: "No hidden fees",
      text_box_6: "Completely controlled",
    },
  },
  my: {
    social_trade_cover_title: `Eiloy Social Trade`,
    social_trade_cover_description: `IUX Markets berbesar hati untuk menawarkan AutoTrade kepada pelanggan kami. Perkhidmatan penyalinan akaun versi baharu (Copy Trade) yang dikendalikan oleh Eiloy Social Trade.`,
    social_trade_cover_buttonText: `Kembali ke Eiloy`,

    social_trade_eiloy_social_title: `Apa itu Eiloy?`,
    social_trade_eiloy_social_description: `IUX Markets berbesar hati untuk menyediakan AutoTrade kepada pelanggan kami. Ini ialah perkhidmatan penyalin akaun versi baharu (Copy Trade) yang dikendalikan oleh IUX Markets. Perkhidmatan ini membolehkan anda menyalin dagangan. Dengan AutoTrade, anda tidak perlu membayar yuran volumetrik atau memasang sebarang perisian yang rumit. Ia sangat mudah dan membuatkan anda dapatkan pendapatan hanya jika anda memilih Master yang betul.`,
    social_trade_eiloy_social_side_box: {
      text_1: `Kelajuan tertinggi yang lancar, tiada kelewatan '`,
      text_2: `Leverage sehingga 1: 1000`,
      text_3: `Saiz lot minimum 0.01`,
      text_4: `Terdapat banyak pilihan untuk menyimpan dan mengeluarkan dana.`,
      text_5: `Tiada had perdagangan`,
      text_6: `Deposit sekurang-kurangnya $ 30.`,
      text_7: `Metatrader`,
    },

    social_trade_why_mt4_title: `Mengapakah platform IUX Markets MetaTrader 4 berbeza?`,
    social_trade_why_mt5_title:
      "Mengapakah platform IUX Markets MetaTrader 5 berbeza?",
    social_trade_why_mt4_sub_title: `Pelaksanaan perintah yang sangat pantas`,
    social_trade_why_mt4_description: `Pelayan IUX Markets MetaTrader 4 ini ialah ekosistem kewangan yang menempatkan syarikat dagangan, pasaran, platform dagangan. Dengan lebih 600 pembekal dan data pasaran. Pelayan MetaTrader 4 juga disambungkan silang kepada Cloud kami untuk memastikan kependaman rendah dan pelaksanaan pesanan yang pantas.`,
    social_trade_eiloy_different: {
      title: `Apakah yang membezakan Eiloy Social Trade?`,
      text_box_1: `Hanya sistem yang terbaik akan ditunjukkan.`,
      text_box_2: `Akaun sebenar sahaja`,
      text_box_3: `Tiada insentif`,
      text_box_4: `Statistik yang tepat`,
      text_box_5: `Tanpa yuran tersembunyi`,
      text_box_6: `Dalam kawalan sepenuhnya`,
    },
  },
  id: {
    social_trade_cover_title: `Eiloy Dagang Sosial`,
    social_trade_cover_description: `IUX Markets adalah senang untuk memberikan layanan Autotrade kepada pelanggan kami, yang merupakan versi baru dari layanan copy rekening. (Copy perdagangan) yang dioperasikan oleh Eiloy Dagang Sosial.`,
    social_trade_cover_buttonText: `Go To Eiloy`,

    social_trade_eiloy_social_title: `Apa Eiloy?`,
    social_trade_eiloy_social_description: `IUX Markets adalah senang untuk memberikan layanan Autotrade kepada pelanggan kami, yang merupakan versi baru dari layanan copy rekening. (Copy perdagangan) yang dioperasikan oleh Eiloy Dagang Sosial.`,
    social_trade_eiloy_social_side_box: {
      text_1: `Halus kecepatan tertinggi tanpa penundaan`,
      text_2: `Memanfaatkan hingga 1: 1000`,
      text_3: `ukuran lot minimum 0.01`,
      text_4: `Ada banyak pilihan untuk penyetoran dan penarikan dana.`,
      text_5: `Tidak ada pembatasan perdagangan`,
      text_6: `Deposit minimal hanya $ 30.`,
      text_7: `metatrader`,
    },

    social_trade_why_mt4_title: `Mengapa IUX Markets MetaTrader 4 platform yang berbeda?`,
    social_trade_why_mt5_title:
      "Why is the IUX Markets MetaTrader 5 platform different?",
    social_trade_why_mt4_sub_title: `Sangat cepat perintah eksekusi`,
    social_trade_why_mt4_description: `The IUX Markets MetaTrader 4 Server dianggap sebagai ekosistem keuangan yang rumah perusahaan perdagangan di pasar. Lebih dari 600 penyedia layanan dan data terkait pasar, MetaTrader 4 Server juga menyediakan cross-koneksi ke Cloud kami untuk memastikan latency rendah dan eksekusi order cepat.`,
    social_trade_eiloy_different: {
      title: `Apa yang membuat Eiloy Dagang Sosial yang berbeda?`,
      text_box_1: `Hanya sistem terbaik yang akan ditampilkan.`,
      text_box_2: `real account hanya`,
      text_box_3: `ada insentif`,
      text_box_4: `statistik yang akurat`,
      text_box_5: `Tidak ada biaya tersembunyi`,
      text_box_6: `-benar dikontrol`,
    },
  },
  cn: {
    social_trade_cover_title: `Eiloy社会贸易`,
    social_trade_cover_description: `IUX Markets非常高​​兴地向我们的客户，这是帐户复制服务的新版本，提供自动交易服务。 （复制交易），这是由Eiloy社会交易操作。`,
    social_trade_cover_buttonText: `转到Eiloy`,

    social_trade_eiloy_social_title: `什么是Eiloy？`,
    social_trade_eiloy_social_description: `IUX Markets非常高​​兴地向我们的客户，这是帐户复制服务的新版本，提供自动交易服务。 （复制交易），这是由Eiloy社会交易操作。`,
    social_trade_eiloy_social_side_box: {
      text_1: `顶部光滑速度没有延误`,
      text_2: `杠杆高达1：1000`,
      text_3: `最小地块面积0.01`,
      text_4: `有，用于存放与取款许多选项。`,
      text_5: `没有交易限制`,
      text_6: `存款最少的只有30 $。`,
      text_7: `MetaTrader的`,
    },

    social_trade_why_mt4_title: `为什么IUX Markets MetaTrader 4平台有什么不同？`,
    social_trade_why_mt5_title:
      "Why is the IUX Markets MetaTrader 5 platform different?",
    social_trade_why_mt4_sub_title: `极快的命令执行`,
    social_trade_why_mt4_description: `该IUX Markets的MetaTrader 4服务器被认为是金融生态系统的房屋公司在市场上交易。超过600个服务供应商和市场的相关数据，MetaTrader的4服务器还提供了跨连接到我们的云，以确保低延迟和快速的订单执行。`,
    social_trade_eiloy_different: {
      title: `是什么让Eiloy社会交易有什么不同？`,
      text_box_1: `只有最优秀的系统中。`,
      text_box_2: `只有真实账户`,
      text_box_3: `没有动力`,
      text_box_4: `准确的统计信息`,
      text_box_5: `无隐藏费用`,
      text_box_6: `完全控制`,
    },
  },
  vn: {
    social_trade_cover_title: "Eiloy Social Trade",
    social_trade_cover_description:
      "IUX Markets rất vui được phục vụ AutoTrade cho khách hàng của chúng tôi, đây là dịch vụ sao chép tài khoản thế hệ mới. (Sao chép giao dịch) dưới  hoạt động của Eiloy Social Trade ",
    social_trade_cover_buttonText: "tại Eiloy",

    social_trade_eiloy_social_title: "Eiloy Social là gì?",
    social_trade_eiloy_social_description:
      "IUX Markets rất vui được phục vụ AutoTrade cho khách hàng của chúng tôi, đây là dịch vụ sao chép tài khoản thế hệ mới. (Sao chép giao dịch) ở đưới hoạt động của IUX Markets. Dịch vụ này cho bạn sao chép các giao dịch của hệ thống mà bạn đã chọn sang tài khoản IUX Markets MetaTrader 4 của bạn trực tiếp với AutoTrade, bạn sẽ không phải trả phí theo khối lượng hoặc cài đặt bất kỳ phần mềm phức tạp nào. Nó rất dễ dàng và làm cho bạn có kiếm được thu nhập chỉ chọn đúng Master.",
    social_trade_eiloy_social_side_box: {
      text_1: "Tốc độ tối đa mượt mà, không có độ trễ.",
      text_2: "Đòn bẩy tối đa 1:1000",
      text_3: "Kích thước lot tối thiểu 0.01",
      text_4: "Có nhiều tùy chọn nạp và rút tiền khác nhau.",
      text_5: "Không hạn chế giao dịch",
      text_6: "Số tiền nạp tối thiểu chỉ là 30 đo la.",
      text_7: "Metatrader",
    },

    social_trade_why_mt4_title:
      "Tại sao nền tảng MetaTrader 4 của IUX Markets lại khác biệt?",
    social_trade_why_mt5_title:
      "Tại sao nền tảng MetaTrader 5 của IUX Markets lại khác biệt??",
    social_trade_why_mt4_sub_title: "Thực hiện đơn hàng rất nhanh chóng",
    social_trade_why_mt4_description:
      "Máy chủ IUX Markets MetaTrader 4 này là một hệ sinh thái tài chính, là nơi đặt  công ty thương mại, thị trường, nền tảng giao dịch. Với hơn 600 nhà cung cấp và dữ liệu thị trường, Máy chủ MetaTrader 4 cũng được kết nối chéo với Đám mây của chúng tôi để đảm bảo độ trễ thấp và thực hiện đơn hàng nhanh chóng." +
      "\n" +
      "Máy chủ IUX Markets MetaTrader 4  độ trễ dưới 1 mili giây cho tất cả các nhà cung cấp VPS lớn cho dù ở trong trung tâm dữ liệu thông qua các đường dây chuyên dụng tới trung tâm dữ liệu." +
      "\n",
    social_trade_eiloy_different: {
      title: `Điều gì làm cho Eiloy Social Trade khác biệt?`,
      text_box_1: "Chỉ những hệ thống tốt nhất mới được hiển thị.",
      text_box_2: "Chỉ tài khoản thực",
      text_box_3: "Không có sự khuyến khích",
      text_box_4: "Thống kê chính xác",
      text_box_5: "Không có phí ẩn",
      text_box_6: "Kiểm soát hoàn toàn",
    },
  },
  kr: {
    social_trade_cover_title: `Eiloy 사회 전시회`,
    social_trade_cover_description: `IUX Markets는 계정 복사 서비스의 새로운 버전입니다 고객에게 자동 거래 서비스를 제공하기 위해 기쁘게 생각합니다. Eiloy 사회 무역에 의해 운영되는 (복사 거래).`,
    social_trade_cover_buttonText: `이동] Eiloy`,

    social_trade_eiloy_social_title: `Eiloy은 무엇인가?`,
    social_trade_eiloy_social_description: `IUX Markets는 계정 복사 서비스의 새로운 버전입니다 고객에게 자동 거래 서비스를 제공하기 위해 기쁘게 생각합니다. Eiloy 사회 무역에 의해 운영되는 (복사 거래).`,
    social_trade_eiloy_social_side_box: {
      text_1: `지연없이 '로 최고 속도를 부드럽게`,
      text_2: `1까지 활용 : 1000`,
      text_3: `최소 부지의 크기 0.01`,
      text_4: `입금 및 자금 인출을위한 많은 옵션이 있습니다.`,
      text_5: `어떤 거래 제한 없음`,
      text_6: `단지 $ 30 최소 입금.`,
      text_7: `메타 트레이더`,
    },

    social_trade_why_mt4_title: `왜 IUX Markets 메타 트레이더 4 플랫폼 다른 무엇입니까?`,
    social_trade_why_mt5_title:
      "Why is the IUX Markets MetaTrader 5 platform different?",
    social_trade_why_mt4_sub_title: `매우 빠른 명령 실행`,
    social_trade_why_mt4_description: `IUX Markets 메타 트레이더 4 서버는 주택 업체들이 시장에서 거래하는 금융 생태계로 간주됩니다. 서비스 제공 업체 및 시장 관련 데이터 (600) 동안, 메타 트레이더 4 서버는 낮은 지연 시간과 빠른 주문 실행을 보장하기 위해 우리의 클라우드에 대한 상호 연결을 제공합니다.`,
    social_trade_eiloy_different: {
      title: `무엇 Eiloy 사회 무역 다른한다?`,
      text_box_1: `오직 최고의 시스템이 표시됩니다.`,
      text_box_2: `실제 계정 만`,
      text_box_3: `인센티브 없다`,
      text_box_4: `정확한 통계`,
      text_box_5: `어떤 숨겨진 수수료 없음`,
      text_box_6: `완전히 제어`,
    },
  },
  ph: {
    social_trade_cover_title: `Eiloy Social Trade`,
    social_trade_cover_description: `IUX Markets ay nalulugod na magbigay ng mga serbisyo AutoTrade sa aming mga customer, na kung saan ay ang bagong bersyon ng account copy serbisyo. (Copy trades) na pinatatakbo ng Eiloy Social Trade.`,
    social_trade_cover_buttonText: `Pumunta Upang Eiloy`,

    social_trade_eiloy_social_title: `Ano ang Eiloy?`,
    social_trade_eiloy_social_description: `IUX Markets ay nalulugod na magbigay ng mga serbisyo AutoTrade sa aming mga customer, na kung saan ay ang bagong bersyon ng account copy serbisyo. (Copy trades) na pinatatakbo ng Eiloy Social Trade.`,
    social_trade_eiloy_social_side_box: {
      text_1: `Makinis top speed na walang pagkaantala '`,
      text_2: `Paghusayin hanggang sa 1: 1000`,
      text_3: `Minimum lot size 0.01`,
      text_4: `Mayroong maraming mga pagpipilian para sa pagdedeposito ng at ma-withdraw ang mga pondo.`,
      text_5: `Walang mga paghihigpit kalakalan`,
      text_6: `Magdeposito ng isang minimum na $ 30 lamang.`,
      text_7: `Metatrader`,
    },

    social_trade_why_mt4_title: `Bakit ang IUX Markets MetaTrader 4 platform naiiba?`,
    social_trade_why_mt5_title:
      "Why is the IUX Markets MetaTrader 5 platform different?",
    social_trade_why_mt4_sub_title: `Lubhang mabilis utos ng execution`,
    social_trade_why_mt4_description: `Ang IUX Markets MetaTrader 4 server ay itinuturing na isang pinansiyal na ecosystem na bahay mga kumpanya trading sa merkado. Sa paglipas ng 600 mga service provider at market-kaugnay na data, ang MetaTrader 4 server ay nagbibigay din ng cross-koneksyon sa aming Cloud upang matiyak mababang latency at mabilis na pagkakasunod-sunod execution.`,
    social_trade_eiloy_different: {
      title: `Ano ang pagkaiba ng Eiloy Social Trade iba't-ibang?`,
      text_box_1: `Tanging ang pinakamahusay na mga sistema ay ipinapakita.`,
      text_box_2: `Real account lamang`,
      text_box_3: `walang insentibo`,
      text_box_4: `tumpak na mga istatistika`,
      text_box_5: `Walang mga nakatagong mga bayarin`,
      text_box_6: `ganap na kinokontrol`,
    },
  },
};
