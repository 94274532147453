import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Row,
  Select,
  Statistic,
  Upload,
} from "antd";
import { CloseOutlined, GlobalOutlined } from "@ant-design/icons";
import { get, post } from "../../helper/request";
import { connect, useDispatch, useSelector } from "react-redux";
import { getUser } from "../../actions/user";

import moment from "moment";

import { Document, Page, pdfjs } from "react-pdf";
import { Checkbox } from "antd";
import { getBankList } from "../../api/verifyuser";
import { onOpenPolicy } from "../../helper/policy";
import { postChangePhoneOtp, postCheckPassword } from "../../api/account";
import OtpInput from "../../components/otpinput/OtpInput";
import FormBookbank from "../dashboard-content/fund/verifies/FormBookbank";

const { Option } = Select;
const { Countdown } = Statistic;
const styleInput = {
  // height: 42,
  borderRadius: 8,
};

const OtpButton = styled(Button)`
  background-color: ${(props) =>
    props.countDown !== 0 ? "#d0d0d02e " : "#fff"} !important;
  border: ${(props) =>
    props.countDown !== 0
      ? "1px solid #d0d0d02e "
      : "1px solid #1CB894"} !important;
  color: ${(props) => (props.countDown !== 0 ? "gray" : "#1CB894")} !important;
  border-radius: 4px;
  height: 40px;
`;

const PhoneDialog = ({
  isModalVisible,
  handleCancel,
  profile,
  locale,
  prefixPhone,
  numberPhone,
  verifyFrom,
}) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [countDown, setCountDown] = useState(0);
  const [location, setLocation] = useState(0);
  const [countries, setCountries] = useState(null);
  const [phone, setPhone] = useState(undefined);
  const [isTime, setIsTime] = useState(moment().add(60, "s").toDate());
  const [otp, setOtp] = useState(undefined);
  const [data, setData] = useState(null);
  const [isFile, setIsFile] = useState(null);
  const [isFileName, setIsFileName] = useState(null);
  const [isFilePDF, setIsFilePDF] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isStep, setIsStep] = useState(1);
  const [loading, setLoading] = useState({
    phone: false,
    otp: false,
    button: false,
  });
  const [form] = Form.useForm();
  const [file, setFile] = useState(null);
  const [checked, setChecked] = useState(false);
  const [dataBankList, setDataBankList] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const onChange = (value) => {
    setOtp(value);

    if (value.trim().length === 6) {
      confirmOtp(value);
    } else {
      setIsError(false);
    }
  };
  useEffect(() => {
    fetchBankList();
    const fetchCountry = async () => {
      try {
        const res = await get(process.env.REACT_APP_API_URL + "countries");
        setCountries(res.data);
      } catch (e) {
        console.log(e);
      }
    };

    fetchCountry();
  }, []);

  const overviewMemo = useMemo(
    () => (
      <Countdown
        // title="Countdown"
        format="ss"
        value={isTime}
        onFinish={() => setIsTime(null)}
      />
    ),
    [isTime]
  );

  const onSelectLocation = (event) => {
    setLocation(event.target.value);
  };

  const onChangePolicy = (e) => {
    setChecked(e.target.checked);
  };
  const requestOtp = async (values) => {
    setLoadingButton(true);
    try {
      const ph = `${prefixPhone}${
        values.phonenumber.charAt(0) === "0"
          ? values.phonenumber.substring(1)
          : values.phonenumber
      }`;
      const payload = {
        phone_number: ph,
      };

      const res = await postChangePhoneOtp(payload);
      setLoadingButton(false);
      setPhone(ph);
      setData(res);
      setIsTime(moment().add(60, "s").toDate());
      setIsStep(3);
      setLoading({ phone: false });
      setIsError(false);
      message.success(locale.t("modal_otp.validate.success"));
    } catch (error) {
      if (error?.response?.data?.code === 1008) {
        message.error("Duplicate phone number");
      } else {
        message.error(error?.response?.data?.error);
      }

      setLoadingButton(false);
      console.log(error);
      setIsTime(null);
    }
  };
  const confirmOtp = async (value) => {
    const ph = `${prefixPhone}${phone}`;
    try {
      setLoading({ otp: true });
      await post(process.env.REACT_APP_API_URL + "v2/phone/confirm", {
        code: +value,
      });
      // setTimeout(async () => {
      //   await post(process.env.REACT_APP_API_URL + "v2/switch-otp", {
      //     to: "phone",
      //   });
      // }, 3000);

      setLoading({ otp: false });
      setIsStep(4);
      dispatch(getUser());
      message.success(locale.t("modal_otp.confirm.success"));
    } catch (e) {
      setIsError(true);
      setOtp("");
      message.error(locale.t("modal_otp.confirm.error"));
      setLoading({ otp: false });
      console.log(e);
      setCountDown(0);
    }
  };
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");
        resolve(base64String);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleVerifyBookBank = async () => {
    setisLoading(true);
    try {
      const values = await form.validateFields();

      const formData = new FormData();
      const filebookbank = values?.upload?.file.preview
        ? values?.upload?.file.preview
        : await fileToBase64(values?.upload?.file.originFileObj)
            .then((base64String) => {
              return base64String;
            })
            .catch((error) => {
              console.error(error);
            });

      const payload = {
        book_bank: filebookbank,
        bank_branch: values?.bank_branch,
        bank_account_type: values?.bank_account_type,
        bank_account_number: values.bank_number,
        bank_id: values.bank_name,
        bank_account_name: values.bookbank_name,
      };

      post(process.env.REACT_APP_API_URL + "v2/user/verify-account", payload)
        .then((res) => {
          setLoading({ button: false });
          setisLoading(false);
          handleCancel();
          dispatch(getUser());
          // message.success(locale.t("modal_otp.confirm.success"));
        })
        .catch((e) => {
          setisLoading(false);
          message.error(e.response.data.message);
          setLoading({ button: false });
        });
    } catch (e) {
      setLoading({ button: false });
    }
  };
  const fetchBankList = async () => {
    try {
      const { data } = await getBankList();
      setDataBankList(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const verifyBookBankForm = () => {
    const uploadOption = {
      onRemove: async (file) => {
        setIsFile(null);
        setIsFilePDF(null);
        setIsFileName(null);
        await form.setFieldsValue({ upload: null });
        await form.validateFields(["upload"]);
      },

      beforeUpload: async (file) => {
        const isPNG = file.type === "image/png";
        const isJPG = file.type === "image/jpg";
        const isJPEG = file.type === "image/jpeg";
        const isPDF = file.type === "application/pdf";
        const isLt2M = file.size / 1024 / 1024 < 8;
        if (!isPNG && !isJPG && !isJPEG && !isPDF) {
          message.error(`${file.name} is not an acceptable file type.`);
          setIsFile(null);
          setIsFileName(null);
          setIsFilePDF(null);
          await form.setFieldsValue({ upload: null });
          // await form.validateFields(["upload"]);
        } else if (!isLt2M) {
          message.error("Your file size exceeds 8MB");
        } else {
          if (isPDF) {
            setIsFileName(file.name);
            setIsFilePDF(file);
            setIsFile(file);
          } else if (!file.url && !file.preview) {
            file.preview = await fileToBase64(file);
            setIsFilePDF(null);
            setIsFile(`data:${file.type};base64,` + file.preview || file.url);
            setIsFileName(file.name);
          } else {
            setIsFilePDF(null);
            setIsFile(`data:${file.type};base64,` + file.preview || file.url);
            setIsFileName(file.name);
          }
        }

        return false;
      },
    };

    return (
      <Form name="verify_book_bank" form={form} className="user">
        <Form.Item
          name="bank_name"
          rules={[
            {
              required: true,
              message: locale.t("dashboardFund.modalVerify.validateBank"),
            },
          ]}
          label={locale.t("dashboardFund.modalVerify.bankLabel")}
        >
          <Select
            placeholder={locale.t("dashboardFund.modalVerify.bankPlaceHolder")}
          >
            {dataBankList?.map((bank, index) => {
              return (
                <>
                  {bank.active === 1 && (
                    <Option key={"bankType_" + index} value={bank.id}>
                      {bank.text}
                    </Option>
                  )}
                </>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="bank_number"
          rules={[
            {
              required: true,
              message: locale.t(
                "dashboardFund.modalVerify.validateBankAccount"
              ),
            },
          ]}
          label={locale.t("dashboardFund.modalVerify.accountNumberLabel")}
        >
          <Input
            placeholder={locale.t(
              "dashboardFund.modalVerify.accountNumberLabel"
            )}
          />
        </Form.Item>
        <Form.Item
          name="bookbank_name"
          rules={[
            {
              required: true,
              message: locale.t("dashboardFund.modalVerify.validateBankName"),
            },
          ]}
          label={locale.t("dashboardFund.modalVerify.accountNameLabel")}
        >
          <Input
            placeholder={locale.t("dashboardFund.modalVerify.accountNameLabel")}
          />
        </Form.Item>
        {user.profile.country_id === 7 && (
          <Form.Item
            name="bank_branch"
            rules={[
              {
                required: true,
                message: "Please Input Bank Branch Correctly",
              },
            ]}
            label={"Bank Branch"}
          >
            <Input placeholder={"Bank Branch"} />
          </Form.Item>
        )}
        <div className="fs-12">
          Please take a picture of your identity document and make sure your
          documents meet the following requirements.
        </div>
        <div className="d-flex align-items-center mt-2">
          <div className={`badge-check`}></div>
          <div className={`ml-2  text-lightgray `}>
            Good quality, bright and clear.
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className={`badge-check`}></div>
          <div className={`ml-2  text-lightgray `}>
            File type jpg, jpeg, png, pdf.
          </div>
        </div>
        <div className="d-flex align-items-center mb-2">
          <div className={`badge-check`}></div>
          <div className={`ml-2  text-lightgray `}>
            All corners of your document must be visible.
          </div>
        </div>
        <Form.Item
          name="upload"
          rules={[
            {
              required: true,
              message: locale.t("dashboardFund.modalVerify.footerText2"),
            },
          ]}
          className="w-100 bookbank"
        >
          <Upload
            {...uploadOption}
            className="w-100 cursor-pointer"
            fileList={false}
          >
            <div className="box-upload-bookbank w-100 d-flex align-items-center">
              <div className="mr-3">
                {isFile ? (
                  <div
                    style={{
                      maxWidth: "52px",
                      borderRadius: 8,
                    }}
                    className=" position-reletive"
                  >
                    <div
                      className="fs-20 cursor-pointer"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 8,
                        zIndex: 8,
                      }}
                      onClick={() => {
                        form.setFieldsValue({ upload: null });
                        setIsFile(null);
                      }}
                    >
                      x
                    </div>
                    {isFilePDF ? (
                      <Document
                        file={isFile}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        <Page
                          width={52}
                          pageIndex={0}
                          renderTextLayer={false}
                          renderAnnotationLayer={false}
                        />
                      </Document>
                    ) : (
                      <img
                        src={isFile}
                        alt="eieiei"
                        width={"100%"}
                        style={{ borderRadius: 8 }}
                      />
                    )}
                  </div>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M17.8144 8.28232C17.4902 6.98659 16.7425 5.8363 15.6899 5.0141C14.6373 4.19189 13.3402 3.74492 12.0045 3.74414C10.6689 3.74337 9.37122 4.18884 8.31766 5.00981C7.26411 5.83079 6.51503 6.98022 6.18937 8.27557C4.65325 8.41973 3.23169 9.15011 2.21999 10.315C1.20829 11.4799 0.684215 12.9897 0.756616 14.5309C0.829017 16.0721 1.49233 17.5261 2.60878 18.591C3.72523 19.6559 5.209 20.2498 6.75187 20.2493H9.00187C9.20079 20.2493 9.39155 20.1703 9.5322 20.0296C9.67286 19.889 9.75187 19.6982 9.75187 19.4993C9.75187 19.3004 9.67286 19.1096 9.5322 18.969C9.39155 18.8283 9.20079 18.7493 9.00187 18.7493H6.75187C6.16093 18.751 5.57542 18.6364 5.0288 18.4118C4.48218 18.1872 3.98513 17.8572 3.56605 17.4406C2.71967 16.5991 2.24223 15.4559 2.23875 14.2624C2.23527 13.069 2.70604 11.923 3.54749 11.0766C4.38894 10.2302 5.53215 9.7528 6.72563 9.74932C6.91807 9.7638 7.10903 9.70629 7.26147 9.58794C7.41391 9.4696 7.51696 9.29885 7.55062 9.10882C7.70334 8.03786 8.23731 7.05796 9.05446 6.34907C9.87161 5.64018 10.9171 5.24989 11.9989 5.24989C13.0807 5.24989 14.1261 5.64018 14.9433 6.34907C15.7604 7.05796 16.2944 8.03786 16.4471 9.10882C16.4864 9.29225 16.588 9.45637 16.7348 9.57318C16.8816 9.69 17.0643 9.75225 17.2519 9.74932C18.4453 9.74932 19.5899 10.2234 20.4339 11.0673C21.2778 11.9113 21.7519 13.0558 21.7519 14.2493C21.7519 15.4428 21.2778 16.5874 20.4339 17.4313C19.5899 18.2752 18.4453 18.7493 17.2519 18.7493H15.0019C14.803 18.7493 14.6122 18.8283 14.4715 18.969C14.3309 19.1096 14.2519 19.3004 14.2519 19.4993C14.2519 19.6982 14.3309 19.889 14.4715 20.0296C14.6122 20.1703 14.803 20.2493 15.0019 20.2493H17.2519C18.7835 20.2333 20.2509 19.6321 21.3536 18.569C22.4562 17.5059 23.1105 16.0613 23.1825 14.5314C23.2544 13.0014 22.7384 11.5018 21.7404 10.34C20.7423 9.17811 19.3377 8.44194 17.8144 8.28232Z"
                      fill="#1CB894"
                    />
                    <path
                      d="M15.2183 14.7801C15.3598 14.9167 15.5492 14.9923 15.7459 14.9906C15.9425 14.9889 16.1307 14.91 16.2697 14.771C16.4088 14.6319 16.4876 14.4438 16.4894 14.2471C16.4911 14.0505 16.4155 13.861 16.2788 13.7196L12.5288 9.96959C12.3882 9.82899 12.1975 9.75 11.9986 9.75C11.7997 9.75 11.609 9.82899 11.4683 9.96959L7.71835 13.7196C7.58173 13.861 7.50613 14.0505 7.50784 14.2471C7.50955 14.4438 7.58843 14.6319 7.72748 14.771C7.86654 14.91 8.05465 14.9889 8.2513 14.9906C8.44794 14.9923 8.63739 14.9167 8.77885 14.7801L11.2486 12.3103V21.7498C11.2486 21.9488 11.3276 22.1395 11.4683 22.2802C11.6089 22.4208 11.7997 22.4998 11.9986 22.4998C12.1975 22.4998 12.3883 22.4208 12.5289 22.2802C12.6696 22.1395 12.7486 21.9488 12.7486 21.7498V12.3103L15.2183 14.7801Z"
                      fill="#1CB894"
                    />
                  </svg>
                )}
              </div>
              {isFileName ? (
                <div className="fs-12 fw-300" style={{ maxWidth: 200 }}>
                  {isFileName}
                </div>
              ) : (
                <div className="cursor-pointer">
                  <p className="fw-700 fs-12 text-left p-0">Upload</p>
                  <p className="fw-300 fs-12">File size cannot exceed 8mb.</p>
                </div>
              )}
            </div>
          </Upload>
        </Form.Item>
        <Form.Item shouldUpdate>
          {({ getFieldValue, getFieldsError }) => (
            <div className="text-center">
              <Button
                loading={isLoading}
                disabled={
                  form.getFieldValue("upload") === undefined ||
                  form.getFieldValue("upload") === null ||
                  getFieldValue("bank_name") === undefined ||
                  getFieldValue("bank_number") === undefined ||
                  getFieldValue("bookbank_name") === undefined ||
                  getFieldsError().filter(({ errors }) => errors.length)
                    .length ||
                  isFile === null
                }
                // type="submit"
                // loading={loading}
                onClick={() => handleVerifyBookBank("upload")}
                className="btn-primary-new-iux"
              >
                Continue
              </Button>
            </div>
          )}
        </Form.Item>
        {/* <div className="text-center">
          <Button
            className="btn-primary-new-iux"
            key="submit"
            type="primary"
            loading={loading.button}
            onClick={handleVerifyBookBank}
          >
            {locale.t("dashboardWallet.modal.confirm")}
          </Button>
        </div> */}
      </Form>
    );
  };
  const onCheckPassword = async (values) => {
    setLoadingButton(true);
    try {
      const payload = {
        password: values.currentPassword,
      };
      const { data } = await postCheckPassword(payload);
      // message.success("success");
      setLoadingButton(false);
      setIsStep(2);
    } catch (error) {
      message.error("Invalid password!");
      setLoadingButton(false);
      console.log(error);
    }
  };
  return (
    <div className="user">
      <Modal
        // title={null}
        visible={isModalVisible.phone}
        footer={null}
        closable={true}
        onCancel={() => {
          form.resetFields();
          setChecked(false);
          setIsStep(1);
          setOtp(undefined);
          handleCancel();
        }}
        title={false}
        className="w-modal width-modal-openAccount user user-change"
      >
        <div style={{ minHeight: 500, position: "relative" }}>
          <div className="ps-4 pe-4 user">
            {isStep === 1 && (
              <>
                <div className="fs-20 fw-700 text-main-blakc text-center">
                  {locale.t("verify_that_title")}
                </div>
                <div className="text-primary-sub-iux text-center mt-4">
                  {profile.email}
                </div>
                <div className="text-center fs-12 text-lightgray-iux mb-5">
                  {locale.t("verify_sub_title")}
                </div>
                <Form
                  onFinish={onCheckPassword}
                  className="p-2 p-md-4 mt-5 "
                  form={form}
                >
                  <div
                    style={{ height: 300 }}
                    className="d-flex align-items-center w-100"
                  >
                    <Form.Item
                      name="currentPassword"
                      label={locale.t("current_password")}
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                      style={{ color: "#8D8D8D" }}
                      className="w-100"
                    >
                      <Input.Password
                        placeholder={locale.t("current_password")}
                        className="user-password"
                      />
                    </Form.Item>
                  </div>
                  <Form.Item shouldUpdate>
                    {({
                      getFieldsValue,
                      getFieldValue,
                      isFieldTouched,
                      getFieldsError,
                    }) => (
                      <div className="d-flex align-items-end">
                        <Button
                          htmlType="submit"
                          shape="round"
                          loading={loadingButton}
                          disabled={
                            !isFieldTouched("currentPassword") ||
                            getFieldsError().filter(
                              ({ errors }) => errors.length
                            ).length
                          }
                          className="mt-5 w-100 btn-primary-new-iux"
                          type="primary"
                          block
                        >
                          {locale.t("dashboardWallet.modal.confirm")}
                        </Button>
                      </div>
                    )}
                  </Form.Item>
                </Form>
              </>
            )}
            {isStep === 2 && (
              <>
                <div className="fs-20 fw-700 text-main-blakc text-center mt-4">
                  {locale.t("change_phone_title")}
                </div>

                <Form
                  onFinish={requestOtp}
                  className="p-2 p-md-4 mt-5"
                  initialValues={{
                    phonenumber: "",
                  }}
                  form={form}
                >
                  <div
                    style={{ minHeight: 300 }}
                    className="d-flex align-items-center w-100"
                  >
                    <div className="w-100">
                      <div className="mt-4 user">
                        {locale.t("modal_otp.label_input")}
                      </div>
                      <Form.Item
                        name="phonenumber"
                        rules={[
                          {
                            required: true,
                            message: locale.t("..."),
                          },
                        ]}
                      >
                        <Input
                          addonBefore={<div>{prefixPhone}</div>}
                          placeholder="xxxxxxxxx"
                          style={{ width: "100%" }}
                          maxLength={13}
                          defaultValue={phone}
                          // onChange={(e) => setPhone(e.target.value)}
                          autocomplete="off"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div
                    className="text-center mt-2 mb-2 d-flex align-items-center flex-column justify-content-end w-100"
                    // style={{ minheight: 300 }}
                  >
                    <div className="mt-3 text-center ">
                      <Checkbox onChange={onChangePolicy}>
                        <span>{locale.t("accept_to_condition")} </span>
                        <span
                          onClick={() => onOpenPolicy("sms_iux")}
                          className="text-primary-new-iux cursor-pointer align-items-center"
                        >
                          {locale.t("contact_sidebar.lable_4")}
                        </span>
                      </Checkbox>
                      <div className="mt-3 text-center fs-10 text-lightgray">
                        {locale.t("sub_change_condition")}
                      </div>
                    </div>

                    {/* <OtpButton
                      loading={loading.phone}
                      countDown={countDown}
                      disabled={countDown !== 0 || !phone}
                      onClick={() => requestOtp("otp")}
                    >
                      {locale.t("modal_otp.button_otp")}
                    </OtpButton> */}
                  </div>{" "}
                  <Form.Item shouldUpdate>
                    {({
                      getFieldsValue,
                      getFieldValue,
                      isFieldTouched,
                      getFieldsError,
                    }) => (
                      <div className="d-flex w-100">
                        <Button
                          htmlType="submit"
                          shape="round"
                          loading={loadingButton}
                          disabled={
                            !checked ||
                            !isFieldTouched("phonenumber") ||
                            getFieldsError().filter(
                              ({ errors }) => errors.length
                            ).length
                          }
                          className="mt-5 w-100 btn-primary-new-iux"
                          type="primary"
                          block
                        >
                          {locale.t("modal_otp.button_otp")}
                        </Button>
                      </div>
                    )}
                  </Form.Item>
                  {/* {countDown !== 0 && (
                    <p className="text-center">{`${locale.t(
                      "modal_otp.request_again"
                    )} ${countDown}`}</p>
                  )} */}
                </Form>
              </>
            )}
            {isStep === 3 && (
              <>
                <div className="fs-20 fw-700 text-main-blakc text-center mt-4 mb-5">
                  {locale.t("change_phone_title")}
                </div>
                <div className="text-main-black fs-14 text-center mt-5">
                  {locale.t("verify_new_phone")}
                </div>
                <div className="text-main-black fs-12 mt-3 text-center">
                  {locale.t("enterotp")}
                </div>
                <div className="text-main-black fs-14 text-center mt-3">
                  {phone}
                </div>
                <div className="mt-2 user d-flex justify-content-center">
                  {/* <Form.Item
                    name="otp"
                    rules={[
                      {
                        required: true,
                        message: locale.t("..."),
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder="Enter your OTP Psassword"
                      style={styleInput}
                      disabled={data === null}
                      onChange={(e) => setOtp(e.target.value)}
                      className="user-change"
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item> */}
                  <OtpInput
                    value={otp}
                    valueLength={6}
                    onChange={onChange}
                    isError={isError}
                    isSuccess={isSuccess}
                  />
                </div>{" "}
                <div className="text-center mt-4">
                  <div className="fs-14 mt-3 text-center text-gray d-flex align-items-center justify-content-center">
                    {isError ? (
                      <div className="fs-16 text-is-error d-flex align-items-center">
                        {locale.t("codenotmatch")}&nbsp;
                        {isTime ? (
                          <div className="d-flex align-items-center">
                            &nbsp;{overviewMemo} &nbsp;{locale.t("seconds")}
                          </div>
                        ) : (
                          <span
                            className="text-primary-new-iux font-weight-bold cursor-pointer"
                            onClick={() =>
                              requestOtp({
                                phonenumber: phone?.replace(prefixPhone, ""),
                              })
                            }
                          >
                            {locale.t("requestotp")}
                          </span>
                        )}
                      </div>
                    ) : isTime ? (
                      <div className="d-flex align-items-center">
                        {locale.t("codeexpiresin")}&nbsp;{overviewMemo} &nbsp;
                        {locale.t("seconds")}
                      </div>
                    ) : (
                      <div className="fs-16">
                        {locale.t("didotp")}
                        <span
                          className="text-primary-new-iux font-weight-bold cursor-pointer"
                          onClick={() =>
                            requestOtp({
                              phonenumber: phone?.replace(prefixPhone, ""),
                            })
                          }
                        >
                          {locale.t("requestotp")}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {isStep === 4 && (
              <>
                <div className="fs-20 fw-700 text-main-blakc text-center mt-4">
                  {locale.t("step3")}
                </div>
                <div
                  className="d-flex justify-content-center my-5 flex-column align-items-center"
                  style={{ minHeight: 500 }}
                >
                  <div className="iux-scan success d-flex justify-content-center mt-4">
                    <img
                      src="/assets/images/verify/success.svg"
                      alt="success"
                    />
                  </div>
                  <div className="fs-16 mt-4 text-lightgray">
                    {locale.t("verify_phone_success")}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        closable={true}
        title={false}
        visible={isModalVisible.bank}
        footer={null}
        className="w-modal width-modal-openAccount user user-change"
        onCancel={() => handleCancel()}
      >
        <div className=" p-2 p-md-5">
          {" "}
          <div className="text-center f-20">{locale.t("modal_bank.title")}</div>
          <div className="box-info-bookbank d-flex my-4">
            <div className="mr-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.33138 9.33333C7.33138 9.70153 7.62985 10 7.99805 10C8.36625 10 8.66471 9.70153 8.66471 9.33333V4.66667C8.66471 4.29848 8.36625 4 7.99805 4C7.62985 4 7.33138 4.29848 7.33138 4.66667V9.33333ZM7.99805 10.6667C7.62985 10.6667 7.33138 10.9651 7.33138 11.3333C7.33138 11.7015 7.62985 12 7.99805 12C8.36625 12 8.66471 11.7015 8.66471 11.3333C8.66471 10.9651 8.36625 10.6667 7.99805 10.6667Z"
                  fill="#999999"
                />
              </svg>
            </div>
            <p className="">{locale.t("modal_bank.description")}</p>
          </div>
          {/* {verifyBookBankForm()} */}
          <FormBookbank onSuccess={() => handleCancel()} isUpdateBank={true} />
        </div>
      </Modal>
    </div>
  );
};

export default PhoneDialog;
