// Screener.jsx
import React, { useEffect, useRef, memo } from "react";

function TechnicalAnalysis(props) {
  const { widgetProps, widgetPropsAny } = props;
  const container = useRef();

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
        {
            "width": "100%",
            "height": 400,
            "symbol": "${widgetProps.symbol}",
            "isTransparent": false,
            "colorTheme": "dark",
            "locale": "${widgetProps.lang}"
        }`;
    container.current.appendChild(script);
  }, []);

  return (
    <div className="tradingview-widget-container" ref={container}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
}

export default memo(TechnicalAnalysis);
