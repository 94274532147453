import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Row, Col, Card } from "antd";
import "react-alice-carousel/lib/alice-carousel.css";
import Carousel from "react-bootstrap/Carousel";
import styled from "styled-components";
import { connect } from "react-redux";
import { InfoCircleOutlined } from "@ant-design/icons";
import MarketBox from "./MarketBox";
import "./styles.scss";
const locale = require("react-redux-i18n").I18n;

export const Banner = (props) => {
  const [index, setIndex] = useState(0);
  const loopArr = [
    locale.t("banner_slide_text1"),
    locale.t("banner_slide_text2"),
    locale.t("banner_slide_text3"),
    locale.t("banner_slide_text4"),
    locale.t("banner_slide_text5"),
  ];
  useEffect(() => {
    const intervalDelayMilliseconds = loopArr[index].length * 500;
    const interval = setInterval(() => {
      setIndex((prevIndex) => {
        // reset index if current index is greater than array size
        return prevIndex + 1 < loopArr.length ? prevIndex + 1 : 0;
      });
    }, intervalDelayMilliseconds);

    return () => clearInterval(interval);
  });

  return (
    <>
      <Carousel>
        {props.bannerList.map((banner) => {
          return (
            <div key={1} className="main-curs" style={{ background: "#000" }}>
              <div
                className="d-block w-100 mobile-responsive"
                style={{
                  background:
                    "url('" +
                    process.env.PUBLIC_URL +
                    "../assets/images/covers/img-landing2.svg" +
                    "')",
                  width: "100%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: "100%",
                }}
              >
                <div className="pb-pt-textCover">
                  <Carousel.Caption className="container">
                    <div className="align-items-center d-lg-block d-md-block d-xl-flex justify-center w-100">
                      <Col className="landing-header col-md-7">
                        <h2
                          className={`banner-titleheader p-3 p-lg-0 p-xl-0 text-lg-center text-white text-xl-left text-uppercase ${
                            props.locale === "ph" ||
                            props.locale === "vn" ||
                            props.locale === "id"
                              ? "fs-32"
                              : ""
                          }`}
                        >
                          {banner.title}
                        </h2>
                        <p
                          className="mt-3 p-3 p-lg-0 p-xl-0 text-lg-center text-xl-left title-font-mobile"
                          dangerouslySetInnerHTML={{ __html: banner.content }}
                        ></p>
                        <div
                          className="d-flex "
                          style={{ padding: "32px 0 24px 0" }}
                        >
                          {/* <div id="container">
                            <div id="flip">
                              {loopArr.map((e, i) => (
                                <div>
                                  <div>{e}</div>
                                </div>
                              ))}
                            </div>
                          </div> */}
                          {/* <div class="offset-header">
                            <h1 class="hero-header">
                              <span class="offset-header offset-header-odd">
                                <span
                                  key={loopArr[index]}
                                  style={{ color: "#FFF" }}
                                >
                                  {loopArr[index]}
                                </span>
                              </span>
                            </h1>
                          </div> */}

                          <div className="content-animation">
                            <div className="content-animation-container">
                              <ul className="content-animation-container-list p-0">
                                {loopArr.map((e, i) => (
                                  <li className="content-animation-container-list-item">
                                    {e}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div className="d-lg-flex d-md-flex d-xl-flex">
                          <div className="text24-center w-100">
                            <Link to={`#`}>
                              <button className="btn-free30 text-center">
                                <p
                                  className="text-center"
                                  style={{ fontSize: 18 }}
                                >
                                  {banner.buttonText}
                                </p>
                                {/* <span className="" style={{ fontSize: 12 }}>
                                  {banner.buttonText2}
                                </span> */}
                              </button>
                            </Link>
                          </div>
                        </div>
                      </Col>
                      <Col className="col-md-5 ">{/* <MarketBox /> */}</Col>
                    </div>
                    <div className="container mt-2 mt-lg-5 mt-md-3 mt-xl-5 pl-4 pl-lg-0 pl-xl-0 pr-4 pr-lg-0 pr-xl-0 ">
                      <Row>
                        <MarketBox />
                      </Row>
                    </div>
                  </Carousel.Caption>
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
    </>
  );
};

const mapStateToProps = ({ i18n }) => ({
  locale: i18n.locale,
});

export default connect(mapStateToProps, null)(Banner);
