import React from "react";
import { Link } from "react-router-dom";
import { Row, Button, Card, Divider, Menu, Popover, Tooltip } from "antd";
import {
  UserOutlined,
  TeamOutlined,
  DeleteOutlined,
  LineChartOutlined,
  SettingOutlined,
  SyncOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import { get } from "../../helper/request";
const locale = require("react-redux-i18n").I18n;

const text = <span>Update</span>;
const menu = (props) => (
  <Menu style={{ border: "none" }}>
    <Menu.Item
      onClick={() => {
        props.onEditPasswordShow(props.account);
      }}
    >
      <UserOutlined />{" "}
      {`${locale.t("dashboardWallet.menu.account_edit")} ${
        props.account.platform
      }`}
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        props.onEditInvestorPasswordShow(props.account);
      }}
    >
      <TeamOutlined /> {locale.t("dashboardWallet.menu.investor_edit")}
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        props.onEditLeverageShow(props.account);
      }}
    >
      <LineChartOutlined /> {locale.t("dashboardWallet.menu.leverage_edit")}
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        props.onDelete(props.account);
      }}
    >
      <DeleteOutlined />
      {locale.t("dashboardWallet.menu.account_close")}
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        props.onArchive(props.account);
      }}
    >
      <FolderOpenOutlined />
      {locale.t("dashboardWallet.menu.account_archive")}
    </Menu.Item>
  </Menu>
);

const DropdownSetting = (props) => (
  <>
    <Popover
      placement="bottom"
      content={menu(props)}
      trigger="click"
      className="cursor-pointer"
    >
      <img src="/assets/images/icons/icon-edit-account.svg" alt="edit" />
    </Popover>
  </>
);

const RealAccountCard = (props) => {
  const account = props.account.account_type;
  const replace = account.replace("StandardBonus", "STDB");
  // const setDeposit = () => {
  //   get(
  //     process.env.REACT_APP_API_URL +
  //       "get-currency?from=THB&to=USD&amount=0&precision=2",
  //     {
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //         Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  //       },
  //     }
  //   ).then((res) => {
  //     localStorage.setItem("USDCurrency", res.data.rate.data);
  //     localStorage.setItem("USDdeposit", res.data.rate.data);
  //     localStorage.setItem("LAKeposit", res.data.rate.data);
  //   });
  // };
  return (
    <Card
      className="account-card"
      headStyle={{ background: "#fff" }}
      title={
        <>
          <div className="align-content-center d-flex justify-content-between w-100">
            <div className="d-flex align-items-center flex-column">
              <div className="">
                <div className="fs-14 fw-700">
                  {props.account.account_number}
                </div>

                <div className="d-flex fs-10">
                  {replace}
                  {" • "}
                  <div className="m-auto">{props.account.platform}</div>
                </div>
              </div>
            </div>
            <div
              className="ml-2"
              style={{
                cursor: "pointer",
                outline: "none",
                boxShadow: "none",
                border: "none",
                fontSize: "14px",
                paddingRight: "8px",
                textAlign: "right",
                paddingLeft: "0",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => props.onSync(props.account)}
              {...props}
            >
              <Tooltip placement="bottom" title={text}>
                <SyncOutlined />
              </Tooltip>
            </div>
          </div>
        </>
      }
      extra={<DropdownSetting {...props} />}
    >
      <Row gutter={[8, 8]}>
        <div span={12} className="ant-respon w-100" style={{ padding: "6px" }}>
          <div className="d-flex justify-content-between w-100 mb-3">
            <div className="fs-12 ">Server</div>
            <div className="fs-14 fw-700">
              {props.account.api_server === "api"
                ? "IUXMarkets-Live"
                : props.account.api_server === "live2"
                ? "IUXMarkets-Live2"
                : props.account.api_server === "demo"
                ? "IUXMarkets-Demo"
                : "IUXMarkets"}
            </div>
          </div>
          <div className="d-flex justify-content-between w-100 mb-3">
            <div className="fs-12 ">
              {locale.t("dashboardWallet.accountBalance")} (USD)
            </div>
            <div className="fs-14 fw-700">
              {props.account.current_balance.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
          <div className="d-flex justify-content-between w-100 mb-3">
            <div className="fs-12 ">
              {locale.t("dashboardWallet.accountCredit")} (USD)
            </div>
            <div className="fs-14 fw-700">
              {props.account.current_credit.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
          <div className="d-flex justify-content-between w-100">
            <div className="fs-12 ">
              {locale.t("dashboardWallet.accountLeverage")}
            </div>
            <div className="fs-14 fw-700">
              1 : {props.account.current_leverage}
            </div>
          </div>
        </div>
      </Row>
      <Row className="w-100">
        <div className="d-flex justify-content-between text-center w-100">
          <Link
            to={{
              pathname: `/${localStorage.getItem(
                "locale"
              )}/wallet/fund/deposit`,
              search: `?account=${props.account.account_number}`,
              state: { account: props.account },
            }}
            className="w-100"
          >
            <Button
              // onClick={setDeposit}
              className="btn-primary-new-iux w-100 "
              type="primary"
              style={{ borderRadius: 8 }}
            >
              {/* {locale.t("dashboardWallet.deposit")} */}Fund
            </Button>
          </Link>{" "}
          <Link
            to={{
              pathname: `/${localStorage.getItem(
                "locale"
              )}/wallet/fund/withdraw`,
              search: `?account=${props.account.account_number}`,
            }}
          >
            <Button className="btn-primary-new-outline-not-hover ml-2">
              <img
                src="/assets/images/icons/withdraw-account.svg"
                alt="withdraw"
                width={24}
              />
            </Button>
          </Link>{" "}
        </div>
      </Row>
    </Card>
  );
};

export default RealAccountCard;
