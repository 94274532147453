export default {
  th: {
    platform_iphone_header: {
      title: "MetaTrader สำหรับ iPhone/iPad\n",
      sub_title:
        "ใช้ประโยชน์ของการเชื่อมต่อแบบ IUX Markets บน MetaTrader เวอร์ชั่นที่เป็นของเราเอง ซึ่งได้ถูกออกแบบมาสำหรับ Iphone / Ipad",
      button_text: "ดาวน์โหลด Metatrader 4",
    },
    platform_tab: {
      mt4: "Meta Trader 4",
      iphone: "MT4 iPhone/ iPad",
      android: "MT4 Android",
      mac: "MT4 Mac",
    },

    platform_iphone_description: `เรามีแอพพลิเคชั่น  MetaTrader 5 บน iPhone และ iPad แอพพลิเคชั่นเหล่านี้จะให้ เทรดเดอร์สามารถเข้าถึงบัญชีได้ทุกที่ที่ต้องการ แอพพลิเคชั่น MetaTrader โมบายของเราใช้ฟีเจอร์ขั้นสูงมากมายของ iPhone และ iPad เช่น การเปลี่ยน แนวนอนเป็นแนวตั้ง (การหมุนตัวเครื่อง) นอกจากนี้แอพพลิเคชั่นโมบาย MetaTrader ยังมีฟังก์ชั่นการเทรด ด้วยการคลิกเพียงครั้งเดียว ความสามารถในการปรับรูปแบบของคุณ รวมถึงกราฟและการวิเคราะห์ขั้นสูง ใช้แอพพลิเคชั่นโมบาย MetaTrader ของเราเพื่อสัมผัสกับความยืดหยุ่นที่ไม่มีจำกัด โดยที่ไม่ต้องลดความเร็ว หรือคุณภาพลง`,
    platform5_iphone_description: `เรามีแอพพลิเคชั่น MetaTrader 5 บน iPhone และ iPad แอพพลิเคชั่นเหล่านี้จะให้ เทรดเดอร์สามารถเข้าถึงบัญชีได้ทุกที่ที่ต้องการ แอพพลิเคชั่น MetaTrader 5 โมบายของเราใช้ฟีเจอร์ขั้นสูงมากมายของ iPhone และ iPad เช่น การเปลี่ยน แนวนอนเป็นแนวตั้ง (การหมุนตัวเครื่อง) นอกจากนี้แอพพลิเคชันโมบาย MetaTrader 5 ยังมีฟังก์ชันการเทรด ด้วยการคลิกเพียงครั้งเดียว ความสามารถในการปรับรูปแบบของคุณ รวมถึงกราฟและการวิเคราะห์ขั้นสูง ใช้แอพพลิเคชั่นโมบาย MetaTrader 5 ของเราเพื่อสัมผัสกับความยืดหยุ่นที่ไม่มีจำกัด โดยที่ไม่ต้องลดความเร็ว หรือคุณภาพลง`,
    platform_android_description: `เรามีแอพพลิเคชั่น MetaTrader 4 สำหรับอุปกรณ์ Android แอพพลิเคชั่นเหล่านี้จะให้ เทรดเดอร์สามารถเข้าถึงบัญชีได้ทุกที่ที่ต้องการ IUX Markets MetaTrader Android แอพพลิเคชั่นจะให้คุณได้เข้าสเปรดชั้นนำของตลาดและความรวดเร็วใน การดำเนินคำสั่งที่ยากจะเทียบเคียงของเราได้โดยตรงบนแอพพลิเคชั่น Android ของคุณ แอพพลิเคชั่นนี้มีฟังก์ชั่น การซื้อขายด้วยการคลิกเพียงครั้งเดียวจากหลายหน้าจอและมีรูปแบบที่สามารถปรับเปลี่ยนได้ ด้วยความสามารถ ในการเรียกดูข้อมูลย้อนหลังและการแสดงกราฟขั้นสูงที่สมบูรณ์แบบ คุณจึงสามารถจัดการบัญชีของคุณ ทำการ เทรดผลิตภัณฑ์ของเราทั้งหมด และใช้อินดิเคเตอร์ทางเทคนิคกว่า 30 เพื่อทำการวิเคราะห์ตลาดได้`,
    platform5_android_description: `เรามีแอพพลิเคชั่น MetaTrader 5 สำหรับอุปกรณ์ Android แอพพลิเคชั่นเหล่านี้จะให้ เทรดเดอร์สามารถเข้าถึงบัญชีได้ทุกที่ที่ต้องการ IUX Markets MetaTrader Android แอพพลิเคชั่นจะให้คุณได้เข้าสเปรดชั้นนำของตลาดและความรวดเร็วใน การดำเนินคำสั่งที่ยากจะเทียบเคียงของเราได้โดยตรงบนแอพพลิเคชั่น Android ของคุณ แอพพลิเคชั่นนี้มีฟังก์ชั่น การซื้อขายด้วยการคลิกเพียงครั้งเดียวจากหลายหน้าจอและมีรูปแบบที่สามารถปรับเปลี่ยนได้ ด้วยความสามารถ ในการเรียกดูข้อมูลย้อนหลังและการแสดงกราฟขั้นสูงที่สมบูรณ์แบบ คุณจึงสามารถจัดการบัญชีของคุณ ทำการ เทรดผลิตภัณฑ์ของเราทั้งหมด และใช้อินดิเคเตอร์ทางเทคนิคกว่า 30 เพื่อทำการวิเคราะห์ตลาดได้`,
    platform_mac_description: `เรามีแอพพลิเคชั่น  MetaTrader 5 บน Mac แอพพลิเคชั่นเหล่านี้จะให้ เทรดเดอร์สามารถเข้าถึงบัญชีได้ทุกที่ที่ต้องการ แอพพลิเคชั่น MetaTrader โมบายของเราใช้ฟีเจอร์ขั้นสูงมากมายของ Mac เช่น การเปลี่ยน แนวนอนเป็นแนวตั้ง (การหมุนตัวเครื่อง) นอกจากนี้แอพพลิเคชั่นโมบาย MetaTrader ยังมีฟังก์ชั่นการเทรด ด้วยการคลิกเพียงครั้งเดียว ความสามารถในการปรับรูปแบบของคุณ รวมถึงกราฟและการวิเคราะห์ขั้นสูง ใช้แอพพลิเคชั่นโมบาย MetaTrader ของเราเพื่อสัมผัสกับความยืดหยุ่นที่ไม่มีจำกัด โดยที่ไม่ต้องลดความเร็ว หรือคุณภาพลง`,
    platform_iphone_section_2: {
      title:
        "เรามีแอพพลิเคชั่น MetaTrader 5 บน iPhone และ iPad แอพพลิเคชั่นเหล่านี้จะให้ เทรดเดอร์สามารถเข้าถึงบัญชีได้ทุกที่ที่ต้องการ\n" +
        "\n" +
        "แอพพลิเคชั่น MetaTrader โมบายของเราใช้ฟีเจอร์ขั้นสูงมากมายของ iPhone และ iPad เช่น การเปลี่ยน แนวนอนเป็นแนวตั้ง (การหมุนตัวเครื่อง) นอกจากนี้แอพพลิเคชั่นโมบาย MetaTrader ยังมีฟังก์ชั่นการเทรด ด้วยการคลิกเพียงครั้งเดียว ความสามารถในการปรับรูปแบบของคุณ รวมถึงกราฟและการวิเคราะห์ขั้นสูง ใช้แอพพลิเคชั่นโมบาย MetaTrader ของเราเพื่อสัมผัสกับความยืดหยุ่นที่ไม่มีจำกัด โดยที่ไม่ต้องลดความเร็ว หรือคุณภาพลง",
      sub_title: "การดำเนินคำสั่งที่รวดเร็วเป็นอย่างมาก\n",
      description:
        "เซิฟเวอร์ของ IUX Markets MetaTrader 4 นี้ถือเป็นระบบนิเวศทางการเงินที่เป็นที่ตั้งของบริษัทที่เป็นผู้ทำการซื้อขาย ตลาด พื้นที่ซื้อขาย ผู้ให้บริการและข้อมูลเกี่ยว กับตลาดกว่า 600 ราย เซิฟเวอร์ MetaTrader 4 ยังได้มีการเชื่อมต่อข้ามมายัง Cloud ของเรา เพื่อให้แน่ใจว่าเกิดเวลาแฝงที่ต่ำและมีการดำเนินคำสั่งซื้อขายที่รวดเร็ว\n" +
        "\n" +
        "เซิฟเวอร์ IUX Markets MetaTrader 4 มีเวลาแฝงน้อยกว่า 1 มิลลิวินาที ไปยังผู้ให้บริการ VPS หลัก ไม่ว่าจะอยู่ในศูนย์ข้อมูลผ่านทางสายแบบเฉพาะไปยังศูนย์ข้อมูล \n" +
        "\n",
      specification_header: "ความต้องการของระบบ\n",
      specification_sub:
        "iPhone iOS 7.0 หรือใหม่กว่า ใช้งานได้กับ iPhone, iPad และ iPod touch\n" +
        "\n",
      header_2: "สิ่งที่เราให้บน IUX Markets\n",
      label_1: "เลเวอเรจสูงสุด 1:1000\n",
      label_2: "โบนัสเงินเทรดฟรี $30\n",
    },
    why_platform: {
      iphone: {
        title1: "ทำไมแพลตฟอร์ม IUX Markets\n",
        title2: "MetaTrader 4 จึงมีความแตกต่าง?",
        title_2_5: "MetaTrader 5 จึงมีความแตกต่าง?",
        subTitle: "การดำเนินคำสั่งที่รวดเร็วเป็นอย่างมาก\n",
        description:
          "เซิฟเวอร์ของ IUX Markets MetaTrader 4 นี้ถือเป็นระบบนิเวศทางการเงินที่เป็นที่ตั้งของบริษัทที่เป็นผู้ทำการซื้อขาย ตลาด พื้นที่ซื้อขาย ผู้ให้บริการและข้อมูลเกี่ยว กับตลาดกว่า 600 ราย เซิฟเวอร์ MetaTrader 4 ยังได้มีการเชื่อมต่อข้ามมายัง Cloud ของเรา เพื่อให้แน่ใจว่าเกิดเวลาแฝงที่ต่ำและมีการดำเนินคำสั่งซื้อขายที่รวดเร็ว\n" +
          "\n" +
          "เซิฟเวอร์ IUX Markets MetaTrader 4 มีเวลาแฝงน้อยกว่า 1 มิลลิวินาที ไปยังผู้ให้บริการ VPS หลัก ไม่ว่าจะอยู่ในศูนย์ข้อมูลผ่านทางสายแบบเฉพาะไปยังศูนย์ข้อมูล \n" +
          "\n",
        description5: `เซิฟเวอร์ของ IUX Markets MetaTrader 5 นี้ถือเป็นระบบนิเวศทางการเงินที่เป็นที่ตั้งของบริษัทที่เป็นผู้ทำการซื้อขาย ตลาด พื้นที่ซื้อขาย ผู้ให้บริการและข้อมูลเกี่ยว กับตลาดกว่า 600 ราย เซิฟเวอร์ MetaTrader 5 ยังได้มีการเชื่อมต่อข้ามมายัง Cloud ของเรา เพื่อให้แน่ใจว่าเกิดเวลาแฝงที่ต่ำและมีการดำเนินคำสั่งซื้อขายที่รวดเร็ว เซิฟเวอร์ IUX Markets MetaTrader 5 มีเวลาแฝงน้อยกว่า 1 มิลลิวินาที ไปยังผู้ให้บริการ VPS หลัก ไม่ว่าจะอยู่ในศูนย์ข้อมูลผ่านทางสายแบบเฉพาะไปยังศูนย์ข้อมูล ที่อยู่ใกล้เคียง สภาพแวดล้อมที่มีเวลาแฝงต่ำนี้เหมาะสำหรับการเทรดทุกรูปแบบ`,
        specification: {
          title: "ความต้องการของระบบ\n",
          description:
            "iPhone iOS 7.0 หรือใหม่กว่า ใช้งานได้กับ iPhone, iPad และ iPod touch\n",
        },
      },
      android: {
        title1: "ทำไมแพลตฟอร์ม IUX Markets\n",
        title2: "MetaTrader 4 จึงมีความแตกต่าง?",
        subTitle: "การดำเนินคำสั่งที่รวดเร็วเป็นอย่างมาก\n",
        description:
          "เซิฟเวอร์ของ IUX Markets MetaTrader 4 นี้ถือเป็นระบบนิเวศทางการเงินที่เป็นที่ตั้งของบริษัทที่เป็นผู้ทำการซื้อขาย ตลาด พื้นที่ซื้อขาย ผู้ให้บริการและข้อมูลเกี่ยว กับตลาดกว่า 600 ราย เซิฟเวอร์ MetaTrader 4 ยังได้มีการเชื่อมต่อข้ามมายัง Cloud ของเรา เพื่อให้แน่ใจว่าเกิดเวลาแฝงที่ต่ำและมีการดำเนินคำสั่งซื้อขายที่รวดเร็ว\n" +
          "\n" +
          "เซิฟเวอร์ IUX Markets MetaTrader 4 มีเวลาแฝงน้อยกว่า 1 มิลลิวินาที ไปยังผู้ให้บริการ VPS หลัก ไม่ว่าจะอยู่ในศูนย์ข้อมูลผ่านทางสายแบบเฉพาะไปยังศูนย์ข้อมูล \n" +
          "\n",
      },
      mac: {
        title1: "ทำไมแพลตฟอร์ม IUX Markets\n",
        title2: "MetaTrader 4 จึงมีความแตกต่าง?",
        subTitle: "การดำเนินคำสั่งที่รวดเร็วเป็นอย่างมาก\n",
        description:
          "เซิฟเวอร์ของ IUX Markets MetaTrader 4 นี้ถือเป็นระบบนิเวศทางการเงินที่เป็นที่ตั้งของบริษัทที่เป็นผู้ทำการซื้อขาย ตลาด พื้นที่ซื้อขาย ผู้ให้บริการและข้อมูลเกี่ยว กับตลาดกว่า 600 ราย เซิฟเวอร์ MetaTrader 4 ยังได้มีการเชื่อมต่อข้ามมายัง Cloud ของเรา เพื่อให้แน่ใจว่าเกิดเวลาแฝงที่ต่ำและมีการดำเนินคำสั่งซื้อขายที่รวดเร็ว\n" +
          "\n" +
          "เซิฟเวอร์ IUX Markets MetaTrader 4 มีเวลาแฝงน้อยกว่า 1 มิลลิวินาที ไปยังผู้ให้บริการ VPS หลัก ไม่ว่าจะอยู่ในศูนย์ข้อมูลผ่านทางสายแบบเฉพาะไปยังศูนย์ข้อมูล \n" +
          "\n",
      },
      window: {
        title1: "ทำไมแพลตฟอร์ม IUX Markets\n",
        title2: "MetaTrader 4 จึงมีความแตกต่าง?",
        subTitle: "การดำเนินคำสั่งที่รวดเร็วเป็นอย่างมาก\n",
        description:
          "เซิฟเวอร์ของ IUX Markets MetaTrader 4 นี้ถือเป็นระบบนิเวศทางการเงินที่เป็นที่ตั้งของบริษัทที่เป็นผู้ทำการซื้อขาย ตลาด พื้นที่ซื้อขาย ผู้ให้บริการและข้อมูลเกี่ยว กับตลาดกว่า 600 ราย เซิฟเวอร์ MetaTrader 4 ยังได้มีการเชื่อมต่อข้ามมายัง Cloud ของเรา เพื่อให้แน่ใจว่าเกิดเวลาแฝงที่ต่ำและมีการดำเนินคำสั่งซื้อขายที่รวดเร็ว\n" +
          "\n" +
          "เซิฟเวอร์ IUX Markets MetaTrader 4 มีเวลาแฝงน้อยกว่า 1 มิลลิวินาที ไปยังผู้ให้บริการ VPS หลัก ไม่ว่าจะอยู่ในศูนย์ข้อมูลผ่านทางสายแบบเฉพาะไปยังศูนย์ข้อมูล \n" +
          "\n",
      },
    },
  },
  la: {
    platform_iphone_header: {
      title: "MetaTrader สำหรับ iPhone/iPad\n",
      sub_title:
        "ໃຊ້ປະໂຫຍດຈາກການເຊື່ອມຕໍ່ IUX Markets ດ້ວຍຕົວເອງຂອງ MetaTrader. ເຊິ່ງໄດ້ຖືກອອກແບບມາ ສຳ ລັບ Iphone / Ipad",
      button_text: "ດາວໂຫລດ Metatrader 4",
    },
    platform_tab: {
      mt4: "Meta Trader 4",
      iphone: "MT4 iPhone/ iPad",
      android: "MT4 Android",
      mac: "MT4 Mac",
    },

    platform_iphone_description:
      "ພວກເຮົາມີໂປແກຼມ MetaTrader 4 ແລະ MetaTrader 5 ໃນ iPhone ແລະ iPad. ຄຳ ຮ້ອງສະ ໝັກ ເຫຼົ່ານີ້ຈະຊ່ວຍໃຫ້ພໍ່ຄ້າສາມາດເຂົ້າເຖິງບັນຊີໄດ້ທຸກທີ່ທີ່ຕ້ອງການ\n" +
      "\n" +
      "ແອັບພລິເຄຊັນມືຖື MetaTrader ໃຊ້ຄຸນລັກສະນະຂັ້ນສູງຫຼາຍຢ່າງຂອງ iPhone ແລະ iPad ເຊັ່ນ: ການປ່ຽນພູມສັນຖານໄປເປັນຮູບຄົນ. (ການ ໝູນ ວຽນແບບມືຖື) ນອກຈາກນັ້ນ, ໂປແກຼມມືຖື MetaTrader ຍັງສະ ໜອງ ການເຮັດວຽກການຄ້າດ້ວຍການກົດພຽງເທື່ອດຽວຄວາມສາມາດໃນການປັບແຕ່ງຮູບແບບຂອງທ່ານລວມທັງກາຟິກທີ່ກ້າວ ໜ້າ ແລະການວິເຄາະການ ນຳ ໃຊ້ໂປແກມ MetaTrader ມືຖືຂອງພວກເຮົາເພື່ອປະສົບກັບຄວາມຍືດຫຍຸ່ນບໍ່ ຈຳ ກັດ. ໂດຍບໍ່ ຈຳ ເປັນຕ້ອງຫຼຸດຜ່ອນຄວາມໄວຫລືຄຸນນະພາບ",
    platform5_iphone_description: `We offer  MetaTrader 5 applications for iPhone and iPad. Traders can access their accounts wherever they want. Our MetaTrader 5 mobile application uses many of the advanced features of iPhone and iPad such as landscape to portrait transitions. In addition, the MetaTrader 5 mobile application includes trading functions. with one click The ability to adjust your style including advanced graphs and analysis Use our MetaTrader 5 mobile application to experience unlimited flexibility. without sacrificing speed or quality`,
    platform_android_description:
      "ພວກເຮົາມີໂປແກຼມ MetaTrader 4 ສຳ ລັບອຸປະກອນ Android. ຄຳ ຮ້ອງສະ ໝັກ ເຫຼົ່ານີ້ຈະຊ່ວຍໃຫ້ພໍ່ຄ້າສາມາດເຂົ້າເຖິງບັນຊີໄດ້ທຸກທີ່ທີ່ຕ້ອງການ.\n" +
      "\n" +
      "IUX Markets MetaTrader Android application ຊ່ວຍໃຫ້ທ່ານສາມາດເຂົ້າເຖິງການກະຈາຍສິນຄ້າແລະຄວາມໄວໃນຕະຫລາດໃນການປະຕິບັດ ຄຳ ສັ່ງທຽບເທົ່າຂອງພວກເຮົາໂດຍກົງໃນໂປແກຼມ Android ຂອງທ່ານ. ແອັບພລິເຄຊັນນີ້ມີ ໜ້າ ທີ່ການຄ້າດ້ວຍການກົດປຸ່ມດຽວຈາກຫລາຍ ໜ້າ ຈໍແລະມີຮູບແບບທີ່ສາມາດປັບແຕ່ງໄດ້ດ້ວຍຄວາມສາມາດໃນການຊອກຫາຂໍ້ມູນປະຫວັດສາດແລະການສ້າງເສັ້ນສະແດງທີ່ສົມບູນແບບດັ່ງນັ້ນທ່ານສາມາດຈັດການບັນຊີຂອງທ່ານ, ຄ້າຂາຍຜະລິດຕະພັນທັງ ໝົດ ຂອງພວກເຮົາ. ແລະ ນຳ ໃຊ້ຫລາຍກວ່າ 30 ຕົວຊີ້ວັດດ້ານວິຊາການເພື່ອວິເຄາະຕະຫລາດ",
    platform5_android_description: ``,
    platform_mac_description:
      "ພວກເຮົາມີໂປແກຼມ MetaTrader 4 ແລະ MetaTrader 5 ໃນ Mac. ຄຳ ຮ້ອງສະ ໝັກ ເຫຼົ່ານີ້ຈະຊ່ວຍໃຫ້ພໍ່ຄ້າສາມາດເຂົ້າເຖິງບັນຊີໄດ້ທຸກທີ່ທີ່ຕ້ອງການ\n" +
      "\n" +
      "ແອັບພລິເຄຊັນມືຖື MetaTrader ຂອງພວກເຮົາໃຊ້ຄຸນລັກສະນະ Mac ຂັ້ນສູງຫຼາຍຢ່າງເຊັ່ນ: ປ່ຽນພູມສັນຖານໄປເປັນຮູບຄົນ. (ການ ໝູນ ວຽນແບບມືຖື) ນອກຈາກນັ້ນ, ໂປແກຼມມືຖື MetaTrader ຍັງສະ ໜອງ ການເຮັດວຽກການຄ້າດ້ວຍການກົດພຽງເທື່ອດຽວຄວາມສາມາດໃນການປັບແຕ່ງຮູບແບບຂອງທ່ານລວມທັງກາຟິກທີ່ກ້າວ ໜ້າ ແລະການວິເຄາະການ ນຳ ໃຊ້ໂປແກມ MetaTrader ມືຖືຂອງພວກເຮົາເພື່ອປະສົບກັບຄວາມຍືດຫຍຸ່ນບໍ່ ຈຳ ກັດ. ໂດຍບໍ່ ຈຳ ເປັນຕ້ອງຫຼຸດຜ່ອນຄວາມໄວຫລືຄຸນນະພາບ\n" +
      "\n",

    platform_iphone_section_2: {
      title: `ສິ່ງທີ່ພວກເຮົາໃຫ້ທ່ານໃນ IUX Markets\n" + "\n" + "ຄວາມຕ້ອງການຂອງລະບົບ`,
      sub_title: "ການປະຕິບັດ ຄຳ ສັ່ງທີ່ໄວທີ່ສຸດ\n",
      description:
        "ເຊີຟເວີຂອງ IUX Markets MetaTrader 4 ແມ່ນຖືວ່າເປັນລະບົບນິເວດທາງການເງິນທີ່ເປັນບໍລິສັດທີ່ຊື້ຂາຍໃນຕະຫຼາດ. ຜູ້ໃຫ້ບໍລິການ 600 ກວ່າຄົນແລະຂໍ້ມູນທີ່ກ່ຽວຂ້ອງກັບຕະຫລາດ, ເຄື່ອງແມ່ຂ່າຍ MetaTrader 4 ຍັງສະ ໜອງ ການເຊື່ອມຕໍ່ຂ້າມກັບ Cloud ຂອງພວກເຮົາເພື່ອຮັບປະກັນຄວາມຊ້າແລະການປະຕິບັດການສັ່ງຊື້ໄດ້ໄວ\n" +
        "\n" +
        "ສິ່ງທີ່ພວກເຮົາໃຫ້ທ່ານໃນ IUX Markets\n" +
        "\n",
      specification_header: "ຄວາມຕ້ອງການຂອງລະບົບ\n",
      specification_sub:
        "iPhone iOS 7.0 ຫຼືຫຼັງຈາກນັ້ນສາມາດໃຊ້ໄດ້ກັບ iPhone, iPad ແລະ iPod touch\n" +
        "\n",
      header_2: "ສິ່ງທີ່ພວກເຮົາໃຫ້ທ່ານໃນ IUX Markets\n",
      label_1: "Leverage ສູງເຖິງ 1 1000 \n",
      label_2: "ໂບນັດ 30 $ ໃນການຊື້ຂາຍ\n",
    },
    why_platform: {
      iphone: {
        title1: " ເປັນຫຍັງຕ້ອງ ແພລຕຕະຟອມ IUX Markets\n",
        title2: "MetaTrader 4 ດັ່ງນັ້ນມັນມີຄວາມແຕກຕ່າງ",
        title_2_5: "MetaTrader 5 ດັ່ງນັ້ນມັນມີຄວາມແຕກຕ່າງ",
        subTitle: "ການປະຕິບັດ ຄຳ ສັ່ງທີ່ໄວທີ່ສຸດ\n",
        description:
          "ເຊີຟເວີຂອງ IUX Markets MetaTrader 4 ແມ່ນຖືວ່າເປັນລະບົບນິເວດທາງການເງິນທີ່ເປັນບໍລິສັດທີ່ຊື້ຂາຍໃນຕະຫຼາດ. ຜູ້ໃຫ້ບໍລິການ 600 ກວ່າຄົນແລະຂໍ້ມູນທີ່ກ່ຽວຂ້ອງກັບຕະຫລາດ, ເຄື່ອງແມ່ຂ່າຍ MetaTrader 4 ຍັງສະ ໜອງ ການເຊື່ອມຕໍ່ຂ້າມກັບ Cloud ຂອງພວກເຮົາເພື່ອຮັບປະກັນຄວາມຊ້າແລະການປະຕິບັດການສັ່ງຊື້ໄດ້ໄວ\n" +
          "\n" +
          "ເຊີຟເວີ IUX Markets MetaTrader 4 4 ມີເວລາຫນ້ອຍກວ່າ 1 ມິນລິລິດຕໍ່ຜູ້ໃຫ້ບໍລິການ VPS ທີ່ ສຳ ຄັນ, ບໍ່ວ່າຈະຢູ່ໃນສູນຂໍ້ມູນ, ຜ່ານສາຍທີ່ອຸທິດໃຫ້ສູນຂໍ້ມູນ. ໃກ້ຄຽງ ສະພາບແວດລ້ອມທີ່ຕ່ ຳ ຊ້ານີ້ ເໝາະ ສຳ ລັບການຊື້ຂາຍແລະແບບມີຄວາມຖີ່ສູງ,ແບບອັດຕະໂນມັດ ແລະການເຮັດ scalping\n" +
          "\n",
        description5: `เซิฟเวอร์ของ IUX Markets MetaTrader 5 นี้ถือเป็นระบบนิเวศทางการเงินที่เป็นที่ตั้งของบริษัทที่เป็นผู้ทำการซื้อขาย ตลาด พื้นที่ซื้อขาย ผู้ให้บริการและข้อมูลเกี่ยว กับตลาดกว่า 600 ราย เซิฟเวอร์ MetaTrader 5 ยังได้มีการเชื่อมต่อข้ามมายัง Cloud ของเรา เพื่อให้แน่ใจว่าเกิดเวลาแฝงที่ต่ำและมีการดำเนินคำสั่งซื้อขายที่รวดเร็ว เซิฟเวอร์ IUX Markets MetaTrader 5 มีเวลาแฝงน้อยกว่า 1 มิลลิวินาที ไปยังผู้ให้บริการ VPS หลัก ไม่ว่าจะอยู่ในศูนย์ข้อมูลผ่านทางสายแบบเฉพาะไปยังศูนย์ข้อมูล ที่อยู่ใกล้เคียง สภาพแวดล้อมที่มีเวลาแฝงต่ำนี้เหมาะสำหรับการเทรดทุกรูปแบบ`,
        specification: {
          title: "ຄວາມຕ້ອງການຂອງລະບົບ\n",
          description:
            "iPhone iOS 7.0 ຫຼືຫຼັງຈາກນັ້ນສາມາດໃຊ້ໄດ້ກັບ iPhone, iPad ແລະ iPod touch\n",
        },
      },
      android: {
        title1: " ເປັນຫຍັງຕ້ອງ ແພລຕຕະຟອມ IUX Markets\n",
        title2: "MetaTrader 4 ດັ່ງນັ້ນມັນມີຄວາມແຕກຕ່າງ",
        subTitle: "ການປະຕິບັດ ຄຳ ສັ່ງທີ່ໄວທີ່ສຸດ\n",
        description:
          "ເຊີຟເວີຂອງ IUX Markets MetaTrader 4 ແມ່ນຖືວ່າເປັນລະບົບນິເວດທາງການເງິນທີ່ເປັນບໍລິສັດທີ່ຊື້ຂາຍໃນຕະຫຼາດ. ຜູ້ໃຫ້ບໍລິການ 600 ກວ່າຄົນແລະຂໍ້ມູນທີ່ກ່ຽວຂ້ອງກັບຕະຫລາດ, ເຄື່ອງແມ່ຂ່າຍ MetaTrader 4 ຍັງສະ ໜອງ ການເຊື່ອມຕໍ່ຂ້າມກັບ Cloud ຂອງພວກເຮົາເພື່ອຮັບປະກັນຄວາມຊ້າແລະການປະຕິບັດການສັ່ງຊື້ໄດ້ໄວ\n" +
          "\n" +
          "ເຊີຟເວີຂອງ IUX Markets MetaTrader 4 ມີໄລຍະເວລາຕ່ ຳ ກວ່າ 1milliseconds ຕໍ່ຜູ້ໃຫ້ບໍລິການ VPS ຫຼັກ, ບໍ່ວ່າຈະຢູ່ໃນສູນຂໍ້ມູນຜ່ານສາຍສົ່ງຕໍ່ສູນຂໍ້ມູນ. ບໍລິເວນໃກ້ຄຽງສະພາບແວດລ້ອມທີ່ຕ່ ຳ ຊ້ານີ້ແມ່ນດີເລີດ ສຳ ລັບການຊື້ຂາຍທີ່ມີຄວາມຖີ່ສູງແລະອັດຕະໂນມັດແລະການຕັດຫົວ.\n" +
          "\n",
      },
      mac: {
        title1: " ເປັນຫຍັງຕ້ອງ ແພລຕຕະຟອມ IUX Markets\n",
        title2: "MetaTrader 4 ດັ່ງນັ້ນມັນມີຄວາມແຕກຕ່າງ",
        subTitle: "ການປະຕິບັດ ຄຳ ສັ່ງທີ່ໄວທີ່ສຸດ\n",
        description:
          "ເຊີຟເວີຂອງ IUX Markets MetaTrader 4 ແມ່ນຖືວ່າເປັນລະບົບນິເວດທາງການເງິນທີ່ເປັນບໍລິສັດທີ່ຊື້ຂາຍໃນຕະຫຼາດ. ຜູ້ໃຫ້ບໍລິການ 600 ກວ່າຄົນແລະຂໍ້ມູນທີ່ກ່ຽວຂ້ອງກັບຕະຫລາດ, ເຄື່ອງແມ່ຂ່າຍ MetaTrader 4 ຍັງສະ ໜອງ ການເຊື່ອມຕໍ່ຂ້າມກັບ Cloud ຂອງພວກເຮົາເພື່ອຮັບປະກັນຄວາມຊ້າແລະການປະຕິບັດການສັ່ງຊື້ໄດ້ໄວ\n" +
          "\n" +
          "ເຊີຟເວີຂອງ IUX Markets MetaTrader 4 ມີໄລຍະເວລາຕ່ ຳ ກວ່າ 1milliseconds ຕໍ່ຜູ້ໃຫ້ບໍລິການ VPS ຫຼັກ, ບໍ່ວ່າຈະຢູ່ໃນສູນຂໍ້ມູນຜ່ານສາຍສົ່ງຕໍ່ສູນຂໍ້ມູນ. ບໍລິເວນໃກ້ຄຽງສະພາບແວດລ້ອມທີ່ຕ່ ຳ ຊ້ານີ້ແມ່ນດີເລີດ ສຳ ລັບການຊື້ຂາຍທີ່ມີຄວາມຖີ່ສູງແລະອັດຕະໂນມັດແລະການຕັດຫົວ.\n" +
          "\n",
      },
      window: {
        title1: " ເປັນຫຍັງຕ້ອງ ແພລຕຕະຟອມ IUX Markets\n",
        title2: "MetaTrader 4 ດັ່ງນັ້ນມັນມີຄວາມແຕກຕ່າງ",
        subTitle: "ການປະຕິບັດ ຄຳ ສັ່ງທີ່ໄວທີ່ສຸດ\n",
        description:
          "ເຊີຟເວີຂອງ IUX Markets MetaTrader 4 ແມ່ນຖືວ່າເປັນລະບົບນິເວດທາງການເງິນທີ່ເປັນບໍລິສັດທີ່ຊື້ຂາຍໃນຕະຫຼາດ. ຜູ້ໃຫ້ບໍລິການ 600 ກວ່າຄົນແລະຂໍ້ມູນທີ່ກ່ຽວຂ້ອງກັບຕະຫລາດ, ເຄື່ອງແມ່ຂ່າຍ MetaTrader 4 ຍັງສະ ໜອງ ການເຊື່ອມຕໍ່ຂ້າມກັບ Cloud ຂອງພວກເຮົາເພື່ອຮັບປະກັນຄວາມຊ້າແລະການປະຕິບັດການສັ່ງຊື້ໄດ້ໄວ\n" +
          "\n" +
          "ເຊີຟເວີຂອງ IUX Markets MetaTrader 4 ມີໄລຍະເວລາຕ່ ຳ ກວ່າ 1milliseconds ຕໍ່ຜູ້ໃຫ້ບໍລິການ VPS ຫຼັກ, ບໍ່ວ່າຈະຢູ່ໃນສູນຂໍ້ມູນຜ່ານສາຍສົ່ງຕໍ່ສູນຂໍ້ມູນ. ບໍລິເວນໃກ້ຄຽງສະພາບແວດລ້ອມທີ່ຕ່ ຳ ຊ້ານີ້ແມ່ນດີເລີດ ສຳ ລັບການຊື້ຂາຍທີ່ມີຄວາມຖີ່ສູງແລະອັດຕະໂນມັດແລະການຕັດຫົວ.\n" +
          "\n",
      },
    },
  },
  en: {
    platform_iphone_header: {
      title: "MetaTrader iPhone/iPad\n",
      sub_title:
        "Take advantage of IUX Markets connectivity on our own version of MetaTrader. Which has been designed for Iphone / Ipad\n",
      button_text: "Download Metatrader 4",
    },
    platform_tab: {
      mt4: "Meta Trader 4",
      iphone: "MT4 iPhone/ iPad",
      android: "MT4 Android",
      mac: "MT4 Mac",
    },
    platform_iphone_description:
      "We have the  MetaTrader 5 applications on the iPhone and iPad. These applications will provide Traders can access the account wherever they want. Our MetaTrader mobile application uses many advanced features of the iPhone and iPad such as changing landscape to portrait. (Handset rotation) In addition, the MetaTrader mobile application also provides trading functions With just one click The ability to adjust your style Including advanced graphs and analysis Use our MetaTrader mobile application to experience unlimited flexibility. Without having to reduce the speed or quality",
    platform5_iphone_description: `We offer  MetaTrader 5 applications for iPhone and iPad. Traders can access their accounts wherever they want. Our MetaTrader 5 mobile application uses many of the advanced features of iPhone and iPad such as landscape to portrait transitions. In addition, the MetaTrader 5 mobile application includes trading functions. with one click The ability to adjust your style including advanced graphs and analysis Use our MetaTrader 5 mobile application to experience unlimited flexibility. without sacrificing speed or quality`,
    platform_android_description:
      "We have the MetaTrader 4 application for Android devices. These applications will provide Traders can access the account wherever they want.\n" +
      "\n" +
      "IUX Markets MetaTrader Android application gives you access to market-leading spread and speed in The execution of our comparable commands directly on your Android application. This application has the function Trading with a single click from multiple screens and with customizable formats With ability To browse historical data and complete advanced graphing So you can manage your account, trade all of our products. And use over 30 technical indicators to analyze the market",
    platform5_android_description: `We have MetaTrader 5 applications for Android devices. Traders can access their accounts wherever they want IUX Markets MetaTrader Android. The app gives you access to market leading spread and fast Execute our incomparable commands directly on your Android application. One-click trading from multiple screens and customizable layouts. with talent To browse historical data and complete advanced graphing. So you can manage your account, trade all of our products. and use over 30 technical indicators to analyze the market.`,
    platform_mac_description:
      "We have the  MetaTrader 5 applications on the Mac. These applications will provide Traders can access the account wherever they want.\n" +
      "\n" +
      "Our MetaTrader mobile application uses many advanced Mac features such as changing landscape to portrait. (Handset rotation) In addition, the MetaTrader mobile application also provides trading functions With just one click The ability to adjust your style Including advanced graphs and analysis Use our MetaTrader mobile application to experience unlimited flexibility. Without having to reduce the speed or quality\n",

    platform_iphone_section_2: {
      title: "What we provide on IUX Markets\n",
      sub_title: "",
      description: "",
      specification_header: "System requirements\n",
      specification_sub:
        "iPhone iOS 7.0 or later is compatible with iPhone, iPad and iPod touch." +
        "\n",
      header_2: "What we provide on IUX Markets",
      label_1: "Leverage up to 1: 1000",
      label_2: "30 $ Free Trade Bonus",
    },
    why_platform: {
      iphone: {
        title1: "Why IUX Markets MetaTrader5 ?",
        title2: "MetaTrader 4 So there is a difference\n?",
        title_2_5: "MetaTrader 5 So there is a difference\n?",
        subTitle: "Extremely fast execution\n\n",
        description:
          "The IUX Markets MetaTrader 4 server is considered a financial ecosystem that houses companies trading in the market. Over 600 service providers and market-related data, the MetaTrader 4 server also provides cross-connection to our Cloud to ensure low latency and fast order execution." +
          "The IUX Markets MetaTrader 4 server has a latency of less than 1 milliseconds to the main VPS service provider, whether in the data center via a dedicated line to the data center. Nearby This low-latency environment is perfect for high-frequency and automated trading and scalping.",
        description5: `With over 600 providers and real time market data, MetaTrader 5 server is also cross-connected to our Cloud to ensure low latency and fast execution. Through our several server location across the world, MetaTrader 5 offers all major VPS providers less than 1 millisecond latency regardless of where they are located. `,
      },
      android: {
        title1: "Why IUX Markets MetaTrader5 ?",
        title2: "MetaTrader 4 So there is a difference\n?",
        subTitle: "Extremely fast execution\n\n",
        description:
          "The IUX Markets MetaTrader 4 server is considered a financial ecosystem that houses companies trading in the market. Over 600 service providers and market-related data, the MetaTrader 4 server also provides cross-connection to our Cloud to ensure low latency and fast order execution. \\ N '+\n" +
          "                    '\\ n' +\n" +
          "                    'The IUX Markets MetaTrader 4 server has a latency of less than 1 milliseconds to the main VPS service provider, whether in the data center via a dedicated line to the data center. Nearby This low-latency environment is perfect for high-frequency and automated trading and scalping. \\ N '+\n",
      },
      mac: {
        title1: "ทำไมแพลตฟอร์ม IUX Markets\n",
        title2: "MetaTrader 4 จึงมีความแตกต่าง?",
        subTitle: "การดำเนินคำสั่งที่รวดเร็วเป็นอย่างมาก\n",
        description:
          "เซิฟเวอร์ของ IUX Markets MetaTrader 4 นี้ถือเป็นระบบนิเวศทางการเงินที่เป็นที่ตั้งของบริษัทที่เป็นผู้ทำการซื้อขาย ตลาด พื้นที่ซื้อขาย ผู้ให้บริการและข้อมูลเกี่ยว กับตลาดกว่า 600 ราย เซิฟเวอร์ MetaTrader 4 ยังได้มีการเชื่อมต่อข้ามมายัง Cloud ของเรา เพื่อให้แน่ใจว่าเกิดเวลาแฝงที่ต่ำและมีการดำเนินคำสั่งซื้อขายที่รวดเร็ว\n" +
          "\n" +
          "เซิฟเวอร์ IUX Markets MetaTrader 4 มีเวลาแฝงน้อยกว่า 1 มิลลิวินาที ไปยังผู้ให้บริการ VPS หลัก ไม่ว่าจะอยู่ในศูนย์ข้อมูลผ่านทางสายแบบเฉพาะไปยังศูนย์ข้อมูล \n" +
          "\n",
      },
      window: {
        title1: "ทำไมแพลตฟอร์ม IUX Markets\n",
        title2: "MetaTrader 4 จึงมีความแตกต่าง?",
        subTitle: "การดำเนินคำสั่งที่รวดเร็วเป็นอย่างมาก\n",
        description:
          "เซิฟเวอร์ของ IUX Markets MetaTrader 4 นี้ถือเป็นระบบนิเวศทางการเงินที่เป็นที่ตั้งของบริษัทที่เป็นผู้ทำการซื้อขาย ตลาด พื้นที่ซื้อขาย ผู้ให้บริการและข้อมูลเกี่ยว กับตลาดกว่า 600 ราย เซิฟเวอร์ MetaTrader 4 ยังได้มีการเชื่อมต่อข้ามมายัง Cloud ของเรา เพื่อให้แน่ใจว่าเกิดเวลาแฝงที่ต่ำและมีการดำเนินคำสั่งซื้อขายที่รวดเร็ว\n" +
          "\n" +
          "เซิฟเวอร์ IUX Markets MetaTrader 4 มีเวลาแฝงน้อยกว่า 1 มิลลิวินาที ไปยังผู้ให้บริการ VPS หลัก ไม่ว่าจะอยู่ในศูนย์ข้อมูลผ่านทางสายแบบเฉพาะไปยังศูนย์ข้อมูล \n" +
          "\n",
      },
    },
  },
  in: {
    platform_iphone_header: {
      title: "MetaTrader iPhone/iPad\n",
      sub_title:
        "Take advantage of IUX Markets connectivity on our own version of MetaTrader. Which has been designed for Iphone / Ipad\n",
      button_text: "Download Metatrader 4",
    },
    platform_tab: {
      mt4: "Meta Trader 4",
      iphone: "MT4 iPhone/ iPad",
      android: "MT4 Android",
      mac: "MT4 Mac",
    },
    platform_iphone_description:
      "We have the  MetaTrader 5 applications on the iPhone and iPad. These applications will provide Traders can access the account wherever they want. Our MetaTrader mobile application uses many advanced features of the iPhone and iPad such as changing landscape to portrait. (Handset rotation) In addition, the MetaTrader mobile application also provides trading functions With just one click The ability to adjust your style Including advanced graphs and analysis Use our MetaTrader mobile application to experience unlimited flexibility. Without having to reduce the speed or quality",
    platform5_iphone_description: `We offer  MetaTrader 5 applications for iPhone and iPad. Traders can access their accounts wherever they want. Our MetaTrader 5 mobile application uses many of the advanced features of iPhone and iPad such as landscape to portrait transitions. In addition, the MetaTrader 5 mobile application includes trading functions. with one click The ability to adjust your style including advanced graphs and analysis Use our MetaTrader 5 mobile application to experience unlimited flexibility. without sacrificing speed or quality`,
    platform_android_description:
      "We have the MetaTrader 4 application for Android devices. These applications will provide Traders can access the account wherever they want.\n" +
      "\n" +
      "IUX Markets MetaTrader Android application gives you access to market-leading spread and speed in The execution of our comparable commands directly on your Android application. This application has the function Trading with a single click from multiple screens and with customizable formats With ability To browse historical data and complete advanced graphing So you can manage your account, trade all of our products. And use over 30 technical indicators to analyze the market",
    platform5_android_description: `We have MetaTrader 5 applications for Android devices. Traders can access their accounts wherever they want IUX Markets MetaTrader Android. The app gives you access to market leading spread and fast Execute our incomparable commands directly on your Android application. One-click trading from multiple screens and customizable layouts. with talent To browse historical data and complete advanced graphing. So you can manage your account, trade all of our products. and use over 30 technical indicators to analyze the market.`,
    platform_mac_description:
      "We have the  MetaTrader 5 applications on the Mac. These applications will provide Traders can access the account wherever they want.\n" +
      "\n" +
      "Our MetaTrader mobile application uses many advanced Mac features such as changing landscape to portrait. (Handset rotation) In addition, the MetaTrader mobile application also provides trading functions With just one click The ability to adjust your style Including advanced graphs and analysis Use our MetaTrader mobile application to experience unlimited flexibility. Without having to reduce the speed or quality\n",

    platform_iphone_section_2: {
      title: "What we provide on IUX Markets\n",
      sub_title: "",
      description: "",
      specification_header: "System requirements\n",
      specification_sub:
        "iPhone iOS 7.0 or later is compatible with iPhone, iPad and iPod touch." +
        "\n",
      header_2: "What we provide on IUX Markets",
      label_1: "Leverage up to 1: 1000",
      label_2: "30 $ Free Trade Bonus",
    },
    why_platform: {
      iphone: {
        title1: "Why IUX Markets MetaTrader5 ?",
        title2: "MetaTrader 4 So there is a difference\n?",
        title_2_5: "MetaTrader 5 So there is a difference\n?",
        subTitle: "Extremely fast execution\n\n",
        description:
          "The IUX Markets MetaTrader 4 server is considered a financial ecosystem that houses companies trading in the market. Over 600 service providers and market-related data, the MetaTrader 4 server also provides cross-connection to our Cloud to ensure low latency and fast order execution." +
          "The IUX Markets MetaTrader 4 server has a latency of less than 1 milliseconds to the main VPS service provider, whether in the data center via a dedicated line to the data center. Nearby This low-latency environment is perfect for high-frequency and automated trading and scalping.",
        description5: `With over 600 providers and real time market data, MetaTrader 5 server is also cross-connected to our Cloud to ensure low latency and fast execution. Through our several server location across the world, MetaTrader 5 offers all major VPS providers less than 1 millisecond latency regardless of where they are located. `,
      },
      android: {
        title1: "Why IUX Markets MetaTrader5 ?",
        title2: "MetaTrader 4 So there is a difference\n?",
        subTitle: "Extremely fast execution\n\n",
        description:
          "The IUX Markets MetaTrader 4 server is considered a financial ecosystem that houses companies trading in the market. Over 600 service providers and market-related data, the MetaTrader 4 server also provides cross-connection to our Cloud to ensure low latency and fast order execution. \\ N '+\n" +
          "                    '\\ n' +\n" +
          "                    'The IUX Markets MetaTrader 4 server has a latency of less than 1 milliseconds to the main VPS service provider, whether in the data center via a dedicated line to the data center. Nearby This low-latency environment is perfect for high-frequency and automated trading and scalping. \\ N '+\n",
      },
      mac: {
        title1: "ทำไมแพลตฟอร์ม IUX Markets\n",
        title2: "MetaTrader 4 จึงมีความแตกต่าง?",
        subTitle: "การดำเนินคำสั่งที่รวดเร็วเป็นอย่างมาก\n",
        description:
          "เซิฟเวอร์ของ IUX Markets MetaTrader 4 นี้ถือเป็นระบบนิเวศทางการเงินที่เป็นที่ตั้งของบริษัทที่เป็นผู้ทำการซื้อขาย ตลาด พื้นที่ซื้อขาย ผู้ให้บริการและข้อมูลเกี่ยว กับตลาดกว่า 600 ราย เซิฟเวอร์ MetaTrader 4 ยังได้มีการเชื่อมต่อข้ามมายัง Cloud ของเรา เพื่อให้แน่ใจว่าเกิดเวลาแฝงที่ต่ำและมีการดำเนินคำสั่งซื้อขายที่รวดเร็ว\n" +
          "\n" +
          "เซิฟเวอร์ IUX Markets MetaTrader 4 มีเวลาแฝงน้อยกว่า 1 มิลลิวินาที ไปยังผู้ให้บริการ VPS หลัก ไม่ว่าจะอยู่ในศูนย์ข้อมูลผ่านทางสายแบบเฉพาะไปยังศูนย์ข้อมูล \n" +
          "\n",
      },
      window: {
        title1: "ทำไมแพลตฟอร์ม IUX Markets\n",
        title2: "MetaTrader 4 จึงมีความแตกต่าง?",
        subTitle: "การดำเนินคำสั่งที่รวดเร็วเป็นอย่างมาก\n",
        description:
          "เซิฟเวอร์ของ IUX Markets MetaTrader 4 นี้ถือเป็นระบบนิเวศทางการเงินที่เป็นที่ตั้งของบริษัทที่เป็นผู้ทำการซื้อขาย ตลาด พื้นที่ซื้อขาย ผู้ให้บริการและข้อมูลเกี่ยว กับตลาดกว่า 600 ราย เซิฟเวอร์ MetaTrader 4 ยังได้มีการเชื่อมต่อข้ามมายัง Cloud ของเรา เพื่อให้แน่ใจว่าเกิดเวลาแฝงที่ต่ำและมีการดำเนินคำสั่งซื้อขายที่รวดเร็ว\n" +
          "\n" +
          "เซิฟเวอร์ IUX Markets MetaTrader 4 มีเวลาแฝงน้อยกว่า 1 มิลลิวินาที ไปยังผู้ให้บริการ VPS หลัก ไม่ว่าจะอยู่ในศูนย์ข้อมูลผ่านทางสายแบบเฉพาะไปยังศูนย์ข้อมูล \n" +
          "\n",
      },
    },
  },
  my: {
    platform_iphone_header: {
      title: `MetaTrader untuk iPhone / iPad`,
      sub_title: `Manfaatkan ketersambungan IUX Markets pada versi MetaTrader kami sendiri, yang telah direka untuk Iphone / Ipad`,
      button_text: `Muat turun Metatrader 4`,
    },
    platform_tab: {
      mt4: `Meta 4 Trader`,
      iphone: `MT4 iPhone / iPad`,
      android: `MT4 Android`,
      mac: `MT4 Mac`,
    },
    platform_iphone_description:
      `Kami menawarkan aplikasi MetaTrader 4 dan MetaTrader 5 untuk iPhone dan iPad. Peniaga boleh mengakses akaun mereka di mana sahaja mereka mahu` +
      ` Aplikasi mudah alih MetaTrader kami menggunakan banyak feature iPhone dan iPad terkini seperti peralihan landskap kepada potret. Selain itu, aplikasi mudah alih MetaTrader mempunyai fungsi dagangan dengan satu klik. Keupayaan untuk menyesuaikan gaya anda termasuk graf dan analisis terbaik. Gunakan aplikasi mudah alih MetaTrader kami untuk mengalami fleksibiliti tanpa had, tanpa mengorbankan kelajuan atau kualiti`,
    platform5_iphone_description: `Kami menawarkan aplikasi MetaTrader 4 dan MetaTrader 5 untuk iPhone dan iPad. Peniaga boleh mengakses akaun mereka di mana sahaja mereka mahu. Aplikasi mudah alih MetaTrader kami menggunakan banyak feature iPhone dan iPad terkini seperti peralihan landskap kepada potret. Selain itu, aplikasi mudah alih MetaTrader mempunyai fungsi dagangan dengan satu klik. Keupayaan untuk menyesuaikan gaya anda termasuk graf dan analisis terbaik. Gunakan aplikasi mudah alih MetaTrader kami untuk mengalami fleksibiliti tanpa had, tanpa mengorbankan kelajuan atau kualiti`,
    platform_android_description:
      `Kami mempunyai aplikasi MetaTrader 4 untuk peranti Android. Pedagang boleh mengakses akaun mereka di mana sahaja mereka mahu. Aplikasi IUX Markets MetaTrader Android memberi anda akses kepada spread terkemuka pasaran dan kelajuan pantas di pasaran.\n` +
      "\n" +
      `Pelaksanaan pesanan kami yang tiada tandingan secara langsung pada aplikasi Android anda. Dagangan satu klik daripada berbilang skrin dan susun atur yang boleh disesuaikan. Dengan bakat untuk menyemak imbas data sejarah dan kelengkapan grafik berstandard tinggi. Jadi anda boleh menguruskan akaun anda, berdagang semua produk kami dan menggunakan lebih 30 penunjuk teknikal untuk menganalisis pasaran`,
    platform5_android_description: `Kami mempunyai aplikasi MetaTrader 4 untuk peranti Android. Pedagang boleh mengakses akaun mereka di mana sahaja mereka mahu. Aplikasi IUX Markets MetaTrader Android memberi anda akses kepada spread terkemuka pasaran dan kelajuan pantas di pasaran. Pelaksanakan arahan kami yang tiada tandingan secara langsung pada aplikasi Android anda. Dagangan satu klik daripada berbilang skrin dan susun atur yang boleh disesuaikan. Dengan bakat untuk menyemak imbas data sejarah dan kelengkapan grafik berstandard tinggi. Jadi anda boleh menguruskan akaun anda, berdagang semua produk kami dan menggunakan lebih 30 penunjuk teknikal untuk menganalisis pasaran.`,
    platform_mac_description:
      `Kami menawarkan aplikasi MetaTrader 4 dan MetaTrader 5 pada Mac. Peniaga boleh mengakses akaun mereka di mana sahaja mereka mahu.\n` +
      "\n" +
      `Aplikasi mudah alih MetaTrader kami menggunakan banyak feature Mac terkini seperti peralihan landskap kepada potret. Selain itu, aplikasi mudah alih MetaTrader mempunyai fungsi dagangan dengan satu klik. Keupayaan untuk menyesuaikan gaya anda termasuk graf dan analisis berstandard tinggi.  Gunakan aplikasi mudah alih MetaTrader kami untuk mengalami fleksibiliti tanpa had, tanpa mengorbankan kelajuan atau kualiti\n`,

    platform_iphone_section_2: {
      title: `Kami menawarkan aplikasi MetaTrader 4 dan MetaTrader 5 untuk iPhone dan iPad. Peniaga boleh mengakses akaun mereka di mana sahaja mereka mahu. Aplikasi mudah alih MetaTrader kami menggunakan banyak feature iPhone dan iPad terkini seperti peralihan landskap kepada potret. Selain itu, aplikasi mudah alih MetaTrader mempunyai fungsi dagangan dengan satu klik. Keupayaan untuk menyesuaikan gaya anda termasuk graf dan analisis terbaik. Gunakan aplikasi mudah alih MetaTrader kami untuk mengalami fleksibiliti tanpa had, tanpa mengorbankan kelajuan atau kualiti\n`,
      sub_title: "Pelaksanaan pesanan yang sangat pantas",
      description:
        "Pelayan IUX Markets MetaTrader 4 ini ialah ekosistem kewangan yang menempatkan syarikat dagangan, pasaran, platform dagangan. Dengan lebih 600 pembekal dan data pasaran. Pelayan MetaTrader 4 juga disambungkan silang kepada Cloud kami untuk memastikan kependaman rendah dan pelaksanaan pantas. Pelayan IUX Markets MetaTrader 4 menawarkan kependaman kurang daripada 1 milisaat kepada penyedia VPS utama sama ada di pusat data melalui talian khusus ke pusat data",
      specification_header: `Keperluan Sistem\n`,
      specification_sub:
        `iPhone iOS 7.0 atau lebih baru, serasi dengan iPhone, iPad dan iPod touch\n` +
        "\n",
      header_2: `Apa yang kami sediakan di IUX Markets`,
      label_1: `Leverage sehingga 1 1000`,
      label_2: `Percuma bonus perdagangan 30 $  `,
    },
    why_platform: {
      iphone: {
        title1: `Mengapa platform IUX Markets`,
        title2: `MetaTrader 4 terdapat perbezaan`,
        title_2_5: `MetaTrader 5 terdapat perbezaan`,
        subTitle: `Pelaksanaan pesanan yang sangat pantas`,
        description:
          `Pelayan IUX Markets MetaTrader 4 ini ialah ekosistem kewangan yang menempatkan syarikat dagangan, pasaran, platform dagangan. Dengan lebih 600 pembekal dan data pasaran. Pelayan MetaTrader 4 juga disambungkan silang kepada Cloud kami untuk memastikan kependaman rendah dan pelaksanaan pantas\n` +
          `Pelayan IUX Markets MetaTrader 4 menawarkan kependaman kurang daripada 1 milisaat kepada penyedia VPS utama sama ada di pusat data melalui talian khusus ke pusat data`,
        description5: `Pelayan IUX Markets MetaTrader 4 ini ialah ekosistem kewangan yang menempatkan syarikat dagangan, pasaran, platform dagangan. Dengan lebih 600 pembekal dan data pasaran. Pelayan MetaTrader 4 juga disambungkan silang kepada Cloud kami untuk memastikan kependaman rendah dan pelaksanaan pantas.Pelayan IUX Markets MetaTrader 4 menawarkan kependaman kurang daripada 1 milisaat kepada penyedia VPS utama sama ada di pusat data melalui talian khusus ke pusat data`,
      },
      android: {
        title1: `Mengapa platform IUX Markets`,
        title2: `MetaTrader 4 terdapat perbezaan`,
        subTitle: `pelaksanaan pesanan yang sangat pantas`,
        description:
          `Pelayan IUX Markets MetaTrader 4 ini ialah ekosistem kewangan yang menempatkan syarikat dagangan, pasaran, platform dagangan. Dengan lebih 600 pembekal dan data pasaran. Pelayan MetaTrader 4 juga disambungkan silang kepada Cloud kami untuk memastikan kependaman rendah dan pelaksanaan pantas.\n` +
          `Pelayan IUX Markets MetaTrader 4 menawarkan kependaman kurang daripada 1 milisaat kepada penyedia VPS utama sama ada di pusat data melalui talian khusus ke pusat data.\n`,
      },
      mac: {
        title1: `Mengapa platform IUX Markets`,
        title2: `MetaTrader 4 terdapat perbezaan`,
        subTitle: `pelaksanaan perintah yang sangat pantas`,
        description: `Pelayan IUX Markets MetaTrader 4 ini ialah ekosistem kewangan yang menempatkan syarikat dagangan, pasaran, platform dagangan. Dengan lebih 600 pembekal dan data pasaran. Pelayan MetaTrader 4 juga disambungkan silang kepada Cloud kami untuk memastikan kependaman rendah dan pelaksanaan pantas\n Pelayan IUX Markets MetaTrader 4 menawarkan kependaman kurang daripada 1 milisaat kepada penyedia VPS utama sama ada di pusat data melalui talian khusus ke pusat data\n`,
      },
      window: {
        title1: `Mengapa platform IUX Markets`,
        title2: `MetaTrader 4 terdapat perbezaan`,
        subTitle: `pelaksanaan perintah yang sangat pantas`,
        description: `Pelayan IUX Markets MetaTrader 4 ini ialah ekosistem kewangan yang menempatkan syarikat dagangan, pasaran, platform dagangan. Dengan lebih 600 pembekal dan data pasaran. Pelayan MetaTrader 4 juga disambungkan silang kepada Cloud kami untuk memastikan kependaman rendah dan pelaksanaan pantas\n Pelayan IUX Markets MetaTrader 4 menawarkan kependaman kurang daripada 1 milisaat kepada penyedia VPS utama sama ada di pusat data melalui talian khusus ke pusat data\n`,
      },
    },
  },
  id: {
    platform_iphone_header: {
      title: `MetaTrader iPhone / iPad`,
      sub_title: `Manfaatkan konektivitas IUX Markets pada versi kita sendiri MetaTrader. Yang telah dirancang untuk Iphone / Ipad`,
      button_text: `Download Metatrader 4`,
    },
    platform_tab: {
      mt4: `Meta Trader 4`,
      iphone: `MT4 iPhone / iPad`,
      android: `MT4 Android`,
      mac: `MT4 Mac`,
    },
    platform_iphone_description:
      `Kami memiliki MetaTrader 4 dan MetaTrader 5 aplikasi pada iPhone dan iPad. Aplikasi ini akan memberikan Pedagang dapat mengakses akun mana pun yang mereka inginkan` +
      ` aplikasi mobile MetaTrader menggunakan banyak fitur-fitur canggih dari iPhone dan iPad seperti mengubah landscape ke portrait. (Handset rotasi) Selain itu, MetaTrader aplikasi mobile juga menyediakan perdagangan fungsi Dengan hanya satu klik Kemampuan untuk menyesuaikan gaya Anda Termasuk grafik canggih dan analisis Gunakan kami MetaTrader aplikasi mobile untuk mengalami fleksibilitas tak terbatas. Tanpa harus mengurangi kecepatan atau kualitas`,
    platform5_iphone_description: `We offer  MetaTrader 5 applications for iPhone and iPad. Traders can access their accounts wherever they want. Our MetaTrader 5 mobile application uses many of the advanced features of iPhone and iPad such as landscape to portrait transitions. In addition, the MetaTrader 5 mobile application includes trading functions. with one click The ability to adjust your style including advanced graphs and analysis Use our MetaTrader 5 mobile application to experience unlimited flexibility. without sacrificing speed or quality`,
    platform_android_description:
      `Kami memiliki aplikasi MetaTrader 4 untuk perangkat Android. Aplikasi ini akan memberikan Pedagang dapat mengakses akun mana pun mereka inginkan.` +
      "\n" +
      `aplikasi IUX Markets MetaTrader Android memberi Anda akses ke spread pasar terkemuka dan kecepatan dalam Eksekusi perintah sebanding kami langsung di aplikasi Android Anda. Aplikasi ini memiliki fungsi Perdagangan dengan satu klik dari beberapa layar dan dengan format disesuaikan dengan kemampuan Untuk isi data historis dan lengkap grafik canggih Jadi Anda dapat mengelola akun Anda, perdagangan semua produk kami. Dan menggunakan lebih dari 30 indikator teknis untuk menganalisis pasar`,
    platform5_android_description: `We have MetaTrader 5 applications for Android devices. Traders can access their accounts wherever they want IUX Markets MetaTrader Android. The app gives you access to market leading spread and fast Execute our incomparable commands directly on your Android application. One-click trading from multiple screens and customizable layouts. with talent To browse historical data and complete advanced graphing. So you can manage your account, trade all of our products. and use over 30 technical indicators to analyze the market.`,
    platform_mac_description:
      `Kami memiliki MetaTrader 4 dan MetaTrader 5 aplikasi pada Mac. Aplikasi ini akan memberikan Pedagang dapat mengakses akun mana pun yang mereka inginkan` +
      "\n" +
      `aplikasi mobile MetaTrader kami menggunakan banyak maju Mac fitur seperti mengubah landscape ke portrait. (Handset rotasi) Selain itu, MetaTrader aplikasi mobile juga menyediakan perdagangan fungsi Dengan hanya satu klik Kemampuan untuk menyesuaikan gaya Anda Termasuk grafik canggih dan analisis Gunakan kami MetaTrader aplikasi mobile untuk mengalami fleksibilitas tak terbatas. Tanpa harus mengurangi kecepatan atau kualitas\n`,

    platform_iphone_section_2: {
      title: `Apa yang kita berikan pada IUX Markets\n`,
      sub_title: "",
      description: "",
      specification_header: `Persyaratan sistem\n`,
      specification_sub:
        `iPhone iOS 7.0 atau yang lebih kompatibel dengan iPhone, iPad dan iPod touch\n` +
        "\n",
      header_2: `Apa yang kita berikan pada IUX Markets`,
      label_1: `Memanfaatkan hingga 1 1000`,
      label_2: `Bonus 30 $ Perdagangan Bebas`,
    },
    why_platform: {
      iphone: {
        title1: `Mengapa Platform IUX Markets`,
        title2: `MetaTrader 4 Jadi ada perbedaan`,
        title_2_5: `MetaTrader 5 Jadi ada perbedaan`,
        subTitle: `Sangat cepat perintah eksekusi`,
        description:
          `The IUX Markets MetaTrader 4 Server dianggap sebagai ekosistem keuangan yang rumah perusahaan perdagangan di pasar. Lebih dari 600 penyedia layanan dan data terkait pasar, MetaTrader 4 Server juga menyediakan cross-koneksi ke Cloud kami untuk memastikan latency rendah dan eksekusi order cepat\n` +
          `The IUX Markets MetaTrader 4 server memiliki latency kurang dari 1 milidetik ke penyedia layanan VPS utama, apakah di pusat data melalui dedicated line ke pusat data. Dekatnya lingkungan low-latency ini sangat cocok untuk frekuensi tinggi dan perdagangan otomatis dan scalping`,
        description5: `With over 600 providers and real time market data, MetaTrader 5 server is also cross-connected to our Cloud to ensure low latency and fast execution. Through our several server location across the world, MetaTrader 5 offers all major VPS providers less than 1 millisecond latency regardless of where they are located. `,
      },
      android: {
        title1: `Mengapa Platform IUX Markets`,
        title2: `MetaTrader 4 Jadi ada perbedaan`,
        subTitle: `Sangat cepat perintah eksekusi`,
        description:
          `The IUX Markets MetaTrader 4 Server dianggap sebagai ekosistem keuangan yang rumah perusahaan perdagangan di pasar. Lebih dari 600 penyedia layanan dan data terkait pasar, MetaTrader 4 Server juga menyediakan cross-koneksi ke Cloud kami untuk memastikan latency rendah dan eksekusi order cepat.\n` +
          `The IUX Markets MetaTrader 4 server memiliki latency kurang dari 1 milidetik ke penyedia layanan VPS utama, apakah di pusat data melalui dedicated line ke pusat data. Dekatnya lingkungan low-latency ini sangat cocok untuk frekuensi tinggi dan perdagangan otomatis dan scalping.\n`,
      },
      mac: {
        title1: ``,
        title2: ``,
        subTitle: ``,
        description: `\n \n`,
      },
      window: {
        title1: ``,
        title2: ``,
        subTitle: ``,
        description: `\n \n`,
      },
    },
  },
  cn: {
    platform_iphone_header: {
      title: `MetaTrader的iPhone / iPad版`,
      sub_title: `就拿我们自己版本的MetaTrader的优势IUX Markets的连接。这是专为iPhone / iPad版`,
      button_text: `下载MetaTrader 4`,
    },
    platform_tab: {
      mt4: `MetaTrader 4的`,
      iphone: `MT4 iPhone / iPad版`,
      android: `MT4安卓`,
      mac: `MT4的Mac`,
    },
    platform_iphone_description:
      `我们对iPhone和iPad的MetaTrader 4和MetaTrader 5的应用程序。这些应用程序将提供交易员可以随时随地访问他们所需的帐户` +
      ` MetaTrader的移动应用程序使用iPhone和iPad的许多先进的功能，如改变景观肖像。 （手机旋转）此外，MetaTrader的移动应用程序还提供交易功能，只需点击一下，调整自己的风格，包括先进的图表和分析使用我们的MetaTrader的移动应用体验无限的灵活性的能力。而不必降低速度或质量`,
    platform5_iphone_description: `We offer  MetaTrader 5 applications for iPhone and iPad. Traders can access their accounts wherever they want. Our MetaTrader 5 mobile application uses many of the advanced features of iPhone and iPad such as landscape to portrait transitions. In addition, the MetaTrader 5 mobile application includes trading functions. with one click The ability to adjust your style including advanced graphs and analysis Use our MetaTrader 5 mobile application to experience unlimited flexibility. without sacrificing speed or quality`,
    platform_android_description:
      `我们对Android设备的MetaTrader 4应用程序。这些应用程序将提供交易员可以随时随地访问他们需要的帐户。` +
      "\n" +
      `IUX Markets MetaTrader的Android应用程序，您可以访问市场领先的利差和速度在我们相当的命令的执行，直接在你的Android应用程序。此应用程序所具有的功能与交易从多个屏幕和可自定义的格式，能够浏览历史数据和完整的先进的绘图这样你就可以管理自己的帐户，只需一次点击，交易所有我们的产品。而使用超过30种技术指标来分析市场`,
    platform5_android_description: `We have MetaTrader 5 applications for Android devices. Traders can access their accounts wherever they want IUX Markets MetaTrader Android. The app gives you access to market leading spread and fast Execute our incomparable commands directly on your Android application. One-click trading from multiple screens and customizable layouts. with talent To browse historical data and complete advanced graphing. So you can manage your account, trade all of our products. and use over 30 technical indicators to analyze the market.`,
    platform_mac_description:
      `我们在Mac上的MetaTrader 4和MetaTrader 5的应用程序。这些应用程序将提供交易员可以随时随地访问他们所需的帐户` +
      "\n" +
      `我们的MetaTrader的移动应用程序使用了很多先进的MAC功能，如改变景观肖像。 （手机旋转）此外，MetaTrader的移动应用程序还提供交易功能，只需点击一下，调整自己的风格，包括先进的图表和分析使用我们的MetaTrader的移动应用体验无限的灵活性的能力。而不必降低速度或质量\n`,

    platform_iphone_section_2: {
      title: `我们提供IUX Markets\n`,
      sub_title: "",
      description: "",
      specification_header: `系统要求\n`,
      specification_sub:
        `iPhone的iOS 7.0或更高版本与iPhone，iPad和iPod touch兼容\n` + "\n",
      header_2: `我们提供IUX Markets`,
      label_1: `杠杆高达1 1000`,
      label_2: `30 $自由贸易奖金`,
    },
    why_platform: {
      iphone: {
        title1: `为什么平台IUX Markets`,
        title2: `MetaTrader的4所以是有区别的`,
        title_2_5: `MetaTrader的5所以是有区别的`,
        subTitle: `极快的命令执行`,
        description:
          `该IUX Markets的MetaTrader 4服务器被认为是金融生态系统的房屋公司在市场上进行交易。超过600个服务供应商和市场的相关数据，MetaTrader的4服务器还提供了跨连接到我们的云，以确保低延迟和快速的订单执行\n` +
          `所述IUX Markets MT4客户服务器具有小于1毫秒到主VPS服务提供商的等待时间，无论是在经由专用线路到数据中心的数据中心。最近这低延迟的环境非常适合高频率和自动交易和倒卖`,
        description5: `With over 600 providers and real time market data, MetaTrader 5 server is also cross-connected to our Cloud to ensure low latency and fast execution. Through our several server location across the world, MetaTrader 5 offers all major VPS providers less than 1 millisecond latency regardless of where they are located. `,
      },
      android: {
        title1: `为什么平台IUX Markets`,
        title2: `MetaTrader的4所以是有区别的`,
        subTitle: `极快的命令执行`,
        description:
          `该IUX Markets的MetaTrader 4服务器被认为是金融生态系统的房屋公司在市场上进行交易。超过600个服务供应商和市场的相关数据，MetaTrader的4服务器还提供了跨连接到我们的云，以确保低延迟和快速的订单执行。\n` +
          `所述IUX Markets MT4客户服务器具有小于1毫秒到主VPS服务提供商的等待时间，无论是在经由专用线路到数据中心的数据中心。最近这低延迟的环境非常适合高频率和自动交易和倒卖。\n`,
      },
      mac: {
        title1: ``,
        title2: ``,
        subTitle: ``,
        description: `\n \n`,
      },
      window: {
        title1: ``,
        title2: ``,
        subTitle: ``,
        description: `\n \n`,
      },
    },
  },
  vn: {
    platform_iphone_header: {
      title: "MetaTrader đối với iPhone/iPad\n",
      sub_title:
        "Tận dụng khả năng kết nối IUX Markets trên phiên bản MetaTrader của riêng chúng tôi. đã được thiết kế cho Iphone / Ipad",
      button_text: "Tải xuống Metatrader 4",
    },
    platform_tab: {
      mt4: "Meta Trader 4",
      iphone: "MT4 iPhone/ iPad",
      android: "MT4 Android",
      mac: "MT4 Mac",
    },

    platform_iphone_description: `Chúng tôi có ứng dụng MetaTrader 4 và MetaTrader 5 trên iPhone và iPad, các ứng dụng này sẽ cung cấp Các nhà giao dịch có thể truy cập tài khoản của họ ở bất cứ đâu họ muốn. Các ứng dụng di động MetaTrader của chúng tôi sử dụng nhiều tính năng nâng cao của iPhone và iPad như chuyển đổi ngang sang dọc. Ngoài ra, ứng dụng di động MetaTrader có chức năng giao dịch. với một cú nhấp chuột Khả năng điều chỉnh phong cách của bạn bao gồm đồ thị nâng cao và phân tích Sử dụng ứng dụng di động MetaTrader của chúng tôi để trải nghiệm tính linh hoạt không giới hạn. mà không giảm sinh tốc độ hoặc chất lượng.`,
    platform5_iphone_description: `Chúng tôi có ứng dụng MetaTrader 4 và MetaTrader 5 trên iPhone và iPad Các ứng dụng này sẽ cung cấp Nhà giao dịch có thể truy cập tài khoản của họ ở bất cứ đâu họ muốn. Ứng dụng di động MetaTrader 5 của chúng tôi sử dụng nhiều tính năng nâng cao của iPhone và iPad như chuyển đổi ngang sang dọc. Ngoài ra, ứng dụng di động MetaTrader 5 bao gồm các chức năng giao dịch. với một cú nhấp chuột Khả năng điều chỉnh phong cách của bạn bao gồm đồ thị nâng cao và phân tích Sử dụng ứng dụng di động MetaTrader 5 của chúng tôi để trải nghiệm tính linh hoạt không giới hạn. mà không giảm tốc độ hoặc chất lượng.`,
    platform_android_description: `Chúng tôi có ứng dụng MetaTrader 4 đối với thiết bị Android Các ứng dụng này sẽ cung cấp Nhà giao dịch có thể truy cập tài khoản của họ ở bất cứ đâu họ muốn IUX Markets MetaTrader Android Ứng dụng cung cấp cho bạn quyền truy cập vào mức chênh lệch hàng đầu thị trường và tốc độ nhanh trong Thực thi các lệnh có một không hai của chúng tôi trực tiếp trên ứng dụng Android của bạn. Giao dịch bằng một cú nhấp chuột từ nhiều màn hình và bố cục có thể tùy chỉnh. với tài năng Để duyệt dữ liệu lịch sử và hoàn thành vẽ đồ thị nâng cao. Vì vậy, bạn có thể quản lý tài khoản của mình, giao dịch tất cả các sản phẩm của chúng tôi và sử dụng hơn 30 chỉ báo kỹ thuật để phân tích thị trường.`,
    platform5_android_description: `Chúng tôi có ứng dụng MetaTrader 5 đối với thiết bị Android Các ứng dụng này sẽ cung cấp Nhà giao dịch có thể truy cập tài khoản của họ ở bất cứ đâu họ muốn IUX Markets MetaTrader Android Ứng dụng cung cấp cho bạn quyền truy cập vào mức chênh lệch hàng đầu thị trường và tốc độ nhanh trong Thực thi các lệnh có một không hai của chúng tôi trực tiếp trên ứng dụng Android của bạn. Giao dịch bằng một cú nhấp chuột từ nhiều màn hình và bố cục có thể tùy chỉnh. với tài năng Để duyệt dữ liệu lịch sử và hoàn thành vẽ đồ thị nâng cao. Vì vậy, bạn có thể quản lý tài khoản của mình, giao dịch tất cả các sản phẩm của chúng tôi và sử dụng hơn 30 chỉ báo kỹ thuật để phân tích thị trường.`,
    platform_mac_description: `Chúng tôi có ứng dụng MetaTrader 4 và MetaTrader 5 trên Mac Các ứng dụng này sẽ cung cấp Các nhà giao dịch có thể truy cập tài khoản của họ ở bất cứ đâu họ muốn. Ứng dụng di động MetaTrader của chúng tôi sử dụng nhiều tính năng nâng cao của Mac như chuyển đổi ngang sang dọc. Ngoài ra, ứng dụng di động MetaTrader có chức năng giao dịch. với một cú nhấp chuột Khả năng điều chỉnh phong cách của bạn bao gồm đồ thị nâng cao và phân tích Sử dụng ứng dụng di động MetaTrader của chúng tôi để trải nghiệm tính linh hoạt không giới hạn. mà không không tốc độ hoặc chất lượng.`,
    platform_iphone_section_2: {
      title:
        "Chúng tôi có ứng dụng MetaTrader 4 và MetaTrader 5 cho iPhone và iPad. Nhà giao dịch có thể truy cập tài khoản của họ ở bất cứ đâu họ muốn.\n" +
        "\n" +
        "Các ứng dụng di động MetaTrader của chúng tôi sử dụng nhiều tính năng nâng cao của iPhone và iPad, chẳng hạn như chuyển đổi ngang sang dọc. Ngoài ra, ứng dụng di động MetaTrader có chức năng giao dịch. với một cú nhấp chuột Khả năng điều chỉnh phong cách của bạn bao gồm đồ thị nâng cao và phân tích Sử dụng ứng dụng di động MetaTrader của chúng tôi để trải nghiệm tính linh hoạt không giới hạn. mà không giảm tốc độ hoặc chất lượng.",
      sub_title: "Thực hiện đơn hàng rất nhanh chóng\n",
      description:
        "Máy chủ IUX Markets MetaTrader 4 này là một hệ sinh thái tài chính, nơi đặt các công ty thương mại, thị trường, nền tảng giao dịch. Với hơn 600 nhà cung cấp và dữ liệu thị trường, các máy chủ MetaTrader 4 cũng được kết nối chéo với Đám mây của chúng tôi để đảm bảo độ trễ thấp và thực thi nhanh chóng.\n" +
        "\n" +
        "Máy chủ IUX Markets MetaTrader 4 cung cấp độ trễ dưới 1 mili giây cho tất cả các nhà cung cấp VPS lớn cho dù ở trong trung tâm dữ liệu thông qua các đường dây chuyên dụng tới trung tâm dữ liệu.\n" +
        "\n",
      specification_header: "Yêu cầu hệ thống\n",
      specification_sub:
        "iPhone iOS 7.0 trở lên có thể sử dụng iPhone, iPad và iPod touch\n" +
        "\n",
      header_2: "Điều chúng tôi cho trên IUX Markets\n",
      label_1: "Đòn bẩy tối đa 1:1000\n",
      label_2: "tiền thưởng giao dịch miễn phí 30$\n",
    },
    why_platform: {
      iphone: {
        title1: "Tại sao nền tảng IUX Markets\n",
        title2: "MetaTrader 4 có sự khác biệt?",
        title_2_5: "MetaTrader 5 có sự khác biệt?",
        subTitle: "Thực hiện đơn hàng rất nhanh chóng.\n",
        description:
          "Máy chủ IUX Markets MetaTrader 4 này là một hệ sinh thái tài chính, nơi đặt các công ty thương mại, thị trường, nền tảng giao dịch. Với hơn 600 nhà cung cấp và dữ liệu thị trường, các máy chủ MetaTrader 4 cũng được kết nối chéo với Đám mây của chúng tôi để đảm bảo độ trễ thấp và thực thi nhanh chóng." +
          "\n" +
          "Máy chủ IUX Markets MetaTrader 4 cung cấp độ trễ dưới 1 mili giây cho tất cả các nhà cung cấp VPS lớn cho dù ở trong trung tâm dữ liệu thông qua các đường dây chuyên dụng tới trung tâm dữ liệu. \n" +
          "\n",
        description5: `Máy chủ IUX Markets MetaTrader 5 này là một hệ sinh thái tài chính chứa các công ty thương mại, thị trường, sàn giao dịch. Với hơn 600 nhà cung cấp và dữ liệu thị trường, các máy chủ MetaTrader 5 cũng được kết nối chéo với Đám mây của chúng tôi để đảm bảo độ trễ thấp và thực thi nhanh chóng. Máy chủ IUX Markets MetaTrader 5 cung cấp độ trễ dưới 1 mili giây cho các nhà cung cấp VPS lớn cho dù ở trong trung tâm dữ liệu thông qua các đường dây chuyên dụng tới trung tâm dữ liệu. gần đây Môi trường có độ trễ thấp này phù hợp cho tất cả các loại hình giao dịch.`,
        specification: {
          title: "Yêu cầu hệ thống\n",
          description:
            "iPhone iOS 7.0 trở lên, có thể sử dụng với iPhone, iPad và iPod touch\n",
        },
      },
      android: {
        title1: "Tại sao nền tảng IUX Markets\n",
        title2: "MetaTrader 4 có sự khác biệt?",
        subTitle: "Thực hiện đơn hàng rất nhanh chóng\n",
        description:
          "Máy chủ IUX Markets MetaTrader 4 này là một hệ sinh thái tài chính, nơi đặt các công ty thương mại, thị trường, nền tảng giao dịch. Với hơn 600 nhà cung cấp và dữ liệu thị trường, các máy chủ MetaTrader 4 cũng được kết nối chéo với Đám mây của chúng tôi để đảm bảo độ trễ thấp và thực thi nhanh chóng.\n" +
          "\n" +
          "Máy chủ IUX Markets MetaTrader 4 cung cấp độ trễ dưới 1 mili giây cho tất cả các nhà cung cấp VPS lớn cho dù ở trong trung tâm dữ liệu thông qua các đường dây chuyên dụng tới trung tâm dữ liệu. \n" +
          "\n",
      },
      mac: {
        title1: "Tại sao nền tảng IUX Markets\n",
        title2: "MetaTrader 4 có sự khác biệt?",
        subTitle: "Thực hiện đơn hàng rất nhanh chóng\n",
        description:
          "Máy chủ IUX Markets MetaTrader 4 này là một hệ sinh thái tài chính chứa các công ty thương mại, thị trường, nền tảng giao dịch. Với hơn 600 nhà cung cấp và dữ liệu thị trường, các máy chủ MetaTrader 4 cũng được kết nối chéo với Đám mây của chúng tôi để đảm bảo độ trễ thấp và thực thi nhanh chóng.\n" +
          "\n" +
          "Máy chủ IUX Markets MetaTrader 4 cung cấp độ trễ dưới 1 mili giây cho tất cả các nhà cung cấp VPS lớn cho dù ở trong trung tâm dữ liệu thông qua các đường dây chuyên dụng tới trung tâm dữ liệu.\n" +
          "\n",
      },
      window: {
        title1: "Tại sao nền tảng IUX Markets\n",
        title2: "MetaTrader 4 có sự khác biệt?",
        subTitle: "Thực hiện đơn hàng rất nhanh chóng\n",
        description:
          "Máy chủ IUX Markets MetaTrader 4 này là một hệ sinh thái tài chính chứa các công ty thương mại, thị trường, nền tảng giao dịch. Với hơn 600 nhà cung cấp và dữ liệu thị trường, các máy chủ MetaTrader 4 cũng được kết nối chéo với Đám mây của chúng tôi để đảm bảo độ trễ thấp và thực thi nhanh chóng.\n" +
          "\n" +
          "Máy chủ IUX Markets MetaTrader 4 cung cấp độ trễ dưới 1 mili giây cho các nhà cung cấp VPS lớn, cho dù ở trung tâm dữ liệu, thông qua các đường dây chuyên dụng đến trung tâm dữ liệu. \n" +
          "\n",
      },
    },
  },
  kr: {
    platform_iphone_header: {
      title: `메타 트레이더 아이폰 / 아이 패드`,
      sub_title: `메타 트레이더의 우리의 자신의 버전에 IUX Markets 연결을 활용할 수 있습니다. 아이폰 / 아이 패드 디자인 된`,
      button_text: `다운로드 메타 트레이더 4`,
    },
    platform_tab: {
      mt4: `메타 트레이더 4`,
      iphone: `MT4 아이폰 / 아이 패드`,
      android: `MT4 안드로이드`,
      mac: `MT4 맥`,
    },
    platform_iphone_description:
      `우리는 아이폰과 아이 패드에 대한 메타 트레이더 4 메타 트레이더 5 응용 프로그램을. 그들이 원하는 목적지 계정에 액세스 할 수 있습니다 상인을 제공 할 것이다 이러한 응용 프로그램` +
      ` 메타 트레이더 모바일 응용 프로그램은 세로로 가로를 변경하는 등 아이폰과 아이 패드의 다양한 고급 기능을 사용합니다. (핸드셋 회전)에서 추가의 메타 트레이더 모바일 응용 프로그램은 하나의 무제한 유연성을 경험할 수있는 고급 그래프와 분석을 사용하여 우리의 메타 트레이더 모바일 응용 프로그램을 포함하여 스타일을 조정하는 기능을 클릭 한 번으로 기능 거래를 제공합니다. 속도 나 품질이 저하하지 않고`,
    platform5_iphone_description: `We offer  MetaTrader 5 applications for iPhone and iPad. Traders can access their accounts wherever they want. Our MetaTrader 5 mobile application uses many of the advanced features of iPhone and iPad such as landscape to portrait transitions. In addition, the MetaTrader 5 mobile application includes trading functions. with one click The ability to adjust your style including advanced graphs and analysis Use our MetaTrader 5 mobile application to experience unlimited flexibility. without sacrificing speed or quality`,
    platform_android_description:
      `우리는 안드로이드 장치에 대한 메타 트레이더 4 응용 프로그램이 있습니다. 이러한 응용 프로그램은 상인들이 원하는 목적지 계정에 액세스 할 수 있습니다 제공 할 것입니다.` +
      "\n" +
      `IUX Markets 메타 트레이더 안드로이드 응용 프로그램은 직접 안드로이드 응용 프로그램에서 우리의 비교 명령의 실행에 시장을 선도하는 스프레드과 속도에 액세스 할 수 있습니다. 이 응용 프로그램은 여러 화면에서와 계정을 관리 할 수 ​​있도록 기록 데이터 완전 고급 그래프를 검색 할 수있는 기능으로 사용자 정의 형식과 한 번의 클릭으로 기능 거래를 가지고, 우리의 제품의 모든 무역. 그리고 시장을 분석하기 위해 30여 기술적 지표를 사용`,
    platform5_android_description: `We have MetaTrader 5 applications for Android devices. Traders can access their accounts wherever they want IUX Markets MetaTrader Android. The app gives you access to market leading spread and fast Execute our incomparable commands directly on your Android application. One-click trading from multiple screens and customizable layouts. with talent To browse historical data and complete advanced graphing. So you can manage your account, trade all of our products. and use over 30 technical indicators to analyze the market.`,
    platform_mac_description:
      `우리는 Mac에서 메타 트레이더 4 메타 트레이더 5 응용 프로그램을. 그들이 원하는 목적지 계정에 액세스 할 수 있습니다 상인을 제공 할 것이다 이러한 응용 프로그램` +
      "\n" +
      `우리의 메타 트레이더 모바일 응용 프로그램은 많은 고급 맥 세로로 가로을 변경하는 등의 기능을 사용합니다. (핸드셋 회전)에서 추가의 메타 트레이더 모바일 응용 프로그램은 하나의 무제한 유연성을 경험할 수있는 고급 그래프와 분석을 사용하여 우리의 메타 트레이더 모바일 응용 프로그램을 포함하여 스타일을 조정하는 기능을 클릭 한 번으로 기능 거래를 제공합니다. 속도 나 품질이 저하하지 않고\n`,

    platform_iphone_section_2: {
      title: `우리가 IUX Markets에 제공\n`,
      sub_title: "",
      description: "",
      specification_header: `시스템 요구 사항\n`,
      specification_sub:
        `나중에 아이폰 아이폰 OS 7.0은 아이폰, 아이 패드 및 아이팟 터치와 호환\n` +
        "\n",
      header_2: `우리가 IUX Markets에 제공`,
      label_1: `1 1000까지 활용`,
      label_2: `30 $ 자유 무역 보너스`,
    },
    why_platform: {
      iphone: {
        title1: `왜 플랫폼 IUX Markets`,
        title2: `메타 트레이더 4는 따라서 차이가 있습니다`,
        titl_e_52: `메타 트레이더 4는 따라서 차이가 있습니다`,
        subTitle: `매우 빠른 명령 실행`,
        description:
          `IUX Markets 메타 트레이더 4 서버는 주택 업체들이 시장에서 거래하는 금융 생태계로 간주됩니다. 서비스 제공 업체 및 시장 관련 데이터 (600) 동안, 메타 트레이더 4 서버는 낮은 지연 시간과 빠른 주문 실행을 보장하기 위해 우리의 클라우드에 대한 상호 연결을 제공합니다\n` +
          `IUX Markets 메타 트레이더 4 서버는 기본 VPS 서비스 제공자 미만 1 밀리 초의 지연 시간을 갖는 데이터 센터에 전용 회선을 통해 데이터 센터의 여부. 이 짧은 대기 시간 환경 근처의 고주파에 대한 완벽하고 자동화 된 거래 및 스캘핑 (scalping)입니다`,
        description5: `With over 600 providers and real time market data, MetaTrader 5 server is also cross-connected to our Cloud to ensure low latency and fast execution. Through our several server location across the world, MetaTrader 5 offers all major VPS providers less than 1 millisecond latency regardless of where they are located. `,
      },
      android: {
        title1: `왜 플랫폼 IUX Markets`,
        title2: `메타 트레이더 4는 따라서 차이가 있습니다`,
        subTitle: `매우 빠른 명령 실행`,
        description:
          `IUX Markets 메타 트레이더 4 서버는 주택 업체들이 시장에서 거래하는 금융 생태계로 간주됩니다. 서비스 제공 업체 및 시장 관련 데이터 (600) 동안, 메타 트레이더 4 서버는 낮은 지연 시간과 빠른 주문 실행을 보장하기 위해 우리의 클라우드에 대한 상호 연결을 제공합니다.\n` +
          `IUX Markets 메타 트레이더 4 서버는 기본 VPS 서비스 제공자 미만 1 밀리 초의 지연 시간을 갖는 데이터 센터에 전용 회선을 통해 데이터 센터의 여부. 인근이 저 지연 환경은 고주파 및 자동화 된 거래 및 스캘핑 (scalping)에 적합합니다.\n`,
      },
      mac: {
        title1: ``,
        title2: ``,
        subTitle: ``,
        description: `\n \n`,
      },
      window: {
        title1: ``,
        title2: ``,
        subTitle: ``,
        description: `\n \n`,
      },
    },
  },
  ph: {
    platform_iphone_header: {
      title: `MetaTrader iPhone / iPad`,
      sub_title: `Samantalahin ang IUX Markets koneksyon sa aming sariling bersyon ng MetaTrader. Na kung saan ay dinisenyo para sa iPhone / iPad`,
      button_text: `I-download ang Metatrader 4`,
    },
    platform_tab: {
      mt4: `Meta Trader 4`,
      iphone: `MT4 iPhone / iPad`,
      android: `MT4 Android`,
      mac: `MT4 Mac`,
    },
    platform_iphone_description:
      `Mayroon kaming ang MetaTrader 4 at MetaTrader 5 application sa iPhone at iPad. Ang mga aplikasyon ay magbibigay Traders maaaring ma-access ang account kung saan man nila gusto` +
      `MetaTrader mobile application ay gumagamit ng maraming mga advanced na mga tampok ng iPhone at iPad tulad ng pagpapalit ng landscape sa portrait. (Handset pag-ikot) Bilang karagdagan, ang MetaTrader mobile application ay nagbibigay din ng kalakalan function Sa isang pag-click lamang Ang kakayahan upang ayusin ang iyong estilo Kabilang ang mga advanced na mga graph at pagtatasa Gamitin ang aming MetaTrader mobile application upang maranasan walang limitasyong flexibility. Nang hindi na kinakailangang upang mabawasan ang bilis o kalidad`,
    platform5_iphone_description: `We offer  MetaTrader 5 applications for iPhone and iPad. Traders can access their accounts wherever they want. Our MetaTrader 5 mobile application uses many of the advanced features of iPhone and iPad such as landscape to portrait transitions. In addition, the MetaTrader 5 mobile application includes trading functions. with one click The ability to adjust your style including advanced graphs and analysis Use our MetaTrader 5 mobile application to experience unlimited flexibility. without sacrificing speed or quality`,
    platform_android_description:
      `Mayroon kaming mga aplikasyon para sa mga aparatong Android MetaTrader 4. Ang mga aplikasyon ay magbibigay Traders maaaring ma-access ang account kung saan man nila gusto.` +
      "\n" +
      `IUX Markets MetaTrader Android application ay nagbibigay sa iyo ng access sa market-nangungunang spread at bilis sa Ang pagpapatupad ng aming mga maihahambing na mga utos nang direkta sa iyong Android application. Ang application na ito ay may mga pag-andar Trading na may isang solong pag-click mula sa maraming screen at may mga nako-customize na mga format Gamit kakayahan Upang i-browse ang makasaysayang data at kumpleto advanced graphing Kaya maaari mong pamahalaan ang iyong account, pangkalakal lahat ng aming mga produkto. At gumamit ng higit sa 30 mga teknikal na tagapagpahiwatig upang pag-aralan ang merkado`,
    platform5_android_description: `We have MetaTrader 5 applications for Android devices. Traders can access their accounts wherever they want IUX Markets MetaTrader Android. The app gives you access to market leading spread and fast Execute our incomparable commands directly on your Android application. One-click trading from multiple screens and customizable layouts. with talent To browse historical data and complete advanced graphing. So you can manage your account, trade all of our products. and use over 30 technical indicators to analyze the market.`,
    platform_mac_description:
      `Mayroon kaming ang MetaTrader 4 at MetaTrader 5 application sa Mac. Ang mga aplikasyon ay magbibigay Traders maaaring ma-access ang account kung saan man nila gusto` +
      "\n" +
      `Ang aming MetaTrader mobile application ay gumagamit ng maraming mga advanced Mac Nagtatampok tulad ng pagpapalit ng landscape sa portrait. (Handset pag-ikot) Bilang karagdagan, ang MetaTrader mobile application ay nagbibigay din ng kalakalan function Sa isang pag-click lamang Ang kakayahan upang ayusin ang iyong estilo Kabilang ang mga advanced na mga graph at pagtatasa Gamitin ang aming MetaTrader mobile application upang maranasan walang limitasyong flexibility. Nang hindi na kinakailangang upang mabawasan ang bilis o kalidad\n`,

    platform_iphone_section_2: {
      title: `Ano ang binigay namin sa IUX Markets\n`,
      sub_title: "",
      description: "",
      specification_header: `Pangangailangan sa System\n`,
      specification_sub:
        `iPhone iOS 7.0 o mas bago ay tugma sa iPhone, iPad at iPod touch\n` +
        "\n",
      header_2: `Ano ang binigay namin sa IUX Markets`,
      label_1: `Paghusayin hanggang sa 1 1000`,
      label_2: `30 $ Free Trade Bonus`,
    },
    why_platform: {
      iphone: {
        title1: `Bakit platform IUX Markets`,
        title2: `MetaTrader 4 Kaya mayroong isang pagkakaiba`,
        title_2_5: `MetaTrader 5 Kaya mayroong isang pagkakaiba`,
        subTitle: `Lubhang mabilis utos ng execution`,
        description:
          `Ang IUX Markets MetaTrader 4 server ay itinuturing na isang pinansiyal na ecosystem na bahay mga kumpanya trading sa merkado. Sa paglipas ng 600 mga service provider at market-kaugnay na data, ang MetaTrader 4 server ay nagbibigay din ng cross-koneksyon sa aming Cloud upang matiyak mababang latency at mabilis na pagkakasunod-sunod execution\n` +
          `Ang IUX Markets MetaTrader 4 server ay may latency ng mas mababa sa 1 millisecond sa main VPS service provider, kung sa data center sa pamamagitan ng isang dedikado linya sa sentro ng data. Malalapit na ito low-latency kapaligiran ay perpekto para sa mataas na dalas at automated na kalakalan at scalping`,
        description5: `With over 600 providers and real time market data, MetaTrader 5 server is also cross-connected to our Cloud to ensure low latency and fast execution. Through our several server location across the world, MetaTrader 5 offers all major VPS providers less than 1 millisecond latency regardless of where they are located. `,
      },
      android: {
        title1: `Bakit platform IUX Markets`,
        title2: `MetaTrader 4 Kaya mayroong isang pagkakaiba`,
        subTitle: `Lubhang mabilis utos ng execution`,
        description:
          `Ang IUX Markets MetaTrader 4 server ay itinuturing na isang pinansiyal na ecosystem na bahay mga kumpanya trading sa merkado. Sa paglipas ng 600 mga service provider at market-kaugnay na data, ang MetaTrader 4 server ay nagbibigay din ng cross-koneksyon sa aming Cloud upang matiyak mababang latency at mabilis na pagkakasunod-sunod execution.\n` +
          `Ang IUX Markets MetaTrader 4 server ay may latency ng mas mababa sa 1 millisecond sa main VPS service provider, kung sa data center sa pamamagitan ng isang dedikado linya sa sentro ng data. Malalapit na ito low-latency kapaligiran ay perpekto para sa mataas na dalas at automated na kalakalan at scalping.\n`,
      },
      mac: {
        title1: ``,
        title2: ``,
        subTitle: ``,
        description: `\n \n`,
      },
      window: {
        title1: ``,
        title2: ``,
        subTitle: ``,
        description: `\n \n`,
      },
    },
  },
  jp: {
    platform_iphone_header: {
      title: "Metatrader iPhone/iPad  n",
      sub_title:
        "Metatraderの独自のバージョンでIUX Markets Connectivityを活用してください。 iPhone / iPad  n用に設計されています",
      button_text: "Metatrader 4をダウンロードします",
    },
    platform_tab: {
      mt4: "Metatrader 4",
      iphone: "MT4 iPhone/ iPad",
      android: "MT4 Android",
      mac: "MT4 MAC",
    },
    platform_iphone_description:
      "iPhoneとiPadにMetatrader 5アプリケーションがあります。 これらのアプリケーションは、トレーダーが必要な場所にアカウントにアクセスできるようにします。 Metatraderモバイルアプリケーションでは、Landscapeをポートレートに変更するなど、iPhoneやiPadの多くの高度な機能を使用しています。 （携帯電話の回転）さらに、Metatraderモバイルアプリケーションは、ワンクリックしてトレーディング機能を提供し、高度なグラフや分析などのスタイルを調整する機能をクリックして、メタトレーダーモバイルアプリケーションを使用して無制限の柔軟性を体験します。 速度や品質を減らす必要なく",
    platform5_iphone_description: `We offer  MetaTrader 5 applications for iPhone and iPad. Traders can access their accounts wherever they want. Our MetaTrader 5 mobile application uses many of the advanced features of iPhone and iPad such as landscape to portrait transitions. In addition, the MetaTrader 5 mobile application includes trading functions. with one click The ability to adjust your style including advanced graphs and analysis Use our MetaTrader 5 mobile application to experience unlimited flexibility. without sacrificing speed or quality`,
    platform_android_description:
      "Androidデバイス用のMetatrader 4アプリケーションがあります。 これらのアプリケーションは、トレーダーが好きな場所にアカウントにアクセスできるようにします。 n" +
      "\n" +
      "IUX Markets Metatrader Androidアプリケーションは、Androidアプリケーションでの同等のコマンドの実行における市場をリードする広がりと速度へのアクセスを提供します。 このアプリケーションには、複数の画面からの1回のクリックと、履歴データを閲覧し、高度なグラフを完全に閲覧する機能を備えたカスタマイズ可能なフォーマットを使用して、関数取引があり、アカウントを管理し、すべての製品を取引できるようにします。 30を超える技術指標を使用して市場を分析する",
    platform5_android_description: `We have MetaTrader 5 applications for Android devices. Traders can access their accounts wherever they want IUX Markets MetaTrader Android. The app gives you access to market leading spread and fast Execute our incomparable commands directly on your Android application. One-click trading from multiple screens and customizable layouts. with talent To browse historical data and complete advanced graphing. So you can manage your account, trade all of our products. and use over 30 technical indicators to analyze the market.`,
    platform_mac_description:
      "MacにMetatrader 5アプリケーションがあります。 これらのアプリケーションは、トレーダーが好きな場所にアカウントにアクセスできるようにします。 n" +
      "\n" +
      "Metatraderモバイルアプリケーションでは、風景の変更など、多くの高度なMac機能をポートレートに使用しています。 （携帯電話の回転）さらに、Metatrader Mobileアプリケーションは、ワンクリックして取引機能を提供し、高度なグラフや分析などのスタイルを調整する機能をクリックして、Metatraderモバイルアプリケーションを使用して無制限の柔軟性を体験します。 速度や品質を減らす必要がありません n",

    platform_iphone_section_2: {
      title: "IUX Markets  nで提供するもの",
      sub_title: "",
      description: "",
      specification_header: "システム要件 n",
      specification_sub:
        "iPhone iOS 7.0以降は、iPhone、iPad、iPod Touchと互換性があります。" +
        "\n",
      header_2: "IUX市場で提供するもの",
      label_1: "最大1：1000を活用します",
      label_2: "30 $無料貿易ボーナス",
    },
    why_platform: {
      iphone: {
        title1: "なぜiux Markets Metatrader5？",
        title2: "Metatrader 4違いがありますか？",
        title_2_5: "Metatrader 5違いがありますか？",
        subTitle: "非常に速い実行 n  n",
        description:
          "IUX Markets Metatrader 4サーバーは、企業が市場で取引している金融エコシステムと見なされています。 600を超えるサービスプロバイダーと市場関連のデータであるMetatrader 4サーバーは、クラウドとの相互接続も提供して、遅延と迅速な注文実行を確保します。" +
          "IUX Markets Metatrader 4サーバーは、データセンターに専用のラインを介してデータセンターにいるかどうかにかかわらず、メインVPSサービスプロバイダーに1ミリ秒未満の遅延があります。 近くのこの低遅延環境は、高周波と自動化された取引とスキャルピングに最適です。",
        description5: `With over 600 providers and real time market data, MetaTrader 5 server is also cross-connected to our Cloud to ensure low latency and fast execution. Through our several server location across the world, MetaTrader 5 offers all major VPS providers less than 1 millisecond latency regardless of where they are located. `,
      },
      android: {
        title1: "なぜiux Markets Metatrader5？",
        title2: "Metatrader 4違いがありますか？",
        subTitle: "非常に速い実行 n  n",
        description:
          "IUX Markets Metatrader 4サーバーは、企業が市場で取引している金融エコシステムと見なされています。 600を超えるサービスプロバイダーと市場関連のデータであるMetatrader 4サーバーは、クラウドとの相互接続も提供して、遅延と迅速な注文実行を確保します。 \\ n '+ n" +
          "                    '\\ n' +\n" +
          "'IUX Markets Metatrader 4サーバーは、データセンターに専用のラインを介してデータセンターにいるかどうかにかかわらず、メインVPSサービスプロバイダーに1ミリ秒未満のレイテンシを持っています。 近くのこの低遅延環境は、高周波と自動化された取引とスキャルピングに最適です。 \\ n '+ n",
      },
    },
  },
};
