import React, { useEffect, useState } from "react";
import {
  Modal,
  Row,
  Col,
  Checkbox,
  Button,
  Form,
  Input,
  Select,
  Alert,
  message,
  Divider,
} from "antd";
import { post } from "../../../helper/request";
import { onOpenPolicy } from "../../../helper/policy";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { addAccount } from "../../../actions";
const locale = require("react-redux-i18n").I18n;

function CreateAccount(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const profileCountry = useSelector((state) => state.user.profileCountry);
  const { type } = useParams();
  const { isOpen, onClose, isType, isAccountType } = props;
  const { Option } = Select;
  const [mobile, setMobile] = useState(false);
  const leverages = useSelector((state) => state.user.leverages);
  const accounts = useSelector((state) => state.user.accounts);
  const accountTypeList = useSelector((state) => state.account.accountTypeList);
  const isDetail =
    accountTypeList && accountTypeList.find((e) => e.display === isAccountType);
  const accountTypeListDemo = useSelector(
    (state) => state.account.accountTypeListDemo
  );
  const [form] = Form.useForm();
  const refAdd = React.createRef();
  const [modalCreateVisible, setModalCreateVisible] = useState(false);
  const [bonusVisibleModal, setBonusVisibleModal] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [buttonbonus, setButtonbonus] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const onChange = (e) => {
    setButtonbonus(e.target.checked);
  };
  const onCreate = async () => {
    try {
      const values = await form.validateFields();
      const payload =
        isType === "trade-real"
          ? {
              currency: values.currency,
              // account_type: "Standard",
              account_type_id: isDetail.id,
              platform: "MT5",
              password: values.password,
              password_confirmation: values.password,
              leverage: values.select,
              // password_investor: "Test-55yoho",
              code: localStorage.getItem("code"),
            }
          : {
              currency: values.currency,
              // account_type: "Standard",
              account_type_id: accountTypeListDemo.find(
                (e) => e.name === isDetail.name
              )?.id,
              platform: "MT5",
              password: values.password,
              password_confirmation: values.password,
              leverage: values.select,
              // password_investor: values.password_investor,
              code: localStorage.getItem("code"),
              deposit: parseFloat(values.deposit),
            };
      setButtonLoading(true);
      post(
        process.env.REACT_APP_API_URL + `v2/user/account/${isType}/add`,
        payload
      )
        .then((res) => {
          let { data } = res;

          const account = {
            id: data.data.id,
            user_id: data.data.user_id,
            account_number: data.data.account_number.toString(),
            platform: data.data.platform,
            currency: data.data.currency,
            api_server: data.data.api_server,
            account_type: isDetail?.name,
            account_group: { display: isDetail?.display },
            password: data.data.password,
            status: data.data.status,
            leverage: data.data.leverage,
            current_leverage: data.data.current_leverage,
            current_balance:
              isType === "trade-demo"
                ? parseFloat(values.deposit).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) || 0
                : data.data.current_balance || 0,
            current_credit: data.data.current_credit || 0,
            deletedAt: null,
            type: data.data.type,
            reset_code: null,
            password_investor: data.data.password_investor,
            reset_code_investor: null,
            createdAt: data.data.createdAt,
            updatedAt: data.data.updatedAt,
            isOrderPlaced: false,
            avilableWithdrawNonCalculated: 0,
            avilableWithdraw: 0,
          };
          refAdd?.current?.resetFields();
          dispatch(addAccount([...accounts, account]));
          setButtonLoading(false);
          setModalCreateVisible(false);
          setMobile(false);
          setAccountNumber(data?.data?.account_number);
          setShowSuccess(true);
        })
        .catch((e) => {
          console.log(e);
          setButtonLoading(false);
          try {
            if (e.response.data.code === 10) {
              Modal.error({
                title: locale.t("dashboardWallet.your_account"),
                content: (
                  <Alert
                    message={locale.t("dashboardWallet.error_10")}
                    type="success"
                  />
                ),
              });
            } else {
              message.error(e.response.data.error);
            }
          } catch (error) {
            console.log(error);
          }
        });
    } catch (errorInfo) {
      setButtonLoading(false);
    }
  };

  const validatorPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = new RegExp(
        /^(?=.*[0-9])(?=.*[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]{8,}$/
      );
      // const regex = new RegExp(
      //   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
      // );

      if (value?.match(regex)) {
        return Promise.resolve();
      } else {
        if (value) {
          return Promise.reject(
            locale.t("dashboardWallet.modal.accountPassword_validate")
          );
        } else {
          return Promise.reject(locale.t("dashboardWallet.input_error"));
        }
      }
    },
  });

  return (
    <Modal
      className="w-modal width-modal-openAccountBonus user user-change"
      closable={true}
      visible={isOpen}
      title={false}
      onCancel={() => {
        form.resetFields();
        onClose();
        setShowSuccess(false);
      }}
      footer={[
        <div className="d-flex justify-content-between text-center"></div>,
      ]}
    >
      {!showSuccess ? (
        <Form
          form={form}
          ref={refAdd}
          initialValues={{
            currency: "USD",
            deposit: 1000,
          }}
          // className="user-change"
        >
          <div className="p-4">
            <div className="d-flex justify-content-center w-100 fs-20 fw-700 pb-4">
              {locale.t("dashboardWallet.modal.title")}
            </div>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="mt_type"
                  rules={[
                    {
                      message: locale.t("dashboardWallet.input_error"),
                    },
                  ]}
                >
                  <div>{locale.t("dashboardWallet.server")}</div>
                  <Input disabled defaultValue={"Metatrader 5"} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="currency"
                  rules={[
                    {
                      required: true,
                      message: locale.t("dashboardWallet.input_error"),
                    },
                  ]}
                >
                  <div>{locale.t("dashboardWallet.modal.accountMoney")}</div>
                  <Input disabled defaultValue={"USD"} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="type">
                  <div>{locale.t("dashboardWallet.accountType")}</div>
                  <Select
                    size="large"
                    placeholder={isDetail?.display}
                    // defaultValue={isDetail?.display}
                    disabled
                  >
                    <Option value={isDetail?.id}>{isDetail?.display}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                {" "}
                <div>{locale.t("dashboardWallet.accountLeverage")}</div>
                <Form.Item
                  name="select"
                  rules={[
                    {
                      required: true,
                      message: locale.t("dashboardWallet.input_error"),
                    },
                  ]}
                >
                  <Select
                    size="large"
                    placeholder={locale.t("dashboardWallet.accountLeverage")}
                    listHeight={290}
                  >
                    {leverages[isDetail?.name] &&
                      leverages[isDetail?.name].map((e, i) => (
                        <Option value={e.values} key={i}>
                          <div>
                            <div className="row">
                              <div className="col-8 pr-0">
                                <div className="">1:{e.values} &nbsp;</div>
                                <Divider className="m-0" />
                              </div>
                              {(e.values === 1 ||
                                e.values === 100 ||
                                e.values === 1000) && (
                                <div
                                  className={`${
                                    e.values >= 1000
                                      ? "text-danger"
                                      : e.values < 100
                                      ? "text-primary-new-iux"
                                      : "text-yellow-new-iux"
                                  } col-4 text-right pl-0`}
                                >
                                  <div className="">
                                    {locale.t(
                                      e.title.replace(" ", "_").toLowerCase()
                                    )}
                                    {/* {e.title.replace(" ", "_").toLowerCase()} */}
                                  </div>
                                  <Divider
                                    className={`${
                                      e.values >= 1000
                                        ? "bg-danger"
                                        : e.values < 100
                                        ? "bg-primary-iux"
                                        : "bg-yellow-iux"
                                    } m-0`}
                                    style={{
                                      background:
                                        e.values >= 1000
                                          ? "#B44844"
                                          : e.values < 100
                                          ? "#1cb894"
                                          : "rgb(241, 158, 3)",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              {" "}
              <Col span={24}>
                <div>{locale.t("dashboardWallet.modal.accountPassword")}</div>
                <Form.Item
                  name="password"
                  rules={[
                    // {
                    //   required: true,
                    //   message: locale.t("dashboardWallet.input_error"),
                    // },
                    validatorPassword,
                  ]}
                >
                  <Input.Password
                    type="password"
                    placeholder={locale.t(
                      "dashboardWallet.modal.accountPassword"
                    )}
                    className="user-password"
                  />
                </Form.Item>
              </Col>
            </Row>
            {isType === "trade-demo" && (
              <Col span={24}>
                <div>{locale.t("dashboardWallet.accountDemoMoney")} (USD)</div>
                <Form.Item
                  name="deposit"
                  rules={[
                    {
                      required: true,
                      message: locale.t("dashboardWallet.input_error"),
                    },
                  ]}
                >
                  <Input type="number" placeholder={`Amount (USD)`} />
                </Form.Item>
              </Col>
            )}
            <Button
              className="btn-primary-new-iux w-100 mt-4"
              key="submit"
              type="primary"
              loading={buttonLoading}
              onClick={onCreate}
              // disabled={buttonbonus === false}
            >
              {locale.t("dashboardWallet.modal.confirm")}
            </Button>
          </div>
        </Form>
      ) : (
        <div className="p-4">
          <div className="d-flex justify-content-center w-100 fs-20 fw-700">
            {locale.t("congrat")}
          </div>

          <div className="d-flex justify-content-center align-items-center flex-column w-100 fs-12 mt-4">
            <div className="fs-14">
              {locale.t(
                isType === "trade-demo"
                  ? "congrat_demo_detail"
                  : "congrat_detail"
              )}
            </div>
            {/* <img
              src="/assets/images/icons/dashboard/congratulations.png"
              alt="bonus30"
              className="mt-3"
            /> */}
            <div className="mt-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="72"
                height="72"
                viewBox="0 0 72 72"
                fill="none"
              >
                <g clip-path="url(#clip0_2574_2870)">
                  <path
                    d="M9.06641 62.584V53.168H9.81441V72H9.06641V62.584ZM16.2034 55.875V46.458H16.9514V65.29H16.2034V55.875ZM59.2274 58.588V54.3H56.2734V4.2H59.2274V0H59.9754V2.1V4.2H61.4494H62.9234V54.3H61.4494H59.9754V58.58V62.86H59.2274V58.588ZM23.3274 49.7V40.284H24.0894V59.116H23.3274V49.7ZM31.0274 49.753V48.353H28.0724V23.7H31.0264V20.676H31.7744V22.183V23.69H33.2484H34.7224V48.356H33.2484H31.7744V49.749V51.142H31.0264V49.742L31.0274 49.753ZM33.9774 36.023V24.323H31.4014H28.8254V36.004C28.8254 42.429 28.8254 47.693 28.8254 47.704C28.8254 47.715 29.3474 47.72 31.4014 47.717H33.9714V36.017L33.9774 36.023ZM40.1694 41.413V40.013H37.2144V15.357H40.1664V12.336H40.9144V13.843V15.35H42.3884H43.8624V40.016H42.3884H40.9144V41.409V42.802H40.1664V41.402L40.1694 41.413ZM43.1234 27.686V15.986H40.5474H37.9714V27.667C37.9714 34.092 37.9714 39.356 37.9714 39.367C37.9714 39.378 38.5134 39.384 40.5514 39.384H43.1244V27.684L43.1234 27.686ZM49.4584 32.11V30.71H46.5034V6.055H49.4574V3.034H50.2054V4.541V6.048H51.6794H53.1534V30.714H51.6794H50.2054V32.107V33.5H49.4574V32.1L49.4584 32.11ZM52.4124 18.384V6.683H49.8364H47.2604V18.364C47.2604 24.789 47.2604 30.053 47.2604 30.064C47.2604 30.075 47.7994 30.081 49.8404 30.081H52.4134V18.381L52.4124 18.384Z"
                    fill={isType === "trade-demo" ? "#FE9F01" : "#1CB894"}
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2574_2870">
                    <rect width="72" height="72" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="fs-14 text-lightgray mt-3">
              {locale.t("account_number")}
            </div>
            <div className="fw-700 fs-14 mt-2">{accountNumber}</div>
            <div
              // onClick={() => onOpenSwap()}
              className={`
                    bage-free-swap-isfree
                     ml-2`}
            >
              Free Swap
            </div>
            {profileCountry?.isalamic === 0 && (
              <div className="text-center mt-3">
                {isType === "trade-demo"
                  ? locale.t("account_create_demo")
                  : locale.t("account_create_success")}
              </div>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
}

export default CreateAccount;
