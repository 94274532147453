import React, { useState } from "react";
import Axios from "axios";
import { Result, Button } from "antd";
const locale = require("react-redux-i18n").I18n;

function EmailVerifier(props) {
  const [status, setStatus] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  Axios.get(
    process.env.REACT_APP_API_URL +
      "v2/email-verifier?token=" +
      props.match.params.token
  )
    .then((res) => {
      setStatus("success");
      setTitle(locale.t("register_verify_title"));
      setContent(locale.t("register_verify_description"));
      if (res.status === 200) {
        return (window.location.href = `/login`);
      }
    })
    .catch((e) => {
      setStatus("error");
      setTitle("Verifier email is failed.");
      setContent(e.response.data.message);
    });

  const onClick = () => {
    props.history.replace(`/${localStorage.getItem("locale")}/login`);
  };
  return (
    <>
      <div
        className="h-100 d-flex align-items-center justify-content-center"
        style={{ background: "url('/assets/images/covers/slide-page.jpg')" }}
      >
        <Result
          style={{
            backgroundColor: "white",
            borderRadius: 8,
            padding: 64,
          }}
          status={status || 404}
          title={title || ""}
          subTitle={content || ""}
          extra={[
            <Button type="primary" size="large" shape="round" onClick={onClick}>
              {locale.t("register_verify_go")}
            </Button>,
          ]}
        />
      </div>
    </>
  );
}

export default EmailVerifier;
