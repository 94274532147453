import { post } from "../../helper/request";
const api = process.env.REACT_APP_API_URL;

export const calcCurrency = async (base, pair) => {
  const { data } = await post(`${api}calc/converse`, {
    base: base,
    pair: pair,
  });
  return data;
};

export const calcCurrencyMt5 = async (base, pair) => {
  const { data } = await post(`${api}calc/mt5/converse`, {
    base: base,
    pair: pair,
  });
  return data;
};

export const calcMargin = (lot, contractSize, leverage, ask, converse) => {
  const marginBase = (((lot * contractSize) / leverage) * ask) / converse;
  const marginQuote = ((lot * contractSize) / leverage) * ask;
  return {
    marginBase,
    marginQuote,
  };
};

export const calcMarginMt5 = async (
  lot,
  contractSize,
  leverage,
  bid,
  base,
  converseRate,
  marginRate,
  accountCurrency,
  calcMode
) => {
  if (calcMode === 0) {
    const marginBase = ((lot * contractSize) / leverage) * marginRate;
    const marginQuote = marginBase * converseRate;
    if (base === accountCurrency) {
      return {
        marginBase,
        marginQuote,
      };
    } else {
      const { converse } = await calcCurrencyMt5(base, accountCurrency);
      const marginCalc = marginBase * converse;
      const marginCalcQoute = marginCalc * converseRate;
      return {
        marginCalc,
        marginCalcQoute,
      };
    }
  } else {
    const marginQuote = ((lot * contractSize * bid) / leverage) * marginRate;
    const { converse } = await calcCurrencyMt5(base, accountCurrency);
    const marginAcc = marginQuote * converse;
    const marginCalc = marginAcc;
    const marginCalcQoute = marginQuote * converse;
    return {
      marginCalc,
      marginCalcQoute,
    };
  }
};

export const calcPipValue = (fluctuation, converse, lot, contractSize) => {
  const pipBase = (fluctuation / converse) * (lot * contractSize);
  const pipQoute = lot * contractSize * fluctuation;
  return {
    pipBase,
    pipQoute,
  };
};

export const calcPipValueMt5 = (
  fluctuation,
  converse,
  lot,
  contractSize,
  calcMode
) => {
  if (calcMode === 0) {
    const pipBase = lot * contractSize * (fluctuation / converse);
    const pipQoute = fluctuation * contractSize * lot;
    return {
      pipBase,
      pipQoute,
    };
  } else {
    const pipBase = (fluctuation * contractSize * lot) / converse;
    const pipQoute = fluctuation * contractSize * lot;
    return {
      pipBase,
      pipQoute,
    };
  }
};

export const calcSwap = (
  fluctuation,
  lot,
  contractSize,
  swapLong,
  swapShort,
  converse
) => {
  const swaplongAccount = fluctuation * (lot * contractSize * swapLong);
  const swapShortAccount = fluctuation * (lot * contractSize * swapShort);
  const swapLongQuote =
    (fluctuation / converse) * (lot * contractSize * swapLong);
  const swapShortQuote =
    (fluctuation / converse) * (lot * contractSize * swapShort);
  return {
    swaplongAccount,
    swapShortAccount,
    swapLongQuote,
    swapShortQuote,
  };
};

export const calcSwapMt5 = (
  fluctuation,
  lot,
  contractSize,
  swapLong,
  swapShort,
  converse,
  calcMode
) => {
  if (calcMode === 0) {
    const swaplongAccount = 0;
    const swapShortAccount = 0;
    const swapLongQuote = 0;
    const swapShortQuote = 0;
    return {
      swaplongAccount,
      swapShortAccount,
      swapLongQuote,
      swapShortQuote,
    };
  } else if (calcMode !== 0) {
    const swaplongAccount = fluctuation * (lot * contractSize * swapLong);
    const swapShortAccount = fluctuation * (lot * contractSize * swapShort);
    const swapLongQuote =
      (fluctuation / converse) * (lot * contractSize * swapLong);
    const swapShortQuote =
      (fluctuation / converse) * (lot * contractSize * swapShort);
    return {
      swaplongAccount,
      swapShortAccount,
      swapLongQuote,
      swapShortQuote,
    };
  }
};
