import { get } from "../helper/request";

export const getSymbolStats = async (id, date) => {
  return get(
    `${process.env.REACT_APP_API_URL}v2/user/account/symbol/stats?account_trade_id=${id}&date=${date}`
  );
};
export const getSymbolStatsChart = async (id, date) => {
  return get(
    `${process.env.REACT_APP_API_URL}v2/user/account/trade/stats?account_trade_id=${id}&date=${date}`
  );
};
