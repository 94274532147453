import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { get, post } from "../../../../helper/request";
import { LoadingOutlined } from "@ant-design/icons";
export const b2binpayApi = (page) => {
  return get(
    `${process.env.REACT_APP_API_URL + `deposit/b2binpay?page=${page}`}`
  );
};
export const b2binpayPostApi = (data) => {
  return post(`${process.env.REACT_APP_API_URL + `/deposit/b2binpay`}`, data);
};

const B2binpay = (props) => {
  const {
    paymentId,
    account: { id },
  } = props;
  const [page, setPage] = useState(3);
  const [loading, setLoading] = useState(false);
  const [isData, setData] = useState({
    links: {
      first: "",
      last: "",
      next: "",
    },
    wallets: [],
    maxPage: 0,
  });

  const [payload, setPayload] = useState({
    wallet_id: 0,
    account_trade_id: id,
    payment_id: paymentId,
  });

  const onNextPage = async (el) => {
    try {
      setLoading(true);
      const { data } = await b2binpayApi(el);
      if (data) {
        const a = data.links.last.split("page=");
        setData(() => ({
          ...data,
          wallets: [...isData.wallets, ...data.wallets],
          maxPage: a[1],
        }));
        setLoading(false);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    onNextPage(3);
  }, []);

  const onFinish = async (el, currency_id) => {
    try {
      setLoading(true);
      payload.wallet_id = el;
      payload.currency_id = currency_id;
      const { data } = await b2binpayPostApi(payload);
      if (data) {
        window.location.assign(data.link);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  const addDefaultSrc = (ev) => {
    ev.target.src = "/assets/images/payment/alphapo.png";
  };
  return (
    <Spin spinning={loading}>
      <div className="row">
        {isData.wallets.map((e) => (
          <div className="col-6">
            <button
              className="btn shadow-sm w-100 mb-3 d-flex align-items-center justify-center"
              onClick={() => {
                onFinish(e.id, e.currency_id);
              }}
              disabled={loading}
            >
              <img
                onError={addDefaultSrc}
                src={
                  process.env.REACT_APP_HOST +
                  `/assets/img/logo-crypto/${e.currency_id}.png`
                }
                alt="..."
                width={35}
              />
              <span className="ml-2">
                {e.name.replace(", IUX Markets", "")}
              </span>
            </button>
          </div>
        ))}
      </div>
      <div className="text-center">
        {page !== 1 && (
          <button
            className="btn btn-link"
            onClick={() => {
              onNextPage(page - 1);
              setPage(page - 1);
            }}
          >
            Show more
          </button>
        )}
      </div>
    </Spin>
  );
};

export default B2binpay;
