import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Steps,
  Divider,
  Row,
  Col,
  Result,
  Modal,
  Tooltip,
  Collapse,
  Button,
} from "antd";
import { get } from "../../../helper/request";
import WithDrawThirdStep from "./withdraw/WithDrawThirdStep";
import WithDrawFirstStep from "./withdraw/WithDrawFirstStep";
import WithDrawSecondStep from "./withdraw/WithDrawSecondStep";

import { Link } from "react-router-dom";
const { Panel } = Collapse;
const locale = require("react-redux-i18n").I18n;
const customPanelStyle = {
  background: "#FFF",
  borderRadius: 4,
  fontSize: 12,
  // marginBottom: 24,
  border: 0,
  overflow: "hidden",
};
// const locale = require("react-redux-i18n").I18n;
export const WithDraw = (props) => {
  const { user } = props;
  const [step, setStep] = useState(null);
  const [account, setAccount] = useState(undefined);
  const [amount, setAmount] = useState(3);
  const [amount_thb, setAmount_thb] = useState(0);
  const [paymentAll, setPaymentAll] = useState([]);
  const [account_withdraw_id, setAccount_withdraw_id] = useState(0);
  const [accountSelected, setAccountSelected] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [payment_id, setPayment_id] = useState(1);
  const [payment_key, setPayment_key] = useState("thai_bank");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState({ title: "", description: "" });
  const [currentRate, setCurrentRate] = useState(0);
  const [paymentSelected, setpaymentSelected] = useState(null);
  const [accountWelcome, setaccountWelcome] = useState(null);
  const onBackStep = (acc) => {
    if (acc !== "welcome") {
      setAmount(0);
    }
    setStep(step - 1);
  };
  // const onBackStepWelcome = (state) => {
  //   setAmount(0);
  //   setStep(step - 1);
  // };
  const setOnCurrentRate = (value) => {
    setCurrentRate(value);
  };
  const onNextStep = (withDrawid) => {
    setAccount_withdraw_id(withDrawid);
    setStep(step + 1);
  };
  const onNextStepPayment = (state) => {};
  const onSuccess = (state) => {
    addInvite();
    setShowSuccess(true);

    setStep(step + 1);
  };
  const addInvite = () => {
    try {
      const customerInvitation = {
        customerEmail: user?.profile?.email,
        customerFirstName: user?.profile?.first_name,
        customerLastName: user?.profile?.last_name || "",
      };
      window.tfn.createInvitation(customerInvitation);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    renderWithdraw();
  }, []);

  const renderWithdraw = () => {
    let urlParams = new URLSearchParams(window.location.search);
    let account_number = urlParams.get("account");
    let condition = urlParams.get("condition");
    let isamount = urlParams.get("amount");
    if (account_number && condition) {
      setAccountSelected(
        props.user.accounts.find((e) => e.account_number === account_number)
      );

      setaccountWelcome({
        account: props.user.accounts.find(
          (e) => e.account_number === account_number
        ),
        condition: condition,
        amount: isamount,
      });
      setAmount(isamount);
      setStep(0);
    } else if (account_number) {
      setAccountSelected(
        props.user.accounts.find((e) => e.account_number === account_number)
      );
      setStep(0);
      // setStep({
      //   step: 1,
      //   account: props.accounts.find(
      //     (e) => e.account_number === account_number
      //   ),
      // });
    } else {
      setStep(0);
    }
  };

  const onAccountSelected = (value) => {
    if (props.user.accounts.find((e) => e.id === value)) {
      setAccountSelected(props.user.accounts.find((e) => e.id === value));
    }
  };
  const onPaymentSelected = (event) => {
    if (paymentAll.find((e) => e.payment_key === event.target.value)) {
      setpaymentSelected(
        paymentAll.find((e) => e.payment_key === event.target.value)
      );
      setPayment_key(
        paymentAll.find((e) => e.payment_key === event.target.value).payment_key
      );
    }
  };
  useEffect(() => {
    get(`${process.env.REACT_APP_API_URL}payment/withdraw`)
      .then(({ data }) => {
        setPaymentAll(data);
        if (data.length > 0) {
          if (data.filter((e) => e.recommend === 1).length > 0) {
            setpaymentSelected(data.filter((e) => e.recommend === 1)[0]);
            setPayment_key(
              data.filter((e) => e.recommend === 1)[0].payment_key
            );
            setPayment_id(data.filter((e) => e.recommend === 1)[0].id);
          } else {
            setpaymentSelected(data[0]);
            setPayment_key(data[0].payment_key);
            setPayment_id(data[0].id);
          }
        } else {
          setpaymentSelected(null);
          setPayment_key(null);
          setPayment_id(null);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const onChangeAmount = (value) => {
    setAmount(value);
  };
  const renderStep = (step) => {
    switch (step) {
      case 0:
        return (
          <WithDrawFirstStep
            key={"withdraw step 1"}
            onNextStep={onNextStep}
            onBackStep={onBackStep}
            onAccountSelected={onAccountSelected}
            paymentId={payment_id}
            paymentKey={payment_key}
            paymentAll={paymentAll}
            onChangePayment={onPaymentSelected}
            paymentSelected={paymentSelected}
            accountSelected={accountSelected}
            accountWelcome={accountWelcome}
          />
        );
      case 1:
        return (
          // <Modal
          //   style={{ borderRadius: 20 }}
          //   headStyle={{ textAlign: "center" }}
          //   title="Withdraw"
          //   visible={step === 1}
          //   onOk={handleOk}
          //   onCancel={handleCancel}
          //   width={580}
          //   className="user"
          //   footer={[
          //     <div className="d-flex justify-content-between text-center"></div>,
          //   ]}
          // >
          <WithDrawSecondStep
            key={"withdraw step 2"}
            account={accountSelected}
            onSetRate={setOnCurrentRate}
            onBackStep={onBackStep}
            onNextStep={onNextStep}
            onNextStepPayment={onNextStepPayment}
            paymentId={paymentSelected.id}
            paymentKey={payment_key}
            paymentSelected={paymentSelected}
            address={address}
            onChangeAmount={onChangeAmount}
            amount={amount}
            accountWelcome={accountWelcome}
          />
          // </Modal>
        );
      case 2:
        return (
          // <Modal
          //   style={{ borderRadius: 20 }}
          //   headStyle={{ textAlign: "center" }}
          //   title="Withdraw"
          //   visible={step === 2}
          //   width={580}
          //   onOk={handleOk}
          //   onCancel={handleCancel}
          //   className="user"
          //   footer={[
          //     <div className="d-flex justify-content-between text-center"></div>,
          //   ]}
          // >
          <WithDrawThirdStep
            amount={amount}
            account={accountSelected}
            account_withdraw_id={account_withdraw_id}
            onNextStep={onNextStep}
            onBackStep={onBackStep}
            onSuccess={onSuccess}
            onNextStepPayment={onNextStepPayment}
            paymentId={paymentSelected.id}
            paymentSelected={paymentSelected}
            paymentKey={payment_key}
            address={address}
            rate={currentRate}
          />
          // </Modal>
        );
      default: {
        if (showSuccess) {
          return (
            <div>
              <div className="d-flex justify-content-center">
                <img
                  src="/assets/images/icons/icon-success.svg"
                  alt="success"
                />
              </div>
              <div className="text-center fs-14 font-weight-bold mt-4">
                {locale.t("withdraw_success.title")}
              </div>
              <div className="text-center fs-18 font-weight-bold mt-4 text-primary-new-iux">
                {amount}{" "}
                {accountSelected?.account_type === "Cent" ? "USC" : "USD"}
              </div>
              <div className="text-center fs-14 font-weight-bold mt-4 mb-4">
                {locale.t("withdraw_success.detail")}
              </div>

              <Link to={`/${localStorage.getItem("locale")}/wallet`}>
                <Button className="btn-primary-new-iux w-100">
                  {locale.t("withdraw_success.button")}
                </Button>
              </Link>
            </div>
          );
        }
      }
    }
  };
  return (
    <div>
      {" "}
      <div className="row mt-5">
        <div className="col-12 col-md-6 ">
          <div style={{ maxWidth: 320 }}> {renderStep(step)}</div>
        </div>
        <div className="d-none d-sm-block col-0 col-md-6 user">
          <div style={{ maxWidth: 300 }}>
            {step === 0 ? (
              <>
                <div className="d-flex justify-content-between ">
                  <div className="fw-700">{locale.t("faq.title_faq")}</div>
                  <div
                    className="text-primary-new-iux cursor-pointer"
                    onClick={() =>
                      window.open(
                        `https://help.iuxmarkets.com/${
                          localStorage.getItem("locale") === "jp"
                            ? "ja"
                            : localStorage.getItem("locale")
                        }/`
                      )
                    }
                  >
                    {locale.t("faq.viewmore")}
                  </div>
                </div>
                <Collapse
                  bordered={false}
                  className="user"
                  style={{ backgroundColor: "#FFF" }}
                  expandIconPosition="right"
                >
                  <Panel
                    header={locale.t("faq.faq_title_1")}
                    key="1"
                    style={customPanelStyle}
                  >
                    <p>{locale.t("faq.faq_des_1")}</p>
                  </Panel>
                  <Panel
                    header={locale.t("faq.faq_title_2")}
                    key="2"
                    style={customPanelStyle}
                  >
                    <p>{locale.t("faq.faq_des_2")}</p>
                  </Panel>
                  <Panel
                    header={locale.t("faq.faq_title_3")}
                    key="3"
                    style={customPanelStyle}
                  >
                    <p>{locale.t("faq.faq_des_3")}</p>
                  </Panel>
                  <Panel
                    header={locale.t("faq.faq_title_4")}
                    key="4"
                    style={customPanelStyle}
                  >
                    <p>{locale.t("faq.faq_des_4")}</p>
                  </Panel>
                  {user?.bonus_config?.bonus_countries?.find(
                    (e) => e === props?.user?.profile?.country_id
                  ) && (
                    <Panel
                      header={locale.t("faq.faq_title_5")}
                      key="5 "
                      style={customPanelStyle}
                    >
                      <p>{locale.t("faq.faq_des_5")}</p>
                    </Panel>
                  )}
                </Collapse>
                <div style={{ color: "#F19E03" }} className="fs-10">
                  {locale.t("faq.faq_footer")}
                </div>
              </>
            ) : (
              <>
                <div className="d-flex justify-content-between">
                  <div className="fw-700">{locale.t("faq.notice_title")}</div>
                </div>
                <div style={{ marginTop: 24 }}>{locale.t("faq.notice")}</div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WithDraw);
