import { get, post } from "../helper/request";

export const getAccountTypeList = async (platform) => {
  return get(
    `${process.env.REACT_APP_API_URL}api/v2/account/types/${platform}`
  );
};
export const postChangePassword = async (data) => {
  return post(`${process.env.REACT_APP_API_URL}v2/user/password/change`, data);
};
export const postCheckPassword = async (data) => {
  return post(`${process.env.REACT_APP_API_URL}v2/user/password/check`, data);
};
export const postChangePhoneOtp = async (data) => {
  return post(`${process.env.REACT_APP_API_URL}v2/phone/change`, data);
};
export const getSymbolsSwap = async (account_type) => {
  return get(`${process.env.REACT_APP_API_URL}v2/symbols/${account_type}`);
};
