import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Select, Button, Input, Modal, Checkbox, Alert, Form } from "antd";
import axios from "axios";
import { connect } from "react-redux";
import { get } from "../../helper/request.js";
import { Helmet } from "react-helmet";
import FooterAuthPage from "../../components/footer-landing-page/FooterAuth";
import { GlobalOutlined, CloseOutlined } from "@ant-design/icons";
import { Times } from "../forgotpassword/ForgotPasswordForm.js";
import { onOpenPolicy } from "../../helper/policy.js";
const TITLE = "Sign Up | IUX Markets";
const locale = require("react-redux-i18n").I18n;
const { Option } = Select;

export const RegisterPage = (props) => {
  let urlParams = new URLSearchParams(window.location.search);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [countries, setCountries] = useState([]);
  useEffect(() => {
    get(process.env.REACT_APP_API_URL + "countries")
      .then((res) => {
        setCountries(res.data.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch((e) => {});
  }, []);
  const onSubmit = (value) => {
    const history = props.history;
    const errors = [];
    let invite = urlParams.get("invite");
    let code = urlParams.get("code");
    setLoading(true);

    setButtonLoading(true);
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "v2/register", {
          email: value.email,
          password: value.password,
          countries_id: value.countries,
          reference_code: sessionStorage.getItem("code") || code || null,
          invite_code: invite || null,
        })
        .then((res) => {
          if (res.data.code === 0) {
            Modal.success({
              title: "Register has been created successfully.",
              content: locale.t("register_verify_email"),
              onOk() {
                localStorage.setItem("code", "");
                localStorage.removeItem("code", "");
                sessionStorage.setItem("code", "");
                sessionStorage.removeItem("code", "");
                history.push(`/${localStorage.getItem("locale")}/login`);
              },
            });
            setButtonLoading(false);
          } else {
            Modal.error({
              title: locale.t("dashboardWallet.your_account"),
              content: res.response.data.error,
            });
            errors.push(res.response.data.error);
          }
          setLoading(false);
        })
        .catch((e) => {
          Modal.error({
            title: locale.t("dashboardWallet.your_account"),
            content: e.response.data.error,
          });

          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      Modal.error({
        title: locale.t("dashboardWallet.your_account"),
        content: (
          <Alert
            message={locale.t("dashboardWallet.error_10")}
            type="success"
          />
        ),
      });
      setLoading(false);
    }
  };
  const validatorPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = new RegExp(
        /^(?=.*[0-9])(?=.*[A-Za-z])[a-zA-Z0-9~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]{8,15}$/
      );

      if (getFieldValue("password")) {
        if (value.match(regex)) {
          return Promise.resolve();
        } else {
          return Promise.reject(locale.t("register_password_validate"));
        }
      } else {
        return Promise.reject(locale.t("register_password_validate"));
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="bg-black">
        <div className="container">
          <div className="pt-4 pb-4 text-center text-white">
            {locale.t("login_top_1")}&nbsp;
            <span className="font-bold-iux text-white">
              {locale.t("login_top_2")}&nbsp;
            </span>
            {locale.t("login_top_3")}
          </div>
        </div>
      </div>
      <section className="d-flex flex-column h-auto bg-black">
        <div className="text-center rectangle-card rectangle-card-login ml-auto mr-auto mt-4 mb-4 position-relative">
          <Times
            className=" position-absolute"
            onClick={() =>
              props.history.push(`/${localStorage.getItem("locale")}`)
            }
          >
            <CloseOutlined style={{ color: "#A0A0A0" }} />
          </Times>{" "}
          <div className="logo-register">
            <Link
              to={`/${localStorage.getItem("locale")}`}
              style={{ padding: 0, overflowY: "auto" }}
            >
              <img
                alt="IUX"
                className="registerlogo"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/icons/iuxmarket_logo.svg"
                }
              />
            </Link>
          </div>
          <h5
            className="mt-4 text-center fs-24 fw-700 text-white"
            style={{
              padding: "10px 0px 0px",
            }}
          >
            {locale.t("register_title")}
          </h5>
          <div className="mt-4" />
          <Form
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            layout={"vertical"}
            wrapperCol={{ span: 16 }}
            // initialValues={{ remember: true }}
            onFinish={onSubmit}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label={locale.t("register_choose_region")}
              name="countries"
              rules={[
                {
                  required: true,
                  message: locale.t("dashboardWallet.input_error"),
                },
              ]}
            >
              <Select
                className="login"
                style={{ width: "100%" }}
                size="large"
                // onChange={this.onSelectLocation}
                placeholder={
                  <React.Fragment>
                    <GlobalOutlined /> &nbsp;
                    {locale.t("register_choose_region_placeholder")}
                  </React.Fragment>
                }
              >
                {countries.map((country, index) => {
                  return (
                    <Option key={index} value={country.id}>
                      {/* {
                        <img
                          className="mr-2 mb-1 rounded"
                          style={flagStyle}
                          src={`${process.env.PUBLIC_URL}/assets/images/icons/${country.image}`}
                          alt=""
                        />
                        
                      } */}
                      <span
                        className={`fi fi-${country?.short_name?.toLowerCase()}`}
                      />
                      &nbsp;
                      {country.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={locale.t("register_email")}
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: locale.t("dashboardWallet.input_error"),
                },
              ]}
            >
              <Input
                style={{ height: "42px" }}
                className="input-register login"
                placeholder={locale.t("register_email")}
                type="email"
              />
            </Form.Item>
            <Form.Item
              label={locale.t("register_password")}
              name="password"
              style={{ background: "#242424" }}
              rules={[
                {
                  required: true,
                  message: locale.t("dashboardWallet.input_error"),
                },
                validatorPassword,
              ]}
            >
              <Input.Password
                style={{
                  height: "42px",
                  background: "#242424",
                  border: "1px solid #3c3c3c",
                  "&:hover": {
                    border: "1px solid #1f7bb8",
                    boxShadow: "unset",
                  },
                }}
                className="input-register login"
                placeholder={locale.t("register_password")}
                type="password"
              />
            </Form.Item>

            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(locale.t("register_error_choose_policy"))
                        ),
                },
              ]}
            >
              <Checkbox className="text-white">
                {locale.t("register_policy")}
                <span
                  className="cursor-pointer text-primary-new-iux"
                  onClick={() => onOpenPolicy("condition_iux_market")}
                >
                  {locale.t("register_policy_link")}
                </span>
              </Checkbox>
            </Form.Item>

            <Form.Item shouldUpdate>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !form.isFieldTouched("countries") ||
                    !form.isFieldTouched("email") ||
                    !form.isFieldTouched("password") ||
                    !form.isFieldTouched("agreement") ||
                    form.getFieldsError().filter(({ errors }) => errors.length)
                      .length
                  }
                  className="btn-primary-new-iux w-100"
                  loading={loading}
                >
                  {locale.t("register_button_text")}
                </Button>
              )}
            </Form.Item>
          </Form>
          <div>
            <Link to={`/${localStorage.getItem("locale")}/login`}>
              <p className="text-center mt-4 d-flex justify-content-between">
                <span className="text-white">
                  {locale.t("already_account")}&nbsp;
                </span>
                <span
                  style={{
                    fontWeight: "600",
                  }}
                  className="text-primary-new-iux fs-14"
                >
                  {locale.t("login_title")}
                </span>
              </p>
            </Link>
          </div>
        </div>
        <FooterAuthPage locale={locale} />
      </section>
    </>
  );
};

const mapStateToProps = ({ i18n }) => ({
  locale: i18n.locale,
});
export default connect(mapStateToProps, null)(RegisterPage);
