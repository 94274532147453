import React, { useState, useEffect } from "react";
import {
  Form,
  Icon,
  Result,
  Input,
  Select,
  message,
  Upload,
  Button,
  Alert,
  Divider,
} from "antd";
import { connect } from "react-redux";
import { updateVerifierBookBank } from "../../../../actions";
import { post } from "../../../../helper/request";
import jsonBank from "../../../../mock/bank.json";
import jsonBankLaos from "../../../../mock/laosBank.json";
import jsonBankVn from "../../../../mock/bank_vn.json";
import jsonBankMy from "../../../../mock/bank_my.json";
import jsonBankId from "../../../../mock/bank_id.json";
import { FileSearchOutlined } from "@ant-design/icons";
import { StyledContent } from "../../../../components/content/Content";
import { getBankList } from "../../../../api/verifyuser";
import InprogressVerify from "./InprogressVerify";
import FormBookbank from "./FormBookbank";

const locale = require("react-redux-i18n").I18n;
const { Option } = Select;

function VerifyBookBank(props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [dataBankList, setDataBankList] = useState([]);
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");
        resolve(base64String);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleVerifyBookBank = async () => {
    try {
      const values = await form.validateFields();
      const filebookbank = await fileToBase64(values.upload.file)
        .then((base64String) => {
          return base64String;
        })
        .catch((error) => {
          console.error(error);
        });
      // const formData = new FormData();

      // formData.append("book_bank", values.upload.file);

      // props.user.profile.country_id === 7 &&
      //   formData.append("bank_branch", values?.bank_branch);
      // props.user.profile.country_id === 7 &&
      //   formData.append("bank_account_type", values?.bank_account_type);

      // formData.append("bank_account_number", values.bank_number);
      // formData.append("bank_id", values.bank_name);
      // formData.append("bank_account_name", values.bookbank_name);
      const payload = {
        book_bank: filebookbank,
        bank_branch: values?.bank_branch,
        bank_account_type: values?.bank_account_type,
        bank_account_number: values.bank_number,
        bank_id: values.bank_name,
        bank_account_name: values.bookbank_name,
      };
      post(process.env.REACT_APP_API_URL + "v2/user/verify-account", payload)
        .then((res) => {
          props.updateVerifierBookBank({
            status: "pending",
            id: null,
            user_id: null,
            bank_name: null,
            bank_account_name: null,
            bank_account_number: null,
            book_bank: null,
            country_id: null,
            reason: null,
            current: null,
            flags: null,
            actionAt: null,
            action_seconds: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
          });
          setLoading(false);
        })
        .catch((e) => {
          message.error(e?.response?.data?.error);
          setLoading(false);
        });
    } catch (e) {
      message.error(e?.response?.data?.error);
      setLoading(false);
      console.log(e);
    }
  };

  const verifyBookBankForm = () => {
    const uploadOption = {
      onRemove: async (file) => {
        setFile(null);
        await form.setFieldsValue({ upload: null });
        await form.validateFields(["upload"]);
      },

      beforeUpload: (file) => {
        const isPNG = file.type === "image/png";
        const isJPG = file.type === "image/jpg";
        const isJPEG = file.type === "image/jpeg";
        const isPDF = file.type === "application/pdf";
        if (!isPNG && !isJPG && !isJPEG && !isPDF) {
          message.error(`${file.name} is not a image file`);
        } else {
          setFile([file]);
        }
        return false;
      },
    };
    return (
      <Form name="verify_book_bank" form={form} className="bg-white user">
        <Alert
          description={locale.t(
            "dashboardFund.modalVerify.alertVerifyBookBank"
          )}
          type="warning"
          showIcon
          style={{
            borderRadius: 8,
            color: "#FFA800",
            border: "unset",
            padding: "8px 8px 8px 48px",
          }}
        />

        <Divider />
        <Form.Item
          name="bank_name"
          rules={[
            {
              required: true,
              message: locale.t("dashboardFund.modalVerify.validateBank"),
            },
          ]}
          label={locale.t("dashboardFund.modalVerify.bankLabel")}
        >
          <Select
            placeholder={locale.t("dashboardFund.modalVerify.bankPlaceHolder")}
          >
            {dataBankList?.map((bank, index) => {
              return (
                <>
                  {bank.active === 1 && (
                    <Option key={"bankType_" + index} value={bank.id}>
                      {bank.text}
                    </Option>
                  )}
                </>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="bank_number"
          rules={[
            {
              required: true,
              message: locale.t(
                "dashboardFund.modalVerify.validateBankAccount"
              ),
            },
          ]}
          label={locale.t("dashboardFund.modalVerify.accountNumberLabel")}
        >
          <Input
            placeholder={locale.t(
              "dashboardFund.modalVerify.accountNumberLabel"
            )}
            autocomplete="off"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onPaste={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Form.Item>
        <Form.Item
          name="bookbank_name"
          rules={[
            {
              required: true,
              message: locale.t("dashboardFund.modalVerify.validateBankName"),
            },
          ]}
          label={locale.t("dashboardFund.modalVerify.accountNameLabel")}
        >
          <Input
            placeholder={locale.t("dashboardFund.modalVerify.accountNameLabel")}
          />
        </Form.Item>
        {/* {props.user.profile.country_id === 7 && (
          <Form.Item
            name="bank_branch"
            rules={[
              {
                required: true,
                message: "Please Input IFSC Code Correctly",
              },
            ]}
            label={"IFSC Code"}
          >
            <Input placeholder={"IFSC Code"} />
          </Form.Item>
        )}
        {props.user.profile.country_id === 7 && (
          <Form.Item
            name="bank_account_type"
            rules={[
              {
                required: true,
                message: "Please Input IFSC Code Correctly",
              },
            ]}
            label={"Bank account type "}
          >
            <Input placeholder={"Bank account type"} />
          </Form.Item>
        )} */}
        <Form.Item
          className="upload-verify"
          name="upload"
          rules={[
            {
              required: true,
              message: locale.t("dashboardFund.modalVerify.footerText2"),
            },
          ]}
          label={
            <div style={{ whiteSpace: "pre-line" }} className="">
              {locale.t("dashboardFund.modalVerify.footerText2")}
              *(xxx.png, xxx.jpg, xxx.jpeg)
            </div>
          }
        >
          <Upload {...uploadOption} fileList={file}>
            <Button className="btn-outline-iux mt-3">
              <Icon type="upload" /> Choose File.
            </Button>
          </Upload>
        </Form.Item>
        <div className="text-right">
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleVerifyBookBank}
            className="btn-primary-new-iux"
          >
            Confirm
          </Button>
        </div>
      </Form>
    );
  };
  const fetchBankList = async () => {
    try {
      const { data } = await getBankList();
      setDataBankList(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchBankList();
  }, []);
  return (
    <div>
      <div
        // style={{ width: 320, borderRadius: 12 }}
        className={` pt-4  pb-4 `}
      >
        <h2 className="font-weight-bold px-4 fs-24 fs-xs-16">
          {locale.t("bookbankverifyTitle")}
        </h2>
        <Divider />
        {props.user.bookBankVerify.status === "not_verify" ? (
          <div className="bookbank-container mx-auto">
            <FormBookbank />
          </div>
        ) : props.user.bookBankVerify.status === "rejected" ? (
          <div className="bookbank-container mx-auto">
            <FormBookbank />
          </div>
        ) : props.user.bookBankVerify.status === "pending" ? (
          <div className="w-auto">
            <InprogressVerify des={locale.t("sendverifysuccess")} />
          </div>
        ) : props.user.bookBankVerify.status === "accepted" ? (
          <Result
            status="success"
            title="You passed identity verification."
            subTitle="Have enjoy with IUXmarkets."
          />
        ) : (
          <Result
            status="error"
            title="Please Wait"
            subTitle="Please contact to admin"
          />
        )}
        {/* <p className="mt-2">
        <span style={{ color: "red" }}>Note :</span>
        {locale.t("dashboardFund.modalVerify.footerText3")}
      </p> */}
      </div>
      <p className="p-4 fs-10 text-gray">
        Note :{locale.t("dashboardFund.modalVerify.footerText3")}
      </p>
    </div>
  );
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, { updateVerifierBookBank })(
  VerifyBookBank
);
